import React, { Component } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Badge } from 'antd';

class SemiCircleGauge extends Component {
  constructor(props) {
    super(props);

    this.state = {


      series: [60],

      options: {
        chart: {
          type: 'radialBar',
          width: "100%",
          offsetY: -20,
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          radialBar: {

            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#e7e7e7",
              strokeWidth: '97%',
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: '#999',
                opacity: 1,
                blur: 2
              }
            },
            dataLabels: {


              name: {
                show: false
              },
              value: {
                offsetY: -2,
                fontSize: '22px'
              }
            }
          }
        },
        grid: {
          padding: {
            top: -10
          }
        },
        fill: {

          type: 'gradient',
          gradient: {
            shade: 'light',
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 53, 91]
          },
        },
        labels: ['Average Results'],
      },



    };
  }
  componentDidMount() {
    console.log(this.props.setvalues)
    this.setState({
      series: [this.props.setvalues === 'NaN' || null ? 0 : this.props.setvalues],
      options: {
        chart: {
          color: 'red',
          type: 'radialBar',
          width: "100%",
          offsetY: -20,
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          radialBar: {
            color: 'red',
            startAngle: -90,
            endAngle: 90,
            track: {
              background: '#FFFFFF',
              strokeWidth: '97%',
              margin: 5, 
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: '#999',
                opacity: 0.5,
                blur: 1
              }
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: -2,
                fontSize: '22px'
              }
            },
          }
        },
        grid: {
          padding: {
            top: -10
          }
        },
        fill: {
          colors: [this.props.setcolor],
          type: 'gradient',
          gradient: {
            shade: 'dark',
            //gradientToColors: ["#87D4F9"],
            gradientToColors: ["#42B02A"],
            shadeIntensity: 0.4,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
            //stops: [0, 50, 53, 91]
          },
        },
        labels: ['Average Results'],
      },
    })
  }

  render() {
    return (
      <div>
        <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" height={"260"} />
        <div class="card-body" style={{ padding: 0, paddingBottom: 5 }}>
          <div className='row'>
            <div className='col-md-6'>
              <Badge
                count={this.props.realValue !== 0 ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.props.realValue) : 0}
                color="#2D5FF4"
                overflowCount={parseFloat(this.props.realValue.toFixed(2)) !== 0 ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.props.realValue) : 0}
                showZero
              />
            </div>
            <div className='col-md-6'>
              <Badge
                count={this.props.targetValue !== 0 ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.props.targetValue) : 0}
                color="#42B02A"
                overflowCount={this.props.targetValue !== 0 ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.props.targetValue) : 0}
                showZero
              />
            </div>
          </div>
        </div>
        <h6>{this.props.setTitel}</h6>
      </div>
    )
  }
}
export default SemiCircleGauge;