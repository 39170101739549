import React, { Component } from "react";
import { connect } from "react-redux";
import { getSiteListByPage, filterSiteData } from "../../api/Repos/SiteRepo";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Collapse } from "reactstrap";
import {
  RightOutlined,
  DownOutlined,
  BorderOuterOutlined,
  TeamOutlined,
  AppstoreOutlined,
  DeploymentUnitOutlined,
  ClusterOutlined,
} from "@ant-design/icons";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

class RegionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      site_list: [],
      site_name: "",
      site_id: "",
      depotList: [],
      isOpen: false,
      depotId: "",
    };
  }

  componentDidMount() {
    console.log(this.props.regions.filter(checkuser=>checkuser?.isSubRegion==1))
    console.log(this.props.depots)
    //	this.getAllSites()
    // var _depotList = [];
    // this.props.regions.map((depot)=>{
    // 	_depotList.push({ value: depot.id, label: depot.name })
    // })
    // this.setState({
    // 	depotList : _depotList
    // })
  }

  // getAllSites = async() =>{
  // 	var res = await getSiteListByPage(this.state.pageNo);
  // 	if(res.success){
  // 		this.setState({
  // 			site_list : res.data
  // 		})
  // 	}
  // }

  previousBtnClick = () => {
    if (this.state.pageNo > 1) {
      var aa = this.state.pageNo - 1;
      this.setState(
        {
          pageNo: aa,
        },
        () => {
          this.getAllSites();
        }
      );
    }
  };

  nextBtnClick = () => {
    var aa = this.state.pageNo + 1;
    this.setState(
      {
        pageNo: aa,
      },
      () => {
        this.getAllSites();
      }
    );
  };

  searchFormItemChange = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    //console.log(obj);
    this.setState(obj);
  };

  searchDepottemChange = (event) => {
    this.setState({
      depotId: event.value,
    });
  };

  viewFilterClick = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  filterBtnClick = async () => {
    let formData = new FormData(); //formdata object

    formData.append("depotId", this.state.depotId); //append the values with key, value pair
    formData.append("siteId", this.state.site_id);
    formData.append("siteName", this.state.site_name);

    var res = await filterSiteData(formData);
    if (res.success) {
      this.setState({
        site_list: res.data,
      });
    }
  };

  clearBtnClick = () => {
    window.location.reload();
  };

  render() {
    return (
      <div className="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="page-title-box">
              <div class="row">
                <div class="col">
                  <h4 class="page-title">Region </h4>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0);">Organization</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0);">Region</a></li>

                  </ol>
                </div>
                <div class="col-auto align-self-center">
                <div class="button-items"> 
                                        <Link to={'/depot-list'}  target="_blank" type="button" class="btn btn-primary"><i class="mdi mdi-source-fork me-2"></i>All Depots</Link>
                                        <Link to={'/site-info'} target="_blank" type="button" class="btn btn-secondary"><i class="mdi mdi-home me-2"></i>All Sites</Link>
                                    </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Collapse isOpen={this.state.isOpen}>
          <div className="row">
            <div class="card">
              <div class="card-body bootstrap-select-1">
                <div class="row">
                  <div class="col-md-3">
                    <label class="mb-3">Depot</label>
                    <Select
                      options={this.state.depotList}
                      name="depotId"
                      onChange={(e) => this.searchDepottemChange(e)}
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="mb-3">Site Name</label>
                    <input
                      autoComplete="off"
                      style={{ height: 40 }}
                      type="text"
                      value={this.state.site_name}
                      className="form-control"
                      name="site_name"
                      id="site_name"
                      placeholder="Site Name"
                      onChange={(e) => this.searchFormItemChange(e)}
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="mb-3">Site ID</label>
                    <input
                      autoComplete="off"
                      style={{ height: 40 }}
                      type="text"
                      value={this.state.description}
                      className="form-control"
                      name="site_id"
                      id="site_id"
                      placeholder="Site ID"
                      onChange={(e) => this.searchFormItemChange(e)}
                    />
                  </div>
                </div>
                <div class="col-md-3" style={{ marginTop: 10 }}>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => this.filterBtnClick()}
                  >
                    {" "}
                    Filter
                  </button>
                  <button
                    type="button"
                    style={{ marginLeft: 5 }}
                    class="btn btn-dark"
                    onClick={() => this.clearBtnClick()}
                  >
                    Clear Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Collapse> */}
        <div className="row">
        {this.props.regions.filter(checkuser=>checkuser?.isSubRegion==1).map((site, index) => {
          return (
            <div className="col-md-4" style={{display:'grid'}}>
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">{site.name}</h4>
                <p class="text-muted mb-0">Parent Region : {site.parentRegion?.name}</p>
                <p class="text-muted mb-0"> Depots:  {this.props.depots
                              .filter((depot) => depot.region.id == site.id).length
                            }</p>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12">
                    <ul class="list-group">
                      {this.props.depots
                              .filter((depot) => depot.region.id == site.id).map((x,y)=>{
                                return(
                                  
                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <Link to={"/depot-summary/" + x.id}>
                                     
                                    <div>
                                      <i class="la la-check text-muted font-16 me-2"></i>{x.name}
                                    </div>
                                   
                                    </Link>
                                    {/* <span class="badge badge-outline-primary badge-pill">4</span> */}
                                  </li>
                              
                                   )
                              })
                            }

                    
                     
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          )
        })}
          

          {/* <div className="col-lg-6">
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    <h5 className="mt-0">Here the list of all continu projects. <span className="badge badge-pink">6</span></h5>
                                </li>
                            </ul>
                        </div>  */}

          <div className="col-lg-6 text-end">
            <div className="text-end">
              {/* <ul className="list-inline">
                                    <li className="list-inline-item">
                                        <div className="input-group">                               
                                            <input autoComplete="off"  type="text" id="example-input1-group2" name="example-input1-group2" className="form-control form-control-sm" placeholder="Search" />
                                            <button type="button" className="btn btn-soft-primary btn-sm"><i className="fas fa-search"></i></button>
                                        </div>
                                    </li>
                                    <li className="list-inline-item">
                                        <button type="button" className="btn btn-soft-primary btn-sm"><i className="fas fa-filter"></i></button>
                                    </li> 
                                </ul> */}
            </div>
          </div>
        </div>

        <div className="row">
          {this.props.regions.map((site, index) => {
           // return (
              // <div className="col-lg-3">
              //   <Link to={"/region-summary/" + site.id}>
              //     <div className="card">
              //       <div className="card-body">
              //         <div className="media mb-3">
              //           <ClusterOutlined style={{ fontSize: 30 }} />
              //           {/* <img src="assets/images/widgets/project2.jpg" alt="" className="thumb-md rounded-circle"/>                                       */}
              //           <div className="media-body align-self-center text-truncate ms-3">
              //             {/* <h4 className="m-0 fw-semibold  font-15">{site.siteName}</h4>    */}
              //             <p className="text-muted  mb-0 font-13">
              //               Region :{" "}
              //               <span className="fw-semibold m-0 fw-normal">
              //                 {" "}
              //                 {site.name}
              //               </span>
              //             </p>
              //           </div>
              //         </div>
              //         <hr
              //           className="hr-dashed"
              //           style={{ background: "#adadad" }}
              //         />
              //         <div className="d-flex justify-content-between mb-3">
              //           <h6 className="fw-semibold m-0">
              //             Depots :{" "}
              //             <span className="text-muted fw-normal">
              //               {" "}
              //               {this.props.depots
              //                 .filter((depot) => depot.region.id == site.id).length
              //               }
              //             </span>
              //           </h6>
              //           {/* <h6 className="fw-semibold m-0">Status : <span className="text-muted fw-normal"><span class="badge bg-success">ACTIVE</span></span></h6>                           */}
              //         </div>
              //         {/* <div className="d-flex justify-content-between mb-3">  
							// 			 <h6 className="fw-semibold m-0">Depot : <span className="text-muted fw-normal"> {site.depot.name}</span></h6>
							// 			 <h6 className="fw-semibold m-0"> Site Category: <span className="text-muted fw-normal"> {site.siteCategory.name}</span></h6>
									
							// 		 </div>  */}
              //         {/* <div className="d-flex justify-content-between mb-3">  
							// 			 <h6 className="fw-semibold m-0">Tower Legs Count : <span className="text-muted fw-normal"> {site.towerLegsCount == null ? "N/A" : site.towerLegsCount}</span></h6>
							// 			 <h6 className="fw-semibold m-0">Tower Height : <span className="text-muted fw-normal"> {site.towerHeight}</span></h6>                          
							// 		 </div>  */}
              //         {/* <hr className="hr-dashed" style={{background:"#adadad"}}/>
							// 		{site.depot.depotOfficers != null && site.depot.depotOfficers.length != 0 && <div className="d-flex justify-content-between mb-3">  
							// 			 <h6 className="fw-semibold m-0">Depot Officer : <span className="text-muted fw-normal"> {site.depot.depotOfficers[0].name}</span></h6>
										 
				      //                   </div> }
							// 			{site.depot.depotOfficers != null && site.depot.depotOfficers.length != 0 && <div className="d-flex justify-content-between mb-3">  
							// 			  <h6 className="fw-semibold m-0"> Contact No : <span className="text-muted fw-normal"> {site.depot.depotOfficers[0].mobileNumber}</span></h6>
									
				      //                   </div> } */}
              //         {/* <div className="row">
							// 			 <div className="col">
							// 				 <div>
							// 					 <h5 className="font-16 m-0 fw-bold">$56,800</h5>
							// 					 <p className="mb-0 fw-semibold">Total Budget</p>                                        
							// 				 </div>
							// 			 </div> 
							// 			 <div className="col-auto align-self-center">
							// 				 <h5 className="font-14 m-0 fw-bold">$22,100 <span className="font-12 text-muted fw-normal">Used Budget</span></h5>
							// 			 </div> 
							// 		 </div>  */}

              //         {/* <div> 
							// 			 <p className="text-muted mt-2 mb-1">There are many variations of passages of Lorem Ipsum available, 
							// 				 but the majority have suffered alteration in some form.
							// 			 </p>
							// 			 <div className="d-flex justify-content-between">
							// 				 <h6 className="fw-semibold">All Hours : <span className="text-muted fw-normal"> 530 / 281:30</span></h6>
							// 				 <h6 className="fw-semibold">Today : <span className="text-muted fw-normal"> 2:45</span><span className="badge badge-soft-pink fw-semibold ms-2"><i className="far fa-fw fa-clock"></i> 35 days left</span></h6>
							// 			 </div>
							// 			 <p className="text-muted text-end mb-1">15% Complete</p>
							// 			 <div className="progress mb-4" style={{height: '4px'}}>
							// 				 <div className="progress-bar bg-purple" role="progressbar" style= {{width: '15%'}} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
							// 			 </div>
							// 			 <div className="d-flex justify-content-between">
							// 				 <div className="img-group">
							// 					 <a className="user-avatar user-avatar-group" href="#">
							// 						 <img src="assets/images/users/user-6.jpg" alt="user" className="rounded-circle thumb-xxs" />
							// 					 </a>
							// 					 <a className="user-avatar user-avatar-group" href="#">
							// 						 <img src="assets/images/users/user-2.jpg" alt="user" className="rounded-circle thumb-xxs" />
							// 					 </a>
							// 					 <a className="user-avatar user-avatar-group" href="#">
							// 						 <img src="assets/images/users/user-3.jpg" alt="user" className="rounded-circle thumb-xxs" />
							// 					 </a>
							// 					 <a className="user-avatar user-avatar-group" href="#">
							// 						 <img src="assets/images/users/user-4.jpg" alt="user" className="rounded-circle thumb-xxs" />
							// 					 </a>
							// 					 <a href="" className="avatar-box thumb-xxs align-self-center">
							// 						 <span className="avatar-title bg-soft-info rounded-circle font-13 fw-normal">+6</span>
							// 					 </a>                    
							// 				 </div> 
							// 				 <ul className="list-inline mb-0 align-self-center">                                                                    
							// 					 <li className="list-item d-inline-block me-2">
							// 						 <a className="" href="#">
							// 							 <i className="mdi mdi-format-list-bulleted text-success font-15"></i>
							// 							 <span className="text-muted fw-bold">15/100</span>
							// 						 </a>
							// 					 </li>
							// 					 <li className="list-item d-inline-block">
							// 						 <a className="" href="#">
							// 							 <i className="mdi mdi-comment-outline text-primary font-15"></i>
							// 							 <span className="text-muted fw-bold">3</span>
							// 						 </a>                                                                               
							// 					 </li>
							// 					 <li className="list-item d-inline-block">
							// 						 <a className="ms-2" href="#">
							// 							 <i className="mdi mdi-pencil-outline text-muted font-18"></i>
							// 						 </a>                                                                               
							// 					 </li>
							// 					 <li className="list-item d-inline-block">
							// 						 <a className="" href="#">
							// 							 <i className="mdi mdi-trash-can-outline text-muted font-18"></i>
							// 						 </a>                                                                               
							// 					 </li>
							// 				 </ul>
							// 			 </div>                                        
							// 		 </div>                                                                  */}
              //       </div>
              //     </div>
              //   </Link>
              // </div>
            //);
          })}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    regions: state.regions,
    depots: state.depots,
  };
}

export default connect(mapStateToProps, {})(RegionList);
