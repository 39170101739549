import * as XLSX from 'xlsx';

const generateExcel = (data) => {
  const wb = XLSX.utils.book_new();

  const jsonToSheet = (json) => {
    if (!json || json.length === 0) {
      return XLSX.utils.aoa_to_sheet([[]]); 
    }
    return XLSX.utils.json_to_sheet(json);
  };


  if (data.visitiInfo && data.visitiInfo.length > 0) {
    const visitiInfoSheet = jsonToSheet(data.visitiInfo);
    XLSX.utils.book_append_sheet(wb, visitiInfoSheet, 'visitiInfo');
  }

  Object.keys(data).forEach((key) => {
    if (key !== 'visitiInfo') {
      const sectionData = data[key];
      if (sectionData && sectionData.length > 0) {
        const sheet = jsonToSheet(sectionData);
        XLSX.utils.book_append_sheet(wb, sheet, key);
      }
    }
  });


  XLSX.writeFile(wb, 'Routine_'+ new Date().toISOString() + '.xlsx');
};

export default generateExcel;

