import React, {Component} from 'react'
import {Card, Col, Descriptions, Image, Row, Table, Tag} from 'antd';
import {EditOutlined, FileImageOutlined} from '@ant-design/icons';

import {withRouter} from "react-router-dom";


import {getRoutingVisitById} from "../../../api/index";
import {connect} from "react-redux";
import moment from "moment/moment";

const {Meta} = Card;

const image_url = "https://storage.googleapis.com/dns_storage_v2/requestimages/";


class RoutineRequestDetails extends Component {

    columns = [
        {
            title: 'Section Name',
            dataIndex: 'sectionName',
            key: 'sectionName',
            render: (text, record) => {
                return (
                    <div>
                        <b style={{color: 'gray'}}>{text}</b>
                    </div>
                );
            },
            width: 150,
        },
        {
            title: 'Answer',
            dataIndex: 'answer',
            key: 'answer',
            render: (text, record) => {
                return (
                    <div>
                        <b>{text == 'Not Answered' ? <Tag color="red">{text}</Tag> : <Tag color="green">{text}</Tag>}</b>
                    </div>
                );
            },
            width: 200
        }

    ];

    constructor(props) {
        super(props);
        this.state = {
            itemDetails: [],
            newIteams: [],
            fullAccess: [],
            issuesListData : [],
            showModal: false,
            selectedItem: {},
            mainLoading: false,
        };

    }

    componentDidMount() {
        this.getData()
    }

    handleCloseFullModal = () => {
        this.setState({
            showModal: false
        })
    }

    showImage = (item) => {
        this.setState({
            showModal: true,
            selectedItem: item
        })
    }

    getData = async () => {
        this.setState({
            mainLoading: true
        })

        console.log('type');
        console.log(this.props.match.params.id);

        var formdata = new FormData();
        formdata.append('routingVisitId', this.props.match.params.id);
        var res = await getRoutingVisitById(formdata);


        if (res.success) {
            console.log('res')
            console.log(res)
            this.setState({
                fullAccess: res.data,
                itemDetails: res.data.checklists,
                issuesListData: res.data.checklists,
                newIteams: res.data.general,
                mainLoading: false
            })
        }


    }

    render() {

        const itemDetails = this.state.itemDetails;

        const nameId1 = itemDetails[0]?.item.find(item => parseInt(item.id) === 1)?.name;
        const nameId2 = itemDetails[0]?.item.find(item => parseInt(item.id) === 2)?.name;

        const checklistLength =
            this.state?.itemDetails[0]?.item[0]?.checklist?.length || 0;

        const data = [];

        for (let i = 0; i < checklistLength; i++) {
            data.push({
                key: this.state?.itemDetails[0]?.item[0]?.checklist[i]?.id,
                sectionName: this.state?.itemDetails[0]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[0]?.item[0]?.checklist[i]?.name : 'N/A',
                itemName: this.state?.itemDetails[0]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[0]?.item[0]?.checklist[i]?.name : 'N/A',
                answer: this.state?.itemDetails[0]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[0]?.item[0]?.checklist[i]?.answer : 'Not Answered',
            });
        }

        // Access Technologies Details Array Data
        const accessTechnologiesLength =
            this.state?.itemDetails[1]?.item[0]?.checklist?.length || 0;
        const accessTechnologiesData = [];

        for (let i = 0; i < accessTechnologiesLength; i++) {
            accessTechnologiesData.push({
                key: this.state?.itemDetails[1]?.item[0]?.checklist[i]?.id,
                sectionName: this.state?.itemDetails[1]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[1]?.item[0]?.checklist[i]?.name : 'N/A',
                itemName: this.state?.itemDetails[1]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[1]?.item[0]?.checklist[i]?.name : 'N/A',
                answer: this.state?.itemDetails[1]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[1]?.item[0]?.checklist[i]?.answer : 'Not Answered',
            });
        }

        // Main Power Section Array Data
        const mainPowerLength =
            this.state?.itemDetails[2]?.item[0]?.checklist?.length || 0;
        const mainPowerData = [];

        for (let i = 0; i < mainPowerLength; i++) {
            mainPowerData.push({
                key: this.state?.itemDetails[2]?.item[0]?.checklist[i]?.id,
                sectionName: this.state?.itemDetails[2]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[2]?.item[0]?.checklist[i]?.name : 'N/A',
                itemName: this.state?.itemDetails[2]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[2]?.item[0]?.checklist[i]?.name : 'N/A',
                answer: this.state?.itemDetails[2]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[2]?.item[0]?.checklist[i]?.answer : 'Not Answered',
            });
        }

        // Shared Gen Section Data
        const sharedGenSectionLength =
            this.state?.itemDetails[3]?.item[0]?.checklist?.length || 0;
        const sharedGenSectionData = [];

        for (let i = 0; i < sharedGenSectionLength; i++) {
            sharedGenSectionData.push({
                key: this.state?.itemDetails[3]?.item[0]?.checklist[i]?.id,
                sectionName: this.state?.itemDetails[3]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[3]?.item[0]?.checklist[i]?.name : 'N/A',
                itemName: this.state?.itemDetails[3]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[3]?.item[0]?.checklist[i]?.name : 'N/A',
                answer: this.state?.itemDetails[3]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[3]?.item[0]?.checklist[i]?.answer : 'Not Answered',
            });
        }

        // DC Power Share In
        const dcPowerShareInLength =
            this.state?.itemDetails[4]?.item[0]?.checklist?.length || 0;
        const dcPowerShareInData = [];

        for (let i = 0; i < dcPowerShareInLength; i++) {
            dcPowerShareInData.push({
                key: this.state?.itemDetails[4]?.item[0]?.checklist[i]?.id,
                sectionName: this.state?.itemDetails[4]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[4]?.item[0]?.checklist[i]?.name : 'N/A',
                itemName: this.state?.itemDetails[4]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[4]?.item[0]?.checklist[i]?.name : 'N/A',
                answer: this.state?.itemDetails[4]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[4]?.item[0]?.checklist[i]?.answer : 'Not Answered',
            });
        }

        // DC Load Data Section
        const dcLoadLength =
            this.state?.itemDetails[5]?.item[0]?.checklist?.length || 0;
        const dcLoadData = [];

        for (let i = 0; i < dcLoadLength; i++) {
            dcLoadData.push({
                key: this.state?.itemDetails[5]?.item[0]?.checklist[i]?.id,
                sectionName: this.state?.itemDetails[5]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[5]?.item[0]?.checklist[i]?.name : 'N/A',
                itemName: this.state?.itemDetails[5]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[5]?.item[0]?.checklist[i]?.name : 'N/A',
                answer: this.state?.itemDetails[5]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[5]?.item[0]?.checklist[i]?.answer : 'Not Answered',
            });
        }

        // Cabinet Details Data Section Genaral
        const cabinetLength =
            this.state?.itemDetails[6]?.item[0]?.checklist?.length || 0;
        const cabinetData = [];

        for (let i = 0; i < cabinetLength; i++) {
            cabinetData.push({
                key: this.state?.itemDetails[6]?.item[0]?.checklist[i]?.id,
                sectionName: this.state?.itemDetails[6]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[6]?.item[0]?.checklist[i]?.name : 'N/A',
                itemName: this.state?.itemDetails[6]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[6]?.item[0]?.checklist[i]?.name : 'N/A',
                answer: this.state?.itemDetails[6]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[6]?.item[0]?.checklist[i]?.answer : 'Not Answered',
            });
        }

        // Cabinet Set Info
        const cabinetSetLength =
            this.state?.itemDetails[7]?.item[0]?.checklist?.length || 0;
        const cabinetSetData = [];
        const cabinetItemLength = this.state?.itemDetails[7]?.item.length || 0;

        for (let j = 0; j < cabinetItemLength; j++) {
            for (let i = 0; i < cabinetSetLength; i++) {
                cabinetSetData.push({
                    key: this.state?.itemDetails[7]?.item[j]?.checklist[i]?.id,
                    itemIndex: j,
                    sectionName: this.state?.itemDetails[7]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[7]?.item[j]?.checklist[i]?.name : 'N/A',
                    itemName: this.state?.itemDetails[7]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[7]?.item[j]?.checklist[i]?.name : 'N/A',
                    answer: this.state?.itemDetails[7]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[7]?.item[j]?.checklist[i]?.answer : 'Not Answered',
                });
            }
        }

        const groupedCabinetSetData = cabinetSetData.reduce((acc, currentItem) => {
            const {itemIndex, ...rest} = currentItem;
            acc[itemIndex] = acc[itemIndex] || [];
            acc[itemIndex].push(rest);
            return acc;
        }, {});

        const tables = Object.entries(groupedCabinetSetData).map(([itemIndex, data]) => (
            <Col>
                <div key={itemIndex} style={{
                    padding: 10,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: '0px 0px 10px 0px #0000001a',
                    marginTop: 20,
                    width: '100%',
                }}>
                    <h5><b>Cabinet -0{itemIndex} Details</b></h5>
                    <Table
                        dataSource={data}
                        columns={this.columns}
                        bordered
                        pagination={false}
                        size="small"
                    />
                </div>
            </Col>
        ));

        // Rectifier Common Section
        const rectifierCommonLength =
            this.state?.itemDetails[8]?.item[0]?.checklist?.length || 0;
        const rectifierCommonData = [];
        const rectifierItemLen = this.state?.itemDetails[8]?.item.length || 0;

        for (let j = 0; j < rectifierItemLen; j++) {
            for (let i = 0; i < rectifierCommonLength; i++) {
                rectifierCommonData.push({
                    key: this.state?.itemDetails[8]?.item[j]?.checklist[i]?.id,
                    itemIndex: j,
                    sectionName: this.state?.itemDetails[8]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[8]?.item[j]?.checklist[i]?.name : 'N/A',
                    itemName: this.state?.itemDetails[8]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[8]?.item[j]?.checklist[i]?.name : 'N/A',
                    answer: this.state?.itemDetails[8]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[8]?.item[j]?.checklist[i]?.answer : 'Not Answered',
                });
            }
        }

        const groupedRectifierCommonData = rectifierCommonData.reduce((acc, currentItem) => {
            const {itemIndex, ...rest} = currentItem;
            acc[itemIndex] = acc[itemIndex] || [];
            acc[itemIndex].push(rest);
            return acc;
        }, {});

        const rectifierTables = Object.entries(groupedRectifierCommonData).map(([itemIndex, data]) => (
            <Col>
                <div key={itemIndex} style={{
                    padding: 10,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: '0px 0px 10px 0px #0000001a',
                    marginTop: 20,
                    width: '100%',
                }}>
                    <h5><b>Rectifier -0{itemIndex} Details</b></h5>
                    <Table
                        dataSource={data}
                        columns={this.columns}
                        bordered
                        pagination={false}
                        size="small"
                    />
                </div>
            </Col>
        ));

        // DCDU Section Data Set
        const dcduLength =
            this.state?.itemDetails[9]?.item[0]?.checklist?.length || 0;
        const dcduData = [];
        const dcduItemLength = this.state?.itemDetails[9]?.item.length || 0;

        for (let j = 0; j < dcduItemLength; j++) {
            for (let i = 0; i < dcduLength; i++) {
                dcduData.push({
                    key: this.state?.itemDetails[9]?.item[j]?.checklist[i]?.id,
                    itemIndex: j,
                    sectionName: this.state?.itemDetails[9]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[9]?.item[j]?.checklist[i]?.name : 'N/A',
                    itemName: this.state?.itemDetails[9]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[9]?.item[j]?.checklist[i]?.name : 'N/A',
                    answer: this.state?.itemDetails[9]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[9]?.item[j]?.checklist[i]?.answer : 'Not Answered',
                });
            }
        }

        const groupedDcduData = dcduData.reduce((acc, currentItem) => {
            const {itemIndex, ...rest} = currentItem;
            acc[itemIndex] = acc[itemIndex] || [];
            acc[itemIndex].push(rest);
            return acc;
        }, {});

        const dcduTables = Object.entries(groupedDcduData).map(([itemIndex, data]) => (
            <Col>
                <div key={itemIndex} style={{
                    padding: 10,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: '0px 0px 10px 0px #0000001a',
                    marginTop: 20,
                    width: '100%',
                }}>
                    <h5><b>DCDU - 0{itemIndex} Details</b></h5>
                    <Table
                        dataSource={data}
                        columns={this.columns}
                        bordered
                        pagination={false}
                        size="small"
                    />
                </div>
            </Col>
        ));

        // Battery Bank Section Data Set 
        const batteryBankLength =
            this.state?.itemDetails[10]?.item[0]?.checklist?.length || 0;
        const batteryBankData = [];
        const batteryBankItemLength = this.state?.itemDetails[10]?.item.length || 0;

        for (let j = 0; j < batteryBankItemLength; j++) {
            for (let i = 0; i < batteryBankLength; i++) {
                batteryBankData.push({
                    key: this.state?.itemDetails[10]?.item[j]?.checklist[i]?.id,
                    itemIndex: j,
                    sectionName: this.state?.itemDetails[10]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[10]?.item[j]?.checklist[i]?.name : 'N/A',
                    itemName: this.state?.itemDetails[10]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[10]?.item[j]?.checklist[i]?.name : 'N/A',
                    answer: this.state?.itemDetails[10]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[10]?.item[j]?.checklist[i]?.answer : 'Not Answered',
                });
            }
        }

        const groupedBatteryBankData = batteryBankData.reduce((acc, currentItem) => {
            const {itemIndex, ...rest} = currentItem;
            acc[itemIndex] = acc[itemIndex] || [];
            acc[itemIndex].push(rest);
            return acc;
        }, {});

        const batteryBankTables = Object.entries(groupedBatteryBankData).map(([itemIndex, data]) => (
            <Col>
                <div key={itemIndex} style={{
                    padding: 10,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: '0px 0px 10px 0px #0000001a',
                    marginTop: 20,
                    width: '100%',
                }}>
                    <h5><b>Battery Bank - 0{itemIndex} Details</b></h5>
                    <Table
                        dataSource={data}
                        columns={this.columns}
                        bordered
                        pagination={false}
                        size="small"
                    />
                </div>
            </Col>
        ));

        // ID Battery Rack Data 
        const idBatteryRackLength =
            this.state?.itemDetails[11]?.item[0]?.checklist?.length || 0;
        const idBatteryRackData = [];
        const idBatteryRackItemLength = this.state?.itemDetails[11]?.item.length || 0;

        for (let j = 0; j < idBatteryRackItemLength; j++) {
            for (let i = 0; i < idBatteryRackLength; i++) {
                idBatteryRackData.push({
                    key: this.state?.itemDetails[11]?.item[j]?.checklist[i]?.id,
                    itemIndex: j,
                    sectionName: this.state?.itemDetails[11]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[11]?.item[j]?.checklist[i]?.name : 'N/A',
                    itemName: this.state?.itemDetails[11]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[11]?.item[j]?.checklist[i]?.name : 'N/A',
                    answer: this.state?.itemDetails[11]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[11]?.item[j]?.checklist[i]?.answer : 'Not Answered',
                });
            }
        }

        const groupedIdBatteryRackData = idBatteryRackData.reduce((acc, currentItem) => {
            const {itemIndex, ...rest} = currentItem;
            acc[itemIndex] = acc[itemIndex] || [];
            acc[itemIndex].push(rest);
            return acc;
        }, {});

        const idBatteryRackTables = Object.entries(groupedIdBatteryRackData).map(([itemIndex, data]) => (
            <Col>
                <div key={itemIndex} style={{
                    padding: 10,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: '0px 0px 10px 0px #0000001a',
                    marginTop: 20,
                    width: '100%',
                }}>
                    <h5><b>ID Battery Rack - 0{itemIndex} Details</b></h5>
                    <Table
                        dataSource={data}
                        columns={this.columns}
                        bordered
                        pagination={false}
                        size="small"
                    />
                </div>
            </Col>
        ));

        // Genarator Data Set
        const generatorLength =
            this.state?.itemDetails[12]?.item[0]?.checklist?.length || 0;
        const generatorData = [];
        const generatorItemLength = this.state?.itemDetails[12]?.item.length || 0;

        for (let j = 0; j < generatorItemLength; j++) {
            for (let i = 0; i < generatorLength; i++) {
                generatorData.push({
                    key: this.state?.itemDetails[12]?.item[j]?.checklist[i]?.id,
                    itemIndex: j,
                    sectionName: this.state?.itemDetails[12]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[12]?.item[j]?.checklist[i]?.name : 'N/A',
                    itemName: this.state?.itemDetails[12]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[12]?.item[j]?.checklist[i]?.name : 'N/A',
                    answer: this.state?.itemDetails[12]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[12]?.item[j]?.checklist[i]?.answer : 'Not Answered',
                });
            }
        }

        const groupedGeneratorData = generatorData.reduce((acc, currentItem) => {
            const {itemIndex, ...rest} = currentItem;
            acc[itemIndex] = acc[itemIndex] || [];
            acc[itemIndex].push(rest);
            return acc;
        }, {});

        const generatorTables = Object.entries(groupedGeneratorData).map(([itemIndex, data]) => (
            <Col>
                <div key={itemIndex} style={{
                    padding: 10,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: '0px 0px 10px 0px #0000001a',
                    marginTop: 20,
                    width: '100%',
                }}>
                    <h5><b>Generator - 0{itemIndex} Details</b></h5>
                    <Table
                        dataSource={data}
                        columns={this.columns}
                        bordered
                        pagination={false}
                        size="small"
                    />
                </div>
            </Col>
        ));

        // Gen Hut Data List Implementation 
        const genHutLength = this.state?.itemDetails[13]?.item[0]?.checklist?.length || 0;
        const genHutData = [];
        const genHutItemLength = this.state?.itemDetails[13]?.item.length || 0;

        for (let j = 0; j < genHutItemLength; j++) {
            for (let i = 0; i < genHutLength; i++) {
                genHutData.push({
                    key: this.state?.itemDetails[13]?.item[j]?.checklist[i]?.id,
                    itemIndex: j,
                    sectionName: this.state?.itemDetails[13]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[13]?.item[j]?.checklist[i]?.name : 'N/A',
                    itemName: this.state?.itemDetails[13]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[13]?.item[j]?.checklist[i]?.name : 'N/A',
                    answer: this.state?.itemDetails[13]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[13]?.item[j]?.checklist[i]?.answer : 'Not Answered',
                });
            }
        }

        const groupedGenHutData = genHutData.reduce((acc, currentItem) => {
            const {itemIndex, ...rest} = currentItem;
            acc[itemIndex] = acc[itemIndex] || [];
            acc[itemIndex].push(rest);
            return acc;
        }, {});

        const genHutTables = Object.entries(groupedGenHutData).map(([itemIndex, data]) => (
            <Col>
                <div key={itemIndex} style={{
                    padding: 10,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: '0px 0px 10px 0px #0000001a',
                    marginTop: 20,
                    width: '100%',
                }}>
                    {/* <h4 style={{color: 'gray'}}><b>Gen Hut - 0{itemIndex}</b></h4> */}
                    <h5><b>Gen Hut - 0{itemIndex} Details</b></h5>
                    <Table
                        dataSource={data}
                        columns={this.columns}
                        bordered
                        pagination={false}
                        size="small"
                    />
                </div>
            </Col>
        ));

        // ATS Data List Implementation
        const atsLength = this.state?.itemDetails[14]?.item[0]?.checklist?.length || 0;
        const atsData = [];
        const atsItemLength = this.state?.itemDetails[14]?.item.length || 0;

        for (let j = 0; j < atsItemLength; j++) {
            for (let i = 0; i < atsLength; i++) {
                atsData.push({
                    key: this.state?.itemDetails[14]?.item[j]?.checklist[i]?.id,
                    itemIndex: j,
                    sectionName: this.state?.itemDetails[14]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[14]?.item[j]?.checklist[i]?.name : 'N/A',
                    itemName: this.state?.itemDetails[14]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[14]?.item[j]?.checklist[i]?.name : 'N/A',
                    answer: this.state?.itemDetails[14]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[14]?.item[j]?.checklist[i]?.answer : 'Not Answered',
                });
            }
        }

        const groupedAtsData = atsData.reduce((acc, currentItem) => {
            const {itemIndex, ...rest} = currentItem;
            acc[itemIndex] = acc[itemIndex] || [];
            acc[itemIndex].push(rest);
            return acc;
        }, {});

        const atsTables = Object.entries(groupedAtsData).map(([itemIndex, data]) => (
            <Col>
                <div key={itemIndex} style={{
                    padding: 10,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: '0px 0px 10px 0px #0000001a',
                    marginTop: 20,
                    width: '100%',
                }}>
                    {/* <h4 style={{color: 'gray'}}><b>ATS - 0{itemIndex}</b></h4> */}
                    <h5><b>ATS - 0{itemIndex} Details</b></h5>
                    <Table
                        dataSource={data}
                        columns={this.columns}
                        bordered
                        pagination={false}
                        size="small"
                    />
                </div>
            </Col>
        ));

        // AC Data List Implemenattion
        const acLength = this.state?.itemDetails[15]?.item[0]?.checklist?.length || 0;
        const acData = [];
        const acItemLength = this.state?.itemDetails[15]?.item.length || 0;

        for (let j = 0; j < acItemLength; j++) {
            for (let i = 0; i < acLength; i++) {
                acData.push({
                    key: this.state?.itemDetails[15]?.item[j]?.checklist[i]?.id,
                    itemIndex: j,
                    sectionName: this.state?.itemDetails[15]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[15]?.item[j]?.checklist[i]?.name : 'N/A',
                    itemName: this.state?.itemDetails[15]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[15]?.item[j]?.checklist[i]?.name : 'N/A',
                    answer: this.state?.itemDetails[15]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[15]?.item[j]?.checklist[i]?.answer : 'Not Answered',
                });
            }
        }

        const groupedAcData = acData.reduce((acc, currentItem) => {
            const {itemIndex, ...rest} = currentItem;
            acc[itemIndex] = acc[itemIndex] || [];
            acc[itemIndex].push(rest);
            return acc;
        }, {});

        const acTables = Object.entries(groupedAcData).map(([itemIndex, data]) => (
            <Col>
                <div key={itemIndex} style={{
                    padding: 10,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: '0px 0px 10px 0px #0000001a',
                    marginTop: 20,
                    width: '100%',
                }}>
                    <h5><b>AC - 0{itemIndex} Details</b></h5>
                    <Table
                        dataSource={data}
                        columns={this.columns}
                        bordered
                        pagination={false}
                        size="small"
                    />
                </div>
            </Col>
        ));

        // Cabin Data List Implementation 
        const cabinLength = this.state?.itemDetails[16]?.item[0]?.checklist?.length || 0;
        const cabinData = [];
        const cabinItemLength = this.state?.itemDetails[16]?.item.length || 0;

        for (let j = 0; j < cabinItemLength; j++) {
            for (let i = 0; i < cabinLength; i++) {
                cabinData.push({
                    key: this.state?.itemDetails[16]?.item[j]?.checklist[i]?.id,
                    itemIndex: j,
                    sectionName: this.state?.itemDetails[16]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[16]?.item[j]?.checklist[i]?.name : 'N/A',
                    itemName: this.state?.itemDetails[16]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[16]?.item[j]?.checklist[i]?.name : 'N/A',
                    answer: this.state?.itemDetails[16]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[16]?.item[j]?.checklist[i]?.answer : 'Not Answered',
                });
            }
        }

        const groupedCabinData = cabinData.reduce((acc, currentItem) => {
            const {itemIndex, ...rest} = currentItem;
            acc[itemIndex] = acc[itemIndex] || [];
            acc[itemIndex].push(rest);
            return acc;
        }, {});

        const cabinTables = Object.entries(groupedCabinData).map(([itemIndex, data]) => (
            <Col>
                <div key={itemIndex} style={{
                    padding: 10,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: '0px 0px 10px 0px #0000001a',
                    marginTop: 20,
                    width: '100%',
                }}>
                    {/* <h4 style={{color: 'gray'}}><b>Cabin - 0{itemIndex}</b></h4> */}
                    <h5><b>Cabin - 0{itemIndex} Details</b></h5>
                    <Table
                        dataSource={data}
                        columns={this.columns}
                        bordered
                        pagination={false}
                        size="small"
                    />
                </div>
            </Col>
        ));

        // FCB Data List Implementation
        const fcbLength = this.state?.itemDetails[17]?.item[0]?.checklist?.length || 0;
        const fcbData = [];
        const fcbItemLength = this.state?.itemDetails[17]?.item.length || 0;

        for (let j = 0; j < fcbItemLength; j++) {
            for (let i = 0; i < fcbLength; i++) {
                fcbData.push({
                    key: this.state?.itemDetails[17]?.item[j]?.checklist[i]?.id,
                    itemIndex: j,
                    sectionName: this.state?.itemDetails[17]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[17]?.item[j]?.checklist[i]?.name : 'N/A',
                    itemName: this.state?.itemDetails[17]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[17]?.item[j]?.checklist[i]?.name : 'N/A',
                    answer: this.state?.itemDetails[17]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[17]?.item[j]?.checklist[i]?.answer : 'Not Answered',
                });
            }
        }

        const groupedFcbData = fcbData.reduce((acc, currentItem) => {
            const {itemIndex, ...rest} = currentItem;
            acc[itemIndex] = acc[itemIndex] || [];
            acc[itemIndex].push(rest);
            return acc;
        }, {});

        const fcbTables = Object.entries(groupedFcbData).map(([itemIndex, data]) => (
            <Col>
                <div key={itemIndex} style={{
                    padding: 10,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: '0px 0px 10px 0px #0000001a',
                    marginTop: 20,
                    width: '100%',
                }}>
                    {/* <h4 style={{color: 'gray'}}><b>FCB - 0{itemIndex}</b></h4> */}
                    <h5><b>FCB - 0{itemIndex} Details</b></h5>
                    <Table
                        dataSource={data}
                        columns={this.columns}
                        bordered
                        pagination={false}
                        size="small"
                    />
                </div>
            </Col>
        ));

        // SPD Data List Implementation
        const spdLength = this.state?.itemDetails[18]?.item[0]?.checklist?.length || 0;
        const spdData = [];
        const spdItemLength = this.state?.itemDetails[18]?.item.length || 0;

        for (let j = 0; j < spdItemLength; j++) {
            for (let i = 0; i < spdLength; i++) {
                spdData.push({
                    key: this.state?.itemDetails[18]?.item[j]?.checklist[i]?.id,
                    itemIndex: j,
                    sectionName: this.state?.itemDetails[18]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[18]?.item[j]?.checklist[i]?.name : 'N/A',
                    itemName: this.state?.itemDetails[18]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[18]?.item[j]?.checklist[i]?.name : 'N/A',
                    answer: this.state?.itemDetails[18]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[18]?.item[j]?.checklist[i]?.answer : 'Not Answered',
                });
            }
        }

        const groupedSpdData = spdData.reduce((acc, currentItem) => {
            const {itemIndex, ...rest} = currentItem;
            acc[itemIndex] = acc[itemIndex] || [];
            acc[itemIndex].push(rest);
            return acc;
        }, {});

        const spdTables = Object.entries(groupedSpdData).map(([itemIndex, data]) => (
            <Col>
                <div key={itemIndex} style={{
                    padding: 10,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: '0px 0px 10px 0px #0000001a',
                    marginTop: 20,
                    width: '100%',
                }}>
                    {/* <h4 style={{color: 'gray'}}><b>SPD - 0{itemIndex}</b></h4> */}
                    <h5><b>SPD - 0{itemIndex} Details</b></h5>
                    <Table
                        dataSource={data}
                        columns={this.columns}
                        bordered
                        pagination={false}
                        size="small"
                    />
                </div>
            </Col>
        ));

        // Solar Data Implemnantion List
        const solarLength = this.state?.itemDetails[19]?.item[0]?.checklist?.length || 0;
        const solarData = [];
        const solarItemLength = this.state?.itemDetails[19]?.item.length || 0;

        for (let j = 0; j < solarItemLength; j++) {
            for (let i = 0; i < solarLength; i++) {
                solarData.push({
                    key: this.state?.itemDetails[19]?.item[j]?.checklist[i]?.id,
                    itemIndex: j,
                    sectionName: this.state?.itemDetails[19]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[19]?.item[j]?.checklist[i]?.name : 'N/A',
                    itemName: this.state?.itemDetails[19]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[19]?.item[j]?.checklist[i]?.name : 'N/A',
                    answer: this.state?.itemDetails[19]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[19]?.item[j]?.checklist[i]?.answer : 'Not Answered',
                });
            }
        }

        const groupedSolarData = solarData.reduce((acc, currentItem) => {
            const {itemIndex, ...rest} = currentItem;
            acc[itemIndex] = acc[itemIndex] || [];
            acc[itemIndex].push(rest);
            return acc;
        }, {});

        const solarTables = Object.entries(groupedSolarData).map(([itemIndex, data]) => (
            <Col>
                <div key={itemIndex} style={{
                    padding: 10,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: '0px 0px 10px 0px #0000001a',
                    marginTop: 20,
                    width: '100%',
                }}>
                    {/* <h4 style={{color: 'gray'}}><b>Solar - 0{itemIndex}</b></h4> */}
                    <h5><b>Solar - 0{itemIndex} Details</b></h5>
                    <Table
                        dataSource={data}
                        columns={this.columns}
                        bordered
                        pagination={false}
                        size="small"
                    />
                </div>
            </Col>
        ));

        // Renewable Energy General Data Set
        const renewableLength = this.state?.itemDetails[20]?.item[0]?.checklist?.length || 0;
        const renewableData = [];
        const renewableItemLength = this.state?.itemDetails[20]?.item.length || 0;

        for (let j = 0; j < renewableItemLength; j++) {
            for (let i = 0; i < renewableLength; i++) {
                renewableData.push({
                    key: this.state?.itemDetails[20]?.item[j]?.checklist[i]?.id,
                    itemIndex: j,
                    sectionName: this.state?.itemDetails[20]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[20]?.item[j]?.checklist[i]?.name : 'N/A',
                    itemName: this.state?.itemDetails[20]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[20]?.item[j]?.checklist[i]?.name : 'N/A',
                    answer: this.state?.itemDetails[20]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[20]?.item[j]?.checklist[i]?.answer : 'Not Answered',
                });
            }
        }

        const groupedRenewableData = renewableData.reduce((acc, currentItem) => {
            const {itemIndex, ...rest} = currentItem;
            acc[itemIndex] = acc[itemIndex] || [];
            acc[itemIndex].push(rest);
            return acc;
        }, {});

        const renewableTables = Object.entries(groupedRenewableData).map(([itemIndex, data]) => (
            <Col>
                <div key={itemIndex} style={{
                    padding: 10,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: '0px 0px 10px 0px #0000001a',
                    marginTop: 20,
                    width: '100%',
                }}>
                    {/* <h4 style={{color: 'gray'}}><b>Renewable Enargy</b></h4> */}
                    <h5><b>Renewable Energy Details</b></h5>
                    <Table
                        dataSource={data}
                        columns={this.columns}
                        bordered
                        pagination={false}
                        size="small"
                    />
                </div>
            </Col>
        ));

        // Wind Data Set Implementation Goes Here
        const windLength = this.state?.itemDetails[21]?.item[0]?.checklist?.length || 0;
        const windData = [];
        const windItemLength = this.state?.itemDetails[21]?.item.length || 0;

        for (let j = 0; j < windItemLength; j++) {
            for (let i = 0; i < windLength; i++) {
                windData.push({
                    key: this.state?.itemDetails[21]?.item[j]?.checklist[i]?.id,
                    itemIndex: j,
                    sectionName: this.state?.itemDetails[21]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[21]?.item[j]?.checklist[i]?.name : 'N/A',
                    itemName: this.state?.itemDetails[21]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[21]?.item[j]?.checklist[i]?.name : 'N/A',
                    answer: this.state?.itemDetails[21]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[21]?.item[j]?.checklist[i]?.answer : 'Not Answered',
                });
            }
        }

        const groupedWindData = windData.reduce((acc, currentItem) => {
            const {itemIndex, ...rest} = currentItem;
            acc[itemIndex] = acc[itemIndex] || [];
            acc[itemIndex].push(rest);
            return acc;
        }, {});

        const windTables = Object.entries(groupedWindData).map(([itemIndex, data]) => (
            <Col>
                <div key={itemIndex} style={{
                    padding: 10,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: '0px 0px 10px 0px #0000001a',
                    marginTop: 20,
                    width: '100%',
                }}>
                    {/* <h4 style={{color: 'gray'}}><b>Wind - 0{itemIndex}</b></h4> */}
                    <h5><b>Wind - 0{itemIndex} Details</b></h5>
                    <Table
                        dataSource={data}
                        columns={this.columns}
                        bordered
                        pagination={false}
                        size="small"
                    />
                </div>
            </Col>
        ));

        // Shared Out Operators Data Section
        const sharedOutOperatorsLength = this.state?.itemDetails[22]?.item[0]?.checklist?.length || 0;
        const sharedOutOperatorsData = [];
        const sharedOutOperatorsItemLength = this.state?.itemDetails[22]?.item.length || 0;

        for (let j = 0; j < sharedOutOperatorsItemLength; j++) {
            for (let i = 0; i < sharedOutOperatorsLength; i++) {
                sharedOutOperatorsData.push({
                    key: this.state?.itemDetails[22]?.item[j]?.checklist[i]?.id,
                    itemIndex: j,
                    sectionName: this.state?.itemDetails[22]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[22]?.item[j]?.checklist[i]?.name : 'N/A',
                    itemName: this.state?.itemDetails[22]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[22]?.item[j]?.checklist[i]?.name : 'N/A',
                    answer: this.state?.itemDetails[22]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[22]?.item[j]?.checklist[i]?.answer : 'Not Answered',
                });
            }
        }

        const groupedSharedOutOperatorsData = sharedOutOperatorsData.reduce((acc, currentItem) => {
            const {itemIndex, ...rest} = currentItem;
            acc[itemIndex] = acc[itemIndex] || [];
            acc[itemIndex].push(rest);
            return acc;
        }, {});

        const sharedOutOperatorsTables = Object.entries(groupedSharedOutOperatorsData).map(([itemIndex, data]) => (
            <Col>
                <div key={itemIndex} style={{
                    padding: 10,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: '0px 0px 10px 0px #0000001a',
                    marginTop: 20,
                    width: '100%',
                }}>
                    {/* <h4 style={{color: 'gray'}}><b>Shared Out Operators - 0{itemIndex}</b></h4> */}
                    <h5><b>Shared Out Operators - 0{itemIndex} Details</b></h5>
                    <Table
                        dataSource={data}
                        columns={this.columns}
                        bordered
                        pagination={false}
                        size="small"
                    />
                </div>
            </Col>
        ));

        // Shared Out Operators General Data Section
        const sharedOutOperatorsGeneralLength = this.state?.itemDetails[23]?.item[0]?.checklist?.length || 0;
        const sharedOutOperatorsGeneralData = [];
        const sharedOutOperatorsGeneralItemLength = this.state?.itemDetails[23]?.item.length || 0;

        for (let j = 0; j < sharedOutOperatorsGeneralItemLength; j++) {
            for (let i = 0; i < sharedOutOperatorsGeneralLength; i++) {
                sharedOutOperatorsGeneralData.push({
                    key: this.state?.itemDetails[23]?.item[j]?.checklist[i]?.id,
                    itemIndex: j,
                    sectionName: this.state?.itemDetails[23]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[23]?.item[j]?.checklist[i]?.name : 'N/A',
                    itemName: this.state?.itemDetails[23]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[23]?.item[j]?.checklist[i]?.name : 'N/A',
                    answer: this.state?.itemDetails[23]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[23]?.item[j]?.checklist[i]?.answer : 'Not Answered',
                });
            }
        }

        const groupedSharedOutOperatorsGeneralData = sharedOutOperatorsGeneralData.reduce((acc, currentItem) => {
            const {itemIndex, ...rest} = currentItem;
            acc[itemIndex] = acc[itemIndex] || [];
            acc[itemIndex].push(rest);
            return acc;
        }, {});

        const sharedOutOperatorsGeneralTables = Object.entries(groupedSharedOutOperatorsGeneralData).map(([itemIndex, data]) => (
            <Col>
                <div key={itemIndex} style={{
                    padding: 10,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: '0px 0px 10px 0px #0000001a',
                    marginTop: 20,
                    width: '100%',
                }}>
                    {/* <h4 style={{color: 'gray'}}><b>Shared Out Operators General</b></h4> */}
                    <h5><b>Shared Out Operators General Details</b></h5>
                    <Table
                        dataSource={data}
                        columns={this.columns}
                        bordered
                        pagination={false}
                        size="small"
                    />
                </div>
            </Col>
        ));

        // PG Data Set Implementation 
        const pgLength = this.state?.itemDetails[24]?.item[0]?.checklist?.length || 0;
        const pgData = [];
        const pgItemLength = this.state?.itemDetails[24]?.item.length || 0;

        for (let j = 0; j < pgItemLength; j++) {
            for (let i = 0; i < pgLength; i++) {
                pgData.push({
                    key: this.state?.itemDetails[24]?.item[j]?.checklist[i]?.id,
                    itemIndex: j,
                    sectionName: this.state?.itemDetails[24]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[24]?.item[j]?.checklist[i]?.name : 'N/A',
                    itemName: this.state?.itemDetails[24]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[24]?.item[j]?.checklist[i]?.name : 'N/A',
                    answer: this.state?.itemDetails[24]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[24]?.item[j]?.checklist[i]?.answer : 'Not Answered',
                });
            }
        }

        const groupedPgData = pgData.reduce((acc, currentItem) => {
            const {itemIndex, ...rest} = currentItem;
            acc[itemIndex] = acc[itemIndex] || [];
            acc[itemIndex].push(rest);
            return acc;
        }, {});

        const pgTables = Object.entries(groupedPgData).map(([itemIndex, data]) => (
            <Col>
                <div key={itemIndex} style={{
                    padding: 10,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: '0px 0px 10px 0px #0000001a',
                    marginTop: 20,
                    width: '100%',
                }}>
                    {/* <h4 style={{color: 'gray'}}><b>PG Possibility</b></h4> */}
                    <h5><b>PG Possibility Details</b></h5>
                    <Table
                        dataSource={data}
                        columns={this.columns}
                        bordered
                        pagination={false}
                        size="small"
                    />
                </div>
            </Col>
        ));

        // Eathing Data Section
        const earthingLength = this.state?.itemDetails[25]?.item[0]?.checklist?.length || 0;
        const earthingData = [];
        const earthingItemLength = this.state?.itemDetails[25]?.item.length || 0;

        for (let j = 0; j < earthingItemLength; j++) {
            for (let i = 0; i < earthingLength; i++) {
                earthingData.push({
                    key: this.state?.itemDetails[25]?.item[j]?.checklist[i]?.id,
                    itemIndex: j,
                    sectionName: this.state?.itemDetails[25]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[25]?.item[j]?.checklist[i]?.name : 'N/A',
                    itemName: this.state?.itemDetails[25]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[25]?.item[j]?.checklist[i]?.name : 'N/A',
                    answer: this.state?.itemDetails[25]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[25]?.item[j]?.checklist[i]?.answer : 'Not Answered',
                });
            }
        }

        const groupedEarthingData = earthingData.reduce((acc, currentItem) => {
            const {itemIndex, ...rest} = currentItem;
            acc[itemIndex] = acc[itemIndex] || [];
            acc[itemIndex].push(rest);
            return acc;
        }, {});

        const earthingTables = Object.entries(groupedEarthingData).map(([itemIndex, data]) => (
            <Col>
                <div key={itemIndex} style={{
                    padding: 10,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: '0px 0px 10px 0px #0000001a',
                    marginTop: 20,
                    width: '100%',
                }}>
                    {/* <h4 style={{color: 'gray'}}><b>Earthing</b></h4> */}
                    <h5><b>Earthing Details</b></h5>
                    <Table
                        dataSource={data}
                        columns={this.columns}
                        bordered
                        pagination={false}
                        size="small"
                    />
                </div>
            </Col>
        ));

        // Bulk Tank Details
        const bulkTankLength = this.state?.itemDetails[26]?.item[0]?.checklist?.length || 0;
        const bulkTankData = [];
        const bulkTankItemLength = this.state?.itemDetails[26]?.item.length || 0;

        for (let j = 0; j < bulkTankItemLength; j++) {
            for (let i = 0; i < bulkTankLength; i++) {
                bulkTankData.push({
                    key: this.state?.itemDetails[26]?.item[j]?.checklist[i]?.id,
                    itemIndex: j,
                    sectionName: this.state?.itemDetails[26]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[26]?.item[j]?.checklist[i]?.name : 'N/A',
                    itemName: this.state?.itemDetails[26]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[26]?.item[j]?.checklist[i]?.name : 'N/A',
                    answer: this.state?.itemDetails[26]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[26]?.item[j]?.checklist[i]?.answer : 'Not Answered',
                });
            }
        }

        const groupedBulkTankData = bulkTankData.reduce((acc, currentItem) => {
            const {itemIndex, ...rest} = currentItem;
            acc[itemIndex] = acc[itemIndex] || [];
            acc[itemIndex].push(rest);
            return acc;
        }, {});

        const bulkTankTables = Object.entries(groupedBulkTankData).map(([itemIndex, data]) => (
            <Col>
                <div key={itemIndex} style={{
                    padding: 10,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: '0px 0px 10px 0px #0000001a',
                    marginTop: 20,
                    width: '100%',
                }}>
                    {/* <h4 style={{color: 'gray'}}><b>Bulk Tank - {itemIndex}</b></h4> */}
                    <h5><b>Bulk Tank - {itemIndex} Details</b></h5>
                    <Table
                        dataSource={data}
                        columns={this.columns}
                        bordered
                        pagination={false}
                        size="small"
                    />
                </div>
            </Col>
        ));

        // Images Preview Section
        const imageAlbumLength = this.state?.itemDetails[27]?.item[0]?.checklist?.length || 0;
        const images = [];
        const imageItemLength = this.state?.itemDetails[27]?.item.length || 0;

        for (let j = 0; j < imageItemLength; j++) {
            for (let i = 0; i < imageAlbumLength; i++) {
                images.push({
                    key: this.state?.itemDetails[27]?.item[j]?.checklist[i]?.id,
                    itemIndex: j,
                    sectionName: this.state?.itemDetails[27]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[27]?.item[j]?.checklist[i]?.name : 'N/A',
                    itemName: this.state?.itemDetails[27]?.item[0]?.checklist[i]?.name ? this.state?.itemDetails[27]?.item[j]?.checklist[i]?.name : 'N/A',
                    answer: this.state?.itemDetails[27]?.item[0]?.checklist[i]?.answer ? this.state?.itemDetails[27]?.item[j]?.checklist[i]?.answer : 'Not Answered',
                    url: this.state?.itemDetails[27]?.item[0]?.checklist[i]?.answer ? image_url + this.state?.itemDetails[27]?.item[j]?.checklist[i]?.answer : 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/330px-No-Image-Placeholder.svg.png?20200912122019',
                });
            }
        }

        const groupImageAlbumData = images.reduce((acc, currentItem) => {
            const {itemIndex, ...rest} = currentItem;
            acc[itemIndex] = acc[itemIndex] || [];
            acc[itemIndex].push(rest);
            return acc;
        }, {});

        const imagesPreview = Object.entries(groupImageAlbumData).map(
            ([itemIndex, data]) => (
                <div
                    key={itemIndex}
                    style={{
                        padding: 10,
                        backgroundColor: "white",
                        borderRadius: 5,
                        boxShadow: "0px 0px 10px 0px #0000001a",
                        marginTop: 20,
                        width: "100%",
                    }}
                >
                    <h5><b>Images Capture </b></h5>
                    <Row gutter={[16, 16]}>
                        {data.map((image, index) => (
                            <Col span={6} key={index}>
                                {/*<div style={{padding: 10, backgroundColor: '#f8f9fa', borderRadius: 10}}>*/}
                                {/*    <div style={{width: 250, height: 300}}>*/}
                                {/*        <>*/}
                                {/*            <Image*/}
                                {/*                width={250}*/}
                                {/*                height={300}*/}
                                {/*                style={{borderRadius: 10, cursor: 'pointer'}}*/}
                                {/*                src={image.answer}*/}
                                {/*                placeholder={*/}
                                {/*                    <Image preview={true}*/}
                                {/*                           src={image.answer}*/}
                                {/*                           width={200}*/}
                                {/*                           height={400}/>*/}
                                {/*                }*/}
                                {/*            />*/}
                                {/*        </>*/}
                                {/*    </div>*/}
                                {/*    <Descriptions style={{marginLeft: 10, marginTop: 10}} size="small">*/}
                                {/*        <Descriptions.Item label="Capture"><b>{image.itemName}</b></Descriptions.Item>*/}
                                {/*    </Descriptions>*/}
                                {/*</div>*/}
                                <div key={image.key} style={{ margin: '10px' }}>
                                    <h6 style={{textTransform: 'capitalize', fontSize: 12, fontWeight: 'bold'
                                    }}> <FileImageOutlined /> {image.itemName.replace(/_/g, ' ')}</h6>
                                    <Image
                                        width={200}
                                        height={200}
                                        src={(image.url ? image.url : "error")}
                                        style={{ cursor: 'pointer', borderRadius: 10 }}
                                    />
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            )
        );

        const physicalObservetionList = [

            {
                title: 'Observation Name',
                dataIndex: 'name',
                key: 'name',
                render: (text, record) => (
                    <b>
                        {record.observation.name}
                    </b>
                ),
                width: '25%'
            },
            {
                title: 'Status Dropdown',
                dataIndex: 'statusDropdown',
                key: 'statusDropdown',
                render: (text, record) => (
                    <b>
                        {record.statusDropdown ? <Tag color="green">{record.statusDropdown}</Tag> : <Tag color="red">Not Answered</Tag>}
                    </b>
                ),
                width: '25%'
            },
            {
                title: 'Rectification Actions',
                dataIndex: 'rectificationActions',
                key: 'rectificationActions',
                render: (text, record) => (
                    <b>
                        {record.rectificationActions ? <Tag color="green">{record.rectificationActions}</Tag> : <Tag color="red">Not Answered</Tag>}
                    </b>
                ),
                width: '25%'
            },
            {
                title: 'Status Text',
                dataIndex: 'statusText',
                key: 'statusText',
                render: (text, record) => (
                    <b>
                        {record.statusText ? <Tag color="green">{record.statusText}</Tag> : <Tag color="red">Not Answered</Tag>}
                    </b>
                ),
                width: '25%'
            },
        ]

        const commentseList = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                render: (text, record) => (
                    <b>
                        {record.id}
                    </b>
                )
            },
            {
                title: 'Comments',
                dataIndex: 'comments',
                key: 'comments',
                render: (text, record) => (
                    <b>
                        {record.comments}
                    </b>
                )
            }
        ]

        const ipCoreList = [
            {
                title: 'Node Name',
                dataIndex: 'nodeName',
                key: 'nodeName',
                render: (text, record) => (
                    <b>
                        {record.nodeName}
                    </b>
                )
            },
            {
                title: 'Issues And Observations',
                dataIndex: 'issuesAndObservations',
                key: 'issuesAndObservations',
                render: (text, record) => (
                    <b>
                        {record.issuesAndObservations ? <Tag color="green">{record.issuesAndObservations}</Tag> : <Tag color="red">Not Answered</Tag>}
                    </b>
                )
            },
            {
                title: 'Rectification Actions',
                dataIndex: 'rectificationActions',
                key: 'rectificationActions',
                render: (text, record) => (
                    <b>
                        {record.rectificationActions ? <Tag color="green">{record.rectificationActions}</Tag> : <Tag color="red">Not Answered</Tag>}
                    </b>
                )
            },
            {
                title: 'Status Text',
                dataIndex: 'statusText',
                key: 'statusText',
                render: (text, record) => (
                    <b>
                        {record.statusText ? <Tag color="green">{record.statusText}</Tag> : <Tag color="red">Not Answered</Tag>}
                    </b>
                )
            },
            {
                title: 'Back Haul Path',
                dataIndex: 'backHaulPath',
                key: 'backHaulPath',
                render: (text, record) => (
                    <b>
                        {record.backHaulPath ? <Tag color="green">{record.backHaulPath}</Tag> : <Tag color="red">Not Answered</Tag>}
                    </b>
                )
            },
            {
                title: 'A End',
                dataIndex: 'aend',
                key: 'aend',
                render: (text, record) => (
                    <b>
                        {record.aend ? <Tag color="green">{record.aend}</Tag> : <Tag color="red">Not Answered</Tag>}
                    </b>
                )
            },
            {
                title: 'B End',
                dataIndex: 'bend',
                key: 'bend',
                render: (text, record) => (
                    <b>
                        {record.bend ? <Tag color="green">{record.bend}</Tag> : <Tag color="red">Not Answered</Tag>}
                    </b>
                )
            }
        ]

        const bssList = [
            {
                title: 'Node Name',
                dataIndex: 'nodeName',
                key: 'nodeName',
                render: (text, record) => (
                    <b>
                        {record.nodeName}
                    </b>
                )
            },
            {
                title: 'Issues And Observations',
                dataIndex: 'issuesAndObservations',
                key: 'issuesAndObservations',
                render: (text, record) => (
                    <b>
                        {record.issuesAndObservations ? <Tag color="green">{record.issuesAndObservations}</Tag> : <Tag color="red">Not Answered</Tag>}
                    </b>
                ),
                width: '25%'
            },
            {
                title: 'Rectification Actions',
                dataIndex: 'rectificationActions',
                key: 'rectificationActions',
                render: (text, record) => (
                    <b>
                        {record.rectificationActions ? <Tag color="green">{record.rectificationActions}</Tag> : <Tag color="red">Not Answered</Tag>} 
                    </b>
                ),
                width: '25%'
            },
            {
                title: 'Status Text',
                dataIndex: 'statusText',
                key: 'statusText',
                render: (text, record) => (
                    <b>
                        {record.statusText ? <Tag color="green">{record.statusText}</Tag> : <Tag color="red">Not Answered</Tag>}
                    </b>
                ),
                width: '25%'
            },
        ]

        const mwList = [
            {
                title: 'Hop Ref',
                dataIndex: 'hopRef',
                key: 'hopRef',
                render: (text, record) => (
                    <b>
                        {record.hopRef ? <Tag color="green">{record.hopRef}</Tag> : <Tag color="red">Not Answered</Tag>}
                    </b>
                )
            },
            {
                title: 'Far End',
                dataIndex: 'farEnd',
                key: 'farEnd',
                render: (text, record) => (
                    <b>
                        {record.farEnd ? <Tag color="green">{record.farEnd}</Tag> : <Tag color="red">Not Answered</Tag>}
                    </b>
                )
            },
            {
                title: 'Line Of Sight Risks Observed',
                dataIndex: 'lineOfSightRisksObserved',
                key: 'lineOfSightRisksObserved',
                render: (text, record) => (
                    <b>
                        {record.lineOfSightRisksObserved ? <Tag color="green">{record.lineOfSightRisksObserved}</Tag> : <Tag color="red">Not Answered</Tag>}
                    </b>
                )
            },
            {
                title: 'Issues And Observations',
                dataIndex: 'issuesAndObservations',
                key: 'issuesAndObservations',
                render: (text, record) => (
                    <b>
                        {record.issuesAndObservations ? <Tag color="green">{record.issuesAndObservations}</Tag> : <Tag color="red">Not Answered</Tag>}
                    </b>
                )
            },
            {
                title: 'Rectification Actions And Follow Up',
                dataIndex: 'rectificationActionsAndFollowUp',
                key: 'rectificationActionsAndFollowUp',
                render: (text, record) => (
                    <b>
                        {record.rectificationActionsAndFollowUp ? <Tag color="green">{record.rectificationActionsAndFollowUp}</Tag> : <Tag color="red">Not Answered</Tag>}
                    </b>
                )
            },
            {
                title: 'Status Text',
                dataIndex: 'statusText',
                key: 'statusText',
                render: (text, record) => (
                    <b>
                        {record.statusText ? <Tag color="green">{record.statusText}</Tag> : <Tag color="red">Not Answered</Tag>}
                    </b>
                )
            },
            {
                title: 'Model And Type',
                dataIndex: 'modelAndType',
                key: 'modelAndType',
                render: (text, record) => (
                    <b>
                        {record.modelAndType ? <Tag color="green">{record.modelAndType}</Tag> : <Tag color="red">Not Answered</Tag>}
                    </b>
                )
            }
        ]

        const ofnList = [
            {
                title: 'Node Name',
                dataIndex: 'nodeName',
                key: 'nodeName',
                render: (text, record) => (
                    <b>
                        {record.nodeName ? record.nodeName : 'N/A'}
                    </b>
                ),
                width: '25%'
            },
            {
                title: 'Issues And Observations',
                dataIndex: 'issuesAndObservations',
                key: 'issuesAndObservations',
                render: (text, record) => (
                    <b>
                        {record.issuesAndObservations ? <Tag color="green">{record.issuesAndObservations}</Tag> : <Tag color="red">Not Answered</Tag>}
                    </b>
                ),
                width: '25%'
            },
            {
                title: 'Rectification Actions',
                dataIndex: 'rectificationActions',
                key: 'rectificationActions',
                render: (text, record) => (
                    <b>
                        {record.rectificationActions ? <Tag color="green">{record.rectificationActions}</Tag> : <Tag color="red">Not Answered</Tag>}
                    </b>
                ),
                width: '25%'
            },
            {
                title: 'Status Text',
                dataIndex: 'statusText',
                key: 'statusText',
                render: (text, record) => (
                    <b>
                        {record.statusText ? <Tag color="green">{record.statusText}</Tag> : <Tag color="red">Not Answered</Tag>}
                    </b>
                ),
                width: '25%'
            }
        ]

        const bssListLength = this.state?.newIteams?.bssList?.length ? this.state?.newIteams?.bssList?.length : 0;
        const commentseListLength = this.state?.newIteams?.commentseList?.length ? this.state?.newIteams?.commentseList?.length : 0;
        const ipCoreListLength = this.state?.newIteams?.ipCoreList?.length ? this.state?.newIteams?.ipCoreList?.length : 0;
        const mwListLength = this.state?.newIteams?.mwList?.length ? this.state?.newIteams?.mwList?.length : 0;
        const ofnListLength = this.state?.newIteams?.ofnList?.length ? this.state?.newIteams?.ofnList?.length : 0;
        const physicalObservetionListLength = this.state?.newIteams?.physicalObservetionList?.length ? this.state?.newIteams?.physicalObservetionList?.length : 0;

        // BSS List Data Set Implementation Goes Here
        const bssListData = [];
        for (let i = 0; i < bssListLength; i++) {
            bssListData.push({
                sectionKey: i,
                id: this.state?.newIteams?.bssList[i]?.id,
                nodeName: this.state?.newIteams?.bssList[i]?.nodeName,
                issuesAndObservations: this.state?.newIteams?.bssList[i]?.issuesAndObservations,
                rectificationActions: this.state?.newIteams?.bssList[i]?.rectificationActions,
                statusText: this.state?.newIteams?.bssList[i]?.statusText,
            });
        }

        const groupBssListData = bssListData.reduce((acc, currentItem) => {
            const {sectionKey, ...rest} = currentItem;
            acc[sectionKey] = acc[sectionKey] || [];
            acc[sectionKey].push(rest);
            return acc;
        }, {});

        const bssListTables = Object.entries(groupBssListData).map(([sectionKey, data]) => (
            <Col key={sectionKey}>
                <div style={{
                    padding: 4,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: '0px 0px 10px 0px #0000001a',
                    marginTop: 8,
                    width: '100%',
                }}>
                    <h6><b>BSS List : {sectionKey}</b></h6>
                    <Table
                        dataSource={data}
                        columns={bssList}
                        bordered
                        pagination={false}
                        size="small"
                    />
                </div>
            </Col>
        ));

        //ipCoreList Data Set Implementation Goes Here
        const ipCoreListData = [];
        for (let i = 0; i < ipCoreListLength; i++) {
            ipCoreListData.push({
                sectionKey: i,
                id: this.state?.newIteams?.ipCoreList[i]?.id,
                nodeName: this.state?.newIteams?.ipCoreList[i]?.nodeName,
                issuesAndObservations: this.state?.newIteams?.ipCoreList[i]?.issuesAndObservations,
                rectificationActions: this.state?.newIteams?.ipCoreList[i]?.rectificationActions,
                statusText: this.state?.newIteams?.ipCoreList[i]?.statusText,
                backHaulPath: this.state?.newIteams?.ipCoreList[i]?.backHaulPath,
                aend: this.state?.newIteams?.ipCoreList[i]?.aend,
                bend: this.state?.newIteams?.ipCoreList[i]?.bend,
            });
        }

        const groupIpCoreListData = ipCoreListData.reduce((acc, currentItem) => {
            const {sectionKey, ...rest} = currentItem;
            acc[sectionKey] = acc[sectionKey] || [];
            acc[sectionKey].push(rest);
            return acc;
        }, {});

        const ipCoreListTables = Object.entries(groupIpCoreListData).map(([sectionKey, data]) => (
            <Col key={sectionKey}>
                <div style={{
                    padding: 4,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: '0px 0px 10px 0px #0000001a',
                    marginTop: 8,
                    width: '100%',
                }}>
                    <h6><b>IP Core List {sectionKey}</b></h6>
                    <Table
                        dataSource={data}
                        columns={ipCoreList}
                        bordered
                        pagination={false}
                        size="small"
                    />
                </div>
            </Col>
        ));

        // MW List Data Set Implementation Goes Here
        const mwListData = [];
        for (let i = 0; i < mwListLength; i++) {
            mwListData.push({
                sectionKey: i,
                id: this.state?.newIteams?.mwList[i]?.id,
                hopRef: this.state?.newIteams?.mwList[i]?.hopRef,
                farEnd: this.state?.newIteams?.mwList[i]?.farEnd,
                lineOfSightRisksObserved: this.state?.newIteams?.mwList[i]?.lineOfSightRisksObserved,
                issuesAndObservations: this.state?.newIteams?.mwList[i]?.issuesAndObservations,
                rectificationActionsAndFollowUp: this.state?.newIteams?.mwList[i]?.rectificationActionsAndFollowUp,
                statusText: this.state?.newIteams?.mwList[i]?.statusText,
                modelAndType: this.state?.newIteams?.mwList[i]?.modelAndType,
            });
        }

        const groupMwListData = mwListData.reduce((acc, currentItem) => {
            const {sectionKey, ...rest} = currentItem;
            acc[sectionKey] = acc[sectionKey] || [];
            acc[sectionKey].push(rest);
            return acc;
        }, {});

        const mwListTables = Object.entries(groupMwListData).map(([sectionKey, data]) => (
            <Col key={sectionKey}>
                <div style={{
                    padding: 4,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: '0px 0px 10px 0px #0000001a',
                    marginTop: 8,
                    width: '100%',
                }}>
                    <h6><b>MW List {sectionKey}</b></h6>
                    <Table
                        dataSource={data}
                        columns={mwList}
                        bordered
                        pagination={false}
                        size="small"
                    />
                </div>
            </Col>
        ));

        // OFN List Data Set Implementation Goes Here
        const ofnListData = [];
        for (let i = 0; i < ofnListLength; i++) {
            ofnListData.push({
                sectionKey: i,
                id: this.state?.newIteams?.ofnList[i]?.id,
                nodeName: this.state?.newIteams?.ofnList[i]?.nodeName,
                issuesAndObservations: this.state?.newIteams?.ofnList[i]?.issuesAndObservations,
                rectificationActions: this.state?.newIteams?.ofnList[i]?.rectificationActions,
                statusText: this.state?.newIteams?.ofnList[i]?.statusText,
            });
        }

        const groupOfnListData = ofnListData.reduce((acc, currentItem) => {
            const {sectionKey, ...rest} = currentItem;
            acc[sectionKey] = acc[sectionKey] || [];
            acc[sectionKey].push(rest);
            return acc;
        }, {});

        const ofnListTables = Object.entries(groupOfnListData).map(([sectionKey, data]) => (
            <Col key={sectionKey}>
                <div style={{
                    padding: 4,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: '0px 0px 10px 0px #0000001a',
                    marginTop: 8,
                    width: '100%',
                }}>
                    <h6><b>OFN List : {sectionKey}</b></h6>
                    <Table
                        dataSource={data}
                        columns={ofnList}
                        bordered
                        pagination={false}
                        size="small"
                    />
                </div>
            </Col>
        ));

        // Physical Observetion List Data Set Implementation Goes Here
        const physicalObservetionListData = [];
        for (let i = 0; i < physicalObservetionListLength; i++) {
            physicalObservetionListData.push({
                sectionKey: i,
                id: this.state?.newIteams?.physicalObservetionList[i]?.id,
                observation: this.state?.newIteams?.physicalObservetionList[i]?.observation,
                statusDropdown: this.state?.newIteams?.physicalObservetionList[i]?.statusDropdown,
                rectificationActions: this.state?.newIteams?.physicalObservetionList[i]?.rectificationActions,
                statusText: this.state?.newIteams?.physicalObservetionList[i]?.statusText,
            });
        }

        const groupPhysicalObservetionListData = physicalObservetionListData.reduce((acc, currentItem) => {
            const {sectionKey, ...rest} = currentItem;
            acc[sectionKey] = acc[sectionKey] || [];
            acc[sectionKey].push(rest);
            return acc;
        }, {});

        const physicalObservetionListTables = Object.entries(groupPhysicalObservetionListData).map(([sectionKey, data]) => (
            <Col key={sectionKey}>
                <div style={{
                    padding: 4,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    boxShadow: '0px 0px 10px 0px #0000001a',
                    marginTop: 20,
                    width: '100%',
                }}>
                    <h6><b>Physical Observation List : {sectionKey}</b></h6>
                    <Table
                        dataSource={data}
                        columns={physicalObservetionList}
                        bordered
                        pagination={false}
                        size="small"
                    />
                </div>
            </Col>
        ));

        /*
            "routingIssues": [
        {
            "id": 1,
            "issue": "DCDU/test",
            "user": {
                "id": 170,
                "name": "Depot Officer UAT"
            },
            "delete": true
        },
        {
            "id": 2,
            "issue": "Main Power Connection/testtttt",
            "user": {
                "id": 170,
                "name": "Depot Officer UAT"
            },
            "delete": true
        }
    ],*/

    const issuesTable = [
        {
            title: 'Issue ID',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => (
                <b>
                    {record.id}
                </b>
            ),
            width: '25%'
        },
        {
            title: 'Issue Discription',
            dataIndex: 'issue',
            key: 'issue',
            render: (text, record) => (
                <b>
                    {record.issue ? <Tag color="green">{record.issue}</Tag> : <Tag color="red">Not Answered</Tag>}
                </b>
            ),
            width: '50%'
        },
        {
            title: 'Identified By',
            dataIndex: 'user',
            key: 'user',
            render: (text, record) => (
                <b>
                    {record.user ? <Tag color="green">{record.user}</Tag> : <Tag color="red">Not Answered</Tag>}
                </b>
            ),
            width: '25%'
        },
    ]

    const issuesLength = this.state?.fullAccess?.routingIssues?.length ? this.state?.fullAccess?.routingIssues?.length : 0;
    const allIssueList = [];

    for (let i = 0; i < issuesLength; i++) {
        allIssueList.push({
            id: this.state?.fullAccess?.routingIssues?.[i]?.id ? this.state?.fullAccess?.routingIssues?.[i]?.id : 'N/A',
            issue: this.state?.fullAccess?.routingIssues?.[i]?.issue ? this.state?.fullAccess?.routingIssues?.[i]?.issue : 'N/A',
            user : this.state?.fullAccess?.routingIssues?.[i]?.user?.name ? this.state?.fullAccess?.routingIssues?.[i]?.user?.name : 'N/A',
        });
    }

    const issuesTableComponent = (
        <div style={{
            padding: 4,
            backgroundColor: 'white',
            borderRadius: 5,
            boxShadow: '0px 0px 10px 0px #0000001a',
            marginTop: 20,
            width: '100%',
        }}>
            <h6><b>Issues List</b></h6>
            <Table
                dataSource={allIssueList}
                columns={issuesTable}  
                bordered
                pagination={false}
                size="small"
            />
        </div>
    );
    



        return (
            <div style={{marginTop: 30, paddingLeft: 10}}>

                 {/*Base Details Section Informations */}
                <div className="row mt-3">
                    <div className="col-12">
                        <div className="card">
                            <div class="card-body">
                                <div class="dastone-profile">
                                    <div class="row">
                                        <div class="col-lg-4 align-self-center mb-3 mb-lg-0">
                                            <div class="dastone-profile-main">
                                                <div class="dastone-profile-main-pic">
                                                    <img src={'https://static.vecteezy.com/system/resources/previews/001/902/502/large_2x/telecommunication-radio-antenna-and-satelite-tower-with-blue-sky-free-photo.JPG'} alt="" height="110" class="rounded-circle"></img>
                                                </div>
                                                <div class="dastone-profile_user-detail">
                                                    <h5 class="dastone-user-name">{this.state.generalDetails?.siteName}</h5>
                                                    <p className="mb-0 text-secondary font-16 align-middle me-2"><span class="ant-tag ant-tag-has-color" style={{ backgroundColor: 'gray', fontSize: 14 }}>{this.state.fullAccess?.siteId}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-5 ms-auto align-self-center">
                                            <ul class="list-unstyled personal-detail mb-0">
                                                <li className="mt-2"><i className="ti ti-map-alt text-secondary font-16 align-middle me-2"></i> <b> Code </b> : {this.state.fullAccess?.routingVisitCode}</li>
                                                <li className="mt-2"><i className="ti ti-home text-secondary font-16 align-middle me-2"></i> <b> Status </b> : <Tag color={this.state?.fullAccess?.routingVisitStatus?.color.replace("0xFF", "#")} >{this.state.fullAccess?.routingVisitStatus?.name}</Tag></li>
                                                <li className="mt-2"><i className="ti ti-email text-secondary font-16 align-middle me-2"></i> <b> Request Type:  </b> : {this.state.fullAccess?.type? this.state.fullAccess?.type : 'N/A'}</li>
                                            </ul>
                                        </div>
                                        <div class="col-lg-3 align-self-center">
                                            <div className='row'>
                                                <ul class="list-unstyled personal-detail mb-0">
                                                    <li ><b>Created Date :</b> <span class="text-muted font-12 fw-normal"> {moment(this.state.fullAccess?.createdOn).format('YYYY-MM-DD HH:mm:ss')}</span></li>
                                                </ul>
                                                <ul className="list-unstyled personal-detail mt-2">
                                                    <li><b>Update On:</b> <span
                                                        className="text-muted font-12 fw-normal"> {moment(this.state.fullAccess?.updatedOn).format('YYYY-MM-DD HH:mm:ss')}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{
                  backgroundColor: '#F6F6F6',
                    padding: 10,
                    borderRadius: 10,
                }}>
                    <h4 style={{color: 'gray', marginLeft: 8, fontSize: 16}}>
                        <b>GENARAL DETAILS </b>
                    </h4>
                        {bssListTables}
                        {ofnListTables}
                        {physicalObservetionListTables}
                        {ipCoreListTables}
                        {mwListTables}
                </div>

                <div style={{
                    backgroundColor: '#F6F6F6',
                    padding: 10,
                    borderRadius: 10,
                    marginTop: 20
                }}>
                    <h4 style={{color: 'gray', marginLeft: 8, fontSize: 16}}>
                        <b>IDENTIFIED ISSUES </b>  
                    </h4>
                    {issuesTableComponent}
                </div>

                <div style={{
                    backgroundColor: '#F6F6F6',
                    padding: 10,
                    borderRadius: 10,
                    marginTop: 20
                }}>
                    <h4 style={{color: 'gray', marginLeft: 8, fontSize: 16}}>
                        <b>CHECK LIST INFORMATION </b>
                    </h4>

                    <div style={{
                        padding: 10,
                        backgroundColor: 'white',
                        borderRadius: 5,
                        boxShadow: '0px 0px 10px 0px #0000001a'
                    }}>
                        <h5><b>General Section</b></h5>
                        <Table
                            dataSource={data}
                            columns={this.columns}
                            bordered
                            pagination={false}
                            size="small"
                        />
                    </div>

                    <div style={{
                        padding: 10,
                        backgroundColor: 'white',
                        borderRadius: 5,
                        boxShadow: '0px 0px 10px 0px #0000001a',
                        marginTop: 20
                    }}>
                        <h5><b>Access Technologies</b></h5>
                        <Table
                            dataSource={accessTechnologiesData}
                            columns={this.columns}
                            bordered
                            pagination={false}
                            size="small"
                        />
                    </div>

                    <div style={{
                        padding: 10,
                        backgroundColor: 'white',
                        borderRadius: 5,
                        boxShadow: '0px 0px 10px 0px #0000001a',
                        marginTop: 20
                    }}>
                        <h5><b>Main Power</b></h5>
                        <Table
                            dataSource={mainPowerData}
                            columns={this.columns}
                            bordered
                            pagination={false}
                            size="small"
                        />
                    </div>

                    <div style={{
                        padding: 10,
                        backgroundColor: 'white',
                        borderRadius: 5,
                        boxShadow: '0px 0px 10px 0px #0000001a',
                        marginTop: 20,
                        width: '100%'
                    }}>
                        <h5><b>Shared Gen Section</b></h5>
                        <Table
                            dataSource={sharedGenSectionData}
                            columns={this.columns}
                            bordered
                            pagination={false}
                            size="small"
                        />
                    </div>

                    <div style={{
                        padding: 10,
                        backgroundColor: 'white',
                        borderRadius: 5,
                        boxShadow: '0px 0px 10px 0px #0000001a',
                        marginTop: 20,
                        width: '100%'
                    }}>
                        <h5><b>DC Power Shared In</b></h5>
                        <Table
                            dataSource={dcPowerShareInData}
                            columns={this.columns}
                            bordered
                            pagination={false}
                            size="small"
                        />
                    </div>

                    <div style={{
                        padding: 10,
                        backgroundColor: 'white',
                        borderRadius: 5,
                        boxShadow: '0px 0px 10px 0px #0000001a',
                        marginTop: 20,
                        width: '100%'
                    }}>
                        <h5><b>DC Load</b></h5>
                        <Table
                            dataSource={dcLoadData}
                            columns={this.columns}
                            bordered
                            pagination={false}
                            size="small"
                        />
                    </div>

                    <div style={{
                        padding: 10,
                        backgroundColor: 'white',
                        borderRadius: 5,
                        boxShadow: '0px 0px 10px 0px #0000001a',
                        marginTop: 20,
                        width: '100%'
                    }}>
                        <h5><b>Cabinet Details</b></h5>
                        <Table
                            dataSource={cabinetData}
                            columns={this.columns}
                            bordered
                            pagination={false}
                            size="small"
                        />
                    </div>

                        {tables}
               
                        {rectifierTables}

                        {dcduTables}

                        {batteryBankTables}

                        {idBatteryRackTables}

                        {genHutTables}

                        {generatorTables}
                   
                        {atsTables}

                        {acTables}

                        {cabinTables}

                        {fcbTables}

                        {spdTables}
    
                        {solarTables}

                
                    {renewableTables}

                  
                        {windTables}

                        {sharedOutOperatorsTables}
                

                    {sharedOutOperatorsGeneralTables}

                    {pgTables}

                    {earthingTables}

      
                        {bulkTankTables}
             

                    {imagesPreview}
                </div>



                {/* {console.log(this.state.itemDetails)}
                {this.state.itemDetails.length == 0 ? (
                    <>
                        <List loading={this.state.mainLoading} />
                    </>
                ) : (
                    <>
                    <p style={{ marginTop: '50px' }}>
                        {JSON.stringify(this.state.itemDetails[1].item[0].checklist[1].name)}
                    </p>
                    <p>
                    <p>
                        {this.state?.itemDetails[0]?.item[0]?.checklist[0]?.id}
                        {this.state.itemDetails[0].item[0].checklist[0].name}
                        {this.state?.itemDetails[0]?.item[0]?.checklist[0]?.answer}
                        </p>
                    </p>

                    <p>
                        {this.state?.itemDetails[0]?.item[0]?.checklist[1]?.id}
                    </p>
                        {this.state.itemDetails.map((item1, index) => {
                            return <>
                                <strong class="font-14">{item1?.sectionName} :</strong>
                                {item1?.item != null ? (item1?.item.map((value, key) => {
                                    var res = value.checklist.filter(elements => {
                                        return elements !== null;
                                    });
                                    return <>
                                        <br></br>Set {key+1}
                                        <pre>
                                            {console.log('res')}
                                            {console.log(res)}
                                        </pre>
                                        <List
                                            grid={{ gutter: 0, column: 5 }}
                                            loading={this.state.mainLoading}
                                            dataSource={res}
                                            renderItem={(item) => (
                                                <List.Item>
                                                    <RoutineChecklistItem item={item} click={this.showImage} />
                                                </List.Item>
                                            )}
                                        />
                                    </>
                                })) : ('No data')}
                                <hr></hr>
                            </>
                        })}
                    </>)} */}

                {/* <Modal
                    isOpen={this.state.showModal}
                    contentLabel="Minimal Modal Example"
                    style={{
                        background: "#FFFF00",
                        overlay: {
                            zIndex: 10000
                        },
                        content: {
                            right: '25%',
                            left: '29%',
                            top: '9px',
                            bottom: '20%'
                        }
                    }}
                >
                    <div style={{
                        height: "100%",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundImage: `url( ${image_url + this.state.selectedItem.value})`,
                        backgroundColor: "rgba(0, 0, 0, 0.22)",
                        backgroundBlendMode: "multiply"
                    }}>
                        <div class="row d-flex justify-content-center">

                            <div class="col-lg-12">
                                <div class="float-end d-print-none">

                                    <button class="btn btn-primary btn-sm" onClick={this.handleCloseFullModal} >Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal > */}

            </div>

        )
    }

}
function mapStateToProps(state) {

}
export default withRouter(connect(mapStateToProps, {})(RoutineRequestDetails));


