import React, { Component } from "react";
import { Table, Input, Tag, Space, DatePicker } from "antd";
import ReactExport from "react-data-export";
import { getRequestReport } from "../../api/index";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { RangePicker } = DatePicker;
class RequestReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      filterTable: null,
      data_list: [],
      startDate: "",
      endDate: "",
    };
  }

  componentDidMount() {
    //console.log(this.props.details);
  }
  getData = async (data) => {
    var res = await getRequestReport(data);
    //console.log(res);
    if (res.success) {
      this.setState({
        data_list: res.data,
      });
    }
  };
  onChangeDate = (date, dateString) => {
    //console.log(date);
    //console.log(dateString);
    this.setState({
      startDate: dateString,
    });
  };
  onChangeDate2 = (date, dateString) => {
    //console.log(date);
    //console.log(dateString);
    this.setState({
      endDate: dateString,
    });
  };
  searchClick = () => {
    //console.log("search");
    if(this.state.startDate!="" && this.state.endDate!=""){
      var data = {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      };
      this.getData(data);
    }
    
  };
  render() {
    const { filterTable } = this.state;
    const dataSet1 = this.state.data_list;

    const columns = [
      {
        title: "Request Code",
        dataIndex: "request_code",
        key: "request_code",
        render: (item) => <span>{item}</span>,
      },
    ];
    const timestamp = Date.now();
    return (
      <div className="row">
        <div className="col-md-3 col-lg-3 col-xl-3">
          <DatePicker
            placeholder="Start Date"
            style={{ width: "100%" }}
            onChange={this.onChangeDate}
          />
        </div>
        <div className="col-md-3 col-lg-3 col-xl-3">
          <DatePicker
            placeholder="End Date"
            style={{ width: "100%" }}
            onChange={this.onChangeDate2}
          />
        </div>
        <div className="col-md-6 col-lg-6 col-xl-6">
          <button
            onClick={this.searchClick}
            class="btn btn-primary"
            //style={{ float: "right" }}
          >
            <i class="fas fa-search"> </i> Search
          </button>
        </div>
        <br/><br/>
        <div className="col-lg-12 col-xl-12">
          <div className="card">
            <div className="card-header">
              <div className="row ">
                <div className="col">
                  <h4 className="card-title">
                    Report - Full export{" "}
                    <ExcelFile
                      filename={"Request report -" + timestamp}
                      element={
                        <button
                          class="btn btn-primary"
                          style={{ float: "right" }}
                        >
                          <i class="fas fa-file-export"> </i> Export
                        </button>
                      }
                    >
                      <ExcelSheet data={dataSet1} name="Request report">
                        <ExcelColumn

                          label="request_date"
                          value="request_date"
                        />
                        <ExcelColumn
                          label="request_time"
                          value="request_time"
                        />
                        <ExcelColumn
                          
                          style={{font: {sz: "24", bold: true}}}
                          label="request_code"
                          value="request_code"
                        />
                        <ExcelColumn
                          label="currentstatus"
                          value="currentstatus"
                        />
                        <ExcelColumn label="region" value="region" />
                        <ExcelColumn label="depot" value="depot" />
                        <ExcelColumn
                          label="site"
                          value="site"
                        />
                        <ExcelColumn label="status" value="status" />
                        <ExcelColumn
                          label="cancellation"
                          value="cancellation"
                        />
                        
                      </ExcelSheet>
                    </ExcelFile>
                  </h4>
                  <div></div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <Table
                size="small"
                columns={columns}
                // rowKey="id"
                dataSource={this.state.data_list}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RequestReport;
