import React from "react";
import "./marker.css";

export default class Marker extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            name: null
        }
    }

    render(){
        const { offsetX, offsetY, name, color } = this.props;
        return(
            <div
                className="svg-map-marker-container"
                style={{ left: `${offsetX*100}%`, top: `${offsetY*100}%`, background: color}}
                onMouseEnter={() => this.setState({ name })}
                onMouseLeave={() => this.setState({ name : null })}
            >
                <b>{this.state.name && this.state.name}</b>
            </div>
        )
    }
}