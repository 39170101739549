import React from "react";
import { Card, Row, Col } from "antd";
import { ShoppingOutlined } from "@ant-design/icons";

const RequestCard = ({ title, count, color, image }) => (
    <Card
        style={{
            width: '19%',
            borderRadius: '12px',
            marginTop: '20px',
            marginBottom: '20px',
            marginLeft: '10px',
            cursor: 'pointer',
        }}
    >
        <Row>
            <Col span={12}>
                <div
                    style={{
                        borderRadius: '50%',
                        width: '52px',
                        height: '52px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: '8px',
                        backgroundColor: color,
                        overflow: 'hidden',
                    }}
                >
                    <img src={image} alt="icon" style={{ width: '30px', height: '30px' }} />
                </div>
            </Col>
            <Col span={12}>
                <span
                    className="textStyles-small"
                    style={{ color: 'gray', textAlign: 'center', fontSize: '13px' }}
                >
                    {title} <br />
                    <b>
                        <span
                            className="textStyles-small"
                            style={{ color: 'gray', textAlign: 'center', fontSize: '22px' }}
                        >
                            {count || 0}
                        </span>
                    </b>
                </span>
            </Col>
        </Row>
    </Card>
);

export default RequestCard;