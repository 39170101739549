import React, { Component } from "react";
import { Table, Input, Tag, Space, DatePicker } from "antd";
import ReactExport from "react-data-export";
import ReactDataSheet from "react-datasheet";
import { getRefuelProcumentExportSheet, getRefuelPurchaseTransactionExport } from "../../api/index";
import ReactLoading from 'react-loading';
import { getDonationCategories, getData } from "../../actions/index";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'

const moment = require('moment');
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



const { RangePicker } = DatePicker;

class ProcurementRefuelFullExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      filterTable: null,
      data_list: [],
      data_list_site: [],
      data_list_purchase: [],
      data_list_Transaction: [],
      startDate: "",
      endDate: "",
      loading: false,

    };
  }
  componentDidMount() {
    if (JSON.parse(localStorage.getItem('serviceTypeSet')) == null) {
      const { history } = this.props;
   
      history.push("/");
     
    } else {
      this.getSetPageData(JSON.parse(localStorage.getItem('serviceTypeSet')) , 15)
    }
    
    this.props.getData();

    //console.log(this.props.details);
  }
  getSetPageData =(serviceTypeIs,Pageid) =>  {
    //console.log("this.props");
    //console.log(serviceTypeIs.serviceType);
    if(serviceTypeIs.serviceType.filter(checkuser=>checkuser?.id==Pageid)[0] == null ){
      const { history } = this.props;
   
      history.push("/not-allowed");
    }
    
  };
  getDataExport = async (data) => {
    //console.log(data);
    var res = await getRefuelProcumentExportSheet(data);
    //console.log("getRefuelProcumentExportSheet");
    //console.log(res);
    if (res.success) {
      // this.createDataset(res.data);
      //this.createDatasetPurchase(res.data);
      this.setState({
        loading: false,
        data_list: res.data,
        data_list_purchase: res.data,
      });
    }
  };
  getPurchaseTransaction = async (data) => {
    //console.log(data);
    var res = await getRefuelPurchaseTransactionExport(data);
    //console.log("getRefuelPurchaseTransactionExport");
    //console.log(res);
    if (res.success) {
      // this.createDataset(res.data);
      //this.createDatasetPurchase(res.data);
      this.setState({
        loading: false,
        data_list_Transaction: res.data,
        //data_list_purchase: res.data,
      });
    }
  };

  onChangeDate = (date, dateString) => {
    //console.log(date);
    //console.log(dateString);
    this.setState({
      startDate: dateString,
    });
  };
  onChangeDate2 = (date, dateString) => {
    //console.log(date);
    //console.log(dateString);
    this.setState({
      endDate: dateString,
    });
  };
  searchClick = () => {
    //console.log("search");
    if (this.state.startDate != "" && this.state.endDate != "") {
      var data = {
        "startDate": this.state.startDate,
        "endDate": this.state.endDate,

      };
      this.setState({
        loading: true,
      });
      this.getDataExport(data);
      this.getPurchaseTransaction(data);
    }

  };
  render() {
    const fontValue = { sz: "12", bold: true, fill: { patternType: "solid", fgColor: { rgb: "FF0B0713" } } };
    const { filterTable } = this.state;
    const dataSet2 = this.state.data_list_Transaction;
    const dataSet1 = this.state.data_list_purchase;
    const StatisticDataSet2 = [
      {
        columns: [
          // Sec 01
          { title: "Transaction ID", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 60 } },//char width 
          { title: "Transaction Date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
          { title: "Transaction Time", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
          { title: "Vendor", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
          { title: "Region", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
          { title: "Sub Region", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount
          // {title: "Depot", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//char width cManual_transport_amount
          { title: "Team", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//pixels width 
          { title: "Transaction Type", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width   


          // Sec 02
          { title: "Cash Purchse Comments", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
          { title: "Transactions Quantity (L)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
          //  {title: "Transactions NFC Card", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
          { title: "NFC Payment Referance", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

          // Sec 03
          { title: "Amount", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
          { title: "Procurement Code", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 240 } },//char width cManual_transport_amount


          // Sec 04

          // {title: "In Hand Quantity", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
          //  {title: "Total Amount", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount




        ],
        data: dataSet2?.map((data) => [

          // Sec 01
          { value: data.id, style: { font: { sz: "12" } } },
          { value: data.createdTime ? moment((data.createdTime)).format('YYYY-MM-DD') : ("Null"), style: { font: { sz: "12" } } },
          { value: data.createdTime ? moment((data.createdTime)).format('YYYY-MM-DD HH:mm:ss') : ("Null"), style: { font: { sz: "12" } } },
          { value: data?.vendor ? (data?.vendor) : (" "), style: { font: { sz: "12" } } },
          { value: data.region ? (data.region) : ("Null"), style: { font: { sz: "12" } } },
          { value: data?.subRegion ? (data.subRegion) : ("Null"), style: { font: { sz: "12" } } },
          //{value:data?.depot?("null"):("Null") ,style: { font: { sz: "12" } }},
          { value: data?.purchasedTeam ? (data.purchasedTeam) : ("Null"), style: { font: { sz: "12" } } },
          { value: data?.transactionType ? (data.transactionType) : ("Null"), style: { font: { sz: "12" } } },


          // Sec 02
          { value: data?.comment ? (data.comment) : ("Null"), style: { font: { sz: "12" } } },
          { value: data.quantity ? (data.quantity) : (0), style: { font: { sz: "12" } } },
          //  {value:data.nfcCard?(data?.nfcCard['code']):("Null") ,style: { font: { sz: "12" } }},
          { value: data?.nfcReference ? (data.nfcReference) : ("Null"), style: { font: { sz: "12" } } },



          // Sec 03
          { value: data?.amount ? (data.amount) : (0), style: { font: { sz: "12" } } },
          { value: data.procurement ? (data.procurement) : ("Null"), style: { font: { sz: "12" } } },







        ]
        ),
      }
    ];
    const StatisticDataSet = [
      {
        columns: [
          // Sec 01
          { title: "Procurement Code", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 250 } },//char width 
          { title: "Region", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 250 } },//char width 
          { title: "Sub Region", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 250 } },//char width 
          // {title: "Depot", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width 
          { title: "Vendor", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
          { title: "Team", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
          { title: "Created Time", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
          { title: "Created By", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount
          { title: "PE Approved", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount
          { title: "PE Approved Time", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 150 } },//char width cManual_transport_amount
          { title: "Apprved Remark", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 150 } },//char width cManual_transport_amount
          { title: "Rejected Time", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 150 } },//char width cManual_transport_amount

          { title: "Requested Quantity", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//pixels width 
          { title: "CF System Qty", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width   
          { title: "Vendor Updated inhand qty", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width   


          // Sec 02
          { title: "Approved Quantity", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 150 } },//char width cManual_transport_amount
          { title: "Total Qty In Procuement", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 150 } },//char width cManual_transport_amount
          { title: "Disaster Quantity", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 150 } },//char width cManual_transport_amount
          /// {title: "Entered Filling Quantity", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:150}},//char width cManual_transport_amount

          // Sec 03
          //{title: "Entered In Hand Quantity", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
          //  {title: "In Hand Quantity", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
          { title: "Purchsed Qty (L)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount



          // Sec 04
          { title: "Purchased  Amount (RS)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
          //     {title: "Total Qty In Procuement ", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
          { title: "Balance Purchse Qty", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount



          // Sec 05      
          { title: "Fuel Consumption", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
          { title: "Current Balance", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
          { title: "Procument Status", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

          { title: "Closed Time", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount




        ],
        data: dataSet1?.map((data) => [

          // Sec 01
          { value: data.code, style: { font: { sz: "12" } } },
          { value: data.region ? (data.region) : ("Null"), style: { font: { sz: "12" } } },
          { value: data?.subRegion ? (data.subRegion) : ("Null"), style: { font: { sz: "12" } } },
          // {value:data.depot?("null"):("Null") ,style: { font: { sz: "12" } }},
          { value: data.vendor ? (data.vendor) : ("Null"), style: { font: { sz: "12" } } },
          { value: data.team ? (data.team) : ("Null"), style: { font: { sz: "12" } } },
          { value: data.createdTime ? moment((data.createdTime)).format('YYYY-MM-DD HH:mm:ss') : ("Null"), style: { font: { sz: "12" } } },
          { value: data.createdBy ? (data.createdBy) : ("Null"), style: { font: { sz: "12" } } },
          { value: data.approveStatus ? (data.approveStatus) : ("Null"), style: { font: { sz: "12" } } },
          { value: data.approvedTime ? moment(data.approvedTime).format('YYYY-MM-DD HH:mm:ss') : ("Null"), style: { font: { sz: "12" } } },
          { value: data.apprvedRemark ? (data.apprvedRemark) : ("Null"), style: { font: { sz: "12" } } },
          { value: data.rejectedTime ? moment(data.rejectedTime).format('YYYY-MM-DD HH:mm:ss') : ("Null"), style: { font: { sz: "12" } } },

          { value: data.requestedQuantity ? (data.requestedQuantity) : (0), style: { font: { sz: "12" } } },
          { value: data.carriedForwardQuantity ? (data.carriedForwardQuantity) : (0), style: { font: { sz: "12" } } },
          { value: data.inHandQuantity ? (data.inHandQuantity) : (0), style: { font: { sz: "12" } } },



          // Sec 02
          { value: data.approvedQuantity ? (data.approvedQuantity) : (0), style: { font: { sz: "12" } } },
          { value: data?.totalQuantity ? (data.totalQuantity) : (0), style: { font: { sz: "12" } } },
          { value: data.disasterQuantity ? (data.disasterQuantity) : (0), style: { font: { sz: "12" } } },
          // {value:data.enteredFillingQuantity?("null"):("Null") ,style: { font: { sz: "12" } }},

          // Sec 03
          // {value:data.inHandQuantity?(data.inHandQuantity):("Null") ,style: { font: { sz: "12" } }},
          // {value:data.inHandQuantity?(data.inHandQuantity):(0) ,style: { font: { sz: "12" } }},
          { value: data?.purchasedQuantity ? (data.purchasedQuantity) : (0), style: { font: { sz: "12" } } },



          // Sec 04

          { value: data?.purchasedAmount ? (data.purchasedAmount) : (0), style: { font: { sz: "12" } } },
          //  {value:data?.totalQuantity?(data.totalQuantity):(0) ,style: { font: { sz: "12" } }},
          { value: data?.balncePurchasedQuantity ? (data.balncePurchasedQuantity) : (0), style: { font: { sz: "12" } } },


          // {value:data.totalAmount?(data.totalAmount):("Null") ,style: { font: { sz: "12" } }},

          // Sec 05
          { value: data?.fuelConsumption ? (data.fuelConsumption) : ("null"), style: { font: { sz: "12" } } },
          { value: data?.currentBalance ? (data.currentBalance) : (0), style: { font: { sz: "12" } } },
          { value: data?.status ? (data.status) : ("Null"), style: { font: { sz: "12" } } },

          { value: data?.closedTime ? moment(data.closedTime).format('YYYY-MM-DD HH:mm:ss') : ("Null"), style: { font: { sz: "12" } } },



        ]
        ),
      }
    ];
    const columns = [
      {
        title: "Code",
        dataIndex: "code",
        key: "code",
        render: (item) => <span>{item}</span>,
      },
      {
        title: "Region",
        dataIndex: "region",
        key: "region",
        render: (item) => <span>{item}</span>,
      },

      {
        title: "Created Date",
        dataIndex: "createdTime",
        key: "createdTime",
        render: (item) => <span>{moment((item)).format('YYYY-MM-DD')}</span>,
      },
      {
        title: "Created Time",
        dataIndex: "createdTime",
        key: "createdTime",
        render: (item) => <span>{moment((item)).format('HH:mm:ss')}</span>,
      },
    ];
    const timestamp = Date.now();

    return (
      <div class="container-fluid">
        <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="row">
                    <div class="col">
                      <h4 class="page-title">Refuel Procurement Full Export</h4>
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Data Export </a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Refuel Procurement Full Export</a></li>

                      </ol>
                    </div>
                    <div class="col-auto align-self-center">
                      <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                      </a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
      <div className="row">
        <div className="col-md-3 col-lg-3 col-xl-3">
          <DatePicker
            placeholder="Start Date"
            style={{ width: "100%" }}
            onChange={this.onChangeDate}
          />
        </div>
        <div className="col-md-3 col-lg-3 col-xl-3">
          <DatePicker
            placeholder="End Date"
            style={{ width: "100%" }}
            onChange={this.onChangeDate2}
          />
        </div>
        <div className="col-md-6 col-lg-6 col-xl-6">
          <button
            onClick={this.searchClick}
            class="btn btn-primary"
          //style={{ float: "right" }}
          >
            <i class="fas fa-search"> </i> Search
          </button>
        </div>
        <br /><br />
        <div className="col-lg-12 col-xl-12">
          <div className="card">
            <div className="card-header">
              <div className="row ">
                <div className="col">
                  <h4 className="card-title">
                    Report -Refuel Procurement Full export{" "}
                    <ExcelFile
                      filename={"Refuel Procurement Full export -" + timestamp}
                      element={
                        <button
                          class="btn btn-primary"
                          style={{ float: "right" }}
                        >
                          <i class="fas fa-file-export"> </i>Refuel Procurement Full Export
                        </button>
                      }
                    >

                      <ExcelSheet dataSet={StatisticDataSet} name="Procurement" />
                      <ExcelSheet dataSet={StatisticDataSet2} name="Purchase Transaction" />
                    </ExcelFile>
                  </h4>
                  <div></div>
                </div>
              </div>
            </div>
            <div className="card-body">


              <Table
                size="small"
                columns={columns}
                // rowKey="id"
                loading={this.state.loading}
                dataSource={this.state.data_list}
              />
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  //console.log("state", state);
  return {
    organization_count: state.organization.length,
    regions: state.regions,
    depots: state.depots,
    sites_count: state.sites.length,
    vendor_count: state.vendor.length,
    roles_count: state.roles.length,
  };
}

export default withRouter(connect(mapStateToProps, { getDonationCategories, getData })(
  ProcurementRefuelFullExport
));