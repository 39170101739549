import React, { useState, useEffect } from 'react';
import {
    ClockCircleFilled,
    PlusCircleFilled,
    EditOutlined,
    DeleteOutlined,
    EyeOutlined,
    SearchOutlined
} from '@ant-design/icons';
import { Checkbox, Divider, Space, Card, Skeleton, Switch, Table, Select, Badge, Input, Button, Row, Upload, Col, Dropdown, Tag, Modal, Steps, message, Form, DatePicker, TimePicker, Descriptions, Image, Radio, Collapse, Spin, Tabs } from 'antd';
import {
    deleteMasterDataAccess,
    insertMasterDataAccess,
    getAllRoleDataAccessFunction,
    getAllSubRegionsDepotCreate,
    updateRoleDataAccessFunction,
    getAllUsersDataREWAMPNewModule,
    getUserDetailsByUserID,
    createUserDetailsDataAccessFunction,
    updateUserDetailsDataAccessFunction,
    getAllRegionRelatedData,
    getSiteViewRegions,
    upsertGroupPermission,
    getAllDepots
} from "../../api/index";
import './style.css';
import { Loading } from '../../components/essentials/alerts';
import { Spinner } from 'reactstrap';
const { Step } = Steps;

const RoleModule = () => {
    const [masterData, setMasterData] = useState([]);
    const [masterDataType, setMasterDataType] = useState("group");
    const [isLoading, setIsLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [subRegions, setSubRegions] = useState([]);
    const [fetchAllUsers, setFetchAllUsers] = useState([]);

    const [selectedRegionIdList, setSelectedRegionIdList] = useState([]);
    const [selectedRoleList, setSelectedRoleList] = useState([]);
    const [selectedGroupList, setSelectedGroupList] = useState([]);

    const [getAllRoleList, setGetAllRoleList] = useState([]);
    const [getAllGroupList, setGetAllGroupList] = useState([]);

    const [searchData, setSearchData] = useState([]);
    const [isView, setIsView] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const [parentRegionList, setParentRegionList] = useState([]);
    const [mapModuleRegionList, setMapModuleRegionList] = useState([]);
    const [depots, setDepots] = useState([]);

    useEffect(() => {
        getMasterData('group');
        getMasterData('role');
        fetchAllSubRegionsDepotCreate();
        fetchAllUserDetails().then(r => console.log(r));
        fetchAllRegionRelatedDataParent();
        fetchAllDepots();
    }, []);

    const onSearchData = (searchText) => {
        if (searchText) {
            const filteredData = fetchAllUsers.filter((value) => {
                return value.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
                    value.lastName?.toLowerCase().includes(searchText.toLowerCase()) ||
                    value.email?.toLowerCase().includes(searchText.toLowerCase()) ||
                    value.mobileNumberOne?.toLowerCase().includes(searchText.toLowerCase()) ||
                    value.mobileNumberTwo?.toLowerCase().includes(searchText.toLowerCase()) ||
                    value.status?.toLowerCase().includes(searchText.toLowerCase());
            });
            setSearchData(filteredData);
        } else {
            setSearchData(fetchAllUsers);
        }
    }


    const fetchAllDepots = async () => {
        const response = await getAllDepots();
        if (response.success) {
            setDepots(response.data);
        } else {
            message.error('Error fetching depots: ' + response?.message);
        }
    };

    const fetchAllSubRegionsDepotCreate = async () => {
        const response = await getAllSubRegionsDepotCreate();
        if (response.success) {
            const responseData = response?.data;
            setSubRegions(responseData);
        }
    }

    const fetchUserDetailsByID = async (id) => {
        const response = await getUserDetailsByUserID(id);
        if (response.success) {
            const responseData = response?.data?.results;
            setSelectedData(responseData);
            setSelectedRegionIdList(responseData?.regions?.map(data => data.id));
            setSelectedRoleList(responseData?.roles?.map(data => data.id));
            setSelectedGroupList(responseData?.groups?.map(data => data.id));
        }
    }

    const fetchAllUserDetails = async () => {
        setFetchAllUsers([]);
        setSearchData([]);
        setIsLoading(true);
        let pageCount = await fetchPageCount();
        for (let i = 1; i <= pageCount; i++) {
            const response = await getAllUsersDataREWAMPNewModule(i, 15);
            if (response.success) {
                const responseData = response?.data?.results?.data;
                setFetchAllUsers(prevState => [...prevState, ...responseData]);
                setSearchData(prevState => [...prevState, ...responseData]);
            }
        }
        setIsLoading(false);
    }

    const fetchAllRegionRelatedDataParent = async () => {
        const response = await getAllRegionRelatedData('parentRegion');
        if (response.success) {
            const responseData = response?.data?.results;
            setParentRegionList(responseData);
        }
    }

    const fetchPageCount = async () => {
        const response = await getAllUsersDataREWAMPNewModule(1, 15);
        if (response.success) {
            const responseData = response?.data?.results?.totalPages;
            return responseData;
        }
    }

    const getMasterData = async (data) => {
        setIsLoading(true);
        const response = await getAllRoleDataAccessFunction(data);
        if (response?.success) {
            if (data === 'role') {
                setGetAllRoleList(response?.data?.results);
            } else if (data === 'group') {
                setGetAllGroupList(response?.data?.results);
            }
        } else {
            message.error("Failed to fetch data");
        }
    }

    const insertMasterData = async (data) => {
        setIsLoading(true);
        const response = await createUserDetailsDataAccessFunction(data);
        console.log(response);
        if (response?.success) {
            setIsLoading(false);
            message.success('New user data inserted successfully');
            closeModal();
            fetchAllUserDetails().then(r => console.log(r));
        } else {
            setIsLoading(false);
            message.error(response?.data?.errors[0]?.errorMessage);
        }
    }

    const updateMasterData = async (id, data) => {
        const requestJson = {
            regionIds: data.mapViewRegions ? data.mapViewRegions : []
        }
        updateMapModuleRegions('post', requestJson, id);
        setIsLoading(true);
        const response = await updateUserDetailsDataAccessFunction(id, data);
        if (response?.success) {
            setIsLoading(false);
            message.success('User data updated successfully');
            closeModal();
            setSelectedData({});
            fetchAllUserDetails().then(r => console.log(r));
        } else {

            message.error("Failed to update data");
        }
    }

    // get map module details
    const mapModuleRegionListFunction = async (userID) => {
        setMapModuleRegionList([]);
        const response = await getSiteViewRegions('get', null, userID);
        if (response.success) {
            const responseData = response?.data?.results;
            setMapModuleRegionList(responseData);
        }
    }

    // getSiteViewRegions
    const updateMapModuleRegions = async (type, data, userID) => {
        const response = await getSiteViewRegions(type, data, userID);
        console.log(response);
        setIsLoading(false);
        if (response.success) {
            message.success(<span className='textStyles-small'>Map module regions updated successfully</span>);
            setIsLoading(false);
        } else {
            message.error(<span className='textStyles-small'>Failed to update map module regions</span>);
            setIsLoading(false);
        }

    }

    const deleteMasterDataAccessMasterData = async (id) => {
        setIsLoading(true);
        console.log(id);
        const response = await deleteMasterDataAccess(masterDataType, id);
        if (response?.success) {
            setIsLoading(false);
            message.success(masterDataType + " data deleted successfully");
            closeModal();
            setSelectedData({});
            getMasterData(masterDataType);
        } else {
            setIsLoading(false);
            message.error("Failed to delete data");
        }
    }

    const closeModal = () => {
        setModalOpen(false);
        setIsUpdate(false);
        setSelectedData([]);
        setSelectedGroupList([]);
        setSelectedRoleList([]);
        setSelectedRegionIdList([]);
        setIsView(false);
        setIsCreate(false);
    }

    const data_Cols = [
        {
            title: <span className="textStyles-small">First Name</span>,
            dataIndex: 'firstName',
            key: 'firstName',
            render: (text, record) => (
                <span className="textStyles-small">{record.firstName ? record.firstName : 'Not Set Yet'}</span>
            )
        },
        {
            title: <span className="textStyles-small">Last Name</span>,
            dataIndex: 'lastName',
            key: 'lastName',
            render: (text, record) => (
                <span className="textStyles-small">{record.lastName ? record.lastName : 'Not Set Yet'}</span>
            )
        },
        {
            title: <span className="textStyles-small">Email</span>,
            dataIndex: 'email',
            key: 'email',
            render: (text, record) => (
                <span className="textStyles-small">{record.email ? record.email : 'Not Set Yet'}</span>
            )
        },
        {
            title: <span className="textStyles-small">Mobile Number</span>,
            dataIndex: 'mobileNumberOne',
            key: 'mobileNumberOne',
            render: (text, record) => (
                <span className="textStyles-small">{record.mobileNumberOne ? record.mobileNumberOne : 'Not Set Yet'}</span>
            )
        },
        {
            title: <span className="textStyles-small">Mobile Number 2</span>,
            dataIndex: 'mobileNumberTwo',
            key: 'mobileNumberTwo',
            render: (text, record) => (
                <span className="textStyles-small">{record.mobileNumberTwo ? record.mobileNumberTwo : 'Not Set Yet'}</span>
            )
        },
        {
            title: <span className="textStyles-small">Status</span>,
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                record?.status
                    ? (record.status === 'ACTIVE' || record.status === '1'
                        ? (
                            <Tag color="green" style={{ borderRadius: '8px', padding: '5px', borderColor: 'transparent' }}>
                                <span className='textStyles-small' style={{ fontWeight: 530 }}>Active</span>
                            </Tag>
                        ) : (
                            <Tag color="red" style={{ borderRadius: '8px', padding: '5px', borderColor: 'transparent' }}>
                                <span className='textStyles-small' style={{ fontWeight: 530 }}>Inactive</span>
                            </Tag>
                        ))
                    : 'Not Set Yet'
            )
        },
        {
            title: <span className="textStyles-small">Action</span>,
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button
                        style={{ backgroundColor: '#F28502', borderColor: '#F28502' }}
                        shape='circle'
                        size='small'
                        icon={<EditOutlined />}
                        type="primary"
                        onClick={() => {
                            // fetchUserDetailsByID(record.id).then(r => console.log(r));
                            // mapModuleRegionListFunction(record.id);
                            setIsUpdate(true);
                            Promise.all([
                                fetchUserDetailsByID(record.id),
                                mapModuleRegionListFunction(record.id)
                            ]).then(() => {
                                setModalOpen(true);
                            });
                        }}
                    />
                    <Button
                        shape='circle'
                        size='small'
                        icon={<EyeOutlined />}
                        type="primary"
                        style={{ borderColor: '#BA183A', backgroundColor: '#BA183A' }}
                        onClick={() => {
                            setIsUpdate(false);
                            setIsView(true);
                            setModalOpen(true);
                            fetchUserDetailsByID(record.id).then(r => console.log(r));
                            mapModuleRegionListFunction(record.id);
                        }}
                    />
                </Space>
            )
        }
    ]

    const onFinishSubmit = async (values) => {
        const jsonRequest = {
            "firstName": values.firstName,
            "lastName": values.lastName,
            "email": values.email,
            "status": values.status,
            "mobileNumberOne": values.mobileNumberOne,
            "mobileNumberTwo": values.mobileNumberTwo,
            "roleIds": values.roles,
            "groupIds": values.groups,
            "regionIds": values.regions,
            "depotIds": values.depotIds,
            "autoGenPassword": false,
            "mapViewRegions": values.mapViewRegions
        }
        if (isUpdate) {
            updateMasterData(selectedData?.id, jsonRequest);
        } else {
            insertMasterData(jsonRequest);
        }
    }

    const handleCheckboxChange = async (updatedRecord) => {
        setIsLoading(true);
        try {
            const response = await upsertGroupPermission(updatedRecord.id, selectedData.id, updatedRecord);
            if (response.success) {
                message.success(<span className='textStyles-small'>Group permission updated successfully</span>);
                fetchUserDetailsByID(selectedData.id);
            } else {
                message.error(<span className='textStyles-small'>Failed to update group permission</span>);
            }
        } catch (error) {
            message.error(<span className='textStyles-small'>Failed to update group permission</span>);
        }
        setIsLoading(false);
    }


    const accessPermissionConfigTable = [
        {
            title: <span className="textStyles-small">Group Name</span>,
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <span className="textStyles-small">{record.name ? record.name : 'Not Set Yet'}</span>
            )
        },
        {
            title: <span className="textStyles-small">Number of Users</span>,
            dataIndex: 'numberOfUsers',
            key: 'numberOfUsers',
            render: (text, record) => (
                <span className="textStyles-small">{record.numberOfUsers ? record.numberOfUsers : '0'}</span>
            )
        },
        {
            title: <span className="textStyles-small">Group Type</span>,
            dataIndex: 'groupType',
            key: 'groupType',
            render: (text, record) => (
                <span className="textStyles-small">{record.groupType ? record.groupType : 'Not Set Yet'}</span>
            )
        },
        {
            title: <span className="textStyles-small">Can Read</span>,
            dataIndex: 'canRead',
            key: 'canRead',
            render: (text, record) => (
                <Checkbox
                    defaultChecked={record?.canRead ? record?.canRead : false}
                    disabled={isView ? true : false || record?.groupType === 'UNSPECIFIED' ? true : false}
                    onChange={(e) => {
                        const updatedRecord = { ...record, canRead: e.target.checked };
                        handleCheckboxChange(updatedRecord);
                    }}
                />
            )
        },
        {
            title: <span className="textStyles-small">Can Write</span>,
            dataIndex: 'canWrite',
            key: 'canWrite',
            render: (text, record) => (
                <Checkbox
                    defaultChecked={record?.canWrite ? record?.canWrite : false}
                    disabled={isView ? true : false || record?.groupType === 'UNSPECIFIED' ? true : false}
                    onChange={(e) => {
                        const updatedRecord = { ...record, canWrite: e.target.checked };
                        handleCheckboxChange(updatedRecord);
                    }}
                />
            )
        }

    ]

    return (
        <body>
            <div style={{ margin: '0', padding: '10px', height: '100%' }}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            <div className="row">
                                <div className="col">
                                    <span className="textStyles-small" style={{ fontSize: '14px', fontWeight: 'bold' }}>User Module V2.0</span>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a className='textStyles-small'>User Module V2.0</a></li>
                                        <li className="breadcrumb-item"><a className='textStyles-small'>Admin</a></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ marginTop: '10px', padding: '10px', width: '100%', backgroundColor: 'white', borderRadius: '10px' }}>
                    <Row>
                        <Col span={12}>
                            <Button
                                type="primary"
                                icon={<PlusCircleFilled />}
                                style={{ width: '150px', borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '8px', height: 36, marginBottom: 5 }}
                                onClick={() => {
                                    setIsUpdate(false);
                                    setModalOpen(true);
                                    setIsView(false);
                                    setIsCreate(true);
                                }}
                            >
                                <span className='textStyles-small'>New User</span>
                            </Button>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Input
                                prefix={<SearchOutlined />}
                                placeholder="Search User"
                                onChange={e => onSearchData(e.target.value)}
                                className='borderedSelect'
                                style={{ width: '300px', height: 36, borderRadius: '8px' }}
                            />
                        </Col>
                    </Row>
                    <Spin spinning={isLoading}>
                        <Table
                            className='table-striped-rows'
                            columns={data_Cols}
                            dataSource={searchData.reverse()}
                            search={true}
                            style={{ marginTop: 10 }}
                        />
                    </Spin>
                </div>
            </div>

            <Modal
                title={
                    <span className='textStyles-small' style={{ fontSize: '16px' }}>
                        {
                            isUpdate ? 'Update User Data' : isView ? 'View User Data' : 'Create User Data'
                        }
                    </span>
                }
                visible={modalOpen}
                onCancel={closeModal}
                footer={null}
                destroyOnClose={true}
                width={800}
            >
                {
                    (isUpdate || isCreate) && (
                        <Tabs defaultActiveKey="1">
                            <Tabs.TabPane tab={<span className='textStyles-small'>Basic Details</span>}
                                key="1">
                                <Form
                                    name="basic"
                                    onFinish={onFinishSubmit}
                                    layout='vertical'
                                >
                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span className='textStyles-small'>First Name</span>}
                                                name="firstName"
                                                rules={[{ required: true, message: 'Please input your first name!' }]}
                                                initialValue={isUpdate ? selectedData?.firstName : ''}
                                            >
                                                <Input
                                                    className='borderedSelect'
                                                    style={{ width: '98%', height: 32 }}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item
                                                label={<span className='textStyles-small'>Last Name</span>}
                                                name="lastName"
                                                initialValue={isUpdate ? selectedData?.lastName : ''}
                                            >
                                                <Input
                                                    className='borderedSelect'
                                                    style={{ width: '98%', height: 32 }}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item
                                                label={<span className='textStyles-small'>Email</span>}
                                                name="email"
                                                rules={[{ required: true, message: 'Please input your email!' }]}
                                                initialValue={isUpdate ? selectedData?.email : ''}
                                            >
                                                <Input
                                                    type='email'
                                                    className='borderedSelect'
                                                    style={{ width: '98%', height: 32 }}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item
                                                label={<span className='textStyles-small'>Mobile Number 1</span>}
                                                name="mobileNumberOne"
                                                rules={[
                                                    { required: true, message: 'Please input your mobile number 1!' },
                                                    { pattern: /^\d{10}$/, message: 'Mobile number must be 10 digits!' }
                                                ]}
                                                initialValue={isUpdate ? selectedData?.mobileNumberOne : ''}
                                            >
                                                <Input
                                                    className='borderedSelect'
                                                    style={{ width: '98%', height: 32 }}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item
                                                label={<span className='textStyles-small'>Mobile Number 2</span>}
                                                name="mobileNumberTwo"
                                                rules={[
                                                    { pattern: /^\d{10}$/, message: 'Mobile number must be 10 digits!' }
                                                ]}
                                                initialValue={isUpdate ? selectedData?.mobileNumberTwo : ''}
                                            >
                                                <Input
                                                    className='borderedSelect'
                                                    style={{ width: '98%', height: 32 }}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item
                                                label={<span className='textStyles-small'>Status</span>}
                                                name="status"
                                                rules={[{ required: true, message: 'Please input your status!' }]}
                                                initialValue={isUpdate ? selectedData?.status : ''}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="Select a Status"
                                                    className='borderedSelect'
                                                    style={{ width: '98%' }}
                                                    bordered={false}
                                                >
                                                    <Select.Option value="ACTIVE" className='textStyles-small'>Active</Select.Option>
                                                    <Select.Option value="INACTIVE" className='textStyles-small'>Inactive</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item
                                                label={<span className='textStyles-small'>Roles</span>}
                                                name="roles"
                                                initialValue={isUpdate ? selectedRoleList : []}
                                            >
                                                <Select
                                                    showSearch
                                                    mode="multiple"
                                                    placeholder="Select a Role"
                                                    className='borderedSelect'
                                                    style={{ width: '98%' }}
                                                    bordered={false}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().includes(input.toLowerCase())
                                                    }
                                                >
                                                    {
                                                        getAllRoleList.map((data, index) => {
                                                            return (
                                                                <Select.Option key={data.id} value={data.id}>
                                                                    <span className='textStyles-small'>{data.name}</span>
                                                                </Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item
                                                label={<span className='textStyles-small'>Groups</span>}
                                                name="groups"
                                                initialValue={isUpdate ? selectedGroupList : []}
                                            >
                                                <Select
                                                    showSearch
                                                    mode="multiple"
                                                    placeholder="Select a Group"
                                                    className='borderedSelect'
                                                    style={{ width: '98%' }}
                                                    bordered={false}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().includes(input.toLowerCase())
                                                    }
                                                >
                                                    {
                                                        getAllGroupList.map((data, index) => {
                                                            return (
                                                                <Select.Option key={data.id} value={data.id}>
                                                                    <span className='textStyles-small'>{data.name} {data?.groupType ? ' - '+data?.groupType : ''}</span>
                                                                </Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item
                                                label={<span className='textStyles-small'>Regions</span>}
                                                name="regions"
                                                initialValue={isUpdate ? selectedRegionIdList : []}
                                            >
                                                <Select
                                                    className='borderedSelect'
                                                    style={{ width: '98%' }}
                                                    bordered={false}
                                                    showSearch
                                                    mode="multiple"
                                                    placeholder="Select a Region"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().includes(input.toLowerCase())
                                                    }
                                                >
                                                    {
                                                        subRegions.map((data, index) => {
                                                            return (
                                                                <Select.Option key={data.id} value={data.id}>
                                                                    <span className='textStyles-small'>{data.name}</span>
                                                                </Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item
                                                label={<span className='textStyles-small'>Depots</span>}
                                                name="depotIds"
                                                initialValue={isUpdate ? selectedData?.depots?.map(data => data.id) : []}
                                            >
                                                <Select
                                                    className='borderedSelect'
                                                    style={{ width: '98%' }}
                                                    bordered={false}
                                                    showSearch
                                                    mode="multiple"
                                                    placeholder="Select a Depot"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().includes(input.toLowerCase())
                                                    }
                                                >
                                                    {
                                                        depots.map((data, index) => {
                                                            return (
                                                                <Select.Option key={data.id} value={data.id}>
                                                                    <span className='textStyles-small'>{data.name}</span>
                                                                </Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        {
                                            isUpdate ? (
                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span className='textStyles-small'>Map View Regions</span>}
                                                        name="mapViewRegions"
                                                        initialValue={mapModuleRegionList?.map(data => data.id)}
                                                    >
                                                        <Select
                                                            className='borderedSelect'
                                                            mode='multiple'
                                                            style={{ width: '98%' }}
                                                            bordered={false}
                                                            showSearch
                                                            placeholder="Select a Parent Region"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().includes(input.toLowerCase())
                                                            }
                                                        >
                                                            {
                                                                parentRegionList.map((data, index) => {
                                                                    return (
                                                                        <Select.Option key={data.id} value={data.id}>
                                                                            <span className='textStyles-small'>{data.name}</span>
                                                                        </Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            ) : null
                                        }

                                        <Col span={24}>
                                            <Form.Item style={{ textAlign: 'right' }}>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    style={{ width: '150px', borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '8px', height: 36 }}
                                                >
                                                    <span className='textStyles-small'>{isUpdate ? 'Update' : 'Create'} User Data</span>
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Tabs.TabPane>

                            {
                                isUpdate && (
                                    <Tabs.TabPane tab={<span className='textStyles-small'>Site Access Permissions</span>}
                                        key="2">
                                        <Spin tip={<span className='textStyles-small'>Waiting For Update Site Access Permissions</span>}
                                            spinning={isLoading}>
                                            <Table
                                                className='table-striped-rows'
                                                columns={accessPermissionConfigTable}
                                                dataSource={selectedData?.groups}
                                                search={true}
                                                size='small'
                                                pagination={false}
                                            />
                                        </Spin>
                                    </Tabs.TabPane>
                                )
                            }
                        </Tabs>
                    )
                }

                {
                    isView ? (
                        <Tabs defaultActiveKey="1">
                            <Tabs.TabPane tab={<span className='textStyles-small'>Basic Details</span>} key="1">
                                <Descriptions
                                    bordered
                                    column={2}
                                    size='small'
                                     className='custom-descriptions'
                                >
                                    <Descriptions.Item label={<span className='textStyles-small'>First Name</span>}>
                                        <span className='textStyles-small'>{selectedData?.firstName ? selectedData?.firstName : 'Not Set Yet'}</span>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={<span className='textStyles-small'>Last Name</span>}>
                                        <span className='textStyles-small'>{selectedData?.lastName ? selectedData?.lastName : 'Not Set Yet'}</span>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={<span className='textStyles-small'>Email</span>}>
                                        <span className='textStyles-small'>{selectedData?.email ? selectedData?.email : 'Not Set Yet'}</span>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={<span className='textStyles-small'>Mobile Number 1</span>}>
                                        <span className='textStyles-small'>{selectedData?.mobileNumberOne ? selectedData?.mobileNumberOne : 'Not Set Yet'}</span>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={<span className='textStyles-small'>Mobile Number 2</span>}>
                                        <span className='textStyles-small'>{selectedData?.mobileNumberTwo ? selectedData?.mobileNumberTwo : 'Not Set Yet'}</span>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={<span className='textStyles-small'>Status</span>}>
                                        <span className='textStyles-small'>
                                            {selectedData?.status
                                                ? (selectedData.status === 'ACTIVE' || selectedData.status === '1' ?
                                                    <Tag color="green" style={{ borderRadius: '8px', padding: '5px', borderColor: 'transparent' }}>
                                                        <span className='textStyles-small' style={{ fontWeight: 500 }}>Active</span>
                                                    </Tag>
                                                    : <Tag color="red" style={{ borderRadius: '8px', padding: '5px', borderColor: 'transparent' }}>
                                                        <span className='textStyles-small' style={{ fontWeight: 500 }}>Inactive</span>
                                                    </Tag>)
                                                : 'Not Set Yet'}
                                        </span>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={<span className='textStyles-small'>Roles</span>}>
                                        {
                                            selectedData?.roles?.map((data, index) => {
                                                return (
                                                    <Tag
                                                        color="blue"
                                                        style={{ marginBottom: '5px', borderRadius: '8px', padding: '5px', borderColor: 'transparent' }}
                                                        key={data.id}>
                                                        <span className='textStyles-small' style={{ fontWeight: 500 }}>{data.name}</span>
                                                    </Tag>
                                                )
                                            })
                                        }
                                    </Descriptions.Item>
                                    <Descriptions.Item label={<span className='textStyles-small'>Groups</span>}>
                                        {
                                            selectedData?.groups?.map((data, index) => {
                                                return (
                                                    <Tag
                                                        color="green"
                                                        style={{ marginBottom: '5px', borderRadius: '8px', padding: '5px', borderColor: 'transparent' }}
                                                        key={data.id}>
                                                        <span className='textStyles-small' style={{ fontWeight: 500 }}>{data.name}</span>
                                                    </Tag>
                                                )
                                            })
                                        }
                                    </Descriptions.Item>
                                    <Descriptions.Item label={<span className='textStyles-small'>Regions</span>}>
                                        {
                                            selectedData?.regions?.map((data, index) => {
                                                return (
                                                    <Tag
                                                        color="orange"
                                                        style={{ marginBottom: '5px', borderRadius: '8px', padding: '5px', borderColor: 'transparent' }}
                                                        key={data.id}>
                                                        <span className='textStyles-small' style={{ fontWeight: 500 }}>{data.name}</span>
                                                    </Tag>
                                                )
                                            })
                                        }
                                    </Descriptions.Item>
                                    <Descriptions.Item label={<span className='textStyles-small'>Depots</span>}>
                                        {
                                            selectedData?.depots?.map((data, index) => {
                                                return (
                                                    <Tag
                                                        color="purple"
                                                        style={{ marginBottom: '5px', borderRadius: '8px', padding: '5px', borderColor: 'transparent' }}
                                                        key={data.id}>
                                                        <span className='textStyles-small' style={{ fontWeight: 500 }}>{data.name}</span>
                                                    </Tag>
                                                )
                                            })
                                        }
                                    </Descriptions.Item>
                                    <Descriptions.Item label={<span className='textStyles-small'>Map View Regions</span>}>
                                        {
                                            mapModuleRegionList?.map((data, index) => {
                                                return (
                                                    <Tag
                                                        color="magenta"
                                                        style={{ marginBottom: '5px', borderRadius: '8px', padding: '5px', borderColor: 'transparent' }}
                                                        key={data.id}>
                                                        <span className='textStyles-small' style={{ fontWeight: 500 }}>{data.name}</span>
                                                    </Tag>
                                                )
                                            })
                                        }
                                    </Descriptions.Item>
                                </Descriptions>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={<span className='textStyles-small'>Site Access Permissions</span>} key="2">
                                <Spin tip={<span className='textStyles-small'>Waiting For Update Site Access Permissions</span>}
                                    spinning={isLoading}>
                                    <Table
                                        className='table-striped-rows'
                                        columns={accessPermissionConfigTable}
                                        dataSource={selectedData?.groups}
                                        search={true}
                                        size='small'
                                        pagination={false}
                                    />
                                </Spin>
                            </Tabs.TabPane>
                        </Tabs>
                    ) : null
                }
            </Modal>
        </body>
    );
}

export default RoleModule;