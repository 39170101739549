import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { FileImageOutlined, FileProtectOutlined, AlignRightOutlined, ClockCircleOutlined, ExceptionOutlined, CheckSquareOutlined, CalendarOutlined, BarsOutlined } from '@ant-design/icons';
import { Empty, Divider, Space, Avatar, Card, Skeleton, Switch, Table, Select, Badge, Input, Button, Row, Upload, Col, Dropdown, Tag, Modal, Steps, message, Form, DatePicker, TimePicker, Descriptions, Image, Radio, Checkbox, Collapse } from 'antd';
import { getCrChecklistComponentsREWAMP, getKeyValueDetailsByIdAndComponentIdREWAMP, getPisHistoryGenaralDetailsById } from "../../../api/index";
import moment from 'moment';
const { Step } = Steps;
const image_url = "https://storage.googleapis.com/dns_storage_v2/requestimages/";
const baseAPI = "/civilRoutineService/getRequestDetailsByIdLite";

const CRDetailsView = () => {
    const { id, type } = useParams();
    const [sections, setSections] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedCard, setSelectedCard] = useState({
        id: null,
        name: null,

    });
    const [selectedSection, setSelectedSection] = useState(null);
    const [generalDetails, setGeneralDetails] = useState(null);
    const [siteImgSection, setSiteImgSection] = useState(null);

useEffect(() => {
    getCrChecklistComponents();
    getPISGenaralDeatils(id);
}, [id]);

    const getCrChecklistComponents = async () => {
        const response = await getCrChecklistComponentsREWAMP();
        if (response) {
            setSections(response?.data);
            setLoading(false);
        }
    }

    const getPISGenaralDeatils = async (id) => {
        const formData = new FormData();
        formData.append('requestId', id);
        const response = await getPisHistoryGenaralDetailsById(baseAPI, formData);
        if (response) {
            setGeneralDetails(response?.data);
        } else {
            message.error('Failed to fetch data');
            setGeneralDetails(null);
        }
    }

    const getKeyValueDetailsByIdAndComponentId = async (componentId) => {
        setSelectedSection(null);
        // setSiteImgSection(null);
        const formData = new FormData();
        const requestJson = JSON.stringify({
            "id": id,
            "compId": componentId
        });
        formData.append('Request', requestJson);
        const response = await getKeyValueDetailsByIdAndComponentIdREWAMP(formData);
        if (response) {
            setSelectedSection(response?.data);

        } else {
            message.error('Failed to fetch data');
            setSelectedSection(null);
        }
    }
    
    const allCheckLists = selectedSection?.length || 0;
    const allCheckListData = [];
    const imageCheckListData = [];

    for (let i = 0; i < allCheckLists; i++) {
        for (let j = 0; j < selectedSection[i]?.data.length; j++) {
            const type = selectedSection[i]?.data[j]?.type;
            if (type === 'image') {
                imageCheckListData.push({
                    key: selectedSection[i]?.data[j]?.id,
                    mainSectionName: selectedSection[i]?.section?.name ? selectedSection[i]?.section?.name : 'N/A',
                    sectionName: selectedSection[i]?.data[j]?.name ? selectedSection[i]?.data[j]?.name : 'N/A',
                    itemName: selectedSection[i]?.data[j]?.name ? selectedSection[i]?.data[j]?.name : 'N/A',
                    answer: selectedSection[i]?.data[j]?.value ? selectedSection[i]?.data[j]?.value : 'N/A',
                    type: type ? type : 'N/A',
                    conditions: selectedSection[i]?.data[j]?.conditions ? selectedSection[i]?.data[j]?.conditions : 'N/A',
                    actionType: selectedSection[i]?.data[j]?.actionType ? selectedSection[i]?.data[j]?.actionType : 'N/A',
                    remark: selectedSection[i]?.data[j]?.remark ? selectedSection[i]?.data[j]?.remark : 'N/A',
                });
            } else {
                allCheckListData.push({
                    key: selectedSection[i]?.data[j]?.id,
                    mainSectionName: selectedSection[i]?.section?.name ? selectedSection[i]?.section?.name : 'N/A',
                    sectionName: selectedSection[i]?.data[j]?.name ? selectedSection[i]?.data[j]?.name : 'N/A',
                    itemName: selectedSection[i]?.data[j]?.name ? selectedSection[i]?.data[j]?.name : 'N/A',
                    answer: selectedSection[i]?.data[j]?.value ? selectedSection[i]?.data[j]?.value : 'N/A',
                    type: type ? type : 'N/A',
                    conditions: selectedSection[i]?.data[j]?.conditions ? selectedSection[i]?.data[j]?.conditions : 'N/A',
                    actionType: selectedSection[i]?.data[j]?.actionType ? selectedSection[i]?.data[j]?.actionType : 'N/A',
                    remark: selectedSection[i]?.data[j]?.remark ? selectedSection[i]?.data[j]?.remark : 'N/A',
                });
            }
        }
    }

    console.log("All CheckList Images:", imageCheckListData);
    console.log("All CheckList Data:", allCheckListData);

    // setSiteImgSection(imageCheckListData);

    const sectionDataMap = {};
    allCheckListData.forEach((item) => {
        const mainSectionName = item.mainSectionName;
        if (!sectionDataMap[mainSectionName]) {
            sectionDataMap[mainSectionName] = [];
        }
        sectionDataMap[mainSectionName].push(item);
    });

    const imageSectionDataMap = {};
    imageCheckListData.forEach((item) => {
        const mainSectionName = item.mainSectionName;
        if (!imageSectionDataMap[mainSectionName]) {
            imageSectionDataMap[mainSectionName] = [];
        }
        imageSectionDataMap[mainSectionName].push(item);
    });

    console.log("Section Data Map:", imageSectionDataMap);

    const columns = [
        {
            title: <span className='textStyle-small'>Section Name</span>,
            dataIndex: 'sectionName',
            key: 'sectionName',
            render: (text, record) => (
                <div>
                        <span className='textStyle-small' style={{ textTransform: 'capitalize', fontSize: 12 }}>
                            {text?.replace(/_/g, ' ')}
                        </span>
                </div>
            ),
            width: 150,
        },
        {
            title: <span className='textStyle-small'>Answer</span>,
            dataIndex: 'answer',
            key: 'answer',
            render: (text, record) => (
                <div>
                    {text === 'N/A' && record.type !== 'Image' ? (
                        <div>
                            <Tag color='red' style={{ padding: 5, borderRadius: 5 }} key={text} className="textStyles-small">
                                <span style={{ color: 'red', fontWeight: 550, fontSize: '11px' }} className="textStyles-small">
                                    Action Type : {record.actionType}
                                </span>
                            </Tag>

                            <Tag color='blue' style={{ padding: 5, borderRadius: 5 }} key={text} className="textStyles-small">
                                <span style={{ color: 'blue', fontWeight: 550, fontSize: '11px' }} className="textStyles-small">
                                    Conditions: {record.conditions}
                                </span>
                            </Tag>

                            <Tag color='purple' style={{ padding: 5, borderRadius: 5 }} key={text} className="textStyles-small">
                                <span style={{ color: 'purple', fontWeight: 550, fontSize: '12px' }} className="textStyles-small">
                                    Remark: {record.remark}
                                </span>
                            </Tag>
                        </div>
                    ) : (
                        <Tag color='#1FAD4B36' style={{ padding: 5, borderRadius: 5 }} key={text} className="textStyles-small">
                            <span style={{ color: '#1FAD4B', fontWeight: 550, fontSize: '11px' }} className="textStyles-small">
                                {text}
                            </span>
                        </Tag>
                    )}
                </div>
            ),
            width: 200,
        },
    ];


    const combineFix = Object.keys(sectionDataMap).map((mainSectionName) => {
        const dataSource = sectionDataMap[mainSectionName];
        const imageDataSource = imageSectionDataMap[mainSectionName] || [];
        return (
            <div key={mainSectionName} style={{
                padding: 10,
                backgroundColor: 'white',
                borderRadius: 5,
                marginTop: 20,
                width: '100%',
            }}>
                <h5 className='textStyle-small'
                    style={{ fontSize: 14 }}
                ><FileProtectOutlined /> {mainSectionName}</h5>
                <Table
                    dataSource={dataSource}
                    className="table-striped-rows"
                    columns={columns}
                    pagination={false}
                    size='small'
                    locale={{ emptyText: 'No data' }}
                />
                <br /><br />
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {imageDataSource.map((item) => (
                        <div key={item.key} style={{ margin: '10px' }}>
                            <h6 style={{
                                textTransform: 'capitalize', fontSize: 12, fontWeight: 'bold'
                            }}> <FileImageOutlined /> {item.itemName.replace(/_/g, ' ')}</h6>
                            <Image
                                width={200}
                                height={200}
                                src={image_url + (item.answer ? item.answer : "error")}
                                style={{ cursor: 'pointer', borderRadius: 10 }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    });

    const dataWithSectionNameImages = selectedSection?.filter(item => item?.section?.name === 'IMAGES');

    console.log("Data With Section Name Images:", dataWithSectionNameImages);

    if (dataWithSectionNameImages?.length > 0) {
        const imagesData = dataWithSectionNameImages[0]?.data;
        const imageValues = imagesData?.map(image => image?.value);
        console.log("Image Values:", imageValues);
    } else {
        console.log("No data found with section name 'IMAGES'");
    }

    const renderImagesSection = dataWithSectionNameImages?.map((section) => {
        const imagesData = section?.data;
        return (
            <div style={{ padding: 10, backgroundColor: 'white', borderRadius: 5, marginTop: 20, width: '100%' }}>
                <h5 className='textStyle-small' style={{ fontSize: 14 }}><FileImageOutlined /> {section?.section?.name}</h5>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {imagesData?.map((item) => (
                        <div key={item?.id} style={{ margin: '10px' }}>
                            <h6 style={{ textTransform: 'capitalize', fontSize: 12, fontWeight: 'bold' }}> 
                            <FileImageOutlined /> {item?.name.replace(/_/g, ' ')}</h6>
                            <Image
                                width={200}
                                height={200}
                                src={item?.value ? image_url + item?.value : "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg"}
                                style={{ cursor: 'pointer', borderRadius: 10 }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    });

    const mainSections = [];
    imageCheckListData.forEach((item) => {
        if (!mainSections.includes(item.mainSectionName)) {
            mainSections.push(item.mainSectionName);
        }
    });
    
    const combinedRender = (
        <Fragment>
            {
                selectedCard?.name !== 'SITE IMAGES' && (
                    <Fragment>
                        {combineFix}
                        {renderImagesSection}
                    </Fragment>
                )}
            {
                selectedCard?.name === 'SITE IMAGES' && (
                    mainSections.map((section, sectionIndex) => (
                        <div key={sectionIndex} style={{ padding: 10, backgroundColor: 'white', borderRadius: 5, marginTop: 20, width: '100%' }}>
                            <h5 className='textStyle-small' style={{ fontSize: 14 }}>
                                <FileImageOutlined /> {section}
                            </h5>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {imageCheckListData
                                    .filter(item => item.mainSectionName === section)
                                    .map(item => (
                                        <div key={item.key} style={{ margin: '10px' }}>
                                            <h6 style={{ textTransform: 'capitalize', fontSize: 12, fontWeight: 'bold' }}>
                                                <FileImageOutlined /> {item.itemName.replace(/_/g, ' ')}
                                            </h6>
                                            <Image
                                                width={200}
                                                height={200}
                                                src={item.answer ? image_url + item.answer : "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg"}
                                                style={{ cursor: 'pointer', borderRadius: 10 }}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                )
            }
        </Fragment>
    );

    return (
        <>
        {
            generalDetails ? (
                    <div className="col-12">
                        <div className="card" style={{ margin: 20, borderRadius: 12, padding: 10, backgroundColor: 'white', borderColor: 'white' }}>
                            <div class="card-body">
                                <div class="dastone-profile">
                                    <div class="row">
                                        <div class="col-lg-4 align-self-center mb-3 mb-lg-0">
                                            <div class="dastone-profile-main">
                                                <div class="dastone-profile-main-pic">
                                                    <img src={'https://static.vecteezy.com/system/resources/previews/001/902/502/large_2x/telecommunication-radio-antenna-and-satelite-tower-with-blue-sky-free-photo.JPG'} alt="" height="110" class="rounded-circle"></img>
                                                </div>
                                                <div class="dastone-profile_user-detail">
                                                        <h5 className='textStyles-small' style={{ fontSize: 18, fontWeight: 550 }}>{generalDetails?.siteName}</h5>
                                                        <Tag color='#C4C4C452' style={{ padding: 5, borderRadius: 5, marginTop: 3 }} className="textStyles-small">
                                                            <span style={{ color: '#979797', fontWeight: 550, fontSize: '14px' }} className="textStyles-small">
                                                                {generalDetails?.siteId}
                                                            </span>
                                                        </Tag>
                                                    </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-5 ms-auto align-self-center">
                                            <ul class="list-unstyled personal-detail mb-0">
                                            <li className="mt-2"><AlignRightOutlined style={{ marginRight: 2 }} /> <span className='textStyles-small' style={{ fontWeight: 600, marginRight: 5 }}>Code: </span>
                                                        <span className='textStyles-small'>
                                                            {generalDetails?.requestCode}
                                                        </span></li>
                                                    <li className="mt-2"><ClockCircleOutlined style={{ marginRight: 2 }} /> <span className='textStyles-small' style={{ fontWeight: 600, marginRight: 5 }}>Status: </span>
                                                        <Tag color={generalDetails?.colorCode?.replace("0xFF", "#")} style={{ padding: 5, borderRadius: 5 }} className="textStyles-small">
                                                            <span style={{ color: 'white', fontWeight: 550, fontSize: '12px' }} className="textStyles-small">
                                                                {generalDetails.status}
                                                            </span>
                                                        </Tag>
                                                    </li>
                                                    <li className="mt-2"><ExceptionOutlined style={{ marginRight: 2 }} /> <span className='textStyles-small' style={{ fontWeight: 600, marginRight: 5 }}>
                                                        Request Type:
                                                    </span><span className='textStyles-small'>
                                                            {generalDetails?.requestType}
                                                        </span></li>
                                            </ul>
                                        </div>
                                        <div class="col-lg-3 align-self-center">
                                            <div class="row">
                                                <ul className="list-unstyled personal-detail mb-0" style={{ listStyleType: 'none' }}>
                                                    <li className="mt-1"> <CheckSquareOutlined style={{ marginRight: 3 }} /><span className='textStyles-small' style={{ fontWeight: 600, marginRight: 5 }}>Created Date:</span><span className='textStyles-small'>
                                                        {moment(generalDetails?.createdDate).format('YYYY-MM-DD HH:mm')}</span></li>
                                                    <li className="mt-1"><CalendarOutlined style={{ marginRight: 3 }} /><span className='textStyles-small' style={{ fontWeight: 600, marginRight: 5 }}>Request Date:</span><span className='textStyles-small'>
                                                        {moment(generalDetails?.requestDate).format('YYYY-MM-DD')}</span></li>
                                                    <li className="mt-1"><CalendarOutlined style={{ marginRight: 3 }} /><span className='textStyles-small' style={{ fontWeight: 600, marginRight: 5 }}>Service Date:</span><span className='textStyles-small'>
                                                        {moment(generalDetails?.serviceDate).format('YYYY-MM-DD')}</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
            ) : (
                <div style={{ margin: 20, borderRadius: 10, padding: 12, backgroundColor: 'white'}}>
                <span className='textStyle-small' style={{ fontSize: 16 }}>{selectedCard?.name}</span>
                {combinedRender ? combinedRender : <Empty />}
            </div>
            )
        }


                    
            <div style={{ margin: 20, borderRadius: 12, padding: 10, backgroundColor: 'white'}}>
                <Row>
                    {
                        sections?.map((section, index) => {
                            return (
                                <Card key={index}
                                    style={{
                                        width: 200, margin: 2, cursor: 'pointer',
                                        backgroundColor: selectedCard?.id === section?.id ? '#f0f0f0' : 'white', borderRadius: 10
                                    }}
                                    onClick={() => {
                                        setSelectedCard({
                                            id: section?.id,
                                            name: section?.name,
                                        });
                                        getKeyValueDetailsByIdAndComponentId(section?.id);
                                    }}
                                >
                                    <BarsOutlined />
                                    <span className='textStyle-small' style={{ marginLeft: 10, fontSize: 12 }}>
                                        {section?.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                    </span>
                                </Card>
                            )
                        })
                    }
                </Row>
            </div>

            {
                selectedSection === null ? (
                    <div style={{ margin: 20, borderRadius: 12, padding: 10, backgroundColor: 'white'}}>
                        <Skeleton active />
                    </div>
                ) : (
                    <div style={{ margin: 20, borderRadius: 12, padding: 10, backgroundColor: 'white'}}>
                        <span className='textStyle-small' style={{ fontSize: 16 }}>{selectedCard?.name}</span>
                        {combinedRender ? combinedRender : <Empty />}
                    </div>
                )
            }
        </>
    )
}

export default CRDetailsView;
