import React, { Component } from "react";
import { connect } from "react-redux";
import { getSiteListByPage, filterSiteData } from "../../api/Repos/SiteRepo";
import { Link } from "react-router-dom";
// import Select from 'react-select'
import { Collapse } from "reactstrap";
import { DatePicker, Table, List, Skeleton, Select, Tag, Row, Col, Input, Button, message, Menu, Dropdown, Modal } from "antd";
import { getDonationCategories, getData } from "../../actions/index";
import {
  getAllDepotList,
  getSitesLiteForSiteManage,
  getAllSiteCategories,
  removeSite
} from "../../api/index";
import '../master-data/style.css';
import { checkServiceType } from "../../actions/service-type";
import {ReloadOutlined, SearchOutlined, EnvironmentOutlined, PlusOutlined, FilterOutlined, CloseCircleOutlined, EditOutlined, DownOutlined, ExclamationCircleOutlined, DeleteFilled, DeleteOutlined, MinusCircleOutlined } from '@ant-design/icons';

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
const { Option } = Select;
class Site extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      editVisibilty: false,
      site_list: [],
      site_name: "",
      site_id: "",
      depotList: [],
      depot_list_new: [],
      isOpen: false,
      loading: true,
      depotId: "",
      setLogin: false,
      AssetManagementPermission: false,
      filterButton: true,
      setSearchValue: "",
      filteredTable: null,
      setValues: null,
      depot_list: [],
      sitwCategory: [],
      filterRegion: null,
      filterDepot: null,
      filterCategory: null,
      fullAllSite: [],
    };
  }

  componentDidMount() {
    this.props.getData();
    this.getAllDepotList();
    console.log("checkServiceType");
    console.log(this.props);
    this.setState({
      depot_list: this.props.depots,
    });

    checkServiceType(38).then((data) =>
      this.setState(
        {
          AssetManagementPermission: data,
        },
        () => {
          console.log(this.state.AssetManagementPermission);
        }
      )
    );

    if (JSON.parse(localStorage.getItem("serviceTypeSet")) == null) {
      this.setState({
        setLogin: true,
      });
    }
    // if (JSON.parse(localStorage.getItem('serviceTypeSet')) == null) {
    // 	//window.location.reload(false);

    // } else {
    // 	this.getSetPageData(JSON.parse(localStorage.getItem('serviceTypeSet')), 13)
    // }
    //	this.getAllSites()
    this.getSitesLiteForSiteManage();
    this.getAllSiteCategories();
  }
  getAllSiteCategories = async () => {
    var res = await getAllSiteCategories();
    console.log("getAllSiteCategories");
    console.log(res);
    if (res.success) {
      this.setState({
        sitwCategory: res.data,
      });
    }
  };
      // error message modal define here
  showErrorMessage = (message) => {
    Modal.error({
      title: 'Error',
      content: <span className='textStyles-small'>{message}</span>,
      onOk() { },
    });
  };

  removeSitesFromList = async (siteID) => {
    const fromData = new FormData();
    fromData.append("siteId", siteID);
    const res = await removeSite(fromData);
    console.log(res);
    if (res.success) {
      message.success("Site deactivated successfully");
      localStorage.removeItem("siteInfo");
      this.getSitesLiteForSiteManage();
    } else {
      this.showErrorMessage(res.data.data);
    }
  }

  localStorageSaverFunction = (data) => {
    const numberOfDaysCacheAvailable = 1;
    const currentTime = new Date().getTime();
    const cacheExpiryTime = localStorage.getItem("cacheExpiryTime");
    if (!localStorage.getItem("siteInfo") || currentTime > cacheExpiryTime) {
        localStorage.setItem("siteInfo", JSON.stringify(data));
        localStorage.setItem("cachingTime", currentTime);
        const expiryTime = currentTime + numberOfDaysCacheAvailable * 24 * 60 * 60 * 1000; 
        localStorage.setItem("cacheExpiryTime", expiryTime);
    }
};

checkLocalStorageCache = () => {
  const currentTime = new Date().getTime();
  const cacheExpiryTime = localStorage.getItem("cacheExpiryTime");
  const siteInfo = JSON.parse(localStorage.getItem("siteInfo"));
  if (siteInfo && Object.keys(siteInfo).length > 0 && currentTime <= cacheExpiryTime) {
      return true;
  }
  return false;
};

  // getSitesLiteForSiteManage = async () => {
  //   this.setState({
  //     loading: true,
  //     site_list: [],
  //   });
  //   var res = await getSitesLiteForSiteManage();
  //   console.log("getSitesLiteForSiteManage");
  //   console.log(res);
  //   if (res.success) {
  //     this.localStorageSaverFunction(res.data);
  //     if (this.state.setValues == null) {
  //       this.setState({
  //         site_list: res.data,
  //         fullAllSite: res.data,
  //         loading: false,
  //       });
  //     } else {
  //       this.setState(
  //         {
  //           site_list: res.data,
  //           fullAllSite: res.data,
  //           loading: false,
  //         },
  //         () => {
  //           const filtered = this.state.site_list.filter(
  //             (user) =>
  //               user?.site_name
  //                 ?.toLowerCase()
  //                 .includes(this.state.setValues.toLowerCase()) ||
  //               user?.site_id
  //                 ?.toLowerCase()
  //                 .includes(this.state.setValues.toLowerCase())
  //           );
  //           this.setState({
  //             filteredTable: filtered,
  //           });
  //         }
  //       );
  //     }
  //   }
  // };

  getSitesLiteForSiteManage = async () => {
    this.setState({
        loading: true,
        site_list: [],
    });

    // Check if cache is available
    if (this.checkLocalStorageCache()) {
        // Use cached data
        var res = {
            success: true,
            data: JSON.parse(localStorage.getItem("siteInfo"))
        };
    } else {
        // Fetch data and save to cache
        var res = await getSitesLiteForSiteManage();
        console.log("getSitesLiteForSiteManage");
        console.log(res);
        if (res.success) {
            this.localStorageSaverFunction(res.data);
        }
    }

    if (res.success) {
        if (this.state.setValues == null) {
            this.setState({
                site_list: res.data,
                fullAllSite: res.data,
                loading: false,
            });
        } else {
            this.setState(
                {
                    site_list: res.data,
                    fullAllSite: res.data,
                    loading: false,
                },
                () => {
                    const filtered = this.state.site_list.filter(
                        (user) =>
                            user?.site_name
                                ?.toLowerCase()
                                .includes(this.state.setValues.toLowerCase()) ||
                            user?.site_id
                                ?.toLowerCase()
                                .includes(this.state.setValues.toLowerCase())
                    );
                    this.setState({
                        filteredTable: filtered,
                    });
                }
            );
        }
    }
};


  getSetPageData = (serviceTypeIs, Pageid) => {
    if (
      serviceTypeIs.serviceType.filter(
        (checkuser) => checkuser?.id == Pageid
      )[0] == null
    ) {
      this.setState({
        editVisibilty: false,
      });
    } else {
      this.setState({
        editVisibilty: true,
      });
    }
  };
  getAllDepotList = async () => {
    var res = await getAllDepotList();
    this.setState({
      loadingDepot: true,
      depot_list_new: [],
    });
    console.log(res);
    if (res.success) {
      this.setState(
        {
          depot_list_new: res.data,
          loadingDepot: false,
        },
        () => {
          var _depotList = [];

          this.state.depot_list_new.map((depot) => {
            _depotList.push({ value: depot.id, label: depot.name });
          });

          this.setState({
            depotList: _depotList,
          });
        }
      );
    }
  };

  getAllSites = async () => {
    var res = await getSiteListByPage(this.state.pageNo);
    this.setState({
      loading: true,
      site_list: [],
      fullAllSite: [],
    });
    if (res.success) {
      this.setState({
        site_list: res.data,
        loading: false,
        fullAllSite: res.data,
      });
    }
  };

  previousBtnClick = () => {
    if (this.state.pageNo > 1) {
      this.setState({
        loading: true,
        site_list: [],
      });
      var aa = this.state.pageNo - 1;
      this.setState(
        {
          pageNo: aa,
        },
        () => {
          this.getAllSites();
        }
      );
    }
  };

  nextBtnClick = () => {
    this.setState({
      loading: true,
      site_list: [],
    });
    var aa = this.state.pageNo + 1;
    this.setState(
      {
        pageNo: aa,
      },
      () => {
        this.getAllSites();
      }
    );
  };

  searchFormItemChange = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    //console.log(obj)
    this.setState(obj, () => {
      if (
        this.state.site_id != "" ||
        this.state.site_name != "" ||
        this.state.depotId != ""
      ) {
        this.setState({
          filterButton: true,
        });
      } else {
        this.setState({
          filterButton: false,
        });
      }
    });
  };

  searchDepottemChange = (event) => {
    this.setState(
      {
        depotId: event.value,
      },
      () => {
        if (
          this.state.site_id != "" ||
          this.state.site_name != "" ||
          this.state.depotId != ""
        ) {
          this.setState({
            filterButton: true,
          });
        } else {
          this.setState({
            filterButton: false,
          });
        }
      }
    );
  };

  viewFilterClick = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  filterBtnClick = async () => {
    this.setState({
      loading: true,
    });
    console.log(this.state.filterRegion);
    console.log(this.state.filterDepot);
    console.log(this.state.filterCategory);
    console.log(this.state.fullAllSite);
    let filtered = [];
    //const filtered = this.state.site_list.filter(site=>( site.patent_region_id==this.state.filterRegion && site.depot_id==this.state.filterDepot ));
    if (this.state.filterCategory != null) {
      filtered = this.state.fullAllSite
        .filter((site) =>
          this.state.filterDepot != null
            ? site.depot_id == this.state.filterDepot
            : site && this.state.filterRegion != null
            ? site.patent_region_id == this.state.filterRegion
            : site
        )
        .filter(
          (category) => category.site_category_id == this.state.filterCategory
        );
    } else {
      filtered = this.state.fullAllSite.filter((site) =>
        this.state.filterDepot != null
          ? site.depot_id == this.state.filterDepot
          : site && this.state.filterRegion != null
          ? site.patent_region_id == this.state.filterRegion
          : site
      );
    }
    console.log(filtered);
    this.setState({
      site_list: filtered,
      loading: false,
    });
  };

  clearBtnClick = () => {
    window.location.reload();
  };
  search = (value) => {
    this.setState({
      setValues: value,
    });

    const filtered = this.state.site_list.filter(
      (user) =>
        user?.site_name?.toLowerCase().includes(value.toLowerCase()) ||
        user?.site_id?.toLowerCase().includes(value.toLowerCase())
    );

    this.setState({
      filteredTable: filtered,
    });
  };
  onChangeRegion = (value) => {
    console.log(value);

    var dd = value;
    //console.log(dd)
    var subRegion = [];
    var _depotList1 = [];
    var _allSite = [];
    var subRegionIdArray = [];

    this.props.regions
      .filter((allret) => allret?.parentRegion?.id == dd)
      .map((subR) => {
        subRegionIdArray.push(subR.id);
      });

    this.setState(
      {
        selectRegion: subRegionIdArray,
        filterDepot: null,
        filterRegion: value == "undefined" ? null : value,
      },
      () => {
        if (
          this.state.filterRegion != null ||
          this.state.filterDepot != null ||
          this.state.filterCategory != null
        ) {
          this.setState({
            filterButton: true,
          });
        } else {
          this.setState({
            filterButton: true,
          });
        }
      }
    );

    subRegion = this.props.regions.filter(
      (allret) => allret?.parentRegion?.id == dd
    );
    this.props.depots
      .filter((alldate) =>
        subRegion.map((n) => n.id).includes(alldate.region?.id)
      )
      .map((depot) => {
        _depotList1.push({ id: depot?.id, name: depot?.name });
      });
    console.log("selectDepot");
    console.log(this.state.selectDepot);

    this.setState({
      depot_list: _depotList1,
    });
  };
  onChangeCategory = (value) => {
    this.setState(
      {
        // DepotName: dataa[value - 1]?.name,
        filterCategory: value == "undefined" ? null : value,
        selectType: 2,
        selectId: value,
        selectDepot: value,
      },
      () => {
        if (
          this.state.filterRegion != null ||
          this.state.filterDepot != null ||
          this.state.filterCategory != null
        ) {
          this.setState({
            filterButton: true,
          });
        } else {
          this.setState({
            filterButton: true,
          });
        }
      }
    );
  };
  onChangeDepot = (value) => {
    //console.log("onChangeDepot");
    console.log(value);

    this.setState(
      {
        // DepotName: dataa[value - 1]?.name,
        filterDepot: value == "undefined" ? null : value,
        selectType: 2,
        selectId: value,
        selectDepot: value,
      },
      () => {
        if (
          this.state.filterRegion != null ||
          this.state.filterDepot != null ||
          this.state.filterCategory != null
        ) {
          this.setState({
            filterButton: true,
          });
        } else {
          this.setState({
            filterButton: true,
          });
        }
      }
    );
  };

  render() {
    const columns123 = [
      {
        title: <span className='textStyles-small' style={{fontSize: 12}}>Site ID</span>,
        render: (text, record, index) => (
          <span className='textStyles-small' >
              <Link className='textStyles-small' to={"/site-summary/" + text.id}>{text?.site_id}</Link>
          </span>
        ),
      },
      {
        title: <span className='textStyles-small' style={{fontSize: 12}}>Site Name</span>,
        key: "siteName",
        render: (item) => (
          <span className='textStyles-small' >
              <Link className='textStyles-small' 
              style={{fontSize: 12}}
              to={"/site-summary/" + item.id}>{item?.site_name}</Link>
          </span>
        ),
      },
      {
        title: <span className='textStyles-small' style={{fontSize: 12}}>Region</span>,
        key: "accessPermissionCode",
        render: (item) => <span>{item.parent_region_name}</span>,
      },
      {
        title: <span className='textStyles-small' style={{fontSize: 12}}>Depot</span>,
        key: "accessPermissionCode",
        render: (item) => <span className='textStyles-small' >{item?.depot_name}</span>,
      },

      {
        title: <span className='textStyles-small' style={{fontSize: 12}}>Status</span>,
        dataIndex: "status",
        key: "operationTypeName",
        render: (item) => (
          <>
            <Tag color="#03d87f" key={item} style={{borderRadius: 5}}>
              <span className='textStyles-small' style={{fontSize: 10}}>{item}</span>
            </Tag>
          </>
        ),
      },
      {
        title: <span className='textStyles-small' style={{fontSize: 12}}>Site Category</span>,
        align: "center",
        key: "siteCategory",
        render: (item) => (
          <span class="badge badge-outline-primary badge-pill textStyles-small">
            {item?.site_category_name}
          </span>
        ),
      },
      {
        title: <span className='textStyles-small' style={{fontSize: 12}}>Depot Officer</span>,
        key: "DepotOfficer ",
        render: (item) => <span className='textStyles-small'>{item?.contact_person}</span>,
      },
      {
        title: <span className='textStyles-small' style={{fontSize: 12}}>Contact Number</span>,
        key: "contact_no",
        align: "right",
        width: "9%",
        render: (item) => (
          <span className='textStyles-small'>
            {item?.contact_no}
          </span>
        ),
      },
      this.state.AssetManagementPermission ? {
        title: <span className='textStyles-small' style={{ fontSize: 12 }}>Action</span>,
        key: "ContactNo",
        align: "center",
        hidden: true,
        render: (item) => {
          const menu = (
            <Menu>
              <Menu.Item key="list-loadmore-edit-1" icon={<EditOutlined style={{ color: 'green' }} />}>
                <Link to={"/sites/edit/" + item.id}>
                  <span className='textStyles-small'>Edit Site</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="list-loadmore-edit-3" icon={<MinusCircleOutlined style={{ color: 'red' }} />}>
                <span className='textStyles-small' onClick={() => showUpdateConfirmationModal(item.id)}>Deactivate Site</span>
              </Menu.Item>
            </Menu>
          );
      
          return (
            <Dropdown overlay={menu}>
              <Button
                type="primary"
                size="small"
                icon={<DownOutlined />}
                shape="circle" />
            </Dropdown>
          );
        },
      }
        : {},
    ];
    const showUpdateConfirmationModal = (siteID) => {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: <span className='textStyles-small'>Do you want to deactivate this site?</span>,
        okText: 'Yes',
        cancelText: 'No',
        onOk: async () => this.removeSitesFromList(siteID),
      });
    };

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title-box">
                <div className="row">
                  <div class="col">
                    <h4 class="page-title">Sites</h4>
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="javascript:void(0);">Admin</a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="javascript:void(0);">Manage Sites </a>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ marginTop: 10, backgroundColor: "white", padding: 15, borderRadius: 5 }}>
            <div className="row">
              <Row>
                <Col span={12}>
                  <Row>
                    {/* BUTTON FOR MAP */}
                    <Button
                      style={{ borderRadius: 5 }}
                      type="primary"
                      icon={<ReloadOutlined style={{ color: 'white' }} />}
                      onClick={() => {
                        localStorage.removeItem("siteInfo");
                        window.location.reload();
                      }}
                    ><span className='textStyles-small' style={{ color: 'white' }}>Refetch</span></Button>
                    <Link to="/map">
                      <Button
                        style={{ marginLeft: 10, backgroundColor: '#00AC4F', borderColor: '#00AC4F', borderRadius: 5 }}
                        icon={<EnvironmentOutlined style={{ color: 'white' }} />}
                        onClick={() => this.props.history.push("/map")}
                      ><span className='textStyles-small' style={{ color: 'white' }}>Map</span></Button>
                    </Link>
                    {/* BUTTON FOR CREATE SITE */}
                    {this.state.AssetManagementPermission && (
                      <Link to="/sites/create">
                        <Button
                          style={{ marginLeft: 10, backgroundColor: '#00AC4F', borderColor: '#00AC4F', borderRadius: 5 }}
                          icon={<PlusOutlined style={{ color: 'white' }} />}
                          onClick={() => this.props.history.push("/sites/create")}
                        ><span className='textStyles-small' style={{ color: 'white' }}>Create Site</span></Button>
                      </Link>
                    )}
                  </Row>
                </Col>
                <Col span={12}>
                  <Row align="middle" justify="end">
                    <Input
                      id="clearSearch"
                      enterButton
                      className="borderedSelect"
                      style={{ height: 34, width: '50%' }}
                      placeholder="Search by Site name or Site ID"
                      value={this.state.setSearchValue}
                      onChange={(e) => {
                        const currValue = e.target.value;
                        this.search(currValue);
                        this.setState({
                          setSearchValue: currValue,
                        });
                      }}
                    />
                    <Button
                      icon={<FilterOutlined style={{ color: 'white' }} />}
                      style={{ width: 34, height: 34, borderRadius: 5, marginLeft: 5, backgroundColor: "#00AC4F", borderColor: "#00AC4F"}}
                      class="btn btn-danger"
                      onClick={() => this.viewFilterClick()}
                    />
                  </Row>
                </Col>
              </Row>

              <Collapse isOpen={this.state.isOpen} style={{marginTop: 15}}>
                <div className="row" style={{backgroundColor: "#FAFAFA", padding: 10, borderRadius: 5}}>
                  <div>
                    <div class="row">
                      <div className="col-md-4">
                        <label class="mb-3"><span class="textStyles-small">Region</span></label>
                        <Select
                          showSearch
                          onChange={this.onChangeRegion}
                          name="regionId"
                          value={this.state.filterRegion}
                          placeholder="Select Regions"
                          bordered={false}
                          className="borderedSelect"
                          style={{ height: 34, width: '98%' }}
                          optionFilterProp="children"
                          allowClear
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {this.props.regions
                            .filter((allret) => "0".includes(allret.isSubRegion))
                            .map((item, index) => {
                              return (
                                <Option value={item?.id}>{item?.name}</Option>
                              );
                            })}
                        </Select>
                      </div>
                      <div class="col-md-4">
                        <label class="mb-3"><span class="textStyles-small">Depot</span></label>
                        <Select
                          showSearch
                          onChange={this.onChangeDepot}
                          value={this.state.filterDepot}
                          name="depotsId"
                          bordered={false}
                          className="borderedSelect"
                          style={{ height: 34, width: '98%' }}
                          placeholder="Select Depot"
                          optionFilterProp="children"
                          allowClear
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {this.state.depot_list.map((item, index) => {
                            return <Option value={item?.id}>{item?.name}</Option>;
                          })}
                        </Select>
                      </div>
                      <div class="col-md-4">
                        <label class="mb-3"><span class="textStyles-small">Category</span></label>
                        <Select
                          showSearch
                          onChange={this.onChangeCategory}
                          value={this.state.filterCategory}
                          name="depotsId"
                          bordered={false}
                          className="borderedSelect"
                          style={{ height: 34, width: '98%' }}
                          placeholder="Select Depot"
                          optionFilterProp="children"
                          allowClear
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {this.state.sitwCategory.map((item, index) => {
                            return <Option value={item?.id}>{item?.name}</Option>;
                          })}
                        </Select>
                      </div>
                      
                      <div class="col-md-12" style={{ marginTop: 10, display: 'flex', justifyContent: 'flex-end', marginLeft: 5 }}>
                        {this.state.filterButton ? (
                          <Button
                            icon={<SearchOutlined style={{ color: 'white' }} />}
                            style={{ width: 100, height: 34, borderRadius: 5, marginLeft: 5, backgroundColor: "#00AC4F", borderColor: "#00AC4F"}}
                            class="btn btn-primary"
                            onClick={() => this.filterBtnClick()}
                          >
                            <span className='textStyles-small' style={{ color: 'white' }}>Apply</span>
                          </Button>
                        ) : (
                          <Button
                            icon={<SearchOutlined style={{ color: 'white' }} />}
                            disabled
                            style={{ width: 100, height: 34, borderRadius: 5, marginLeft: 5, backgroundColor: "#00AC4F", borderColor: "#00AC4F"}}
                            class="btn btn-primary"
                            onClick={() => this.filterBtnClick()}
                          >
                            <span className='textStyles-small' style={{ color: 'white' }}>Apply</span>
                          </Button>
                        )}
                      
                        <Button
                          icon={<CloseCircleOutlined style={{ color: 'white' }} />}
                          style={{ width: 100, height: 34, borderRadius: 5, marginLeft: 5, backgroundColor: "red", borderColor: "red"}}
                          class="btn btn-dark"
                          onClick={() => this.clearBtnClick()}
                        >
                          <span className='textStyles-small' style={{ color: 'white' }}>Clear</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
              
              <Table
                size="small"
                className="table-striped-rows"
                style={{ marginTop: 10 }}
                columns={columns123}
                loading={this.state.loading}
                //dataSource={this.state.site_list}
                dataSource={
                  this.state.filteredTable == null
                    ? [...this.state.site_list].sort((a, b) => b.id - a.id)
                    : [...this.state.filteredTable].sort((a, b) => b.id - a.id)
                }
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  //console.log("state", state);
  return {
    organization_count: state.organization.length,
    regions: state.regions,
    depots: state.depots,
    sites_count: state.sites.length,
    vendor_count: state.vendor.length,
    roles_count: state.roles.length,
  };
}

export default connect(mapStateToProps, { getDonationCategories, getData })(
  Site
);

// export default connect(
// 	mapStateToProps,
// 	{}
// )(Site);
