import React from "react";
import { Flex, Box } from "rebass"
import { CardContainer } from "../essentials/containers";
import  { FiTruck, FiActivity, FiMapPin, FiWatch } from 'react-icons/fi';
import _ from "lodash";
import moment from "moment";

import "./style.css";

function Card({ value, from, header, className, style }){
    return(
        <CardContainer className={className} style={style}>
            <div className="dashboard-card-header center-content">{header}</div>
            <h3 className="dashboard-card-value large" style={{fontSize:40}}>{value}</h3><h4 className="dashboard-card-value small"> / {from}</h4>      
        </CardContainer>
    )
}
function Card2({ value, from, header, className, style }){
    return(
        <CardContainer className={className} style={style}>
            <div className="dashboard-card-header center-content">{header}</div>
            <h5 className="dashboard-card-value large" style={{fontSize:40}}>{value}</h5><h4 className="dashboard-card-value small"> </h4>      
        </CardContainer>
    )
}

function VehicleCard({ style, data=[], index, childComponent, primaryColor= "red", name="" }){
  
    return(
        _.find(data, ({id}) => id) ? <CardContainer key={index} className="card-vehicle-card" style={{borderColor: primaryColor, ...style}}>
            <div className="card-vehicle-header-container center-content-horozontal" style={{background: primaryColor}}>
                {
                    index
                }
            </div>
            {
                data.map(childComponent)
            }
        </CardContainer> : <div/>
    )
}

function getSmallName(_str){
    // return _str.split(" ")[0];
    return _str
}

function getStatus(_status){
    switch(_status){
        case "1":
            return ({
                className: 'l-started',
                column: "LOADING STARTED"
            });
        case "2":
            return ({
                className: 'l-completed',
                column: "LOADING COMPLETED"
            });
        case "3":
            return ({
                className: 'departure',
                column: "DEPARTURE"
            });
        case "4":
            return ({
                className: 'arrival',
                column: "ARRIVAL"
            });
        default: return "~";
    }
}

function VehicaleCell(d){
    

    var slaBreachNew=false;

    if(d.installedOrNot==false && d.omApprovedOrNot==false){

        const requestDataTime=d.requestDate+' '+d.requestTime;
        let nowdate =moment();
        let requestData =moment(requestDataTime);
        //console.log("newDate");
        //console.log(nowdate+" ddddd "+requestData);
        var ms = moment(nowdate,"DD/MM/YYYY HH:mm:ss").diff(moment(requestData,"DD/MM/YYYY HH:mm:ss"));
        var dg = moment.duration(ms);
        var s = Math.floor(dg.asHours()) + moment.utc(ms).format(":mm:ss");
        
        if(s.split(':')[0] >2){
            slaBreachNew=true;

        }else{
            slaBreachNew=false;
         
        
    }
}
if(d.installedOrNot==false && d.omApprovedOrNot==true){

    const requestDataTime=d.omApprovedTime;
    let nowdate =moment();
    let requestData =moment(requestDataTime);
    //console.log("newDate");
    //console.log(moment(nowdate,"DD/MM/YYYY HH:mm:ss")+" ddddd "+moment(requestData,"DD/MM/YYYY HH:mm:ss"));
    var ms = moment(nowdate,"DD/MM/YYYY HH:mm:ss").diff(moment(requestData,"DD/MM/YYYY HH:mm:ss"));
    var dg = moment.duration(ms);
    var s = Math.floor(dg.asHours()) + moment.utc(ms).format(":mm:ss");
    var slatime="2:00:00" 
    if(s.split(':')[0] >2){
        slaBreachNew=true;


    }else{
      slaBreachNew=false;
    
}
}
if(d.installedOrNot==true){
    slaBreachNew=d.isSLABreach;
}
//console.log("slaBreachNew")
//console.log(slaBreachNew)
    //const { className, column } = getStatus(d.status);
    return(
         <Flex className="card-vehicle-cell" m={1}>
            <Box width={1/2} className="vehicle-cell">{d.name}</Box>
            <Box width={1/2} className="vehicle-cell vehicle-cell-requse-num">{d.siteName}</Box>
           
            {slaBreachNew==false?( <Box width={1} className="vehicle-cell-status vehicle-cell" style={{ width: "fit-content",paddingRight:20,color:"green"}}>{slaBreachNew == false && ' Yes'}{slaBreachNew == true && ' No'}</Box>):( <Box width={1} className="vehicle-cell-status vehicle-cell" style={{ width: "fit-content",paddingRight:20,color:"red"}}>{slaBreachNew == false && ' Yes'}{slaBreachNew == true && ' No'}</Box>) }


           {d.statusId==3 || d.statusId==6 || d.statusId==7 ?( <Box width={1/2} className="delay-first-set vehicle-cell">{d.status}</Box> )
           :d.statusId==8 || d.statusId==9 ?( <Box width={1/2} className="delay-second-set">{d.status} </Box> ):d.statusId==13 || d.statusId==26 || d.statusId==30 ?( <Box width={1/2} className="delay-third-set">{d.status} </Box> ):( <Box width={1/2} className="delay vehicle-cell">{d.status} </Box> )}
           
           
        </Flex>
    )
}

function LocationContainer({children}){

    return(
        <React.Fragment>
            <Flex pt={0} pr={30} pb={0} pl={30} alignItems="center" className="card-vehicle-container-header">
                <Flex width={1/2} alignItems="center"><FiMapPin style={{margin: '0 5px'}}/>Depots</Flex>
                <Flex width={1/2} alignItems="center">Sites</Flex>
                <Flex width={1/2} alignItems="center"><FiActivity style={{margin: '0 5px'}}/>Within SLA</Flex>
                {/* <Flex width={1} alignItems="center"><FiArrowUpRight style={{margin: '0 5px'}}/>Departure Time</Flex> */}
                {/* <Flex width={1} alignItems="center"><FiArrowDownRight style={{margin: '0 5px'}}/>Arrival Time</Flex> */}
                <Flex width={1/2} alignItems="center" style={{display:"contents"}}>Status</Flex>
            </Flex>
            <div>{children}</div>
        </React.Fragment>
    )
}
function LocationContainer2({children}){

    return(
        <React.Fragment>
            <Flex pt={0} pr={30} pb={0} pl={30} alignItems="center" className="card-vehicle-container-header">
                <Flex width={1/3} alignItems="center"><FiMapPin style={{margin: '0 5px'}}/>Site </Flex>
                <Flex width={1/2} alignItems="center">Status</Flex>
                <Flex width={1/2} alignItems="center">Team status</Flex>
                <Flex width={1/2} alignItems="center">Requested time</Flex>
                <Flex width={1/2} alignItems="center"><FiActivity style={{margin: '0 5px'}}/>Within SLA</Flex>
                {/* <Flex width={1} alignItems="center"><FiArrowUpRight style={{margin: '0 5px'}}/>Departure Time</Flex> */}
                {/* <Flex width={1} alignItems="center"><FiArrowDownRight style={{margin: '0 5px'}}/>Arrival Time</Flex> */}
                <Flex width={1/2} alignItems="center"><FiWatch style={{margin: '0 5px'}}/>Installation time</Flex>
            </Flex>
            <div>{children}</div>
        </React.Fragment>
    )
}

export { Card, VehicleCard, LocationContainer,VehicaleCell ,Card2,LocationContainer2}