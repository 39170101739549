import React, { Component } from 'react';
// import type { UploadProps } from 'antd';/
import { Table, Input, Tag, Space, Select, Form, DatePicker, TimePicker, Button, Switch, message, Upload, Modal, Icon } from "antd";
import SimpleReactValidator from "simple-react-validator";
import { getDonationCategories, getData } from "../../actions/index";
import { connect } from "react-redux";
import { getSitesByuser, getUserSites, getGroupBySite, getHandlersBySite, getAllActiveUsers, getSiteAccessBaseData, siteAccessCreate } from "../../api/index";
import { PlusCircleOutlined, DeleteOutlined, UploadOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import _ from "lodash";
import { withRouter } from 'react-router-dom'
import ReactLoading from 'react-loading';
import * as XLSX from "xlsx";
//import fs from 'fs'
//import { threadId } from 'worker_threads';
//import SimpleReactValidator from 'simple-react-validator';

const format = 'HH:mm';
const valuesArrObj = [];
const valuesArrObjInst = [];
const valuesArrObjVhe = [];
const valuesArrObjVisitor = [];
const AllDataObject = [];
// const [form] = Form.useForm();
const importExcel = (file, addItem) => {
    const fileReader = new FileReader();
    fileReader.onload = event => {
        try {
            const { result } = event.target;
            const workbook = XLSX.read(result, { type: "binary" });
            for (const Sheet in workbook.Sheets) {
                // var XL_row_object =
                XLSX.utils.sheet_to_row_object_array(workbook.Sheets['Sheet1']);
                if (workbook.Sheets.hasOwnProperty(Sheet)) {

                    XLSX.utils.sheet_to_row_object_array(workbook.Sheets[Sheet]).forEach(x => {
                        addItem(x.Name, x.ContactNumber, x.NIC, x.Company);
                    });
                }
            }
            message.success("upload success!");
        } catch (e) {
            message = message.error("file type is incorrect!");
        }
    };
    fileReader.readAsBinaryString(file);
};
class Create extends Component {

    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.validatorall = new SimpleReactValidator();
        this.validatorequ = new SimpleReactValidator();
        this.validatorins = new SimpleReactValidator();
        this.validatorNewuser = new SimpleReactValidator();

        this.state = {
            previewVisible: false,
            previewImage: "",
            allSite: [],
            AllHandlersBySite: [],
            AllUsers: [],
            allSite_loading: true,
            submit_loading: false,
            selectedSiteId: "",
            selectedHandler: "",
            selectedGroupId: "",
            selectedGroupName: "",
            equ_itemName: "",

            projectName: "",
            scopeofWork: "",
            downTimeRequiredDate: "",
            nameofTeamLeader: "",
            contactNoofTeamLeader: '',

            equ_sirialNum: "",
            equ_actionType: "",
            equ_description: "",
            equ_qty: "",
            equipmentArray: [],
            ins_itemName: "",
            ins_sirialNum: "",
            ins_actionType: "",
            ins_description: "",
            ins_qty: "",
            insArray: [],
            vhe_no: "",
            vhe_type: "",

            selectWorkArea: [],
            selectWorkType: [],
            vheArray: [],
            SiteAccessBaseDataVisitingPurposes: [],
            SiteAccessBaseDataAccessNeed: [],
            SiteAccessBaseDatarelatedTechnologys: [],
            referenceNo: null,
            workDescription: null,
            scheduleType: {
                "id": 1
            },
            operationType: { "id": 1 },
            visitingPurpose: null,
            workArea: null,
            workType: null,
            workRemarks: null,
            accessNeed: null,
            relatedTechnology: null,
            sites: [],
            handler: null,
            scheduleStartOn: null,
            scheduleEndOn: null,
            dayOfWeek: "",
            dayOfMonth: "",
            accessDate: moment().format('YYYY-MM-DD'),
            accessEndDate:'',
            accessStartOn: moment().format("HH:mm"),
            workDuration: "",
            visitors: [],
            equipments: [],
            instruments: [],
            accessVehicles: [],
            alldataset: [],
            isOpen: false,
            recurrentStatus: false,
            formValidatorTest: "dfhfkdjfk",
            newUserName: null,
            newUserConNum: null,
            newUsernic: null,
            newUserCompany: null,
            newUserSelectTo: "",
            selectOpararionType: 1,
            uploadFiles: [],
            fileList: []







        };
        this.handleChangeItmeName = this.handleChangeItmeName.bind(this);
        this.handleChangeActionType = this.handleChangeActionType.bind(this);
        this.handleChangeSerialNum = this.handleChangeSerialNum.bind(this);
        this.handleChangeDescription = this.handleChangeDescription.bind(this);
        this.handleChangetUserName = this.handleChangetUserName.bind(this);
        this.handleChangetUserContactNumber = this.handleChangetUserContactNumber.bind(this);
        this.handleChangetUserNic = this.handleChangetUserNic.bind(this);
        this.handleChangetUserCompany = this.handleChangetUserCompany.bind(this);


        this.handleChangeQty = this.handleChangeQty.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleChangeInsItmeName = this.handleChangeInsItmeName.bind(this);
        this.handleChangeInsActionType = this.handleChangeInsActionType.bind(this);
        this.handleChangeInsSerialNum = this.handleChangeInsSerialNum.bind(this);
        this.handleChangeInsDescription = this.handleChangeInsDescription.bind(this);
        this.handleChangeInsQty = this.handleChangeInsQty.bind(this);
        this.handleInsSubmit = this.handleInsSubmit.bind(this);

        this.handleChangeVheNo = this.handleChangeVheNo.bind(this);
        this.handleChangeVheType = this.handleChangeVheType.bind(this);
        this.handleVheSubmit = this.handleVheSubmit.bind(this);

        this.handleChangeRefNo = this.handleChangeRefNo.bind(this);
        this.handleChangeWorkDes = this.handleChangeWorkDes.bind(this);
        this.handleRemarks = this.handleRemarks.bind(this);
        this.handleChangeDuration = this.handleChangeDuration.bind(this);

        this.handleProjectName = this.handleProjectName.bind(this);
        this.handleScopeofWork = this.handleScopeofWork.bind(this);
        this.handleNameofTeamLeader = this.handleNameofTeamLeader.bind(this);
        this.handleContactNoofTeamLeader = this.handleContactNoofTeamLeader.bind(this);



    }


    componentDidMount() {
        if (JSON.parse(localStorage.getItem('serviceTypeSet')) == null) {
            const { history } = this.props;
         
            history.push("/");
           
          }else{
            
                this.getSetPageData(JSON.parse(localStorage.getItem('serviceTypeSet')) , 4)
              

          }
        this.props.getData();
        this.getDataSitesByuser();
        this.getDataAllActiveUsers();
        this.getDataSiteAccessBaseData();
        var Firsttoday = new Date();

        // this.setState({
        //     dayOfWeek: Firsttoday.getDay().toString(),
        //     dayOfMonth: Firsttoday.getUTCDate().toString()
        // })

        //console.log(this.props.details);
    }
    getSetPageData =(serviceTypeIs,Pageid) =>  {
        //console.log("this.props");
        //console.log(serviceTypeIs.serviceType);
        if(serviceTypeIs.serviceType.filter(checkuser=>checkuser?.id==Pageid)[0] == null ){
          const { history } = this.props;
       
          history.push("/not-allowed");
        }
        
      };

    getDataSitesByuser = async () => {
        var res = await getUserSites();
        //console.log("getUserSites");
        //console.log(res.data);
        if (res.success) {
            this.setState({
                allSite_loading: false,
                allSite: res.data,
            });
        }
    };
    getDataSiteAccessBaseData = async () => {
        var res = await getSiteAccessBaseData();
        //console.log("getSiteAccessBaseData");
        //console.log(res.data);
        if (res.success) {
            this.setState({
                //allSite_loading: false,
                SiteAccessBaseDataVisitingPurposes: res.data.visitingPurposes,
                SiteAccessBaseDataAccessNeed: res.data.accessNeeds,
                selectWorkArea: res.data.workAreas,
                selectWorkType: res.data.workTypes,
                SiteAccessBaseDatarelatedTechnologys: res.data.relatedTechnologys
            });
        }
    };
    getDataAllActiveUsers = async () => {
        var res = await getAllActiveUsers();
        //console.log("getAllActiveUsers");
        //console.log(res.data);
        if (res.success) {
            this.setState({

                AllUsers: res.data,
            });
        }
    };
    getDataGroupBySite = async (data) => {
        var res = await getGroupBySite(data);
        console.log("getGroupBySite");
        console.log(res.data);
        if (res.success) {
            //console.log("getGroupBySitessssssssssssssss");

            this.setState({
                selectedGroupId:res.data.id,
                selectedGroupName:res.data.name
            })
        }

    };
    getDataHandlersBySite = async (data) => {
        var res = await getHandlersBySite(data);
        //console.log("getHandlersBySite");
        //console.log(res.data);
        if (res.success) {


            this.setState({
                AllHandlersBySite: res.data
            })
        }

    };

    onChangeSite = (value) => {
        //console.log("onChangeSite");
        //console.log(value);
        this.setState({
            selectedSiteId: value,
            sites: [{
                "id": value
            }],
            AllHandlersBySite: []
        })
        var form = new FormData();
        form.append("siteId", value);
        this.getDataGroupBySite(form);
        this.getDataHandlersBySite(form);
    };
    onChangeHandler = (value) => {
        //console.log("onChangeHandler");
        //console.log(value);
        this.setState({
            selectedHandler: value,
            handler: { "id": value }
            //  AllHandlersBySite: []
        })

    };
    onChangeScheduleType = (value) => {
        //console.log("onChangeScheduleType");
        //console.log(value);
        this.setState({
            scheduleType: { "id": value },
        })

    };
    onChangeOperationType = (value) => {
        //console.log("onChangeOperationType");
        //console.log(value);
        if (value == 1) {
            this.setState({
                projectName: "",
                scopeofWork: "",
                downTimeRequiredDate: "",
                nameofTeamLeader: "",
                contactNoofTeamLeader: '',
            })
        }
        this.setState({
            operationType: { "id": value },
            selectOpararionType: value
        })

    };
    OnChangeRecurrent = (value) => {

        if (value == true) {
            var Firsttoday = new Date();

            // this.setState({
            //     dayOfWeek: Firsttoday.getDay().toString(),
            //     dayOfMonth: Firsttoday.getUTCDate().toString()
            // })
            this.setState({
                recurrentStatus: value,
                scheduleEndOn: moment().format('YYYY-MM-DD'),
                scheduleStartOn: moment().format('YYYY-MM-DD'),
                scheduleType: { 'id': 2 },
                dayOfWeek: Firsttoday.getDay().toString(),
                dayOfMonth: Firsttoday.getUTCDate().toString()




            })
        } else {
            this.setState({
                recurrentStatus: value
            })
        }


        //console.log(`switch to ${value}`);

    };

    onChangeWorkType = (value) => {
        //console.log("onChangeWorkType");
        //console.log(value);
       
        this.setState({
            workType: { "id": value },
        })
    };
    onChangeWorkArea = (value) => {
        //console.log("onChangeWorkArea");
        //console.log(value);
        this.setState({
            workArea: { "id": value },
        })
       

    };
    onChangeVisitingPurpose = (value) => {
        //console.log("onChangeVisitingPurpose");
        //console.log(value);
        this.setState({
            visitingPurpose: { "id": value },
        })

    };
    onChangeAccessNeed = (value) => {
        //console.log("onChangeAccessNeed");
        //console.log(value);
        this.setState({
            accessNeed: { "id": value },
        })

    };
    onChangerelatedTechnology = (value) => {
        //console.log("onChangerelatedTechnology");
        //console.log(value);
        this.setState({
            relatedTechnology: { "id": value },
        })

    };

    onChangeScheduleStartOn = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);
        this.setState({
            scheduleStartOn: dateString,
        });
    };
    onChangeScheduleEndOn = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);
        this.setState({
            scheduleEndOn: dateString,
        });
    };
    onChangeDownTimeRequiredDate = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);
        if (dateString != "") {

            this.setState({
                downTimeRequiredDate: moment(dateString).format('YYYY-MM-DD'),
            });
        } else {

            this.setState({
                downTimeRequiredDate: null
            });
        }

    };

    onChangeAccessEndDate = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);
        if (dateString != "") {

            this.setState({
                accessEndDate: moment(dateString).format('YYYY-MM-DD'),
            });
        } else {

            this.setState({
                accessEndDate: null
            });
        }

    };

    onChangeAccessDate = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);
        if (dateString != "") {

            this.setState({
                accessDate: moment(dateString).format('YYYY-MM-DD'),
            });
        } else {

            this.setState({
                accessDate: null
            });
        }

    };
    onChangeAccessStartOn = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);

        this.setState({
            accessStartOn: dateString,
        });
    };
    onChangeDayOfWeek = (value) => {
        //console.log("onChangeDayOfWeek");
        //console.log(value);
        this.setState({
            dayOfWeek: value,
            //dayOfMonth:'',
        })

    };
    onChangeDayOfMonth = (value) => {
        //console.log("onChangeDayOfMonth1222");
        //console.log(value);
        this.setState({
            dayOfMonth: value,
            // dayOfWeek:'',
        })

    };

    onChangeUplod1 = (value) => {
        //console.log("onChangeUplod");
        //console.log(value);
        //    this.setState({
        //     uploadFiles:value.target.files[0]
        //    })

    };
    handleCancel = () => this.setState({ previewVisible: false });
    handlePreview = file => {
        //console.log(file)
        this.setState({
            previewImage: file.thumbUrl,
            previewVisible: true
        });
    };
    handleUpload = (FileList) => {
        //---------------^^^^^----------------
        // This Is Equivalent To Your "const Img = Event.target.files[0]"
        // Here, Antd Is Giving You An Array Of Files, Just Like Event.target.files
        // But The Structure Is A Bit Different That The Original File
        // The Original File Is Located At The `originFileObj` Key Of Each Of This Files
        // So `event.target.files[0]` Is Actually FileList[0].originFileObj
        //console.log('fileList', FileList.fileList);

        this.setState({
            uploadFiles: FileList.fileList,
            fileList: FileList.fileList
        })

        // You Store Them In State, So That You Can Make A Http Req With Them Later

    };
    onChangeUplod = (value) => {
        //console.log("onChangeUplod");
        //console.log(value);
        //    this.setState({
        //     uploadFiles:value.file
        //    })

    };
    selectedNewUserDel(value) {

        if (value !== -1) {
            valuesArrObjVisitor.splice(--value, 1);
            this.setState({ visitors: valuesArrObjVisitor });
        }

        //console.log(this.state.equipments)

    };
    selectedQuiDel(value) {

        if (value !== -1) {
            valuesArrObj.splice(--value, 1);
            this.setState({ equipments: valuesArrObj });
        }

        //console.log(this.state.equipments)

    };
    selectedInsDel(value) {

        if (value !== -1) {
            valuesArrObjInst.splice(--value, 1);
            this.setState({ instruments: valuesArrObjInst });
        }

        //console.log(this.state.instruments)

    };
    selectedInsVhe(value) {

        if (value !== -1) {
            valuesArrObjVhe.splice(--value, 1);
            this.setState({ accessVehicles: valuesArrObjVhe });
        }

        //console.log(this.state.accessVehicles)

    };
    onChangeTeamMember = (value) => {

        //const {data, color, name} = _data;
        var arrayNew = []
        //console.log("onChangeTeamMember");
        //console.log(value);
        //console.log(valuesArrObjVisitor.filter(checkuser => checkuser?.user?.id == value));
        if (valuesArrObjVisitor.filter(checkuser => checkuser?.user?.id == value)[0] == null) {
            this.state.AllUsers.filter(allusernew => allusernew.id == value).map((item, key) => (
                valuesArrObjVisitor.push(
                    { "name": item.name, "isActive": item.status, "mobileNo": item.mobileNumber, "nic": item.nic, "company": 'dd', "visitorType": 'eee', "user": { "id": item.id }, "active": true }
                )
            ))


            //console.log("sss")
            this.setState({
                visitors: valuesArrObjVisitor,
                newUserSelectTo: ""
            })
            //console.log(valuesArrObjVisitor)
        } else {
            alert("This user already in")
        }


    };
    formItemChange = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
        //console.log(obj)
    }

    handleChangeItmeName(e) {
        this.setState({ equ_itemName: e.target.value });
    }
    handleChangeSerialNum(e) {
        this.setState({ equ_sirialNum: e.target.value });
    }
    handleChangeActionType(e) {
        this.setState({ equ_actionType: e.target.value });
    }

    handleChangeDescription(e) {
        this.setState({ equ_description: e.target.value });
    }
    handleChangetUserName(e) {
        this.setState({ newUserName: e.target.value });
    }
    handleChangetUserContactNumber(e) {
        this.setState({ newUserConNum: e.target.value });
    }
    handleChangetUserCompany(e) {
        this.setState({ newUserCompany: e.target.value });
    }
    handleChangetUserNic(e) {
        this.setState({ newUsernic: e.target.value });
    }
    handleChangeQty(e) {
        this.setState({ equ_qty: e.target.value });
    }
    AllDataSubmit = async () => {
        if (this.validatorall.allValid()) {
            var dataSet;
            if (this.state.sites != "" && this.state.handler) {
                if (this.state.recurrentStatus == false) {
                    dataSet = {
                        "referenceNo": this.state.referenceNo,
                        "workDescription": this.state.workDescription,
                        "scheduleType": this.state.scheduleType,
                        "operationType": this.state.operationType,

                        "projectName": this.state.projectName,

                        "scopeOfWork": this.state.scopeofWork,

                        "downTimeRequiredDate": this.state.downTimeRequiredDate,

                        "nameOfTeamLeader": this.state.nameofTeamLeader,

                        "contactNoOfTeamLeader": this.state.contactNoofTeamLeader,


                        "visitingPurpose": this.state.visitingPurpose,
                        "accessNeed": this.state.accessNeed,
                        "workArea": this.state.workArea,
                        "remarks": this.state.workRemarks,
                        "workType": this.state.workType,
                        "relatedTechnology": this.state.relatedTechnology,
                        "sites": this.state.sites,
                        "handler": this.state.handler,
                        "scheduleStartOn": '',
                        "scheduleEndOn": '',
                        "dayOfWeek": '',
                        "dayOfMonth": '',
                        "accessDate": this.state.accessDate,
                        "accessEndDate": this.state.accessEndDate,
                        "accessStartOn": '0000-00-00T' + this.state.accessStartOn + ':22.000+05:30',
                        "workDuration": this.state.workDuration,
                        "visitors": this.state.visitors,
                        "equipments": this.state.equipments,
                        "instruments": this.state.instruments,
                        "accessVehicles": this.state.accessVehicles
                    }
                }
                if (this.state.recurrentStatus == true) {
                    if (this.state.scheduleType['id'] == 2) {
                        dataSet = {
                            "referenceNo": this.state.referenceNo,
                            "workDescription": this.state.workDescription,
                            "scheduleType": this.state.scheduleType,
                            "operationType": this.state.operationType,

                            "projectName": this.state.projectName,

                            "scopeOfWork": this.state.scopeofWork,

                            "downTimeRequiredDate": this.state.downTimeRequiredDate,

                            "nameOfTeamLeader": this.state.nameofTeamLeader,

                            "contactNoOfTeamLeader": this.state.contactNoofTeamLeader,

                            "visitingPurpose": this.state.visitingPurpose,
                            "workArea": this.state.workArea,
                            "workType": this.state.workType,
                            "remarks": this.state.workRemarks,

                            "accessNeed": this.state.accessNeed,
                            "relatedTechnology": this.state.relatedTechnology,
                            "sites": this.state.sites,
                            "handler": this.state.handler,
                            "scheduleStartOn": this.state.scheduleStartOn,
                            "scheduleEndOn": this.state.scheduleEndOn,
                            "dayOfWeek": this.state.dayOfWeek,
                            "dayOfMonth": '',
                            "accessDate": '',
                            "accessEndDate": '',

                            "accessStartOn": '0000-00-00T' + this.state.accessStartOn + ':22.000+05:30',
                            "workDuration": this.state.workDuration,
                            "visitors": this.state.visitors,
                            "equipments": this.state.equipments,
                            "instruments": this.state.instruments,
                            "accessVehicles": this.state.accessVehicles
                        }
                    }
                    if (this.state.scheduleType['id'] == 3) {
                        dataSet = {
                            "referenceNo": this.state.referenceNo,
                            "workDescription": this.state.workDescription,
                            "scheduleType": this.state.scheduleType,
                            "operationType": this.state.operationType,
                            "projectName": this.state.projectName,

                            "scopeOfWork": this.state.scopeofWork,

                            "downTimeRequiredDate": this.state.downTimeRequiredDate,

                            "nameOfTeamLeader": this.state.nameofTeamLeader,

                            "contactNoOfTeamLeader": this.state.contactNoofTeamLeader,
                            "visitingPurpose": this.state.visitingPurpose,
                            "workArea": this.state.workArea,
                            "remarks": this.state.workRemarks,

                            "workType": this.state.workType,
                            "accessNeed": this.state.accessNeed,
                            "relatedTechnology": this.state.relatedTechnology,
                            "sites": this.state.sites,
                            "handler": this.state.handler,
                            "scheduleStartOn": this.state.scheduleStartOn,
                            "scheduleEndOn": this.state.scheduleEndOn,
                            "dayOfWeek": '',
                            "dayOfMonth": this.state.dayOfMonth,
                            "accessDate": '',
                            "accessEndDate": '',

                            "accessStartOn": '0000-00-00T' + this.state.accessStartOn + ':22.000+05:30',
                            "workDuration": this.state.workDuration,
                            "visitors": this.state.visitors,
                            "equipments": this.state.equipments,
                            "instruments": this.state.instruments,
                            "accessVehicles": this.state.accessVehicles
                        }
                    }


                }

                this.setState({
                    alldataset: dataSet,
                    submit_loading: true
                })

                // //console.log("alllll datttttaaa");
                //console.log(dataSet);
                var form = new FormData();
                form.append("accessPermitionData", JSON.stringify(dataSet));
                this.state.uploadFiles?.map((item, index) => {
                    form.append('files', item.originFileObj);

                })



                var res = await siteAccessCreate(form);
                //console.log("siteAccessCreate");

                if (res.success) {
                    //console.log("awaaa")
                    const { history } = this.props;
                    history.push("/site-access/list");
                }

            } else {

                alert('please complete required fields')
            }
            //console.log(dataSet);
        } else {
            //console.log("dkfdkfd");
            this.validatorall.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }
    handleSubmitNewUser(e) {
        if (this.validatorNewuser.allValid()) {

            valuesArrObjVisitor.push(
                { "name": this.state.newUserName, "mobileNo": this.state.newUserConNum, "nic": this.state.newUsernic, "user": null, "company": this.state.newUserCompany, "active": true }
            )
            document.getElementById("equipment_close").click()
            //console.log(valuesArrObjVisitor)
            this.setState({
                visitors: valuesArrObjVisitor,
                newUserName: "",
                newUserConNum: "",
                newUsernic: "",
                newUserCompany: "",
                // equ_description: "",
                // equ_qty: "",
            })

        } else {
            //console.log("dkfdkfd");
            this.validatorNewuser.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }


    }
    handleSubmit(e) {
        //console.log("asdadasa")

        if (this.validatorequ.allValid()) {


            valuesArrObj.push(
                {
                    "name": this.state.equ_itemName,
                    "serialNo": this.state.equ_sirialNum,
                    "discription": this.state.equ_description,
                    "ActionType": this.state.equ_actionType,
                    "qnty": this.state.equ_qty,
                    "active": 1
                }
            )
            document.getElementById("equipment_close").click()
            //console.log(valuesArrObj)
            this.setState({
                equipments: valuesArrObj,
                equ_itemName: "",
                equ_sirialNum: "",
                equ_actionType: "",
                equ_description: "",
                equ_qty: "",
            })
        }
        else {
            this.validatorequ.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }

        //  e.preventDefault();
    }
    handleChangeInsItmeName(e) {
        this.setState({ ins_itemName: e.target.value });
    }
    handleChangeInsSerialNum(e) {
        this.setState({ ins_sirialNum: e.target.value });
    }
    handleChangeInsActionType(e) {
        this.setState({ ins_actionType: e.target.value });
    }

    handleChangeInsDescription(e) {
        this.setState({ ins_description: e.target.value });
    }
    handleChangeInsQty(e) {
        this.setState({ ins_qty: e.target.value });
    }
    handleInsSubmit(e) {
        if (this.validatorins.allValid()) {
            valuesArrObjInst.push(
                {
                    "name": this.state.ins_itemName,
                    "serialNo": this.state.ins_sirialNum,
                    "discription": this.state.ins_description,
                    "ActionType": this.state.ins_actionType,
                    "qnty": this.state.ins_qty,
                    "active": 1
                }
            )
            document.getElementById("instrument_close").click()

            //console.log(valuesArrObjInst)
            this.setState({
                instruments: valuesArrObjInst,
                ins_itemName: "",
                ins_sirialNum: "",
                ins_actionType: "",
                ins_description: "",
                ins_qty: "",
            })

        } else {
            this.validatorins.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }

        //  e.preventDefault();
    }
    handleChangeVheNo(e) {
        this.setState({ vhe_no: e.target.value });
    }
    handleChangeVheType(e) {
        this.setState({ vhe_type: e.target.value });
    }

    handleVheSubmit(e) {
        if (this.validator.allValid()) {
            valuesArrObjVhe.push({
                "vehicleNo": this.state.vhe_no,
                "vehicleType": this.state.vhe_type,
                "active": 1
            })
            document.getElementById("vehicle_close").click()

            //console.log(valuesArrObjVhe)

            this.setState({
                accessVehicles: valuesArrObjVhe,
                vhe_no: "",
                vhe_type: "",
            })
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }

        // e.preventDefault();
    }
    handleChangeRefNo(e) {
        ////console.log(e.target.value)
        this.setState({ referenceNo: e.target.value });
    }
    handleRemarks(e) {
        //console.log(e.target.value)
        this.setState({ workRemarks: e.target.value });
    }
    handleChangeWorkDes(e) {
        //console.log(e.target.value)
        this.setState({ workDescription: e.target.value });
    }
    handleContactNoofTeamLeader(e) {
        //console.log(e.target.value)
        this.setState({ contactNoofTeamLeader: e.target.value });
    }
    handleNameofTeamLeader(e) {
        //console.log(e.target.value)
        this.setState({ nameofTeamLeader: e.target.value });
    }
    handleScopeofWork(e) {
        //console.log(e.target.value)
        this.setState({ scopeofWork: e.target.value });
    }
    handleProjectName(e) {
        //console.log(e.target.value)
        this.setState({ projectName: e.target.value });
    }
    handleChangeDuration(e) {
        //console.log(e.target.value)
        this.setState({ workDuration: (e.target.value) * 60 });
    }
    onImportExcel = event => {
        const { files } = event.target;
        if (files.length === 1) {
            //console.log(files);
            // Process a file if we have exactly one
            importExcel(
                files[0],
                // Not sure what you want to do with the data, so let's just log it
                (Name,ContactNumber,NIC, Company) => {

                    if (Name) {
                        valuesArrObjVisitor.push(
                            { "name": Name, "mobileNo": ContactNumber, "nic": NIC, "user": null, "company": Company, "active": true }
                        )
                        // document.getElementById("equipment_close").click()
                        //console.log(valuesArrObjVisitor)
                        this.setState({
                            visitors: valuesArrObjVisitor,

                        })
                    }

                },
            );
        }
    };

    render() {
        const props2 = {

            previewFile(file) {
                //console.log("s.");

            },
        };

        const { Option } = Select;
        var today = new Date();
        //console.log("slpololll");
        //console.log(today.getUTCDate());
        const dataSource = this.state.equipments;

        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                //  key: 'name',
            },
            {
                title: 'Serial Number',
                dataIndex: 'serialNo',
                // key: 'serialNo',
            },
            {
                title: 'Action Type',
                dataIndex: 'ActionType',
                // key: 'ActionType',
            },
            {
                title: 'Description',
                dataIndex: 'discription',
                // key: 'discription',
            },
            {
                title: 'Qty',
                dataIndex: 'qnty',
                // key: 'qnty',
            }
        ];


        // function onSearch(val) {
        //     //console.log('search:', val);
        //   }

        function MyFormComponent() {
            const [form] = Form.useForm();

            const submitForm = ({ name, favoriteColor }) => {
                //console.log(name, favoriteColor);
                form.resetFields();
            };

            return (
                <Form
                    form={form}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 12 }}
                    onFinish={submitForm}
                >
                    <Form.Item name="name" label="What is your name?">
                        <Input />
                    </Form.Item>
                    <Form.Item name="favoriteColor" label="What is your favorite color?">
                        <Input />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            );
        }
        return (
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="page-title-box">
                            <div class="row">
                                <div class="col">
                                    <h4 class="page-title">New  Site Access</h4>
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Site Access</a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Create</a></li>
                                        

                                    </ol>
                                </div>
                                <div class="col-auto align-self-center">
                                    <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Create Site Access Request</h4>
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div className="col-md-6">
                                        <label className="form-label" for="users">
                                            Add Site <x style={{ color: "red" }}>*</x>
                                        </label>
                                        <Select
                                            showSearch
                                            onChange={this.onChangeSite}
                                            loading={this.state.allSite_loading}
                                            style={{ width: "100%" }}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"

                                        >
                                            {this.state.allSite.map((item, index) => {
                                                return <Option value={item.id}>{item.siteName}  - ({item.siteId}) </Option>;
                                            })}
                                        </Select>
                                        <p style={{ color: "#f5325c" }}>{this.validatorall.message('Site', this.state.selectedSiteId, 'required')}</p>


                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label" for="depot">
                                            Group <x style={{ color: "red" }}>*</x>
                                        </label>
                                        <Form.Item required tooltip="This is a required field">
                                            <Input disabled placeholder="Group" value={this.state.selectedGroupName} />
                                        </Form.Item>

                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label" for="users">
                                            Handler <x style={{ color: "red" }}>*</x>
                                        </label>
                                        <Select
                                            showSearch
                                            onChange={this.onChangeHandler}
                                            defaultValue={this.state.selectedHandler}
                                            style={{ width: "100%" }}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"

                                        >
                                            {this.state.AllHandlersBySite.map((item, index) => {
                                                return <Option value={item.id}>{item.name} ({item.mobileNumber}) </Option>;
                                            })}
                                        </Select>
                                        <p style={{ color: "#f5325c" }}>{this.validatorall.message('Handler', this.state.handler, 'required')}</p>

                                    </div>
                                </div>
                                <div className='row'>

                                    <div className="col-md-12">
                                        <br></br>

                                        <div className="card">
                                            <div className="card-body">
                                                <div className='row'>
                                                    <div className="col-md-6">
                                                        <div className='row'>

                                                            <div className='col-md-12'>
                                                                <label className="form-label" for="depot">
                                                                    Operation Type
                                                                </label>
                                                                <Select
                                                                    style={{ width: "100%" }}
                                                                    showSearch
                                                                    defaultValue="1"
                                                                    placeholder="Select a person"
                                                                    optionFilterProp="children"
                                                                    onChange={this.onChangeOperationType}
                                                                    //onSearch={onSearch}
                                                                    filterOption={(input, option) =>
                                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                >
                                                                    <Option value="1">Operation</Option>
                                                                    <Option value="2">Project</Option>

                                                                </Select>
                                                            </div>


                                                            {/* <div className='col-md-2'>
                                                                <label className="form-label" for="depot">
                                                                    Recurrent
                                                                </label>
                                                                <br></br>
                                                                <Switch onChange={this.OnChangeRecurrent} />
                                                            </div> */}
                                                        </div>

                                                        <br></br>
                                                    </div>

                                                    {this.state.selectOpararionType == 2 ? (
                                                        <div className='row'>

                                                            <div className='col-md-6'>
                                                                <label className="form-label" for="depot">
                                                                    Project Name
                                                                </label>
                                                                <Form.Item required tooltip="This is a required field">
                                                                    <Input placeholder="Project Name " onChange={this.handleProjectName} />
                                                                </Form.Item>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label className="form-label" for="depot">
                                                                    Scope of Work
                                                                </label>
                                                                <Form.Item required tooltip="This is a required field">
                                                                    <Input placeholder="Scope of Work" onChange={this.handleScopeofWork} />
                                                                </Form.Item>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label className="form-label" for="downTimeRequiredDate">
                                                                    Down Time Required Date
                                                                </label>
                                                                <DatePicker
                                                                    //defaultValue={moment()}
                                                                    style={{ width: "100%" }}
                                                                    onChange={this.onChangeDownTimeRequiredDate}
                                                                //onChange={this.onChangeScheduleStartOn}
                                                                />
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label className="form-label" for="depot">
                                                                    Name of Team Leader
                                                                </label>
                                                                <Form.Item required tooltip="This is a required field">
                                                                    <Input placeholder="Name of Team Leader" onChange={this.handleNameofTeamLeader} />
                                                                </Form.Item>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label className="form-label" for="depot">
                                                                    Contact No of Team Leader
                                                                </label>
                                                                <Form.Item required tooltip="This is a required field">
                                                                    <Input placeholder="Contact No of Team Leader" onChange={this.handleContactNoofTeamLeader} />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    ) : ('')}

                                                    {this.state.recurrentStatus ? (<div className="col-md-6">


                                                        <label className="form-label" for="depot">
                                                            Schedule Type <x style={{ color: "red" }}>*</x>
                                                        </label>
                                                        <Select
                                                            style={{ width: "100%" }}
                                                            showSearch
                                                            defaultValue='2'
                                                            placeholder="Select a person"
                                                            optionFilterProp="children"
                                                            onChange={this.onChangeScheduleType}
                                                            //onSearch={onSearch}
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >

                                                            <Option value="2">Weekly</Option>
                                                            <Option value="3">Monthly</Option>

                                                        </Select>



                                                    </div>) : ("")}

                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div className='row'>
                                    {this.state.recurrentStatus ? ('') : (<div className="col-md-6" style={{ marginBottom: 22 }}>
                                        <label className="form-label" for="depot">
                                            Permission Start Date <x style={{ color: "red" }}>*</x>
                                        </label>
                                        <DatePicker
                                            defaultValue={moment()}
                                            style={{ width: "100%" }}
                                            onChange={this.onChangeAccessDate}
                                        />
                                        <p style={{ color: "#f5325c" }}>{this.validatorall.message('Permission Start Date', this.state.accessDate, 'required')}</p>

                                    </div>)}


                                    {this.state.recurrentStatus ? (<div>
                                        {this.state.scheduleType['id'] == 2 || this.state.scheduleType['id'] == 1 ? (<div className="col-md-6" style={{ marginBottom: 22 }}>
                                            <label className="form-label" for="depot">
                                                Day of Week
                                            </label>
                                            <Select
                                                style={{ width: "100%" }}
                                                showSearch
                                                defaultValue={today.getDay().toString()}
                                                placeholder="Select a person"
                                                optionFilterProp="children"
                                                onChange={this.onChangeDayOfWeek}
                                                //onSearch={onSearch}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option value="0">Sunday</Option>
                                                <Option value="1">Monday</Option>
                                                <Option value="2">Tuesday</Option>
                                                <Option value="3">Wednesday</Option>
                                                <Option value="4">Thursday</Option>
                                                <Option value="5">Friday</Option>
                                                <Option value="6">Saturday</Option>

                                            </Select>
                                        </div>) : ('')}
                                    </div>) : ('')}

                                    {this.state.recurrentStatus ? (<div>
                                        {this.state.scheduleType['id'] == 3 ? (<div className="col-md-6" style={{ marginBottom: 22 }}>
                                            <label className="form-label" for="depot">
                                                Day of Month
                                            </label>
                                            <Select
                                                style={{ width: "100%" }}
                                                showSearch
                                                defaultValue={today.getUTCDate().toString()}
                                                placeholder="Select a person"
                                                optionFilterProp="children"
                                                onChange={this.onChangeDayOfMonth}
                                                //onSearch={onSearch}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option value="1">1</Option>
                                                <Option value="2">2</Option>
                                                <Option value="3">3</Option>
                                                <Option value="4">4</Option>
                                                <Option value="5">5</Option>
                                                <Option value="6">6</Option>
                                                <Option value="7">7</Option>
                                                <Option value="8">8</Option>
                                                <Option value="9">9</Option>
                                                <Option value="10">10</Option>
                                                <Option value="11">11</Option>
                                                <Option value="12">12</Option>
                                                <Option value="13">13</Option>
                                                <Option value="14">14</Option>
                                                <Option value="15">15</Option>
                                                <Option value="16">16</Option>
                                                <Option value="17">17</Option>
                                                <Option value="18">18</Option>
                                                <Option value="19">19</Option>
                                                <Option value="21">21</Option>
                                                <Option value="22">22</Option>
                                                <Option value="23">23</Option>
                                                <Option value="24">24</Option>
                                                <Option value="25">25</Option>
                                                <Option value="26">26</Option>
                                                <Option value="28">28</Option>
                                                <Option value="29">29</Option>
                                                <Option value="30">30</Option>
                                                <Option value="31">31</Option>

                                            </Select>
                                        </div>) : ('')}
                                    </div>) : ('')}


                                    {this.state.recurrentStatus ? (<div className="col-md-6" style={{ marginBottom: 22 }}>
                                        <label className="form-label" for="depot">
                                            Schedule Start Date
                                        </label>
                                        <DatePicker
                                            defaultValue={moment()}
                                            style={{ width: "100%" }}
                                            onChange={this.onChangeScheduleStartOn}
                                        />
                                    </div>) : ("")}
                                    {this.state.recurrentStatus ? (<div className="col-md-6" style={{ marginBottom: 22 }}>
                                        <label className="form-label" for="depot">
                                            Schedule End date
                                        </label>
                                        <DatePicker
                                            defaultValue={moment()}
                                            style={{ width: "100%" }}
                                            onChange={this.onChangeScheduleEndOn}
                                        />
                                    </div>) : ("")}
                                    <div className="col-md-6" style={{ marginBottom: 22 }}>
                                        <label className="form-label" for="depot">
                                            Start Time <x style={{ color: "red" }}>*</x>
                                        </label>
                                        <TimePicker style={{ width: "100%" }} defaultValue={moment()} onChange={this.onChangeAccessStartOn} format={format} />
                                        <p style={{ color: "#f5325c" }}>{this.validatorall.message('Start Time', this.state.accessStartOn, 'required')}</p>

                                    </div>

                                    {this.state.recurrentStatus ? ('') : (<div className="col-md-6" style={{ marginBottom: 22 }}>
                                        <label className="form-label" for="depot">
                                            Permission End Date <x style={{ color: "red" }}>*</x>
                                        </label>
                                        <DatePicker
                                            // defaultValue={moment()}
                                            style={{ width: "100%" }}
                                            onChange={this.onChangeAccessEndDate}
                                        />
                                        <p style={{ color: "#f5325c" }}>{this.validatorall.message('EndDate', this.state.accessEndDate, 'required')}</p>


                                    </div>)}


                                    {/* <div className="col-md-6" style={{ marginBottom: 0 }}>
                                        <label className="form-label" for="depot">
                                            Duration (Hours)
                                        </label>
                                        <Form.Item required name="duration" tooltip="This is a required field">
                                            <Input placeholder="Duration" id="duration" onChange={this.handleChangeDuration} />
                                        </Form.Item>

                                    </div> */}




                                    <div className="col-md-9">


                                        <label className="form-label" for="depot">
                                            Add Team Members <x style={{ color: "red" }}>*</x>
                                        </label>
                                        <Popup

                                            trigger={
                                                <Button block style={{ textAlign: "end" }} >
                                                    <PlusCircleOutlined /> <b style={{ color: "#40a9ff" }}>Add Users</b>
                                                </Button>
                                            }
                                            open={this.state.isOpen}
                                            modal>
                                            {close => (<div className="row" >
                                                <div className="col-lg-12">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="card-title">Add Users</h4>
                                                        </div>
                                                        <div className="card-body">
                                                            <form>
                                                                <div className="mb-3">

                                                                    <input autoComplete="off" type="text" className="form-control" name="user_Name" value={this.state.newUserName} aria-describedby="emailHelp" placeholder="Name with Initials" onChange={this.handleChangetUserName} />
                                                                    <p style={{ color: "#f5325c" }}>{this.validatorNewuser.message('User Name', this.state.newUserName, 'required')}</p>
                                                                </div>


                                                                <div className="mb-3">

                                                                    <input autoComplete="off" type="number" value={this.state.newUserConNum} className="form-control" name="user_contact_number" placeholder="Contact Number" onChange={this.handleChangetUserContactNumber} />
                                                                    <p style={{ color: "#f5325c" }}>{this.validatorNewuser.message('Contact Number', this.state.newUserConNum, 'required')}</p>

                                                                </div>
                                                                <div className="mb-3">

                                                                    <input autoComplete="off" type="text" value={this.state.newUsernic} className="form-control" name="user_nic" placeholder="NIC" onChange={this.handleChangetUserNic} />
                                                                    <p style={{ color: "#f5325c" }}>{this.validatorNewuser.message('NIC', this.state.newUsernic, 'required')}</p>
                                                                </div>
                                                                <div className="mb-3">

                                                                    <input autoComplete="off" type="text" value={this.state.newUserCompany} className="form-control" name="company" placeholder="Company" onChange={this.handleChangetUserCompany} />
                                                                    <p style={{ color: "#f5325c" }}>{this.validatorNewuser.message('NIC', this.state.newUserCompany, 'required')}</p>
                                                                </div>




                                                            </form>




                                                            <div className='row' style={{ justifyContent: "end" }}>
                                                                <div class='col-md-2' style={{ width: '10.66667%' }}>   <button className="btn btn-primary" onClick={() => this.handleSubmitNewUser()}>Submit</button></div>
                                                                <div class='col-md-2' style={{ width: '10.66667%' }}>
                                                                    <button className="btn btn-primary" id="equipment_close" onClick={close}>Close</button>

                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            )}
                                        </Popup>

                                        {/* <Select
                                            showSearch
                                            style={{ width: "100%" }}
                                            defaultValue={this.state.newUserSelectTo}
                                            allowClear
                                            placeholder="Select a person"
                                            optionFilterProp="children"
                                            onChange={this.onChangeTeamMember}
                                        //onSearch={onSearch}

                                        >
                                            {this.state.AllUsers.map((item, index) => {
                                                return <Option value={item.id}>{item.name}-{item.mobileNumber}</Option>;
                                            })}

                                        </Select> */}



                                    </div>
                                    <div className="col-md-3" >
                                        <div className='row'>
                                            <label className="form-label" for="depot">
                                                Upload Team Members List(.xlsx, .xls)
                                            </label>

                                        </div>
                                        <div className='row'>
                                            <Button className="upload-wrap" style={{ textAlign: "left" }}>
                                                {/* <UploadOutlined/> */}
                                                <input style={{ margin: -9 }} className="file-uploader" type="file" accept=".xlsx, .xls" onChange={this.onImportExcel} />

                                            </Button>
                                            {/* <p className="upload-tip">Supports files in .xlsx, .xls format</p> */}

                                        </div>


                                    </div>

                                    {/* <div className="col-md-2">
                                        <label className="form-label" for="depot">
                                            &nbsp;
                                        </label>
                                        
                                    </div> */}
                                    <p style={{ color: "#f5325c" }}>{this.validatorall.message('Team Members', this.state.visitors, 'required')}</p>

                                    <div className='col-md-12'>
                                        {this.state.visitors[0] == null ? (<div></div>) : (<div className='row' style={{ display: "contents" }}>
                                            <br></br>
                                            <table style={{ border: "1px solid black", width: "100%" }}>
                                                <thead>
                                                    <tr style={{ border: "1px solid black" }}>
                                                        <th style={{ border: "1px solid black" }}>#</th>
                                                        <th style={{ border: "1px solid black" }}>Name</th>
                                                        <th style={{ border: "1px solid black" }}>Contact Number</th>
                                                        <th style={{ border: "1px solid black" }}>NIC</th>
                                                        <th style={{ border: "1px solid black" }}>Company </th>

                                                        <th style={{ border: "1px solid black", width: "50px" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.visitors.map((item, key) => (
                                                        <tr style={{ border: "1px solid black" }}>
                                                            <td style={{ border: "1px solid black" }}>{++key}</td>
                                                            <td style={{ border: "1px solid black" }}>{item.name}</td>
                                                            <td style={{ border: "1px solid black" }}>{item.mobileNo}</td>
                                                            <td style={{ border: "1px solid black" }}>{item.nic}</td>
                                                            <td style={{ border: "1px solid black" }}>{item.company}</td>

                                                            <td style={{ border: "1px solid black" }} > <Button block style={{ textAlign: "end" }} onClick={() => this.selectedNewUserDel(key)}>
                                                                <DeleteOutlined />
                                                            </Button></td>
                                                        </tr>
                                                    ))}



                                                </tbody>
                                            </table>

                                        </div>)}
                                    </div>
                                    <div className="col-md-12">
                                        <br></br>

                                        <label className="form-label" for="depot">
                                            Equipments
                                        </label>



                                        <Popup

                                            trigger={
                                                <Button block style={{ textAlign: "end" }} >
                                                    <PlusCircleOutlined /> <b style={{ color: "#40a9ff" }}>Add Equipments</b>
                                                </Button>
                                            }
                                            open={this.state.isOpen}
                                            modal>
                                            {close => (<div className="row" >
                                                <div className="col-lg-12">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="card-title">Add Equipment</h4>
                                                        </div>
                                                        <div className="card-body">
                                                            <form>
                                                                <div className="mb-3">

                                                                    <input autoComplete="off" type="text" className="form-control" name="Item_Name" value={this.state.equ_itemName} aria-describedby="emailHelp" placeholder="Item Name" onChange={this.handleChangeItmeName} />
                                                                    <p style={{ color: "#f5325c" }}>{this.validatorequ.message('name', this.state.equ_itemName, 'required')}</p>
                                                                </div>
                                                                <div className="mb-3">

                                                                    <input autoComplete="off" type="text" value={this.state.equ_sirialNum} className="form-control" name="Serial_Number" placeholder="Enter Serial Number" onChange={this.handleChangeSerialNum} />

                                                                </div>

                                                                <div className="mb-3">

                                                                    <input autoComplete="off" type="text" value={this.state.equ_actionType} className="form-control" name="Action_Type" placeholder="Enter Action Type" onChange={this.handleChangeActionType} />

                                                                </div>
                                                                <div className="mb-3">

                                                                    <input autoComplete="off" type="text" value={this.state.equ_description} className="form-control" name="Description" placeholder="Enter Description" onChange={this.handleChangeDescription} />

                                                                </div>
                                                                <div className="mb-3">

                                                                    <input autoComplete="off" type="number" value={this.state.equ_qty} className="form-control" name="Quantity" placeholder="Enter Quantity" onChange={this.handleChangeQty} />
                                                                    <p style={{ color: "#f5325c" }}>{this.validatorequ.message('Quantity', this.state.equ_qty, 'required')}</p>
                                                                </div>




                                                            </form>




                                                            <div className='row' style={{ justifyContent: "end" }}>
                                                                <div class='col-md-2' style={{ width: '10.66667%' }}>   <button className="btn btn-primary" onClick={() => this.handleSubmit()}>Submit</button></div>
                                                                <div class='col-md-2' style={{ width: '10.66667%' }}>
                                                                    <button className="btn btn-primary" id="equipment_close" onClick={close}>Close</button>

                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            )}
                                        </Popup>
                                        <div className='row'>

                                        </div>
                                        {this.state.equipments == "" ? (<div></div>) : (<div className='row' style={{ display: "contents" }}>
                                            <br></br>
                                            <table style={{ border: "1px solid black", width: "100%" }}>
                                                <thead>
                                                    <tr style={{ border: "1px solid black" }}>
                                                        <th style={{ border: "1px solid black" }}>#</th>
                                                        <th style={{ border: "1px solid black" }}>Name</th>
                                                        <th style={{ border: "1px solid black" }}>Serial Number</th>
                                                        <th style={{ border: "1px solid black" }}>Action Type</th>
                                                        <th style={{ border: "1px solid black" }}>Description</th>
                                                        <th style={{ border: "1px solid black" }}>Quantity</th>
                                                        <th style={{ border: "1px solid black", width: "50px" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.equipments.map((item, key) => (
                                                        <tr style={{ border: "1px solid black" }}>
                                                            <td style={{ border: "1px solid black" }}>{++key}</td>
                                                            <td style={{ border: "1px solid black" }}>{item.name}</td>
                                                            <td style={{ border: "1px solid black" }}>{item.serialNo}</td>
                                                            <td style={{ border: "1px solid black" }}>{item.ActionType}</td>
                                                            <td style={{ border: "1px solid black" }}>{item.discription}</td>
                                                            <td style={{ border: "1px solid black" }}>{item.qnty}</td>
                                                            <td style={{ border: "1px solid black" }} > <Button block style={{ textAlign: "end" }} onClick={() => this.selectedQuiDel(key)}>
                                                                <DeleteOutlined />
                                                            </Button></td>
                                                        </tr>
                                                    ))}



                                                </tbody>
                                            </table>

                                        </div>)}


                                    </div>
                                    <div className="col-md-12">
                                        <br></br>
                                        <label className="form-label" for="depot">
                                            Instrument
                                        </label>



                                        <Popup trigger={
                                            <Button block style={{ textAlign: "end" }} >
                                                <PlusCircleOutlined /> <b style={{ color: "#40a9ff" }}>Add Instrument</b>
                                            </Button>
                                        } modal>
                                            {close => (<div className="row">
                                                <div className="col-lg-12">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="card-title">Add Instrument</h4>
                                                        </div>
                                                        <div className="card-body">
                                                            <form>
                                                                <div className="mb-3">

                                                                    <input autoComplete="off" type="text" className="form-control" name="ins_Item_Name" value={this.state.ins_itemName} aria-describedby="emailHelp" placeholder="Item Name" onChange={this.handleChangeInsItmeName} />
                                                                    <p style={{ color: "#f5325c" }}>{this.validatorins.message('Item name', this.state.ins_itemName, 'required')}</p>
                                                                </div>
                                                                <div className="mb-3">

                                                                    <input autoComplete="off" type="text" value={this.state.ins_sirialNum} className="form-control" name="ins_Serial_Number" placeholder="Enter Serial Number" onChange={this.handleChangeInsSerialNum} />
                                                                    {/* <p style={{ color: "#f5325c" }}>{this.validatorins.message('Serial Number', this.state.ins_sirialNum, 'required')}</p> */}
                                                                </div>

                                                                <div className="mb-3">

                                                                    <input autoComplete="off" type="text" value={this.state.ins_actionType} className="form-control" name="ins_Action_Type" placeholder="Enter Action Type" onChange={this.handleChangeInsActionType} />
                                                                    {/* <p style={{ color: "#f5325c" }}>{this.validatorins.message('Action Type', this.state.ins_actionType, 'required')}</p> */}
                                                                </div>
                                                                <div className="mb-3">

                                                                    <input autoComplete="off" type="text" value={this.state.ins_description} className="form-control" name="ins_Description" placeholder="Enter Description" onChange={this.handleChangeInsDescription} />
                                                                    {/* <p style={{ color: "#f5325c" }}>{this.validatorins.message('description', this.state.ins_description, 'required')}</p> */}
                                                                </div>
                                                                <div className="mb-3">

                                                                    <input autoComplete="off" type="number" value={this.state.ins_qty} className="form-control" name="ins_Quantity" placeholder="Enter Quantity" onChange={this.handleChangeInsQty} />
                                                                    <p style={{ color: "#f5325c" }}>{this.validatorins.message('Quantity', this.state.ins_qty, 'required')}</p>
                                                                </div>





                                                            </form>


                                                            <div className='row' style={{ justifyContent: "end" }} >
                                                                <div class='col-md-2' style={{ width: '10.66667%' }}>
                                                                    <button className="btn btn-primary" onClick={() => this.handleInsSubmit()}>Submit</button>
                                                                </div>
                                                                <div class='col-md-2' style={{ width: '10.66667%' }}>
                                                                    <button className="btn btn-primary" id="instrument_close" onClick={close}>Close</button>

                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            )}
                                        </Popup>
                                        <div className='row'>

                                        </div>
                                        {this.state.instruments == "" ? (<div></div>) : (<div className='row' style={{ display: "contents" }}>
                                            <br></br>
                                            <table style={{ border: "1px solid black", width: "100%" }}>
                                                <thead>
                                                    <tr style={{ border: "1px solid black" }}>
                                                        <th style={{ border: "1px solid black" }}>#</th>
                                                        <th style={{ border: "1px solid black" }}>Name</th>
                                                        <th style={{ border: "1px solid black" }}>Serial Number</th>
                                                        <th style={{ border: "1px solid black" }}>Action Type</th>
                                                        <th style={{ border: "1px solid black" }}>Description</th>
                                                        <th style={{ border: "1px solid black" }}>Quantity</th>
                                                        <th style={{ border: "1px solid black", width: "50px" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.instruments.map((item, key) => (
                                                        <tr style={{ border: "1px solid black" }}>
                                                            <td style={{ border: "1px solid black" }}>{++key}</td>
                                                            <td style={{ border: "1px solid black" }}>{item.name}</td>
                                                            <td style={{ border: "1px solid black" }}>{item.serialNo}</td>
                                                            <td style={{ border: "1px solid black" }}>{item.ActionType}</td>
                                                            <td style={{ border: "1px solid black" }}>{item.discription}</td>
                                                            <td style={{ border: "1px solid black" }}>{item.qnty}</td>
                                                            <td style={{ border: "1px solid black" }} > <Button block style={{ textAlign: "end" }} onClick={() => this.selectedInsDel(key)}>
                                                                <DeleteOutlined />
                                                            </Button></td>
                                                        </tr>
                                                    ))}



                                                </tbody>
                                            </table>

                                        </div>)}
                                    </div>




                                    <div className="col-md-12">
                                        <br></br>
                                        <label className="form-label" for="depot">
                                            Vehicles
                                        </label>



                                        <Popup trigger={
                                            <Button block style={{ textAlign: "end" }} >
                                                <PlusCircleOutlined /> <b style={{ color: "#40a9ff" }}>Add Vehicle</b>
                                            </Button>
                                        } modal>
                                            {close => (<div className="row">
                                                <div className="col-lg-12">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="card-title">Add Vehicle</h4>
                                                        </div>
                                                        <div className="card-body">
                                                            <form>
                                                                <div className="mb-3">

                                                                    <input autoComplete="off" type="text" className="form-control" name="Vehicle_No" value={this.state.vhe_no} aria-describedby="emailHelp" placeholder="Vehicle No" onChange={this.handleChangeVheNo} />
                                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Vehicle No', this.state.vhe_no, 'required')}</p>
                                                                </div>
                                                                <div className="mb-3">

                                                                    <input autoComplete="off" type="text" value={this.state.vhe_type} className="form-control" name="Vehicle type" placeholder="Vehicle type" onChange={this.handleChangeVheType} />
                                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Vehicle type', this.state.vhe_type, 'required')}</p>
                                                                </div>






                                                            </form>

                                                            <div className='row' style={{ justifyContent: "end" }}>
                                                                <div class='col-md-2' style={{ width: '10.66667%' }}>
                                                                    <button className="btn btn-primary" onClick={() => this.handleVheSubmit()}>Submit</button>
                                                                </div>
                                                                <div class='col-md-2' style={{ width: '10.66667%' }}>
                                                                    <button className="btn btn-primary" id="vehicle_close" onClick={close}>Close</button>

                                                                </div>

                                                            </div>


                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            )}
                                        </Popup>
                                        <div className='row'>

                                        </div>
                                        {this.state.accessVehicles == "" ? (<div></div>) : (<div className='row' style={{ display: "contents" }}>
                                            <br></br>
                                            <table style={{ border: "1px solid black", width: "100%" }}>
                                                <thead>
                                                    <tr style={{ border: "1px solid black" }}>
                                                        <th style={{ border: "1px solid black" }}>#</th>
                                                        <th style={{ border: "1px solid black" }}>Vehicle No</th>
                                                        <th style={{ border: "1px solid black" }}>Vehicle Type</th>

                                                        <th style={{ border: "1px solid black", width: "50px" }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.accessVehicles.map((item, key) => (
                                                        <tr style={{ border: "1px solid black" }}>
                                                            <td style={{ border: "1px solid black" }}>{++key}</td>
                                                            <td style={{ border: "1px solid black" }}>{item.vehicleNo}</td>
                                                            <td style={{ border: "1px solid black" }}>{item.vehicleType}</td>

                                                            <td style={{ border: "1px solid black" }} > <Button block style={{ textAlign: "end" }} onClick={() => this.selectedInsVhe(key)}>
                                                                <DeleteOutlined />
                                                            </Button></td>
                                                        </tr>
                                                    ))}



                                                </tbody>
                                            </table>

                                        </div>)}
                                    </div>

                                    <div className='row'>

                                        <div className="col-md-6" style={{ marginBottom: 0 }}>
                                            <br></br>


                                            <Upload
                                                // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                listType=""
                                                multiple={true}
                                                fileList={this.state.fileList}
                                                //onPreview={this.handlePreview}

                                                onChange={this.handleUpload}
                                                beforeUpload={() => false} // Return False So That Antd Doesn't Upload The Picture Right Away
                                            >
                                                <Button icon={<UploadOutlined />}>Upload the relevant documets</Button>
                                            </Upload>


                                        </div>
                                    </div>
                                    <Modal
                                        visible={this.state.previewVisible}
                                        footer={null}
                                        onCancel={this.handleCancel}
                                    >
                                        <img alt="example" style={{ width: "100%" }} src={this.state.previewImage} />
                                    </Modal>

                                    {
                                        this.state.selectedGroupId == 1 ? (
                                        
                                        <div className="col-md-6">


                                            <label className="form-label" for="depot">
                                                Visiting Purpose
                                            </label>
                                            <Select
                                                //showSearch
                                                onChange={this.onChangeVisitingPurpose}
                                                //defaultValue={[]}
                                                style={{ width: "100%" }}
                                                placeholder="Search to Select"
                                                optionFilterProp="children"

                                            >
                                                {this.state.SiteAccessBaseDataVisitingPurposes.map((item, index) => {
                                                    return <Option value={item.id}>{item.name} </Option>;
                                                })}
                                            </Select>

                                        </div>) : ('')
                                    }
                                    {this.state.selectedGroupId == 16 || this.state.selectedGroupId == 10 ? (<div className="col-md-6" style={{ marginBottom: 0 }}>
                                        <label className="form-label" for="depot">
                                            Reference No
                                        </label>
                                        <Form.Item name="ref_no" required tooltip="This is a required field">
                                            <Input placeholder="Reference No" id="ref_no" onChange={this.handleChangeRefNo} />
                                        </Form.Item>

                                    </div>) : ('')}
                                    
                                    {this.state.selectedGroupId == 16 || this.state.selectedGroupId == 2 || this.state.selectedGroupId == 4 || this.state.selectedGroupId == 10 ? (<div className="col-md-6" style={{ marginBottom: 0 }}>
                                            <br></br>
                                        <label className="form-label" for="depot">
                                            Work Description
                                        </label>
                                        <Form.Item name="work_des" required tooltip="This is a required field">
                                            <Input placeholder=" Work Description" id="work_des" onChange={this.handleChangeWorkDes} />
                                        </Form.Item>

                                    </div>) : ('')}

                                    {this.state.selectedGroupId == 2 ? (<div className="col-md-6">


                                        <label className="form-label" for="depot">
                                            Work Type
                                        </label>
                                        <Select
                                            //showSearch
                                            onChange={this.onChangeVisitingPurpose}

                                            //defaultValue={[]}
                                            style={{ width: "100%" }}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"

                                        >
                                            {this.state.SiteAccessBaseDataVisitingPurposes.map((item, index) => {
                                                return <Option value={item.id}>{item.name} </Option>;
                                            })}
                                        </Select>

                                    </div>) : ('')}

                                    {this.state.selectedGroupId == 1 ? (<div className="col-md-6">


                                        <label className="form-label" for="depot">
                                            Access Need
                                        </label>
                                        <Select
                                            //showSearch
                                            onChange={this.onChangeAccessNeed}
                                            //defaultValue={[]}
                                            style={{ width: "100%" }}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"

                                        >
                                            {this.state.SiteAccessBaseDataAccessNeed.map((item, index) => {
                                                return <Option value={item.id}>{item.name} </Option>;
                                            })}
                                        </Select>

                                    </div>) : ('')}
                                    {this.state.selectedGroupId == 2 ? (<div className="col-md-6">


                                        <label className="form-label" for="depot">
                                            Related Technologys
                                        </label>
                                        <Select
                                            //showSearch
                                            onChange={this.onChangerelatedTechnology}
                                            //defaultValue={[]}
                                            style={{ width: "100%" }}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"

                                        >
                                            {this.state.SiteAccessBaseDatarelatedTechnologys.map((item, index) => {
                                                return <Option value={item.id}>{item.name} </Option>;
                                            })}
                                        </Select>

                                    </div>) : ('')}

                                  <div className="col-md-6" style={{ marginBottom: 0 }}>
                                  <br></br>
                                        <label className="form-label" for="depot">
                                        Reason
                                        </label>
                                        <Form.Item name="work_des" required tooltip="This is a required field">
                                            <Input placeholder="Reason" id="work_des" onChange={this.handleChangeWorkDes} />
                                        </Form.Item>

                                    </div>
                                    {this.state.selectedGroupId == 4 ? (<div className="col-md-6" style={{ marginBottom: 0 }}>
                                        <label className="form-label" for="depot">
                                            Project Name
                                        </label>
                                        <Form.Item required tooltip="This is a required field">
                                            <Input placeholder="Reason" />
                                        </Form.Item>

                                    </div>) : ('')}
                                   
                                        <div className='row'>
                                        {this.state.selectedGroupId == 10 || this.state.selectedGroupId == 1? (<div className="col-md-6" style={{ marginBottom: 0 }}>
                                            <label className="form-label" for="depot">
                                                Remarks
                                            </label>
                                            <Form.Item required tooltip="This is a required field">
                                                <Input placeholder="Reason" onChange={this.handleRemarks} />
                                            </Form.Item>

                                        </div>

                                        ) : ("")}

                                        {this.state.selectedGroupId == 1 ? (<div> <div className="col-md-6" style={{ marginBottom: 0 }}>
                                                <label className="form-label" for="depot">
                                                    Work Type
                                                </label>
                                                <Select
                                                    //showSearch
                                                    onChange={this.onChangeWorkType}

                                                    //defaultValue={[]}
                                                    style={{ width: "100%" }}
                                                    placeholder="Search to Select"
                                                    optionFilterProp="children"

                                                >
                                                    {this.state.selectWorkType.map((item, index) => {
                                                        return <Option value={item.id}>{item.name} </Option>;
                                                    })}
                                                </Select>

                                            </div>
                                            <div className="col-md-6" style={{ marginBottom: 0 }}>
                                                <label className="form-label" for="depot">
                                                    Work Area
                                                </label>
                                                <Select
                                                    //showSearch
                                                    onChange={this.onChangeWorkArea}

                                                    //defaultValue={[]}
                                                    style={{ width: "100%" }}
                                                    placeholder="Search to Select"
                                                    optionFilterProp="children"

                                                >
                                                    {this.state.selectWorkArea.map((item, index) => {
                                                        return <Option value={item.id}>{item.name} </Option>;
                                                    })}
                                                </Select>

                                            </div></div>):('')}

                                           
                                        </div>

                                   




                                </div>

                                <br></br>
                                <div class='col-md-12' style={{ textAlign: "end" }}>

                                    {this.state.submit_loading ? (<ReactLoading
                                        type={"bars"}
                                        color={"#03fc4e"}
                                        height={86}
                                        width={100}

                                    // style={{color:"#03fc4e",flex: 0.1}} 
                                    />) : (<button
                                        className="btn btn-primary"
                                        onClick={() => this.AllDataSubmit()}
                                    >
                                        Submit
                                    </button>)}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    //console.log("state", state);
    return {
        organization_count: state.organization.length,
        regions: state.regions,
        depots: state.depots,
        sites_count: state.sites.length,
        vendor_count: state.vendor.length,
        roles_count: state.roles.length,
    };
}
export default withRouter(connect(mapStateToProps, { getDonationCategories, getData })(Create)); 