import React, { useState, useEffect } from 'react';
import {
    ClockCircleFilled,
    PlusCircleFilled,
    EditOutlined,
    DeleteOutlined,
    EyeOutlined,
    SearchOutlined
} from '@ant-design/icons';
import { Divider, Space, Card, Skeleton, Switch, Table, Select, Badge, Input, Button, Row, Upload, Col, Dropdown, Tag, Modal, Steps, message, Form, DatePicker, TimePicker, Descriptions, Image, Radio, Checkbox, Collapse, Spin } from 'antd';
import {
    genMakeCreate
} from "../../api/index";
import { get } from '../../api/api.client';

import '../../pages/master-data/style.css';
const { Step } = Steps;

const GenMake = () => {
    const [getAllGenMake, setGetAllGenMake] = useState([]);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [form] = Form.useForm();
    const [searchData , setSearchData] = useState([]);

    useEffect(() => {
        getAllDataGen();
    }, []);

    const getAllDataGen = async () => {
        setLoading(true);
        const res = await genMakeCreate('getAll');
        if (res) {
            setGetAllGenMake(res.data);
            setSearchData(res.data);
        }
        setLoading(false);
    }

    const columns = [
        {
            title: "Genaerator Make",
            dataIndex: "name",
            key: "name",
            render: (item) => <span>{item}</span>,
            },
            {
                title: "Action",
                dataIndex: "code",
                key: "code",
                render: (text, record) => (
                    <Space size="middle">
                        <Button
                            shape='circle'
                            size='small'
                            icon={<EditOutlined />}
                            type="primary"
                            onClick={() => {
                                setVisible(true);
                                setSelectedRecord(record);
                                setIsEdit(true);
                            }}
                        />

                        <Button
                            shape='circle'
                            size='small'
                            danger
                            icon={<DeleteOutlined />}
                            type="primary"
                            onClick={() => {
                                Modal.confirm({
                                    title: 'Are you sure you want to delete this Generator Make?',
                                    onOk: async () => {
                                        const formdata = new FormData();
                                        formdata.append('GenMakeId', record.id);
                                        const res = await genMakeCreate('deleteGenMake', formdata);
                                        if (res) {
                                            message.success('Generator Make deleted successfully');
                                            getAllDataGen();
                                        }
                                    }
                                });
                            }}
                        />
                    </Space>
                )
              },
    ]
    
    return (
        <div style={{ padding: '20px', backgroundColor: '#fff' }}>

            <Row>
                <Col span={12}>
                <Button
                    type="primary"
                    style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px', height: 33 }}
                    onClick={() => {
                        setVisible(true);
                        setIsEdit(false);
                    }}
                    icon={<PlusCircleFilled />}
                >
                    <span className='textStyles-small'>Generator Make</span>
                </Button>
                </Col>
<Col span={12}>
  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
    <Input 
      onChange={(e) => {
        const value = e.target.value;
        const filteredData = getAllGenMake?.filter((entry) => entry.name.toLowerCase().includes(value.toLowerCase())) || [];
        setSearchData(filteredData);
      }}
      placeholder='Search Generator Make'
      style={{ width: '80%', height: 33 }}
      className='borderedSelect'
    />
  </div>
</Col>
            </Row>
            <Table
                style={{marginTop: 10}}
                columns={columns}
                dataSource={searchData.reverse() || []}
                loading={loading}
                rowKey={(record) => record.id}
                pagination={{ pageSize: 10 }}
                size='small'
                className='table-striped-rows textStyles-small'
            />

            <Modal
                title={isEdit ? <span className='textStyles-small' style={{fontSize: 14}}>Edit Generator Make</span> : 
                <span className='textStyles-small' style={{fontSize: 14}}>Create Generator Make</span>}
                visible={visible}
                onCancel={() => {
                  setVisible(false);
                  form.resetFields();
                  setIsEdit(false);
                  setSelectedRecord(null);
                }}
                footer={null}
                destroyOnClose={true}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={async (values) => {

                        if (!isEdit) {
                            const res = await genMakeCreate('create', values);
                            if (res) {
                                message.success('Generator Make created successfully');
                                setVisible(false);
                                getAllDataGen();
                            }
                            return;
                        }else {
                            const res = await genMakeCreate('update', {
                                ...values,
                                id: selectedRecord.id
                            });
                            if (res) {
                                message.success('Generator Make updated successfully');
                                setVisible(false);
                                getAllDataGen();
                            }
                        }
                    }}
                >
                    <Form.Item
                        label={<span className='textStyles-small'>Generator Make</span>}
                        name="name"
                        initialValue={selectedRecord?.name}
                        rules={[{ required: true, message: 'Please enter Generator Make' }]}
                    >
                        <Input className='borderedSelect' style={{height: 34}}/>
                    </Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Form.Item>
                        <Button
                          type="primary"
                          style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px', height: 33 }}
                          htmlType="submit">
                          <span className='textStyles-small'>{isEdit ? 'Update' : 'Create'}</span>
                        </Button>
                      </Form.Item>
                    </div>
                </Form>
            </Modal>
        </div>
    )
}

export default GenMake;