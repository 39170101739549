import ReactFlow, { MiniMap, Controls, Background, applyNodeChanges, ConnectionLineType, ReactFlowProvider } from 'react-flow-renderer';
import { useCallback, useState, useEffect } from 'react';
import { useLocation } from "react-router-dom"
import 'react-flow-renderer/dist/style.css';
import { getsldDiagramById } from '../../api/index';
import { getSiteDetails } from '../../api/Repos/SiteRepo';


import TextUpdaterNode from './TextUpdaterNode.js';

import './text-updater-node.css';

function Flow() {
  let fullEdgesSet = []
  let fullNodesSet = []
  let fullObject = [
    {
      "itemId": 1,
      "name": "ACDB",
      "levelNo": 3,
      "connectedItems": [
        6
      ]
    },
    {
      "itemId": 2,
      "name": "ATS",
      "levelNo": 2,
      "connectedItems": [
        1
      ]
    },
    {
      "itemId": 3,
      "name": "Commercial Power",
      "levelNo": 1,
      "connectedItems": [
        2
      ]
    },
    {
      "itemId": 4,
      "name": "Generator 1",
      "levelNo": 1,
      "connectedItems": [
        2
      ]
    },
    {
      "itemId": 5,
      "name": "Generator 1",
      "levelNo": 1,
      "connectedItems": [
        2
      ]
    },
    {
      "itemId": 6,
      "name": "rectifire",
      "levelNo": 4,
      "connectedItems": [
        7,
        8,
        9,
        10
      ]
    },
    {
      "itemId": 7,
      "name": "batteryBank",
      "levelNo": 5,
      "connectedItems": []
    },
    {
      "itemId": 8,
      "name": "batteryBank",
      "levelNo": 5,
      "connectedItems": []
    },
    {
      "itemId": 9,
      "name": "dcdu",
      "levelNo": 5,
      "connectedItems": []
    },
    {
      "itemId": 10,
      "name": "dcdu",
      "levelNo": 5,
      "connectedItems": []
    },
    {
      "itemId": 11,
      "name": "Solar",
      "levelNo": 1,
      "connectedItems": [
        6
      ]
    }
  ]

  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [siteName, setSiteName] = useState('');
  const [siteId, setSiteId] = useState('');

  useEffect(() => {


    let siteId = window.location.href.split('/')[5]
    console.log(siteId)

    async function getDatasldDiagramById() {
      var res = await getsldDiagramById(siteId);
      console.log(res);
      if (res.success) {
        fullObject = res.data
        setNodeLevelOne(fullObject)
        setEdgeslOne(fullObject)

      }

    }

    async function getSiteData() {

      var res = await getSiteDetails(siteId);
      console.log(res)
      if (res.success) {
        setSiteName(res.data.siteName)
        setSiteId(res.data.siteId)

      }

    }
    // setNodeLevelOne(fullObject)
    // setEdgeslOne(fullObject)
     getDatasldDiagramById()
     getSiteData()




  }, []);







  const generateColor = () => {
    let randomColorString = "#";
    const arrayOfColorFunctions = "0123456789abcdef";
    for (let x = 0; x < 6; x++) {
      let index = Math.floor(Math.random() * 16);
      let value = arrayOfColorFunctions[index];

      randomColorString += value;
    }
    return randomColorString;
  };
  const setEdgeslOne = (data) => {
    const levelOne = data.filter(person => person.levelNo == 1)
    createEdges(levelOne, 1)

  }
  const setEdgeslTwo = (data) => {
    const levelTwo = data.filter(person => person.levelNo == 2)
    createEdges(levelTwo, 2)

  }
  const setEdgeslThree = (data) => {
    const levelThree = data.filter(person => person.levelNo == 3)
    createEdges(levelThree, 3)

  }
  const setEdgeslFour = (data) => {
    const levelFour = data.filter(person => person.levelNo == 4)
    createEdges(levelFour, 4)

  }

  const setNodeLevelOne = (data) => {
    const levelOne = data.filter(person => person.levelNo == 1)
    createNodes(levelOne, 1)

  };
  const setNodeLevelTwo = (data) => {
    const levelTwo = data.filter(person => person.levelNo == 2)
    createNodes(levelTwo, 2)


  };
  const setNodeLevelThree = (data) => {
    const levelThree = data.filter(person => person.levelNo == 3)
    createNodes(levelThree, 3)
  };
  const setNodeLevelFour = (data) => {
    const levelFour = data.filter(person => person.levelNo == 4)
    createNodes(levelFour, 4)
  };
  const setNodeLevelFive = (data) => {
    const levelFive = data.filter(person => person.levelNo == 5)
    createNodes(levelFive, 5)
  };
  const createEdges = (data, lv) => {
    if (lv == 1) {

      var newseArray
      var newseArray2 = []

      var newNodesArray = data.map(value => {

        value.connectedItems.map(x => {
          newseArray = {
            "id": value.itemId + '-' + x.toString(),
            source: value.itemId.toString(),
            target: x.toString(),
            
          
            style: { stroke: "#f6ab6c" ,strokeWidth:2}
          }

          newseArray2 = [...newseArray2, newseArray];
        })


      })

      fullEdgesSet = newseArray2
      setEdgeslTwo(fullObject)

    }
    if (lv == 2) {
      console.log("fullEdgesSet2")
      console.log(fullEdgesSet)
      var newseArray
      var newseArray2 = []
      var newNodesArray = data.map(value => {

        value.connectedItems.map(x => {
          newseArray = {
            "id": value.itemId + '-' + x.toString(),
            source: value.itemId.toString(),
            target: x.toString(),
            type: 'straight',
            style: { strokeWidth:2}
          }

          newseArray2 = [...newseArray2, newseArray];
        })


      })



      fullEdgesSet = [...fullEdgesSet, ...newseArray2];
      setEdgeslThree(fullObject)



    }
    if (lv == 3) {
      console.log("fullEdgesSet2")
      console.log(fullEdgesSet)
      var newseArray
      var newseArray2 = []
      var newNodesArray = data.map(value => {

        value.connectedItems.map(x => {
          newseArray = {
            "id": value.itemId + '-' + x.toString(),
            source: value.itemId.toString(),
            target: x.toString(),
            
            style: {strokeWidth:2 }
          }

          newseArray2 = [...newseArray2, newseArray];
        })


      })



      fullEdgesSet = [...fullEdgesSet, ...newseArray2];
      setEdgeslFour(fullObject)



    }
    if (lv == 4) {
      console.log("fullEdgesSet2")
      console.log(fullEdgesSet)
      var newseArray
      var newseArray2 = []
      var newNodesArray = data.map(value => {
        let setColor = generateColor();
        value.connectedItems.map(x => {
          newseArray = {
            "id": value.itemId + '-' + x.toString(),
            source: value.itemId.toString(),
            target: x.toString(),
            
            style: { stroke: setColor,strokeWidth:3 }
          }

          newseArray2 = [...newseArray2, newseArray];
        })


      })



      fullEdgesSet = [...fullEdgesSet, ...newseArray2];
      setEdges(fullEdgesSet)



    }

  }
  const createNodes = (data, lv) => {

    if (lv == 1) {

      var newseArray
      var setY = 30
      var newNodesArray = data.map(value => {
        if (setY == 30) {
          var setY12 = setY
          setY = setY + 50
        } else {
          var setY12 = setY
          setY = setY + 50
        }
        return newseArray = {
          "id": value.itemId.toString(),
          "data": { label: value.name },
          type: 'input',
          sourcePosition: "right",
          position: { x: 20, y: setY12 },
          style: {
            background: "#FFA500",
            color: "#FFFFFF",
            border: "1px solid #222138",

          }
        }
      })
      console.log('newNodesArray')
      fullNodesSet = newNodesArray
      setNodeLevelTwo(fullObject)
    }
    if (lv == 2) {

      var newseArray
      var setY = 30
      var newNodesArray = data.map(value => {
        if (setY == 30) {
          var setY12 = setY
          setY = setY + 100
        } else {
          var setY12 = setY
          setY = setY + 100
        }
        return newseArray = {
          "id": value.itemId.toString(),
          "data": { label: value.name },
          targetPosition: "left",

          sourcePosition: "right",
          position: { x: 250, y: setY12 },
          style: {
            background: "#5539CC",
            color: "#FFFFFF",
            border: "1px solid #222138",

          }
        }
      })

      fullNodesSet = [...fullNodesSet, ...newNodesArray];
      setNodeLevelThree(fullObject)
    }
    if (lv == 3) {
      var newseArray
      var setY = 30
      var newNodesArray = data.map(value => {
        if (setY == 30) {
          var setY12 = setY
          setY = setY + 100
        } else {
          var setY12 = setY
          setY = setY + 100
        }
        return newseArray = {
          "id": value.itemId.toString(),
          "data": { label: value.name },
          targetPosition: "left",

          sourcePosition: "right",
          position: { x: 420, y: setY12 },
          style: {
            background: "#5539CC",
            color: "#FFFFFF",
            border: "1px solid #222138",

          }
        }
      })

      fullNodesSet = [...fullNodesSet, ...newNodesArray];
      setNodeLevelFour(fullObject)




    }
    if (lv == 4) {
      var newseArray
      var setY = 0
      var newNodesArray = data.map(value => {
        if (setY == 0) {
          var setY12 = setY
          setY = setY + 100
        } else {
          var setY12 = setY
          setY = setY + 100
        }
        return newseArray = {
          "id": value.itemId.toString(),
          "data": { label: value.name },
          targetPosition: "left",

          sourcePosition: "right",
          position: { x: 650, y: setY12 },
          style: {
            background: "#B6B6B4",
            color: "#FFFFFF",
            border: "1px solid #222138",

          }
        }
      })

      fullNodesSet = [...fullNodesSet, ...newNodesArray];
      setNodeLevelFive(fullObject)


    }
    if (lv == 5) {
      var newseArray
      var setY = 10
      var newNodesArray = data.map(value => {
        if (setY == 10) {
          var setY12 = setY
          setY = setY + 60
        } else {
          var setY12 = setY
          setY = setY + 60
        }
        return newseArray = {
          "id": value.itemId.toString(),
          "data": { label: value.name },
          type: 'output',

          targetPosition: "left",


          position: { x: 950, y: setY12 },
          style: {
            background:"#"+value.color,
            color: "#FFFFFF",
            border: "1px solid #222138",

          }
        }
      })

      fullNodesSet = [...fullNodesSet, ...newNodesArray];
      setNodes(fullNodesSet)
    }
  }


  const nodeTypes = { textUpdater: TextUpdaterNode };

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );

  return (
    <div className="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="page-title-box">
            <div class="row">
              <div class="col">
                <h4 class="page-title">Site SLD Diagram </h4>
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="javascript:void(0);">{siteName} </a></li>
                  <li class="breadcrumb-item"><a href="javascript:void(0);">{siteId}</a></li>

                </ol>
              </div>
              <div class="col-auto align-self-center">
                <div class="btn btn-sm btn-outline-primary" id="Dash_Date">
                  <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                  <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div   style={{ width: "100%", height: "calc(100% - 30px)" }}
      className="layoutflow">
        <div style={{ width: "100%", height: "100%" }}>



          <ReactFlowProvider>

            <ReactFlow
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              onNodesChange={onNodesChange}
              connectionLineType={ConnectionLineType.SmoothStep}

            // onEdgesChange={onEdgesChange}
            // onConnect={onConnect}
            >
              <MiniMap />
              <Controls />
            </ReactFlow>
          </ReactFlowProvider>
        </div>
      </div>




    </div >
  );
}

export default Flow;
