
import React, { Component } from "react";
import { Table, Input, Tag, Space, DatePicker } from "antd";
import ReactExport from "react-data-export";
import { getVehiclePaymentSheet } from "../../api/index";
import { getDonationCategories, getData } from "../../actions/index";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'

import ReactLoading from 'react-loading';
const moment = require('moment');




const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { RangePicker } = DatePicker;

class PaymentFullExport extends Component {
    constructor(props) {
        super(props);
        this.state = {
          title: "",
          filterTable: null,
          data_list: [],
          startDate: "",
          endDate: "",
          loading:false,
    
        };
      }
    
      componentDidMount() {
        if (JSON.parse(localStorage.getItem('serviceTypeSet')) == null) {
          const { history } = this.props;
   
      history.push("/");
         
        } else {
          this.getSetPageData(JSON.parse(localStorage.getItem('serviceTypeSet')) , 16)
        }
        this.props.getData();

        //console.log(this.props.details);
      }
      getSetPageData =(serviceTypeIs,Pageid) =>  {
        //console.log("this.props");
        //console.log(serviceTypeIs.serviceType);
        if(serviceTypeIs.serviceType.filter(checkuser=>checkuser?.id==Pageid)[0] == null ){
          const { history } = this.props;
       
          history.push("/not-allowed");
        }
        
      };
      getDataexpot = async (data) => {
          //console.log(data);
        var res = await getVehiclePaymentSheet(data);
        //console.log("getVehiclePaymentSheet");
        //console.log(res);
        if (res.success) {
          this.setState({
            loading: false,
            data_list: res.data,
          });
        }
      };
      onChangeDate = (date, dateString) => {
        //console.log(date);
        //console.log(dateString.slice(0,-3));
        //console.log(dateString.slice(-2));
        this.setState({
          startDate: dateString.slice(0,-3),
          endDate: dateString.slice(-2),
        });
      };
    
      searchClick = () => {
        //console.log("search");
        if(this.state.startDate!="" && this.state.endDate){
        //   var data =  {
        //     "RequestDateFrom": this.state.startDate,
        //     "RequestDateTo": this.state.endDate,
           
        // };
        var data = new FormData();

        data.append("RequestMonth",this.state.endDate);
        data.append("RequestYear",this.state.startDate);
      
          this.setState({
            loading: true,
          });
          this.getDataexpot(data);
        }
        
      };
      render() {
        const fontValue =  {sz: "12", bold: true,fill: {patternType: "solid", fgColor: {rgb: "FF0B0713"}}};
        const { filterTable } = this.state;
        const dataSet1 = this.state.data_list;
    
       
    
        const StatisticDataSet = [
    {
      columns: [
        // Sec 01
        {title: "Vehicle Code",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:210}},//char width 
        {title: "Vehicle Region", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width 
       // {title: "Journey ID", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Fixed User Email", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Vehicle Registation Number", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
      
       {title: "Model", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//char width cManual_transport_amount
        {title: "Brand", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//char width cManual_transport_amount
        {title: "Owner", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//char width cManual_transport_amount
        {title: "Committed Milage",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}} },fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//pixels width 
       
        {title: "Extra rate", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//char width cManual_transport_amount
        {title: "Unit Rate", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//char width cManual_transport_amount
        
        // Sec 02
        {title: "Payment Category",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}} },fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//pixels width 
        {title: "Year",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width   
       // Sec03
       {title: "Month",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width   
       {title: "Consumed Milage",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width   
       {title: "Payment for Ex milage",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width   
       {title: "Total payment",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width   
      
        
        
    ],
    data:dataSet1?.map((data)=>[
    
      // Sec 01
      {value:data.vehicleCode ,style: { font: { sz: "12" } }},
     // {value:data.createdOn?moment((data.createdOn)).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
     {value:data.vehicleRegion?(data.vehicleRegion):("Null") ,style: { font: { sz: "12" } }},
     {value:data.fixedUserEmail?(data.fixedUserEmail):("Null") ,style: { font: { sz: "12" } }},
     
     
      {value:data.vehicleRegistrationNo ?(data.vehicleRegistrationNo ):("Null") ,style: { font: { sz: "12" } }},
      {value:data.vehicleModel?(data.vehicleModel):("Null") ,style: { font: { sz: "12" } }},
      {value:data.vehicleBrand?(data.vehicleBrand):("Null") ,style: { font: { sz: "12" } }},
      {value:data.owner?(data.owner):("Null") ,style: { font: { sz: "12" } }},
      
      
     
      {value:data.commitedMilage?(data.commitedMilage):("Null") ,style: { font: { sz: "12" } }}, 
      {value:data.extraMilageRate?(data.extraMilageRate):(0) ,style: { font: { sz: "12" } }}, 
      {value:data.unitRate?(data.unitRate):(0) ,style: { font: { sz: "12" } }}, 
     
      
      // Sec 2
      {value:data.paymentCategory?(data.paymentCategory):("Null") ,style: { font: { sz: "12" } }},
      {value:data.year?(data.year):("Null") ,style: { font: { sz: "12" } }},
  
      // Sec 03
      {value:data.month?(data.month):("Null") ,style: { font: { sz: "12" } }},
      {value:data.consumedMilage?(data.consumedMilage):(0) ,style: { font: { sz: "12" } }},
      {value:data.paymentForExMilage?(data.paymentForExMilage):(0) ,style: { font: { sz: "12" } }},
      {value:data.totalPayment?(data.totalPayment):(0) ,style: { font: { sz: "12" } }},
          
    ]),
    }
        ];
    
        const columns = [
          {
            title: "vehicle Code",
            dataIndex: "vehicleCode",
            key: "vehicleCode",
            render: (item) => <span>{item}</span>,
          },
          {
            title: "Vehicle Region",
            dataIndex: "vehicleRegion",
            key: "vehicleRegion",
            render: (item) => <span>{item}</span>,
          },
          {
            title: "Vehicle No",
            dataIndex: "vehicleRegistrationNo",
            key: "vehicleRegistrationNo",
            render: (item) => <span>{item}</span>,
          },
          {
            title: "Vehicle Model",
            dataIndex: "vehicleModel",
            key: "vehicleModel",
            render: (item) => <span>{item}</span>,

          },
          {
            title: "Vehicle Brand",
            dataIndex: "vehicleBrand",
            key: "vehicleBrand",
            render: (item) => <span>{item}</span>,

          },
        ];
        const timestamp = Date.now();
        return (
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="row">
                    <div class="col">
                      <h4 class="page-title">Payment Full Export</h4>
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Data Export </a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Payment Full Export</a></li>

                      </ol>
                    </div>
                    <div class="col-auto align-self-center">
                      <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                      </a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div className="row">
            <div className="col-md-3 col-lg-3 col-xl-3">
              <DatePicker
                picker="month" 
                placeholder="Start Date"
                style={{ width: "100%" }}
                onChange={this.onChangeDate}
              />
            </div>
            
            <div className="col-md-6 col-lg-6 col-xl-6">
              <button
                onClick={this.searchClick}
                class="btn btn-primary"
                //style={{ float: "right" }}
              >
                <i class="fas fa-search"> </i> Search
              </button>
            </div>
            <br/><br/>
            <div className="col-lg-12 col-xl-12">
              <div className="card">
                <div className="card-header">
                  <div className="row ">
                    <div className="col">
                      <h4 className="card-title">
                        Report -Payment Full export{" "}
                        <ExcelFile
                          filename={"Payment Full export -" + timestamp}
                          element={
                            <button
                              class="btn btn-primary"
                              style={{ float: "right" }}
                            >
                              <i class="fas fa-file-export"> </i>Payment Full export
                            </button>
                          }
                        >
                         
                          <ExcelSheet dataSet={StatisticDataSet} name="Payment Full export"/>
                        </ExcelFile>
                      </h4>
                      <div></div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
               
                  <Table
                    size="small"
                    columns={columns}
                    // rowKey="id"
                    loading={this.state.loading}
                    dataSource={this.state.data_list}
                  />
        
                </div>
              </div>
            </div>
          </div>
          </div>
        );
      }
}
function mapStateToProps(state) {
  //console.log("state", state);
  return {
    organization_count: state.organization.length,
    regions: state.regions,
    depots: state.depots,
    sites_count: state.sites.length,
    vendor_count: state.vendor.length,
    roles_count: state.roles.length,
  };
}
export default   withRouter(connect(mapStateToProps, { getDonationCategories, getData })(
    PaymentFullExport
)); 