import React, { Component } from 'react'
import { connect } from "react-redux";
import { getDonationCategories, getData } from "../../../actions/index";
import { getDashboardChartsDataREWAMP } from "../../../api/index";
import StackedBarLine from '../../../components/charts/stacked-bar-line';
import StackedBarChat from '../../../components/charts/stacked-bar';
import LineCharts from '../../../components/charts/line-charts';
import ColumnChartsDataLablesChart from '../../../components/charts/column-charts-data-lables'
import SemiCircleGaugeChat from '../../../components/charts/semi-circle-gauge'
import SimpleDonutChart from '../../../components/charts/simple-donut-chart'
import StackedBarWithoutTick from '../../../components/charts/stackChart-withoutTick'
import '../style.css';
import {
    PlusCircleFilled,
    PlusSquareFilled,
    ClockCircleFilled,
    CarFilled,
    UserOutlined,
    CarOutlined,
    PlusCircleOutlined,
    NodeIndexOutlined,
    FieldTimeOutlined
} from '@ant-design/icons';
import '../style.css';
import { Select, DatePicker, Space, Badge, message, Row, Col, Spin, Empty } from "antd";
import moment from 'moment';
const { RangePicker } = DatePicker;

class AccessDash extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SubRegion_list: [],
            depot_list: [],
            filter_date: moment(moment().format("YYYY-MM-DD").slice(0, -2) + '01')._i,
            filter_date_to: moment().endOf('month').format("YYYY-MM-DD"),
            filter_depot: [],
            filter_region: [],
            filter_sub_region: [],
            journey_end_count: 0,
            journey_admin_verify_count: 0,
            journey_start_count: 0,
            journey_officer_verify_count: 0,
            journey_vehicle_release_count: 0,
            total_count: 0,
            journey_initiate_count: 0,
            journey_canceled_count: 0,
            getAllData: [],
            vehicleSeriesCount: [20, 28, 30, 12],
            vehicleSerieslabels: [],
            runningKMChart2: false,
            runningKMChart2SetY1: null,
            runningKMChart2SetX1: null,
            runningKMChart2: false,
            runningKMChart2SetY1: null,
            runningKMChart2SetX1: null,
            requesteTrendChart: false,
            requesteTrendChartSetY1: null,
            requesteTrendChartSetX1: null,
            journeyStatus: false,
            journeyStatusSetY1: null,
            journeyStatusSetX1: null,
            runningKMChart1: false,
            runningKMChart1SetY1: null,
            runningKMChart1SetX1: null,

            vehicleRegionViceMillageERChart: false,
            vehicleRegionViceMillageERChartValues: null,
            vehicleRegionViceMillageERChartTarget: null,
            vehicleRegionViceMillageERChartRealValue: null,

            vehicleRegionViceMillageWRChart: false,
            vehicleRegionViceMillageWRChartValues: null,
            vehicleRegionViceMillageWRChartTarget: null,
            vehicleRegionViceMillageWRChartRealValue: null,

            vehicleRegionViceMillageCRChart: false,
            vehicleRegionViceMillageCRChartValues: null,
            vehicleRegionViceMillageCRChartTarget: null,
            vehicleRegionViceMillageCRChartRealValue: null,

            vehicleRegionViceMillageSRChart: false,
            vehicleRegionViceMillageSRChartValues: null,
            vehicleRegionViceMillageSRChartTarget: null,
            vehicleRegionViceMillageSRChartRealValue: null,
            vehicleRegionViceMillageNRChart: false,
            vehicleRegionViceMillageNRChartValues: null,
            vehicleRegionViceMillageNRChartTarget: null,
            vehicleRegionViceMillageNRChartRealValue: null,

            runningKMChartWithcumulativeData: null,
            journeyStatusChartData: null,
            requestTrendChartData: null,

            chartSelectedRegionSet: 1,
            chartTwoSelectedRegionSet: 1,


            basicAccessInformations: null,

            requestStatusSetY1: null,
            requestStatusSetX1: null,
            requestStatus: false,

            requestBrSetY1: null,
            requestBrSetX1: null,
            requestBrStatus: false,

            isLoading: false,
            pieChartDataX1: null,
            pieChartDataY1: null,
            pieChartDisplay: false,

        }
    }

    componentDidMount() {
        this.props.getData();
        var data1 = new FormData();
        let year = this.state.filter_date ? moment(this.state.filter_date).year() : moment().year();
        let month = this.state.filter_date_to ? moment(this.state.filter_date_to).month() + 1 : moment().endOf('month').month() + 1;
        data1.append('year', year);
        data1.append('month', month);
        this.fetchAccessBasicDetails(data1);
        this.setState({
            depot_list: this.props.depots,
            SubRegion_list: this.props.regions.filter((item) => {
                return item.isSubRegion == 1;
            }),
        });
    }

    fetchAccessBasicDetails = async (data) => {
        this.setState({ isLoading: true });
        var res = await getDashboardChartsDataREWAMP(data);
        if (res.success) {
            this.setState({
                basicAccessInformations: res?.data?.statusViceCount
            })
            this.fetchJourneyTrendDetails(res?.data?.monthlyRequestTrend);
            this.getMonthlyRegionViceMillageRewampFetch(res?.data?.regionViceInitiatedAndApproved);
            this.momChartDataSet(res?.data?.mom);
            this.requestCategoryBreakdownDataSet(res?.data?.requestCategoryBreakdown);
            this.pieChartDataSet(res?.data?.singleVsBulk);
            this.setState({ isLoading: false });
        } else {
            console.log(res);
            this.setState({ isLoading: false });
        }
    }

    fetchJourneyTrendDetails = async (data) => {
        this.setState({
            requesteTrendChartSetX1: null,
            requesteTrendChartSetY1: null,
            requesteTrendChart: false
        });
        if (data != null) {
            let set_year = this.state.filter_date;
            let set_month = this.state.filter_date_to;
            let set_data = set_year + '-' + set_month
            const inputDate = set_data;
            const lastDayOfMonth = this.getLastDayOfMonth(inputDate);
            const today = new Date(lastDayOfMonth);
            const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
            const formattedFirstDay = firstDayOfMonth.toISOString().split('T')[0];

            const filteredData = data
                .filter(entry => entry.date >= formattedFirstDay && entry.date <= today.toISOString().split('T')[0])
                .sort((a, b) => Date.parse(a.date) - Date.parse(b.date));

            const countSet = filteredData.map(entry => entry.count);
            const dateSet = filteredData.map(entry => entry.date);

            this.setState({
                requesteTrendChartSetY1: [{
                    name: "Request",
                    data: countSet
                }],
                requesteTrendChartSetX1: dateSet,
            }, () => {
                this.setState({
                    requesteTrendChart: true
                })
            })
        } else {
            message.error('Data Fetching Error, In Journey Trend Details !');
        }
    }

    getMonthlyRegionViceMillageRewampFetch = async (data) => {
        console.log(data);
        this.setState({
            vehicleRegionViceMillageERChartValues: null,
            vehicleRegionViceMillageERChartTarget: null,
            vehicleRegionViceMillageERChartRealValue: null,
            vehicleRegionViceMillageERChart: false,
            vehicleRegionViceMillageWRChartValues: null,
            vehicleRegionViceMillageWRChartTarget: null,
            vehicleRegionViceMillageWRChartRealValue: null,
            vehicleRegionViceMillageWRChart: false,
            vehicleRegionViceMillageCRChartValues: null,
            vehicleRegionViceMillageCRChartTarget: null,
            vehicleRegionViceMillageCRChartRealValue: null,
            vehicleRegionViceMillageCRChart: false,
            vehicleRegionViceMillageSRChartValues: null,
            vehicleRegionViceMillageSRChartTarget: null,
            vehicleRegionViceMillageSRChartRealValue: null,
            vehicleRegionViceMillageSRChart: false,
            vehicleRegionViceMillageNRChartValues: null,
            vehicleRegionViceMillageNRChartTarget: null,
            vehicleRegionViceMillageNRChartRealValue: null,
            vehicleRegionViceMillageNRChart: false,
        });
        if (data !== null) {
            data?.map((x, y) => {
if (x.name == 'Eastern (ER)') {
    console.log(x);
    this.setState({
        vehicleRegionViceMillageERChartTarget: x?.approved ? x?.approved : 0,
        vehicleRegionViceMillageERChartRealValue: x?.initiated ? x?.initiated : 0,
    }, () => {
        this.setState({
            vehicleRegionViceMillageERChart: true
        })
    })
}
                if (x.name == 'Western (WR)') {
                    console.log(x);
                    //let setvaluesscr = (x?.initiated / x?.approved)
                    this.setState({
                      //  vehicleRegionViceMillageWRChartValues: setvaluesscr,
                        vehicleRegionViceMillageWRChartTarget: x?.approved ? x?.approved : 0,
                        vehicleRegionViceMillageWRChartRealValue: x?.initiated ? x?.initiated : 0,
                    }, () => {
                        this.setState({
                            vehicleRegionViceMillageWRChart: true
                        })
                    })
                }
                if (x.name == 'Central (CR)') {
                    console.log(x);
                    //let setvaluesscr = (x?.initiated / x?.approved)
                    this.setState({
                        //vehicleRegionViceMillageCRChartValues: setvaluesscr,
                        vehicleRegionViceMillageCRChartTarget: x?.approved ? x?.approved : 0,
                        vehicleRegionViceMillageCRChartRealValue: x?.initiated ? x?.initiated : 0,
                    }, () => {
                        this.setState({
                            vehicleRegionViceMillageCRChart: true
                        })
                    })
                }
                if (x.name == 'Southern (SR)') {
                    console.log(x);
                    //let setvaluesscr = (x?.initiated / x?.approved)
                    this.setState({
                        //vehicleRegionViceMillageSRChartValues: setvaluesscr,
                        vehicleRegionViceMillageSRChartTarget: x?.approved ? x?.approved : 0,
                        vehicleRegionViceMillageSRChartRealValue: x?.initiated ? x?.initiated : 0,
                    }, () => {
                        this.setState({
                            vehicleRegionViceMillageSRChart: true
                        })
                    })
                }
                if (x.name == 'Northern (NR)') {
                    console.log(x);
                    //let setvaluesscr = (x?.initiated / x?.approved)
                    this.setState({
                      //  vehicleRegionViceMillageNRChartValues: setvaluesscr,
                        vehicleRegionViceMillageNRChartTarget: x?.approved ? x?.approved : 0,
                        vehicleRegionViceMillageNRChartRealValue: x?.initiated ? x?.initiated : 0,
                    }, () => {
                        this.setState({
                            vehicleRegionViceMillageNRChart: true
                        })
                    })
                }
            })
        } else {
            message.error('Data Fetching Error !');
        }
    }

    momChartDataSet(data) {
        if (data != null) {
            this.setState({
                requestStatusSetY1: null,
                requestStatusSetX1: null,
                requestStatus: false,
            })

            data.sort((a, b) => (b.year + b.month) - (a.year + a.month));

            let canceledSet = data.map(a => a?.cancel);
            let rejectedSet = data.map(a => a?.reject);
            let pendingSet = data.map(a => a?.pending);
            let completeSet = data.map(a => a?.complete);
            let regionSet = data.map(a => a?.month?.substring(0, 3) + '-' + a?.year);

            this.setState({
                requestStatusSetY1: [{
                    name: 'Cancelled',
                    type: 'column',
                    data: canceledSet
                },
                {
                    name: 'Pending Approval',
                    type: 'column',
                    data: pendingSet
                },
                {
                    name: 'Rejected',
                    type: 'column',
                    data: rejectedSet
                },
                {
                    name: 'Approved',
                    type: 'column',
                    data: completeSet
                },],
                requestStatusSetX1: regionSet,
            }, () => {
                this.setState({
                    requestStatus: true
                })
            })
        }
    }

    requestCategoryBreakdownDataSet(data) {
        if (data != null) {
            this.setState({
                requestBrSetY1: null,
                requestBrSetX1: null,
                requestBrStatus: false,
            })
            let canceledSet = data.map(a => a?.cancel);
            let rejectedSet = data.map(a => a?.reject);
            let pendingSet = data.map(a => a?.pending);
            let completeSet = data.map(a => a?.complete);
            let regionSet = data.map(a => a?.name);

            this.setState({
                requestBrSetY1: [{
                    name: 'Cancelled',
                    type: 'column',
                    data: canceledSet
                },
                {
                    name: 'Pending Approval',
                    type: 'column',
                    data: pendingSet
                },
                {
                    name: 'Rejected',
                    type: 'column',
                    data: rejectedSet
                },
                {
                    name: 'Approved',
                    type: 'column',
                    data: completeSet
                },],
                requestBrSetX1: regionSet,
            }, () => {
                this.setState({
                    requestBrStatus: true
                })
            })
        }
    }

    pieChartDataSet = async (data) => {
        this.setState({
            pieChartDisplay: false,
            pieChartDataX1: [],
            pieChartDataY1: [],
        })
        if (data !== null) {
            let result = data.map(item => item.count);
            let labels = data.map(item => item.type);

            this.setState({
                pieChartDataX1: result,
                pieChartDataY1: labels
            }, () => { this.setState({ pieChartDisplay: true }) })
        }
    };

    getLastDayOfMonth(inputDate) {
        const [year, month] = inputDate.split('-');
        const nextMonth = new Date(year, month);
        nextMonth.setDate(0);
        const lastDay = nextMonth.getDate();
        return `${year}-${month}-${lastDay.toString().padStart(2, '0')}`;
    }

    onChangeRegion = (value) => {
        this.setState({
            depot_list: [],
            filter_depot: [],
            filter_sub_region: [],
        });
        console.log(value);
        this.setState({
            filter_region: value,
            selectedDepot: "all_depots",
            selected_region_id: value,
            SubRegion_list: this.props.regions.filter(alldate => value.map((n) => n).includes(alldate.parentRegion?.id)),
            depot_list: this.props.depots.filter(alldate => value.map((n) => n).includes(alldate.region?.parentRegion?.id))
        }, () => {
            if (this.state.filter_sub_region == "") {
                var getall = this.props.regions.filter(alldate => value.includes(alldate.parentRegion?.id))
                var output = [];
                for (var i = 0; i < getall.length; ++i)
                    output.push(getall[i]['id']);
                var data1 = new FormData();

                data1.append('year', this.state.filter_date ? moment(this.state.filter_date).year() : moment().year());
                data1.append('month', this.state.filter_date_to ? moment(this.state.filter_date_to).month() + 1 : moment().endOf('month').month() + 1);
                data1.append('regions', this.state.filter_region);

                this.fetchAccessBasicDetails(data1);
            }
        });
        var data = {
            id: value,
            type: "region",
        };
    };

    dataonChange = (date, dateString) => {
        const [year, month] = dateString.split('-');
        const startDate = moment([year, month - 1]).format('YYYY-MM-DD');
        const endDate = moment(startDate).endOf('month').format('YYYY-MM-DD');

        this.setState({
            filter_date: startDate,
            filter_date_to: endDate,
        });
        var data1 = new FormData();

        data1.append('year', startDate ? moment(startDate).year() : moment().year());
        data1.append('month', endDate ? moment(endDate).month() + 1 : moment().endOf('month').month() + 1);
        data1.append('regions', this.state.filter_region);

        this.fetchAccessBasicDetails(data1);
    };

    stripYearFromDates(dates) {
        return dates.map(date => date.slice(8));
    }

    renderChart = () => (
        <div className="card-body">
            {this.state.requesteTrendChart ? (
                <LineCharts
                    redraw={true}
                    key={this.state.requesteTrendChartSetX1} // Ensure a unique key
                    y1={this.state.requesteTrendChartSetY1}
                    x1={this.stripYearFromDates(this.state.requesteTrendChartSetX1)}
                    yLabale={"Total"}
                />
            ) : ('')}
        </div>
    );

    handleButtonClick = () => {
        // Set state to trigger rendering of the chart
        this.setState({ requesteTrendChart: true });
    };

    render() {
        const { Option } = Select;

        function onBlur() {
            //console.log("blur");
        }

        function onFocus() {
            //console.log("focus");
        }

        function onSearch(val) {
            //console.log("search:", val);
        }
        const { vehicleSeriesCount } = this.state
        const { vehicleSerieslabels } = this.state

        return (
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="page-title-box">
                            <div class="row">
                                <div class="col">
                                    <h4 class="page-title">Dashboard</h4>
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Site Access</a></li>
                                    </ol>
                                </div>
                                <div class="col-auto align-self-center">
                                    <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div className="row" style={{
                            background: 'white', padding: '10px', borderRadius: '10px'
                        }}>
                            <div className="col-md-2 col-lg-3">
                                <DatePicker
                                    picker="month"
                                    className='customDropdown textStyles-small'
                                    style={{ width: "100%", borderRadius: "5px" }}
                                    onChange={this.dataonChange}
                                    allowClear={false}
                                    defaultValue={moment(moment().format("YYYY-MM-DD").slice(0, -2) + '01')}
                                    size='medium'
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>

                <Spin spinning={this.state.isLoading} tip="Loading...">
                    <div style={{ background: 'white', borderRadius: '10px', padding: '20px' }}>
                        <Row>
                            <Col span={8}>
                                <h5 style={{
                                    fontWeight: "bold", fontFamily: 'Roboto'
                                }}>Dashboard Statistics </h5>
                            </Col>
                            <Col span={16}>
                                <div style={{ float: 'right' }}>

                                    <Select
                                        mode="multiple"
                                        showSearch
                                        className="borderedSelect"
                                        bordered={false}
                                        style={{ width: "250px", borderRadius: "5px", marginLeft: '5px' }}
                                        placeholder="Select a region"
                                        optionFilterProp="children"
                                        onChange={this.onChangeRegion}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                        onSearch={onSearch}
                                        filterOption={(input, option) =>
                                            option.props.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.props.regions.filter(allret => "0".includes(allret.isSubRegion)).map((item, index) => {
                                            return <Option value={item.id}>{item.name}</Option>;
                                        })}
                                    </Select>
                                </div>

                            </Col>
                        </Row>


                        <div class="row justify-content-center" style={{ marginTop: '20px' }}>
                            <div class="col-md-2 col-lg-3" style={{ width: "25%" }}>
                                <div class="card report-card bg-soft-success" style={{ borderRadius: '20px' }}>
                                    <div class="card-body" >
                                        <div class="row d-flex justify-content-center">
                                            <div class="col">
                                                <p class="mb-0 fw-semibold textStyles-smallx"><b>Rejected<br />Requests</b></p>
                                                <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.basicAccessInformations?.REJECTED ? this.state.basicAccessInformations?.REJECTED : 0}</h2>
                                            </div>
                                            <div class="col-auto align-self-center">
                                                <div style={{
                                                    background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                                                    alignItems: "center", justifyContent: "center", margin: "auto"
                                                }} float="left" className="mt-1">
                                                    <NodeIndexOutlined style={{ color: "#99db7f", fontSize: '20px' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-3" style={{ width: "25%" }}>
                                <div class="card report-card bg-soft-pink" style={{ borderRadius: '20px' }}>
                                    <div class="card-body" >
                                        <div class="row d-flex justify-content-center">
                                            <div class="col">
                                                <p class="mb-0 fw-semibold textStyles-smallx"><b>Pending <br />Approval</b></p>
                                                <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.basicAccessInformations?.PENDING_APPROVAL ? (this.state.basicAccessInformations?.PENDING_APPROVAL) : (0)}</h2>
                                            </div>
                                            <div class="col-auto align-self-center">
                                                <div style={{
                                                    background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                                                    alignItems: "center", justifyContent: "center", margin: "auto"
                                                }} float="left" className="mt-1">
                                                    <FieldTimeOutlined style={{ color: "#fd3ca3", fontSize: '20px' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-3" style={{ width: "25%" }}>
                                <div class="card report-card bg-soft-warning" style={{ borderRadius: '20px' }}>
                                    <div class="card-body" >
                                        <div class="row d-flex justify-content-center">
                                            <div class="col">
                                                <p class="mb-0 fw-semibold textStyles-smallx"><b>Approved<br /> Requests</b></p>
                                                <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.basicAccessInformations?.APPROVED ? (this.state.basicAccessInformations?.APPROVED) : (0)}</h2>
                                            </div>
                                            <div class="col-auto align-self-center">
                                                <div style={{
                                                    background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                                                    alignItems: "center", justifyContent: "center", margin: "auto"
                                                }} float="left" className="mt-1">
                                                    <CarOutlined style={{ color: "#ffda7e", fontSize: '20px' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-3" style={{ width: "25%" }}>
                                <div class="card report-card bg-soft-info" style={{ borderRadius: '20px' }}>
                                    <div class="card-body" >
                                        <div class="row d-flex justify-content-center">
                                            <div class="col">
                                                <p class="mb-0 fw-semibold textStyles-smallx" style={{
                                                }}><b>Cancelled<br />Requests</b></p>
                                                <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.basicAccessInformations?.CANCELLED ? (this.state.basicAccessInformations?.CANCELLED) : (0)}</h2>
                                            </div>
                                            <div class="col-auto align-self-center">
                                                <div style={{
                                                    background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                                                    alignItems: "center", justifyContent: "center", margin: "auto"
                                                }} float="left" className="mt-1">
                                                    <UserOutlined style={{ color: "#2d81fe", fontSize: '20px' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className='col-md-12'>

                        <div className="card">
                            <div className="card-header">
                                <div className='row'>
                                    <div className="col-md-3">
                                        <h4 className="card-title textStyle-charts-header" >Requests Status Monthly</h4>
                                    </div>
                                    <div className="container" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <div className="col">
                                            <span>
                                                <Badge color="#2D5FF4" text="Actual" style={{ marginLeft: "10px", fontFamily: 'Roboto', fontSize: '9px', fontWeight: 'bold' }} /></span>
                                            <span style={{ marginLeft: "10px", fontFamily: 'Roboto', fontSize: '9px', fontWeight: 'bold' }}><Badge color="#42B02A" text="Target" /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body">
                                <div className='row'>
                                    <div class="col-md-6 col-lg-3" style={{ textAlign: "center", width: "20%" }} >
                                        {this.state.vehicleRegionViceMillageWRChart ? (
                                            <SemiCircleGaugeChat
                                                setvalues={((this.state.vehicleRegionViceMillageWRChartTarget / this.state.vehicleRegionViceMillageWRChartRealValue) * 100).toFixed(2) ? ((this.state.vehicleRegionViceMillageWRChartTarget / this.state.vehicleRegionViceMillageWRChartRealValue) * 100).toFixed(2) : 0}
                                                setTitel="Western"
                                                targetValue={this.state.vehicleRegionViceMillageWRChartRealValue}
                                                realValue={this.state.vehicleRegionViceMillageWRChartTarget}
                                            />
                                        ) : (
                                            console.log(((this.state.vehicleRegionViceMillageCRChartRealValue / this.state.vehicleRegionViceMillageCRChartTarget) * 100).toFixed(2))
                                        )}
                                    </div>

                                    <div class="col-md-6 col-lg-3" style={{ textAlign: "center", width: "20%" }} >
                                        {this.state.vehicleRegionViceMillageSRChart ? (
                                            <SemiCircleGaugeChat
                                                setcolor="#2D5FF4"
                                                setvalues={((this.state.vehicleRegionViceMillageSRChartTarget / this.state.vehicleRegionViceMillageSRChartRealValue) * 100).toFixed(2)}
                                                setTitel="Southern"
                                                targetValue={this.state.vehicleRegionViceMillageSRChartRealValue}
                                                realValue={this.state.vehicleRegionViceMillageSRChartTarget}
                                            />
                                        ) : (
                                            console.log(((this.state.vehicleRegionViceMillageCRChartRealValue / this.state.vehicleRegionViceMillageCRChartTarget) * 100).toFixed(2))
                                        )}
                                    </div>

                                    <div class="col-md-6 col-lg-3" style={{ textAlign: "center", width: "20%" }} >
                                        {this.state.vehicleRegionViceMillageCRChart ? (
                                            <SemiCircleGaugeChat
                                                setcolor="#2D5FF4"
                                                setvalues={((this.state.vehicleRegionViceMillageCRChartTarget / this.state.vehicleRegionViceMillageCRChartRealValue) * 100).toFixed(2)}
                                                setTitel="Central"
                                                targetValue={this.state.vehicleRegionViceMillageCRChartRealValue}
                                                realValue={this.state.vehicleRegionViceMillageCRChartTarget}
                                            />
                                        ) : (
                                            console.log(((this.state.vehicleRegionViceMillageCRChartRealValue / this.state.vehicleRegionViceMillageCRChartTarget) * 100).toFixed(2))
                                        )}
                                    </div>

                                    <div class="col-md-6 col-lg-3" style={{ textAlign: "center", width: "20%" }} >
                                        {this.state.vehicleRegionViceMillageNRChart ? (
                                            <SemiCircleGaugeChat
                                                setcolor="#2D5FF4"
                                                setvalues={((this.state.vehicleRegionViceMillageNRChartTarget / this.state.vehicleRegionViceMillageNRChartRealValue) * 100).toFixed(2)}
                                                setTitel="Northern"
                                                targetValue={this.state.vehicleRegionViceMillageNRChartRealValue}
                                                realValue={this.state.vehicleRegionViceMillageNRChartTarget}
                                            />
                                        ) : (
                                            console.log(((this.state.vehicleRegionViceMillageCRChartRealValue / this.state.vehicleRegionViceMillageCRChartTarget) * 100).toFixed(2))
                                        )}
                                    </div>

                                    <div class="col-md-6 col-lg-3" style={{ textAlign: "center", width: "20%" }} >
                                        {this.state.vehicleRegionViceMillageERChart ? (
                                            <SemiCircleGaugeChat
                                                setcolor="#2D5FF4"
                                                setvalues={((this.state.vehicleRegionViceMillageERChartTarget / this.state.vehicleRegionViceMillageERChartRealValue) * 100).toFixed(2)}
                                                setTitel="Eastern"
                                                targetValue={this.state.vehicleRegionViceMillageERChartRealValue}
                                                realValue={this.state.vehicleRegionViceMillageERChartTarget}
                                            />
                                        ) : (
                                            console.log(((this.state.vehicleRegionViceMillageCRChartRealValue / this.state.vehicleRegionViceMillageCRChartTarget) * 100).toFixed(2))
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <h4 className="card-title textStyle-charts-header">Request Trend</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {this.state.requesteTrendChart ? (
                                        <LineCharts y1={this.state.requesteTrendChartSetY1}
                                            x1={this.stripYearFromDates(this.state.requesteTrendChartSetX1)}
                                            yLabale={"Total"} />) : (<Empty />)}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <h4 className="card-title" style={{
                                                fontWeight: "bold", fontFamily: 'Roboto'
                                            }}>MOM Completion</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {this.state.requestStatus ? (
                                        <StackedBarChat
                                            y1={this.state.requestStatusSetY1}
                                            x1={this.state.requestStatusSetX1}
                                            yLabale={'Total'}
                                            barColors={['#FF3A29', '#42B02A', '#FFB200', '#4C77F6']}
                                        />) : (<Empty />)}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <h4 className="card-title" style={{
                                                fontWeight: "bold", fontFamily: 'Roboto'
                                            }}>Request Category Breakdown</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {this.state.requestBrStatus ? (
                                        <StackedBarWithoutTick
                                            y1={this.state.requestBrSetY1}
                                            x1={this.state.requestBrSetX1}
                                            yLabale={'Request Category'}
                                            barColors={['#FF3A29', '#42B02A', '#FFB200', '#4C77F6']}
                                        />) : (<Empty />)}
                                </div>
                            </div>
                        </div>

                        <div className='col-md-6' style={{ marginTop: '5px', background: 'white', padding: '5px', borderRadius: '5px', marginLeft: '10px' }}>
                            {this.state.pieChartDisplay ? (
                                <SimpleDonutChart
                                    chartLabale={'Single Vs Bulk'}
                                    colorArray={['#FFB200', '#42B02A', '#2D5FF4', '#E34234', '#EB0029', '#CBC300']}
                                    y1={this.state.pieChartDataX1}
                                    x1={this.state.pieChartDataY1} />) : (<Empty />)}
                        </div>

                    </div>
                </Spin>

            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        organization_count: state.organization.length,
        regions: state.regions,
        depots: state.depots,
        sites_count: state.sites.length,
        vendor_count: state.vendor.length,
        roles_count: state.roles.length,
    };
}

export default connect(mapStateToProps, { getDonationCategories, getData })(
    AccessDash
);


