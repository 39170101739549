import React, { Component, Fragment } from 'react'

import { Table, Input, InputNumber, Tag, Space, Button, Row, Col, Drawer, Select, Spin, Switch, message } from "antd";
import { updateGenHutDetails } from "../../api/index";
import checkPermissions from '../../utils/permissionManager';
import { getGenHutMasterData } from "../../api/index";

class GeneratorHut extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            tabaleLoading: false,
            switchStatus: true,
            setAvailableGeneratorHut1Type: null,
            setAvailableGeneratorHut2Type: null,
            objectSet: {
                "SpaceAvailableNewGen": false,
                "NumberofGeneratorHut": null,
                "availableGeneratorHut1Type": null,
                "availableGeneratorHut2Type": null
            },
            dropdownObjGenHut: {
                "SpaceAvailableNewGen": "",
                "NumberofGeneratorHut": "",
                "availableGeneratorHut1Type": "",
                "availableGeneratorHut2Type": ""
            }
        };
        this.handlNofgenGut = this.handlNofgenGut.bind(this);
        this.onChangehenHut1type = this.onChangehenHut1type.bind(this);
        this.onChangehenHut2type = this.onChangehenHut2type.bind(this);
        this.onChangehenIsAvabel = this.onChangehenIsAvabel.bind(this);

    }
    onChangehenIsAvabel(value) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                SpaceAvailableNewGen: value
            }
        })
    }
    onChangehenHut2type(value) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                availableGeneratorHut2Type: { "id": value }
            }
        })
    }
    onChangehenHut1type(value) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                availableGeneratorHut1Type: { "id": value }
            }
        })
    }

    handlNofgenGut(e) {
        console.log(e)


        this.setState({
            objectSet: {
                ...this.state.objectSet,
                NumberofGeneratorHut: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }

    componentDidMount() {
        console.log('7985255')
        console.log(this.props.genHutMastetData.typeSet)

        this.setState({

        })

        this.setState({
            objectSet: { "SpaceAvailableNewGen": this.props.details3?.allData?.isSpaceAvailableNewGen, "NumberofGeneratorHut": this.props.details3?.allData?.numberofGeneratorHut, "availableGeneratorHut1Type": this.props.details3?.allData?.availableGeneratorHut1Type != null ? ({ 'id': this.props.details3?.allData?.availableGeneratorHut1Type?.id }) : (null), "availableGeneratorHut2Type": this.props.details3?.allData?.availableGeneratorHut2Type != null ? ({ 'id': this.props.details3?.allData?.availableGeneratorHut2Type?.id }) : (null) }
        });
        // console.log(this.props.details2)
    }
    dataUpdate() {
        console.log("ssss")
        this.setState({
            switchStatus: false
        })
    }

    close = async () => {
        this.props.parentMethod();
        this.setState({
            objectSet: { "SpaceAvailableNewGen": this.props.details3?.allData?.isSpaceAvailableNewGen, "NumberofGeneratorHut": this.props.details3?.allData?.numberofGeneratorHut, "availableGeneratorHut1Type": this.props.details3?.allData?.availableGeneratorHut1Type != null ? ({ 'id': this.props.details3?.allData?.availableGeneratorHut1Type?.id }) : (null), "availableGeneratorHut2Type": this.props.details3?.allData?.availableGeneratorHut2Type != null ? ({ 'id': this.props.details3?.allData?.availableGeneratorHut2Type?.id }) : (null) }
        });
        this.setState({
            switchStatus: true
        });
    }

    dataSetToUpdate = async () => {
        console.log(this.props.detailsSite)
        let formData = new FormData(); //formdata object
        let setobjectToUpate = { "isSpaceAvailableNewGen": this.state.objectSet.SpaceAvailableNewGen, "numberofGeneratorHut": this.state.objectSet.NumberofGeneratorHut, "availableGeneratorHut1Type": this.state.objectSet.availableGeneratorHut1Type, "availableGeneratorHut2Type": this.state.objectSet.availableGeneratorHut2Type }
        console.log(setobjectToUpate)
        formData.append("object", JSON.stringify(setobjectToUpate)); //append the values with key, value pair
        formData.append("id", this.props.detailsSite.site_details.id);
        var res = await updateGenHutDetails(formData);
        if (res.success) {
            message.success('Successfully Update!')
            this.props.parentMethod();
            this.setState({
                switchStatus: true
            })
        } else {
            message.warning(' Something went wrong!')
        }
    }

    render() {
        const { Option } = Select;
        const { details } = this.props.details

        return (
            <Fragment>
                {this.props.details.access_technologies_data != null ? (
                    <div style={{ width: "100%", backgroundColor: "white", padding: "20px", borderRadius: "5px" }}>
                        <div className="d-flex justify-content-end align-items-center" style={{ marginBottom: "10px", marginRight: "10px" }}>
                            {this.state.switchStatus ? (
                                checkPermissions('SITE_VIEW_GENERAL_USER') || checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                                    <button
                                        type="button"
                                        className="btn btn-soft-primary btn-icon-square-sm"
                                        onClick={() => this.dataUpdate()}
                                        style={{ borderRadius: 5, backgroundColor: '#F28502', borderColor: '#F28502' }}
                                    >
                                        <i className="mdi mdi-pencil-outline" style={{ color: 'white' }}></i>
                                    </button>
                                ) : null
                            ) : (
                                <div className="d-flex justify-content-end align-items-center">
                                    <button
                                        type="button"
                                        className="btn btn-soft-secondary"
                                        onClick={() => this.close()}
                                        style={{ marginRight: "10px" }}
                                    >
                                        <span className="textStyles-small">Cancel</span>
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-soft-primary"
                                        onClick={() => this.dataSetToUpdate()}
                                    >
                                        <span className="textStyles-small">Submit to Update</span>
                                    </button>
                                </div>
                            )}
                        </div>
                        <ul class="list-group">
                            <Row>
                                <Col span={12}>
                                    <div style={{ width: '98%' }}>
                                        <li class="list-group-item d-flex justify-content-between align-items-center">
                                            <div>
                                                <span className='textStyles-small'>Number of Generator Hut</span>
                                            </div>
                                            <div>
                                                {this.state.switchStatus.toString() == "true" ? (
                                                    this.props.details3?.allData?.numberofGeneratorHut ? (
                                                        <span class="badge badge-outline-success textStyles-small">{this.props.details3?.allData?.numberofGeneratorHut}</span>
                                                    ) : (
                                                        <span class="badge badge-outline-danger textStyles-small">Currently Not Set</span>
                                                    )
                                                ) : (
                                                    <Select
                                                        className='borderedSelect'
                                                        bordered={false}
                                                        defaultValue={this.props.details3?.allData?.numberofGeneratorHut}
                                                        style={{ width: 140 }}
                                                        onChange={(e) => {
                                                            this.state.objectSet.NumberofGeneratorHut = e;
                                                        }}>
                                                        {this.props.dropdownGenhut["dropdownObjGenHut"]["NumberofGeneratorHut"].map((x) => {
                                                            return <Option value={x}>{x}</Option>
                                                        })}
                                                    </Select>
                                                )}
                                            </div>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f8f9fa' }}>
                                            <div>
                                                <span className='textStyles-small'>Available Generator Hut 1 type</span>
                                            </div>
                                            {this.state.switchStatus
                                                ? (<> {this.props.details3?.allData?.availableGeneratorHut1Type != null ?
                                                    (<span class="badge badge-outline-success textStyles-small">{this.props.details3?.allData?.availableGeneratorHut1Type?.name}</span>) :
                                                    (<span class="badge badge-outline-danger textStyles-small">Currently Not Set</span>)}</>)
                                                : (<Select
                                                    className='borderedSelect'
                                                    bordered={false}
                                                    showSearch
                                                    onChange={this.onChangehenHut1type}
                                                    defaultValue={this.props.details3?.allData?.availableGeneratorHut1Type?.id}
                                                    style={{ width: 140 }}
                                                    placeholder="Search to Select"
                                                    optionFilterProp="children"
                                                >
                                                    {this.props.genHutMastetData.typeSet?.map((item, index) => {
                                                        return <Option value={item?.id}>{item.name}</Option>;
                                                    })}
                                                </Select>)}
                                        </li>
                                    </div>
                                </Col>

                                <Col span={12}>
                                    <div style={{ width: '98%' }}>
                                        <li class=
                                            "list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f8f9fa' }}>
                                            <div>
                                                <span className='textStyles-small'>Available Generator Hut 2 type</span>
                                            </div>
                                            {this.state.switchStatus
                                                ? (<> {this.props.details3?.allData?.availableGeneratorHut2Type != null ? (
                                                    <span class="badge badge-outline-success textStyles-small">{this.props.details3?.allData?.availableGeneratorHut2Type?.name}</span>) :
                                                    (<span class="badge badge-outline-danger textStyles-small">Currently Not Set</span>)}</>)
                                                : (<Select
                                                    showSearch
                                                    className='borderedSelect'
                                                    bordered={false}
                                                    onChange={this.onChangehenHut2type}
                                                    defaultValue={this.props.details3?.allData?.availableGeneratorHut2Type?.id}
                                                    style={{ width: 140 }}
                                                    placeholder="Search to Select"
                                                    optionFilterProp="children"
                                                >
                                                    {this.props.genHutMastetData.typeSet?.map((item, index) => {
                                                        return <Option value={item?.id}>{item.name}</Option>;
                                                    })}
                                                </Select>)}
                                        </li>

                                        <li class="list-group-item d-flex justify-content-between align-items-center">
                                            <div>
                                                <span className='textStyles-small'>Generator space availability for a new generator</span>
                                            </div>
                                            {this.state.switchStatus
                                                ? (<> {this.props.details3?.allData?.isSpaceAvailableNewGen != null ? (
                                                    <span class="badge badge-outline-success textStyles-small">{this.props.details3?.allData?.isSpaceAvailableNewGen ? ('Yes') : ('No')}</span>) :
                                                    (<span class="badge badge-outline-danger textStyles-small">Currently Not Set</span>)}</>)
                                                : (<Select
                                                    showSearch
                                                    className='borderedSelect'
                                                    bordered={false}
                                                    onChange={this.onChangehenIsAvabel}
                                                    defaultValue={this.props.details3?.allData?.isSpaceAvailableNewGen}
                                                    style={{ width: 140 }}
                                                    placeholder="Search to Select"
                                                    optionFilterProp="children"
                                                >
                                                    <Option value={1}>Yes</Option>
                                                    <Option value={0}>No</Option>
                                                </Select>)}
                                        </li>
                                    </div>
                                </Col>
                            </Row>
                        </ul>
                    </div>
                ) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h4 style={{ color: "#c5c5c5" }}>No Dat Found Access Technologies</h4></div>)}
            </Fragment>
        )
    }
}
export default GeneratorHut;
