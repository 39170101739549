import React from "react";
import { CardContainer, FloatingContainer } from "../containers";
import { FiLogIn } from 'react-icons/fi';
import { Flex, Box } from "rebass"

import "./style.css"

class LoginModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            _password: ""
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange({target}){
        this.setState({
            _password: target.value
        })
    }

    render(){
        return(
            <React.Fragment>
            {this.state._password !== this.props.password && <FloatingContainer style={{background: "#ffffff"}}>
                <CardContainer className="modal-login-form">
                    <Flex p="10px" pt="5px" pb="2px" style={{fontWeight: 500, fontSize: 30}}>
                        <Box width={1}>LOG IN</Box>
                        <Box><FiLogIn/></Box>
                    </Flex>
                    <Flex p="5px" pl="10px" style={{fontSize: "13px"}}><i>Enter the passphrase</i></Flex>
                    <input
                        className="modal-login-form-input"
                        id="new-todo"
                        type="password"
                        onChange={this.handleChange}
                        value={this.state._password}
                    />
                </CardContainer>
            </FloatingContainer>}
            </React.Fragment>
        )
    }
} 

export { LoginModal }