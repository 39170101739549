import React, { Fragment, useState, useEffect } from "react";
import { Form, Select, Button, Row, Col, message, DatePicker } from 'antd';
import {
  getAllSitesListByID,
  getAllSubRegionsDepotCreate,
  getAllDepots,
  getAllRequestCategoryREWAMP,
  getAllSubRequestTypeREWAMP,
  bulkSiteAccessRequestDTOsREWAMPForRewamp
} from "../../../../api/index";

const { Option } = Select;
const { RangePicker } = DatePicker;

const FilterForm = ({ returnData, modalCloseFunction, jobType }) => {
  const [form] = Form.useForm();
  const [requestCategory, setRequestCategory] = useState([]);
  const [subRequestType, setSubRequestType] = useState([]);
  const [subRegions, setSubRegions] = useState([]);
  const [depots, setDepots] = useState([]);
  const [loadFullSiteList, setLoadFullSiteList] = useState([]);
  const [allBulkRequests, setAllBulkRequests] = useState([]);

  const [accessRequestType, setAccessRequestType] = useState([]);
  const [dateType, setDateType] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          fetchAllSiteListSet(),
          fetchAllSubRegionsDepotCreate(),
          fetchAllDepots(),
          fetchAllRequestCategoryREWAMP(),
          fetchAllSubRequestTypeREWAMP(),
          fetchAllBulkRequestsListViwer()
        ]);
      } catch (error) {
        message.error('Failed to load filter options');
      }
    };
    fetchData();
  }, []);

  const fetchAllBulkRequestsListViwer = async () => {
    const response = await bulkSiteAccessRequestDTOsREWAMPForRewamp();
    if (response.success) {
      setAllBulkRequests(response?.data?.results);
    } else {
      return;
    }
  }

  const fetchAllSiteListSet = async () => {
    const response = await getAllSitesListByID();
    if (response.success) {
      setLoadFullSiteList(response.data);
    } else {
      message.error('Error fetching sites: ' + response?.message);
    }
  };

  const fetchAllSubRegionsDepotCreate = async () => {
    const response = await getAllSubRegionsDepotCreate();
    if (response.success) {
      setSubRegions(response.data);
    } else {
      message.error('Error fetching sub-regions: ' + response?.message);
    }
  };

  const fetchAllDepots = async () => {
    const response = await getAllDepots();
    if (response.success) {
      setDepots(response.data);
    } else {
      message.error('Error fetching depots: ' + response?.message);
    }
  };

  const fetchAllRequestCategoryREWAMP = async () => {
    const response = await getAllRequestCategoryREWAMP();
    if (response.success) {
      setRequestCategory(response.data?.results || []);
    } else {
      message.error('Error fetching request categories: ' + response?.message);
    }
  };

  const fetchAllSubRequestTypeREWAMP = async () => {
    const response = await getAllSubRequestTypeREWAMP();
    if (response.success) {
      setSubRequestType(response.data?.results || []);
    } else {
      message.error('Error fetching sub-request types: ' + response?.message);
    }
  };


  const onFinish = (values) => {
    console.log('Received values of form: ', values);
    const formattedValues = {
      ...values,
      dateRange: values.dateRange ? [
        values.dateRange[0]?.format('YYYY-MM-DD'),
        values.dateRange[1]?.format('YYYY-MM-DD'),
      ] : undefined,
    };
    returnData(formattedValues);
    modalCloseFunction();
    // form.resetFields();
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Fragment>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{}}
      >
        <Row gutter={16}>
          <Col span={24}>
            {/* <Form.Item name="siteId" label={<span className="textStyles-small" style={{ fontWeight: 500 }}>Site Name</span>} >
              <Select
                bordered={false}
                className='borderedSelect'
                style={{ width: '100%' }}
                placeholder="Search By Site Name or ID"
                allowClear
                showSearch
                mode='multiple'
                filterOption={(input, option) => {
                  const [siteName, siteId] = option.children.split(" - ");
                  return (
                    siteName.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    siteId.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                {
                  loadFullSiteList?.map((item, index) => (
                    <Select.Option key={index} value={item?.id}>
                      <span className="textStyles-small">
                        {`${item?.siteName} - ${item?.siteId}`}
                      </span>
                    </Select.Option>
                  ))
                }
              </Select>
            </Form.Item> */}

            <Form.Item label={<span className="textStyles-small" style={{ fontWeight: 500 }}>Bulk ID</span>} name="bulkIDs"
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Select Bulk ID"
                mode="multiple"
                bordered={false}
                className='borderedSelect'
                filterOption={(inputValue, option) =>
                  option.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())
                }
              >
                {
                  allBulkRequests?.map((item, index) => (
                    <Option key={item.id} value={item.id} className='textStyle-small'>
                      <span className="textStyles-small">
                        {item.code}
                      </span>
                    </Option>
                  ))
                }
              </Select>
            </Form.Item>

            <Form.Item
              label={<span className="textStyles-small" style={{ fontWeight: 500 }}>Step</span>}
              name="step"
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Select Step"
                mode="multiple"
                bordered={false}
                className='borderedSelect'
              >
                {
                  jobType === 'RUNNING' ? (<>
                    <Option value="PENDING_APPROVAL" className='textStyle-small'>Pending Approval</Option>
                    <Option value="PENDING_WITH_SITE_OWNER" className='textStyle-small'>Pending With Site Owner</Option>
                  </>
                  ) : (
                    <>
                      <Option value="APPROVED" className='textStyle-small'>Approved</Option>
                      <Option value="CANCELLED" className='textStyle-small'>Cancelled</Option>
                      <Option value="REJECTED" className='textStyle-small'>Rejected</Option>
                    </>
                  )
                }
              </Select>
            </Form.Item>

            <Form.Item name="type"
              label={<span className="textStyles-small" style={{ fontWeight: 500 }}>Request Type</span>}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Select Type"
                mode="multiple"
                bordered={false}
                className='borderedSelect'
                onChange={(e) => setAccessRequestType(e)}
              >
                <Option value="PROJECT" className='textStyle-small'>Project</Option>
                <Option value="OPERATION" className='textStyle-small'>Operation</Option>
              </Select>
            </Form.Item>

            {
              accessRequestType.length > 0 ? (
                <Form.Item
                  label={<span className="textStyles-small" style={{ fontWeight: 500 }}>Sub Request Type</span>}
                  name="subType"
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Select Sub Request Type"
                    mode="multiple"
                    bordered={false}
                    className='borderedSelect'
                    name="subType"
                  >
                    {
                      subRequestType?.map((item, index) => {
                        return (
                          <Option value={item?.id} className='textStyle-small'>
                            {item?.name}
                          </Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>
              ) : null
            }

            <Form.Item
              label={<span className="textStyles-small" style={{ fontWeight: 500 }}>Request Category</span>}
              name="category"

            >
              <Select
                style={{ width: '100%' }}
                placeholder="Select Request Category"
                mode="multiple"
                bordered={false}
                showSearch
                className='borderedSelect'
              >
                {
                  requestCategory?.map((item, index) => {
                    return (
                      <Option value={item?.id} className='textStyle-small'>
                        {item?.name}
                      </Option>
                    )
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item
              name="date"
              label={<span className="textStyles-small" style={{ fontWeight: 500 }}>Date</span>}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Select Time Frame"
                bordered={false}
                className='borderedSelect'
                allowClear
                onChange={(e) => setDateType(e)}
              >
                <Option value="TODAY" className='textStyle-small'>Today</Option>
                <Option value="YESTERDAY" className='textStyle-small'>Yesterday</Option>
                <Option value="LAST_7_DAYS" className='textStyle-small'>Last 7 Days</Option>
                <Option value="LAST_30_DAYS" className='textStyle-small'>Last 30 Days</Option>
                <Option value="CUSTOM" className='textStyle-small'>Custom</Option>
              </Select>
            </Form.Item>

            {
              dateType === 'CUSTOM' ? (
                <div style={{ marginTop: '20px' }}>
                  <Form.Item
                    name="customDate"
                    label={<span className="textStyles-small" style={{ fontWeight: 500 }}>Date Range</span>}
                    rules={[{ required: true, message: 'Please select date range' }]}
                  >
                    <DatePicker.RangePicker
                      style={{ width: '100%', height: '33px' }}
                      className='borderedSelect'
                    />
                  </Form.Item>
                </div>
              ) : null
            }
          </Col>
        </Row>
        <Row span={24}>
          <Col span={12}>
            <Button type="primary" htmlType="submit"
              style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '10px', width: '98%', height: '36px', marginLeft: 5 }}>
              <span className="textStyles-small" style={{ fontSize: 13, fontWeight: 500 }}>Filter</span>
            </Button>
          </Col>
          <Col span={12}>
            <Button onClick={onReset} style={{ borderColor: '#F28502', backgroundColor: '#F28502', borderRadius: '10px', width: '98%', height: '36px', marginLeft: 5 }}>
              <span className="textStyles-small" style={{ fontSize: 13, fontWeight: 500, color: 'white' }}>Reset</span>
            </Button>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

export default FilterForm;