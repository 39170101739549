import React, { Component,useState } from 'react';
import { Table, Input, Tag, Space, DatePicker } from "antd";
import ReactExport from "react-data-export";
import ReactLoading from 'react-loading';



import { getRegionWiseDepots } from "../../api/index";
import { getRegionWiseSites } from "../../api/index";
import { getReginoWiseUsers } from "../../api/index";
import { getRegionWiseVendorTeam } from "../../api/index";
import { getOrganizationWiseUsers } from "../../api/index";
import { getPgCountSummaryDetails } from "../../api/index";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



class StatisticData extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
          title: "",
          filterTable: null,
          data_list_region_wise_depots: [],
          data_list_region_wise_sites: [],
          data_list_region_wise_users: [],
          data_list_region_wise_vendor_team: [],
          data_list_organization_wise_users: [],
          data_list_PgCountSummaryDetails: [],
          startDate: "",
          endDate: "",
          export_button: " Please select Start Date and End Date",
          loading:false,
        };
        
    }
    componentDidMount() {
        //console.log(this.props.details);
        
      }
    getDataRegionWiseDepot = async () => {
        var res = await getRegionWiseDepots();
       //console.log(res.data);
        if (res.success) { 
         this.setState({
          data_list_region_wise_depots: res.data,
         });
        }
      };
    getDataRegionWiseSites = async () => {
        var res = await getRegionWiseSites();
       //console.log(res.data);
        if (res.success) {
          this.setState({
            data_list_region_wise_sites: res.data,
          });
        }
      };
    getDataReginoWiseUsers = async () => {
        var res = await getReginoWiseUsers();
     //console.log(res.data);
        if (res.success) {
         this.setState({
          data_list_region_wise_users: res.data,
         });
        }
      };   
      getDatagetRegionWiseVendorTeam = async () => {
        var res = await getRegionWiseVendorTeam();
      //console.log(res.data);
        if (res.success) {
          this.setState({
            data_list_region_wise_vendor_team: res.data,
          });
        }
      };  
      
      getDataOrganizationWiseUsers = async () => {
        var res = await getOrganizationWiseUsers();
      // //console.log(res.data);
        if (res.success) {
          this.setState({
            data_list_organization_wise_users: res.data,
          });
        }
      };
      getDataPgCountSummaryDetails = async (data) => {

        var res = await getPgCountSummaryDetails(data);
        //console.log(res.data);
       // //console.log(res.data.pgCounts);
        //console.log("sdsddd");
        if (res.success) {
          this.setState({
            loading: false,
            export_button:"Export Now",
          });
        
         
          this.setState({
            data_list_PgCountSummaryDetails: res.data,
          });
        }
      };   
      onChangeDate = (date, dateString) => {
       //console.log(date);
        //console.log(dateString);
        this.setState({
          startDate: dateString,
        });
      };
      onChangeDate2 = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);
        this.setState({
          endDate: dateString,
        });
      };
      searchClick = () => {
        
        //console.log("search");
        if(this.state.startDate!="" && this.state.endDate!=""){
          var data = {
            "startDate": this.state.startDate,
            "endDate": this.state.endDate,
          };
         // this.getData(data);
         this.setState({
          loading: true,
        });
        this.getDataRegionWiseDepot();
        this.getDataRegionWiseSites();
        this.getDataReginoWiseUsers();
        this.getDatagetRegionWiseVendorTeam();
        this.getDataOrganizationWiseUsers();
        this.getDataPgCountSummaryDetails(data);

      
        }
        
      };
    
    render() {
      const dataSet1 = this.state.data_list_region_wise_users;
      const dataSet2 = this.state.data_list_region_wise_vendor_team;
      const dataSet3 =[];
      const dataSet4 =this.state.data_list_region_wise_sites;
      const dataSet5 =this.state.data_list_region_wise_depots;
      const dataSet6 =this.state.data_list_PgCountSummaryDetails;
      const dataSet7= dataSet6.userWisePgCount;
      const dataSet8= dataSet6.pgCounts;
      //console.log(dataSet2);
     
      //console.log(dataSet8?.totalPgCount);
     
     
      const StatisticDataSet = [
        {
            columns: [
              { title: "Region" , style: {font:{color: {rgb: "#fffff"},bold:true},fill: {patternType: "solid", fgColor: {rgb: "#1bc780"}}} ,width: {wpx:140}},
              { title: "DNS Users",style: {border:{right:{ style:"thin", color: {rgb: "#1bc780"}}},font:{color: {rgb: "#fffff"},bold:true},fill: {patternType: "solid", fgColor: {rgb: "#1bc780"}}}, width: {wpx: 155}},
             
           

            ],
            data:dataSet1?.map((data)=>[
              {value:data.region ,style: { font: { sz: "12" } }},
              {value:data.user,style: { font: { sz: "12" },border:{right:{ style:"thin", color: {rgb: "#1bc780"}}} }},

            ]),
        },
        {
          xSteps: 3, // Will start putting cell with 1 empty cell on left most
          ySteps: - + dataSet1.length-1, //will put space of 5 rows,
          columns: [
            { title: "Region" , style: {font:{color: {rgb: "#fffff"},bold:true},fill: {patternType: "solid", fgColor: {rgb: "#1bc780"}},width: {wpx: 140}} },
            { title: "Vendor Teams", style: {border:{right:{ style:"thin", color: {rgb: "#1bc780"}}},font:{color: {rgb: "#fffff"},bold:true},fill: {patternType: "solid", fgColor: {rgb: "#1bc780"}}},width: {wpx: 140}},
          ],
          data:dataSet2?.map((data2)=>[
            {value:data2.region ,style: { font: { sz: "12" } }},
            {value:data2.vendorTeam,style: { font: { sz: "12" },border:{right:{ style:"thin", color: {rgb: "#1bc780"}}} }},

          ]),
        },
        {
          xSteps: 6, // Will start putting cell with 1 empty cell on left most
          ySteps: - + dataSet2.length-1, //will put space of 5 rows,
          columns: [
            { title: "Region" , style: { font:{color: {rgb: "#fffff"},bold:true},fill: {patternType: "solid", fgColor: {rgb: "#1bc780"}}},width: {wpx: 140} },
            { title: "Site", style: {border:{right:{ style:"thin", color: {rgb: "#1bc780"}}},font:{color: {rgb: "#fffff"},bold:true},fill: {patternType: "solid", fgColor: {rgb: "#1bc780"}}},width: {wpx: 160}},
           
          ],
          data:dataSet4?.map((data3)=>[
            {value:data3.region,style: {font: { sz: "12" } }},
            {value:data3.site,style: { font: { sz: "12" },border:{right:{ style:"thin", color: {rgb: "#1bc780"}}} }},

          ]),
        },
        {
          xSteps: 9, // Will start putting cell with 1 empty cell on left most
          ySteps: - + dataSet4.length-1, //will put space of 5 rows,
          columns: [
            { title: "Region" , style: { font:{color: {rgb: "#fffff"},bold:true},fill: {patternType: "solid", fgColor: {rgb: "#1bc780"}}},width: {wpx: 140} },
            { title: "Depot", style: {border:{right:{ style:"thin", color: {rgb: "#1bc780"}}},font:{color: {rgb: "#fffff"},bold:true},fill: {patternType: "solid", fgColor: {rgb: "#1bc780"}}},width: {wpx: 160}},
            
          ],
          data:dataSet5?.map((data3)=>[
            {value:data3.region,style: {font: { sz: "12" } }},
            {value:data3.depot,style: { font: { sz: "12" },border:{right:{ style:"thin", color: {rgb: "#1bc780"}}} }},

          ]),
        },
        {
          xSteps: 12, // Will start putting cell with 1 empty cell on left most
          ySteps: - + dataSet5.length-1, //will put space of 5 rows,
          columns: [
            { title: "No. of PG tickets completed" , style: { font:{color: {rgb: "#fffff"},bold:true},fill: {patternType: "solid", fgColor: {rgb: "#1bc780"}}},width: {wpx: 140} },

            { title: "No. of  PG penalty tickets", style: {border:{right:{ style:"thin", color: {rgb: "#1bc780"}}},font:{color: {rgb: "#fffff"},bold:true},fill: {patternType: "solid", fgColor: {rgb: "#1bc780"}}},width: {wpx: 160}},
            { title: "No. of PG cancelled tickets",style: { font:{color: {rgb: "#fffff"},bold:true},fill: {patternType: "solid", fgColor: {rgb: "#1bc780"}}} },
            { title: "Total No. of PG tickets", style: {border:{right:{ style:"thin", color: {rgb: "#1bc780"}}},font:{color: {rgb: "#fffff"},bold:true},fill: {patternType: "solid", fgColor: {rgb: "#1bc780"}}},width: {wpx:160}},
         
          ],
          data: [
          [
            {value:dataSet8?.completedPgCount},
            {value:dataSet8?.paneltyPgCount},
            {value:dataSet8?.canceledPgCount},
            {value:dataSet8?.totalPgCount},
          ]
          ],
        },

        {
          xSteps: 12, // Will start putting cell with 1 empty cell on left most
          ySteps: 2, //will put space of 5 rows,
          columns: [
            { title: "Users" , style: { font:{color: {rgb: "#fffff"},bold:true},fill: {patternType: "solid", fgColor: {rgb: "#1bc780"}}},width: {wpx: 140} },
            { title: "pgCount", style: {border:{right:{ style:"thin", color: {rgb: "#1bc780"}}},font:{color: {rgb: "#fffff"},bold:true},fill: {patternType: "solid", fgColor: {rgb: "#1bc780"}}},width: {wpx: 160}},
            { title: ""},
            { title: "", width: {wpx:160}},
            { title: "", style: {border:{right:{ style:"thin", color: {rgb: "#1bc780"}}},font:{color: {rgb: "#fffff"},bold:true},fill: {patternType: "solid", fgColor: {rgb: "#1bc780"}}},width: {wpx:160}},
            { title: "", },
            { title: "", style: {border:{right:{ style:"thin", color: {rgb: "#1bc780"}}},font:{color: {rgb: "#fffff"},bold:true},fill: {patternType: "solid", fgColor: {rgb: "#1bc780"}}},width: {wpx:160}},
            { title: "", style: {border:{right:{ style:"thin", color: {rgb: "#1bc780"}}},font:{color: {rgb: "#fffff"},bold:true},fill: {patternType: "solid", fgColor: {rgb: "#1bc780"}}},width: {wpx:160}},
            { title: "", },
            { title: "", style: {border:{right:{ style:"thin", color: {rgb: "#1bc780"}}},font:{color: {rgb: "#fffff"},bold:true},fill: {patternType: "solid", fgColor: {rgb: "#1bc780"}}},width: {wpx:160}},
            { title: "", style: {border:{right:{ style:"thin", color: {rgb: "#1bc780"}}},font:{color: {rgb: "#fffff"},bold:true},fill: {patternType: "solid", fgColor: {rgb: "#1bc780"}}},width: {wpx:160}},
            { title: "", },
            { title: "", style: {border:{right:{ style:"thin", color: {rgb: "#1bc780"}}},font:{color: {rgb: "#fffff"},bold:true},fill: {patternType: "solid", fgColor: {rgb: "#1bc780"}}},width: {wpx:160}},
            { title: "", style: {border:{right:{ style:"thin", color: {rgb: "#1bc780"}}},font:{color: {rgb: "#fffff"},bold:true},fill: {patternType: "solid", fgColor: {rgb: "#1bc780"}}},width: {wpx:160}},
            { title: "", style: {border:{right:{ style:"thin", color: {rgb: "#1bc780"}}},font:{color: {rgb: "#fffff"},bold:true},fill: {patternType: "solid", fgColor: {rgb: "#1bc780"}}},width: {wpx:160}},
            { title: "", style: {border:{right:{ style:"thin", color: {rgb: "#1bc780"}}},font:{color: {rgb: "#fffff"},bold:true},fill: {patternType: "solid", fgColor: {rgb: "#1bc780"}}},width: {wpx:160}},
            
            

            
          ],
          data:dataSet7?.map((data7)=>[
            {value:data7.user ,style: { font: { sz: "12" } }},
            {value:data7.pgCount,style: { font: { sz: "12" },border:{right:{ style:"thin", color: {rgb: "#1bc780"}}} }},

          ]),
        },
        
        
      ];
      const timestamp = Date.now();
        return (
           <div className="row" >
             <div className="col-md-3 col-lg-3 col-xl-3">
              <DatePicker
                placeholder="Start Date"
                style={{ width: "100%" }}
                onChange={this.onChangeDate}
              />
             </div>
             <div className="col-md-3 col-lg-3 col-xl-3">
              <DatePicker
                placeholder="End Date"
                style={{ width: "100%" }}
                onChange={this.onChangeDate2}
              />
            </div>
            <div className="col-md-6 col-lg-6 col-xl-6">
              <button
                onClick={this.searchClick}
                class="btn btn-primary"
                //style={{ float: "right" }}
              >
                <i class="fas fa-search"> </i> Search
              </button>
             </div>
             <br/><br/>

             <div className="col-lg-12 col-xl-12">
          <div className="card">
            <div className="card-header">
              <div className="row ">
                <div className="col">
                  <h4 className="card-title">
                    Report - Statistic Data{" "}
                    <ExcelFile
                      filename={"Statistic Data list -" + timestamp}
                      element={
                        <button
                          class="btn btn-primary"
                          style={{ float: "right" }}
                        >
                          <i class="fas fa-file-export"> </i>{this.state.export_button}
                        </button>
                      }
                    >
                       <ExcelSheet dataSet={StatisticDataSet} name="Statistic Data"/>
                       <ExcelSheet dataSet={StatisticDataSet} name="Total Count"/>
                       <ExcelSheet dataSet={StatisticDataSet} name="Dashboard"/>
                      {/* <ExcelSheet data={dataSet1} name="Total Count">
                      <ExcelColumn
                          label="request_date"
                          
                        />
                      </ExcelSheet> */}
                      {/* <ExcelSheet data={dataSet1} name="Dashboard">
                      <ExcelColumn
                          label="request_date"
                          
                        />
                      </ExcelSheet> */}
                    
                    </ExcelFile>
                  </h4>
                  <div  className="row">
                  {this.state.loading ? (
        <ReactLoading 
          type={"bars"}
          color={"#03fc4e"}
          height={86}
          width={100}
         
          // style={{color:"#03fc4e",flex: 0.1}} 
        />
      ) : (<div></div>
      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
           </div>
        );
    }
}

export default StatisticData;