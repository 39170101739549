import React, { Component, Fragment } from "react";
import checkPermissions from "../../utils/permissionManager";
import { siteViewNew } from '../../api/api.config';
import { connect } from "react-redux";
import { getSiteListByPage, filterSiteData } from "../../api/Repos/SiteRepo";
import { Link } from "react-router-dom";
import { DownloadOutlined } from '@ant-design/icons';
import { DatePicker, Table, List, Skeleton, Select, Tag, Row, Col, Input, Button, message, Menu, Dropdown, Modal, Card, Collapse, Form, Divider, Badge } from "antd";
import { getDonationCategories, getData } from "../../actions/index";
import {
  getAllSiteCategories,
  getFilterDetails,
  getAllRegionRelatedData,
  getAllSitesListByID
} from "../../api/index";
import '../master-data/style.css';
import axios from 'axios';
import { checkServiceType } from "../../actions/service-type";
const { Option } = Select;
const { Panel } = Collapse;

class SiteNewExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      editVisibilty: false,
      site_list: [],
      site_name: "",
      site_id: "",
      depotList: [],
      depot_list_new: [],
      isOpen: false,
      loading: true,
      depotId: "",
      setLogin: false,
      AssetManagementPermission: false,
      filterButton: true,
      setSearchValue: "",
      filteredTable: null,
      setValues: null,
      depot_list: [],
      sitwCategory: [],
      filterRegion: null,
      filterDepot: null,
      filterCategory: null,
      fullAllSite: [],
      staticalData: [],
      allSiteList: [],
      siteOwnerList: [],
      towerCategoryList: [],
      towerTypeList: [],
      siteTypeList: [],
      allRegions: [],
      allDepots: [],
      openFilter: false,
      loadFullSiteList: [],
      mapQuery: {
        siteIds: [],
        regionIds: [],
        depotIds: [],
        status: null,
        siteOwnerIds: [],
        siteTypeIds: [],
        towerCategoryIds: [],
        towerTypeIds: []
      },

      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        totalPages: 0,
        lastSeenId: 0,
        hasNextPage: true
      },

      isActiveFilter: false,
      inlineSearchID: 0,
      selectedRegionID: [],

      fileFormateName: 'csv',
      isDownloading: false,
    };
  }

  componentDidMount() {
    this.props.getData();
    this.setState({
      depot_list: this.props.depots,
    });
    checkServiceType(38).then((data) =>
      this.setState(
        {
          AssetManagementPermission: data,
        },
        () => {
          console.log(this.state.AssetManagementPermission);
        }
      )
    );
    if (JSON.parse(localStorage.getItem("serviceTypeSet")) == null) {
      this.setState({
        setLogin: true,
      });
    }
    this.fetchSiteListFilter({});
    this.fetchTowerCategoryList();
    this.fetchTowerTypeList();
    this.fetchSiteTypeList();
    this.fetchSiteOwnerList();
    this.fetchRegionList();
    this.fetchDepotList();
    this.fetchAllSiteListSet();
  }


  fetchAllSiteListSet = async () => {
    var responseAPI = await getAllSitesListByID();
    if (responseAPI.success) {
      const customSite = [{
        "id": 0,
        "siteName": "All",
        "siteId": 'Sites',
        "accessPermission": null
      }]
      customSite.push(...responseAPI.data);
      this.setState({
        loadFullSiteList: customSite,
      });
    }
  }

  fetchSiteOwnerList = async () => {
    const response = await getFilterDetails('siteOwner');
    console.log(response);
    if (response.success) {
      this.setState({
        siteOwnerList: response.data,
      });
    }
  };

  fetchTowerCategoryList = async () => {
    const response = await getFilterDetails('towerCategory');
    console.log(response);
    if (response.success) {
      this.setState({
        towerCategoryList: response.data,
      });
    }
  };

  fetchTowerTypeList = async () => {
    const response = await getFilterDetails('towerType');
    console.log(response);
    if (response.success) {
      this.setState({
        towerTypeList: response.data,
      });
    }
  };

  fetchSiteTypeList = async () => {
    const response = await getFilterDetails('siteType');
    console.log(response);
    if (response.success) {
      this.setState({
        siteTypeList: response.data,
      });
    }
  };

  fetchRegionList = async () => {
    const response = await getAllRegionRelatedData('permission-regions');
    if (response.success) {
      this.setState({
        allRegions: response?.data?.results ? response.data.results : [],
      });
    }
  }

  fetchDepotList = async () => {
    const response = await getAllRegionRelatedData('permission-depots');
    if (response.success) {
      this.setState({
        allDepots: response?.data?.results ? response?.data?.results : [],
      });
    }
  }

  getAllSiteCategories = async () => {
    var res = await getAllSiteCategories();
    if (res.success) {
      this.setState({
        sitwCategory: res.data,
      });
    }
  };

  // fetchSiteListFilter = async (siteIdsP = [], regionIdsP = [], depotIdsP = [], statusP = null, siteOwnerIdsP = [], siteTypeIdsP = [], towerCategoryIdsP = [], towerTypeIdP = []) => {
  //   this.setState({
  //     loading: true,
  //     mapQuery: {
  //       siteIds: siteIdsP,
  //       regionIds: regionIdsP,
  //       depotIds: depotIdsP,
  //       status: statusP,
  //       siteOwnerIds: siteOwnerIdsP,
  //       siteTypeIds: siteTypeIdsP,
  //       towerCategoryIds: towerCategoryIdsP,
  //       towerTypeIds: towerTypeIdP
  //     }
  //   });
  //   const pageSize = 100;
  //   let apiCallCount = 0;
  //   let fetchData = [];

  //   let { hasNextPage, lastSeenId } = this.state.pagination;
  //   const requestBody = {
  //     siteIds: siteIdsP,
  //     regionIds: regionIdsP,
  //     depotIds: depotIdsP,
  //     status: statusP,
  //     siteOwnerIds: siteOwnerIdsP,
  //     siteTypeIds: siteTypeIdsP,
  //     towerCategoryIds: towerCategoryIdsP,
  //     towerTypeIds: towerTypeIdP
  //   };

  //   try {
  //     while (hasNextPage && apiCallCount < 2) {
  //       const response = await axios.post(siteViewNew + 'api/v1/site-view',
  //         requestBody,
  //         {
  //           params: {
  //             lastSeenId,
  //             pageSize
  //           },
  //           headers: {
  //             "userId": localStorage.getItem("userId")
  //           }
  //         }
  //       );
  //       if (response.data?.results?.data) {
  //         fetchData = response.data.results.data;
  //       }
  //       lastSeenId = response?.data?.results?.lastSeenId;
  //       hasNextPage = response?.data?.results?.hasNextPage;
  //       apiCallCount++;
  //       if (!hasNextPage) break;
  //     }
  //   } catch (err) {
  //     console.error('Error fetching data:', err);
  //   }
  //   let exisitingData = this.state.allSiteList;
  //   this.setState({
  //     loading: false,
  //     allSiteList: [...exisitingData, ...fetchData],
  //     openFilter: false,
  //     pagination: {
  //       ...this.state.pagination,
  //       lastSeenId,
  //       hasNextPage
  //     }
  //   });
  // };

  fetchSiteListFilter = async (requestBody) => {
    this.setState({ loading: true })
    const response = await axios.post(siteViewNew + 'api/v1/site-view/all',
      requestBody, {
      headers: { "userId": localStorage.getItem("userId") }
    }
    );
    if (response?.data?.results) {
      this.setState({
        allSiteList: response?.data?.results,
        loading: false
      })
    }
  }

  filterBtnClick = (values) => {
    this.setState({
      inlineSearchID: 0,
    });
    this.fetchSiteListFilter(
      {
        siteIds: values.siteIds ? values.siteIds : null,
        regionIds: values.region ? values.region : null,
        depotIds: values.depot ? values.depot : null,
        status: values.status ? values.status : null,
        siteOwnerIds: values.siteOwner ? values.siteOwner : null,
        siteTypeIds: values.siteType ? values.siteType : null,
        towerCategoryIds: values.towerCategory ? values.towerCategory : null,
        towerTypeIds: values.towerType ? values.towerType : null
      }
    )
  }

  exportData = async (formate) => {
    this.setState({
      isDownloading: true
    })
    message.info('File Is Being Downloaded', 3);
    try {
      const response = await axios.post(siteViewNew + 'api/v1/sites/export',
        this.state?.mapQuery,
        {
          params: {
            format: formate,
          },
          headers: {
            "userId": localStorage.getItem("userId")
          }
        }
      );
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `site-list.${formate}`);
        document.body.appendChild(link);
        link.click();
      }
    } catch (err) {
      console.error('Error fetching data:', err.message);
      return;
    }
    this.setState({
      isDownloading: false
    });

  }
  render() {
    const columns123 = [
      {
        title: <span className='textStyles-small' style={{ fontSize: 12 }}>Site ID</span>,
        render: (text, record, index) => (
          <span className='textStyles-small' >
            <Link className='textStyles-small' to={"/site/new/" + text.id} style={{ color: 'black' }}>{text?.siteId}</Link>
          </span>
        ),
      },
      {
        title: <span className='textStyles-small' style={{ fontSize: 12 }}>Site Name</span>,
        key: "siteName",
        render: (item) => (
          <span className='textStyles-small' >
            <Link className='textStyles-small'
              style={{ fontSize: 12, color: 'black' }}
              to={"/site/new/" + item.id}>{item?.siteName}</Link>
          </span>
        ),
      },
      {
        title: <span className='textStyles-small' style={{ fontSize: 12 }}>Region</span>,
        key: "accessPermissionCode",
        render: (item) => <span>{item.region}</span>,
      },
      {
        title: <span className='textStyles-small' style={{ fontSize: 12 }}>Depot</span>,
        key: "accessPermissionCode",
        render: (item) => <span className='textStyles-small' >{item?.depot}</span>,
      },
      {
        title: <span className="textStyles-small">Status</span>,
        dataIndex: 'status',
        key: 'status',
        render: (text) => {
          let color = '#1FAD4B36';
          let fontColor = '#1FAD4B';
          let displayText = 'Active';
          if (text.toLowerCase() === 'inactive' || text.toLowerCase() === 'deactivated' || text.toLowerCase() === 'removed') {
            color = '#C4C4C452';
            fontColor = '#979797';
            displayText = 'Removed';
          } else if (text.toLowerCase() === 'live' || text.toLowerCase() === 'active') {
            color = '#1FAD4B36';
            fontColor = '#1FAD4B';
            displayText = 'Live';
          }
          return (
            <Tag color={color} style={{ padding: 5, borderRadius: 5 }} key={text} className="textStyles-small">
              <span style={{ color: fontColor, fontWeight: 550, fontSize: '12px' }} className="textStyles-small">
                {displayText}
              </span>
            </Tag>
          );
        }
      },
      {
        title: <span className='textStyles-small' style={{ fontSize: 12 }}>Site Category</span>,
        align: "center",
        key: "siteCategory",
        render: (item) => (
          <span class="badge badge-outline-primary badge-pill textStyles-small">
            {item?.siteCategory}
          </span>
        ),
      },
      {
        title: <span className='textStyles-small' style={{ fontSize: 12 }}>Site Owner</span>,
        key: "siteOwnerName ",
        render: (item) => <span className='textStyles-small'>{item?.siteOwnerName}</span>,
      },
      {
        title: <span className='textStyles-small' style={{ fontSize: 12 }}>Tower Category</span>,
        key: "towerCategoryName",
        align: "right",
        width: "9%",
        render: (item) => (
          <span className='textStyles-small'>
            {item?.towerCategoryName}
          </span>
        ),
      },
    ];

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title-box">
                <div className="row">
                  <div class="col">
                    <h4 class="page-title"><span className='textStyles-small' style={{ fontSize: 15, fontWeight: 'bold' }}>Export</span></h4>
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="javascript:void(0);"><span className='textStyles-small' style={{ fontSize: 12 }}>Site View</span></a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="javascript:void(0);"><span className='textStyles-small' style={{ fontSize: 12 }}>Export</span></a>
                      </li>
                    </ol>
                  </div>
                  <div className="col align-right">
                    {
                      !this.state.loading && !this.state.isDownloading ? (
                        <Button
                          type="button"
                          className="btn btn-soft-primary btn-sm"
                          style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '10px', width: '100px', height: '34px', marginLeft: 5, float: 'right' }}
                          onClick={() => this.setState({ openFilter: true })}
                        >
                          <svg width="15" height="15" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.25 2.66667C10.25 1.74619 9.46652 1 8.5 1C7.53348 1 6.75 1.74619 6.75 2.66667M10.25 2.66667C10.25 3.58714 9.46652 4.33333 8.5 4.33333C7.53348 4.33333 6.75 3.58714 6.75 2.66667M10.25 2.66667H15.5M6.75 2.66667H1.5M12 8.5C12 9.4205 12.7835 10.1667 13.75 10.1667C14.7165 10.1667 15.5 9.4205 15.5 8.5C15.5 7.5795 14.7165 6.83333 13.75 6.83333C12.7835 6.83333 12 7.5795 12 8.5ZM12 8.5H1.5M5 14.3333C5 13.4128 4.2165 12.6667 3.25 12.6667C2.2835 12.6667 1.5 13.4128 1.5 14.3333C1.5 15.2538 2.2835 16 3.25 16C4.2165 16 5 15.2538 5 14.3333ZM5 14.3333H15.5"
                              stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" />
                          </svg>
                          <span className="textStyles-small" style={{ color: 'white', fontSize: 13, marginLeft: 10 }}>Filter</span>
                        </Button>
                      ) : null
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            title={<span className="textStyles-small" style={{ fontSize: 15 }}>Filter By</span>}
            open={this.state.openFilter}
            onCancel={() => this.setState({ openFilter: false })}
            onOk={() => this.setState({ openFilter: false })}
            //onOk={}
            //onCancel={}
            destroyOnClose={true}
            width={350}
            footer={null}
            header={null}
            style={{
              position: 'fixed',
              top: 20,
              right: 20,
              overflow: 'auto',
            }}
          >
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={(values) => {
                this.filterBtnClick(values);
                this.setState({
                  isActiveFilter: true,
                  allSiteList: [],
                  pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                    totalPages: 0,
                    lastSeenId: null,
                    hasNextPage: true
                  }
                });
              }}
            >
              <Collapse ghost={true} expandIconPosition="end" style={{ backgroundColor: 'white' }}>
                <Panel header={<span className="textStyles-small" style={{ fontSize: 12 }}>Region</span>} key="2">
                  <Form.Item name="region">
                    <Select
                      placeholder="Select Region"
                      bordered={false}
                      style={{ width: "100%", height: 'auto' }}
                      className='borderedSelect'
                      mode='multiple'
                      showSearch
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        this.setState({
                          selectedRegionID: value
                        })
                      }
                      }
                    >
                      {this.state.allRegions.map((region) => (
                        <Option className="textStyles-small" key={region?.id} value={region?.id}>
                          {region.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Panel>
                <Divider />
                <Panel header={<span className="textStyles-small" style={{ fontSize: 12 }}>Depot</span>} key="3">
                  <Form.Item name="depot">
                    <Select
                      placeholder="Select Depot"
                      bordered={false}
                      style={{ width: "100%", height: 'auto' }}
                      showSearch
                      mode='multiple'
                      className='borderedSelect'
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {
                        this.state.selectedRegionID.length === 0 ? (
                          this.state.allDepots.map((depot) => (
                            <Option className="textStyles-small" key={depot.id} value={depot.id}>
                              {depot.name}
                            </Option>
                          ))
                        ) : (
                          this.state.allDepots
                            .filter((depot) => this.state.selectedRegionID?.includes(depot?.parentRegionId))
                            .map((depot) => (
                              <Option className="textStyles-small" key={depot.id} value={depot.id}>
                                {depot.name}
                              </Option>
                            ))
                        )
                      }
                    </Select>
                  </Form.Item>
                </Panel>
                <Divider />
                <Panel header={<span className="textStyles-small" style={{ fontSize: 12 }}>Status</span>} key="4">
                  <Form.Item name="status">
                    <Select placeholder="Select Status" bordered={false} defaultValue={null}
                      style={{ width: "100%", height: 'auto' }}
                      className='borderedSelect'>
                      <Option className="textStyles-small" value={null}><Badge color="blue" style={{ marginRight: 10 }} />All</Option>
                      <Option className="textStyles-small" value="Live"><Badge color="green" style={{ marginRight: 10 }} />Live</Option>
                      <Option className="textStyles-small" value="Removed"><Badge color="red" style={{ marginRight: 10 }} />Removed</Option>
                    </Select>
                  </Form.Item>
                </Panel>
                <Divider />
                <Panel header={<span className="textStyles-small" style={{ fontSize: 12 }}>Site Owner</span>} key="5">
                  <Form.Item name="siteOwner">
                    <Select
                      placeholder="Select Site Owner"
                      bordered={false}
                      style={{ width: "100%", height: 'auto' }}
                      mode='multiple'
                      className='borderedSelect'
                      showSearch
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.siteOwnerList.map((siteOwner) => (
                        <Option className="textStyles-small" key={siteOwner.id} value={siteOwner.id}>
                          {siteOwner.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Panel>
                <Divider />
                <Panel header={<span className="textStyles-small" style={{ fontSize: 12 }}>Site Type</span>} key="6">
                  <Form.Item name="siteType">
                    <Select
                      placeholder="Select Site Type"
                      bordered={false}
                      style={{ width: "100%", height: 'auto' }}
                      showSearch
                      mode='multiple'
                      className='borderedSelect'
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.siteTypeList.map((siteType) => (
                        <Option className="textStyles-small" key={siteType.id} value={siteType.id}>
                          {siteType.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Panel>
                <Divider />
                <Panel header={<span className="textStyles-small" style={{ fontSize: 12 }}>Tower Category</span>} key="7">
                  <Form.Item name="towerCategory">
                    <Select
                      placeholder="Select Tower Category"
                      bordered={false}
                      style={{ width: "100%", height: 'auto' }}
                      showSearch
                      mode='multiple'
                      className='borderedSelect'
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.towerCategoryList.map((towerCategory) => (
                        <Option className="textStyles-small" key={towerCategory.id} value={towerCategory.id}>
                          {towerCategory.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Panel>
                <Divider />
                <Panel header={<span className="textStyles-small" style={{ fontSize: 12 }}>Tower Type</span>} key="8">
                  <Form.Item name="towerType">
                    <Select
                      placeholder="Select Tower Type"
                      bordered={false}
                      style={{ width: "100%", height: 'auto' }}
                      showSearch
                      mode='multiple'
                      className='borderedSelect'
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.towerTypeList.map((towerType) => (
                        <Option className="textStyles-small" key={towerType.id} value={towerType.id}>
                          {towerType.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Panel>
                <Button
                  type="button"
                  class="btn btn-soft-primary btn-sm"
                  htmlType="submit"
                  style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', width: '100%', height: '34px', marginTop: 10 }}
                >
                  <span className="textStyles-small" style={{ color: 'white', fontSize: 13 }}>Filter</span>
                </Button>
              </Collapse>
            </Form>
          </Modal>

          <div style={{ marginTop: 10, backgroundColor: "white", padding: 15, borderRadius: 15 }}>
            <div className="row">
              <Row>
                <Col span={12}>
                  <Row>
                    <span className="textStyles-small" style={{ fontSize: 18, fontWeight: 'bolder' }}>
                      Site List
                    </span>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row align="middle" justify="end">
                    {
                      !this.state.loading && (
                        <Fragment>
                          <Select placeholder="Select Export Format"
                            className='borderedSelect'
                            style={{ width: '220px', marginLeft: "10px", float: "right" }}
                            onChange={(value) => this.setState({
                              fileFormateName: value
                            })}
                            defaultValue={this.state.fileFormateName}
                            bordered={false}
                          >
                            <Option value="excel" className="textStyle-small">EXCEL</Option>
                            <Option value="csv" className="textStyle-small">CSV</Option>
                          </Select>
                          {
                            this.state.allSiteList.length !== 0 ? (
                              <Button
                                className='textStyles-small'
                                type="primary"
                                loading={this.state?.isDownloading}
                                style={{ borderColor: '#B01F88', backgroundColor: '#B01F88', borderRadius: '10px', width: '143px', height: '34px', marginLeft: 5, float: "right" }}
                                icon={<DownloadOutlined style={{ color: 'white', fontWeight: 'bold' }} />}
                                onClick={async () => await this.exportData(this.state.fileFormateName)}
                              >
                                <span className='textStyles-small'>Download</span>
                              </Button>
                            ) : <></>
                          }
                        </Fragment>
                      )
                    }
                  </Row>
                </Col>
              </Row>

              <Table
                size="small"
                className="table-striped-rows"
                style={{ marginTop: 10 }}
                columns={columns123}
                loading={this.state.loading}
                dataSource={this.state.allSiteList}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    organization_count: state.organization.length,
    regions: state.regions,
    depots: state.depots,
    sites_count: state.sites.length,
    vendor_count: state.vendor.length,
    roles_count: state.roles.length,
  };
}

export default connect(mapStateToProps, { getDonationCategories, getData })(
  SiteNewExport
);
