import React, { Component } from 'react';
import locationFlag from "../../../icon/location_flag.png";
import locationFlagHovered from "../../../icon/location_flag_hovered.png";
import { Card, Col, Row, Descriptions, Tag } from 'antd';
import { Route, withRouter } from 'react-router-dom';

class MapLocation extends Component {


    constructor(props) {
        super(props);
    }

    viewSiteDetails = () => {
        this.props.history.push('/site-summary/' + this.props.id);
    }


    render() {

        return (
            <div>
                <div  >
                    <img style={{ width: 40, position: 'relative', zIndex: '1' }} src={this.props.isHovered ? locationFlagHovered : locationFlag} />
                    {this.props.showSiteName && <div style={{ fontWeight: 500, textAlign: "center", fontSize: 9, padding: 5, backgroundColor: "#000000", width: 75, color: "#ffffff", borderRadius: 5 }}>{this.props.name}</div>}
                </div>

            </div >
        )
    }
}


export default withRouter(MapLocation);