import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { createNewRegion } from "../../app/api/index";
import { ToastContainer, toast } from "material-react-toastify";
import { getUsers } from "../api/index";
import { Table, Input, Tag, Space } from "antd";
import {
  RightOutlined,
  DownOutlined,
  BorderOuterOutlined,
  TeamOutlined,
  AppstoreOutlined,
  DeploymentUnitOutlined,
  ClusterOutlined,
} from "@ant-design/icons";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      filterTable: null,
      baseData: "",
    };
  }
  organizationList() {
    //console.log("kk");
  }
  componentDidMount() {
    this.getUsers();
  }

  getUsers = async () => {
    var res = await getUsers();
    //console.log(res);
    if (res.success) {
      this.setState({
        baseData: res.data,
      });
    }
  };

  formItemChange = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
    //console.log(obj);
  };

  formItemChecked = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.checked;
    this.setState(obj);
    //console.log(obj);
  };
  search = (value) => {
    const { baseData } = this.state;
    //console.log("PASS", { value });

    const filterTable = baseData.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );

    this.setState({ filterTable });
  };
  filterRegions = (value, region) => {
    var rr = region.filter((d) => d.name.includes(value));
    //console.log(rr);
    return rr.length > 0;
  };
  render() {
    const { filterTable, baseData } = this.state;

    const columns = [
      {
        title: "#",
        dataIndex: "id",

        defaultSortOrder: "ascend",
        sorter: (a, b) => a.id - b.id,
        sortDirections: ["descend"],
        render: (text, record, index) => <span>{text}</span>,
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text) => <span>{text}</span>,
      },
      
     
      {
        title: "Description",
        key: "description",
        dataIndex: "description",
        render: (text) => <span>{text}</span>,

       // onFilter: (value, record) => this.filterRegions(value, record.roles),
        
      },
      
     

  
      
    ];
    const columnsRole = [
      {
        title: "#",
        dataIndex: "id",

        defaultSortOrder: "ascend",
        sorter: (a, b) => a.id - b.id,
        sortDirections: ["descend"],
        render: (text, record, index) => <span>{text}</span>,
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text) => <span>{text}</span>,
      },
      
     
      {
        title: "Description",
        key: "description",
        dataIndex: "description",
        render: (text) => <span>{text}</span>,

       // onFilter: (value, record) => this.filterRegions(value, record.roles),
        
      },
      
     

  
      
    ];
    return (
      <div>
        <div className="row">
        <div className="col-lg-2">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">   <i class="fa fa-user"></i> Details</h4>
              </div>
              <div class="card-body">
                                    <ul class="list-group custom-list-group mb-n3">
                                        <li class="list-group-item align-items-center d-flex justify-content-between pt-0">
                                            <div class="media">
                                                {/* <img src="assets/images/small/rgb.svg" height="30" class="me-3 align-self-center rounded" alt="..."> */}
                                                <div class="media-body align-self-center"> 
                                                    <h6 class="m-0">Name</h6>
                                                    <p class="mb-0 text-muted">{this.props.user.name}</p>                                                                                           
                                                </div>
                                            </div>
                                            {/* <div class="align-self-center">
                                                <a href="" class="btn btn-sm btn-soft-primary">4.3k <i class="las la-external-link-alt font-15"></i></a>  
                                            </div>                                             */}
                                        </li>
                                        <li class="list-group-item align-items-center d-flex justify-content-between pt-0">
                                            <div class="media">
                                                {/* <img src="assets/images/small/rgb.svg" height="30" class="me-3 align-self-center rounded" alt="..."> */}
                                                <div class="media-body align-self-center"> 
                                                    <h6 class="m-0">Email</h6>
                                                    <p class="mb-0 text-muted">{this.props.user.email}</p>                                                                                           
                                                </div>
                                            </div>
                                            {/* <div class="align-self-center">
                                                <a href="" class="btn btn-sm btn-soft-primary">4.3k <i class="las la-external-link-alt font-15"></i></a>  
                                            </div>                                             */}
                                        </li>
                                      
                                        <li class="list-group-item align-items-center d-flex justify-content-between pt-0">
                                            <div class="media">
                                                {/* <img src="assets/images/small/rgb.svg" height="30" class="me-3 align-self-center rounded" alt="..."> */}
                                                <div class="media-body align-self-center"> 
                                                    <h6 class="m-0">Mobile</h6>
                                                    <p class="mb-0 text-muted">{this.props.user.mobileNumber} , {this.props.user.mobileNumber2}</p>                                                                                           
                                                </div>
                                            </div>
                                            {/* <div class="align-self-center">
                                                <a href="" class="btn btn-sm btn-soft-primary">4.3k <i class="las la-external-link-alt font-15"></i></a>  
                                            </div>                                             */}
                                        </li>
                                        <li class="list-group-item align-items-center d-flex justify-content-between pt-0">
                                            <div class="media">
                                                {/* <img src="assets/images/small/rgb.svg" height="30" class="me-3 align-self-center rounded" alt="..."> */}
                                                <div class="media-body align-self-center"> 
                                                    <h6 class="m-0">NIC</h6>
                                                    <p class="mb-0 text-muted">{this.props.user.nic}</p>                                                                                           
                                                </div>
                                            </div>
                                            {/* <div class="align-self-center">
                                                <a href="" class="btn btn-sm btn-soft-primary">4.3k <i class="las la-external-link-alt font-15"></i></a>  
                                            </div>                                             */}
                                        </li>
                                       
                                    </ul>                                
                                </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Roles</h4>
              </div>
              <div className="card-body">
               

                <Table
                  size="small"
                  columns={columnsRole}
                  dataSource={filterTable == null ? this.props.user.roles : filterTable}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Permissions</h4>
              </div>
              <div className="card-body">
               

                <Table
                  size="small"
                  columns={columns}
                  dataSource={filterTable == null ? this.props.user.permissions : filterTable}
                />
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="top-right" autoClose={5000} />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
  
  };
}
export default connect(mapStateToProps)(Profile);
