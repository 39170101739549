import React, { Component } from 'react'

 class AssetDelete extends Component {


    static Action = async (a, b,c) => {
     
      delete a['delete'];
      Object.assign(a, {site: { "id": b},});
      Object.assign(a, {delete:true,});
      Object.assign(a, {asset: {
        "id": 2
    },});


    var res = await c(a);
    
    if(res.success){
      return Promise.resolve(1);
    }else{
      return Promise.resolve(2);
    }

   

    }
    

    


     
    


  render() {
    return (
      <div>asset_delete</div>
    )
  }
}

export default AssetDelete;
