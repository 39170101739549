import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers/index"; 
import createSagaMiddleware from "redux-saga";
import apiSaga from "../sagas/api-saga";

const initialiseSagaMiddleware = createSagaMiddleware();

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


function saveToLocalStorage(state) {
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem('state', serializedState)
    }catch(e){
        //console.log(e);
    }
}

function loadFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem('state')
        if(serializedState === null) return undefined
        return JSON.parse(serializedState)
    }catch (e) {
        //console.log(e)
        return undefined
    }
}

const persistedState = loadFromLocalStorage()

const store = createStore(
   // //console.log("SID1"),
  rootReducer,
  persistedState,
  storeEnhancers(
    applyMiddleware(  initialiseSagaMiddleware)
  )
);

const unsubscribe = store.subscribe(() => {
    const state = store.getState();
    saveToLocalStorage(state);
});

initialiseSagaMiddleware.run(apiSaga);

export default store;
