import React, { Component, Fragment } from 'react';
import { siteViewNew } from '../../api/api.config';
import { Row, Col, Button, Form, Select, Table, DatePicker, Modal, Tag } from 'antd';
import axios from 'axios';
const { RangePicker } = DatePicker;
const moment = require('moment');

class ActivityLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activityLogData: [],
            loading: false,
            page: 0,
            size: 100 ,
            last: false,
            startDate: moment().subtract(3, 'months').format('YYYY-MM-DD'), 
            endDate: moment().format('YYYY-MM-DD'),
            serviceId: 1,
            requestStatus: null,
            filterUiOpen: false
        };
    }

    componentDidMount() {
        this.fetchActivityLog(this.props?.siteID);
    }

    fetchActivityLog = async (id) => {
        // reset page to 0
        this.setState({ page: 0 });
        let last = false;
        let fetchedData = [];
        this.setState({ loading: true });
        try {
            while (!last) {
                const response = await axios.get(siteViewNew+'summary/site-summary', {
                    params: {
                        siteId: id,
                        serviceId: this.state.serviceId,
                        startDate: this.state.startDate,
                        endDate: this.state.endDate,
                        page: this.state.page,
                        size: this.state.size,
                        requestStatusId: this.state.requestStatus
                    }
                });
                if (response.status === 200) {
                    console.log('response:', response.data.results);
                    fetchedData.push(...response.data.results);
                    last = response.data.last;
                    // this.setState({ page: this.state.page + 1 });
                }
            }
    
            this.setState({
                activityLogData: fetchedData,
                last: last,
                loading: false
            });
        } catch (error) {
            console.error('Error fetching activity log:', error);
            this.setState({ loading: false });
        }
    };

    // fetchActivityLog = async (id) => {
    //     this.setState({ loading: true });
    //     let { page, size, activityLogData } = this.state;
    //     try {
    //         while (!this.state.last) {
    //             const response = await axios.get('http://34.28.76.89:8802/summary/site-summary', {
    //                 params: {
    //                     siteId: id,
    //                     serviceId: this.state.serviceId,
    //                     startDate: this.state.startDate,
    //                     endDate: this.state.endDate,
    //                     page: this.state.page,
    //                     size: this.state.size,
    //                 },
    //             });
    //             if (response.status === 200) {
    //                 const { content, last } = response.data;
    //                 activityLogData = [...activityLogData, ...content];
    //                 this.setState({
    //                     activityLogData: activityLogData,
    //                     last: last,
    //                     page: page + 1,
    //                 });
    //             }
    //         }
    //         this.setState({ loading: false });
    //     } catch (error) {
    //         console.error('Error fetching activity log:', error);
    //         this.setState({ loading: false });
    //     }
    // };

    handleDateChange = (dates, dateStrings) => {
        this.setState({
            startDate: dateStrings[0],
            endDate: dateStrings[1],
        }, () => {
            // this.fetchActivityLog(this.props?.siteID);
        });
    };

    render() {
        const dataArray = [
            {
                title: <span className="textStyles-small">Site ID</span>,
                dataIndex: 'siteId',
                key: 'siteId',
                render: text => <span className="textStyles-small">{text}</span>,
            },
            {
                title: <span className="textStyles-small">Site Name</span>,
                dataIndex: 'siteName',
                key: 'siteName',
                render: text => <span className="textStyles-small">{text}</span>,
            },
            {
                title: <span className="textStyles-small">Region</span>,
                dataIndex: 'region',
                key: 'region',
                render: text => <span className="textStyles-small">{text}</span>,
            },
            {
                title: <span className="textStyles-small">Depot</span>,
                dataIndex: 'depot',
                key: 'depot',
                render: text => <span className="textStyles-small">{text}</span>,
            },
            {
                title: <span className="textStyles-small">Request ID</span>,
                dataIndex: 'requestId',
                key: 'requestId',
                render: text => <span className="textStyles-small">{text}</span>,
            },
            {
                title: <span className="textStyles-small">Created Date</span>,
                dataIndex: 'createdDate',
                key: 'createdDate',
                render: text => <span className="textStyles-small">{new Date(text).toLocaleString()}</span>,
            },
            {
                title: <span className="textStyles-small">Request Status</span>,
                dataIndex: 'requestStatus',
                key: 'requestStatus',
                key: 'status',
                render: (text) => {
                  let color = '#1FAD4B36';
                  let fontColor = '#1FAD4B';
                  let displayText = 'Completed';
                  if (text !== 'COMPLETED') {
                    color = '#FFAC2C42';
                    fontColor = '#FFAC2C';
                    displayText = 'In Progress';
                  }
                  return (
                    <Tag color={color} style={{ padding: 5, borderRadius: 5 }} key={text} className="textStyles-small">
                      <span style={{ color: fontColor, fontWeight: 550, fontSize: '12px' }} className="textStyles-small">
                        {displayText}
                      </span>
                    </Tag>
                  );
                }
            },
        ]

        return (
            <Fragment>
                <div style={{ padding: '10px' }}>
                    <Row span={24}>
                        <Col span={12}>
                            <div className="site-card-border-less-wrapper" style={{ width: '98%', marginTop: '20px', backgroundColor: 'white', borderRadius: '15px', padding: '15px' }}>
                                <Row span={24}>
                                    <Col span={12}>
                                        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.16644 13.5006C5.16644 19.1979 9.80113 23.8335 15.5002 23.8335C18.3058 23.8335 20.8944 22.7072 22.7971 20.7969L19.3754 17.3754H27.1257V25.1251L24.6223 22.6206C22.243 25.0101 19.0072 26.4167 15.5002 26.4167C8.37636 26.4167 2.58301 20.6225 2.58301 13.5006H5.16644ZM15.4992 0.583374C22.623 0.583374 28.4163 6.37756 28.4163 13.4995H25.8329C25.8329 7.80221 21.1982 3.16661 15.4992 3.16661C12.6935 3.16661 10.1049 4.2929 8.20224 6.20319L11.624 9.62469H3.87371V1.87499L6.37704 4.37813C8.75638 1.98995 11.9922 0.583374 15.4992 0.583374Z" fill="#F28603" />
                                        </svg>
                                        <span style={{ marginLeft: '10px', fontSize: 14, fontWeight: 600 }} className="textStyles-small">In Progress</span>
                                    </Col>
                                    <Col span={12}>
                                        <span style={{ marginLeft: '10px', fontSize: 16, fontWeight: 600, float: 'right', color: '#F28603' }}
                                            className="textStyles-small">0{this.props.apiResponse?.totalPending ? this.props.apiResponse.totalPending : 0}</span>
                                    </Col>
                                </Row>
                                {
                                    this.props.apiResponse?.serviceSummaries?.map((item, index) => {
                                        return (
                                            <Row span={24} key={index} style={{ marginTop: '15px' }}>
                                                <Col span={12}>
                                                    <span style={{ fontSize: 13, fontWeight: 500, marginLeft: '31px' }} className="textStyles-small">{item?.serviceName}</span>
                                                </Col>
                                                <Col span={12}>
                                                    <span style={{ fontSize: 14, fontWeight: 500, float: 'right' }} className="textStyles-small">{item?.siteRequestCount?.ongoing}</span>
                                                </Col>
                                            </Row>
                                        )
                                    })
                                }
                            </div>
                        </Col>

                        <Col span={12}>
                            <div className="site-card-border-less-wrapper" style={{ width: '98%', marginTop: '20px', backgroundColor: 'white', borderRadius: '15px', padding: '15px' }}>
                                <Row span={24}>
                                    <Col span={12}>
                                        <svg width="31" height="31" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.5643 23.1274C15.4167 23.1311 15.2698 23.1049 15.1326 23.0504C14.9954 22.9959 14.8706 22.9142 14.7658 22.8102L9.57053 17.6149C9.3653 17.4096 9.25 17.1313 9.25 16.841C9.25 16.5508 9.3653 16.2724 9.57053 16.0672C9.77576 15.862 10.0541 15.7467 10.3444 15.7467C10.6346 15.7467 10.913 15.862 11.1182 16.0672L15.5424 20.4914L23.8604 12.1899C23.962 12.0882 24.0826 12.0076 24.2154 11.9526C24.3482 11.8976 24.4905 11.8693 24.6342 11.8693C24.7779 11.8693 24.9202 11.8976 25.053 11.9526C25.1858 12.0076 25.3064 12.0882 25.408 12.1899C25.5096 12.2915 25.5903 12.4121 25.6453 12.5449C25.7003 12.6777 25.7286 12.82 25.7286 12.9637C25.7286 13.1074 25.7003 13.2497 25.6453 13.3825C25.5903 13.5152 25.5096 13.6359 25.408 13.7375L16.3354 22.8102C16.1303 23.0136 15.8531 23.1276 15.5643 23.1274Z" fill="#F28603" />
                                            <path d="M17.4996 32.8125C14.5077 32.808 11.5826 31.9271 9.08579 30.2787C6.58896 28.6303 4.62975 26.2865 3.4502 23.5369C2.27065 20.7873 1.92245 17.7524 2.44862 14.8071C2.97478 11.8618 4.35226 9.13521 6.41087 6.96411C8.46948 4.79301 11.119 3.27256 14.0322 2.59059C16.9453 1.90862 19.9945 2.09502 22.8028 3.12675C25.6112 4.15849 28.0559 5.99035 29.8347 8.39604C31.6135 10.8017 32.6486 13.6758 32.8121 16.6633C32.8285 16.9526 32.7295 17.2366 32.5367 17.4529C32.344 17.6692 32.0732 17.8003 31.784 17.8172C31.6398 17.826 31.4954 17.8063 31.3589 17.759C31.2225 17.7118 31.0967 17.638 30.989 17.5419C30.8812 17.4458 30.7935 17.3293 30.7309 17.1992C30.6684 17.069 30.6323 16.9278 30.6246 16.7836C30.4762 14.0415 29.4715 11.4151 27.752 9.27404C26.0324 7.13301 23.6846 5.58525 21.0392 4.84869C18.3937 4.11213 15.5839 4.22388 13.0053 5.1682C10.4267 6.11252 8.20927 7.84185 6.66519 10.1127C5.1211 12.3835 4.32816 15.0815 4.39801 17.8266C4.46787 20.5718 5.397 23.2259 7.05459 25.4153C8.71217 27.6046 11.0147 29.2189 13.638 30.0309C16.2613 30.8428 19.0732 30.8115 21.6777 29.9414C23.331 29.3912 24.8573 28.5154 26.1666 27.3657C27.4759 26.2161 28.5417 24.8159 29.3012 23.2476C29.3588 23.1105 29.4438 22.9866 29.551 22.8834C29.6582 22.7803 29.7853 22.7001 29.9245 22.6477C30.0637 22.5954 30.2122 22.572 30.3608 22.579C30.5094 22.586 30.655 22.6232 30.7887 22.6884C30.9224 22.7535 31.0414 22.8453 31.1384 22.9581C31.2355 23.0708 31.3085 23.2022 31.353 23.3441C31.3975 23.486 31.4127 23.6355 31.3974 23.7835C31.3822 23.9315 31.3369 24.0748 31.2644 24.2047C30.3807 26.0361 29.1383 27.6715 27.6108 29.0139C26.0834 30.3562 24.302 31.3783 22.3722 32.0195C20.8013 32.5443 19.1559 32.8121 17.4996 32.8125Z" fill="#F28603" />
                                        </svg>
                                        <span style={{ marginLeft: '10px', fontSize: 14, fontWeight: 600 }} className="textStyles-small">Completed</span>
                                    </Col>
                                    <Col span={12}>
                                        <span style={{ marginLeft: '10px', fontSize: 16, fontWeight: 600, float: 'right', color: '#F28603' }}
                                            className="textStyles-small">0{this.props.apiResponse?.totalComplete ? this.props.apiResponse.totalComplete : 0}</span>
                                    </Col>
                                </Row>
                                {
                                    this.props.apiResponse?.serviceSummaries?.map((item, index) => {
                                        return (
                                            <Row span={24} key={index} style={{ marginTop: '15px' }}>
                                                <Col span={12}>
                                                    <span style={{ fontSize: 13, fontWeight: 500, marginLeft: '31px' }} className="textStyles-small">{item?.serviceName}</span>
                                                </Col>
                                                <Col span={12}>
                                                    <span style={{ fontSize: 14, fontWeight: 500, float: 'right' }} className="textStyles-small">{item?.siteRequestCount?.completed}</span>
                                                </Col>
                                            </Row>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>

                    <Modal
                        title={<span className="textStyles-small" style={{ fontSize: 15 }}>Filter By</span>}
                        open={this.state.filterUiOpen}
                        onCancel={() => this.setState({ filterUiOpen: false })}
                        onOk={() => this.setState({ filterUiOpen: false })}
                        destroyOnClose={true}
                        width={350}
                        height={380}
                        footer={null}
                        header={null}
                        style={{
                            position: 'fixed',
                            top: 20,
                            right: 20,
                            overflow: 'auto',
                        }}
                    >
                        <div style={{ padding: '10px', height: '250px' }}>
                            <Form layout="vertical">
                            <Form.Item label={<span className="textStyles-small">Date Range</span>}>
                            <RangePicker onChange={this.handleDateChange} className='ant-picker-input'
                                    style={{ width: "100%", height: "35px", float: 'right', borderRadius: 10, borderColor: 'gray', marginLeft: '5px' }}
                                    defaultValue={[
                                        this.state.startDate ? moment(this.state.startDate, 'YYYY-MM-DD') : null,
                                        this.state.endDate ? moment(this.state.endDate, 'YYYY-MM-DD') : null
                                    ]}
                                    format="YYYY-MM-DD" />
                            </Form.Item>

                            <Form.Item label={<span className="textStyles-small">Request Status</span>}>
                            <Select 
                            defaultValue={this.state.requestStatus} 
                            style={{ width: '100%', float: 'right', borderColor: 'gray', marginTop: '5px' }} className='borderedSelect'
                                    bordered={false}
                                    onChange={(value) =>{ this.setState({ requestStatus: value })}}
                                    showSearch={true}>
                                    <Select.Option className="textStyles-small" value={null}>All</Select.Option>
                                    <Select.Option className="textStyles-small" value={0}>In Progress</Select.Option>
                                    <Select.Option className="textStyles-small" value={1}>Completed</Select.Option>
                                </Select>
                            </Form.Item>

                            <Button style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', width: '100%', height: '34px', marginTop: 10 }}
                            onClick={() => {
                                this.setState({ filterUiOpen: false }, () => {
                                    this.fetchActivityLog(this.props?.siteID);
                                });
                            }}>
                                <span className="textStyles-small" style={{ color: 'white', fontSize: 13 }}>Filter</span>
                            </Button>
                            </Form>
                        </div>
                    </Modal>

                    

                    <div className="site-card-border-less-wrapper" style={{ width: '98%', marginTop: '20px', backgroundColor: 'white', borderRadius: '15px', padding: '15px' }}>
                        <Row span={24}>
                            <Col span={12}>
                                <span style={{ fontSize: 14, fontWeight: 600 }} className="textStyles-small">Summary List</span>
                            </Col>
                            <Col span={12}>

                                <Button style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '10px', width: '100px', height: '34px', float: 'right', marginRight: '10px' }}
                                    onClick={() => this.setState({ filterUiOpen: true })}>
                                    <svg width="14" height="14" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.25 2.66667C10.25 1.74619 9.46652 1 8.5 1C7.53348 1 6.75 1.74619 6.75 2.66667M10.25 2.66667C10.25 3.58714 9.46652 4.33333 8.5 4.33333C7.53348 4.33333 6.75 3.58714 6.75 2.66667M10.25 2.66667H15.5M6.75 2.66667H1.5M12 8.5C12 9.4205 12.7835 10.1667 13.75 10.1667C14.7165 10.1667 15.5 9.4205 15.5 8.5C15.5 7.5795 14.7165 6.83333 13.75 6.83333C12.7835 6.83333 12 7.5795 12 8.5ZM12 8.5H1.5M5 14.3333C5 13.4128 4.2165 12.6667 3.25 12.6667C2.2835 12.6667 1.5 13.4128 1.5 14.3333C1.5 15.2538 2.2835 16 3.25 16C4.2165 16 5 15.2538 5 14.3333ZM5 14.3333H15.5" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" />
                                    </svg>
                                    <span className="textStyles-small" style={{ color: 'white', fontSize: 13, marginLeft: 10 }}>Filter</span>
                                </Button>

                                <Select defaultValue={1} style={{ width: 180, float: 'right', borderColor: 'gray', marginLeft: 10, marginRight: 10 }} className='borderedSelect'
                                    bordered={false}
                                    onChange={(value) => this.setState({ serviceId: value }, () => this.fetchActivityLog(this.props?.siteID))}
                                    showSearch={true}>
                                    {
                                        this.props.apiResponse?.serviceSummaries?.map((item, index) => {
                                            return (
                                                <Select.Option
                                                    className="textStyles-small"
                                                    key={index} value={item?.serviceId}>{item?.serviceName}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                        </Row>

                        <Table
                            size="small"
                            className="table-striped-rows"
                            style={{ marginTop: 20 }}
                            columns={dataArray}
                            loading={this.state.loading}
                            dataSource={this.state?.activityLogData}
                        />
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default ActivityLog;