export const data_set = {
    "data": [{
        "id": 1,
        "import_sheet_name": "General",
    }],
    "fields":[
        {
            "value": 0,
            "detail_view": false,
            "field_name": "id",
            "display_name": "site_primary_key",
            "label": "Site- Site Primary Key",
            "table_name": "site",
            "is_primary_table": true,
            "table_prefix": "tbl_site",
            "response_type": "text",
            "operotors": [{
                "key": 1,
                "lable": "=",
                "value": "="
            }]
        },
        {
            "value": 1,
            "field_name": "region_id",
            "display_name": "region_id_value",
            "label": "Site- Region Primary Key",
            "table_name": "depot",
            "is_primary_table": true,
            "table_prefix": "tbl_depot",
            "response_type": "text",
            "detail_view": false,
            "operotors": [{
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 2,
            "field_name": "depot_id",
            "display_name": "depot_id_value",
            "label": "Site- Depot Primary Key",
            "table_name": "site",
            "is_primary_table": true,
            "table_prefix": "tbl_site",
            "response_type": "text",
            "detail_view": false,
            "operotors": [{ 
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 3,
            "detail_view": false,
            "field_name": "site_id",
            "display_name": "site_id_value",
            "label": "Site- Site Id",
            "table_name": "site",
            "is_primary_table": true,
            "table_prefix": "tbl_site",
            "response_type": "text",
            "operotors": [{
                "key": 1,
                "lable": "=",
                "value": "="
            }]
        },
        {
            "value": 4,
            "field_name": "site_name",
            "label": "Site- Site Name",
            "display_name": "site_name_value",
            "table_name": "site",
            "is_primary_table": true,
            "table_prefix": "tbl_site",
            "response_type": "array",
            "query_name": "SELECT `site_name` AS `name`, `id` FROM `site`",
            "extra_fields": ['name', 'id'],
            "detail_view": true,
            "operotors": [{
                "key": 1,
                "key": "IN",
                "value": "IN"
            }]
        },
        {
            "value": 5,
            "field_name": "name",
            "display_name": "depot_value",
            "label": "Site- Depot Name",
            "table_name": "depot",
            "is_primary_table": false,
            "primary_table_name": "site",
            "table_prefix": "tbl_depot",
            "primary_table_prefix": "tbl_site",
            "response_type": "array",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name`, `id` FROM `depot`",
            "operotors": [{
                "key": 2,
                "key": "IN",
                "value": "IN"
            }]
        },
        {
            "value": 6,
            "field_name": "name",
            "display_name": "region_value",
            "label": "Site- Region Name",
            "table_name": "region",
            "is_primary_table": false,
            "primary_table_name": "depot",
            "table_prefix": "tbl_region",
            "primary_table_prefix": "tbl_depot",
            "response_type": "array",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name`, `id` FROM `region`",
            "operotors": [{
                "key": 2,
                "key": "IN",
                "value": "IN"
            }]
        },
        {
            "value": 7,
            "field_name": "pg_possibility",
            "display_name": "pg_possibility_value",
            "label": "Site- PG Possibility",
            "table_name": "site",
            "is_primary_table": true,
            "table_prefix": "tbl_site",
            "response_type": "text",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 8,
            "field_name": "name",
            "display_name": "site_owner_value",
            "label": "Site- Owner Name",
            "table_name": "site_owner",
            "is_primary_table": false,
            "primary_table_name": "site",
            "table_prefix": "tbl_site_owner",
            "primary_table_prefix": "tbl_site",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name`, `id` FROM `site_owner`",
            "operotors": [{
                "key": 1,
                "key": "equals",
                "value": "="
            }, {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 9,
            "field_name": "status",
            "display_name": "status_value",
            "label": "Site- Status",
            "table_name": "site",
            "is_primary_table": true,
            "table_prefix": "tbl_site",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `status` as `name`, `id` FROM `site`",
            "operotors": [ {
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]     
        },
        {
            "value": 10,
            "field_name": "tower_height",
            "display_name": "tower_height_value",
            "label": "Site- Tower Height",
            "table_name": "site",
            "is_primary_table": true,
            "table_prefix": "tbl_site",
            "response_type": "text",
            "detail_view": false,
            "operotors": [{
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 11,
            "field_name": "tower_owner_category",
            "display_name": "tower_owner_category_value",
            "label": "Site- Tower Owner Category",
            "table_name": "site",
            "is_primary_table": true,
            "table_prefix": "tbl_site",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `tower_owner_category` as `name`, `id` FROM `site`",
            "operotors": [{
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 12,
            "field_name": "name",
            "display_name": "tower_category_value",
            "label": "Site- Tower Category",
            "table_name": "tower_category",
            "is_primary_table": true,
            "table_prefix": "tbl_tower_category",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name`, `id` FROM `tower_category`",
            "operotors": [{
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 13,
            "field_name": "name",
            "display_name": "tower_type_value",
            "label": "Site- Tower Type",
            "table_name": "tower_type",
            "is_primary_table": false,
            "primary_table_name": "site",
            "table_prefix": "tbl_tower_type",
            "primary_table_prefix": "tbl_site",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name`, `id` FROM `tower_type`",
            "operotors": [{
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 14,
            "field_name": "site_rank",
            "display_name": "site_rank_value",
            "label": "Site- Site Rank",
            "table_name": "site",
            "is_primary_table": true,
            "table_prefix": "tbl_site",
            "response_type": "number",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "=",
                "value": "="
            }, {
                "key": 2,
                "key": ">",
                "value": ">"
            }, {
                "key": 3,
                "key": "<",
                "value": "<"
            }]
        },
        {
            "value": 15,
            "field_name": "name",
            "display_name": "entity_name",
            "label": "Site- Entity",
            "table_name": "entity",
            "is_primary_table": true,
            "table_prefix": "tbl_entity",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name` as `name`, `id` FROM `entity`",
            "operotors": [{
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 16,
            "field_name": "name",
            "display_name": "access_permition_group_name",
            "label": "Site- Access Permition Group",
            "table_name": "access_permition_group",
            "is_primary_table": true,
            "table_prefix": "tbl_access_permition_group",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name` as `name`, `id` FROM `access_permition_group`",
            "operotors": [{
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 17,
            "field_name": "name_en",
            "display_name": "districts_name",
            "label": "Site- Districts",
            "table_name": "districts",
            "is_primary_table": true,
            "table_prefix": "tbl_districts",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name_en` as `name`, `id` FROM `districts`",
            "operotors": [{
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 18,
            "field_name": "request_code",
            "display_name": "request_code_value",
            "label": "Ticket Base- Request Code",
            "table_name": "service_request",
            "is_primary_table": true,
            "table_prefix": "tbl_service_request",
            "response_type": "text",
            "detail_view": false,
            "operotors": [{
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 19,
            "field_name": "name",
            "display_name": "service_type_name",
            "label": "Ticket Base- Service Type",
            "table_name": "service_type",
            "is_primary_table": false,
            "primary_table_name": "service_request",
            "table_prefix": "tbl_service_type",
            "primary_table_prefix": "tbl_service_request",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name` as `name`, `id` FROM `service_type`",
            "operotors": [{
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 20,
            "field_name": "name",
            "display_name": "service_status_name",
            "label": "Ticket Base- Service Status",
            "table_name": "service_status",
            "is_primary_table": false,
            "primary_table_name": "service_request",
            "table_prefix": "tbl_service_status",
            "primary_table_prefix": "tbl_service_request",
            "response_type": "text",
            "detail_view": true,
            "extra_fields": ['name', 'id'],
            "query_name": "SELECT `name` as `name`, `id` FROM `service_status`",
            "operotors": [{
                "key": 2,
                "key": "LIKE",
                "value": "LIKE"
            }]
        },
        {
            "value": 21,
            "field_name": "request_date",
            "display_name": "request_date_value",
            "label": "Ticket Base- Request Date",
            "table_name": "service_request",
            "is_primary_table": true,
            "table_prefix": "tbl_service_request",
            "response_type": "date",
            "detail_view": false,
            "operotors": [{
                "key": 1,
                "key": "BETWEEN",
                "value": "BETWEEN"
            }]
        },
    ],
    "relations": [{
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "generator",
        "sub_table_prefix": "tbl_generator",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "asset",
        "sub_table_prefix": "asset",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "generator",
        "main_table_prefix": "tbl_generator",
        "sub_table": "asset",
        "sub_table_prefix": "tbl_asset",
        "main_table_id": "id",
        "sub_table_id": "asset_id",
    },
    {
        "main_table": "generator",
        "main_table_prefix": "tbl_generator",
        "sub_table": "generator_model",
        "sub_table_prefix": "tbl_generator_model",
        "main_table_id": "model_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "indoor_outdoor_type",
        "sub_table_prefix": "tbl_indoor_outdoor_type",
        "main_table_id": "indoor_outdoor_type_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "renewable_energy_solar",
        "sub_table_prefix": "tbl_renewable_energy_solar",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "renewable_energy_solar",
        "main_table_prefix": "tbl_renewable_energy_solar",
        "sub_table": "solar_panel_model",
        "sub_table_prefix": "tbl_solar_panel_model",
        "main_table_id": "panel_model_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "renewable_energy_wind",
        "sub_table_prefix": "tbl_renewable_energy_wind",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "shared_out_operators",
        "sub_table_prefix": "tbl_shared_out_operators",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "cabinet",
        "sub_table_prefix": "tbl_cabinet",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "cabinet_model",
        "main_table_prefix": "tbl_cabinet_model",
        "sub_table": "cabinet_make",
        "sub_table_prefix": "tbl_cabinet_make",
        "main_table_id": "make_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "cabinet",
        "main_table_prefix": "tbl_cabinet",
        "sub_table": "cabinet_model",
        "sub_table_prefix": "tbl_cabinet_model",
        "main_table_id": "model_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "cabinet",
        "main_table_prefix": "tbl_cabinet",
        "sub_table": "cabinet_type",
        "sub_table_prefix": "tbl_cabinet_type",
        "main_table_id": "type",
        "sub_table_id": "id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "battery_bank",
        "sub_table_prefix": "tbl_battery_bank",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "battery_bank",
        "main_table_prefix": "tbl_battery_bank",
        "sub_table": "battery_bank_make",
        "sub_table_prefix": "tbl_battery_bank_make",
        "main_table_id": "make_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "battery_bank",
        "main_table_prefix": "tbl_battery_bank",
        "sub_table": "battery_bank_model",
        "sub_table_prefix": "tbl_battery_bank_model",
        "main_table_id": "model_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "air_conditioner",
        "sub_table_prefix": "tbl_air_conditioner",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "air_conditioner",
        "main_table_prefix": "tbl_air_conditioner",
        "sub_table": "ac_make",
        "sub_table_prefix": "tbl_ac_make",
        "main_table_id": "make_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "id_battery_rack",
        "sub_table_prefix": "tbl_id_battery_rack",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "site_power_db",
        "sub_table_prefix": "tbl_site_power_db",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "dcdu",
        "sub_table_prefix": "tbl_dcdu",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "rectifier",
        "sub_table_prefix": "tbl_rectifier",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "rectifier",
        "main_table_prefix": "tbl_rectifier",
        "sub_table": "rectifier_make",
        "sub_table_prefix": "tbl_rectifier_make",
        "main_table_id": "make_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "rectifier",
        "main_table_prefix": "tbl_rectifier",
        "sub_table": "rectifier_model",
        "sub_table_prefix": "tbl_rectifier_model",
        "main_table_id": "model_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "generator",
        "sub_table_prefix": "tbl_generator",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "site_owner",
        "sub_table_prefix": "tbl_site_owner",
        "main_table_id": "site_owner_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "depot",
        "sub_table_prefix": "tbl_depot",
        "main_table_id": "depot_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "depot",
        "main_table_prefix": "tbl_depot",
        "sub_table": "region",
        "sub_table_prefix": "tbl_region",
        "main_table_id": "region_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "site_power_db",
        "main_table_prefix": "tbl_site_power_db",
        "sub_table": "power_connection_type",
        "sub_table_prefix": "tbl_power_connection_type",
        "main_table_id": "mpc_power_connection_type",
        "sub_table_id": "id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "fcb",
        "sub_table_prefix": "tbl_fcb",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "fcb",
        "main_table_prefix": "tbl_fcb",
        "sub_table": "fcb_make",
        "sub_table_prefix": "tbl_fcb_make",
        "main_table_id": "make_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "fcb",
        "main_table_prefix": "tbl_fcb",
        "sub_table": "fcb_model",
        "sub_table_prefix": "tbl_fcb_model",
        "main_table_id": "model_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "ats",
        "sub_table_prefix": "tbl_ats",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "ats",
        "main_table_prefix": "tbl_ats",
        "sub_table": "ats_make",
        "sub_table_prefix": "tbl_ats_make",
        "main_table_id": "make_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "ats",
        "main_table_prefix": "tbl_ats",
        "sub_table": "ats_model",
        "sub_table_prefix": "tbl_ats_model",
        "main_table_id": "model_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "site",
        "main_table_prefix": "tbl_site",
        "sub_table": "spds",
        "sub_table_prefix": "tbl_spds",
        "main_table_id": "id",
        "sub_table_id": "site_id",
    },
    {
        "main_table": "spds",
        "main_table_prefix": "tbl_spds",
        "sub_table": "spds_make",
        "sub_table_prefix": "tbl_spds_make",
        "main_table_id": "make_id",
        "sub_table_id": "id",
    },
    {
        "main_table": "tower_category",
        "main_table_prefix": "tbl_tower_category",
        "sub_table": "site",
        "sub_table_prefix": "tbl_site",
        "main_table_id": "id",
        "sub_table_id": "tower_category_id",
    },
    {
        "main_table": "tower_type",
        "main_table_prefix": "tbl_tower_type",
        "sub_table": "site",
        "sub_table_prefix": "tbl_site",
        "main_table_id": "id",
        "sub_table_id": "tower_type",
    },
    // `service_request
    {
        "main_table": "service_request",
        "main_table_prefix": "tbl_service_request",
        "sub_table": "site",
        "sub_table_prefix": "tbl_site",
        "main_table_id": "site_id",
        "sub_table_id": "id",   
    },
    // service_status
    {
        "main_table": "service_status",
        "main_table_prefix": "tbl_service_status",
        "sub_table": "service_request",
        "sub_table_prefix": "tbl_service_request",
        "main_table_id": "id",
        "sub_table_id": "service_status_id",
    },
    // service_type
    {
        "main_table": "service_type",
        "main_table_prefix": "tbl_service_type",
        "sub_table": "service_request",
        "sub_table_prefix": "tbl_service_request",
        "main_table_id": "id",
        "sub_table_id": "service_type_id",
    },
    // entity
    {
        "main_table": "entity",
        "main_table_prefix": "tbl_entity",
        "sub_table": "site",
        "sub_table_prefix": "tbl_site",
        "main_table_id": "id",
        "sub_table_id": "site_entity",
    },
    // access_permition_group
    {
        "main_table": "access_permition_group",
        "main_table_prefix": "tbl_access_permition_group",
        "sub_table": "site",
        "sub_table_prefix": "tbl_site",
        "main_table_id": "id",
        "sub_table_id": "access_permition_group_id",
    },
    // districts
    {
        "main_table": "districts",
        "main_table_prefix": "tbl_districts",
        "sub_table": "site",
        "sub_table_prefix": "tbl_site",
        "main_table_id": "id",
        "sub_table_id": "district",
    },
],
    "all_operators": [{

    }]
}
