import React, { Component } from 'react';
import {
    getUsers, getUserAllRequests, getUserSites, getStatusSummary, getlogByStatusId, getAccessPermitionRequestById,
    gethandlerJobRequests,
    gethandlerAllRequests, downloadAccessReqDocument, genareteAccessReqDocument, requestSendToSite, getAccessRestrictions, accessApprovedFromSite, accessRejectFromSite, requestRejectByHandler, getSitesWithFilter
} from "../../api/index";
import { List, Avatar, Button, Skeleton, Space, Image, Radio } from 'antd';
import { MessageOutlined, LikeOutlined, StarOutlined } from '@ant-design/icons';
import moment from 'moment';
import Modal from 'react-modal';
import styles from './modal.css.js'
import { Link } from "react-router-dom";
import ReactLoading from 'react-loading';
import { DownloadOutlined } from '@ant-design/icons';
import { Collapse } from 'reactstrap';
// import Select from 'react-select'
import { Select, DatePicker, Table, Tag, Drawer } from 'antd';
import { connect } from "react-redux";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


// import { PhoneOutlined } from '@ant-design/icons';

const count = 0;
const count2 = 0;
const { Option } = Select;

class SiteAccessHandler extends Component {

    constructor() {
        super();
        this.state = {
            loadingFull:false,
            all_users: null,
            initLoading: true,
            loading: false,
            totalJobOpen: false,
            data: [],
            list: [],
            initLoading2: true,
            loading2: false,
            data2: [],
            list2: [],
            historyViewLoging: false,
            selectedSiteAccessId: '',
            selectedSitteCode: '',
            selectedSitteAccessHistory: [],
            selectedSitteAccessGroup: '',
            selectedSitteAccessHandlerName: '',
            selectedSitteAccessHandlerConstat: '',
            showModal: false,
            showModal2: false,
            selectedstatusLog: [],
            slectExtraDataNot: null,
            slectExtraDataRqType: null,
            slectApproveImage: null,
            selectedstatusLogCreateUser: "",
            selectedstatusLogCreateTime: "",
            selectedstatusLogCreateStatus: "",
            requestViewData: [],
            requestViewDatavisitor: [],
            requestViewDataEqu: [],
            requestViewDataIns: [],
            requestViewDataVhe: [],
            requestViewDataSites: [],
            downloadloding: false,
            showModal3: false,
            isOpenFillter: false,
            reqType: '',
            reqDescription: '',
            accessPermitionStatusID: '',
            reqRestrictions: [],
            selectedFile: null,
            showModal4: false,
            showModal5: false,
            depotList: [],
            regionsList: [],
            _StatusArry: [],
            allSite_loading: true,
            allSite: [],
            statusIds: '',
            selectUser: '',
            duration: '',
            siteIds: '',
            depotIds: '',
            regionIds: '',
            pageNo: 0,
            pageNo2: 0,
            recodesPerPage: 1,
            recodesPerPage2: 1,
            recodesPerPage_1: 20,
            accessStarttime: ""
        };
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleCloseFullModal = this.handleCloseFullModal.bind(this);
        this.handleRejectFullModal = this.handleRejectFullModal.bind(this);
        this.requestSendToSite = this.requestSendToSite.bind(this);
        this.requestDone = this.requestDone.bind(this);
        this.requestDoneRejct = this.requestDoneRejct.bind(this);
        this.requestFromClose = this.requestFromClose.bind(this);
        this.handleChangeInsItmeName = this.handleChangeInsItmeName.bind(this);

    }

    getUsersAll = async () => {
        var res = await getUsers();
        console.log('getUsersAll');
        console.log(res);
        if (res.success) {
            this.setState({
                all_users: res.data,
            });
        }
    };
    viewFilterClick = () => {
        this.setState({
            isOpenFillter: !this.state.isOpenFillter
        })
    }
    handleOpenModal() {
        this.setState({ showModal: true });
    }
    requestFromClose() {
        this.setState({ showModal3: false, showModal4: false, reqType: '' });
    }

    handleCloseModal() {
        this.setState({ showModal: false });
    }
    handleCloseFullModal() {
        this.setState({
            showModal2: false,
            downloadloding: false
        });
    }
    handleRejectFullModal() {
        //console.log("ssssssssssssss");
        this.setState({
            showModal4: true,
            showModal5: true,

        });

    }

    requestDone() {

        if (this.state.accessPermitionStatusID == 60) {
            if (this.state.reqType !== "" && this.state.reqDescription !== "") {

                var form = new FormData();
                var setstrin = '';
                this.state.reqDescription.split('\n').map(function (item, idx) {
                    if (setstrin == '') {
                        setstrin = item;
                    } else {
                        setstrin = setstrin + '\\n' + item;
                    }

                })

                console.log("22")
                console.log(setstrin)
                console.log('{"note":"' + setstrin + '","requestType":"' + this.state.reqType + '"}')

                form.append('accessPermissionRequestId', this.state.requestViewData.id);
                // form.append('extraData', '{"note":"ssss\ndddd dddd dddd","requestType":"' + this.state.reqType + '"}');
                form.append('extraData', '{"note":"' + setstrin + '","requestType":"' + this.state.reqType + '"}');



                this.getDatarequestSendToSite(form);

            } else {
                alert("please fill out all fields");

            }
        }
        if (this.state.accessPermitionStatusID == 63) {
            if (this.state.reqType !== "" && this.state.reqDescription !== "") {
                var form = new FormData();
                //console.log(this.state.selectedFile)

                var setstrin = '';
                this.state.reqDescription.split('\n').map(function (item, idx) {
                    if (setstrin == '') {
                        setstrin = item;
                    } else {
                        setstrin = setstrin + '\\n' + item;
                    }

                })
                form.append('accessPermissionRequestId', this.state.requestViewData.id);
                if (this.state.selectedFile != null) {
                    form.append(
                        'image',
                        this.state.selectedFile,
                        this.state.selectedFile.name
                    );
                }

                // form.append('extraData', '{"note":"' + this.state.reqDescription + '","requestType":"' + this.state.reqType + '"}');
                form.append('extraData', '{"note":"' + setstrin + '","requestType":"' + this.state.reqType + '"}');

                this.getAccessApprovedFromSite(form)


            } else {
                alert("please fill out all fields");

            }
        }


    }

    requestDoneRejct() {

        if (this.state.accessPermitionStatusID == 60) {
            if (this.state.reqType !== "" && this.state.reqDescription !== "") {

                var form = new FormData();
                //console.log(this.state.requestViewData.id)
                var setstrin = '';
                this.state.reqDescription.split('\n').map(function (item, idx) {
                    if (setstrin == '') {
                        setstrin = item;
                    } else {
                        setstrin = setstrin + '\\n' + item;
                    }

                })

                form.append('accessPermissionRequestId', this.state.requestViewData.id);
                // form.append('extraData', '{"note":"' + this.state.reqDescription + '","requestType":"' + this.state.reqType + '"}');
                form.append('extraData', '{"note":"' + setstrin + '","requestType":"' + this.state.reqType + '"}');



                this.getRequestRejectByHandler(form);

            } else {
                alert("please fill out all fields");

            }
        }
        if (this.state.accessPermitionStatusID == 63) {
            if (this.state.reqType !== "" && this.state.reqDescription !== "") {
                var form = new FormData();
                //console.log(this.state.requestViewData.id)
                var setstrin = '';
                this.state.reqDescription.split('\n').map(function (item, idx) {
                    if (setstrin == '') {
                        setstrin = item;
                    } else {
                        setstrin = setstrin + '\\n' + item;
                    }

                })

                form.append('accessPermissionRequestId', this.state.requestViewData.id);

                form.append(
                    'image',
                    this.state.selectedFile,
                    this.state.selectedFile.name
                );
                //  form.append('extraData', '{"note":"' + this.state.reqDescription + '","requestType":"' + this.state.reqType + '"}');
                form.append('extraData', '{"note":"' + setstrin + '","requestType":"' + this.state.reqType + '"}');
                this.getAccessRejectFromSite(form)


            } else {
                alert("please fill out all fields");

            }
        }


    }
    requestSendToSite() {

        this.setState({
            showModal3: true,

        });
    }
    handleChangeInsItmeName(e) {
        console.log(e.target.value)
        this.setState({ reqDescription: e.target.value });
    }
    onFileChange = event => {

        // Update the state
        //console.log(event)
        this.setState({ selectedFile: event.target.files[0] });

    };


    getDataSitesByuser = async () => {
        var res = await getUserSites();
        var _allSite = [];
        //console.log("getUserSites");
        //console.log(res.data);
        if (res.success) {
            res.data.map((sites) => {
                _allSite.push({ value: sites.id, label: sites.siteName })
            })
            this.setState({
                allSite_loading: false,
                allSite: _allSite,
            });
        }
    };
    getDataSitesWithFilter = async () => {
        this.setState({
            allSite_loading: true,
        })

        console.log("subR");
        var _regionList = [];
        var _depotList = [];
        var _allSite = [];
        if (this.state.regionIds !== '') {
            this.state.regionIds?.map((x, y) => {
                _regionList.push({ "id": x })
            }, () => {

            })
        }
        if (this.state.depotIds !== '') {
            this.state.depotIds?.map((a, b) => {
                _depotList.push({ "id": a })
            }, () => {

            })
        }


        console.log(_regionList)
        var data = new FormData();
        // data.append('regions','[{"id":6},{"id":7}]');
        data.append('regions', JSON.stringify(_regionList));
        data.append('depots', JSON.stringify(_depotList));
        var res = await getSitesWithFilter(data);
        console.log(res)
        if (res.success) {
            res.data.data.map((sites) => {
                _allSite.push({ value: sites.id, label: sites.siteName })
            })
            this.setState({
                allSite_loading: false,
                allSite: _allSite,

            })

        }

    }
    componentDidMount() {
        this.getDataSitesByuser();
        this.getUsersAll();
        var _depotList = [];
        var _regionsList = [];
        this.props.depots.map((depot) => {
            _depotList.push({ value: depot.id, label: depot.name })
        })
        this.props.regions.filter(allret => "0".includes(allret.isSubRegion)).map((regions) => {
            _regionsList.push({ value: regions.id, label: regions.name })
        })
        this.setState({
            depotList: _depotList,
            regionsList: _regionsList
        })
        var StatusArry = [];
        StatusArry = [
            {
                "value": 60,
                "label": "Access Request Initiated"
            },
            {
                "value": 61,
                "label": "Access Request Edited"
            },
            {
                "value": 62,
                "label": "Access permissions not required."
            },
            {
                "value": 63,
                "label": "Access Permissions Requested"
            },
            {
                "value": 64,
                "label": "Access Permission Received"
            },
            {
                "value": 65,
                "label": "Completed"
            },
            {
                "value": 66,
                "label": "Rejected by Handler"
            },
            {
                "value": 67,
                "label": "Rejected by Site"
            },
            {
                "value": 68,
                "label": "Permissions Requested after Edit By User"
            },
            {
                "value": 69,
                "label": "Permissions Requested after Edit By Handler"
            },
            {
                "value": 70,
                "label": "Request Cancelled"
            },
            {
                "value": 71,
                "label": "Transfer To the Another Handler"
            }
        ]
        this.setState({
            _StatusArry: StatusArry,

        })
        var dataSet = {
            'pageNo': count,
            'recodesPerPage': '',
            'duration': '20',
            'statusIds': []
        }
        this.getDataUserJobRequests(dataSet);
        this.getDataUserAllRequests(dataSet);

    }
    filterBtnClick = async () => {
        this.setState({
            pageNo: 0,
            pageNo2: 0,
            initLoading: true,
            initLoading2: true,
            loading:true
        }, () => {
            this.getDataUserJobRequests();
            this.getDataUserAllRequests();
        })



    }

    clearBtnClick = () => {
        window.location.reload()
    }
    searchDepottemChange = (event) => {

        this.setState({
            depotIds: event
        }, () => { this.getDataSitesWithFilter() });
    }
    searchSiteemChange = (event) => {

        this.setState({
            siteIds: event
        });
    }
    searchStatustemChange = (event) => {

        this.setState({
            statusIds: event
        });
    }
    handleChangeUser = (event) => {
        console.log(event)
        this.setState({
            selectUser: event
        });
    }
    searchRegionmChange = (event) => {
        var dd = event
        //console.log(dd)
        var subRegion = [];
        var _depotList1 = [];
        var subRegionIdArray = [];


        this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id)).map((subR) => {
            subRegionIdArray.push(subR.id)
        })

        this.setState({
            regionIds: subRegionIdArray
        }, () => { this.getDataSitesWithFilter() });


        // //console.log(this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id)));
        subRegion = this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id));
        ////console.log(this.props.depots.filter(alldate => subRegion.map((n) => n.id).includes(alldate.region?.id)));
        this.props.depots.filter(alldate => subRegion.map((n) => n.id).includes(alldate.region?.id)).map((depot) => {
            _depotList1.push({ value: depot.id, label: depot.name })
        })

        this.setState({
            depotList: _depotList1

        })

    }
    onChengeReqType = (e) => {
        //console.log(e)
        this.setState({
            reqType: e.target.value
        })
        //setValue(e.target.value);
    }
    previousBtnClick1 = () => {
        if (this.state.pageNo > 0) {
            var aa = this.state.pageNo - 1
            this.setState({
                pageNo: aa
            }, () => {
                this.getDataUserJobRequests();
            })
        }
    }
    nextBtnClick1 = () => {
        var aa = this.state.pageNo + 1
        this.setState({
            pageNo: aa
        }, () => {
            this.getDataUserJobRequests();
        })

    }
    previousBtnClick2 = () => {
        if (this.state.pageNo2 > 0) {
            var aa = this.state.pageNo2 - 1
            this.setState({
                pageNo2: aa
            }, () => {
                this.getDataUserAllRequests();
            })
        }
    }
    nextBtnClick2 = () => {
        var aa = this.state.pageNo2 + 1
        this.setState({
            pageNo2: aa
        }, () => {
            this.getDataUserAllRequests();
        })

    }
    onLoadMore = () => {
        this.setState({
            loading: true,
            list: this.state.data.concat(
                [...new Array(count)].map(() => ({ loading: true, name: {}, picture: {} })),
            ),
        });
        var dataSet = {
            'pageNo': count,
            'recodesPerPage': '',
            'duration': '20',
            'statusIds': [1, 2, 3, 4]
        }
        this.getDataUserJobRequests(dataSet);


    };
    onLoadMore2 = () => {
        this.setState({
            loading2: true,
            list2: this.state.data.concat(
                [...new Array(count2)].map(() => ({ loading2: true, name: {}, picture: {} })),
            ),
        });
        var dataSet = {
            'pageNo': count2,
            'recodesPerPage': '',
            'duration': '20',
            'statusIds': [1, 2, 3, 4]
        }
        this.getDataUserAllRequests(dataSet);


    };
    historyView = (id, code) => {
        this.setState({
            historyViewLoging: true,
            totalJobOpen: true
        })
        var form = new FormData();
        form.append("accessPermissionRequestId", id);


        this.getDataStatusSummary(form);
        this.setState({
            selectedSiteAccessId: id,
            selectedSitteCode: code,
            historyViewLoging: false
        })


    }
    downloadDocumnet = (id) => {
        //console.log(id);
        //console.log("sds");
        var form = new FormData();
        //form.append("siteAccessPermitionId", id);
        this.getDatadownloadAccessReqDocument(id);


    }
    showHistortView = () => {
        this.setState({
            selectedSiteAccessId: ""
        })


    }
    requestView = (id) => {
        var form = new FormData();
        form.append("accessPermitionRequestId", id);
        this.getDataAccessPermitionRequestById(form);
        //console.log(id);


    }
    statusLog = (statusId, accessPermissionRequestId, createOn) => {
        var form = new FormData();
        form.append("accessPermissionRequestId", accessPermissionRequestId);
        form.append("statusId", statusId);


        this.getDataLogByStatusId(form, createOn)
        //console.log("sdsddd")


    }
    getDatadownloadAccessReqDocument = async (data) => {
        //console.log(data);

        var res = await genareteAccessReqDocument(data);
        //console.log("genareteAccessReqDocument");
        //console.log(res);
        if (res.success) {
            this.setState({
                downloadloding: true
            })

        }
    };
    getDataLogByStatusId = async (data, createOn) => {
        //console.log(data);
        this.setState({
            selectedstatusLog: [],
            slectExtraDataNot: null,
            slectExtraDataRqType: null,
            slectApproveImage: null,
            selectedstatusLogCreateUser: "",
            selectedstatusLogCreateTime: "",
            selectedstatusLogCreateStatus: "",
        })

        var res = await getlogByStatusId(data);
        //console.log("getlogByStatusId");
        // //console.log(res.data[0].statusName);
        if (res.success) {
            res?.data.filter(creON => creON.createdOn == createOn).map((item) => (

                this.setState({
                    selectedstatusLog: res.data,
                    slectExtraDataRqType: item.extraData?.requestType,
                    slectApproveImage: item.extraData?.approveImage,
                    slectExtraDataNot: item.extraData?.note,
                    selectedstatusLogCreateUser: item.createdUserName,
                    selectedstatusLogCreateTime: item.createdOn,
                    selectedstatusLogCreateStatus: item.statusName,
                    showModal: true
                })
            ))


        }
    };
    getAccessApprovedFromSite = async (data) => {
        var res = await accessApprovedFromSite(data);
        //console.log("accessApprovedFromSite");
        //console.log(res);

        // //console.log(res.data.visitors);
        if (res.success) {
            this.setState({
                showModal3: false,
                showModal4: false,
                showModal2: false,
                showModal: false,
                showModal5: false,
                reqType: "",
                reqDescription: ""
            })

            var dataSet = {
                'pageNo': count,
                'recodesPerPage': '',
                'duration': '20',
                'statusIds': []
            }
            this.getDataUserJobRequests(dataSet);
            this.getDataUserAllRequests(dataSet);
        }
    };
    getAccessRejectFromSite = async (data) => {
        var res = await accessRejectFromSite(data);
        //console.log("accessRejectFromSite");
        //console.log(res);

        // //console.log(res.data.visitors);
        if (res.success) {
            this.setState({
                showModal3: false,
                showModal4: false,
                showModal2: false,
                showModal: false,
                showModal5: false,
                reqType: "",
                reqDescription: ""
            })

            window.location.reload(false);
        }
    };
    getDatarequestSendToSite = async (data) => {
        var res = await requestSendToSite(data);
        //console.log("requestSendToSite");
        //console.log(res);

        // //console.log(res.data.visitors);
        if (res.success) {
            this.setState({
                showModal3: false,
                showModal4: false,
                showModal2: false,
                showModal: false,
                showModal5: false,
                reqType: "",
                reqDescription: ""
            })
            var dataSet = {
                'pageNo': count,
                'recodesPerPage': '',
                'duration': '20',
                'statusIds': []
            }
            this.getDataUserJobRequests(dataSet);
            this.getDataUserAllRequests(dataSet);
            // window.location.reload(false);


        }
    };
    getRequestRejectByHandler = async (data) => {
        var res = await requestRejectByHandler(data);
        //console.log("requestRejectByHandler");
        //console.log(res);

        // //console.log(res.data.visitors);
        if (res.success) {
            this.setState({
                showModal3: false,

                showModal4: false,
                showModal2: false,
                showModal: false,
                showModal5: false,
                reqType: "",
                reqDescription: ""
            })
            var dataSet = {
                'pageNo': count,
                'recodesPerPage': '',
                'duration': '20',
                'statusIds': []
            }
            this.getDataUserJobRequests(dataSet);
            this.getDataUserAllRequests(dataSet);


        }
    };

    getDataAccessRestrictions = async (data) => {
        //console.log(data);
        var res = await getAccessRestrictions(data);
        //console.log("getAccessRestrictions");
        //console.log(res.data);
        if (res.success) {
            this.setState({
                reqRestrictions: res.data
            })
        }
    };
    getDataAccessPermitionRequestById = async (data) => {
        //console.log(data);
        var res = await getAccessPermitionRequestById(data);
        console.log("getAccessPermitionRequestById");

        console.log(res.data);
        if (res.success) {

            if (res.data.accessPermitionStatus.id == 63) {
                var form = new FormData();

                // form.append('siteId', res.data?.sites?.id);
                // form.append('duration', res.data?.workDuration);
                // form.append('requestDate', res.data?.accessDate);
                // form.append('requestTime', '01:51');
                //var input_time = "10:46";
                //console.log()
                form.append('siteId', res.data.sites[0].id);
                form.append('duration', (res.data.workDuration / 60));
                form.append('requestDate', res.data?.accessDate);
                form.append('requestTime', moment(res.data?.accessStartOn, "HH:mm:ss").format('HH:mm'));
                this.getDataAccessRestrictions(form);


            }

            // if (res.data.accessPermitionStatus.id == 60 || res.data.accessPermitionStatus.id == 63) {
            this.setState({
                showModal2: true,
                requestViewData: res.data,
                requestViewDatavisitor: res.data?.visitors,
                requestViewDataEqu: res.data?.equipments,
                requestViewDataIns: res.data?.instruments,
                requestViewDataVhe: res.data?.accessVehicles,
                requestViewDataSites: res.data.sites[0],
                accessPermitionStatusID: res.data.accessPermitionStatus.id,
                accessStarttime: res.data.accessDate + ' ' + res.data.accessStartOn


            });

            // }



        }
    };
    getDataStatusSummary = async (data) => {
        this.setState({
            selectedSitteAccessHistory: [],
            selectedSitteAccessGroup: '',
            selectedSitteAccessHandlerName: '',
            selectedSitteAccessHandlerConstat: '',
        })
        //console.log(data);
        var res = await getStatusSummary(data);
        //console.log("getStatusSummary");
        //console.log(res.data);
        if (res.success) {
            //console.log(res.data[0]['handlerContactNumber']);


            this.setState({
                selectedSitteAccessHistory: res.data,
                selectedSitteAccessGroup: res.data[0]['groupName'],
                selectedSitteAccessHandlerName: res.data[0]['handlerName'],
                selectedSitteAccessHandlerConstat: res.data[0]['handlerContactNumber'],

            });
        }
    };

    getDataUserJobRequests = async (data) => {
        this.setState({
            loading:true,
        })
        var data1 = new FormData();
        //console.log('this.state.pageNo');
        //console.log(this.state.pageNo);
        //console.log(this.state.recodesPerPage_1);
        //console.log(this.state.regionIds);
        //console.log(this.state.depotIds);
        //console.log(this.state.siteIds);
        //console.log(this.state.statusIds);
        data1.append('duration', this.state.duration);
        data1.append('statusIds', this.state.statusIds);
        data1.append('siteIds', this.state.siteIds);
        data1.append('depotIds', this.state.depotIds);
        data1.append('regionIds', this.state.regionIds);
        data1.append('pageNo', this.state.pageNo);
        data1.append('recodesPerPage', this.state.recodesPerPage_1);
        data1.append('requestUserId', this.state.selectUser);
        var res = await gethandlerJobRequests(data1);
        //console.log("gethandlerJobRequests");
        //console.log(res.data);
        if (res.success) {
            //console.log(res.data);
            var newOb = res.data.data
            console.log(newOb);
            this.setState({
                initLoading: false,
                loading:false,
                data: newOb,
                list: newOb,
                recodesPerPage: res.data.pageCount
            });
            // this.setState({
            //     allSite_loading: false,
            //     allSite: res.data,
            // });
        }
    };
    getDataUserAllRequests = async (data) => {
        this.setState({
            loading:true,
        })
        var data1 = new FormData();
        //console.log('this.state.pageNo');
        //console.log(this.state.pageNo);
        //console.log(this.state.recodesPerPage_1);
        //console.log(this.state.regionIds);
        //console.log(this.state.depotIds);
        //console.log(this.state.siteIds);
        //console.log(this.state.statusIds);
        data1.append('duration', this.state.duration);
        data1.append('statusIds', this.state.statusIds);
        data1.append('siteIds', this.state.siteIds);
        data1.append('depotIds', this.state.depotIds);
        data1.append('regionIds', this.state.regionIds);
        data1.append('pageNo', this.state.pageNo2);
        data1.append('recodesPerPage', this.state.recodesPerPage_1);
        data1.append('requestUserId', this.state.selectUser);

        var res = await gethandlerAllRequests(data1);
        //console.log("gethandlerAllRequests");

        if (res.success) {
            //console.log(res.data);
            var newOb = res.data.data
            console.log(newOb);
            this.setState({
                initLoading2: false,
                loading:false,
                data2: newOb,
                list2: newOb,
                recodesPerPage2: res.data.pageCount
            });
            // this.setState({
            //     allSite_loading: false,
            //     allSite: res.data,
            // });
        }
    };
    onDrawerClose = () => {
        this.setState({
            totalJobOpen: false
        })
    }

    render() {
        const { initLoading, loading, list, initLoading2, loading2, list2 } = this.state;
        const loadMore =
            !initLoading && !loading ? (
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: 12,
                        height: 32,
                        lineHeight: '32px',
                    }}
                >
                    <div class="row">

                        <div class=" col-md-12">
                            <div class="dataTables_paginate paging_simple_numbers" id="row_callback_paginate" style={{ display: "flex", textAlign: "end", justifyContent: "end" }}>
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" id="row_callback_previous">
                                        <a onClick={() => this.previousBtnClick1()} aria-controls="row_callback" data-dt-idx="0" tabindex="0" class="page-link">Previous</a>
                                    </li>
                                    <li class="paginate_button page-item ">
                                        <a aria-controls="row_callback" data-dt-idx="1" tabindex="0" class="page-link">{this.state.pageNo + 1}/{this.state.recodesPerPage}
                                        </a>
                                    </li>

                                    <li class="paginate_button page-item next" id="row_callback_next">
                                        <a onClick={() => this.nextBtnClick1()} aria-controls="row_callback" data-dt-idx="7" tabindex="0" class="page-link">Next
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null;
        const loadMore2 =
            !initLoading2 && !loading2 ? (
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: 12,
                        height: 32,
                        lineHeight: '32px',
                    }}
                >
                    <div class="row">

                        <div class=" col-md-12">
                            <div class="dataTables_paginate paging_simple_numbers" id="row_callback_paginate" style={{ display: "flex", textAlign: "end", justifyContent: "end" }}>
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" id="row_callback_previous">
                                        <a onClick={() => this.previousBtnClick2()} aria-controls="row_callback" data-dt-idx="0" tabindex="0" class="page-link">Previous</a>
                                    </li>
                                    <li class="paginate_button page-item ">
                                        <a aria-controls="row_callback" data-dt-idx="1" tabindex="0" class="page-link">{this.state.pageNo2 + 1}/{this.state.recodesPerPage2}
                                        </a>
                                    </li>

                                    <li class="paginate_button page-item next" id="row_callback_next">
                                        <a onClick={() => this.nextBtnClick2()} aria-controls="row_callback" data-dt-idx="7" tabindex="0" class="page-link">Next
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null;
        const IconText = ({ icon, text }) => (
            <Space>
                {React.createElement(icon)}
                {text}
            </Space>
        );

        const columns123 = [
            {
                title: "Code",
                // dataIndex: "siteId",
                //	defaultSortOrder: "descend",


                render: (text, record, index) => <span> <u><Link onClick={() => this.requestView(text.id)}>{text.accessPermissionCode}</Link></u></span>,
            },
            {
                title: "# Site Id",
                // dataIndex: "siteId",
                //	defaultSortOrder: "descend",


                render: (text, record, index) => <span> {text.siteId}</span>,
            },
            {
                title: "Site",

                key: "siteName",

                render: (item) => <span  >{item.siteName}</span>,

            },
            {
                title: "Operation Type",

                key: "operationTypeName",

                render: (item) => <span  >{item.operationTypeName}</span>,

            },
            {
                title: "Status",

                key: "statusName",

                render: (item) => <span class="badge rounded-pill noti-icon-badge" style={{ textAlign: "center", fontSize: 10, backgroundColor: item.statusColor, color: "black" }}>{item.statusName}</span>,

            },
            {
                title: "Group Name",

                key: "groupName",

                // render: (item) => <span  >{item.groupName}</span>,\
                render: (item) => <>
                    <Tag color="gray" key={item}>
                        <span >{item.groupName}</span>
                    </Tag>
                </>,



            },
            {
                title: "Requester",

                key: "userName",

                render: (item) => <span  >{item.userName}</span>,

            },

            {
                title: "Action",
                key: "ContactNo",
                align: 'center',



                render: (item) => <><a title="History View" data-bs-toggle="tab" href='#' onClick={() => this.historyView(item.id, item.accessPermissionCode)} key="list-loadmore-more" ><i class="fa fa-history"></i></a>  {item.statusId == 60 ? (<Link to={'/site-access/edit-handler/' + item.id} key="list-loadmore-edit">
                    <i class="fa fa-pen"> </i></Link>) : ('')}</>,


            },

            // {
            // 	title: 'Action',

            // 	key: 'x',
            // 	render: (item) => <a href='#' onClick={() => this.getSiteAccessDetails(item.id)}><i data-feather="home" class="fa fa-eye"></i></a>,
            //   },

        ];
        return (

            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="page-title-box">
                            <div class="row">
                                <div class="col">
                                    <h4 class="page-title">Handler</h4>
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Site Access </a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Handler</a></li>

                                    </ol>
                                </div>
                                <div class="col-auto align-self-center">
                                    <div class="row">

                                        <div class="col-md-1">
                                            <button type="button" style={{ marginLeft: 10 }} class="btn btn-danger" onClick={() => this.viewFilterClick()}><i class="fas fa-filter"></i></button>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">

                    <Collapse isOpen={this.state.isOpenFillter}>
                        <div className="row">
                            <div class="card">

                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-3">


                                            <Select
                                                mode="multiple"
                                                showSearch
                                                name="regionId"
                                                onChange={(e) => this.searchRegionmChange(e)}
                                                placeholder="Region"
                                                style={{ width: "100%" }}

                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                            // options={this.state.regionsList}

                                            >
                                                {this.state.regionsList.map((item, index) => {
                                                    return <Option value={item.value}>{item.label}</Option>;
                                                })}
                                            </Select>
                                        </div>
                                        <div class="col-md-3">

                                            <Select
                                                mode="multiple"
                                                showSearch
                                                name="depotId"
                                                onChange={(e) => this.searchDepottemChange(e)}
                                                placeholder="Depot"
                                                style={{ width: "100%" }}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                            // options={this.state.depotList}

                                            >
                                                {this.state.depotList.map((item, index) => {
                                                    return <Option value={item.value}>{item.label}</Option>;
                                                })}
                                            </Select>
                                        </div>
                                        <div class="col-md-3">

                                            <Select
                                                mode="multiple"
                                                placeholder="Site"
                                                style={{ width: "100%" }}
                                                // options={this.state.allSite} 
                                                loading={this.state.allSite_loading}

                                                name="siteId"
                                                onChange={(e) => this.searchSiteemChange(e)}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                            >
                                                {this.state.allSite.map((item, index) => {
                                                    return <Option value={item.value}>{item.label}</Option>;
                                                })}
                                            </Select>
                                        </div>
                                        <div class="col-md-3">

                                            <Select
                                                mode="multiple"
                                                placeholder="Status"
                                                style={{ width: "100%" }}
                                                //options={this.state._StatusArry}
                                                name="StatusId"
                                                onChange={(e) => this.searchStatustemChange(e)}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                            >
                                                {this.state._StatusArry.map((item, index) => {
                                                    return <Option value={item.value}>{item.label}</Option>;
                                                })}
                                            </Select>
                                        </div>



                                    </div>
                                    <br></br>
                                    <div className='row'>
                                        <div class="col-md-3">

                                            <Select
                                              showSearch
                                                allowClear
                                                style={{ width: "100%" }}
                                                placeholder="Requester"

                                                onChange={(e) => this.handleChangeUser(e)}

                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                            >
                                                {this.state.all_users &&
                                                    this.state.all_users.map((item) => (
                                                        <Option key={item.id} value={item.id}>
                                                            {item.email}
                                                        </Option>
                                                    ))}
                                            </Select>
                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div class="col-md-3" style={{ marginTop: 10 }}>
                                            <button type="button" class="btn btn-primary" onClick={() => this.filterBtnClick()}> Filter</button>
                                            <button type="button" style={{ marginLeft: 5 }} class="btn btn-dark" onClick={() => this.clearBtnClick()}>Clear Filter</button>
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </Collapse>
                    <div class="col-lg-12 col-xl-12">
                        <div class="card">
                            <div class="card-header">
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <h4 class="card-title">Site Access Permission</h4>
                                    </div>
                                    <div class="col-md-4 nav-tabs-custom text-center">
                                        <ul class="nav nav-tabs" role="tablist">
                                            <li onClick={this.showHistortView} class="nav-item">
                                                <a class="nav-link text-center active" data-bs-toggle="tab" href="#cu_home" role="tab" aria-selected="true"><i class="la la-home d-block"></i>Running Job</a>
                                            </li>
                                            <li onClick={this.showHistortView} class="nav-item" style={{ width: "112px" }}>
                                                <a class="nav-link text-center" data-bs-toggle="tab" href="#cu_profile" role="tab" aria-selected="false"><i class="la la-calendar d-block" ></i>Total Job</a>
                                            </li>

                                        </ul>
                                    </div>
                                    <div className='col-md-4' style={{ textAlign: "end" }}>

                                    </div>

                                </div>

                            </div>
                            <div class="card-body">





                                <div class="tab-content">
                                    <div class="tab-pane p-3 active" id="cu_home" role="tabpanel">
                                        <Table
                                            size='small'
                                            fontSize={9}
                                            columns={columns123}
                                            bordered
                                            loadMore={loadMore2}
                                            pagination={false}
                                            loading={this.state.loading}
                                            dataSource={this.state.list}
                                        />
                                        <div class="row">

                                            <div class=" col-md-12">
                                                <div class="dataTables_paginate paging_simple_numbers" id="row_callback_paginate" style={{ display: "flex", textAlign: "end", justifyContent: "end" }}>
                                                    <ul class="pagination">
                                                        <li class="paginate_button page-item previous" id="row_callback_previous">
                                                            <a onClick={() => this.previousBtnClick1()} aria-controls="row_callback" data-dt-idx="0" tabindex="0" class="page-link">Previous</a>
                                                        </li>
                                                        <li class="paginate_button page-item ">
                                                            <a aria-controls="row_callback" data-dt-idx="1" tabindex="0" class="page-link">{this.state.pageNo + 1}/{this.state.recodesPerPage}
                                                            </a>
                                                        </li>

                                                        <li class="paginate_button page-item next" id="row_callback_next">
                                                            <a onClick={() => this.nextBtnClick1()} aria-controls="row_callback" data-dt-idx="7" tabindex="0" class="page-link">Next
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane p-3" id="cu_profile" role="tabpanel">
                                        <Table
                                            size='small'
                                            fontSize={9}
                                            columns={columns123}
                                            bordered
                                            loadMore={loadMore2}
                                            pagination={false}
                                            loading={this.state.loading}
                                            dataSource={this.state.list2}
                                        />
                                        <Drawer title={"Site Access Permission Summary"} style={{ zIndex: 0 }} width={"50%"} placement="right" onClose={() => this.onDrawerClose()} visible={this.state.totalJobOpen}>
                                            {this.state.selectedSiteAccessId ? (
                                                <div class="col-lg-12">
                                                    {this.state.historyViewLoging ? (<ReactLoading
                                                        type={"bars"}
                                                        color={"#03fc4e"}
                                                        height={86}
                                                        width={100}

                                                    // style={{color:"#03fc4e",flex: 0.1}} 
                                                    />) : (<div class="card">

                                                        <div class="card-body">
                                                            <div class="table-responsive">
                                                                <table class="table table-dark2 mb-0">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Job Code </td>
                                                                            <td>: {this.state.selectedSitteCode}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td> Group Name</td>
                                                                            <td>: {this.state.selectedSitteAccessGroup}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Handler Name </td>
                                                                            <td>: {this.state.selectedSitteAccessHandlerName}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td> Handler Contact</td>
                                                                            <td>: <i class="fa fa-phone"> </i> {this.state.selectedSitteAccessHandlerConstat}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <hr></hr>
                                                            <div className='row'>
                                                                <div class="col-lg-12">
                                                                    <ul class="list-group">

                                                                        {this.state.selectedSitteAccessHistory.map((item) => (
                                                                            <div style={{ padding: 2 }}>
                                                                                {item.isCompleate ? (
                                                                                    <Popup trigger={<a style={{ borderRadius: 5 }}> <li class="list-group-item list-group-item-success historyView1" onClick={() => this.statusLog(item.statusId, item.accessPermissionRequestId, item.createdOn)}>
                                                                                        <p><h5>{item.statusName}</h5></p>
                                                                                        <p style={{ marginBottom: 0 }} >By : {item.createdUserName}</p>
                                                                                        <p>Create On : {moment(item.createdOn).format('YYYY-MM-DD HH:mm:ss')}</p>


                                                                                    </li></a>} modal nested>
                                                                                        {close => (
                                                                                            <div class="card" >
                                                                                                <div class="card-header">
                                                                                                    <h4 class="card-title">History</h4>



                                                                                                </div>
                                                                                                <div class="card-body">
                                                                                                    <table class="table table-dark1 mb-0">
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td>Create User </td>
                                                                                                                <td>:{this.state.selectedstatusLogCreateUser}</td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>Create time</td>
                                                                                                                <td>:{moment(this.state.selectedstatusLogCreateTime).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>Current Status </td>
                                                                                                                <td>:{this.state.selectedstatusLogCreateStatus}</td>
                                                                                                            </tr>
                                                                                                            {this.state.slectExtraDataNot != null ? (<tr>
                                                                                                                <td>Note </td>
                                                                                                                <td>:{this.state.slectExtraDataNot.split('\n').map(function (item, idx) {
                                                                                                                    return (
                                                                                                                        <span>
                                                                                                                            {item}
                                                                                                                            <br />
                                                                                                                        </span>
                                                                                                                    )
                                                                                                                })} </td>
                                                                                                            </tr>) : ("")}
                                                                                                            {this.state.slectExtraDataRqType != null ? (<tr>
                                                                                                                <td>Request Type</td>
                                                                                                                <td>:{this.state.slectExtraDataRqType}</td>
                                                                                                            </tr>) : ("")}

                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                    <br></br>
                                                                                                    {this.state.slectApproveImage != null ? (

                                                                                                        <div className="card">
                                                                                                            <div className="card-header">
                                                                                                                <h4 className="card-title">Related Documents:</h4>
                                                                                                            </div>
                                                                                                            <div className="card-body" style={{ textAlign: "center" }}>
                                                                                                                <p style={{ fontSize: 10 }}>  please click the following button to download the document</p>

                                                                                                                <div className='row' style={{ padding: 24 }}>
                                                                                                                    <div className='col-md-3'>

                                                                                                                    </div>
                                                                                                                    <div className='col-md-6'>
                                                                                                                        <a href={'https://storage.googleapis.com/dns_storage_v2/requestimages/' + this.state.slectApproveImage} target="_blank" class="btn btn-primary">Download</a>





                                                                                                                    </div>
                                                                                                                    <div className='col-md-3'>

                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    ) : ("")}
                                                                                                    <br></br>
                                                                                                    <div className='row'>
                                                                                                        <div className='row' style={{ position: 'absolute', bottom: 10 }}>

                                                                                                            <div className='col-md-9'>

                                                                                                            </div>
                                                                                                            <div className='col-md-3' style={{ textAlign: 'end' }}>

                                                                                                                <button
                                                                                                                    className="button"
                                                                                                                    class="btn btn-soft-primary btn-sm"
                                                                                                                    onClick={() => {
                                                                                                                        console.log('modal closed ');
                                                                                                                        close();
                                                                                                                    }}
                                                                                                                >
                                                                                                                    close
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </div>


                                                                                                    </div>

                                                                                                </div>


                                                                                            </div>
                                                                                        )}
                                                                                    </Popup>
                                                                                ) : ("")} </div>

                                                                        ))}


                                                                    </ul>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='col-md-6'>

                                                        </div>
                                                    </div>)}



                                                </div>) : ("")}
                                        </Drawer>
                                        <div class="row">

                                            <div class=" col-md-12">
                                                <div class="dataTables_paginate paging_simple_numbers" id="row_callback_paginate" style={{ display: "flex", textAlign: "end", justifyContent: "end" }}>
                                                    <ul class="pagination">
                                                        <li class="paginate_button page-item previous" id="row_callback_previous">
                                                            <a onClick={() => this.previousBtnClick2()} aria-controls="row_callback" data-dt-idx="0" tabindex="0" class="page-link">Previous</a>
                                                        </li>
                                                        <li class="paginate_button page-item ">
                                                            <a aria-controls="row_callback" data-dt-idx="1" tabindex="0" class="page-link">{this.state.pageNo2 + 1}/{this.state.recodesPerPage2}
                                                            </a>
                                                        </li>

                                                        <li class="paginate_button page-item next" id="row_callback_next">
                                                            <a onClick={() => this.nextBtnClick2()} aria-controls="row_callback" data-dt-idx="7" tabindex="0" class="page-link">Next
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>


                </div>
                <div className='col-md-6'>
                    <Modal

                        isOpen={this.state.showModal2}
                        contentLabel="Minimal Modal Example"
                        // style={{
                        //     overlay: {

                        // 		zIndex: 10000
                        //     },
                        //     content: {
                        // 		right: '15%',
                        // 		left: '15%',
                        // 		top: '9px',
                        // 		bottom:'9px'
                        //     }
                        // }}
                        style={{
                            overlay: {

                                // position: 'fixed',
                                // top: 0,
                                // left: 0,
                                // right: 0,
                                // bottom: 0,
                                // backgroundColor: 'rgba(255, 255, 255, 0.75)'
                                zIndex: 1001
                            },
                            content: {
                                right: '15%',
                                left: '15%',
                                top: '9px',
                                bottom: '9px'
                            }
                        }}
                    //    className="modal"
                    >
                        <div class="col-lg-12 mx-auto">

                            <div className="card">
                                <div className="card-header">
                                    <div class="row">
                                        <div class="col-md-4"></div>
                                        <div class="col-md-4 align-self-center" style={{ textAlign: "center" }}>

                                            <i class="mdi" style={{ fontSize: 21 }}>{this.state.requestViewData.handlerName}</i>
                                            <p class="text-muted mb-0"><h6 class="mb-0">{moment(this.state.requestViewData.createdOn).format('YYYY-MM-DD HH:mm:ss')}</h6></p>

                                            <p class="text-muted mb-0"><span class="badge  rounded-pill noti-icon-badge" style={{ textAlign: "center", fontSize: 10, color: "black", backgroundColor: this.state.requestViewData.accessPermitionStatus?.color }}>{this.state.requestViewData.accessPermitionStatus?.name}</span></p>


                                            {/* <img src="assets/images/logo-sm.png" alt="logo-small" class="logo-sm me-1" height="24">                                            
                                            <img src="assets/images/logo-dark.png" alt="logo-large" class="logo-lg logo-dark" height="20">  
                                            <img src="assets/images/logo.png" alt="logo-large" class="logo-lg logo-light" height="20">  */}

                                        </div>
                                        <div class="col-md-4"></div>


                                    </div>
                                </div>
                            </div>


                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Job Details:</h4>
                                </div>
                                <div className="card-body">
                                    <div class="row">

                                        <div class="col-md-12">
                                            <table>
                                                <tr>
                                                    <th>Site Group Name</th>
                                                    <td>{this.state.requestViewData.accessPerminionGroupName}</td>
                                                </tr>
                                                <tr>
                                                    <th>Operation Type</th>
                                                    <td>{this.state.requestViewData?.operationType?.name}</td>
                                                </tr>
                                                {/* <tr>
                                                    <th>Schedule Type</th>
                                                    <td>{this.state.requestViewData?.scheduleType?.name}</td>
                                                </tr> */}
                                                {this.state.requestViewData?.scheduleStartOn != null ? (<tr>
                                                    <th>Schedule Start On</th>
                                                    <td>{this.state.requestViewData?.scheduleStartOn}</td>
                                                </tr>) : ("")}

                                                {this.state.requestViewData?.scheduleStartOn != null ? (<tr>
                                                    <th>Schedule End On</th>
                                                    <td>{this.state.requestViewData?.scheduleEndOn}</td>
                                                </tr>) : ("")}
                                                {this.state.requestViewData?.accessDate != null ? (<tr>
                                                    <th>Start On </th>
                                                    <td>{this.state.requestViewData?.accessDate} {this.state.requestViewData?.accessStartOn}</td>
                                                </tr>) : ("")}
                                                {this.state.requestViewData?.accessEndDate != null ? (<tr>
                                                    <th>End On </th>
                                                    <td>{this.state.requestViewData?.accessEndDate} {'23:59:59'}</td>
                                                </tr>) : ("")}

                                                {this.state.requestViewData?.user != null ? (<tr>
                                                    <th>Requester Name</th>
                                                    <td>{this.state.requestViewData?.user?.name}</td>
                                                </tr>) : ("")}
                                                {this.state.requestViewData?.user != null ? (<tr>
                                                    <th>Requester Email</th>
                                                    <td>{this.state.requestViewData?.user?.email}</td>
                                                </tr>) : ("")}
                                                {this.state.requestViewData?.user != null ? (<tr>
                                                    <th>Requester Contact</th>
                                                    <td>{this.state.requestViewData?.user?.mobileNumber}</td>
                                                </tr>) : ("")}

                                                <tr>
                                                    <th>Handler Name </th>
                                                    <td>{this.state.requestViewData?.handlerName}</td>
                                                </tr>
                                                <tr>
                                                    <th> Handler Contact</th>
                                                    <td><i class="fa fa-phone"> </i> {this.state.requestViewData?.handlerContactNumber}</td>
                                                </tr>
                                            </table>
                                            {/* <div class="row">
                                                <div className='col-md-4'><h6><b>Access Date :</b> {this.state.requestViewData.accessDate}</h6></div>
                                                <div className='col-md-4'><h6><b>Access Start On:</b>{this.state.requestViewData.accessStartOn}</h6></div>
                                                <div className='col-md-4'><h6><b>Duration (Hours):</b> {this.state.requestViewData.workDuration}</h6></div>




                                            </div> */}
                                        </div>


                                    </div>
                                    <div className='row'>
                                        <div class="row justify-content-center" style={{ fontSize: 12 }}>
                                            {/* {this.state.requestViewDatavisitor?==null?("111111"):("22222")} */}
                                            {this.state.requestViewDatavisitor[0] != null ? (<div class="col-lg-12">
                                                <h6 class="mt-4">Team Members:</h6>

                                                <div class="table-responsive project-invoice">
                                                    <table class="table table-bordered mb-0">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>NIC</th>
                                                                <th>Mobile No</th>


                                                                <th>Company</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.requestViewDatavisitor.filter(vito3 => vito3.active == true).map((item) => (
                                                                <tr>
                                                                    <td>

                                                                        {item.name}
                                                                    </td>
                                                                    <td>{item.nic}</td>
                                                                    <td>{item.mobileNo}</td>


                                                                    <td>{item?.company}</td>
                                                                </tr>
                                                            ))}


                                                        </tbody>
                                                    </table>
                                                </div>
                                                <ul class="ps-3">



                                                </ul>
                                            </div>) : ("")}
                                            {this.state.requestViewDataEqu[0] != null ? (<div class="col-lg-12">
                                                <h6 class="mt-4">Equipments:</h6>

                                                <div class="table-responsive project-invoice">
                                                    <table class="table table-bordered mb-0">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Name</th>
                                                                <th>Serial No</th>
                                                                <th>Qty</th>
                                                                <th>Description</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.requestViewDataEqu.filter(equ => equ.active == true).map((item, key) => (
                                                                <tr>
                                                                    <td>

                                                                        {++key}
                                                                    </td>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.serialNo}</td>
                                                                    <td>{item.qnty}</td>
                                                                    <td>{item.discription}</td>
                                                                </tr>
                                                            ))}


                                                        </tbody>
                                                    </table>
                                                </div>
                                                <ul class="ps-3">



                                                </ul>
                                            </div>) : ("")}
                                            {this.state.requestViewDataIns[0] != null ? (<div class="col-lg-12">
                                                <h6 class="mt-4">Instruments:</h6>

                                                <div class="table-responsive project-invoice">
                                                    <table class="table table-bordered mb-0">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Name</th>
                                                                <th>Serial No</th>
                                                                <th>Qty</th>
                                                                <th>Description</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.requestViewDataIns.filter(ins3 => ins3.active == true).map((item, key) => (
                                                                <tr>
                                                                    <td>

                                                                        {++key}
                                                                    </td>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.serialNo}</td>
                                                                    <td>{item.qnty}</td>
                                                                    <td>{item.discription}</td>
                                                                </tr>
                                                            ))}


                                                        </tbody>
                                                    </table>
                                                </div>
                                                <ul class="ps-3">



                                                </ul>
                                            </div>) : ("")}

                                            {this.state.requestViewDataVhe[0] != null ? (<div class="col-lg-12">
                                                <h6 class="mt-4">Access Vehicles:</h6>

                                                <div class="table-responsive project-invoice">
                                                    <table class="table table-bordered mb-0">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th>#</th>
                                                                <th>VehicleNo</th>
                                                                <th>Vehicle Type </th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.requestViewDataVhe.filter(vhe3 => vhe3.active == true).map((item, key) => (
                                                                <tr>
                                                                    <td>

                                                                        {++key}
                                                                    </td>
                                                                    <td>{item.vehicleNo}</td>
                                                                    <td>{item.vehicleType}</td>

                                                                </tr>
                                                            ))}


                                                        </tbody>
                                                    </table>
                                                </div>
                                                <ul class="ps-3">



                                                </ul>
                                            </div>) : ("")}
                                        </div>
                                    </div>

                                    <div class="row">

                                    </div>

                                    <div class="row">

                                        {this.state.requestViewData.projectName != null && this.state.requestViewData.projectName != '' ? (
                                            <div class="col-lg-6">
                                                <h6 class="mt-4">Project Name:</h6>
                                                <ul class="ps-3">
                                                    <li><small class="font-12">{this.state.requestViewData.projectName} </small></li>

                                                </ul>
                                            </div>
                                        ) : ('')}
                                        {this.state.requestViewData.scopeOfWork != null && this.state.requestViewData.scopeOfWork != '' ? (
                                            <div class="col-lg-6">
                                                <h6 class="mt-4">Scope Of Work:</h6>
                                                <ul class="ps-3">
                                                    <li><small class="font-12">{this.state.requestViewData.scopeOfWork} </small></li>

                                                </ul>
                                            </div>
                                        ) : ('')}
                                        {this.state.requestViewData.downTimeRequiredDate != null && this.state.requestViewData.downTimeRequiredDate != '' ? (
                                            <div class="col-lg-6">
                                                <h6 class="mt-4">Down Time Required Date:</h6>
                                                <ul class="ps-3">
                                                    <li><small class="font-12">{this.state.requestViewData.downTimeRequiredDate} </small></li>

                                                </ul>
                                            </div>
                                        ) : ('')}
                                        {this.state.requestViewData.nameOfTeamLeader != null && this.state.requestViewData.nameOfTeamLeader != '' ? (
                                            <div class="col-lg-6">
                                                <h6 class="mt-4">Name Of Team Leader:</h6>
                                                <ul class="ps-3">
                                                    <li><small class="font-12">{this.state.requestViewData.nameOfTeamLeader} </small></li>

                                                </ul>
                                            </div>
                                        ) : ('')}
                                        {this.state.requestViewData.contactNoOfTeamLeader != null && this.state.requestViewData.contactNoOfTeamLeader != '' ? (
                                            <div class="col-lg-6">
                                                <h6 class="mt-4">Contact No Of Team Leader:</h6>
                                                <ul class="ps-3">
                                                    <li><small class="font-12">{this.state.requestViewData.contactNoOfTeamLeader} </small></li>

                                                </ul>
                                            </div>
                                        ) : ('')}

                                        {this.state.requestViewData.visitingPurpose != null ? (<div class="col-lg-6">
                                            <h6 class="mt-4">Visiting Purpose:</h6>
                                            <ul class="ps-3">
                                                <li><small class="font-12">{this.state.requestViewData.visitingPurpose?.name} </small></li>

                                            </ul>
                                        </div>) : ('')}
                                        {this.state.requestViewData.accessNeed != null ? (
                                            <div class="col-lg-6">
                                                <h6 class="mt-4">Access Need:</h6>
                                                <ul class="ps-3">
                                                    <li><small class="font-12">{this.state.requestViewData.accessNeed?.name} </small></li>

                                                </ul>
                                            </div>
                                        ) : ('')}

                                        {this.state.requestViewData.workDescription != null ? (
                                            <div class="col-lg-6">
                                                <h6 class="mt-4">Reason:</h6>
                                                <ul class="ps-3">
                                                    <li><small class="font-12">{this.state.requestViewData.workDescription} </small></li>

                                                </ul>
                                            </div>
                                        ) : ('')}


                                        {this.state.requestViewData.referenceNo != null ? (
                                            <div class="col-lg-6">
                                                <h6 class="mt-4">ReferenceNo:</h6>
                                                <ul class="ps-3">
                                                    <li><small class="font-12">{this.state.requestViewData.referenceNo} </small></li>

                                                </ul>
                                            </div>
                                        ) : ('')}

                                        {this.state.requestViewData.remarks != null ? (
                                            <div class="col-lg-6">
                                                <h6 class="mt-4">Remarks:</h6>
                                                <ul class="ps-3">
                                                    <li><small class="font-12">{this.state.requestViewData.remarks} </small></li>

                                                </ul>
                                            </div>
                                        ) : ('')}
                                        {this.state.requestViewData.workArea != null ? (
                                            <div class="col-lg-6">
                                                <h6 class="mt-4">Work Area:</h6>
                                                <ul class="ps-3">
                                                    <li><small class="font-12">{this.state.requestViewData.workArea.name} </small></li>

                                                </ul>
                                            </div>
                                        ) : ('')}
                                        {this.state.requestViewData.workType != null ? (
                                            <div class="col-lg-6">
                                                <h6 class="mt-4">Work Type:</h6>
                                                <ul class="ps-3">
                                                    <li><small class="font-12">{this.state.requestViewData.workType.name} </small></li>

                                                </ul>
                                            </div>
                                        ) : ('')}




                                    </div>
                                </div>
                            </div>

                            {this.state.requestViewData.files != null ? (<div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Relevant Documents:</h4>
                                </div>
                                <div className="card-body">

                                    {this.state.requestViewData.files != null ? (<div class="ant-upload-list ant-upload-list-">

                                        {this.state.requestViewData.files.map((item) => (
                                            <div class="ant-upload-list--container"><div class="ant-upload-list-item ant-upload-list-item-undefined ant-upload-list-item-list-type-">
                                                <div class="ant-upload-list-item-info">
                                                    <span class="ant-upload-span">
                                                        <div class="ant-upload-text-icon">
                                                            <span role="img" aria-label="paper-clip" class="anticon anticon-paper-clip">
                                                                <svg viewBox="64 64 896 896" focusable="false" data-icon="paper-clip" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                                                    <path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"></path>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                        <a target="_blank" href={'https://storage.googleapis.com/dns_storage_v2/requestimages/' + item} class="ant-upload-list-item-name" title={item}>{item}</a>
                                                        <span class="ant-upload-list-item-card-actions" style={{ bottom: 2 }}>
                                                            <button title="Download file" type="button" class="ant-btn ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-card-actions-btn">
                                                                <span role="img" aria-label="delete" class="anticon anticon-delete">
                                                                    <DownloadOutlined />
                                                                </span>
                                                            </button>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                            </div>
                                        ))}


                                    </div>) : ('')}




                                </div>
                            </div>) : ('')}

                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Site Details:</h4>
                                </div>
                                <div className="card-body">
                                    <table>
                                        <tr>
                                            <th>Site Name</th>
                                            <td>{this.state.requestViewDataSites.siteName}</td>
                                        </tr>
                                        <tr>
                                            <th>Site ID</th>
                                            <td>{this.state.requestViewDataSites?.siteId}</td>
                                        </tr>
                                        <tr>
                                            <th>Site Handler</th>
                                            <td>{this.state.requestViewData?.handlerName}</td>
                                        </tr>
                                        <tr>
                                            <th>Tower Owner Category</th>
                                            <td>{this.state.requestViewDataSites?.towerOwnerCategory}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            {this.state.accessPermitionStatusID != 60 && this.state.accessPermitionStatusID != 63 ? (<div class="row d-flex justify-content-center">

                                <div class="col-lg-12">

                                    <div class="float-end d-print-none" style={{ paddingLeft: 12, }}>

                                        <button class="btn btn-soft-primary btn-sm" style={{ fontSize: 14 }} onClick={this.handleCloseFullModal}> Close </button>
                                    </div>


                                </div>

                            </div>) : ("")}

                            {this.state.accessPermitionStatusID == 60 ? (<div>
                                {/* <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Related Document:</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className='row'>
                                            <div class="col-lg-12">

                                                <div class="card" style={{ textAlign: "center" }}>

                                                    {this.state.downloadloding ? (<h6>Download Successful  </h6>) : (<p style={{ fontSize: 12 }}>

                                                        preparing to download related document</p>)}
                                                    {this.state.downloadloding ? (<div className='row' style={{ justifyContent: "center" }}>
                                                        <Image
                                                            width={50}
                                                            src="https://icon-library.com/images/success-icon-png/success-icon-png-8.jpg"
                                                        />
                                                    </div>) : ("")}





                                                    <div className='row' style={{ padding: 24 }}>
                                                        <div className='col-md-3'>

                                                        </div>
                                                        <div className='col-md-6'>
                                                            <Button icon={<DownloadOutlined />} type="primary" onClick={() => this.downloadDocumnet(this.state.requestViewData.id)} > Download</Button>



                                                        </div>
                                                        <div className='col-md-3'>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}



                                <div class="row d-flex justify-content-center">

                                    <div class="col-lg-12">

                                        <div class="float-end d-print-none" style={{ paddingLeft: 12, }}>

                                            <button class="btn btn-warning btn-sm" style={{ fontSize: 14 }} onClick={this.handleCloseFullModal}> Close </button>
                                        </div>

                                        <div class="float-end d-print-none" style={{ paddingLeft: 12 }}>

                                            <Popup trigger={<button class="btn btn-danger btn-sm" style={{ fontSize: 14 }} onClick={this.handleRejectFullModal}> Reject </button>} modal nested>
                                                {close => (
                                                    <div class="card">
                                                        <div class="card-header" style={{ backgroundColor: "#950F4A" }}>
                                                            <h4 class="card-title" style={{ color: "white" }}>Site Access Reject</h4>



                                                        </div>
                                                        <div class="card-body">
                                                            <div className='row'>
                                                                <label><b>Request Type: </b></label><br></br>
                                                                <Radio.Group onChange={this.onChengeReqType} value={this.state.reqType}>
                                                                    <Radio value={"SMS"}>SMS</Radio>
                                                                    <Radio value={"Call"}>Call</Radio>
                                                                    <Radio value={"E-Mail"}>E-Mail</Radio>
                                                                    <Radio value={"Letter"}>Letter</Radio>

                                                                </Radio.Group>
                                                            </div>
                                                            <br></br>

                                                            <div className='row'>
                                                                <label><b>Note: </b></label><br></br>
                                                                <textarea id="description" name="description" rows="4" cols="50" onChange={this.handleChangeInsItmeName}></textarea>
                                                            </div>
                                                            <br></br>
                                                            <div className='row'>
                                                                <div class="col-lg-12">

                                                                    <div class="float-end d-print-none" style={{ paddingLeft: 12, }}>

                                                                        <button class="btn btn-soft-primary btn-sm" style={{ fontSize: 14 }} onClick={() => {
                                                                            console.log('modal closed ');
                                                                            close();
                                                                        }}> Close </button>
                                                                    </div>


                                                                    <div class="float-end d-print-none" style={{ paddingLeft: 12 }}>

                                                                        <button class="btn btn-soft-primary btn-sm" style={{ fontSize: 14 }} onClick={this.requestDoneRejct}> Done </button>
                                                                    </div>
                                                                </div>
                                                            </div>



                                                        </div>
                                                    </div>
                                                )}
                                            </Popup>
                                        </div>
                                        <div class="float-end d-print-none" style={{ paddingLeft: 12 }}>

                                            <Popup trigger={<button class="btn btn-info btn-sm" style={{ fontSize: 14 }} onClick={this.requestSendToSite}> Request </button>} modal nested>
                                                {close => (<div class="card">
                                                    <div class="card-header" style={{ backgroundColor: "#359747" }}>
                                                        <h4 class="card-title" style={{ color: "white" }}>Site Access Request</h4>



                                                    </div>
                                                    <div class="card-body">
                                                        <div className='row'>
                                                            <label><b>Request Type: </b></label><br></br>
                                                            <Radio.Group onChange={this.onChengeReqType} value={this.state.reqType}>
                                                                <Radio value={"SMS"}>SMS</Radio>
                                                                <Radio value={"Call"}>Call</Radio>
                                                                <Radio value={"E-Mail"}>E-Mail</Radio>
                                                                <Radio value={"Letter"}>Letter</Radio>

                                                            </Radio.Group>
                                                        </div>
                                                        <br></br>

                                                        <div className='row'>
                                                            <label><b>Note: </b></label><br></br>
                                                            <textarea id="description" name="description" rows="4" cols="50" onChange={this.handleChangeInsItmeName}></textarea>
                                                        </div>
                                                        <br></br>
                                                        <div className='row'>
                                                            <div class="col-lg-12">

                                                                <div class="float-end d-print-none" style={{ paddingLeft: 12, }}>

                                                                    <button class="btn btn-soft-primary btn-sm" style={{ fontSize: 14 }} onClick={() => {
                                                                        console.log('modal closed ');
                                                                        close();
                                                                    }}> Close </button>
                                                                </div>


                                                                <div class="float-end d-print-none" style={{ paddingLeft: 12 }}>

                                                                    <button class="btn btn-soft-primary btn-sm" style={{ fontSize: 14 }} onClick={this.requestDone}> Done </button>
                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div>
                                                </div>)}
                                            </Popup>
                                        </div>
                                    </div>

                                </div>
                                <Modal

                                    isOpen={this.state.showModal4}
                                    contentLabel="Minimal Modal Example"
                                    style={{
                                        overlay: {

                                            zIndex: 10000
                                        },
                                        content: {
                                            position: 'absolute',
                                            width: '50%',
                                            height: '57%',
                                            top: '30px',
                                            left: '350px',
                                            right: '40px',
                                            bottom: '40px',
                                            border: '1px solid #ccc',
                                            background: '#fff',
                                            overflow: 'auto',
                                            WebkitOverflowScrolling: 'touch',
                                            borderRadius: '4px',
                                            outline: 'none',
                                            padding: '0px'
                                        }
                                    }}
                                //    className="modal"
                                >
                                    <div class="card22">
                                        <div class="card-header">
                                            <h4 class="card-title">Add Note 1</h4>



                                        </div>
                                        <div class="card-body">
                                            <div className='row'>
                                                <label><b>Request Type: </b></label><br></br>
                                                <Radio.Group onChange={this.onChengeReqType} value={this.state.reqType}>
                                                    <Radio value={"SMS"}>SMS</Radio>
                                                    <Radio value={"Call"}>Call</Radio>
                                                    <Radio value={"E-Mail"}>E-Mail</Radio>
                                                    <Radio value={"Letter"}>Letter</Radio>

                                                </Radio.Group>
                                            </div>
                                            <br></br>

                                            <div className='row'>
                                                <label><b>Note: </b></label><br></br>
                                                <textarea id="description" name="description" rows="4" cols="50" onChange={this.handleChangeInsItmeName}></textarea>
                                            </div>
                                            <br></br>
                                            <div className='row'>
                                                <div class="col-lg-12">

                                                    <div class="float-end d-print-none" style={{ paddingLeft: 12, }}>

                                                        <button class="btn btn-soft-primary btn-sm" style={{ fontSize: 14 }} onClick={this.requestFromClose}> Close </button>
                                                    </div>


                                                    <div class="float-end d-print-none" style={{ paddingLeft: 12 }}>

                                                        <button class="btn btn-soft-primary btn-sm" style={{ fontSize: 14 }} onClick={this.requestDoneRejct}> Done </button>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                </Modal>
                                <Modal

                                    isOpen={this.state.showModal3}
                                    contentLabel="Minimal Modal Example"
                                    style={{
                                        overlay: {

                                            zIndex: 10000
                                        },
                                        content: {
                                            position: 'absolute',
                                            width: '50%',
                                            height: '57%',
                                            top: '30px',
                                            left: '350px',
                                            right: '40px',
                                            bottom: '40px',
                                            border: '1px solid #ccc',
                                            background: '#fff',
                                            overflow: 'auto',
                                            WebkitOverflowScrolling: 'touch',
                                            borderRadius: '4px',
                                            outline: 'none',
                                            padding: '0px'
                                        }
                                    }}
                                //    className="modal"
                                >
                                    <div class="card22">
                                        <div class="card-header">
                                            <h4 class="card-title">Add Note 2</h4>



                                        </div>
                                        <div class="card-body">
                                            <div className='row'>
                                                <label><b>Request Type: </b></label><br></br>
                                                <Radio.Group onChange={this.onChengeReqType} value={this.state.reqType}>
                                                    <Radio value={"SMS"}>SMS</Radio>
                                                    <Radio value={"Call"}>Call</Radio>
                                                    <Radio value={"E-Mail"}>E-Mail</Radio>
                                                    <Radio value={"Letter"}>Letter</Radio>

                                                </Radio.Group>
                                            </div>
                                            <br></br>

                                            <div className='row'>
                                                <label><b>Note: </b></label><br></br>
                                                <textarea id="description" name="description" rows="4" cols="50" onChange={this.handleChangeInsItmeName}></textarea>
                                            </div>
                                            <br></br>
                                            <div className='row'>
                                                <div class="col-lg-12">

                                                    <div class="float-end d-print-none" style={{ paddingLeft: 12, }}>

                                                        <button class="btn btn-soft-primary btn-sm" style={{ fontSize: 14 }} onClick={this.requestFromClose}> Close </button>
                                                    </div>


                                                    <div class="float-end d-print-none" style={{ paddingLeft: 12 }}>

                                                        <button class="btn btn-soft-primary btn-sm" style={{ fontSize: 14 }} onClick={this.requestDone}> Done </button>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                </Modal>
                            </div>) : ("")}

                            {this.state.accessPermitionStatusID == 63 ? (<div>

                                {this.state.reqRestrictions[0] ? (<div className='row'>
                                    <div class="col-lg-8">
                                        <h6 class="mt-4">Restriction</h6>
                                        <div class="card" style={{ textAlign: "center" }}>





                                            <ul class="list-group">

                                                {this.state.reqRestrictions.map((item) => (
                                                    <li class="list-group-item list-group-item-danger" style={{ padding: 6 }}>
                                                        <p><h5>{item.dayGroup} - {moment(item.date).format('YYYY-MM-DD')}</h5></p>

                                                        <p>Site access restriction have from {item.accessRestictionStartTime} to {item.accessRestictionEndTime}</p>


                                                    </li>
                                                ))}


                                            </ul>


                                        </div>
                                    </div>
                                </div>) : ("")}

                                <div class="row d-flex justify-content-center">

                                    <div class="col-lg-12">

                                        <div class="float-end d-print-none" style={{ paddingLeft: 12, }}>

                                            <button class="btn btn-soft-primary btn-sm" style={{ fontSize: 14 }} onClick={this.handleCloseFullModal}> Close </button>
                                        </div>

                                        <div class="float-end d-print-none" style={{ paddingLeft: 12 }}>

                                            <Popup trigger={<button class="btn btn-danger btn-sm" style={{ fontSize: 14 }} onClick={this.handleRejectFullModal}> Reject </button>} modal nested>
                                                {close => (<div class="card">
                                                    <div class="card-header" style={{ backgroundColor: "#950F4A" }}>
                                                        <h4 class="card-title" style={{ color: "white" }}> Site Access  Reject</h4>



                                                    </div>
                                                    <div class="card-body">
                                                        <div className='row'>
                                                            <label><b>Request Type: </b></label><br></br>
                                                            <Radio.Group onChange={this.onChengeReqType} value={this.state.reqType}>
                                                                <Radio value={"SMS"}>SMS</Radio>
                                                                <Radio value={"Call"}>Call</Radio>
                                                                <Radio value={"E-Mail"}>E-Mail</Radio>
                                                                <Radio value={"Letter"}>Letter</Radio>

                                                            </Radio.Group>
                                                        </div>
                                                        <br></br>
                                                        <div className='row'>
                                                            <label><b>Attach Document: </b></label><br></br>
                                                            <div>
                                                                <input type="file" onChange={this.onFileChange} />

                                                            </div>
                                                        </div>
                                                        <br></br>
                                                        <div className='row'>
                                                            <label><b>Note: </b></label><br></br>
                                                            <textarea id="description" name="description" rows="4" cols="50" onChange={this.handleChangeInsItmeName}></textarea>
                                                        </div>
                                                        <br></br>
                                                        <div className='row'>
                                                            <div class="col-lg-12">

                                                                <div class="float-end d-print-none" style={{ paddingLeft: 12, }}>

                                                                    <button class="btn btn-soft-primary btn-sm" style={{ fontSize: 14 }} onClick={() => {
                                                                        console.log('modal closed ');
                                                                        close();
                                                                    }}> Close </button>
                                                                </div>


                                                                <div class="float-end d-print-none" style={{ paddingLeft: 12 }}>

                                                                    <button class="btn btn-soft-primary btn-sm" style={{ fontSize: 14 }} onClick={this.requestDoneRejct}> Done </button>
                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div>
                                                </div>)}
                                            </Popup>
                                        </div>
                                        <div class="float-end d-print-none" style={{ paddingLeft: 12 }}>

                                            <Popup trigger={<button class="btn btn-primary btn-sm" style={{ fontSize: 14 }} onClick={this.requestSendToSite}> Approve </button>} modal nested>
                                                {close => (<div class="card">
                                                    <div class="card-header" style={{ backgroundColor: "#359747" }}>
                                                        <h4 class="card-title" style={{ color: "white" }}>Site Access Approve</h4>



                                                    </div>
                                                    <div class="card-body">
                                                        <div className='row'>
                                                            <label><b>Request Type: </b></label><br></br>
                                                            <Radio.Group onChange={this.onChengeReqType} value={this.state.reqType}>
                                                                <Radio value={"SMS"}>SMS</Radio>
                                                                <Radio value={"Call"}>Call</Radio>
                                                                <Radio value={"E-Mail"}>E-Mail</Radio>
                                                                <Radio value={"Letter"}>Letter</Radio>

                                                            </Radio.Group>
                                                        </div>
                                                        <br></br>
                                                        <div className='row'>
                                                            <label><b>Attach Document: </b></label><br></br>
                                                            <div>
                                                                <input type="file" onChange={this.onFileChange} />

                                                            </div>
                                                        </div>
                                                        <br></br>
                                                        <div className='row'>
                                                            <label><b>Note: </b></label><br></br>
                                                            <textarea id="description" name="description" rows="4" cols="50" onChange={this.handleChangeInsItmeName}></textarea>
                                                        </div>
                                                        <br></br>
                                                        <div className='row'>
                                                            <div class="col-lg-12">

                                                                <div class="float-end d-print-none" style={{ paddingLeft: 12, }}>

                                                                    <button class="btn btn-soft-primary btn-sm" style={{ fontSize: 14 }} onClick={() => {
                                                                        console.log('modal closed ');
                                                                        close();
                                                                    }}> Close </button>
                                                                </div>


                                                                <div class="float-end d-print-none" style={{ paddingLeft: 12 }}>

                                                                    <button class="btn btn-soft-primary btn-sm" style={{ fontSize: 14 }} onClick={this.requestDone}> Done </button>
                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div>
                                                </div>)}
                                            </Popup>
                                        </div>
                                    </div>

                                </div>
                                <Modal

                                    isOpen={this.state.showModal5}
                                    contentLabel="Minimal Modal Example"
                                    style={{
                                        overlay: {

                                            zIndex: 10000
                                        },
                                        content: {
                                            position: 'absolute',
                                            width: '50%',
                                            height: '64%',
                                            top: '30px',
                                            left: '350px',
                                            right: '40px',
                                            bottom: '40px',
                                            border: '1px solid #ccc',
                                            background: '#fff',
                                            overflow: 'auto',
                                            WebkitOverflowScrolling: 'touch',
                                            borderRadius: '4px',
                                            outline: 'none',
                                            padding: '0px'
                                        }
                                    }}
                                //    className="modal"
                                >
                                    <div class="card22">
                                        <div class="card-header">
                                            <h4 class="card-title">Add Note 3</h4>



                                        </div>
                                        <div class="card-body">
                                            <div className='row'>
                                                <label><b>Request Type: </b></label><br></br>
                                                <Radio.Group onChange={this.onChengeReqType} value={this.state.reqType}>
                                                    <Radio value={"SMS"}>SMS</Radio>
                                                    <Radio value={"Call"}>Call</Radio>
                                                    <Radio value={"E-Mail"}>E-Mail</Radio>
                                                    <Radio value={"Letter"}>Letter</Radio>

                                                </Radio.Group>
                                            </div>
                                            <br></br>
                                            <div className='row'>
                                                <label><b>Attach Document: </b></label><br></br>
                                                <div>
                                                    <input type="file" onChange={this.onFileChange} />

                                                </div>
                                            </div>
                                            <br></br>
                                            <div className='row'>
                                                <label><b>Note: </b></label><br></br>
                                                <textarea id="description" name="description" rows="4" cols="50" onChange={this.handleChangeInsItmeName}></textarea>
                                            </div>
                                            <br></br>
                                            <div className='row'>
                                                <div class="col-lg-12">

                                                    <div class="float-end d-print-none" style={{ paddingLeft: 12, }}>

                                                        <button class="btn btn-soft-primary btn-sm" style={{ fontSize: 14 }} onClick={this.requestFromClose}> Close </button>
                                                    </div>


                                                    <div class="float-end d-print-none" style={{ paddingLeft: 12 }}>

                                                        <button class="btn btn-soft-primary btn-sm" style={{ fontSize: 14 }} onClick={this.requestDoneRejct}> Done </button>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                </Modal>
                                <Modal

                                    isOpen={this.state.showModal3}
                                    contentLabel="Minimal Modal Example"
                                    style={{
                                        overlay: {

                                            zIndex: 10000
                                        },
                                        content: {
                                            position: 'absolute',
                                            width: '50%',
                                            height: '64%',
                                            top: '30px',
                                            left: '350px',
                                            right: '40px',
                                            bottom: '40px',
                                            border: '1px solid #ccc',
                                            background: '#fff',
                                            overflow: 'auto',
                                            WebkitOverflowScrolling: 'touch',
                                            borderRadius: '4px',
                                            outline: 'none',
                                            padding: '0px'
                                        }
                                    }}
                                //    className="modal"
                                >
                                    <div class="card22">
                                        <div class="card-header">
                                            <h4 class="card-title">Add Note 4</h4>



                                        </div>
                                        <div class="card-body">
                                            <div className='row'>
                                                <label><b>Request Type: </b></label><br></br>
                                                <Radio.Group onChange={this.onChengeReqType} value={this.state.reqType}>
                                                    <Radio value={"SMS"}>SMS</Radio>
                                                    <Radio value={"Call"}>Call</Radio>
                                                    <Radio value={"E-Mail"}>E-Mail</Radio>
                                                    <Radio value={"Letter"}>Letter</Radio>

                                                </Radio.Group>
                                            </div>
                                            <br></br>
                                            <div className='row'>
                                                <label><b>Attach Document: </b></label><br></br>
                                                <div>
                                                    <input type="file" onChange={this.onFileChange} />

                                                </div>
                                            </div>
                                            <br></br>
                                            <div className='row'>
                                                <label><b>Note: </b></label><br></br>
                                                <textarea id="description" name="description" rows="4" cols="50" onChange={this.handleChangeInsItmeName}></textarea>
                                            </div>
                                            <br></br>
                                            <div className='row'>
                                                <div class="col-lg-12">

                                                    <div class="float-end d-print-none" style={{ paddingLeft: 12, }}>

                                                        <button class="btn btn-soft-primary btn-sm" style={{ fontSize: 14 }} onClick={this.requestFromClose}> Close </button>
                                                    </div>


                                                    <div class="float-end d-print-none" style={{ paddingLeft: 12 }}>

                                                        <button class="btn btn-soft-primary btn-sm" style={{ fontSize: 14 }} onClick={this.requestDone}> Done </button>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                </Modal>
                            </div>) : ("")}

                            <br></br>
                            <div className='row'>


                            </div>


                        </div>


                    </Modal>
                </div>
            </div>

        );
    }
}
function mapStateToProps(state) {
    //console.log("state")
    //console.log(state)
    return {
        depots: state.depots,
        regions: state.regions
    };
}

export default connect(
    mapStateToProps,
    {}
)(SiteAccessHandler);
