import { InputNumber, message } from 'antd';
import React, { Component } from 'react'
import {updateDcLoad} from "../../api/index";

class DCLoad extends Component {
    constructor(props) {
        super(props);
        this.state = {
            switchStatus: true,
            objectSet: {
                "dclDCLoad":0,
                "dclPeakDCLoad":0,
                "dclNominalOperatingVoltage":0
              }
        }
        this.handledclDCLoad = this.handledclDCLoad.bind(this);
        this.handledclPeakDCLoad = this.handledclPeakDCLoad.bind(this);
        this.handledclNominalOperatingVoltage = this.handledclNominalOperatingVoltage.bind(this);
    }
    handledclDCLoad(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                dclDCLoad: e
            }

        })
    }
    handledclPeakDCLoad(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                dclPeakDCLoad: e
            }

        })
    }
    handledclNominalOperatingVoltage(e) {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                dclNominalOperatingVoltage: e
            }

        })
    }
    componentDidMount() {
        this.setState({
            objectSet: {
                "dclDCLoad": this.props.details.access_technologies_data?.dclDCLoad,
                "dclPeakDCLoad": this.props.details.access_technologies_data?.dclPeakDCLoad,
                "dclNominalOperatingVoltage": this.props.details.access_technologies_data?.dclNominalOperatingVoltage

            }

        }, () => {
            console.log("opopop")
            console.log(this.state.objectSet)
        })
    }
    dataSetToUpdate = async () => {

        var res = await updateDcLoad(this.props.details2.site_details.id, this.state.objectSet);

        if(res.success){
           message.success('Successfully Update!')
           this.props.parentMethod();
           this.setState({
             switchStatus: true
         })
        }else{
         message.warning(' Something went wrong!')
        }

    }
    dataUpdate() {
        console.log("ssss")
        this.setState({
            switchStatus: false
        })
    }
    close = async () =>{
        this.props.parentMethod();
        this.setState({
            objectSet: {
                "dclDCLoad": this.props.details.access_technologies_data?.dclDCLoad,
                "dclPeakDCLoad": this.props.details.access_technologies_data?.dclPeakDCLoad,
                "dclNominalOperatingVoltage": this.props.details.access_technologies_data?.dclNominalOperatingVoltage
            }
        });
        this.setState({
            switchStatus: true
        });
    }
    render() {
        const { details } = this.props.details
        return (
            <>
                {this.props.details.access_technologies_data !=null ? (
                    <div className='row'>
                        <div className='col-md-6'>
                            <div class="card">
                                <div class="card-header">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <h4 class="card-title">Information</h4>
                                        {this.state.switchStatus
                                            ?   <button type="button" class="btn btn-soft-primary btn-icon-square-sm" onClick={() => this.dataUpdate()}> <i class="mdi mdi-pencil-outline"></i></button>
                                            :   <div class="d-flex justify-content-end align-items-center">
                                                    <button type="button" class="btn btn-soft-secondary " onClick={() => this.close()} style={{marginRight:"10px"}}> Cancel </button> 
                                                    {this.state.objectSet.dclDCLoad == null || this.state.objectSet.dclDCLoad == "" || this.state.objectSet.dclNominalOperatingVoltage == null || this.state.objectSet.dclNominalOperatingVoltage == "" ?
                                                    <button type="button" class="btn btn-soft-primary " disabled> Submit to Update </button>
                                                    :
                                                    <button type="button" class="btn btn-soft-primary " onClick={() => this.dataSetToUpdate()}> Submit to Update </button>
                                                    }   
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        
                                            <ul class="list-group">
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i> DC Load (KW){this.state.switchStatus == false ?<pre style={{color: 'red'}}> *Required</pre> : ""}
                                                    </div>
                                                    {this.state.switchStatus ? this.props.details.access_technologies_data?.dclDCLoad ? ( <span class="badge badge-outline-success">{this.props.details.access_technologies_data.dclDCLoad}</span>):( <span class="badge badge-outline-warning">{this.props.details.access_technologies_data.dclDCLoad}</span>) : 
                                                    <>
                                                    {/* <p style={{color: 'red'}}>{this.objectSet?.dclDCLoad == null || this.objectSet?.dclDCLoad == "" ? "*Required" : "fgf"}</p> */}
                                                    <InputNumber style={{ width: '20%' }} value={this.state.objectSet?.dclDCLoad} id="mpcNoOfPhases" onChange={this.handledclDCLoad} />
                                                    </>
                                                    }
                                                   
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i> Peak DC Load (KW)
                                                    </div>
                                                    {this.state.switchStatus ? this.props.details.access_technologies_data?.dclPeakDCLoad ? ( <span class="badge badge-outline-success">{this.props.details.access_technologies_data.dclPeakDCLoad}</span>):( <span class="badge badge-outline-warning">{this.props.details.access_technologies_data.dclPeakDCLoad}</span>) : <InputNumber style={{ width: '20%' }} value={this.state.objectSet?.dclPeakDCLoad} id="mpcNoOfPhases" onChange={this.handledclPeakDCLoad} />}

                                                </li>
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i> Nominal Operating Voltage (V){this.state.switchStatus == false ?<pre style={{color: 'red'}}> *Required</pre> : ""}
                                                    </div>
                                                    {this.state.switchStatus ? this.props.details.access_technologies_data?.dclNominalOperatingVoltage ? ( <span class="badge badge-outline-success">{this.props.details.access_technologies_data.dclNominalOperatingVoltage}</span>):( <span class="badge badge-outline-warning">{this.props.details.access_technologies_data.dclNominalOperatingVoltage}</span>) : 
                                                    <>
                                                    {/* <p style={{color: 'red'}}>{this.objectSet?.dclNominalOperatingVoltage == null || this.objectSet?.dclNominalOperatingVoltage == "" ? "*Required" : "fgf"}</p> */}
                                                    <InputNumber style={{ width: '20%' }} value={this.state.objectSet?.dclNominalOperatingVoltage} id="mpcNoOfPhases" onChange={this.handledclNominalOperatingVoltage} />
                                                    </>
                                                    }

                                                </li>
                                              
                                            </ul>
                                     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : ( <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h4 style={{ color: "#c5c5c5" }}>No Dat Found DC Load</h4></div>)}

            </>
        )
    }
}
export default DCLoad;
