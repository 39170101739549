import React, { useEffect, useState } from "react";
import { render } from "react-dom";
import { BrowserRouter, Switch, Route, Link,Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./app/store/index";
import Login from "./app/pages/login";
import App from "./app.js";
import { msalConfig, loginRequest } from './app/api/authConfig';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication, useMsal } from '@azure/msal-browser';
import { LoadScript} from '@react-google-maps/api';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal as msalHook,
} from "@azure/msal-react";
const msalInstance = new PublicClientApplication(msalConfig);
//console.log("ffjjaa");
// //console.log(localStorage.getItem("access_token"));
// if(localStorage.getItem("access_token")==null){
// //console.log("ffjjaa1");
//    <Redirect to="/" />
// }else{
// //console.log("ffjjaa2");
  
// }
//test comment 3
render(
  <MsalProvider instance={msalInstance}>
  {/* <LoadScript googleMapsApiKey="AIzaSyD5EKIlo4bRStD4EzFKd5X405aCoN6dRtw"/> */}
    <Provider store={store}>
    <BrowserRouter>
      <App />
      </BrowserRouter>
    </Provider>
  </MsalProvider>,
  document.getElementById("root")
)