import React, { Component } from 'react'
import { Table, Tag, Button, Drawer, Form, Input, Select, InputNumber, message, Popconfirm, DatePicker } from 'antd';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { getAllModelData, powerDbModelCreate, powerDbModelGetById, powerDbModelUpdate, powerDbModelDelete } from "../../api/index";

class RectifierModelModel extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            dataSet: [],
            actionView: true,
            editFlow: false,
            open: false,
            rectifierModuleModelName: '',
            materialCode: '',
            moduleCapacity: 0,
            maximumCapacity: 0,
            efficiency: 0,
            rectifiersModelDescription: '',
            allRectifiersModuleModel: null,
            AssetManagementPermission: true,
            rectifiersModuleModelId: null,
            setValues: null,
            filteredTable: null,
            clearSearchValue:''



        }
        this.requestFromClose = this.requestFromClose.bind(this);
        this.handleName = this.handleName.bind(this);
        this.handleMaterialCode = this.handleMaterialCode.bind(this);
        this.handleRectifierModuleModel = this.handleRectifierModuleModel.bind(this);
        this.handleMaximumCapacity = this.handleMaximumCapacity.bind(this);
        this.handleEfficiency = this.handleEfficiency.bind(this);
        this.handleDescription = this.handleDescription.bind(this);


    }
    handleDescription(e) {
        console.log(e.target.value)
        this.setState({ rectifiersModelDescription: e.target.value });
    }
    handleEfficiency(e) {
        console.log(e)
        this.setState({ efficiency: e });
    }
    handleMaximumCapacity(e) {
        console.log(e)
        this.setState({ maximumCapacity: e });
    }
    handleRectifierModuleModel(e) {
        console.log(e)
        this.setState({ moduleCapacity: e });
    }
    handleMaterialCode(e) {
        console.log(e.target.value)
        this.setState({ materialCode: e.target.value });
    }
    handleName(e) {
        console.log(e.target.value)
        this.setState({ rectifierModuleModelName: e.target.value });
    }

    requestFromClose() {
        console.log('dsdsd')
        this.setState({ showModal: true, open: true, editFlow: false });
    }

    onDrawerClose = () => {
        console.log('sddsds')
        this.formRef.current.resetFields();

        this.setState({
            open: false,
            rectifierModuleModelName: '',
            materialCode: '',
            moduleCapacity: 0,
            maximumCapacity: 0,
            efficiency: 0,
            rectifiersModelDescription: '',
        })


    }
    AllDataSubmit = async () => {
        var Url = '/rectifierModelModel/create'
        var dataSet = {
            name: this.state.rectifierModuleModelName,
            materialCodeOfRectifierModul: this.state.materialCode,
            moduleCapacity: this.state.moduleCapacity,
            efficiency: this.state.efficiency,
            description: this.state.rectifiersModelDescription,

        }
        var res = await powerDbModelCreate(Url, dataSet);

        if (res.success) {
            this.formRef.current.resetFields();
            this.setState({
                open: false,
                rectifierModuleModelName: '',
                materialCode: '',
                moduleCapacity: 0,
                maximumCapacity: 0,
                efficiency: 0,
                rectifiersModelDescription: '',
                filteredTable: null

            }, () => {
                this.getAllModelData()
                message.success('Successfully Created!')
            })

        }

    }
    componentDidMount() {
        this.getAllModelData()
    }
    modelUpdata = (id) => {
        var Url = '/rectifierModelModel/getById/' + id
        this.getModelById(Url)


    }
    getModelById = async (Url) => {

        var res = await powerDbModelGetById(Url);

        console.log(res)
        if (res.success) {
            this.setState({
                editFlow: true,
                open: true,
                rectifiersModuleModelId: res.data.id,
                rectifierModuleModelName: res.data.name,
                materialCode: res.data.materialCodeOfRectifierModul,
                moduleCapacity: res.data.moduleCapacity,
                efficiency: res.data.efficiency,
                rectifiersModelDescription: res.data.description

            })
        }


    }
    editDataSubmit = async () => {
        var Url = '/rectifierModelModel/update'
        var dataSet = {
            id: this.state.rectifiersModuleModelId,
            name: this.state.rectifierModuleModelName,
            materialCodeOfRectifierModul: this.state.materialCode,
            moduleCapacity: this.state.moduleCapacity,
            efficiency: this.state.efficiency,
            description: this.state.rectifiersModelDescription,

        }
        var res = await powerDbModelUpdate(Url, dataSet);

        if (res.success) {
            this.setState({
                open: false,
                rectifierModuleModelName: '',
                materialCode: '',
                moduleCapacity: 0,
                maximumCapacity: 0,
                efficiency: 0,
                rectifiersModelDescription: '',
                rectifiersModuleModelId: null
            }, () => {
                this.getAllModelData()
                message.success('Successfully Updated!')
            })
        }


    }

    getAllModelData = async () => {

        this.setState({
            tabaleLoading: true
        })
        var Url = '/rectifierModelModel/getAll  '

        var res = await getAllModelData(Url);
        console.log(res.data)
        if (res.success) {

            if (this.state.setValues == null) {
                this.setState({
                    allRectifiersModuleModel: res.data,
                    tabaleLoading: false
                })
            } else {
                this.setState({
                    allRectifiersModuleModel: res.data,
                    tabaleLoading: false
                }, () => {
                    const filtered = this.state.allRectifiersModuleModel.filter(user => (user?.name?.toLowerCase().includes(this.state.setValues.toLowerCase()) || user?.materialCodeOfRectifierModul?.toLowerCase().includes(this.state.setValues.toLowerCase()) || user?.moduleCapacity == this.state.setValues || user?.efficiency == this.state.setValues));

                    this.setState({
                        filteredTable: filtered
                    })


                })
            }


        }

    }

    submitForm = async (item) => {
        this.deleteModelData(item);


    }
    deleteModelData = async (item) => {
        var Url = '/rectifierModelModel/deleteRectifierModuleModel?rectifierModuleModelId=' + item
        var res = await powerDbModelDelete(Url);
        if (res.success) {
            message.success('Successfully Delete!')
            this.getAllModelData();
        } else {
            message.warning(' Something went wrong!')
        }
    }

    search = (value) => {
        console.log(value)
        this.setState({
            setValues: value
        })

        const filtered = this.state.allRectifiersModuleModel.filter(user => (user?.name?.toLowerCase().includes(value.toLowerCase()) || user?.materialCodeOfRectifierModul?.toLowerCase().includes(value.toLowerCase()) || user?.moduleCapacity == value || user?.efficiency == value));
        this.setState({
            filteredTable: filtered
        })



    }

    render() {
        const columns2 = [
            {
                title: "Rectifier Module Model",

                dataIndex: "name",
                key: "name",
                fixed: 'left',

                render: (item) => <>
                    <Tag color="gray" key={item}>
                        <span>{item}</span>
                    </Tag>
                </>,


            },
            {
                title: "Material Code",
                dataIndex: "materialCodeOfRectifierModul",
                key: "materialCodeOfRectifierModul",

                fixed: 'left',
                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,

            },
            {
                title: "Module Capacity",
                dataIndex: "moduleCapacity",
                key: "moduleCapacity",



                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
            },

            {
                title: "Efficiency",
                dataIndex: "efficiency",
                key: "efficiency",



                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
            },

            {

                dataIndex: "id",
                key: "id",
                width: '50px',

                fixed: 'right',

                render: (item) => <>
                    {this.state.AssetManagementPermission ? (<a style={{ color: 'blue' }} onClick={() => this.modelUpdata(item)}><i className="las la-pen font-15"></i></a>) : ('')}
                    <Popconfirm
                        title="Are You Sure?"
                        description="Are you sure to delete this task?"
                        onConfirm={() => this.submitForm(item)}
                        okText="Yes"
                        cancelText="No"
                    >
                        {this.state.AssetManagementPermission ? (<a style={{ color: 'blue' }}><i className="las la-trash-alt font-15"></i></a>) : ('')}
                    </Popconfirm> </>,

            },









        ];
        return (
            <div class="row">
                <div class="card">
                    <div class="card-header">

                        <div className="row align-items-center">
                            <div className="col">
                                <h4 className="card-title">Rectifier Module Model List</h4>
                            </div>
                            <div className="col-auto">
                                {this.state.actionView ? (<Button className="btn btn-primary" onClick={this.requestFromClose} block style={{ textAlign: "end" }} >
                                    <i class="la la-plus-circle text-muted font-16 me-2"></i> <b style={{ color: "#40a9ff" }}>Add Data</b>
                                </Button>) : ('')}
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                    <div className='row'>
                            <div className='col-md-10'>
                                <Input
                                    id='clearSearch'
                                    enterButton
                                    style={{ marginBottom: 5 }}
                                    placeholder="Search ..."
                                    value={this.state.clearSearchValue}
                                    onChange={(e) => {
                                        const currValue = e.target.value;
                                        this.search(currValue);
                                        this.setState({
                                            clearSearchValue:currValue
                                        })
                                    }}
                                />
                            </div>
                            <div className='col-md-2' style={{ textAlign: 'end' }}>
                                <button type="button" class="btn btn-primary " onClick={(e) => {
                                    this.setState({
                                        filteredTable: null
                                    },()=>{
                                        this.setState({
                                            clearSearchValue:''
                                        })
                                      
                                    })

                                }} >Clear Search</button>
                            </div>

                        </div>


                        <Table
                            size="small"
                            columns={columns2}
                            // loading={this.state.loading && this.state.rectifierloading && this.state.batteryBanksloading && this.state.airConditionersloading && this.state.spdsloading && this.state.cabinetsloading && this.state.generatorsloading && this.state.atsesloading && this.state.dcdusloading && this.state.fcbsloading && this.state.sharedOutOperatorsesloading}
                            loading={this.state.tabaleLoading}
                            // dataSource={this.state.allRectifiersModuleModel != null ? (this.state.allRectifiersModuleModel.reverse()) : ([])}
                            dataSource={this.state.filteredTable == null ? this.state.allRectifiersModuleModel?.reverse() : this.state.filteredTable?.reverse()}

                        />


                        <Drawer title={this.state.editFlow ? ('Edit Data') : ('Add Data')} width={"60%"} placement="right" onClose={() => this.onDrawerClose()} visible={this.state.open}>
                            <div className="row" >
                                <div className="col-lg-12">
                                    <div className="card">

                                        <div className="card-body">
                                            <Form
                                                ref={this.formRef}
                                            >

                                                <div className='row'>

                                                    {/*  */}

                                                    <div className="col-md-6">


                                                        <label className="form-label" for="dcdu">
                                                            Rectifier Module Model
                                                        </label>
                                                        <Form.Item required >
                                                            <Input id="Name" onChange={this.handleName} value={this.state.rectifierModuleModelName} />
                                                            {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                        </Form.Item>



                                                    </div>
                                                    <div className="col-md-6">


                                                        <label className="form-label" for="materialCode">
                                                            Material Code
                                                        </label>
                                                        <Form.Item required >
                                                            <Input id="materialCode" onChange={this.handleMaterialCode} value={this.state.materialCode} />
                                                            {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                        </Form.Item>



                                                    </div>


                                                </div>
                                                <div className='row'>

                                                    {/*  */}

                                                    <div className="col-md-6">


                                                        <label className="form-label" for="dcdu">
                                                            Module Capacity
                                                        </label>
                                                        <Form.Item required >
                                                           <InputNumber min={0} style={{ width: '100%' }} id="moduleCapacity" onChange={this.handleRectifierModuleModel} value={this.state.moduleCapacity} />
                                                            {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                        </Form.Item>



                                                    </div>
                                                    <div className="col-md-6">


                                                        <label className="form-label" for="efficiency">
                                                            Efficiency
                                                        </label>
                                                        <Form.Item required>
                                                           <InputNumber min={0} style={{ width: '100%' }} id="efficiency" onChange={this.handleEfficiency} value={this.state.efficiency} />
                                                            {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                        </Form.Item>



                                                    </div>



                                                </div>

                                            </Form>




                                            <div className='row' style={{ justifyContent: "end" }}>
                                                <div className='col-md-2' >   <button className="btn btn-primary" onClick={this.state.editFlow ? (() => this.editDataSubmit()) : (() => this.AllDataSubmit())} >Submit</button></div>


                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Drawer>


                    </div>
                </div>
            </div>
        )
    }
}
export default RectifierModelModel;