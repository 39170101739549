import { takeEvery, call, put } from "redux-saga/effects"
import {get , post} from '../api/api.client'
import {getMasterData,getRolesData,getUserRolesAndPermission,getUserServiceType} from '../api/index'


export default function* watcherSaga() {
    yield takeEvery("DATA_REQUESTED", workerSaga); 
}

function* workerSaga() {
  try {  
	const payload =  yield call(getMasterData); 
	yield put({ type: "MASTER_DATA_LOADED", payload }); 

  const payloadRoles = yield call(getRolesData); 
	yield put({ type: "ROLES_DATA_LOADED", payloadRoles });
  //console.log('1544')


  const payloadUser = yield call(getUserRolesAndPermission); 
	yield put({type: "USER_DATA_LOADED",payloadUser});

  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}
