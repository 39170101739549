import React from "react";
import "./style.css";

export default function({...rest}){

    const { className ="" } = rest

    return (
        <React.Fragment>
            <div {...rest} className={`container-card-container ${className}`}/>
        </React.Fragment>
    )
}