import React, { Component } from 'react'
import SemiCircleGaugeChat from '../../components/charts/semi-circle-gauge';
import TwoLineChartChart from '../../components/charts/two-line-chart';
import LineCharts from '../../components/charts/line-charts';
import StackedBarChart from '../../components/charts/stacked-bar'
import SimpleDonutChart from '../../components/charts/simple-donut-chart'
import {
    getRoutingDashboardCompletionCoun, getRoutingDashboardCharts, getRoutingDashboardCharts2

} from "../../api/index";

import { Select, DatePicker, Space, Row, Badge } from "antd";
import moment from 'moment';


class Dashboard extends Component {

    constructor(props) {
        super(props);
        const currentDate_landing = new Date();
        this.state = {
            genServiceTargetChart: false,
            genServiceTargetChartSetY1: null,
            genServiceTargetChartSetX1: null,

            requesteTrendChart: false,
            requesteTrendChartSetY1: null,
            requesteTrendChartSetX1: null,

            requestStatusChart: false,
            requestStatusChartSetY1: null,
            requestStatusChartSetX1: null,
            filter_date: currentDate_landing.getFullYear(),
            //filter_date_to: moment().format("YYYY-MM-DD"),
            // filter date to end of month
            filter_date_to: currentDate_landing.getMonth() + 1,

            momCompletionChart: false,
            momCompletionChartSetY1: null,
            momCompletionChartSetX1: null,

            slaChart: false,
            slaChartSetY1: null,
            slaChartSetX1: null,

            routineCrChartValues: null,
            routineCrChart: false,
            routineCrChartTarget: null,
            routineCrChartRealValue: null,

            routineSrChartValues: null,
            routineSrChart: false,
            routineSrChartTarget: null,
            routineSrChartRealValue: null,

            routineWrChartValues: null,
            routineWrChart: false,
            routineWrChartTarget: null,
            routineWrChartRealValue: null,

            routineErChartValues: null,
            routineErChart: false,
            routineErChartTarget: null,
            routineErChartRealValue: null,

            routineNrChartValues: null,
            routineNrChart: false,
            routineNrChartTarget: null,
            routineNrChartRealValue: null,

        }

    }

    componentDidMount() {

        var form = new FormData();
        form.append("year", this.state.filter_date);
        form.append("month", this.state.filter_date_to);
        this.getRoutingDashboardCharts(form);
        this.getRoutingDashboardCompletionCoun(form);
        this.getRoutingDashboardCharts2(form);

        this.setState({
            slaChartSetY1: [44, 55],
            slaChartSetX1: ['Without SLA', 'Within SLA']
        }, () => {
            this.setState({
                slaChart: true
            })
        })
    }

    getRoutingDashboardCompletionCoun = async (data) => {
        var res = await getRoutingDashboardCompletionCoun(data);
        console.log('getRoutingDashboardCompletionCoun')
        console.log(res)
        if (res.success) {

            res.data.map((x, y) => {
                if (x?.region === 'Central (CR)') {
                    this.setState({
                        routineCrChartValues: null,
                        routineCrChartTarget: null,
                        routineCrChartRealValue: null,
                        routineCrChart: false
                    });
                    let setvaluesscr = (x?.completedCount / x?.targetCount) * 100;
                    let targetVal = x?.targetCount;
                    let completedVal = x?.completedCount;
                    console.log(targetVal, completedVal);
                    this.setState({
                        routineCrChartValues: setvaluesscr,
                        routineCrChartTarget: targetVal,
                        routineCrChartRealValue: completedVal,
                        routineCrChart: true
                    });
                }
                if (x?.region === 'Southern (SR)') {
                    this.setState({
                        routineSrChartValues: null,
                        routineSrChartTarget: null,
                        routineSrChartRealValue: null,
                        routineSrChart: false,
                    });
                    let setvaluesscr = (x?.completedCount / x?.targetCount) * 100
                    console.log('setvaluesscr' + setvaluesscr)
                    this.setState({
                        routineSrChartValues: setvaluesscr,
                        routineSrChartTarget: x?.targetCount,
                        routineSrChartRealValue: x?.completedCount,
                    }, () => {
                        this.setState({
                            routineSrChart: true
                        })
                    })
                }

                if (x?.region === 'Western (WR)') {
                    this.setState({
                        routineWrChartValues: null,
                        routineWrChartTarget: null,
                        routineWrChartRealValue: null,
                        routineWrChart: false
                    });
                    let setvaluesscr = (x?.completedCount / x?.targetCount) * 100
                    console.log('setvaluesscr' + setvaluesscr)
                    this.setState({
                        routineWrChartValues: setvaluesscr,
                        routineWrChartTarget: x?.targetCount,
                        routineWrChartRealValue: x?.completedCount,
                    }, () => {
                        this.setState({
                            routineWrChart: true
                        })
                    })
                }

                if (x?.region === 'Eastern (ER)') {
                    this.setState({
                        routineErChartValues: null,
                        routineErChartTarget: null,
                        routineErChartRealValue: null,
                        routineErChart: false,
                    });
                    let setvaluesscr = (x?.completedCount / x?.targetCount) * 100
                    console.log('setvaluesscr' + setvaluesscr)
                    this.setState({
                        routineErChartValues: setvaluesscr,
                        routineErChartTarget: x?.targetCount,
                        routineErChartRealValue: x?.completedCount,
                    }, () => {
                        this.setState({
                            routineErChart: true
                        })
                    })
                }

                if (x?.region === 'Northern (NR)') {
                    this.setState({
                        routineNrChartValues: null,
                        routineNrChartTarget: null,
                        routineNrChartRealValue: null,
                        routineNrChart: null
                    });
                    let setvaluesscr = (x?.completedCount / x?.targetCount) * 100
                    console.log('setvaluesscr' + setvaluesscr)
                    this.setState({
                        routineNrChartValues: setvaluesscr,
                        routineNrChartTarget: x?.targetCount,
                        routineNrChartRealValue: x?.completedCount,
                    }, () => {
                        this.setState({
                            routineNrChart: true
                        })
                    })
                }
            })
        }
    }

    getLastDayOfMonth(inputDate) {
        const [year, month] = inputDate.split('-');
        const nextMonth = new Date(year, month);
        nextMonth.setDate(0);
        const lastDay = nextMonth.getDate();
        return `${year}-${month}-${lastDay.toString().padStart(2, '0')}`;
    }

    setCompletionTrendChartData(data) {
        if (data != null) {
            this.setState({
                requesteTrendChart: false
            })
            // const today = new Date();
            // const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
            // const formattedFirstDay = firstDayOfMonth.toISOString().split('T')[0];
            let set_year = this.state.filter_date;
            let set_month = this.state.filter_date_to;
            let set_data = set_year + '-' + set_month
            const inputDate = set_data;
            const lastDayOfMonth = this.getLastDayOfMonth(inputDate);
            const today = new Date(lastDayOfMonth);
            const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
            const formattedFirstDay = firstDayOfMonth.toISOString().split('T')[0];

            const filteredData = data
                .filter(entry => entry.date >= formattedFirstDay && entry.date <= today.toISOString().split('T')[0])
                .sort((a, b) => Date.parse(a.date) - Date.parse(b.date));

            const _counts = filteredData.map(entry => entry.count);
            const _dates = filteredData.map(entry => entry.date);

            let dateSet = _dates;
            let countSet = _counts;

            this.setState({
                requesteTrendChartSetY1: [{
                    name: "Total",
                    data: countSet
                }],
                requesteTrendChartSetX1: dateSet,
            }, () => {
                this.setState({
                    requesteTrendChart: true
                })
            })
        }

    }
    setRequestStatusChartData(data) {
        if (data != null) {
            this.setState({
                requestStatusChart: false
            })
            data.splice(5, 3);
            let canceledSet = data.map(a => a.reviewed);
            let compleateSet = data.map(a => a.compleate);
            let pendingSet = data.map(a => a.pending);
            let regionSet = data.map(a => a.region != null ? (a.region) : ('null'));
            this.setState({
                requestStatusChartSetY1: [{
                    name: 'Reviewed',
                    type: 'column',
                    data: canceledSet
                }, {
                    name: 'Fully Completed',
                    type: 'column',
                    data: compleateSet
                }, {
                    name: 'Partially Completed',
                    type: 'column',
                    data: pendingSet
                },],
                requestStatusChartSetX1: regionSet
            }, () => {
                this.setState({
                    requestStatusChart: true
                })
            })
        }

    }
    getRoutingDashboardCharts = async (data) => {
        var res = await getRoutingDashboardCharts(data);
        console.log('getRoutingDashboardCharts')
        console.log(res)
        if (res.success) {
            this.setState({
                completionTrendChartData: res.data?.completionTrend,
                requestStatusChartData: res.data?.requestStatus,
            }, () => {
                this.setCompletionTrendChartData(this.state.completionTrendChartData)
                this.setRequestStatusChartData(this.state.requestStatusChartData)

            })
        }
    }

    setMomCompletionChartData(data) {
        if (data != null) {
            this.setState({
                momCompletionChart: false
            })
            data.reverse();
            let monthSet = data.map(a => a.month.substring(0, 3));
            let completeSet = data.map(a => a.complete);
            let pendingSet = data.map(a => a.pending);
            this.setState({
                momCompletionChartSetY1: [{
                    name: 'Completed',
                    type: 'column',
                    data: completeSet
                }, {
                    name: ' Pending',
                    type: 'column',
                    data: pendingSet
                },],
                momCompletionChartSetX1: monthSet
            }, () => {
                this.setState({
                    momCompletionChart: true
                })
            })
        }
    }

    setGenServiceTargetVsCompletionChartData(data) {
        if (data.completion != null) {
            this.setState({
                genServiceTargetChart: false,
                genServiceTargetChartSetX1: null,
                genServiceTargetChartSetY1: null
            })

            let fff = data.completion.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
            let dateSet = fff.map(a => a.date);
            let countSets = fff.map(a => a.count);
            const currentDate = new Date();
            const today = currentDate.getDate();
            let countSet = [];
            var counts = 0;

            let set_year = this.state.filter_date;
            let set_month = this.state.filter_date_to - 1; 

            let set_month_new = this.state.filter_date_to; 
            set_month_new = String(set_month_new).padStart(2, '0');
            // create yyyy-mm
            let set_data = set_year + '-' + set_month_new;
            // convert that into date
            let set_date_cnv = new Date(set_data);
            
            console.log(typeof set_date_cnv);
            console.log(set_date_cnv);

            // convert that yyyy-mm string
            set_data = set_data.toString();
            console.log(typeof set_data);
            console.log(set_data);
            
            // get current yyyy-mm
            let current_date = new Date();
            let current_year = current_date.getFullYear();
            let current_month = String(current_date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1
            let current_data = current_year + '-' + current_month;

            console.log(current_data);
            console.log(typeof current_data);



            for (var i = 0; i < fff.length; i++) {
                var entryDate = new Date(fff[i].date);
                var entryDay = entryDate.getDate();
                var entryMonth = entryDate.getMonth();
                var entryYear = entryDate.getFullYear();

                if ((entryYear === set_year && entryMonth === set_month && entryDay <= today) || (entryYear !== set_year || entryMonth !== set_month)) {
                    counts = counts + fff[i].count;
                    countSet.push(counts);
                }
            }

            let _dateList = fff.map(a => a.date);
            let _target = data.target;
            let _targetCount = [];
            let cumulativeTargetCount = 0;

            let sortedTargetData = _target.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));

            sortedTargetData.forEach(targetEntry => {
                cumulativeTargetCount += targetEntry.count;
                _targetCount.push(cumulativeTargetCount);
            });

            while (_targetCount.length < _dateList.length) {
                _targetCount.push(cumulativeTargetCount);
            }

            if (current_data === set_data) {
                console.log(this.daysRemainingInMonth());
                countSet.slice(0, this.daysRemainingInMonth());

                

                let newArray = countSet.slice(countSet.length - this.daysRemainingInMonth(), countSet.length);
                console.log(newArray);

                // get current day as int - dd
                let current_day = new Date().getDate();
                console.log(current_day);

                // create new arry that only contain data upto current day
                let new_arr = [];
                for (let i = 0; i < current_day; i++) {
                    new_arr.push(countSet[i]);
                }

                countSet = new_arr;

//                 let countSet = [/* Your array data here */];
// let daysRemaining = this.daysRemainingInMonth(); // Assuming this returns 30

// if (countSet.length >= daysRemaining) {
//     countSet.splice(-daysRemaining, daysRemaining); // Remove the last 30 elements
// } else {
//     console.error("Array does not have enough elements to remove.");
// }

// console.log(countSet); // Output the modified array

                // let number_of_remove = this.daysRemainingInMonth();
                // const new_arr = [];
                // for (let i = 0; i < number_of_remove; i++) {
                //     new_arr.push(countSet[i]);
                // }

                // console.log(new_arr);
            }

            console.log(countSet);

            this.setState({
                genServiceTargetChartSetY1: [
                    {
                        name: "Routine Service Target",
                        data: _targetCount
                    },
                    {
                        name: "Routine Service Completion",
                        data: countSet
                    }
                ],
                genServiceTargetChartSetX1: dateSet,
            }, () => {
                this.setState({
                    genServiceTargetChart: true
                })
            })
        }
    }

    daysRemainingInMonth() {
        let today = new Date();
        let endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Last day of the current month
        return endOfMonth.getDate() - today.getDate();
    }

    getRoutingDashboardCharts2 = async (data) => {
        var res = await getRoutingDashboardCharts2(data);
        console.log('getRoutingDashboardCharts2')
        console.log(res)
        if (res.success) {

            this.setState({
                momCompletionChartData: res.data?.momCompletion,
                genServiceTargetVsCompletionChartData: res.data?.completion
            }, () => {
                this.setMomCompletionChartData(this.state.momCompletionChartData)
                this.setGenServiceTargetVsCompletionChartData(this.state.genServiceTargetVsCompletionChartData)
            })
        }
    }
    dataonChange = (date, dateString) => {
        //  //console.log(date);
        // globalCancelToken.cancel()
        //console.log(dateString);
        const [yearValue, monthValue] = dateString.split('-');
        console.log(monthValue)
        console.log(yearValue)
        this.setState({
            filter_date: yearValue,
            filter_date_to: monthValue,
        }, () => {
            var form = new FormData();


            form.append("year", this.state.filter_date);
            form.append("month", this.state.filter_date_to);

            this.getRoutingDashboardCharts(form);
            this.getRoutingDashboardCharts2(form);
            this.getRoutingDashboardCompletionCoun(form);
        });



    };

    stripYearFromDates(dates) {
        return dates.map(date => date.slice(8));
    }

    render() {
        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long' });
        return (
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="page-title-box">
                            <div class="row">
                                <div class="col">
                                    <h4 class="page-title">Dashboard</h4>
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Routine</a></li>

                                    </ol>
                                </div>
                                <div class="col-auto align-self-center">
                                    <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <DatePicker
                                    picker="month"
                                    className='textStyles-small customDropdown'
                                    style={{ width: "250px", borderRadius: "5px" }}
                                    allowClear={false}
                                    defaultValue={moment(moment().format("YYYY-MM-DD").slice(0, -2) + '01')}
                                    size='medium'
                                    onChange={this.dataonChange}
                                />
                            </div>

                        </div>
                        <br></br>
                        <div className="card">
                            <div className="card-header">
                                <div className='row'>

                                    <div className="col-md-3">
                                        <h4 className="card-title" style={{
                                            fontWeight: "bold", fontFamily: 'Roboto'
                                        }}>Routine Monthly</h4>
                                    </div>
                                    <div className="container" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <div className="col">
                                            <span><Badge color="#2D5FF4" text="Completed" style={{ marginLeft: "10px", fontFamily: 'Roboto', fontSize: '9px', fontWeight: 'bold' }} /></span>
                                            <span style={{ marginLeft: "10px", fontFamily: 'Roboto', fontSize: '9px', fontWeight: 'bold' }}><Badge color="#42B02A" text="Target" /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div class="col-md-6 col-lg-2" style={{ textAlign: "center", width: "20%" }} >
                                        {this.state.routineCrChart ?
                                            (<SemiCircleGaugeChat setcolor="#2D5FF4"
                                                setvalues={((this.state.routineCrChartRealValue / this.state.routineCrChartTarget) * 100).toFixed(2)}
                                                realValue={this.state.routineCrChartRealValue}
                                                targetValue={this.state.routineCrChartTarget}
                                                setTitel="Central (CR)" />) : ('')}
                                    </div>
                                    <div class="col-md-6 col-lg-2" style={{ textAlign: "center", width: "20%" }} >
                                        {this.state.routineSrChart ? (<SemiCircleGaugeChat setcolor="#2D5FF4"
                                            setvalues={this.state?.routineSrChartValues?.toFixed(2)}
                                            realValue={this.state.routineSrChartRealValue}
                                            targetValue={this.state.routineSrChartTarget} setTitel="Southern (SR)" />) : ('')}
                                    </div>
                                    <div class="col-md-6 col-lg-2" style={{ textAlign: "center", width: "20%" }} >
                                        {this.state.routineWrChart ? (<SemiCircleGaugeChat setcolor="#2D5FF4"
                                            setvalues={this.state?.routineWrChartValues?.toFixed(2)}
                                            realValue={this.state.routineWrChartRealValue}
                                            targetValue={this.state.routineWrChartTarget} setTitel="Western (WR)" />) : ('')}
                                    </div>
                                    <div class="col-md-6 col-lg-2" style={{ textAlign: "center", width: "20%" }} >
                                        {this.state.routineErChart ? (<SemiCircleGaugeChat setcolor="#2D5FF4"
                                            setvalues={this.state?.routineErChartValues?.toFixed(2)}
                                            realValue={this.state.routineErChartRealValue}
                                            targetValue={this.state.routineErChartTarget} setTitel="Eastern (ER)" />) : ('')}
                                    </div>
                                    <div class="col-md-6 col-lg-2" style={{ textAlign: "center", width: "20%" }} >
                                        {this.state.routineNrChart ? (<SemiCircleGaugeChat setcolor="#2D5FF4"
                                            setvalues={this.state?.routineNrChartValues?.toFixed(2)}
                                            realValue={this.state.routineNrChartRealValue}
                                            targetValue={this.state.routineNrChartTarget} setTitel="Northern (NR)" />) : ('')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title" style={{
                                        fontWeight: "bold", fontFamily: 'Roboto'
                                    }}>Routine Service Target vs Completion</h4>
                                </div>
                                <div className="card-body">
                                    {this.state.genServiceTargetChart ? (
                                        <TwoLineChartChart
                                            y1={this.state.genServiceTargetChartSetY1}
                                            x1={this.stripYearFromDates(this.state.genServiceTargetChartSetX1)}
                                            yLabale={'No of Sites'} />) : ('')}
                                </div>
                            </div>

                        </div>
                        <div className="col-md-12 col-lg-6">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title" style={{
                                        fontWeight: "bold", fontFamily: 'Roboto'
                                    }}>Completion Trend</h4>
                                </div>
                                <div className="card-body">
                                    {this.state.requesteTrendChart ? (<LineCharts y1={this.state.requesteTrendChartSetY1}
                                        x1={this.stripYearFromDates(this.state.requesteTrendChartSetX1)}
                                        yLabale={"Total"} />) : ('')}
                                </div>
                            </div>

                        </div>
                        <div className="col-md-12 col-lg-6">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title" style={{
                                        fontWeight: "bold", fontFamily: 'Roboto'
                                    }}>Request Status</h4>
                                </div>
                                <div className="card-body">
                                    {this.state.requestStatusChart ? (
                                        <StackedBarChart y1={this.state.requestStatusChartSetY1}
                                            x1={this.state.requestStatusChartSetX1}
                                            yLabale={'Total'}
                                            barColors={['#FF3A29', '#42B02A', '#FFB200']}
                                            horizontalStatus={false}
                                        />) : ('1')}
                                </div>
                            </div>

                        </div>
                        <div className="col-md-12 col-lg-6">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title" style={{
                                        fontWeight: "bold", fontFamily: 'Roboto'
                                    }}>MOM Completion</h4>
                                </div>
                                <div className="card-body">
                                    {this.state.momCompletionChart ? (
                                        <StackedBarChart
                                            y1={this.state.momCompletionChartSetY1}
                                            x1={this.state.momCompletionChartSetX1}
                                            yLabale={'Total'}
                                            barColors={['#42B02A', '#FFB200']}
                                            horizontalStatus={false}
                                        />) : ('1')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Dashboard;
