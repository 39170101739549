import React, { Component } from 'react'
import GaugeChart from 'react-gauge-chart'

class SpeedMeter extends Component {

  constructor(props) {
    super(props);

    this.state = {
      testColor: '#23fa02'

    }
  }
  componentDidMount() {

    console.log('this.props.setValues')
    console.log(this.props.setValues)

    if (this.props.setValues >=1) {
      this.setState({
        testColor: '#fa1911'

      })
    }


  }
  render() {
    return (
      <div >  <GaugeChart id="gauge-chart3"
        titel="sss"
        nrOfLevels={30}
        needleColor="#345243"
        fontSize="18px"
        textColor={this.state.testColor}
        arcWidth={0.2}
        percent={this.props.setValues}
      />
        <div class="card-body" style={{ padding: 0, paddingBottom: 5 }}>
          <div className='row'>
            <div className='col-md-6'>
              <span class="badge bg-success dashboradMeeterbgsuccess" style={{}}>{this.props.realValue}</span>
            </div>
            <div className='col-md-6'>
              <span class="badge bg-danger dashboradMeeterbgdanger">{this.props.targetValue}</span>
            </div>


          </div>
        </div>
        <h6>{this.props.setTitel}</h6></div>
    )
  }
}
export default SpeedMeter