import {
  ADD_ARTICLE,
  DONATION_CATEGORIES,
  MASTER_DATA_LOADED,
  USER_DATA_LOADED,
  ROLES_DATA_LOADED,
  ADD_ORGANIZATION,
  ADD_VENDOR,
  ADD_REGION,
  ADD_DEPOT,
} from "../constants/action-types";

const initialState = {
  articles: [],
  categoryData: [],
  regions: [],
  organization: [],
  depots: [],
  sites: [],
  vendor: [],
  roles: [],
  user:'',
};

function rootReducer(state = initialState, action) {
   
  if (action.type === ADD_ARTICLE) {
    return Object.assign({}, state, {
      articles: state.articles.concat(action.payload),
    });
  }
  if (action.type === DONATION_CATEGORIES) {
    state.categoryData = [];
    return Object.assign({}, state, {
      categoryData: state.categoryData.concat(action.payload),
      loading: false,
    });
  }
  if (action.type === MASTER_DATA_LOADED) {
    state.regions = [];
    state.organization = [];
    state.depots = [];
    state.sites = [];
    //console.log(action.payload.data["regions"]);
    return Object.assign({}, state, {
      regions: state.regions.concat(action.payload.data["regions"]),
      organization: state.organization.concat(
        action.payload.data["organization"]
      ),
      depots: state.depots.concat(action.payload.data["depots"]),
      sites: state.sites.concat(action.payload.data["sites"]),
      vendor: state.vendor.concat(action.payload.data["vendor"]),
    });
  }
  if (action.type === ROLES_DATA_LOADED) {
    state.roles = [];
    //console.log("action.payloadRoles");
    //console.log(action.payloadRoles);
    return Object.assign({}, state, {
     
      roles: state.roles.concat(action.payloadRoles.data),
    });
  }
  //console.log("action.payloadUser1");
  //console.log(action.type);
  if (action.type === USER_DATA_LOADED) {
    //console.log("action.payloadUser2");
    state.user = "";
    //console.log(action.payloadUser);
    return Object.assign({}, state, {
     
      user: action.payloadUser,
    });
  }
  
  if (action.type === ADD_ORGANIZATION) {
    return Object.assign({}, state, {
      organization: state.organization.concat(action.payload),
    });
  }
  if (action.type === ADD_VENDOR) {
    return Object.assign({}, state, {
      vendor: state.vendor.concat(action.payload),
    });
  }
  if (action.type === ADD_REGION) {
    return Object.assign({}, state, {
      regions: state.regions.concat(action.payload),
    });
  }
  if (action.type === ADD_DEPOT) {
    return Object.assign({}, state, {
      depots: state.depots.concat(action.payload),
    });
  }

  return state;
}

export default rootReducer;
