import React, { Fragment } from "react";
import MapComponent from './mapComponent';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import {
    CarOutlined,
    NodeIndexOutlined,
    EditOutlined,
    DeleteOutlined,
    EyeOutlined,
    CheckOutlined,
    DashboardOutlined,
    SearchOutlined,
    PhoneOutlined,
    PaperClipOutlined,
    UploadOutlined,
    ShoppingOutlined,
    UserOutlined,
    MailOutlined,
    BankOutlined,
    FilterOutlined,
    FormOutlined,
    UserDeleteOutlined,
    SyncOutlined,
    ClockCircleOutlined,
    KeyOutlined,
    SnippetsOutlined,
    DownloadOutlined,
    MenuFoldOutlined,
    MoreOutlined,
    AimOutlined,
    CarryOutOutlined
} from '@ant-design/icons';
import { Menu, Divider, Space, Avatar, Card, Skeleton, Switch, Table, Select, Input, Button, Row, Upload, Col, Dropdown, Tag, Modal, Steps, message, Form, DatePicker, TimePicker, Descriptions, Image, Radio, Checkbox, spin, Spin, Empty } from 'antd';
import '../../../../styles/globleStyles.css';
const baseImgURL = "https://storage.googleapis.com/dns_storage_v2/dnsrevampimages/";


const RequestInfo = ({ reqFormData }) => {

    const [showMap, setShowMap] = React.useState({
        showMapView: false,
        lat: 0,
        lng: 0,
        info: null
    });

    return (
        <Fragment>
            <Row>
                <Col span={12}>
                    <div style={{ borderColor: 'gray', backgroundColor: 'white', border: '1px solid #c2c2c2', padding: '5px', width: '99%', borderRadius: '10px' }}>
                        <div className='textStyle-small'
                            style={{ backgroundColor: '#b5b7c0', color: 'white', padding: '5px', fontSize: '12px', justifyContent: 'center', display: 'flex', borderRadius: '5px' }}>
                            Job Details
                        </div>
                        <Descriptions
                            column={1}
                            className='custom-descriptions'
                            style={{
                                borderColor: 'white',
                            }}
                            border={false}
                            bordered
                            size="small">
                            <Descriptions.Item label="Request ID" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.requestId}</Descriptions.Item>
                            <Descriptions.Item label="Permission Start Date & Time" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.permissionStartDateTime?.split('T')[0] + ' ' + reqFormData?.permissionStartDateTime?.split('T')[1]}</Descriptions.Item>
                            <Descriptions.Item label="Permission End Date" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.permissionEndDate}</Descriptions.Item>
                            <Descriptions.Item label="Request Status" className="textStyle-small">
                                {(() => {
                                    let color = 'red';
                                    let displayText = 'Canceled';

                                    if (reqFormData?.requestStatus) {
                                        if (reqFormData.requestStatus === 'ON_GOING') {
                                            color = 'blue';
                                            displayText = 'On Going';
                                        } else {
                                            color = 'green';
                                            displayText = 'Completed';
                                        }
                                    }

                                    return (
                                        <Tag
                                            color={color}
                                            style={{
                                                borderColor: 'transparent',
                                                padding: 5,
                                                borderRadius: 8,
                                                fontSize: '12px'
                                            }}
                                        >
                                            <span
                                                className="textStyles-small"
                                                style={{
                                                    fontWeight: 550,
                                                    fontSize: '12px'
                                                }}
                                            >
                                                {displayText}
                                            </span>
                                        </Tag>
                                    );
                                })()}
                            </Descriptions.Item>

                            <Descriptions.Item label="Request Approval Status" className="textStyle-small">
                                {(() => {
                                    let color = 'red';
                                    let displayText = 'N/A';

                                    if (reqFormData?.requestApprovalStatus) {
                                        if (reqFormData.requestApprovalStatus === 'PENDING_APPROVAL') {
                                            color = 'orange';
                                            displayText = 'Pending Approval';
                                        }
                                        else if (reqFormData.requestApprovalStatus === 'PENDING_APPROVAL_SITE_OWNER') {
                                            color = 'purple';
                                            displayText = 'Pending With Site Owner';
                                        }
                                        else if (reqFormData.requestApprovalStatus === 'APPROVED') {
                                            color = 'blue';
                                            displayText = 'Approved';
                                        }
                                        else if (reqFormData.requestApprovalStatus === 'REJECTED') {
                                            color = 'red';
                                            displayText = 'Rejected';
                                        }
                                        else {
                                            color = 'pink';
                                            displayText = 'Cancelled';
                                        }
                                    }

                                    return (
                                        <Tag
                                            color={color}
                                            style={{
                                                borderColor: 'transparent',
                                                padding: 5,
                                                borderRadius: 8,
                                                fontSize: '12px'
                                            }}
                                        >
                                            <span
                                                className="textStyles-small"
                                                style={{
                                                    fontWeight: 550,
                                                    fontSize: '12px'
                                                }}
                                            >
                                                {displayText}
                                            </span>
                                        </Tag>
                                    );
                                })()}
                            </Descriptions.Item>
                            <Descriptions.Item label="Request Category" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.requestCategory?.name}</Descriptions.Item>
                            <Descriptions.Item label="Request Type" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.requestType}</Descriptions.Item>
                            <Descriptions.Item label="Sub Request Type" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.subRequestType?.name}</Descriptions.Item>
                        </Descriptions>
                    </div>
                </Col>

                <Col span={12}>
                    <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '99%', border: '1px solid #c2c2c2', padding: '5px', marginLeft: '5px', height: '100%', borderRadius: '10px' }}>
                        <div className='textStyle-small'
                            style={{ backgroundColor: '#b5b7c0', color: 'white', padding: '5px', fontSize: '12px', justifyContent: 'center', display: 'flex', borderRadius: '5px' }}>
                            Site Details
                        </div>

                        <Descriptions
                            column={1}
                            className='custom-descriptions'
                            style={{
                                borderColor: 'white',
                            }}
                            border={false}
                            bordered
                            size="small">
                            <Descriptions.Item label="Site ID" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.siteId}</Descriptions.Item>
                            <Descriptions.Item label="Site Name" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.siteName?.replace(/_/g, ' ')}</Descriptions.Item>
                            <Descriptions.Item label="Site Handler" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.handlerGroupName}</Descriptions.Item>
                            <Descriptions.Item label="Region Name" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.regionName}</Descriptions.Item>
                            <Descriptions.Item label="Depot Name" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.depotName}</Descriptions.Item>
                            <Descriptions.Item label="Site Type" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.siteType}</Descriptions.Item>
                            <Descriptions.Item label="Restricted Hours (Weekdays)" className="textStyle-small">
                                <Tag
                                    color="red"
                                    style={{
                                        borderColor: 'transparent',
                                        padding: 5,
                                        borderRadius: 8,
                                        fontSize: '12px'
                                    }}
                                >
                                    <span
                                        className="textStyles-small"
                                        style={{
                                            fontWeight: 550,
                                            fontSize: '12px'
                                        }}
                                    >
                                        {reqFormData?.siteClosingTime && reqFormData?.siteOpeningTime
                                            ? `${reqFormData.siteClosingTime} - ${reqFormData.siteOpeningTime}`
                                            : 'N/A'}</span>
                                </Tag>
                            </Descriptions.Item>

                            <Descriptions.Item label="Restricted Hours (Weekend)" className="textStyle-small">
                                <Tag
                                    color="purple"
                                    style={{
                                        borderColor: 'transparent',
                                        padding: 5,
                                        borderRadius: 8,
                                        fontSize: '12px'
                                    }}
                                >
                                    <span
                                        className="textStyles-small"
                                        style={{
                                            fontWeight: 550,
                                            fontSize: '12px'
                                        }}
                                    >
                                        {reqFormData?.weekendSiteClosingTime && reqFormData?.weekendSiteOpeningTime
                                            ? `${reqFormData.weekendSiteClosingTime} - ${reqFormData.weekendSiteOpeningTime}`
                                            : 'N/A'}</span>
                                </Tag>
                            </Descriptions.Item>
                        </Descriptions>
                    </div>
                </Col>
            </Row>

            <Row style={{ marginTop: '10px' }}>
                {
                    reqFormData?.siteType === 'OO' ? (
                        <Col span={reqFormData?.requestType === 'PROJECT' ? 12 : 24}>
                            <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '99%', border: '1px solid #c2c2c2', padding: '5px', height: '100%', borderRadius: '10px' }}>
                                <div className='textStyle-small'
                                    style={{ backgroundColor: '#b5b7c0', color: 'white', padding: '5px', fontSize: '12px', justifyContent: 'center', display: 'flex', borderRadius: '5px' }}>
                                    Site Type : OO Type {reqFormData?.siteOwner}
                                </div>
                                <Descriptions
                                    column={1}
                                    className='custom-descriptions'
                                    style={{
                                        borderColor: 'white',
                                    }}
                                    border={false}
                                    bordered
                                    size="small">
                                    <Descriptions.Item label="Visiting Purpose" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.visitingPurpose?.name || 'N/A'}</Descriptions.Item>
                                    <Descriptions.Item label="Access Need" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.accessNeed?.name || 'N/A'}</Descriptions.Item>
                                    <Descriptions.Item label="Reason" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.optionalRemarksOne || 'N/A'}</Descriptions.Item>
                                    <Descriptions.Item label="Remark" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.optionalRemarksTwo || 'N/A'}</Descriptions.Item>
                                    <Descriptions.Item label="Work Type" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.workType?.name || 'N/A'}</Descriptions.Item>
                                    <Descriptions.Item label="Work Area" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.workArea?.name || 'N/A'}</Descriptions.Item>
                                </Descriptions>
                            </div>
                        </Col>
                    ) : null
                }
                {
                    reqFormData?.siteType === 'PROJECT' ? (
                        <Col span={reqFormData?.siteType === 'OO' ? 12 : 24}>
                            <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '99%', border: '1px solid #c2c2c2', padding: '5px', marginLeft: '5px', height: '100%', borderRadius: '10px' }}>
                                <div className='textStyle-small'
                                    style={{ backgroundColor: '#b5b7c0', color: 'white', padding: '5px', fontSize: '12px', justifyContent: 'center', display: 'flex', borderRadius: '5px' }}>
                                    Request Type : {reqFormData?.requestType}
                                </div>
                                <Descriptions
                                    column={1}
                                    className='custom-descriptions'
                                    style={{
                                        borderColor: 'white',
                                    }}
                                    border={false}
                                    bordered
                                    size="small">
                                    <Descriptions.Item label="Project Name" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.projectName || 'N/A'}</Descriptions.Item>
                                    <Descriptions.Item label="Scope Of Work" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.scopeOfWork || 'N/A'}</Descriptions.Item>
                                    <Descriptions.Item label="Down Time Required Date" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.downTimeRequiredDate || 'N/A'}</Descriptions.Item>
                                    <Descriptions.Item label="Sub Request Type" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.subRequestType?.name || 'N/A'}</Descriptions.Item>
                                    <Descriptions.Item label="Team Leader Name" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.teamLeaderName || 'N/A'}</Descriptions.Item>
                                    <Descriptions.Item label="Team Leader Contace" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.teamLeaderContact || 'N/A'}</Descriptions.Item>
                                </Descriptions>
                            </div>
                        </Col>
                    ) : null
                }
            </Row>

            <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '100%', marginTop: '10px', border: '1px solid #c2c2c2', padding: '5px', borderRadius: '10px' }}>
                <div className='textStyle-small'
                    style={{ backgroundColor: '#b5b7c0', color: 'white', padding: '5px', fontSize: '12px', justifyContent: 'center', display: 'flex', borderRadius: '5px' }}>
                    Team Members
                </div>
                <Row>
                    {
                        reqFormData?.teamMembers?.length === 0 ? (
                            <Empty description={<span className='textStyle-small'>No team members are assigned to this request</span>}
                                image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        ) : null
                    }
                    {
                        reqFormData?.teamMembers?.map((item, index) => {
                            return (
                                <div style={{ width: "49%", backgroundColor: item?.isBlacklisted ? "#FFA39E" : "#f5f5f5", padding: "10px", borderRadius: "10px", marginTop: "10px", marginRight: '3px' }}>
                                    <Row>
                                        <Col span={20}>
                                            <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "12px", color: item?.isBlacklisted ? "#FFFFFF" : "#000000" }}>
                                                {item?.name}
                                            </span>
                                            <Row style={{ width: '100%' }}>
                                                <Col style={{ width: '25%' }} >
                                                    <span className='textStyle-small' style={{ fontSize: "11px", color: item?.isBlacklisted ? "#FFFFFF" : "#000000" }}>
                                                        <UserOutlined style={{ fontSize: "12px", color: item?.isBlacklisted ? "#000000" : "#1890ff", marginRight: "5px" }} />
                                                        {item?.nic}
                                                    </span>
                                                </Col>
                                                <Col style={{ width: '25%' }} >
                                                    <span className='textStyle-small' style={{ fontSize: "11px", color: item?.isBlacklisted ? "#FFFFFF" : "#000000" }}>
                                                        <PhoneOutlined style={{ fontSize: "12px", color: item?.isBlacklisted ? "#000000" : "#1890ff", marginRight: "5px" }} />
                                                        {item?.contactNumber}
                                                    </span>
                                                </Col>
                                                <Col style={{ width: '50%' }} >
                                                    <span className='textStyle-small' style={{ fontSize: "11px", color: item?.isBlacklisted ? "#FFFFFF" : "#000000" }}>
                                                        <BankOutlined style={{ fontSize: "12px", color: item?.isBlacklisted ? "#000000" : "#1890ff", marginRight: "5px" }} />
                                                        {item?.company}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={4} style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                                            {
                                                item?.isBlacklisted ? (
                                                    <div
                                                        style={{
                                                            cursor: "pointer", color: "gray", float: "right", width: "20px", height: "20px",
                                                            borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "gray"
                                                        }}>
                                                        <UserDeleteOutlined style={{ cursor: "pointer", color: "white", fontSize: "10px" }} />
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            cursor: "pointer", color: "gray", float: "right", width: "20px", height: "20px",
                                                            borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "gray"
                                                        }}>
                                                        <UserDeleteOutlined style={{ cursor: "pointer", color: "white", fontSize: "10px" }} />
                                                    </div>
                                                )
                                            }

                                            {
                                                item?.isKeyHolder ? (
                                                    <>
                                                        <div
                                                            style={{
                                                                cursor: "pointer", color: "#8C5B00", float: "right", width: "20px", height: "20px", marginLeft: "2px",
                                                                borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "#FFA500"
                                                            }}>
                                                            <KeyOutlined style={{ cursor: "pointer", color: "white", fontSize: "10px" }} />
                                                        </div>

                                                        <div
                                                            onClick={() => { setShowMap({ showMapView: true, lat: item?.latitude, lng: item?.longitude, info: item }) }}
                                                            style={{
                                                                cursor: "pointer", color: "#00AC4F", float: "right", width: "20px", height: "20px", marginLeft: "2px",
                                                                borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "#00AC4F"
                                                            }}>
                                                            <AimOutlined style={{ cursor: "pointer", color: "white", fontSize: "10px" }} />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div
                                                        style={{
                                                            cursor: "pointer", color: "gray", float: "right", width: "20px", height: "20px", marginLeft: "2px",
                                                            borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "gray"
                                                        }}>
                                                        <KeyOutlined style={{ cursor: "pointer", color: "white", fontSize: "10px" }} />
                                                    </div>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            )
                        })
                    }
                </Row>
            </div>

            {
                showMap.showMapView && (
                    <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '100%', marginTop: '10px', border: '1px solid #c2c2c2', padding: '5px', borderRadius: '10px' }}>
                        <MapComponent
                           closeMapFunction={() => setShowMap({ showMapView: false, lat: 0, lng: 0, info: null })}
                            startMapCenter={{
                                lat: showMap?.lat || 0,
                                lng: showMap?.lng || 0
                            }} />
                    </div>
                )
            }

            <Row>
                <Col span={12}>
                    <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '99%', marginTop: '10px', border: '1px solid #c2c2c2', padding: '5px', borderRadius: '10px' }}>
                        <div className='textStyle-small'
                            style={{ backgroundColor: '#b5b7c0', color: 'white', padding: '5px', fontSize: '12px', justifyContent: 'center', display: 'flex', borderRadius: '5px' }}>
                            Equipment
                        </div>

                        {
                            reqFormData?.equipments?.length === 0 ? (
                                <Empty description={<span className='textStyle-small'>No equipments are assigned to this request</span>}
                                    image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            ) : null
                        }
                        {
                            reqFormData?.equipments?.map((item, index) => {
                                return (
                                    <div style={{ width: "100%", backgroundColor: "#f5f5f5", padding: "10px", borderRadius: "10px", marginTop: "10px" }}>
                                        <Row>
                                            <Col span={24}>
                                                <Row>
                                                    <Col span={8}>
                                                        <span className='textStyle-small' style={{ fontWeight: "500px", fontSize: "13px"}}>
                                                            {item?.itemName}
                                                        </span>
                                                    </Col>
                                                    <Col span={16} style={{ display: "flex", flexDirection: "row", float: "right", justifyContent: "flex-end" }}>
                                                        <Tag
                                                            color='blue'
                                                            style={{
                                                                borderColor: 'transparent',
                                                                padding: 5,
                                                                borderRadius: 8,
                                                                fontSize: '12px'
                                                            }}
                                                        >
                                                            <span
                                                                className="textStyles-small"
                                                                style={{
                                                                    fontWeight: 600,
                                                                    fontSize: '12px'
                                                                }}
                                                            >
                                                                {item?.actionType}
                                                            </span>
                                                        </Tag>

                                                        <Tag
                                                            color='green'
                                                            style={{
                                                                borderColor: 'transparent',
                                                                padding: 5,
                                                                borderRadius: 8,
                                                                fontSize: '12px'
                                                            }}
                                                        >
                                                            <span
                                                                className="textStyles-small"
                                                                style={{
                                                                    fontWeight: 600,
                                                                    fontSize: '12px'
                                                                }}
                                                            >
                                                                {item?.quantity}
                                                            </span>
                                                        </Tag>
                                                    </Col>
                                                    <span className='textStyle-small' style={{ fontSize: "12px", marginTop: "7px" }}>
                                                        <b>{item?.serialNumber}</b><br></br>
                                                        <span style={{ fontSize: '10px' }}>
                                                            {
                                                                item?.description
                                                            }
                                                        </span>
                                                    </span>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Col>
                <Col span={12}>
                    <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '99%', marginTop: '10px', border: '1px solid #c2c2c2', padding: '5px', marginLeft: '5px', borderRadius: '10px' }}>
                        <div className='textStyle-small'
                            style={{ backgroundColor: '#b5b7c0', color: 'white', padding: '5px', fontSize: '12px', justifyContent: 'center', display: 'flex', borderRadius: '5px' }}>
                            Instrument
                        </div>
                        {
                            reqFormData?.instruments?.length === 0 ? (
                                <Empty description={<span className='textStyle-small'>No instruments are assigned to this request</span>}
                                    image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            ) : null
                        }
                        {
                            reqFormData?.instruments?.map((item, index) => {
                                return (
                                    <div style={{ width: "100%", backgroundColor: "#f5f5f5", padding: "10px", borderRadius: "12px", marginTop: "10px" }}>
                                        <Row>
                                            <Col span={24}>
                                                <Row>
                                                    <Col span={8}>
                                                        <span className='textStyle-small' style={{ fontWeight: "550px", fontSize: "13px" }}>
                                                            {item?.itemName}
                                                        </span>
                                                    </Col>
                                                    <Col span={16} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                                                        <Tag
                                                            color='blue'
                                                            style={{
                                                                borderColor: 'transparent',
                                                                padding: 5,
                                                                borderRadius: 8,
                                                                fontSize: '12px'
                                                            }}
                                                        >
                                                            <span
                                                                className="textStyles-small"
                                                                style={{
                                                                    fontWeight: 600,
                                                                    fontSize: '12px'
                                                                }}
                                                            >
                                                                {item?.actionType}
                                                            </span>
                                                        </Tag>

                                                        <Tag
                                                            color='green'
                                                            style={{
                                                                borderColor: 'transparent',
                                                                padding: 5,
                                                                borderRadius: 8,
                                                                fontSize: '12px'
                                                            }}
                                                        >
                                                            <span
                                                                className="textStyles-small"
                                                                style={{
                                                                    fontWeight: 600,
                                                                    fontSize: '12px'
                                                                }}
                                                            >
                                                                {item?.quantity}
                                                            </span>
                                                        </Tag>
                                                    </Col>
                                                    <span className='textStyle-small' style={{ fontSize: "12px", marginTop: "7px" }}>
                                                        <b>{item?.serialNumber}</b><br></br>
                                                        <span style={{ fontSize: '12px' }}>
                                                            {
                                                                item?.description
                                                            }
                                                        </span>
                                                    </span>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Col>
            </Row>

            <Row>
                <Col span={12}>
                    <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '99%', marginTop: '10px', border: '1px solid #c2c2c2', padding: '5px', height: '100%', borderRadius: '10px' }}>
                        <div className='textStyle-small'
                            style={{ backgroundColor: '#b5b7c0', color: 'white', padding: '5px', fontSize: '12px', justifyContent: 'center', display: 'flex', borderRadius: '5px' }}>
                            Vehicle
                        </div>

                        {
                            reqFormData?.vehicles?.length === 0 ? (
                                <Empty description={<span className='textStyle-small'>No vehicles are assigned to this request</span>}
                                    image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            ) : null
                        }

                        {
                            reqFormData?.vehicles?.map((item, index) => {
                                return (
                                    <div style={{ width: "100%", backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "10px", marginTop: "10px" }}>
                                        <Row>
                                            <Col span={22}>
                                                <Row>
                                                    <Col span={8}>
                                                        <span className='textStyle-small' style={{ fontWeight: "550px", fontSize: "13px" }}>
                                                            {item?.vehicleNumber}
                                                        </span>
                                                    </Col>
                                                    <Col span={16} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                                                        <Tag
                                                            color='orange'
                                                            style={{
                                                                borderColor: 'transparent',
                                                                padding: 5,
                                                                borderRadius: 8,
                                                                fontSize: '12px'
                                                            }}
                                                        >
                                                            <span
                                                                className="textStyles-small"
                                                                style={{
                                                                    fontWeight: 600,
                                                                    fontSize: '11px'
                                                                }}
                                                            >
                                                                {item?.vehicleType}
                                                            </span>
                                                        </Tag>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Col>

                <Col span={12}>
                    <div style={{
                        borderColor: 'gray',
                        backgroundColor: 'white',
                        width: '99%',
                        marginTop: '10px',
                        border: '1px solid #c2c2c2',
                        padding: '5px',
                        marginLeft: '5px',
                        height: '100%', borderRadius: '10px'
                    }}>
                        <div
                            className='textStyle-small'
                            style={{
                                backgroundColor: '#b5b7c0',
                                color: 'white',
                                padding: '5px',
                                fontSize: '12px',
                                justifyContent: 'center',
                                display: 'flex', borderRadius: '5px'
                            }}
                        >
                            Documents
                        </div>

                        {['One', 'Two', 'Three', 'Four', 'Five'].map((num, index) => {
                            const fileKey = `initiatorFile${num}`;
                            const fileOriginalKey = `initiatorFile${num}Original`;

                            return reqFormData?.[fileKey] ? (
                                <div
                                    key={num}
                                    style={{
                                        width: "100%",
                                        backgroundColor: "#f0f0f0",
                                        padding: "10px",
                                        borderRadius: "10px",
                                        marginTop: "10px"
                                    }}
                                >
                                    <Row>
                                        <Col span={22}>
                                            <Row>
                                                <Col span={20}>
                                                    <span
                                                        className='textStyle-small'
                                                        style={{ fontWeight: "550px", fontSize: "12px" }}
                                                    >
                                                        {reqFormData?.[fileOriginalKey]}
                                                    </span>
                                                </Col>
                                                <Col span={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            backgroundColor: "#F28502",
                                                            width: "30px",
                                                            height: "30px",
                                                            borderRadius: "5px",
                                                        }}
                                                    >
                                                        <a href={baseImgURL + reqFormData?.[fileKey]} target="_blank" download>
                                                            <span
                                                                className="textStyle-small"
                                                                style={{ fontWeight: "bold", fontSize: "14px" }}
                                                            >
                                                                <DownloadOutlined style={{ color: "white", fontSize: 16 }} />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            ) : null;
                        })}
                    </div>
                </Col>
            </Row>
            
                            <Row style={{ marginTop: '10px' }}>
                                <Col span={12}>
                                    <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '99%', marginTop: '10px', border: '1px solid #c2c2c2', padding: '5px', height: '100%', borderRadius: '10px' }}>
                                        <div className='textStyle-small'
                                            style={{ backgroundColor: '#b5b7c0', color: 'white', padding: '5px', fontSize: '12px', justifyContent: 'center', display: 'flex', borderRadius: 'px' }}>
                                            Work Description
                                        </div>
                                        <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                                            <span className='textStyle-small' style={{ fontSize: "12px", marginTop: '10px', marginLeft: '5px' }}>
                                                {reqFormData?.description}
                                            </span>
                                        </div>
                                    </div>
                                </Col>
            
                                <Col span={12}>
                                    <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '99%', marginTop: '10px', border: '1px solid #c2c2c2', padding: '5px', height: '100%', marginLeft: '5px', borderRadius: '10px' }}>
                                        <div className='textStyle-small'
                                            style={{ backgroundColor: '#b5b7c0', color: 'white', padding: '5px', fontSize: '12px', justifyContent: 'center', display: 'flex', borderRadius: '5px' }}>
                                            Work Description
                                        </div>
                                        <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                                            <div style={{ justifyContent: 'center', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', top: '5%', position: 'relative' }}>
                                                <div class="card-new" style={{ margin: '10px' }}>
                                                    <div class="icon-new">
                                                        <UserOutlined style={{ fontSize: '30px', color: '#BA183A' }} />
                                                    </div>
                                                    <div class="text textStyles-small" style={{ fontSize: '10px' }}>
                                                        {reqFormData?.initiatorGroupName}</div>
                                                </div>
                                                <div class="card-new" style={{ margin: '10px' }}>
                                                    <div class="icon-new">
                                                        <PhoneOutlined style={{ fontSize: '30px', color: '#BA183A' }} />
                                                    </div>
                                                    <div class="text textStyles-small" style={{ fontSize: '10px' }}>
                                                        {reqFormData?.initiatorUserMobile}</div>
                                                </div>
                                                <div class="card-new" style={{ margin: '10px' }}>
                                                    <div class="icon-new">
                                                        <MailOutlined style={{ fontSize: '30px', color: '#BA183A' }} />
                                                    </div>
                                                    <div class="text textStyles-small" style={{ fontSize: '10px' }}>
                                                        {reqFormData?.initiatorUserEmail}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
            

        </Fragment>
    );
}
export default RequestInfo;
