import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PowerShareOut extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allDataset: null,
            tableView: false

        }

    }

    componentWillMount() {

    }

    componentDidMount() {
        this.setState({
            allDataset: Object.values(this.props.dataSet)
        }, () => {
            console.log(this.state.allDataset)
            this.setState({
                tableView: true
            })
        })
    }

    // componentWillReceiveProps(nextProps) {

    // }

    // shouldComponentUpdate(nextProps, nextState) { 
    //     console.log(nextProps);
    //     console.log(nextState);
    // }

    // componentWillUpdate(nextProps, nextState) {

    // }

    // componentDidUpdate(prevProps, prevState) {

    // }

    componentWillUnmount() {

    }

    render() {
        return (
            <div>{
                this.state.tableView ? (<table class="table table-bbtype mb-0" style={{ fontSize: 'x-small' }} >
                    <thead style={{ borderWidth: 1 }}>
                        <tr >

                            <th className='table-th-color'><b>Power Shared Out Operator</b></th>
                            <th className='table-th-color'><b>AC Commercial Power</b></th>
                            <th className='table-th-color'><b>AC Commercial Power + Gen Backup Power</b></th>
                            <th className='table-th-color'><b>Gen Backup Power</b></th>

                            <th className='table-th-color'><b>DC Power</b></th>

                            <th className='table-th-color'><b>Total</b></th>
                        </tr>
                    </thead>
                    <tbody style={{ borderWidth: 1 }}>

                        {this.state.allDataset?.map((x) => {
                            return <tr>

                                <td className='table-th-color'  >{x[0].tenant}</td>
                                {(() => {
                                    const arr = [];
                                    let totalValue = 0;

                                

                                        if (x.filter(re => re.shared_power_type =="AC Commercial Power").length != 0) {
                                            totalValue = totalValue + x.filter(re => re.shared_power_type =="AC Commercial Power")[0].count
                                            arr.push(
                                                <td className='power-data-dashboard-table-tb' style={{textAlign:'end'}}>{x.filter(re => re.shared_power_type == "AC Commercial Power")[0].count.toLocaleString()}</td>
                                            );
                                        } else {
                                            arr.push(
                                                <td className='power-data-dashboard-table-tb' style={{textAlign:'end'}}>0</td>
                                            );

                                        }
                                        
                                        if (x.filter(re => re.shared_power_type =="AC Commercial Power + Gen Backup Power").length != 0) {
                                            totalValue = totalValue + x.filter(re => re.shared_power_type =="AC Commercial Power + Gen Backup Power")[0].count
                                            arr.push(
                                                <td className='power-data-dashboard-table-tb' style={{textAlign:'end'}}>{x.filter(re => re.shared_power_type == "AC Commercial Power + Gen Backup Power")[0].count.toLocaleString()}</td>
                                            );
                                        } else {
                                            arr.push(
                                                <td className='power-data-dashboard-table-tb' style={{textAlign:'end'}}>0</td>
                                            );

                                        }

                                        if (x.filter(re => re.shared_power_type =="Gen Backup Power").length != 0) {
                                            totalValue = totalValue + x.filter(re => re.shared_power_type =="Gen Backup Power")[0].count
                                            arr.push(
                                                <td className='power-data-dashboard-table-tb' style={{textAlign:'end'}}>{x.filter(re => re.shared_power_type == "Gen Backup Power")[0].count.toLocaleString()}</td>
                                            );
                                        } else {
                                            arr.push(
                                                <td className='power-data-dashboard-table-tb' style={{textAlign:'end'}}>0</td>
                                            );

                                        }
                                        if (x.filter(re => re.shared_power_type =="DC Power").length != 0) {
                                            totalValue = totalValue + x.filter(re => re.shared_power_type =="DC Power")[0].count
                                            arr.push(
                                                <td className='power-data-dashboard-table-tb' style={{textAlign:'end'}}>{x.filter(re => re.shared_power_type == "DC Power")[0].count.toLocaleString()}</td>
                                            );
                                        } else {
                                            arr.push(
                                                <td className='power-data-dashboard-table-tb' style={{textAlign:'end'}}>0</td>
                                            );

                                        }


                                    
                                    arr.push(
                                        <td className='power-data-dashboard-table-tb' style={{textAlign:'end'}}><b>{totalValue.toLocaleString()} </b></td>
                                    );

                                    return arr;
                                })()}
                            </tr>

                        })}



                    </tbody>
                </table>) : ('')}

            </div>
        );
    }
}

PowerShareOut.propTypes = {

};

export default PowerShareOut;