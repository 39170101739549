import React, { Component } from 'react'
import { Table, Tag, Button, Drawer, Form, Input, Select, InputNumber, message, Popconfirm, DatePicker } from 'antd';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { getAllModelData, powerDbModelCreate, powerDbModelGetById, powerDbModelUpdate, powerDbModelDelete, getMasterDataSiteInfo } from "../../api/index";
import SimpleReactValidator from "simple-react-validator";


class CabinetModel extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            dataSet: [],
            actionView: true,
            AssetManagementPermission: true,
            open: false,
            editFlow: false,
            cabinetModelName: '',
            materialCode: '',
            coolingMethodEquipmentCompartment: '',
            coolingMethodBatteryCompartment: '',
            coolingCapacityEquipmentCompartment: '',
            coolingCapacityBatteryCompartment: '',
            eequipmentSpace: '',
            batterySpace: 0,
            allDataModel: null,
            selectedMakeId: null,
            makeType: null,
            setValues: null

        }
        this.requestFromClose = this.requestFromClose.bind(this);
        this.handleName = this.handleName.bind(this);
        this.handleMaterialCode = this.handleMaterialCode.bind(this);
        this.handleCoolingMethodEquipmentCompartment = this.handleCoolingMethodEquipmentCompartment.bind(this);
        this.handleCoolingMethodBatteryCompartment = this.handleCoolingMethodBatteryCompartment.bind(this);
        this.handleCoolingCapacityEquipmentCompartment = this.handleCoolingCapacityEquipmentCompartment.bind(this);
        this.handleCoolingCapacityBatteryCompartment = this.handleCoolingCapacityBatteryCompartment.bind(this);
        this.handlEequipmentSpace = this.handlEequipmentSpace.bind(this);
        this.handleBatterySpace = this.handleBatterySpace.bind(this);
        this.handleDescription = this.handleDescription.bind(this);


    }
    handleBatterySpace(e) {
        console.log(e)
        this.setState({ batterySpace: e });
    }
    handleDescription(e) {
        console.log(e.target.value)
        this.setState({ rectifiersModelDescription: e.target.value });
    }

    handleMaterialCode(e) {
        console.log(e.target.value)
        this.setState({ materialCode: e.target.value });
    }
    handleName(e) {
        console.log(e.target.value)
        this.setState({ cabinetModelName: e.target.value });
    }
    handleCoolingMethodEquipmentCompartment(e) {
        console.log(e.target.value)
        this.setState({ coolingMethodEquipmentCompartment: e.target.value });
    }
    handleCoolingMethodBatteryCompartment(e) {
        console.log(e.target.value)
        this.setState({ coolingMethodBatteryCompartment: e.target.value });
    }
    handleCoolingCapacityEquipmentCompartment(e) {
        console.log(e.target.value)
        this.setState({ coolingCapacityEquipmentCompartment: e.target.value });
    }
    handleCoolingCapacityBatteryCompartment(e) {
        console.log(e.target.value)
        this.setState({ coolingCapacityBatteryCompartment: e.target.value });
    }
    handlEequipmentSpace(e) {
        console.log(e.target.value)
        this.setState({ eequipmentSpace: e.target.value });
    }
    onChangeMake = (value) => {

        this.setState({
            selectedMakeId: value,
            makeType: {
                "id": value
            },
        });
    };

    requestFromClose() {
        console.log('dsdsd')
        this.setState({ showModal: true, open: true, editFlow: false });
    }

    onDrawerClose = () => {
        console.log('sddsds')
        this.formRef.current.resetFields();
        this.validator.hideMessages();
        this.setState({
            open: false,
            cabinetModelName: '',
            materialCode: '',
            coolingMethodEquipmentCompartment: '',
            coolingMethodBatteryCompartment: '',
            coolingCapacityEquipmentCompartment: '',
            coolingCapacityBatteryCompartment: '',
            eequipmentSpace: '',
            batterySpace: 0,
            selectedMakeId: null,
            makeType: null
        })

    }
    componentDidMount() {
        this.getAllModelData()
        console.log('cabinet moles')
        console.log(this.props)

    }



    getAllModelData = async () => {
        this.setState({
            tabaleLoading: true
        })
        var Url = '/cabinetModel/getAll'

        var res = await getAllModelData(Url);
        console.log(res.data)
        if (res.success) {
            if (this.state.setValues == null) {
                this.setState({
                    allDataModel: res.data,
                    tabaleLoading: false
                })
            } else {
                this.setState({
                    allDataModel: res.data,
                    tabaleLoading: false
                }, () => {
                    let value = this.state.setValues
                    const filtered = this.state.allDataModel.filter(user => (user?.name?.toLowerCase().includes(value.toLowerCase()) || user?.materialCodeOfTheCabinet?.toLowerCase().includes(value.toLowerCase()) || user?.equipmentSpace?.toLowerCase().includes(value.toLowerCase()) || user?.make?.name?.toLowerCase().includes(value.toLowerCase()) || user?.coolingCapacityBatteryCompartment?.toLowerCase().includes(value.toLowerCase()) || user?.coolingCapacityEquipmentCompartment?.toLowerCase().includes(value.toLowerCase()) || user?.coolingMethodBatteryCompartment?.toLowerCase().includes(value.toLowerCase()) || user?.coolingMethodEquipmentCompartment?.toLowerCase().includes(value.toLowerCase()) || user?.batterySpace == value));


                    this.setState({
                        filteredTable: filtered
                    })


                })
            }

        }

    }
    AllDataSubmit = async () => {
        if (this.validator.allValid()) {
            var Url = '/cabinetModel/create'

            var dataSet = {

                "name": this.state.cabinetModelName,
                "materialCodeOfTheCabinet": this.state.materialCode,
                "coolingMethodEquipmentCompartment": this.state.coolingMethodEquipmentCompartment,
                "coolingMethodBatteryCompartment": this.state.coolingMethodBatteryCompartment,
                "coolingCapacityEquipmentCompartment": this.state.coolingCapacityEquipmentCompartment,
                "coolingCapacityBatteryCompartment": this.state.coolingCapacityBatteryCompartment,
                "equipmentSpace": this.state.eequipmentSpace,
                "batterySpace": this.state.batterySpace,
                "make": this.state.makeType


            }

            var res = await powerDbModelCreate(Url, dataSet);
            console.log(res);
            if (res.success) {
                this.formRef.current.resetFields();
                this.setState({
                    open: false,
                    cabinetModelName: '',
                    materialCode: '',
                    coolingMethodEquipmentCompartment: '',
                    coolingMethodBatteryCompartment: '',
                    coolingCapacityEquipmentCompartment: '',
                    coolingCapacityBatteryCompartment: '',
                    eequipmentSpace: '',
                    batterySpace: 0,
                    selectedMakeId: null,
                    makeType: null
                }, () => {
                    this.getAllModelData()
                    message.success('Successfully Created!')

                })


            }

        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }

    }

    modelUpdata = (id) => {
        var Url = '/cabinetModel/getById/' + id
        this.getModelById(Url)


    }

    getModelById = async (Url) => {

        var res = await powerDbModelGetById(Url);
        console.log("ssssdsdsdl")
        console.log(res)
        if (res.success) {
            this.setState({
                editFlow: true,
                open: true,
                modelid: res.data.id,
                cabinetModelName: res.data.name,
                materialCode: res.data.materialCodeOfTheCabinet,
                coolingMethodEquipmentCompartment: res.data.coolingMethodEquipmentCompartment,
                coolingMethodBatteryCompartment: res.data.coolingMethodBatteryCompartment,
                coolingCapacityEquipmentCompartment: res.data.coolingCapacityEquipmentCompartment,
                coolingCapacityBatteryCompartment: res.data.coolingCapacityBatteryCompartment,
                eequipmentSpace: res.data.equipmentSpace,
                batterySpace: res.data.batterySpace,


            })

            if (res.data.make != null) {

                console.log('ok')
                this.setState({
                    selectedMakeId: res.data.make.id,
                    makeType: res.data.make
                })

            } else {
                console.log('no')

                this.setState({
                    selectedMakeId: null,
                    makeType: null
                }, () => {
                    console.log(this.state.selectedMakeId)
                })
            }
        }

    }

    editDataSubmit = async () => {

        if (this.validator.allValid()) {
            var Url = '/cabinetModel/update'
            var dataSet = {
                id: this.state.modelid,
                "name": this.state.cabinetModelName,
                "materialCodeOfTheCabinet": this.state.materialCode,
                "coolingMethodEquipmentCompartment": this.state.coolingMethodEquipmentCompartment,
                "coolingMethodBatteryCompartment": this.state.coolingMethodBatteryCompartment,
                "coolingCapacityEquipmentCompartment": this.state.coolingCapacityEquipmentCompartment,
                "coolingCapacityBatteryCompartment": this.state.coolingCapacityBatteryCompartment,
                "equipmentSpace": this.state.eequipmentSpace,
                "batterySpace": this.state.batterySpace,
                "make": this.state.makeType

            }
            var res = await powerDbModelUpdate(Url, dataSet);

            if (res.success) {
                this.setState({
                    open: false,
                    cabinetModelName: '',
                    materialCode: '',
                    coolingMethodEquipmentCompartment: '',
                    coolingMethodBatteryCompartment: '',
                    coolingCapacityEquipmentCompartment: '',
                    coolingCapacityBatteryCompartment: '',
                    eequipmentSpace: '',
                    batterySpace: 0,
                    selectedMakeId: null,
                    makeType: null,
                    modelid: null
                }, () => {
                    message.success('Successfully Updated!')
                    this.getAllModelData()
                })
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }

    submitForm = async (item) => {
        this.deleteModelData(item);


    }
    deleteModelData = async (item) => {
        var Url = '/cabinetModel/deleteCabinetModel?cabinetModelId=' + item
        var res = await powerDbModelDelete(Url);
        if (res.success) {
            message.success('Successfully Delete!')
            this.getAllModelData();
        } else {
            message.warning(' Something went wrong!')
        }
    }
    search = (value) => {
        console.log(value)
        console.log(this.state.allDataModel)
        this.setState({
            setValues: value
        })

        const filtered = this.state.allDataModel.filter(user => (user?.name?.toLowerCase().includes(value.toLowerCase()) || user?.materialCodeOfTheCabinet?.toLowerCase().includes(value.toLowerCase()) || user?.equipmentSpace?.toLowerCase().includes(value.toLowerCase()) || user?.make?.name?.toLowerCase().includes(value.toLowerCase()) || user?.coolingCapacityBatteryCompartment?.toLowerCase().includes(value.toLowerCase()) || user?.coolingCapacityEquipmentCompartment?.toLowerCase().includes(value.toLowerCase()) || user?.coolingMethodBatteryCompartment?.toLowerCase().includes(value.toLowerCase()) || user?.coolingMethodEquipmentCompartment?.toLowerCase().includes(value.toLowerCase()) || user?.batterySpace == value));
        this.setState({
            filteredTable: filtered
        })



    }

    render() {
        const { Option } = Select;

        const columns2 = [
            {
                title: "Cabinet Model",

                dataIndex: "name",
                key: "name",
                fixed: 'left',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,


            },
            {
                title: "Make",

                dataIndex: "make",
                key: "make",
                fixed: 'left',

                render: (item) => <span>{item !== null ? (item?.name) : ('N/A')}</span>,


            },

            {
                title: "Material Code",
                dataIndex: "materialCodeOfTheCabinet",
                key: "materialCodeOfTheCabinet",

                fixed: 'left',
                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,

            },
            {
                title: "Cooling Method Equipment Compartment",
                dataIndex: "coolingMethodEquipmentCompartment",
                key: "coolingMethodEquipmentCompartment",



                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
            },
            {
                title: "Cooling Method Battery Compartment",
                dataIndex: "coolingMethodBatteryCompartment",
                key: "coolingMethodBatteryCompartment",



                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
            },
            {
                title: "Cooling Capacity Equipment Compartment",
                dataIndex: "coolingCapacityEquipmentCompartment",
                key: "coolingCapacityEquipmentCompartment",



                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
            },
            {
                title: "Cooling Capacity Battery Compartment",
                dataIndex: "coolingCapacityBatteryCompartment",
                key: "coolingCapacityBatteryCompartment",



                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
            },
            {
                title: "Equipment Space",
                dataIndex: "equipmentSpace",
                key: "equipmentSpace",



                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
            },
            {
                title: "Battery Space",
                dataIndex: "batterySpace",
                key: "batterySpace",



                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
            },
            {

                dataIndex: "id",
                key: "id",
                width: '50px',

                fixed: 'right',

                render: (item) => <>
                    {this.state.AssetManagementPermission ? (<a style={{ color: 'blue' }} onClick={() => this.modelUpdata(item)}><i className="las la-pen font-15"></i></a>) : ('')}
                    <Popconfirm
                        title="Are You Sure?"
                        description="Are you sure to delete this task?"
                        onConfirm={() => this.submitForm(item)}
                        okText="Yes"
                        cancelText="No"
                    >
                        {this.state.AssetManagementPermission ? (<a style={{ color: 'blue' }}><i className="las la-trash-alt font-15"></i></a>) : ('')}
                    </Popconfirm> </>,

            },









        ];
        return (
            <div class="row">
                <div class="card">
                    <div class="card-header">

                        <div className="row align-items-center">
                            <div className="col">
                                <h4 className="card-title">Cabinet Model List</h4>
                            </div>
                            <div className="col-auto">
                                {this.state.actionView ? (<Button className="btn btn-primary" onClick={this.requestFromClose} block style={{ textAlign: "end" }} >
                                    <i class="la la-plus-circle text-muted font-16 me-2"></i> <b style={{ color: "#40a9ff" }}>Add Data</b>
                                </Button>) : ('')}
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div className='row'>
                            <div className='col-md-10'>
                                <Input
                                    id='clearSearch'
                                    enterButton
                                    style={{ marginBottom: 5 }}
                                    placeholder="Search ..."
                                    value={this.state.clearSearchValue}
                                    onChange={(e) => {
                                        const currValue = e.target.value;
                                        this.search(currValue);
                                        this.setState({
                                            clearSearchValue: currValue
                                        })
                                    }}
                                />
                            </div>
                            <div className='col-md-2' style={{ textAlign: 'end' }}>
                                <button type="button" class="btn btn-primary " onClick={(e) => {
                                    this.setState({
                                        filteredTable: null
                                    }, () => {
                                        this.setState({
                                            clearSearchValue: ''
                                        })

                                    })

                                }} >Clear Search</button>
                            </div>

                        </div>

                        <Table
                            size="small"
                            columns={columns2}
                            // loading={this.state.loading && this.state.rectifierloading && this.state.batteryBanksloading && this.state.airConditionersloading && this.state.spdsloading && this.state.cabinetsloading && this.state.generatorsloading && this.state.atsesloading && this.state.dcdusloading && this.state.fcbsloading && this.state.sharedOutOperatorsesloading}
                            loading={this.state.tabaleLoading}
                            // dataSource={this.state.allDataModel != null ? (this.state.allDataModel.reverse()) : ([])}
                            dataSource={this.state.filteredTable == null ? this.state.allDataModel?.reverse() : this.state.filteredTable?.reverse()}


                        />


                        <Drawer title={this.state.editFlow ? ('Edit Data') : ('Add Data')} width={"60%"} placement="right" onClose={() => this.onDrawerClose()} visible={this.state.open}>
                            <div className="row" >
                                <div className="col-lg-12">
                                    <div className="card">

                                        <div className="card-body">
                                            <Form
                                                ref={this.formRef}
                                            >

                                                <div className='row'>

                                                    {/*  */}

                                                    <div className="col-md-6">


                                                        <label className="form-label" for="dcdu">
                                                            Cabinet Model <x style={{ color: "red" }}>*</x>
                                                        </label>
                                                        <Form.Item required >
                                                            <Input id="Name" onChange={this.handleName} value={this.state.cabinetModelName} />
                                                            <p style={{ color: "#f5325c" }}>{this.validator.message('Cabinet Model', this.state.cabinetModelName, 'required')}</p>
                                                        </Form.Item>



                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label" for="depot">
                                                            Make <x style={{ color: "red" }}>*</x>
                                                        </label>
                                                        {this.state.selectedMakeId != null ? (<><Form.Item required>
                                                            <Select
                                                                showSearch
                                                                onChange={this.onChangeMake}
                                                                defaultValue={this.state.selectedMakeId}

                                                                style={{ width: "100%" }}
                                                                placeholder="Search to Select"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                filterSort={(optionA, optionB) =>
                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                }
                                                            >
                                                                {this.props.masterData?.cabinetMasterData?.map((item, index) => {
                                                                    return <Option value={item.id}>{item.name}</Option>;
                                                                })}
                                                            </Select>
                                                            <p style={{ color: "#f5325c" }}>{this.validator.message('Make', this.state.selectedMakeId, 'required')}</p>
                                                        </Form.Item></>) : ('')}
                                                        {this.state.selectedMakeId == null ? (<><Form.Item required>
                                                            <Select
                                                                showSearch
                                                                onChange={this.onChangeMake}


                                                                style={{ width: "100%" }}
                                                                placeholder="Search to Select"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                filterSort={(optionA, optionB) =>
                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                }
                                                            >
                                                                {this.props.masterData?.cabinetMasterData?.map((item, index) => {
                                                                    return <Option value={item.id}>{item.name}</Option>;
                                                                })}
                                                            </Select>
                                                            <p style={{ color: "#f5325c" }}>{this.validator.message('Make', this.state.selectedMakeId, 'required')}</p>
                                                        </Form.Item></>) : ('')}

                                                    </div>
                                                    <div className="col-md-6">


                                                        <label className="form-label" for="materialCode">
                                                            Material Code
                                                        </label>
                                                        <Form.Item required>
                                                            <Input id="materialCode" onChange={this.handleMaterialCode} value={this.state.materialCode} />
                                                            {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                        </Form.Item>



                                                    </div>


                                                </div>
                                                <div className='row'>

                                                    {/*  */}

                                                    <div className="col-md-6">


                                                        <label className="form-label" for="dcdu">
                                                            Cooling Method Equipment Compartment
                                                        </label>
                                                        <Form.Item required>
                                                            <Input id="coolingMethodEquipmentCompartment" onChange={this.handleCoolingMethodEquipmentCompartment} value={this.state.coolingMethodEquipmentCompartment} />
                                                            {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                        </Form.Item>




                                                    </div>
                                                    <div className="col-md-6">


                                                        <label className="form-label" for="coolingMethodBatteryCompartment">
                                                            Cooling Method Battery Compartment
                                                        </label>
                                                        <Form.Item required>
                                                            <Input id="coolingMethodBatteryCompartment" onChange={this.handleCoolingMethodBatteryCompartment} value={this.state.coolingMethodBatteryCompartment} />
                                                            {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                        </Form.Item>



                                                    </div>


                                                </div>
                                                <div className='row'>

                                                    {/*  */}

                                                    <div className="col-md-6">


                                                        <label className="form-label" for="coolingCapacityEquipmentCompartment">
                                                            Cooling Capacity Equipment Compartment
                                                        </label>
                                                        <Form.Item required >
                                                            <Input id="coolingCapacityEquipmentCompartment" onChange={this.handleCoolingCapacityEquipmentCompartment} value={this.state.coolingCapacityEquipmentCompartment} />
                                                            {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                        </Form.Item>



                                                    </div>

                                                    <div className="col-md-6">


                                                        <label className="form-label" for="coolingCapacityBatteryCompartment">
                                                            Cooling Capacity Battery Compartment
                                                        </label>
                                                        <Form.Item required >
                                                            <Input id="coolingCapacityBatteryCompartment" onChange={this.handleCoolingCapacityBatteryCompartment} value={this.state.coolingCapacityBatteryCompartment} />
                                                            {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                        </Form.Item>



                                                    </div>



                                                </div>
                                                <div className='row'>

                                                    {/*  */}

                                                    <div className="col-md-6">


                                                        <label className="form-label" for="equipmentSpace">
                                                            Equipment Space
                                                        </label>
                                                        <Form.Item required >
                                                            <Input id="equipmentSpace" onChange={this.handlEequipmentSpace} value={this.state.eequipmentSpace} />
                                                            {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                        </Form.Item>



                                                    </div>

                                                    <div className="col-md-6">


                                                        <label className="form-label" for="batterySpace">
                                                            Battery Space
                                                        </label>
                                                        <Form.Item required >
                                                            <InputNumber min={0} style={{ width: '100%' }} id="batterySpace" onChange={this.handleBatterySpace} value={this.state.batterySpace} />

                                                            {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                        </Form.Item>



                                                    </div>



                                                </div>








                                            </Form>




                                            <div className='row' style={{ justifyContent: "end" }}>
                                                <div className='col-md-2' >   <button className="btn btn-primary" onClick={this.state.editFlow ? (() => this.editDataSubmit()) : (() => this.AllDataSubmit())} >Submit</button></div>


                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Drawer>


                    </div>
                </div>
            </div>
        )
    }
}
export default CabinetModel;
