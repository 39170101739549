import React, { Component } from 'react';

import GoogleMapReact from 'google-map-react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Spin } from "antd";

import { GoogleMap, LoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import { MapPgRequest } from "../api/index";
import redMap from "../icon/red.png";
import blueMap from "../icon/blue.png";
import blackMap from "../icon/black.png";
const moment = require('moment');




class dashboardMapView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            site_details: {},
            requested_count: 0,
            installed_count: 0,
            removal_requested_count: 0,
            removed_count: 0,
            cancelled_count: 0,
            site_list: {},
            center: {
                lat: 7.244444,
                lng: 80.6375,
            },

            centerLat: 7.244444,
            centerlng: 80.6375,
            zoom: 8,
            setSelectedElement: null,
            showInfoWindow: true,
            showingInfoWindow: false,
            isMapLoading:false
        };
    }
    getMapPgRequest = async () => {

this.setState({
    isMapLoading:true
})
        var res = await MapPgRequest();

        if (res.success) {
            console.log("map data list");
            console.log(res);

            this.setState(
                {
                    site_list: res.data,
                    requested_count: res.data.filter(alldate => "Requested".includes(alldate.statusType)).length,
                    installed_count: res.data.filter(alldate => "Installed".includes(alldate.statusType)).length,
                    removal_requested_count: res.data.filter(alldate => "Removed Request".includes(alldate.statusType)).length,
                    removed_count: res.data.filter(alldate => "Removed".includes(alldate.statusType)).length,
                    cancelled_count: res.data.filter(alldate => "Cancelled".includes(alldate.statusType)).length,
                    isMapLoading:false
                },
                () => {

                }
            );
        }





    };

    componentDidMount() {
        this.getMapPgRequest();
        //this.getSitesByDepotId();

    }
    onMarkerClick = (props, marker, e) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            })
        }
    };


    render() {
        const { site_details } = this.state;
        const AnyReactComponentReq = ({ siteName, all }) => <div>



            <Popup trigger={<a><i style={{ color: "black", fontSize: 25 }} class="fas fa-map-marker-alt" >


            </i></a>} position="right center">
            <div> <h4>{siteName} ({all.statusType})</h4>
                        <h6>Depot : {all.depot}</h6>

                        <h6>siteId : {all.siteId}</h6>

                        <h6>Site Owner : {all.siteOwner} </h6>
                        <h6>Site Type : {all.siteType}</h6>
                        <h6>Site Category : {all.siteCategory}</h6>
                        <h6>Service Request Created Time : {moment((all.serviceRequestCreatedTime)).format('YYYY-MM-DD HH:mm:ss')}</h6>
                        <h6>Status : {all.statusName}</h6>
                        <h6>Tower Type : {all.towerType}</h6>
                        <h6>Vehicle Type : {all.vehicleType}</h6>
                        
                        </div>
            </Popup>



        </div>;
        const AnyReactComponentIns = ({ siteName,all}) => <div>



            <Popup trigger={<a><i style={{ color: "blue", fontSize: 25 }} class="fas fa-map-marker-alt" >


            </i></a>} position="right center">
                <div> <h4>{siteName} ({all.statusType})</h4>
                        <h6>Depot : {all.depot}</h6>

                        <h6>siteId : {all.siteId}</h6>

                        <h6>Site Owner : {all.siteOwner} </h6>
                        <h6>Site Type : {all.siteType}</h6>
                        <h6>Site Category : {all.siteCategory}</h6>
                        <h6>Service Request Created Time : {moment((all.serviceRequestCreatedTime)).format('YYYY-MM-DD HH:mm:ss')}</h6>
                        <h6>Status : {all.statusName}</h6>
                        <h6>Tower Type : {all.towerType}</h6>
                        <h6>Vehicle Type : {all.vehicleType}</h6>
                        
                        </div>
            </Popup>



        </div>;
        const AnyReactComponentInsRemo = ({ siteName, all }) => <div>



            <Popup trigger={<a><i style={{ color: "green", fontSize: 25 }} class="fas fa-map-marker-alt" >


            </i></a>} position="right center">
            <div> <h4>{siteName} ({all.statusType})</h4>
                        <h6>Depot : {all.depot}</h6>

                        <h6>siteId : {all.siteId}</h6>

                        <h6>Site Owner : {all.siteOwner} </h6>
                        <h6>Site Type : {all.siteType}</h6>
                        <h6>Site Category : {all.siteCategory}</h6>
                        <h6>Service Request Created Time : {moment((all.serviceRequestCreatedTime)).format('YYYY-MM-DD HH:mm:ss')}</h6>
                        <h6>Status : {all.statusName}</h6>
                        <h6>Tower Type : {all.towerType}</h6>
                        <h6>Vehicle Type : {all.vehicleType}</h6>
                        
                        </div>
            </Popup>



        </div>;
        


        return (

            <div className="container-fluid">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="page-title-box">
                            <div class="row">
                                <div class="col">
                                    <h4 class="page-title">PG Map View</h4>
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">PG</a></li>

                                    </ol>
                                </div>
                                <div class="col-auto align-self-center">
                                    <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box" style={{ padding: 0 }}>
                            <div className="row">
                                <div className="col">
                                    <div className="row">
                                        <div className="col-md-2">


                                        </div>
                                        <div className="col-md-2" style={{ textAlign: "center" }}>


                                        </div>
                                        <div className="col-md-2" style={{ textAlign: "center" }}>


                                        </div>
                                        <div className="col-md-2" style={{ textAlign: "center" }}>
                                            <h6 style={{ fontSize: 12 }}>  <i
                                                style={{ color: "black", fontSize: 13 }}
                                                class="fas fa-map-marker-alt"

                                            > </i> REQUESTED({this.state.requested_count})</h6>

                                        </div>
                                        <div className="col-md-2" style={{ textAlign: "center" }}>

                                            <h6 style={{ fontSize: 12 }}> <i
                                                style={{ color: "blue", fontSize: 13 }}
                                                class="fas fa-map-marker-alt"

                                            > </i> INSTALLED({this.state.installed_count})</h6>
                                        </div>
                                        <div className="col-md-2" style={{ textAlign: "center" }}>

                                            <h6 style={{ fontSize: 12 }}><i
                                                style={{ color: "green", fontSize: 13 }}
                                                class="fas fa-map-marker-alt"

                                            > </i> REMOVAL REQUESTED({this.state.removal_requested_count})</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto align-self-center">
                                    {/* <a href="#" className="btn btn-sm btn-outline-primary" id="Dash_Date">
                                                <span className="day-name" id="Day_Name">Today:</span>&nbsp;
                                                <span className="" id="Select_date">Jan 11</span>
                                                <i data-feather="calendar" className="align-self-center icon-xs ms-1"></i>
                                            </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body p-0">
                            <Spin spinning={this.state.isMapLoading} delay={200}>
                                <div
                                    id="user_map"
                                    className="pro-map"
                                    style={{
                                        height: "600px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: 'black'
                                    }}
                                >
                                    {<GoogleMapReact
                                        bootstrapURLKeys={{ key: "AIzaSyC7QjauaBLV9W__koQgy65PIuxYbcSPpfg" }}
                                        defaultCenter={{
                                            lat: this.state.centerLat,
                                            lng: this.state.centerlng
                                        }}
                                        defaultZoom={8}
                                    >
                                        {this.state.site_list.length && this.state.site_list.map((item) => {
                                            
                                            
                                            if (item.statusType == 'Installed') {
                                              
                                                return(   <AnyReactComponentIns
                                                    lat={item.latitude}
                                                    lng={item.longitude}
                                                    siteName={item.siteName}
                                                    all={item}
                                                />);
                                            }
                                            if (item.statusType == 'Removed Request') {
                                                
                                                return(   <AnyReactComponentInsRemo
                                                    lat={item.latitude}
                                                    lng={item.longitude}
                                                    siteName={item.siteName}
                                                    all={item}
                                                />);
                                            }
                                            if (item.statusType == 'Requested') {
                                                
                                                return(   <AnyReactComponentReq
                                                    lat={item.latitude}
                                                    lng={item.longitude}
                                                    siteName={item.siteName}
                                                    all={item}
                                                />);
                                            }


                                        })}

                                       
                                     


                                    </GoogleMapReact>}
                                </div>
                                </Spin>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

// export default GoogleApiWrapper({
//   apiKey: "AIzaSyC7QjauaBLV9W__koQgy65PIuxYbcSPpfg"
// })(dashboardMapView);

export default dashboardMapView;