import React, { useState, useEffect } from 'react';
import {
    ClockCircleOutlined,
    ClockCircleFilled,
    PlusCircleFilled,
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import { Divider, Space, Avatar, Card, Skeleton, Switch, Table, Select, Badge, Input, Button, Row, Upload, Col, Dropdown, Tag, Modal, Steps, message, Form, DatePicker, TimePicker, Descriptions, Image, Radio, Checkbox, Collapse } from 'antd';
import { getAllAccessMasterData, insetMasterDataAccess, updateMasterDataAccess, deleteMasterDataAccess } from "../../../api/index";
import '../style.css';
const { Step } = Steps;

const AccMasterData = () => {
    const [masterData, setMasterData] = useState([]);
    const [masterDataType, setMasterDataType] = useState("visiting-purpose");
    const [isLoading, setIsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [selectedData, setSelectedData] = useState({});

    useEffect(() => {
        getMasterData('visiting-purpose');
    }, []);

    const getMasterData = async (data) => {
        setIsLoading(true);
        const response = await getAllAccessMasterData(data);
        if (response?.success) {
            setIsLoading(false);
            setMasterData(response?.data?.results);
        } else {
            setIsLoading(false);
            message.error("Failed to fetch data");
        }
    }

    const insertMasterData = async (data) => {
        setIsLoading(true);
        const response = await insetMasterDataAccess(masterDataType, data);
        if (response?.success) {
            setIsLoading(false);
            message.success(masterDataType + " data inserted successfully");
            closeModal();
            getMasterData(masterDataType);
        } else {
            setIsLoading(false);
            message.error("Failed to insert data");
        }
    }

    const updateMasterData = async (id, data) => {
        setIsLoading(true);
        const response = await updateMasterDataAccess(masterDataType, id, data);
        if (response?.success) {
            setIsLoading(false);
            message.success(masterDataType + " data updated successfully");
            closeModal();
            setSelectedData({});
            getMasterData(masterDataType);
        } else {
            setIsLoading(false);
            message.error("Failed to update data");
        }
    }

    const deleteMasterDataAccessMasterData = async (id) => {
        setIsLoading(true);
        console.log(id);
        const response = await deleteMasterDataAccess(masterDataType, id);
        if (response?.success) {
            setIsLoading(false);
            message.success(masterDataType + " data deleted successfully");
            closeModal();
            setSelectedData({});
            getMasterData(masterDataType);
        } else {
            setIsLoading(false);
            message.error("Failed to delete data");
        }
    }

    const closeModal = () => {
        setModalOpen(false);
        setIsUpdate(false);
        setSelectedData([]);
    }

    const dropdownData = [
        {
            id: 1,
            name: "Visiting Purpose",
            value: "visiting-purpose"
        },
        {
            id: 2,
            name: "Access Need",
            value: "access-need"
        },
        {
            id: 3,
            name: "Request Category",
            value: "request-category"
        },
        {
            id: 4,
            name: "Sub Request Type",
            value: "sub-request-type"
        },
        {
            id: 5,
            name: "Work Area",
            value: "work-area"
        },
        {
            id: 6,
            name: "Work Type",
            value: "work-type"
        }
    ];

    const columns = [
        {
            title: <span className="textStyles-small">Name</span>,
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <span className="textStyles-small">{record.name}</span>
            )
        },
        {
            title: <span className="textStyles-small">Description</span>,
            dataIndex: 'description',
            key: 'description',
            render: (text, record) => (
                <span className="textStyles-small">{record.description}</span>
            )
        },
        {
            title: <span className="textStyles-small">Created At</span>,
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => {
                let date = new Date(record.createdAt);
                return <span className="textStyles-small">{date.toLocaleDateString()} {date.toLocaleTimeString()}</span>
            }
        },
        {
            title: <span className="textStyles-small">Updated At</span>,
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (text, record) => {
                let date = new Date(record.updatedAt);
                return <span className="textStyles-small">{date.toLocaleDateString()} {date.toLocaleTimeString()}</span>
            }
        },
        {
            title: <span className="textStyles-small">Action</span>,
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    {/* <a onClick={() => {
                        setIsUpdate(true);
                        setSelectedData(record);
                        setModalOpen(true);
                    }}>
                        <span className="textStyles-small"><EditOutlined
                            style={{ color: 'blue', fontSize: '14px' }} /></span>
                    </a>

                    <a onClick={() => {
                        deleteMasterDataAccessMasterData(record.id);
                    }}>
                        <span className="textStyles-small">
                            <DeleteOutlined style={{ color: 'red', fontSize: '14px' }} /></span>
                    </a> */}

                    <Button
                        shape='circle'
                        size='small'
                        icon={<EditOutlined />}
                        type="primary"
                        onClick={() => {
                            setIsUpdate(true);
                            setSelectedData(record);
                            setModalOpen(true);
                        }}
                        />
                    {/* <Button
                        shape='circle'
                        danger
                        size='small'
                        icon={<DeleteOutlined />}
                        type="primary"
                        onClick={() => {
                            deleteMasterDataAccessMasterData(record.id);
                        }}
                        /> */}
                </Space>)
        }
    ];

    const onFinishSubmit = (values) => {
        if (isUpdate) {
            updateMasterData(selectedData?.id, values);
        } else {
            insertMasterData(values);
        }
    }

    return (
        <body>
            <div style={{ margin: '0', padding: '10px', height: '100%' }}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            <div className="row">
                                <div className="col">
                                    <h4 className="page-title" style={{ fontWeight: "bold" }}>
                                        Master Data
                                    </h4>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Site Access </a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Master Data</a>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ margin: '0', padding: '10px', width: '100%', backgroundColor: 'white', borderRadius: '10px' }}>
                    <Select
                        className='borderedSelect'
                        bordered={false}
                        showSearch
                        style={{ width: 220 }}
                        placeholder="Select a Master Data"
                        onChange={(value) => {
                            setMasterDataType(value);
                            getMasterData(value);
                        }}
                        defaultValue={masterDataType}
                    >
                        {dropdownData.map((data, index) => {
                            return (
                                <Select.Option key={data.id} value={data.value}>{data.name}</Select.Option>
                            )
                        })}
                    </Select>
                    <Button
                        type="primary"
                        style={{ marginLeft: '10px', background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px' }}
                        onClick={() => setModalOpen(true)}
                    >
                        <span className='textStyles-small'>
                            <PlusCircleFilled style={{ fontSize: '16px', marginRight: '5px' }} />
                            Insert Data</span>
                    </Button>
                </div>

                <div style={{ marginTop: '10px', padding: '10px', width: '100%', backgroundColor: 'white', borderRadius: '10px' }}>
                    <Table
                        className='table-striped-rows'
                        columns={columns}
                        dataSource={masterData}
                        loading={isLoading}
                        search={true} />
                </div>
            </div>

            <Modal
                title={
                    <span className='textStyles-small' style={{ fontSize: '16px' }}>
                        {
                            (dropdownData?.filter(data => data.value === masterDataType)[0]?.name || '') + (isUpdate ? ' Data Update' : ' Data Insert')
                        }
                    </span>
                }
                visible={modalOpen}
                onCancel={closeModal}
                footer={null}
                destroyOnClose={true}
            >
                <Form
                    name="basic"
                    onFinish={onFinishSubmit}
                    layout='vertical'
                >
                    <Form.Item
                        label={<span className='textStyles-small'>Name</span>}
                        name="name"
                        rules={[{ required: true, message: 'Please input your name!' }]}
                        initialValue={isUpdate ? selectedData?.name : ''}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={<span className='textStyles-small'>Description</span>}
                        name="description"
                        rules={[{ required: true, message: 'Please input your description!' }]}
                        initialValue={isUpdate ? selectedData?.description : ''}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {<span className='textStyles-small'>{
                                isUpdate ? 'Update' : 'Insert'
                            }</span>}
                        </Button>

                        <Button type="primary" htmlType="reset" danger style={{ marginLeft: '10px' }}>
                            {<span className='textStyles-small'>Reset</span>}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </body>
    );
}

export default AccMasterData;