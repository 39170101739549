import React, { Component } from 'react'
import { InboxOutlined, FileExcelFilled, DownloadOutlined } from '@ant-design/icons';
import { uploadPowerdbuFile, getUploadedPowerDataSheetDetails, powerDataFileValidation, powerDataFileValidationSpecial } from "../../api/index";
import { Upload, Select, Spin, Table, Tag, Popconfirm, message, Drawer, Button } from "antd";
import { ToastContainer, toast } from "material-react-toastify";
const { Option } = Select;
const { Dragger } = Upload;
const { Column } = Table;
const moment = require('moment');


const props: UploadProps = {
    name: 'file',
    multiple: false,
    accept: [".xlsx", ".xls"],
    // multiple: false,

};
class uploadPowerdb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            errorPopLog: false,
            fileMapperData: null,
            selectedUplodeTypeId: null,
            UploadedPowerData: [],
            errorLogTest: null,
            allRowCount: 0,
            totalRowCount: 0,
            updaloRowCount: 0,
            FailRoeCount: 0,
            allFileSet: null,
            invalidData: null,
            setOfSeviceType: [{ "id": 2, "name": "General details", "validationId": 'GENERAL_TABLE' }, { "id": 3, "name": "Air Conditioner", "validationId": 'AIR_CONDITIONER' }, { "id": 4, "name": "SPD", "validationId": 'SPDS' }, { "id": 5, "name": "ATS", "validationId": 'ATS' }, { "id": 6, "name": "DCDU", "validationId": 'DCDU' }, { "id": 7, "name": "FCB", "validationId": 'FCB' }, { "id": 8, "name": "Battery Rack", "validationId": 'ID_BATTERY_RACK' }, { "id": 9, "name": "Renewable Energy Solar", "validationId": 'RENEWABLE_ENERGY_SOLAR' }, { "id": 10, "name": "Renewable Energy Wind", "validationId": 'RENEWABLE_ENERGY_WIND' }, { "id": 11, "name": "Shared Out Operator", "validationId": 'SHARED_OUT_OPERATORS' }, { "id": 12, "name": "Rectifier", "validationId": 'RECTIFIER' }, { "id": 13, "name": "Battery Bank", "validationId": 'BATTERY_BANK' }, { "id": 14, "name": "Cabinet", "validationId": 'CABINET' }, { "id": 15, "name": "Generator", "validationId": 'GENERATOR' }]


        }
    }

    componentDidMount() {
        this.getDataUploadedPowerDataSheetDetails();
    }

    getDataUploadedPowerDataSheetDetails = async () => {
        var data = new FormData();
        data.append('fromDate', '2023-03-29');
        data.append('toDate', moment().format('YYYY-MM-DD'));
        this.showLoading();
        var res = await getUploadedPowerDataSheetDetails(data);
        console.log("----------------");
        console.log(res);
        if (res.success) {
            let newList = res.data
            newList.sort((a, b) => (a.id < b.id) ? 1 : -1)

            console.log(newList)

            this.setState({
                UploadedPowerData: newList
            });
        }

        this.hideLoading();
    }

    onChange = (file) => {
        if (this.state.fileMapperData !== null) {
            this.setState({
                fileMapperData: null
            })
        }
        console.log(file)
        this.setState({
            fileMapperData: file
        })
    }
    showLoading() {
        this.setState({
            isLoading: true
        })
    }


    hideLoading() {
        this.setState({
            isLoading: false
        })
    }


    serviceTypesChange = (e) => {
        console.log(e);
        this.setState({
            invalidData: null,
            selectedUplodeTypeId: e,
            fileMapperData: null
        })
    }
    powerDataFileValidation = async () => {
        if (this.state.selectedUplodeTypeId == null || this.state.fileMapperData === null) {
            message.error('Please select a upload type and upload a file.');
            return;
        }

        //   console.log(serviceTypes)
        let selectedValidationId = this.state.setOfSeviceType.filter(allData => allData.id == this.state.selectedUplodeTypeId)[0].validationId


        if (this.state.selectedUplodeTypeId == 2) {

            const formData = new FormData();
            formData.append('targetTable', selectedValidationId);
            formData.append('file', this.state.fileMapperData.fileList[0].originFileObj);
            var res = await powerDataFileValidationSpecial(formData);
        } else {
            const formData = new FormData();
            formData.append('targetTable', selectedValidationId);
            formData.append('file', this.state.fileMapperData.fileList[0].originFileObj);
            var res = await powerDataFileValidation(formData);
        }

        console.log(res);
        if (res.success) {
            if (res.data.data.totalRows == res.data.data.validRows) {

                this.submitForm()
            } else {
                message.warning('Several invalid rows were found.!')
                this.setState({
                    invalidData: res.data.data.dataProcessingResult.invalidRows
                })

            }

        } else {
            message.warning(' Something went wrong!')
        }




    }
    submitForm = async () => {
        if (this.state.fileMapperData == null) {
            toast.error("Upload document cannot be null");
            return;
        }
        this.showLoading();
        var url = "";
        var formdata = new FormData();
        formdata.append("file", this.state.fileMapperData.file);

        switch (this.state.selectedUplodeTypeId) {
            case 1:
                url = "";
                break;
            case 2:
                url = "/powerSiteData/uploadPowerdbSiteExcelSheet";
                break;
            case 3:
                url = "/powerSiteData/uploadPowerdbAcExcelSheet";
                break;
            case 4:
                url = "/powerSiteData/uploadPowerdbSpdsExcelSheet";
                break;
            case 5:
                url = "/powerSiteData/uploadPowerdbAtsExcelSheet";
                break;
            case 6:
                url = "/powerSiteData/uploadPowerdbDcduExcelSheet";
                break;
            case 7:
                url = "/powerSiteData/uploadPowerdbPcbExcelSheet";
                break;
            case 8:
                url = "/powerSiteData/uploadPowerdbBatteryRackExcelSheet";
                break;
            case 9:
                url = "/powerSiteData/uploadPowerdbRenewbleEnergySolarExcelSheet";
                break;
            case 10:
                url = "/powerSiteData/uploadPowerdbRenewbleEnergyWindExcelSheet";
                break;
            case 11:
                url = "/powerSiteData/uploadPowerdbSharedOutOperatorExcelSheet";
                break;
            case 12:
                url = "/powerSiteData/uploadPowerdbRectifierExcelSheet";
                break;
            case 13:
                url = "/powerSiteData/uploadPowerdbBatteryBankExcelSheet";
                break;
            case 14:
                url = "/powerSiteData/uploadPowerdbCabinetExcelSheet";
                break;
            case 15:
                url = "/powerSiteData/uploadPowerdbGenExcelSheet";
                break;

            default:
                url = "";
                break;
        }
        var res = await uploadPowerdbuFile(formdata, url);

        console.log('res11')
        console.log(res)
        if (res.success) {



            message.success('File successfully uploded!')

            window.location.reload();
        }
        else {

            message.warning(' Something went wrong!')
            this.getDataUploadedPowerDataSheetDetails();

        }
        this.hideLoading()

    }
    errorLogView = (value) => {
        console.log(value)
        this.setState({
            errorLogTest: value?.errorLogs,
            totalRowCount: value?.uploadedRowCount,
            updaloRowCount: value?.savedRowCount,
            FailRoeCount: value?.notSavedRowCount,
            allRowCount: value?.totalRowCount,
            errorPopLog: true
        })

    }

    onDrawerClose = () => {
        this.setState({
            errorPopLog: false
        })
    }
    TimeStamp = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        return `${year}${month}${day}_${hours}${minutes}${seconds}`;
    };
    downloadCSV = () => {
        if (this.state.invalidData.length === 0) {
            message.warning('No error data to download.');
            return;
        }

        let selectedValidationId = this.state.setOfSeviceType.filter(allData => allData.id == this.state.selectedUplodeTypeId)[0].name

        const csvContent = [
            Object.keys(this.state.invalidData[0]).join(','),
            ...this.state.invalidData.map((row) => Object.values(row).map((value) => `"${value}"`).join(',')),
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.TimeStamp() + '_ErrorLog_' + selectedValidationId + '.csv';
        link.click();
    };

    downloadTemplate = () => {
        if (this.state.selectedUplodeTypeId == null) {
            message.error('Please select a upload type ');
            return;
        }
        var url = 'https://storage.googleapis.com/dns_storage_v2/powerdbfile/';
        switch (this.state.selectedUplodeTypeId) {
            case 1:
                url = "";
                break;
            case 2:
                url = url.concat("General Details.xlsx");;
                break;
            case 3:
                url = url.concat("AirConditioner.xlsx");
                break;
            case 4:
                url = url.concat("Spds.xlsx");
                break;
            case 5:
                url = url.concat("Ats.xlsx");
                break;
            case 6:
                url = url.concat("Dcdu.xlsx");
                break;
            case 7:
                url = url.concat("Fcb.xlsx");
                break;
            case 8:
                url = url.concat("IDBatteryRack.xlsx");
                break;
            case 9:
                url = url.concat("RenewableEnergySolar.xlsx");
                break;
            case 10:
                url = url.concat("RenewableEnergyWind.xlsx");
                break;
            case 11:
                url = url.concat("SharedOutOperators.xlsx");
                break;
            case 12:
                url = url.concat("Rectifier.xlsx");
                break;
            case 13:
                url = url.concat("BatteryBank.xlsx");
                break;
            case 14:
                url = url.concat("Cabinet.xlsx");
                break;
            case 15:
                url = url.concat("Generator.xlsx");
                break;
            default:
                url = "";
                break;
        }
        window.open(url, '_blank', 'noreferrer')
    }

    render() {
        var serviceTypes = [{ "id": 2, "name": "General details", "validationId": 'General_details' }, { "id": 3, "name": "Air Conditioner", "validationId": 'AIR_CONDITIONER' }, { "id": 4, "name": "SPD", "validationId": 'SPDS' }, { "id": 5, "name": "ATS", "validationId": 'ATS' }, { "id": 6, "name": "DCDU", "validationId": 'DCDU' }, { "id": 7, "name": "FCB", "validationId": 'FCB' }, { "id": 8, "name": "Battery Rack", "validationId": 'ID_BATTERY_RACK' }, { "id": 9, "name": "Renewable Energy Solar", "validationId": 'RENEWABLE_ENERGY_SOLAR' }, { "id": 10, "name": "Renewable Energy Wind", "validationId": 'RENEWABLE_ENERGY_WIND' }, { "id": 11, "name": "Shared Out Operator", "validationId": 'SHARED_OUT_OPERATORS' }, { "id": 12, "name": "Rectifier", "validationId": 'RECTIFIER' }, { "id": 13, "name": "Battery Bank", "validationId": 'BATTERY_BANK' }, { "id": 14, "name": "Cabinet", "validationId": 'CABINET' }, { "id": 15, "name": "Generator", "validationId": 'GENERATOR' }];
        const invalidColumns = [
            {
                title: 'Row Number',
                dataIndex: 'rowNumber',
                key: 'rowNumber'
            },
            {
                title: 'Row Data',
                dataIndex: 'rowData',
                key: 'rowData',
                render: (rowData) => rowData.join(', ')
            },
            {
                title: 'Error Messages',
                dataIndex: 'errorMessages',
                key: 'errorMessages',
                render: (errorMessages) => (
                    <div>
                        {errorMessages.map((message, index) => (
                            <ul>
                                <li key={index}>{message}</li>
                            </ul>
                        ))}
                    </div>
                ),
            },
        ];
        return (
            <Spin spinning={this.state.isLoading} delay={200}>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="page-title-box">
                                <div class="row">
                                    <div class="col">
                                        <h4 class="page-title">Upload Powerdb</h4>
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item"><a href="javascript:void(0);">Powerdb </a></li>
                                            <li class="breadcrumb-item"><a href="javascript:void(0);">Upload Powerdb</a></li>

                                        </ol>
                                    </div>
                                    <div class="col-auto align-self-center">
                                        <div class="btn btn-sm btn-outline-primary" id="Dash_Date">
                                            <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                                            <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="row">
                            <div class="col-sm-1">
                                <label class="form-label" for="exampleInputEmail1">Upload Type </label>
                            </div>
                            <div class="col-sm-3">
                                <Select
                                    // mode="multiple"
                                    showSearch
                                    name="regionId"
                                    onChange={(e) => this.serviceTypesChange(e)}
                                    placeholder="Upload Type"
                                    style={{ width: "100%" }}
                                    value={this.state.selectedUplodeTypeId}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                >
                                    {serviceTypes.map((item, index) => {
                                        return <Option key={index} value={item.id}>{item.name}</Option>;
                                    })}
                                </Select>
                                {/* {this.state.organizationError && <p style={{ color: "red", fontSize: '10px' }}>The organization field is required.</p>} */}
                            </div>
                        </div>

                        <div style={{ "marginTop": 20 }}>
                            <button class="btn btn-outline-secondary" onClick={this.downloadTemplate}>
                                <i class="fas fa-download" style={{ "marginRight": 8 }}></i>
                                Download {serviceTypes.map((item) => {
                                    return (item.id == this.state.selectedUplodeTypeId) ? item.name : "";
                                })} Template
                            </button>
                        </div>

                        <div style={{ "marginTop": 30 }}>
                            <Dragger {...props} onChange={this.onChange} beforeUpload={() => false} multiple={false} maxCount={1}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                <p className="ant-upload-hint">
                                    Support for a single upload. Strictly prohibit from uploading company data or other
                                    band files
                                </p>
                            </Dragger>
                        </div>
                        <Popconfirm
                            title="Are You Sure?"
                            description="Are you sure to delete this task?"
                            onConfirm={this.powerDataFileValidation}
                            okText="Yes"
                            cancelText="No"
                        >
                            <button style={{ "marginTop": 20 }} class="btn btn-primary"  >Submit</button>
                        </Popconfirm>
                        {this.state.invalidData != null ? (<><br></br><br></br> <div className='row'>
                            <div className='row' >
                                <div className='col-md-8'>

                                </div>
                                {/* <div className='col-md-4' style={{ textAlign: 'end' }} >
                                    <Button type="primary" onClick={this.downloadCSV} >
                                        Download Error Data as CSV
                                    </Button>
                                </div> */}

                            </div>
                            <br></br>
                            <br></br>
                            <div className="col-md-12 col-lg-12">
                                <div className="card">
                                    <div className="card-header bg-warning">

                                        <div className="row align-items-center">
                                            <div className="col">
                                                <h4 className="card-title">Invalid Data</h4>
                                            </div>
                                            <div className="col-auto">
                                                {/* <Button type="primary" onClick={this.downloadCSV} >
                                                    Download Error Data as CSV
                                                </Button> */}
                                                <button type="button" onClick={this.downloadCSV} class="btn btn-primary btn-sm"> Download Error Data as CSV</button>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="card-body">
                                        <Table dataSource={this.state.invalidData} columns={invalidColumns} onRow={(record) => {
                                            // return {
                                            //     onClick: () => handleRowClick(record.rowData, record.errorMessages),
                                            // };
                                        }} />

                                    </div>

                                </div>


                            </div>


                        </div></>) : ('')}
                        <br></br>
                        <br></br>
                        <div className="col-md-12 col-lg-12">
                            <div className="card">
                                <div className="card-header ">


                                    <h4 class="card-title">Uploaded Files</h4>


                                </div>
                                <div className="card-body">
                                    <ToastContainer position="top-right" autoClose={5000} />

                                    <Table style={{ marginTop: 26, padding: 5 }} dataSource={this.state.UploadedPowerData}>
                                        <Column title="File Name" dataIndex="uploadedFileName" key="uploadedFileName" />
                                        <Column title="Created Time" dataIndex="createdTime" key="createdTime" render={(value, item, index) => moment(value).format('YYYY-MM-DD HH:mm:ss')} />
                                        <Column title="Type" dataIndex="type" key="type" />
                                        <Column title="Upload Status" dataIndex="uploadedStatus" key="uploadedStatus" render={(value, item, index) => value ? <Tag color='#52c41a'>Successful</Tag> : <Tag color='#FFA500'>Pending</Tag>} />
                                        <Column title="Upload User" dataIndex="user" key="user" render={(value, item, index) => value.name} />
                                        <Column title="Upload User email" dataIndex="user" key="user" render={(value, item, index) => value.email} />
                                        <Column title="Upload User Mobile No" dataIndex="user" key="user" render={(value, item, index) => value.mobile_number} />
                                        <Column title="Error Log" key="errorLogs" render={(value, item, index) => value.errorLogs ? (<a onClick={() => this.errorLogView(value)}> <i class="fa fa-eye" style={{ color: "coral" }} ></i></a>) : ('')} />

                                    </Table>
                                </div>
                            </div>
                        </div>

                        <Drawer title={"Upload Powerdb Error Log"} style={{ zIndex: 0 }} width={"50%"} placement="right" onClose={() => this.onDrawerClose()} visible={this.state.errorPopLog}>
                            <div class="row mt-4">
                                <div class="col-3 text-center align-items-center">
                                    <h5 class="mb-0 fw-semibold font-20">{this.state.allRowCount}</h5>
                                    <p class="font-14 mb-1 text-muted">Total</p>
                                </div>
                                <div class="col-3 text-center align-items-center">
                                    <h5 class="mb-0 fw-semibold font-20">{this.state.totalRowCount}</h5>
                                    <p class="font-14 mb-1 text-muted">Uploaded</p>
                                </div>
                                <div class="col-3 text-center align-items-center">
                                    <h5 class="mb-0 fw-semibold font-20">{this.state.updaloRowCount}</h5>
                                    <p class="font-14 mb-1 text-muted">Successful</p>
                                </div>
                                <div class="col-3 text-center align-items-center">
                                    <h5 class="mb-0 fw-semibold font-20">{this.state.FailRoeCount}</h5>
                                    <p class="font-14 mb-1 text-muted">Failed</p>
                                </div>
                            </div>
                            <br></br>
                            <div class="card" style={{ backgroundColor: "#ffe6e6" }}>

                                <div class="card-body">


                                    {this.state.errorLogTest}
                                </div>
                            </div>
                        </Drawer>
                    </div>
                </div>

            </Spin>

        )
    }
}
export default uploadPowerdb; 
