import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  message,
  notification,
  Row,
  Select,
  Table,
  Empty,
  Modal,
  Tag
} from "antd";
import { data_set, gen_models } from "./data";
import { CSVLink, CSVDownload } from "react-csv";
import {
  CloseCircleOutlined,
  CopyOutlined,
  DatabaseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
  FilterOutlined,
  HistoryOutlined,
  HomeOutlined,
  SendOutlined,
  SaveOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import "./App.css";
import {
  getPowerDbFilterData,
  getAllQueryTemplates,
  getQueryTemplatesCreate,
  getQueryTemplatesDelete,
  getAllDropdowns,
} from "../../api/index";
import { compose } from "redux";
import { headers } from "../../api/api.config";
import { get } from "../../api/api.client";
import '../master-data/style.css';
import moment from "moment";

//import { ToastContainer, toast } from 'material-react-toastify';
//import 'material-react-toastify/dist/ReactToastify.css';

const { Option } = Select;

const QueryBuilder = () => {
  let firstTable = "";
  let relation = "";
  let firstTablePrimaryStatus = false;
  var tableList = [];
  const [formItems, setFormItems] = useState([
    {
      field_name: "",
      label: "",
      operator: "",
      selectedOperator: "",
      value: "",
      query_name: "",
      extra_fields: [],
      detail_view: false,
    },
  ]);
  const [sql, setSql] = useState("");
  const [operatorOptions, setOperatorOptions] = useState({});
  const [filterList, setFilterList] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [generatedModels, setGeneratedModels] = useState([]);
  const [boolState, setBoolState] = useState(false);
  const [openDrawerDetails, setOpenDrawerDetails] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [filteredModels, setFilteredModels] = useState(generatedModels);
  const [messageApi, contextHolder] = message.useMessage();
  const [savedData, setSavedData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [fieldValidator, setFieldValidator] = useState("");
  const [isRapidTemplate, setIsRapidTemplate] = useState(false);
  const [templateItems, setTemplateItems] = useState([
    {
      templateName: "",
      templateDescription: "",
      headers: "",
      queryBody: "",
      createdAt: "",
      createdBy: "",
      id: "",
    },
  ]);
  const [showRapidFilterSection, setShowRapidFilterSection] = useState(false);
  const [showFilterSection, setShowFilterSection] = useState(true);
  const [showNormalFilterSection, setShowNormalFilterSection] = useState(true);
  const [fieldNames, setFieldNames] = useState([]); 
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(null);
  const handleFilterSection = (query, headers) => {
    resetTableSection();
    setShowNormalFilterSection(!showNormalFilterSection);
    setShowFilterSection(!showFilterSection);
    setShowRapidFilterSection(true);
    setFieldNames(headers);
    setSql(query);
  };
  const [assetsTypes, setAssetsTypes] = useState({
    dcduTypes: [],
    cabinetTypes: [],
  });

  const date = new Date();

  const handleSearch = (value) => {
    console.log(savedData);
    const lowercasedValue = value.toLowerCase();
    const filteredItems = savedData.filter((item) =>
      item.templateName.toLowerCase().includes(lowercasedValue)
    );
    setFilteredData(filteredItems);
    setSearchText(value);
  };

  // Load All Data When The Page Loads
  useEffect(() => {
    getAssetsTypes();
  }, []);

  useEffect(() => {
    console.log(assetsTypes);
  }, [assetsTypes]);

  const getAssetsTypes = async () => {
    var res = await getAllDropdowns();
    if (res.success) {
      setAssetsTypes({
        dcduTypes: res.data["Access Technologies"]["GSM"],
        cabinetTypes: res.data["Access Technologies"]["GSM"],
      });
    } else {
      message.warning("Some Data Can't Be Loaded");
    }
    console.log("===============================+++++++++++++++++++++++++++==============================");
    console.log(assetsTypes);
  };

  // Function to create a new template in the database
  const createTemplate = async () => {
    const fieldNames = filterList.map((item) => item.display_name);
    const requestBody = {
      createdBy: localStorage.getItem("userId"),
      templateName: templateName,
      templateDescription: "SQL Query Template Description",
      headers: fieldNames.join(","),
      queryBody: sql,
    };
    try {
      var res = await getQueryTemplatesCreate(requestBody);
      console.log(res);
      if (res.success) {
        if (res.data.status === "Error") {
          notification.error({
            message: "Template Creation Failed",
            description: res.data.message,
          });
        } else {
          console.log("Template created successfully:");
          notification.success({
            message: "Template Created Successfully",
            description:
              "Template Created Successfully, You can view the template in the template list, And you can use it in the future.",
          });
        }
      } else {
        notification.error({
          message: "Template Creation Failed",
          description: res?.data?.message,
        });
      }
    } catch (error) {
      notification.error({
        message: "Template Creation Failed",
        description: error.message,
      });
    }
  };
  
  // Function to retrieve all templates from the database
  const retriveAllTemplates = async () => {
    var res = await getAllQueryTemplates(localStorage.getItem("userId"));

    if (res.success) {
      console.log(res.data);
      if (res.data.status === "Error") {
        notification.error({
          message: "Template Retrieval Failed",
          description: res.data.message,
        });
      } else {
        console.log("Template retrieved successfully:", res.data.value);
        setSavedData(res.data.value);
      }
    } else {
      notification.error({
        message: "Template Retrieval Failed",
      });
    }
  };

  // Remove Template From The List and From The Database
  const deleteTemplate = async (templateId) => {
    try {
      var res = await getQueryTemplatesDelete(templateId);

      console.log(res);
      if (res.success) {
        if (res.data.status === "Error") {
          notification.error({
            message: "Template Deletion Failed",
            description: res.data.message,
          });
        } else {
          console.log("Template deleted successfully:");
          prepareData();
          notification.success({
            message: "Template Deleted Successfully",
            description:
              "Template Deleted Successfully, Template is no longer available in the template list.",
          });
        }
      } else {
        notification.error({
          message: "Template Deletion Failed",
          description: res?.data?.message,
        });
      }
    } catch (error) {
      notification.error({
        message: "Template Deletion Failed",
        description: error.message,
      });
    }
  };

  // Confirmation Modal to Delete the Template
  const showDeleteConfirmationModal = (templateId) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to delete this Template?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => deleteTemplate(templateId),
    });
  };

  // This function is used to show the modal and Handle Logic Behind it
  const showModal = () => {
    setIsModalVisible(true);
  };
  
  const handleOk = () => {
    console.log("Template Name:", templateName);
    setIsModalVisible(false);
    createTemplate();
  };
  
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleReload = () => {
    window.location.pathname = window.location.pathname;
  };

  const resetRapidFilterSection = () => {
    setShowRapidFilterSection(false);
    setShowFilterSection(true);
    setShowNormalFilterSection(true);

    setSql("");
    setFilterList([]);
    setApiData([]);
    setGeneratedModels([]);
    setBoolState(false);
  };

  const resetTableSection = () => {
    setApiData([]);
  };

  // That Function is used to get the operator options
  const filteredOptions = (value) => {
    var data = [];
    for (let i = 0; i < value.length; i++) data.push(data_set.fields[value[i]]);
    setFilterList(data);
  };

  // This function is used to add new form item
  const handleAddFormItem = () => {
    const newItem = {
      field_name: "",
      label: "",
      operator: "",
      value: "",
      query_name: "",
      extra_fields: [],
      detail_view: false,
      response_type: "",
      display_name: "",
    };
    setFormItems([...formItems, newItem]);
  };

  // This function is used to get the model data
  const fetchModelData = async (sql, fieldNames, targetData) => {
    console.log("IN THIS FUNCTION 01 ================================================================");
    console.log(targetData);
    if (targetData.toLowerCase() === "DCDU- Type") {
      console.log("IN THIS FUNCTION 02 ================================================================");
      const models = getAssetsTypes.dcduTypes.map((name, index) => ({
        id: (index + 1).toString(),
        model: name,
      }));
      setGeneratedModels(models);
    } else {
      const formData = new FormData();
      formData.append("query", sql);
      formData.append("headers", fieldNames);
      var generatedModels = [];
      var res = await getPowerDbFilterData(formData);
      if (res.success) {
        if (Array.isArray(res.data)) {
          const uniqueNames = [...new Set(res.data.map((item) => item.name))];
          console.log("Unique Names: ", uniqueNames);
          const models = uniqueNames.map((name, index) => ({
            id: (index + 1).toString(),
            model: name,
          }));
          setGeneratedModels(models);
        } else {
          console.error("API Response Data is not an array");
        }
      }
    }

    //label
  };

  // This function is used to remove the form item
  const handleRemoveFormItem = (index) => {
    const updatedFormItems = formItems.filter((item, i) => i !== index);
    setFormItems(updatedFormItems);
  };

  // This function is used to get the details
  const getDetails = (index) => {
    const updatedFormItems = formItems.filter((item, i) => i === index);
    console.log("------------------------------------------------------------------------------");
    console.log(updatedFormItems);
    if (updatedFormItems.length === 1) {
      if (updatedFormItems[0].detail_view === true) {
        const execute_query = updatedFormItems[0].query_name;
        const extra_fields = updatedFormItems[0].extra_fields;
        const label = updatedFormItems[0].label;
        fetchModelData(execute_query, extra_fields, label);
      }
    } else {
      console.log("No matching item found or multiple items found.");
    }
  };

  // This function is used to handle the label change
  const handleLabelChange = (index, value) => {
    var selectedItem = data_set.fields.filter(function (el) {
      return el.display_name === value;
    });

    if (selectedItem.length == 0) {
      return;
    }

    const updatedFormItems = [...formItems];
    updatedFormItems[index].label = selectedItem[0].field_name;
    updatedFormItems[index].field_name = selectedItem[0].field_name;
    updatedFormItems[index].table_prefix = selectedItem[0].table_prefix;
    updatedFormItems[index].table_name = selectedItem[0].table_name;
    updatedFormItems[index].response_type = selectedItem[0].response_type;
    updatedFormItems[index].operator = selectedItem[0].operotors;
    updatedFormItems[index].selectedOperator = "";
    updatedFormItems[index].value = "";
    updatedFormItems[index].query_name = selectedItem[0].query_name;
    updatedFormItems[index].extra_fields = selectedItem[0].extra_fields;
    updatedFormItems[index].detail_view = selectedItem[0].detail_view;
    updatedFormItems[index].response_type = selectedItem[0].response_type;
    updatedFormItems[index].display_name = selectedItem[0].display_name;

    console.log(selectedItem[0].detail_view);

    if (selectedItem[0].detail_view === true) {
      setBoolState(true);
    }
    setFormItems(updatedFormItems);
    var data = {};
    data[value] = selectedItem[0].operotors;
    setOperatorOptions(data);
    console.log(formItems);
  };

  const handleFormItemChange = (index, key, value) => {
    const updatedFormItems = [...formItems];
    updatedFormItems[index][key] = value;
    console.log(updatedFormItems);
    setFormItems(updatedFormItems);
  };

  function isValidResponseType(inputType, inputValue) {
    // Define an array of valid response types
    const validResponseTypes = ["text", "number"];

    // Check if the inputType is a valid response type
    if (validResponseTypes.includes(inputType)) {
      // Depending on the response type, you can perform additional checks on the inputValue
      if (inputType === "text") {
        // Check if the inputValue is a string
        setFieldValidator(true);
        return typeof inputValue === "string";
      } else if (inputType === "number") {
        // Check if the inputValue is a number
        setFieldValidator(true);
        return typeof inputValue === "number";
      }
    }
    setFieldValidator(false);
    return false; // Input type is not valid or doesn't match the input value's type
  }

  const generateSQLQuery = () => {
    setApiData([]);

    let selectedItems = "";
    for (let i = 0; i < filterList.length; i++) {
      selectedItems +=
        filterList[i].table_prefix +
        "." +
        filterList[i].field_name +
        " " +
        filterList[i].display_name +
        (filterList.length > 1 && filterList.length !== i + 1 ? "," : "");
    }
    let sqlQuery = "SELECT " + selectedItems + " FROM ";

    if (filterList.length > 0 && filterList[0].field_name !== "") {
      // var selectedItem = data_set.fields.filter(function (el) {
      //     return el.field_name === filterList[0].field_name;
      // });
      firstTable = filterList.length > 1 ? "site" : filterList[0].table_name;
      tableList.push(filterList.length > 1 ? "site" : filterList[0].table_name);
      firstTablePrimaryStatus =
        filterList.length > 1 ? true : filterList[0].is_primary_table;
      let table_prefix =
        filterList.length > 1 ? "tbl_site" : filterList[0].table_prefix;
      sqlQuery += `${firstTable} ${table_prefix}`;

      for (let i = filterList.length > 1 ? 0 : 1; i < filterList.length; i++) {
        var selectedItem = data_set.fields.filter(function (el) {
          return (
            el.field_name === filterList[i].field_name &&
            el.table_name === filterList[i].table_name
          );
        });

        const tableName = selectedItem[0].table_name;
        const table_prefix = selectedItem[0].table_prefix;
        console.log(tableList);
        console.log(tableName);
        console.log(checkTableAlreadyAvailable(tableName));
        console.log("------------------------------------------");
        if (!checkTableAlreadyAvailable(tableName)) {
          if (selectedItem[0].is_primary_table && firstTablePrimaryStatus) {
            const joinCondition = getJoinCondition(firstTable, tableName);
            sqlQuery += ` INNER JOIN ${tableName} ${table_prefix} ON ${joinCondition}`;
          } else {
            if (
              !checkTableAlreadyAvailable(selectedItem[0].primary_table_name)
            ) {
              const primaryJoinCondition = getJoinCondition(
                firstTable,
                selectedItem[0].primary_table_name
              );
              sqlQuery += ` INNER JOIN ${selectedItem[0].primary_table_name} ${selectedItem[0].primary_table_prefix} ON ${primaryJoinCondition}`;
            }
            const joinCondition = getJoinCondition(
              selectedItem[0].primary_table_name,
              tableName
            );
            sqlQuery += ` INNER JOIN ${tableName} ${table_prefix} ON ${joinCondition}`;
          }
        }
      }
      if (formItems.length > 0 && formItems[0].field_name !== "") {
        for (let i = 0; i < formItems.length; i++) {
          const item = formItems[i];
          if (i === 0) {
            sqlQuery += ` WHERE ${item.table_prefix}.${item.label} ${item.selectedOperator}`;
            if (item.selectedOperator == "LIKE") {
              sqlQuery +=
                item.response_type == "text"
                  ? ` '%${item.value}%'`
                  : ` ${item.value}`;
            } else {
              sqlQuery +=
                item.response_type == "text"
                  ? `'${item.value}'`
                  : ` ${item.value}`;
            }
          } else {
            if (item.selectedOperator == "LIKE") {
              sqlQuery += ` AND ${item.table_prefix}.${item.label} ${item.selectedOperator} '%${item.value}%'`;
            } else {
              sqlQuery += ` AND ${item.table_prefix}.${item.label} ${item.selectedOperator} ${item.value}`;
            }
          }
        }
      }
    } else {
      if (filterList.length > 0 && filterList[0].field_name !== "") {
        sqlQuery += filterList[0].table_name;
      } else {
        sqlQuery = "";
      }
    }
    setSql(sqlQuery);
  };

  // This function is used to check the table already available
  const checkTableAlreadyAvailable = (tableName) => {
    const list = tableList;
    const check = list.filter(function (el) {
      return el === tableName;
    });

    if (check.length > 0) {
      return true;
    }
    return false;
  };

  // This function is used to copy the text
  const copyText = (text) => {
    navigator.clipboard.writeText("");
    navigator.clipboard.writeText(text);
    setGeneratedModels([]);
    setOpenDrawerDetails(false);
  };

  const getJoinCondition = (tableName, primaryTable) => {
    const count = tableList.filter(function (el) {
      return el === tableName;
    });
    if (count.length == 0) {
      tableList.push(tableName);
    }

    const primaryTableCOunt = tableList.filter(function (el) {
      return el === primaryTable;
    });
    if (primaryTableCOunt.length == 0) {
      tableList.push(primaryTable);
    }

    relation = data_set.relations.filter(function (el) {
      return el.main_table === primaryTable && el.sub_table === tableName;
    })[0];
    if (relation === undefined) {
      relation = data_set.relations.filter(function (el) {
        return el.main_table === tableName && el.sub_table === primaryTable;
      })[0];
    }

    return (
      relation.main_table_prefix +
      "." +
      relation.main_table_id +
      " = " +
      relation.sub_table_prefix +
      "." +
      relation.sub_table_id
    );
  };

  const onClose = () => {
    setOpenDrawerDetails(false);
  };

  const apiResponse = () => {
    const fieldNames = filterList.map((item) => item.display_name);
    fetchApiData1(fieldNames.join(","));
  };
  const rapidAPIResponse = () => {
    fetchApiData(fieldNames);
  };
  // Function to fetch data from the API

  
  
  const fetchApiData1 = async (fieldNames) => {
    const formData = new FormData();
    formData.append("query", sql);
    formData.append("headers", fieldNames);

    //const requestTimestamp = new Date().toISOString(); // Get current date and time as a string
    var res = await getPowerDbFilterData(formData);

    if (res.success) {
      console.log("API Response Data: ", res.data);
      setApiData(res.data); // Set the API data in the state
      
      // const requestData = {
      //   query: sql,
      //   headers: fieldNames,
      //   responseCode: res.status,
      // };
      // localStorage.setItem(requestTimestamp, JSON.stringify(requestData));

      notification.success({
        message: "Success",
        description: "API request was successful.",
      });
    } else {
      notification.error({
        message: "Error",
        description: "Error fetching data: " + res.message,
      });
    }
  };

  const fetchApiData = async (fieldNames) => {
    const formData = new FormData();
    formData.append("query", sql);
    formData.append("headers", fieldNames);

    var res = await getPowerDbFilterData(formData);

    if (res.success) {
      console.log("API Response Data: ", res.data);
      setApiData(res.data); // Set the API data in the state
      notification.success({
        message: "Success",
        description: "API request was successful.",
      });
    } else {
      notification.error({
        message: "Error",
        description: "Error fetching data: " + res.message,
      });
    }
  };

  

  // const fetchApiData = (fieldNames) => {
  //   const formData = new FormData();
  //   formData.append("query", sql);
  //   formData.append("headers", fieldNames);

  //   const requestTimestamp = new Date().toISOString(); // Get current date and time as a string

  //   fetch("https://dns.eimsky.com/common/powerSiteData/getPowerDbFilterData", {
  //     method: "POST",
  //     body: formData,
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       console.log("API Response Code : ", response.status);
  //       return response
  //         .json()
  //         .then((data) => ({ data, status: response.status }));
  //     })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         console.log("API Response Data: ", response.data);
  //         setApiData(response.data); // Set the API data in the state

  //         const requestData = {
  //           query: sql,
  //           headers: fieldNames,
  //           responseCode: response.status,
  //         };

  //         if (isRequestDataAlreadyStored(requestData)) {
  //           console.log("Request data already exists in localStorage.");
  //         } else {
  //           const storageKey = requestTimestamp;
  //           localStorage.setItem(storageKey, JSON.stringify(requestData));
  //           console.log("Request data saved successfully.");
  //         }
  //         notification.success({
  //           message: "Success",
  //           description: "API request was successful.",
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //       // Show an error notification
  //       notification.error({
  //         message: "Error",
  //         description: "Error fetching data: " + error.message,
  //       });
  //     });

  //   retrieveSavedData();
  // };
  
  // const isRequestDataAlreadyStored = (requestData) => {
  //   return Object.keys(localStorage).some((key) => {
  //     try {
  //       const storedData = JSON.parse(localStorage.getItem(key));
  //       // Customize the comparison logic based on your data structure
  //       return (
  //         storedData &&
  //         storedData.query === requestData.query &&
  //         storedData.headers === requestData.headers
  //       );
  //     } catch (error) {
  //       // Handle potential JSON parsing errors
  //       console.error("Error parsing stored data:", error);
  //       return false;
  //     }
  //   });
  // };

  // // Function to retrieve saved data from local storage
  const retrieveSavedData = () => {
    const data = [];
    for (let key in localStorage) {
      if (localStorage.hasOwnProperty(key)) {
        try {
          const item = JSON.parse(localStorage.getItem(key));
          if (item) {
            console.log("Item: ", item);
            data.push({ timestamp: key, ...item });
          }
        } catch (error) {
          console.error("Error parsing local storage data:", error);
        }
      }
    }
    setSavedData(data);
  };

  const prepareData = () => {
    //setSavedData([]);
    retriveAllTemplates();
    setVisible(true);
  };

  const columns = apiData[0]
    ? Object.keys(apiData[0]).map((key) => ({
        title: key
          .replace(/_/g, " ")
          .replace("value", "")
          .replace(/\b\w/g, (l) => l.toUpperCase()), // Format the title here
        dataIndex: key,
        key,
      }))
    : [];

  const updateFilterText = (e) => {
    const searchText = e.target.value;
    setFilterText(searchText);

    if (searchText) {
      setFilterText(searchText);

      const filtered = generatedModels.filter((item) =>
        item.model.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredModels(filtered);
    } else {
      setFilterText("");
      setFilteredModels("");
    }
  };

  const spanStyles = {
    fontWeight: "bold",
    fontSize: "14.5px",
  };

  const dataShow = {
    fontSize: "14.5px",
    marginTop: "20px",
  };
  const alignment = {
    padding: "10px",
  };

  return (
    <div style={{ marginTop: "5px" }} className="filter-container">
      <div className="row">
        <div className="col-sm-12">
          <div className="page-title-box">
            <div className="row">
              <div className="col">
                <h4 className="page-title" style={{ fontWeight: "bold" }}>
                  Power Data
                </h4>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="javascript:void(0);">Power DB</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="javascript:void(0);">Power Data</a>
                  </li>
                </ol>
              </div>
              <div className="col-auto align-self-center">
                <Button
                  type="primary"
                  onClick={() => prepareData()}
                  style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px', height: 33 }}
                >
                  <span className='textStyles-small'><HistoryOutlined style={{marginRight: 10}}/> Saved Templates</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Drawer
        title={<span className="textStyles-small" style={{ fontWeight: "bold", fontSize: 14 }}>Saved Templates</span>}
        width={500}
        onClose={() => setVisible(false)}
        visible={visible}
        onShow={retrieveSavedData}
      >
        <div>
          <Input
            placeholder="Search by template name"
            style={{ width: '100%', height: 33, marginBottom: 10 }}
            className='borderedSelect'
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
          />
          {filteredData != null ? (
            <>
              {[...filteredData]
                .sort((a, b) => b.id - a.id)
                .map((dataItem, index) => (
                  <div
                    key={index}
                    className="action-container"
                    style={alignment}
                  >
                    <span className="textStyles-small" style={{ fontSize: 14, fontWeight: 'bold' }}>{dataItem.templateName}</span>
                    <div style={{ backgroundColor: "#f0f0f0", padding: 10, borderRadius: 10, marginTop: 10 }}>
                      <Row>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                          <CopyOutlined
                            style={{ fontSize: 14, cursor: 'pointer' }}
                            onClick={() => {
                              copyText(dataItem.queryBody);
                              message.success("Query Copied Successfully")
                            }
                            } />
                        </div>
                      </Row>
                      <span className="textStyles-small">{dataItem.queryBody}</span>
                    </div>
                    <span className="textStyles-small" style={{ marginTop: '10px' }}>Target Headers:</span>
                    <div>
                      {dataItem.headers.split(',').map((item, index) => {
                        return index === 0 ? <Tag style={{ margin: 3 }} color="blue" key={index}><span style={{ fontSize: 11 }} className="textStyles-small">
                          {item.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                        </span></Tag> :
                          <Tag color="blue" style={{ margin: 3 }} key={index}><span style={{ fontSize: 11 }} className="textStyles-small">
                            {item.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                          </span></Tag>;
                      })}
                    </div>
                    <br />
                    <span className="textStyles-small" style={{ color: 'gray', marginTop: 10 }}>Create Date: {moment(dataItem.createdAt).format('YYYY-MM-DD HH:mm')}</span>
                    <br />
                    <Row>
                      <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <Button
                          shape="circle"
                          type="primary"
                          size="small"
                          onClick={() => {
                            handleFilterSection(
                              dataItem.queryBody,
                              dataItem.headers
                            );
                            setVisible(false);
                          }}
                          icon={<EyeOutlined />}
                        />
                        <Button
                          shape="circle"
                          type="primary"
                          size="small"
                          danger
                          style={{ marginLeft: "10px" }}
                          onClick={() => {
                            showDeleteConfirmationModal(dataItem.id);
                            setVisible(false);
                          }}
                          icon={<DeleteOutlined />}
                        />
                      </div>
                    </Row>
                  </div>
                ))}
            </>
          ) : (
            <>
              {[...savedData]
                .sort((a, b) => b.id - a.id)
                .map((dataItem, index) => (
                  <div
                    key={index}
                    className="action-container"
                    style={alignment}
                  >
                    <span className="textStyles-small" style={{ fontSize: 14, fontWeight: 'bold' }}>{dataItem.templateName}</span>
                    <div style={{ backgroundColor: "#f0f0f0", padding: 10, borderRadius: 10, marginTop: 10 }}>
                      <Row>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                          <CopyOutlined
                            style={{ fontSize: 14, cursor: 'pointer' }}
                            onClick={() => {
                              copyText(dataItem.queryBody);
                              message.success("Query Copied Successfully")
                            }
                            } />
                        </div>
                      </Row>
                      <span className="textStyles-small">{dataItem.queryBody}</span>
                    </div>
                    <span className="textStyles-small" style={{ marginTop: '10px' }}>Target Headers:</span>
                    <div>
                      {dataItem.headers.split(',').map((item, index) => {
                        return index === 0 ? <Tag style={{ margin: 3 }} color="blue" key={index}><span style={{ fontSize: 11 }} className="textStyles-small">
                          {item.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                        </span></Tag> :
                          <Tag color="blue" style={{ margin: 3 }} key={index}><span style={{ fontSize: 11 }} className="textStyles-small">
                            {item.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                          </span></Tag>;
                      })}
                    </div>
                    <br />
                    <span className="textStyles-small" style={{ color: 'gray', marginTop: 10 }}>Create Date: {moment(dataItem.createdAt).format('YYYY-MM-DD HH:mm')}</span>
                    <br />
                    <Row>
                      <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <Button
                          shape="circle"
                          type="primary"
                          size="small"
                          onClick={() => {
                            handleFilterSection(
                              dataItem.queryBody,
                              dataItem.headers
                            );
                            setVisible(false);
                          }}
                          icon={<EyeOutlined />}
                        />
                        <Button
                          shape="circle"
                          type="primary"
                          size="small"
                          danger
                          style={{ marginLeft: "10px" }}
                          onClick={() => {
                            showDeleteConfirmationModal(dataItem.id);
                            setVisible(false);
                          }}
                          icon={<DeleteOutlined />}
                        />
                      </div>
                    </Row>
                  </div>
                ))}
            </>
          )}
        </div>
      </Drawer>
      
      {showFilterSection && (
        <div id="filterSection">
          <div style={{ marginTop: 10, backgroundColor: 'white', padding: 10, borderRadius: 5 }}>
            <Form className="action-container" style={{marginTop: 10}}>
              <Form.Item className="main-dropdown"> 
                <Select
                  mode="multiple"
                  onChange={(value) => filteredOptions(value)}
                  placeholder="Please Select"
                  showSearch={false}
                  // filterOption={(input, option) =>
                  //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  // }
                  style={{ width: '100%', height: 'auto' }}
                  className='borderedSelect'
                  bordered={false}
                >
                  {data_set.fields.map((column, index) => (
                    <Option key={index} value={index}>
                      <span className="textStyles-small">{column.label}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>

            <span className="textStyles-small" style={{ fontSize: 14, fontWeight: 'bold' }}>Filters And Actions</span>
            <Form>

            <Button
                  type="dashed"
                  onClick={handleAddFormItem}
                  style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px', height: 33 }}
                  className="main-dropdown"
                  icon={<FilterOutlined style={{color: 'white'}} />}
                >
                  <span className='textStyles-small' style={{color: 'white'}}>Add Filter</span>
                </Button>

              <Button
                style={{ borderRadius: '5px', height: 33 }}
                type="primary"
                className="main-dropdown"
                onClick={generateSQLQuery}
                icon={<DatabaseOutlined />}
              >
                <span className='textStyles-small'>Generate Query</span>
              </Button>

              <Button
                style={{ borderRadius: '5px', height: 33 }}
                type="primary"
                danger
                className="main-dropdown"
                onClick={handleReload}
                icon={<DeleteOutlined />}
              >
                <span className='textStyles-small'>Reload</span>
              </Button>

              <div className="main-advanded-filter">
                {formItems.map((item, index) => (
                  <div
                    key={index}
                    style={{ display: "flex", marginBottom: "-10px" }}
                  >
                    {console.log(item.response_type)}
                    <Form.Item>
                      <Select
                        className='borderedSelect'
                        bordered={false}
                        style={{ width: 250 }}
                        placeholder="Label"
                        value={item.display_name}
                        onChange={(value) => handleLabelChange(index, value)}
                      >
                        .value
                        {filterList &&
                          filterList.map((column, index) => {
                            console.log(
                              index,
                              column.field_name,
                              column.label,
                              column.value
                            );
                            return (
                              <Option key={index} value={column.display_name}>
                                   <span className="textStyles-small">{column.label}</span>
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                    <Form.Item>
                      <Select
                        className='borderedSelect'
                        bordered={false}
                        style={{ width: 100, marginLeft: 4 }}
                        placeholder="Operator"
                        value={item.selectedOperator}
                        onChange={(value) =>
                          handleFormItemChange(index, "selectedOperator", value)
                        }
                      >
                        .value.value
                        {item.operator &&
                          item.operator.map((op) => (
                            <Option key={op.key} value={op.value}>
                                 <span className="textStyles-small">{op.value}</span> 
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Form.Item>
                      <Input
                        type={item.response_type == "text" ? "text" : "number"}
                        style={{ width: 150, marginLeft: 4, height: '34px' }}
                        placeholder="Value"
                        className='borderedSelect'
                        value={item.value}
                        onChange={(e) =>
                          handleFormItemChange(index, "value", e.target.value)
                        }
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                      icon={<DeleteOutlined style={{color: 'white'}}/>}
                        type="primary"
                        style={{marginLeft: 5}}
                        danger
                        shape="circle"
                        size="small"
                        onClick={() => handleRemoveFormItem(index)}
                      />
                    </Form.Item>
                    {item.detail_view && (
                      <Form.Item>
                        <Button
                        icon={<EyeOutlined style={{color: 'white'}}/>}
                          type="primary"
                          shape="circle"
                          size="small"
                          style={{ marginLeft: 10, fontSize: "16px" }}
                          onClick={() => {
                            const fieldValus = getDetails(index);
                            setOpenDrawerDetails(true);
                          }}
                        />
                      </Form.Item>
                    )}
                  </div>
                ))}
              </div>
            </Form>
          </div>
          </div>
      )}

      {showRapidFilterSection && (
        <div>
          <div className="action-container">
            {sql !== "" && (
              <div style={{ padding: 10, borderRadius: 10, marginTop: 10, width: '100%', backgroundColor: 'white' }}>
                <span className="textStyles-small" style={{ fontSize: 12, marginBottom: 20 }}>Target Field List :</span>
                <br />
                {
                  filterList.map((item, index) => {
                    return (
                      <Tag color="blue" style={{ margin: 5 }} key={index}><span style={{ fontSize: 11 }} className="textStyles-small">
                        {item.display_name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                      </span></Tag>
                    );
                  })
                }
                <div style={{ marginTop: 15 }} />
                <span className="textStyles-small" style={{ fontSize: 12, marginBottom: 20 }}>Auto Generated Query :</span>
                <div style={{ backgroundColor: "#f0f0f0", padding: 10, borderRadius: 10, marginTop: 5, width: '100%' }}>
                  <Row>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                      <CopyOutlined
                        style={{ fontSize: 14, cursor: 'pointer' }}
                        onClick={() => {
                          copyText(sql);
                          message.success("Query Copied Successfully")
                        }
                        } />
                    </div>
                  </Row>
                  <span className="textStyles-small">{sql}</span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                  <Button
                    type="primary"
                    onClick={rapidAPIResponse}
                    style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px', height: 33, marginTop: 20 }}
                  >
                    <SendOutlined />
                    <span className='textStyles-small'>Request Data</span>
                  </Button>

                  <Button
                    type="primary"
                    danger
                    onClick={() => handleReload()}
                    style={{ borderRadius: '5px', height: 33, marginTop: 20, marginLeft: 10 }}
                  > <span className='textStyles-small'>Back</span>
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {showNormalFilterSection && (
        <div className="action-container">
          {sql !== "" && (
            <div style={{ padding: 10, borderRadius: 10, marginTop: 10, width: '100%', backgroundColor: 'white' }}>
              <span className="textStyles-small" style={{ fontSize: 12, marginBottom: 20 }}>Target Field List :</span>
              <br />
              {
                filterList.map((item, index) => {
                  return (
                    <Tag color="blue" style={{ margin: 5 }} key={index}><span style={{ fontSize: 11 }} className="textStyles-small">
                      {item.display_name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                    </span></Tag>
                  );
                })
              }
              <div style={{ marginTop: 15 }} />
              <span className="textStyles-small" style={{ fontSize: 12, marginBottom: 20 }}>Auto Generated Query :</span>
              <div style={{ backgroundColor: "#f0f0f0", padding: 10, borderRadius: 10, marginTop: 5, width: '100%' }}>
                <Row>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    <CopyOutlined
                      style={{ fontSize: 14, cursor: 'pointer' }}
                      onClick={() => {
                        copyText(sql);
                        message.success("Query Copied Successfully")
                      }
                      } />
                  </div>
                </Row>
                <span className="textStyles-small">{sql}</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <Button 
                  type="primary" 
                  onClick={apiResponse} 
                  style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px', height: 33, marginTop: 20 }}
                >
                  <SendOutlined /> 
                  <span className='textStyles-small'>Request Data</span>
                </Button>
              </div>
            </div>
          )}
        </div>
      )}

      <Drawer
        title={<span className="textStyles-small" style={{ fontWeight: "bold", fontSize: 14 }}>Model Details</span>}
        placement="right"
        onClose={onClose}
        visible={openDrawerDetails}
      >
        <>
          <Form.Item>
            <Input
              placeholder="Filter Models"
              value={filterText}
              onChange={updateFilterText}
              className="borderedSelect"
              style={{height: 34}}
            />
          </Form.Item>

          <div className="action-container">
            {filteredModels.length != 0 &&
              filteredModels.map((item) => (
                <Row type="flex" justify="start" style={{ marginTop: "4px" }}>
                  <Col span={24}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span className="main-detailview">
                        {item.id}. {item.model}
                      </span>
                      <CopyOutlined
                        onClick={() =>
                          copyText(
                            filteredModels.map((item) => item.model).join("\n")
                          )
                        }
                        style={{
                          fontSize: "12px",
                          cursor: "pointer",
                          marginRight: "10px",
                          color: "#001eca73",
                          marginLeft: "20px",
                        }}
                        className="main-detailview"
                      />
                    </div>
                  </Col>
                </Row>
              ))}
          </div>

          <div className="action-container">
            {generatedModels.map((item) => (
              <Row type="flex" justify="start" key={item.id}>
                <Col span={24}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span className="main-detailview textStyles-small" style={{ fontSize: 12, margin: 8 }}>
                      {item.id}. {item.model}
                    </span>
                    <CopyOutlined
                      onClick={() =>  copyText(item.model)}
                      style={{
                        fontSize: "12px",
                        cursor: "pointer",
                        marginRight: "10px",
                        color: "#001eca73",
                        marginLeft: "20px",
                      }}
                      className="main-detailview"
                    />
                  </div>
                </Col>
              </Row>
            ))}
          </div>
        </>
      </Drawer>

      <Modal
        title={<span className="textStyles-small" style={{ fontWeight: "bold", fontSize: 14 }}>Save As Template</span>}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <span className="textStyles-small">Template Name:</span>
        <Input
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
          className="borderedSelect"
          style={{ height: 34 }}
          rows={4}
        />
        <br />
        <span className="textStyles-small" style={{marginTop: 5}}>Target Headers:</span>
        <Input.TextArea
          className="borderedSelect"
          style={{ color: "black" }}
          value={filterList.map((item) => item.label).join(", ")}
          disabled
          rows={4}
        />
        <br />
        <span className="textStyles-small">Auto Generated Query:</span>
        <Input.TextArea
          style={{ color: "black" }}
          value={sql}
          disabled
          className="borderedSelect"
          rows={4}
        />
      </Modal>

      {apiData.length != 0 && (
        <div className="action-container" style={{ backgroundColor: 'white', padding: 10, borderRadius: 10 }}>
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            className="main-dropdown"
            style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px', height: 33 }}
          >
            <CSVLink
              data={apiData}
              filename={{ date }.date + ".csv"}
              target="_blank"
            >
              <span className="textStyles-small" style={{color: 'white', marginLeft: 3}}>Export Data</span>
            </CSVLink>
          </Button>

          {
            !isRapidTemplate && (
              <Button
              type="primary"
              style={{ width: 150, marginLeft: "10px", borderRadius: '5px', height: 33 }}
              onClick={showModal}
              className="main-dropdown"
              icon={<SaveOutlined />}
            >
              <span className="textStyles-small" style={{color: 'white', marginLeft: 3}}>Save As Template</span>
            </Button>
            ) 
          }

          {apiData && (
            <Table
              style={{ marginTop: 20 }}
              columns={columns}
              dataSource={apiData}
              bordered
              size="small"
              className='table-striped-rows textStyles-small'
            />
          )}
        </div>
      )}

      {apiData.length === 0 && (
        <div className="action-container" style={{ backgroundColor: 'white', padding: 10, borderRadius: 10 }}>
          <Empty style={{ padding: "20px" }} description={<span className="textStyles-small">
            {sql === "" ? "Please generate the query to view the data." : "No data available."}
          </span>} />
        </div>
      )}
    </div>
  );
};

export default QueryBuilder;
