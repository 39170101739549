import { getUserRolesAndPermission2, getUserServiceType } from "../api/index";



export async function checkServiceType(value) {

    var res = await getUserServiceType();
    

    if (res.success) {
   

        if(res.data.serviceType.filter(checkuser => checkuser?.id == value)[0] != null)
            return Promise.resolve(true)
        else
        return Promise.resolve(false)
        
    }else{
        return Promise.resolve(false)
    }

}