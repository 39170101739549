import React, { Component } from 'react'
import { Select, DatePicker, Table, Image, Avatar, List, message, Row, Col, Button, Tag, Collapse, Card, Badge, Result, Spin } from 'antd';
import { LeftOutlined, RightOutlined, CloseOutlined, FileImageOutlined } from '@ant-design/icons';
import { getLastThreeRequestDetails, getLastBySiteId, getHistoryBySiteId, getLastestImgBySiteId, getHistoryBySiteIdAndsubComId } from "../../api/index";
import moment from 'moment';
const { Panel } = Collapse;

const ImageView = ({ siteID }) => {

    const [loading, setLoading] = React.useState(false);
    const [latestImagesList, setLatestImagesList] = React.useState([]);
    const [compList, setCompList] = React.useState([
        { "id": "1", "description": "19\" Rack ", "name": "19\" Rack " },
        { "id": "2", "description": "Access Antenna", "name": "Access Antenna" },
        { "id": "3", "description": "Access Equipment", "name": "Access Equipment" },
        { "id": "4", "description": "ACDB", "name": "ACDB" },
        { "id": "5", "description": "ATS ", "name": "ATS " },
        { "id": "6", "description": "Battery ", "name": "Battery " },
        { "id": "7", "description": "Combine & Filters ", "name": "Combine & Filters " },
        { "id": "8", "description": "Bulkhead", "name": "Bulkhead" },
        { "id": "9", "description": "Cooling System", "name": "Cooling System" },
        { "id": "10", "description": "Franklin Rod", "name": "Franklin Rod" },
        { "id": "11", "description": "SPD ", "name": "SPD " },
        { "id": "12", "description": "Generator ", "name": "Generator " },
        { "id": "13", "description": "MW Link ", "name": "MW Link " },
        { "id": "14", "description": "RRU ", "name": "RRU " },
        { "id": "15", "description": "Transport Equipment ", "name": "Transport Equipment " },
        { "id": "16", "description": "Tower Light", "name": "Tower Light" },
        { "id": "17", "description": "Rectifier", "name": "Rectifier" },
        { "id": "18", "description": "Tower", "name": "Tower" },
        { "id": "19", "description": "Cabin\/Cabinets", "name": "Cabin\/Cabinets" }
    ]);
    const [activeCompId, setActiveCompId] = React.useState(1);
    const [activeData, setActiveData] = React.useState([]);
    const [activeFields, setActiveFields] = React.useState([]);


    React.useEffect(() => {
        getDataLastThreeRequestDetails(siteID);
    }, []);

    const getDataLastThreeRequestDetails = async (siteID) => {
        setLoading(true);
        try {
            var data = new FormData();
            data.append('siteId', siteID);
            const response = await getLastestImgBySiteId(data);
            if (response.data && response.data.length > 0) {
                setLatestImagesList(response.data);
                setActiveCompId(response.data[0]?.id);
                setActiveData(response.data[0]?.imageFields);
                const result = response.data[0]?.imageFields?.map(item => ({
                    fieldId: item.field.id,
                    fieldName: item.field.name,
                    imageCount: item.images.length,
                    currIndex: 0,
                    images: item.images.map((image, index) => ({
                        imageId: image.id,
                        imageName: image.imageName,
                    }))
                }));
                setActiveFields(result);
            }
        } catch (error) {
            setLoading(false);
            message.error("Failed to fetch data");
        }
        setLoading(false);
    };

    const onChangeCopmt = (id) => {
        setActiveData([]);
        setActiveCompId(id);
        latestImagesList.map((item, index) => {
            if (item?.id == id) {
                setActiveData(item?.imageFields);
                const result = item?.imageFields?.map(item => ({
                    fieldId: item.field.id,
                    fieldName: item.field.name,
                    imageCount: item.images.length,
                    currIndex: 0,
                    images: item.images.map((image, index) => ({
                        imageId: image.id,
                        imageName: image.imageName,
                    }))
                }));
                setActiveFields(result);
            }
        });
    }

    const changeActiveIndex = (fieldId, index) => {
        const result = activeFields.map(item => {
            if (item.fieldId == fieldId) {
                item.currIndex = index;
            }
            return item;
        });
        setActiveFields(result);
    }

    return (
        <div>

            <div style={{ backgroundColor: 'white', padding: 2, marginTop: 10, borderRadius: 10, marginBottom: 20, zIndex: 10 }}>
                <Collapse defaultActiveKey={['1']} ghost={true}>
                    <Panel header={<span className='textStyles-small'
                        style={{ fontSize: 13, fontWeight: 'bold' }}> {compList.map((item, index) => {
                            if (activeCompId == item.id) {
                                return item.name
                            }
                        }
                        )}</span>} key="1">
                        <Row span={24}>
                            <Col>
                                {
                                    compList.map((item, index) => {
                                        return (
                                            <Button
                                                id={index}
                                                onClick={() => onChangeCopmt(item.id)}
                                                style={{
                                                    backgroundColor: activeCompId == item.id ? '#F5E5E8' : 'white',
                                                    borderColor: activeCompId == item.id ? '#B9183A' : 'gray',
                                                    color: 'black',
                                                    fontSize: 15,
                                                    width: '200px',
                                                    height: '40px',
                                                    borderRadius: '5px',
                                                    margin: '5px'
                                                }}
                                            >
                                                <span className='textStyles-small' style={{
                                                    fontSize: 11,
                                                    color: activeCompId == item.id ? '#B9183A' : 'black'
                                                }}>
                                                    {item.name}
                                                </span>
                                            </Button>
                                        )
                                    })
                                }
                            </Col>
                        </Row>
                    </Panel>
                </Collapse>
            </div>

            <Spin spinning={loading} size="large" tip={<span className='textStyles-small'>Loading...</span>}>
            {
                ((activeData !== undefined || activeData.length !== 0) && (activeFields !== undefined || activeFields.length !== 0)) ? ( 
                    <Row span={24}>
                    {activeData?.map((item, idx) => {
                        return (
                            <Col id='viewHome' key={idx} span={12}>
                                <div className="card" style={{ marginBottom: 10, width: '99%', borderRadius: '10px' }}>
                                    <Row span={24}>
                                        <Col span={9}>
                                            <Image
                                                height={310}
                                                src={'https://storage.googleapis.com/dns_storage_v2/requestimages/' + item?.images[activeFields?.find(x => x.fieldId == item?.field.id)?.currIndex]?.imageName}
                                                style={{ cursor: 'pointer', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', width: '100%' }}
                                                id={'imge' + item?.field?.id}
                                            />
                                        </Col>

                                        <Col span={15}>
                                            <div>
                                                <div style={{ marginTop: 13 }}>
                                                    <span className="textStyles-small" style={{ fontSize: 16, margin: 10, fontWeight: 600 }}>{item?.field?.name}</span>
                                                </div>
                                                <List
                                                    bordered={false}
                                                    itemLayout="vertical"
                                                    style={{ marginTop: 10, color: 'gray', marginLeft: 10 }}
                                                >
                                                    <List.Item style={{ padding: 5 }}>
                                                        <span className="textStyles-small" style={{ color: 'gray' }}>Request Code: </span>
                                                        <span id={'imgeCode' + item?.field?.id} className="textStyles-small" style={{ color: 'black' }}>
                                                            {item?.images[activeFields.find(x => x.fieldId == item.field.id)?.currIndex]?.request?.requestCode}
                                                        </span>
                                                    </List.Item>
                                                    <List.Item style={{ padding: 5 }}>
                                                        <span className="textStyles-small" style={{ color: 'gray' }}>Request Date: </span>
                                                        <span id={'imgeReDate' + item?.field?.id} className="textStyles-small" style={{ color: 'black' }}>
                                                            {item?.images[activeFields.find(x => x.fieldId == item.field.id)?.currIndex]?.request?.requestDate}
                                                        </span>
                                                    </List.Item>
                                                    <List.Item style={{ padding: 5 }}>
                                                        <span className="textStyles-small" style={{ color: 'gray' }}>Created User: </span>
                                                        <span id={'imgeCreUser' + item?.field?.id} className="textStyles-small" style={{ color: 'black' }}>
                                                            {item?.images[activeFields.find(x => x.fieldId == item.field.id)?.currIndex]?.request?.createdUser?.name}
                                                        </span>
                                                    </List.Item>
                                                    <List.Item style={{ padding: 5 }}>
                                                        <span className="textStyles-small" style={{ color: 'gray' }}>Created Date: </span>
                                                        <span id={'imgeCreData' + item?.field?.id} className="textStyles-small" style={{ color: 'black' }}>
                                                            {moment(item?.images[activeFields.find(x => x.fieldId == item.field.id)?.currIndex]?.request?.createdTime).format('YYYY-MM-DD HH:mm')}
                                                        </span>
                                                    </List.Item>
                                                    <List.Item style={{ padding: 5 }}>
                                                        <span className="textStyles-small" style={{ color: 'gray' }}>Formatted Date: </span>
                                                        <span id={'imgeCreData2' + item?.field?.id} className="textStyles-small" style={{ color: 'black' }}>
                                                            {moment(item?.images[activeFields.find(x => x.fieldId == item.field.id)?.currIndex]?.request?.createdTime).format('YYYY-MM-DD')}
                                                        </span>
                                                    </List.Item>
                                                    <List.Item style={{ padding: 5 }}>
                                                        <span className="textStyles-small" style={{ color: 'gray' }}>Status: </span>
                                                        {item?.images[activeFields.find(x => x.fieldId == item.field.id)?.currIndex]?.request?.serviceStatus?.name == 'Completed' ? (
                                                            <Tag color="green" style={{ borderRadius: 5 }}>
                                                                <span className="textStyles-small" style={{ color: '#1FAD4B', fontWeight: 700 }}>
                                                                    Completed
                                                                </span>
                                                            </Tag>
                                                        ) : (
                                                            <Tag color="red" style={{ borderRadius: 5 }}>
                                                                <span className="textStyles-small" style={{ color: '#FF0000' }}>
                                                                    Pending
                                                                </span>
                                                            </Tag>
                                                        )}
                                                    </List.Item>
                                                    {
                                                        activeFields.find(x => x.fieldId == item?.field?.id)?.imageCount > 1 ? (
                                                            <Row span={24} style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                                                <Tag color="#F5E5E8" style={{ padding: 5, borderRadius: 10, marginTop: 10 }}>
                                                                    {
                                                                        activeFields.find(x => x.fieldId == item?.field?.id).currIndex != 0 ? (
                                                                            <LeftOutlined
                                                                                onClick={() => {
                                                                                    const currentIndex = activeFields.find(x => x.fieldId == item?.field?.id)?.currIndex;
                                                                                    if (currentIndex > 0) {
                                                                                        changeActiveIndex(item?.field?.id, currentIndex - 1);
                                                                                    }
                                                                                }}
                                                                                style={{ fontSize: 14, color: '#B9183A', cursor: 'pointer' }}
                                                                            />
                                                                        ) : null
                                                                    }
                                                                    <span className="textStyles-small" style={{ color: '#B9183A', fontWeight: 700 }}>
                                                                        {activeFields.find(x => x.fieldId == item.field.id)?.currIndex + 1} of {activeFields.find(x => x.fieldId == item.field.id)?.imageCount}
                                                                    </span>
                                                                    {
                                                                        activeFields.find(x => x.fieldId == item?.field?.id)?.currIndex != activeFields.find(x => x.fieldId == item?.field?.id)?.imageCount - 1 ? (
                                                                            <RightOutlined
                                                                                onClick={() => {
                                                                                    const currentIndex = activeFields.find(x => x.fieldId == item?.field?.id)?.currIndex;
                                                                                    if (currentIndex < item?.images.length - 1) {
                                                                                        changeActiveIndex(item?.field?.id, currentIndex + 1);
                                                                                    }
                                                                                }}
                                                                                style={{ fontSize: 14, color: '#B9183A', cursor: 'pointer' }}
                                                                            />
                                                                        ) : null
                                                                    }
                                                                </Tag>
                                                            </Row>
                                                        ) : null
                                                    }
                                                </List>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        );
                    })}
                    {activeData === undefined || activeData.length === 0 ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                            <Result
                                title="Not Found"
                                subTitle={<span className='textStyles-small'>Images not found in {compList.map((item, index) => {
                                    if (activeCompId == item.id) {
                                        return item.name
                                    }
                                } )} section</span>}
                            />
                        </div>
                    ) : ('')}
                </Row>
                ): ('')
            }
            </Spin>
        </div>
    );
};

export default ImageView;