import React, { Component } from 'react'
import { Select, DatePicker, Table, Image, Avatar, List, Tag } from 'antd';

import { Link } from "react-router-dom";
import componentsimg from "../../icon/components-svgrepo-com.svg";
import detailsView from './view'
import GeneratorDetails from '../../components/image-capture/image-details'
import ReactExport from "react-data-export";

import subCompomentd from "../../icon/sub-compomentd.svg";
import { getLastThreeRequestDetails, getUserSites, getImageCapturUserAll, getSitesWithFilter } from "../../api/index";
import Modal from 'react-modal';

import { THE_FAMILY_ID } from '@azure/msal-common/dist/utils/Constants';
import Item from 'antd/lib/list/Item';
import ReactLoading from 'react-loading';
import { tupleNum } from 'antd/lib/_util/type';
import { Collapse } from 'reactstrap';
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from "react-redux";

import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class FullExport extends Component {

    constructor(props) {
        super(props);
        this.state = {

            loading: false,
            showModal: false,
            isOpenFillter: true,
            allList: null,
            allSite_loading: true,
            allSite: [],
            depotList: [],
            regionsList: [],
            allDataList: [],
            startDate: "",
            endDate: "",
            selectSiteId: null,
            selectSiteName: null,
            selectComptName: null,
            comptId: null,
            selectImagesDetails: null,
            regionIds: '',
            depotIds: '',
            siteIds: '',
            fullPageCount: 0,
            loopPageCount: 0,
            recodesPerPage: 50,
            compList: [
                { "id": "1", "description": "19\" Rack ", "name": "19\" Rack " },
                { "id": "2", "description": "Access Antenna", "name": "Access Antenna" },
                { "id": "3", "description": "Access Equipment", "name": "Access Equipment" },
                { "id": "4", "description": "ACDB", "name": "ACDB" },
                { "id": "5", "description": "ATS ", "name": "ATS " },
                { "id": "6", "description": "Battery ", "name": "Battery " },
                { "id": "7", "description": "Combine & Filters ", "name": "Combine & Filters " },
                { "id": "8", "description": "Bulkhead", "name": "Bulkhead" },
                { "id": "9", "description": "Cooling System", "name": "Cooling System" },
                { "id": "10", "description": "Franklin Rod", "name": "Franklin Rod" },
                { "id": "11", "description": "SPD ", "name": "SPD " },
                { "id": "12", "description": "Generator ", "name": "Generator " },
                { "id": "13", "description": "MW Link ", "name": "MW Link " },
                { "id": "14", "description": "RRU ", "name": "RRU " },
                { "id": "15", "description": "Transport Equipment ", "name": "Transport Equipment " },
                { "id": "16", "description": "Tower Light", "name": "Tower Light" },
                { "id": "17", "description": "Rectifier", "name": "Rectifier" },
                { "id": "18", "description": "Tower", "name": "Tower" },
                { "id": "19", "description": "Cabin\/Cabinets", "name": "Cabin\/Cabinets" }
            ]
        };
        this.handleCloseFullModal = this.handleCloseFullModal.bind(this);

    }
    viewFilterClick = () => {
        this.setState({
            isOpenFillter: !this.state.isOpenFillter
        })
    }
    handleCloseFullModal() {
        this.setState({ showModal: false });
    }
    componentDidMount() {
        this.getDataSitesByuser();

        var _depotList = [];
        var _regionsList = [];

        this.props.depots.map((depot) => {
            _depotList.push({ value: depot.id, label: depot.name })
        })
        this.props.regions.filter(allret => "0".includes(allret.isSubRegion)).map((regions) => {
            _regionsList.push({ value: regions.id, label: regions.name })
        })
        console.log(_regionsList);
        this.setState({
            depotList: _depotList,
            regionsList: _regionsList
        })
    }
    getDataImageCapturUserAll = async () => {
        this.setState({
            loading: true
        })
        var data = new FormData();
        data.append("pageNo", 0);
        data.append("recodesPerPage", this.state.recodesPerPage);
        data.append('fromDate', this.state.startDate);
        data.append('toDate', this.state.endDate);
        data.append('regionIds', this.state.regionIds);
        data.append('depotIds', this.state.depotIds);
        data.append('siteIds', this.state.siteIds);

        var pageCount = 0;


        var res = await getImageCapturUserAll(data);

        console.log("getImageCapturUserAll");
        console.log(res.data);
        if (res.success) {
            pageCount = res.data.pageCount;
            this.state.fullPageCount = res.data.pageCount;

            if (this.state.fullPageCount == 0) {

                this.setState({
                    loading: false,
                    tabaleLoading: false,
                    allDataList: res.data.data
                })
            }
            if (this.state.fullPageCount == 1) {
                this.setState({

                    allDataList: res.data.data,
                    loading: false,
                    tabaleLoading: false,


                });

            }
            if (this.state.fullPageCount > 1) {
                this.setState({
                    progressBarLoading: true,
                })

                this.getDataImageCapturUserAll2()


            }
        }



    }
    getDataImageCapturUserAll2 = async () => {
        var data = new FormData();
        data.append("pageNo", this.state.loopPageCount);
        data.append("recodesPerPage", this.state.recodesPerPage);
        data.append('fromDate', this.state.startDate);
        data.append('toDate', this.state.endDate);
        data.append('regionIds', this.state.regionIds);
        data.append('depotIds', this.state.depotIds);
        data.append('siteIds', this.state.siteIds);
        var res = await getImageCapturUserAll(data);
        console.log(res)
        this.setState({
            allDataList: [...this.state.allDataList, ...res.data.data],
        }, () => {

            if (this.state.fullPageCount - 1 == this.state.loopPageCount) {


                this.setState({
                    loading: false,

                    fullDataList: this.state.allDataList


                })


            } else {


                this.setState({
                    loopPageCount: ++this.state.loopPageCount,
                }, () => {
                    console.log('ff')

                    this.getDataImageCapturUserAll2();
                })
            }

        })
    }

    getDataLastThreeRequestDetails = async () => {

        //console.log("getLastThreeRequestDetails");
        if (this.state.selectSiteId != null) {
            this.setState({
                loading: true

            })
            var setData = {
                "siteId": this.state.selectSiteId,
                "componentId": this.state.comptId
            }

            var res = await getLastThreeRequestDetails(setData);
            console.log("getLastThreeRequestDetails");
            console.log(res);
            if (res.success) {
                this.setState({
                    loading: false,
                    allList: res.data

                })


            }

        } else {
            alert('Please select a Site')
        }

    };
    getDataSitesByuser = async () => {


        var res = await getUserSites();
        var _allSite = [];
        //console.log("getUserSites");
        //console.log(res.data);
        if (res.success) {
            res.data.map((sites) => {
                _allSite.push({ value: sites.id, label: sites.siteName + ' - ' + sites.siteId })
            })
            this.setState({
                allSite_loading: false,
                allSite: _allSite,
            });
        }
    };

    onChangeUserSites = (value) => {
        //console.log("onChangeUserSites");
        //console.log(value);
        var siteName = this.state.allSite.filter(person => person.id == value)

        this.setState({
            selectSiteId: value,
            selectSiteName: siteName[0].siteName

        }, () => {
            this.getDataLastThreeRequestDetails();
        })

    };

    onChangeAllCopmt() {

        this.setState({
            comptId: null,
            selectComptName: null

        }, () => {
            this.getDataLastThreeRequestDetails();
        })

    }
    onChangeAllDetails(item) {

        //console.log(item);
        this.setState({
            selectImagesDetails: item
        }, () => {
            this.setState({
                showModal: true
            })
        })

    }
    onChangeCopmt(id, name) {
        //console.log(id);
        if (this.state.selectSiteId != null) {
            this.setState({
                comptId: id,
                selectComptName: name

            }, () => {
                this.getDataLastThreeRequestDetails();
            })
            window.scrollTo(0, 0)

        } else {

            if (!alert('Please select a Site !')) { window.location.reload(); }
        }


    }

    clearBtnClick = () => {
        window.location.reload()
    }
    searchRegionmChange = (event) => {
        var dd = event
        //console.log(dd)
        var subRegion = [];
        var _depotList1 = [];
        var subRegionIdArray = [];

        this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id)).map((subR) => {
            subRegionIdArray.push(subR.id)
        })

        this.setState({
            regionIds: subRegionIdArray
        }, () => { this.getDataSitesWithFilter() });

        //console.log(this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id)));
        subRegion = this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id));
        //console.log(this.props.depots.filter(alldate => subRegion.map((n) => n.id).includes(alldate.region?.id)));
        this.props.depots.filter(alldate => subRegion.map((n) => n.id).includes(alldate.region?.id)).map((depot) => {
            _depotList1.push({ value: depot.id, label: depot.name })
        })

        this.setState({
            depotList: _depotList1

        })

    }
    searchDepottemChange = (event) => {

        this.setState({
            depotIds: event
        }, () => this.getDataSitesWithFilter());
    }
    getDataSitesWithFilter = async () => {
        this.setState({
            allSite_loading: true,
        })

        console.log("subR");
        var _regionList = [];
        var _depotList = [];
        var _allSite = [];
        if (this.state.regionIds !== '') {
            this.state.regionIds?.map((x, y) => {
                _regionList.push({ "id": x })
            }, () => {

            })
        }
        if (this.state.depotIds !== '') {
            this.state.depotIds?.map((a, b) => {
                _depotList.push({ "id": a })
            }, () => {

            })
        }


        console.log(_regionList)
        var data = new FormData();
        // data.append('regions','[{"id":6},{"id":7}]');
        data.append('regions', JSON.stringify(_regionList));
        data.append('depots', JSON.stringify(_depotList));
        var res = await getSitesWithFilter(data);
        console.log(res)
        if (res.success) {
            res.data.data.map((sites) => {
                _allSite.push({ value: sites.id, label: sites.siteName })
            })
            this.setState({
                allSite_loading: false,
                allSite: _allSite,

            })

        }

    }
    onChangeDate = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);
        this.setState({
            startDate: dateString,
        });
    };
    onChangeDate2 = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);
        this.setState({
            endDate: dateString,
        });
    };
    searchSiteemChange = (event) => {

        this.setState({
            siteIds: event
        });
    }
    filterBtnClick = async () => {
        if (this.state.startDate != "" && this.state.endDate != "") {
            this.getDataImageCapturUserAll();
        }






    }
    render() {
        const { Option } = Select;
        const fontValue = { sz: "12", bold: true, fill: { patternType: "solid", fgColor: { rgb: "FF0B0713" } } };
        const dataSet1 = this.state.allDataList;


        const StatisticDataSet = [
            {
                columns: [
                    // Sec 01
                    { title: "Request Code", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 210 } },//char width 
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width 

                    // Sec 02
                    { title: "Created User", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width 
                    { title: "Created Time", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width 
                    { title: "Request Date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width 
                    // Sec 03
                    { title: "Reson", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width 
                    { title: "Service Status", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width 
                    { title: "Sub Reason", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width 



                ],
                data: dataSet1?.map((data) => [

                    // Sec 01
                    { value: data.requestCode, style: { font: { sz: "12" } } },
                    { value: data.site?.siteId ? (data.site?.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.site?.siteName ? (data.site?.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 02
                    { value: data.createdUser?.name ? (data.createdUser?.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.createdTime ? (moment(data.createdTime).format('YYYY-MM-DD HH:mm:ss')) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.requestDate ? (data.requestDate) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 03
                    { value: data.reson?.name ? (data.reson?.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.serviceStatus?.name? (data.serviceStatus?.name)  : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.subReason?.name? (data.subReason?.name) : ("Null"), style: { font: { sz: "12" } } },



                ]),
            }
        ];

        const columns123 = [
            {
                title: "# Request Code",
                //   dataIndex: "accessPermissionCode",
                key: "requestCode",
                render: (item) => <span  ><u><Link to={"/image-capture/requests-view/" + item.id}>{item.requestCode}</Link></u></span>,
                //render: (item) => <span>{item.requestCode}</span>,
            },
            {
                title: "Site Id",
                // dataIndex: "siteId",
                //	defaultSortOrder: "descend",


                render: (item) => <>
                    <Tag color="gray" style={{ padding: 1 }} key={item.site.siteId}>
                        {item.site.siteId}
                    </Tag>
                </>,
            },
            {
                title: "Site",



                render: (item) => <span  >{item.site.siteName}</span>,

            },

            {
                title: "Request Date",
                //   dataIndex: "accessPermissionCode",
                key: "requestDate",
                render: (item) => <span>{item.requestDate}</span>,
            },
            {
                title: "Created Time",
                //   dataIndex: "accessPermissionCode",
                key: "createdTime",
                render: (item) => <span>{moment(item.createdTime).format('YYYY-MM-DD HH:mm:ss')}</span>,
            },

            {
                title: "Created User",
                //   dataIndex: "accessPermissionCode",
                key: "createdUser",
                render: (item) => <span>{item.createdUser.name}</span>,
            },
            {
                title: "Reason",
                //   dataIndex: "accessPermissionCode",
                key: "reson",
                render: (item) => <span>{item.reson.name}</span>,
            },

            {
                title: "Sub Reason",
                //   dataIndex: "accessPermissionCode",
                key: "subReason",
                render: (item) => <span>{item.subReason.name}</span>,
            },
            {
                title: "Service Status",
                filters: [
                    {
                        text: 'Initiated',
                        value: 'Initiated',
                    },
                    {
                        text: 'Completed',
                        value: 'Completed',
                    },
                    {
                        text: 'Pending',
                        value: 'Pending',
                    },
                ],
                onFilter: (value, record) => record.serviceStatus.name.startsWith(value),

                //   dataIndex: "accessPermissionCode",
                key: "serviceStatus",
                //render: (item) => <span class="badge badge-outline-success badge-pill">Successfully</span>,

                render: (item) => <>
                    <Tag color={item.serviceStatus.id == 1 ? ("gray") : (item.serviceStatus.id == 2 ? ("#0a6c18") : (item.serviceStatus.id == 3 ? ("#e50000") : ('')))} key={item}>
                        <span >{item.serviceStatus.name}</span>
                    </Tag>
                </>,
            },
            // {
            // 	title: "Status",
            // 	dataIndex: "status",
            // 	key: "operationTypeName",
            // 	render: (item) => <>
            // 		<Tag color="#03d87f" key={item}>
            // 			<span style={{ color: 'black' }}><b>Active</b></span>
            // 		</Tag>
            // 	</>,

            // },
            // {
            // 	title: "Site Category",
            // 	dataIndex: "siteCategory",
            // 	key: "siteCategory",
            // 	render: (item) => <span>{item.name}</span>,
            // 	//render: (item) => <span>{item}</span>,


            // },
            // {
            // 	title: "Tower Height",
            // 	dataIndex: "towerHeight",
            // 	key: "towerHeight",
            // 	render: (item) => <span>{item}</span>,
            // },

            // {
            // 	title: "Depot Officer ",
            // 	key: "DepotOfficer ",



            // 	render: (item) => <span>{item.depot.depotOfficers[0].name}</span>,


            // },
            // {
            // 	title: "Contact No",
            // 	key: "ContactNo",



            // 	render: (item) => <span>{item.depot.depotOfficers[0].mobileNumber}</span>,


            // },
            // {
            // 	title: "Action",
            // 	key: "ContactNo",
            // 	align: 'center',



            // 	render: (item) => <span><Link to={'/sites/edit/' + item.id} key="list-loadmore-edit"><i class="fa fa-pen"></i></Link></span>,


            // },

            // {
            // 	title: 'Action',

            // 	key: 'x',
            // 	render: (item) => <a href='#' onClick={() => this.getSiteAccessDetails(item.id)}><i data-feather="home" class="fa fa-eye"></i></a>,
            //   },

        ];
        const timestamp = Date.now();
        return (
            <div class="container-fluid" >
                <div class="row">
                    <div class="col-sm-12">
                        <div class="page-title-box">
                            <div class="row">
                                <div class="col">
                                    <h4 class="page-title">Image Capture Full Export</h4>
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Data Export</a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Image Capture Full E</a></li>

                                    </ol>
                                </div>
                                <div class="col-auto align-self-center">
                                    <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <Collapse isOpen={this.state.isOpenFillter}>
                        <div className="row">


                            <div class="row">
                                <div class="col-md-2">
                                    <DatePicker
                                        placeholder="Start Date *"
                                        style={{ width: "100%" }}
                                        onChange={this.onChangeDate}
                                    />

                                </div>
                                <div className='col-md-2'>
                                    <DatePicker
                                        placeholder="End Date *"
                                        style={{ width: "100%" }}
                                        onChange={this.onChangeDate2}
                                    />
                                </div>
                                <div class="col-md-2">


                                    <Select
                                        mode="multiple"
                                        showSearch
                                        name="regionId"
                                        onChange={(e) => this.searchRegionmChange(e)}
                                        placeholder="Region"
                                        style={{ width: "100%" }}

                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                    // options={this.state.regionsList}

                                    >
                                        {this.state.regionsList?.map((item, index) => {
                                            return <Option value={item.value}>{item.label}</Option>;
                                        })}
                                    </Select>
                                </div>
                                <div class="col-md-3">

                                    <Select
                                        mode="multiple"
                                        showSearch
                                        name="depotId"
                                        onChange={(e) => this.searchDepottemChange(e)}
                                        placeholder="Depot"
                                        style={{ width: "100%" }}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                    // options={this.state.depotList}

                                    >
                                        {this.state.depotList?.map((item, index) => {
                                            return <Option value={item.value}>{item.label}</Option>;
                                        })}
                                    </Select>
                                </div>
                                <div class="col-md-3">

                                    <Select
                                        mode="multiple"
                                        placeholder="Site"
                                        style={{ width: "100%" }}
                                        // options={this.state.allSite} 
                                        loading={this.state.allSite_loading}
                                        name="siteId"
                                        onChange={(e) => this.searchSiteemChange(e)}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                    >
                                        {this.state.allSite.map((item, index) => {
                                            return <Option value={item.value}>{item.label}</Option>;
                                        })}
                                    </Select>
                                </div>



                            </div>
                            <div class="col-md-3" style={{ marginTop: 10 }}>
                                <button type="button" class="btn btn-primary" onClick={() => this.filterBtnClick()}>   <i class="fas fa-search"> </i> Search</button>

                            </div>

                        </div>
                    </Collapse>
                    <div className="col-lg-12 col-xl-12">
                        <br></br>
                        <div className="card">
                            <div className="card-header">
                                <div className="row ">
                                    <div className="col">
                                        <h4 className="card-title">
                                            Report -Image Capture Full Export{" "}
                                            <ExcelFile
                                                filename={"Image Capture Full Export -" + timestamp}
                                                element={
                                                    <button
                                                        disabled={this.state.buttonLodading}
                                                        class="btn btn-primary"
                                                        style={{ float: "right" }}
                                                    >
                                                        <i class="fas fa-file-export"> </i> {this.state.buttonLodading ? (<img width={20} src={'https://media.tenor.com/wpSo-8CrXqUAAAAj/loading-loading-forever.gif'}></img>) : ('')} Image Capture Full Export
                                                    </button>
                                                }
                                            >

                                                <ExcelSheet dataSet={StatisticDataSet} name="Image Capture Full Export" />
                                            </ExcelFile>
                                        </h4>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">

                                <Table
                                    size='small'
                                    fontSize={9}
                                    columns={columns123}
                                    bordered
                                    //pagination={false}
                                    loading={this.state.loading}
                                    dataSource={this.state.allDataList}
                                />

                            </div>
                        </div>
                    </div>
                




                </div>



                {/* modal end */}


            </div>
        )
    }

}
function mapStateToProps(state) {
    //console.log("state")
    //console.log(state)
    return {
        depots: state.depots,
        regions: state.regions
    };
}
export default withRouter(connect(mapStateToProps, {})(FullExport));


