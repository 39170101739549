import React, { Component } from 'react'
import ReactApexChart from 'react-apexcharts'

class LineCharts extends Component {
  constructor(props) {
    super(props);
    this.state = {

      series: [{
        name: "Desktops",
        data: []
      }],
      options: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },

        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'butt',
          colors: undefined,
          width: 2,
          dashArray: 0,
        },
        yaxis: {
          title: {
            text: undefined
          },
        },

        xaxis: {
          categories: [],
        }
      },
    };
  }
  // componentDidMount() {
  //   this.setState({

  //     series: this.props.y1,
  //     options: {
  //       chart: {
  //         height: 350,
  //         parentHeightOffset: 0,
  //         type: 'line',
  //         zoom: {
  //           enabled: false
  //         },
  //         toolbar: {
  //           show: false
  //         }
  //       },
  //       dataLabels: {
  //         enabled: false
  //       },
  //       stroke: {
  //         show: true,
  //         curve: 'straight',
  //         colors: undefined,
  //         width:1,
  //       },

  //       yaxis: {
  //         title: {
  //           text: this.props.yLabale
  //         },
  //       },
  //       xaxis: {
  //         categories: this.props.x1,
  //         labels: {
  //           rotate: -0,
  //           //tickAmount: 10,
  //           formatter: function (val) {
  //             return val;
  //           }
  //         },
  //       }
  //     },

  //   })

  // }

  componentDidMount() {
    this.setState({
      series: this.props.y1,
      redraw: true,
      options: {
        chart: {
          height: 350,
          parentHeightOffset: 0,
          type: 'line',
          zoom: {
            enabled: true
          },
          toolbar: {
            show: false
          },
        },
        dataLabels: {
          enabled: false,
          tickAmount: 10,
        },
        stroke: {
          show: true,
          curve: 'straight',
          colors: '#2D5FF4',
          width: 4,
        },
        yaxis: {
          title: {
            text: this.props.yLabale
          },
          labels: {
            formatter: function (val) {
              return new Intl.NumberFormat('en-US').format(val);
            }
          },
        },
        xaxis: {
          categories: this.props.x1,
          tickAmount: 10,
          labels: {
            rotate: -0,
            formatter: function (val) {
              return val;
            }
          },
        },
        markers: {
          size: [4, 7],
          colors: undefined,
          strokeColors: '#fff',
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: undefined,
            sizeOffset: 3
          }
      }
      },
    })
  }
  
  render() {
    return (
      <div id="chart">
        <ReactApexChart
        redraw={true} 
        options={this.state.options} 
        series={this.state.series} 
        type="line" height={265} />
      </div>
    )
  }
}
export default LineCharts;