import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { AimOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Card, Row, Col, Empty, Button } from "antd";

const mapComponent = ({ startMapCenter, closeMapFunction }) => {

    const mapOptions = {
        zoomControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
    };

    const mapStyles = {
        height: "200px",
        width: "100%",
        borderRadius: "8px"
    };
    return (
        <div style={{ width: '100%', height: '80%', border: '1px solid #FAFAFA', borderRadius: '5px' }}>

            <Button icon={<CloseCircleOutlined style={{ color: 'white'}}/>} 
            onClick={closeMapFunction} style={{ float: 'right', marginTop: '5px', marginRight: '5px', backgroundColor: '#BA183A', borderColor: '#BA183A', borderRadius: 5 }} />

            {
                (startMapCenter?.lat === 0 && startMapCenter?.lng === 0) || startMapCenter?.lat === null || startMapCenter?.lng === null ?
                    <Empty
                        image={<AimOutlined style={{ color: '#BA183A' }}/>}
                        imageStyle={{ fontSize: 60 }}
                        description={<span className='textStyle-small'>No Location Found</span>}
                    />
                    : <LoadScript googleMapsApiKey="AIzaSyAYye4nok_qEPk0bHJgzEEhwzXaRxlkM0Y">
                        <GoogleMap
                            mapContainerStyle={mapStyles}
                            zoom={10}
                            center={startMapCenter}
                            options={mapOptions}
                        >
                            <Marker
                                position={startMapCenter}
                            />
                        </GoogleMap>
                    </LoadScript>
            }
        </div>
    );
}

export default mapComponent;