import React, { Component, Fragment, useState } from 'react';
import { siteViewNew } from '../../api/api.config';
import GeneralDetails from '../../components/site_info/general_details'
import AssetDetails from '../../components/site_info/asset_details'
import AccessTechnologies from '../../components/site_info/access_technologies'
import MainPowerConnection from '../../components/site_info/main_power_connection'
import SharedBackupPower from '../../components/site_info/shared_backup_power'
import DCPowerSharing from '../../components/site_info/dc_power'
import PowerCapacityKw from '../../components/site_info/power_capacity_kw'
import DCLoad from '../../components/site_info/dc_load'
import TRCSL from '../../components/site_info/trcsl'
import GeneratorHut from '../../components/site_info/generator_hut'
import Earthing from '../../components/site_info/earthing'
import CAE from '../../components/site_info/cae'
import REW from '../../components/site_info/rew'
import axios from 'axios';
import GeneratorPd from '../../components/site_components/generator_pd'
import Sld from '../../components/site_info/sld'
import Rectifiers from '../../components/site_info/rectifiers'
import ImageCapture from '../../components/site_info/image_capture'
import GeneratorDetails from '../../components/site_info/generator_details'
import FBC from '../../components/site_info/fbc'
import SPD from '../../components/site_info/spd'
import RES from '../../components/site_info/res'
import SharedOutOperators from '../../components/site_info/shared-out-Operators'
import ATS from '../../components/site_info/ats'
import BatteryBanks from '../../components/site_info/battery_banks'
import IDBR from '../../components/site_info/idbattery_racks'
import BulckTankDetails from '../../components/site_info/bulck_tank_details'
import DCDUS from '../../components/site_info/dcdus'
import Cabinet from '../../components/site_info/cabinet'
import AirConditioners from '../../components/site_info/air_conditioners'
import CabiDetails from '../../components/site_info/cabin_details'
import ActivityLog from '../../components/site_info/activityLog'
import ImageView from '../../components/site_info/imageView'
import { withRouter } from 'react-router-dom';
import { getSiteDetails } from '../../api/Repos/SiteRepo';
import { getAllAtsMakes, getPowerDetailsBySite, getMasterDataSiteInfo, getPowerSummaryData, getReMasterData, updateNoOfValues, getupdateGeneralDetailsById, getAllDropdowns, getRefData, setDepotOfficerHeadBySite } from "../../api/index";

import { Link } from "react-router-dom";
import GoogleMapReact from 'google-map-react';
import Item from 'antd/lib/list/Item';
import { message, Row, Col, Button, Tag, Collapse, Popover, Select } from 'antd';
import { UserOutlined, UnorderedListOutlined, GlobalOutlined, WarningOutlined } from '@ant-design/icons';
import '../master-data/style.css';
import { options } from '../../components/charts/bar-chart-chartjs';
const { Panel } = Collapse;
const { Option } = Select;

const AnyReactComponent = ({ text, siteName, sitePrimaryKey, regionId }) => {
    const [visible, setVisible] = useState(false);
    const handleVisibleChange = (visible) => {
        setVisible(visible);
    };
    const requestJson = {
        siteIds: [sitePrimaryKey],
        regionIds: [],
        depotIds: [],
        status: null,
        siteOwnerIds: [],
        siteTypeIds: [],
        towerCategoryIds: [],
        towerTypeIds: [],
        singleView: true
    } 
    const content = (
        <div>
            <Row span={24}>
                <Col span={12}>
                    <span style={{ fontSize: 12, fontWeight: 700 }} className='textStyles-small'>
                        {siteName}
                    </span>
                </Col>
                <Col span={12}>
                    <Button
                        shape='square'
                        style={{ float: 'right', borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', marginLeft: 10, cursor: 'pointer' }}
                        onClick={() => {
                            window.location.href = '/site/map/new'
                            localStorage.setItem('mapQuery', JSON.stringify(requestJson))
                        }}
                        icon={<GlobalOutlined style={{ color: 'white' }} />} />
                </Col>
            </Row>
        </div>
    );

    return (
        <Popover
            content={content}
            trigger="click"
            visible={visible}
            onVisibleChange={handleVisibleChange}
            style={{
                borderRadius: 5
            }}
        >
            <div>
                <i style={{ color: "#ff0000", fontSize: 30, cursor: 'pointer' }} className="fas fa-map-marker-alt"></i>
            </div>
        </Popover>
    );
};

class SiteInfo extends Component {

    constructor(props) {
        super(props)
        this.state = {

            selected_section: 'BASIC_DATA',
            sub_section: 'GENERAL',
            site_details: {},
            site_new_details: {},
            access_technologies_data: null,
            battery_banks_data: null,
            air_conditioners: null,
            cabinet_data: null,
            generators_data: null,
            ats_data: null,
            dcdus_data: null,
            idBatteryRacks_data: null,
            sharedOutOperatorses_data: null,
            spd_data: null,
            fcbs_data: null,
            renewableEnergySolars_data: null,
            renewableEnergyWind_data: null,
            rectifiers_data: null,
            powerSummaryDataSet: null,
            dcduMasterData: null,
            cabinetMasterData: {
                cabinetModelList: [],
                cabinetMakeList: [],
            },
            rewMasterData: {
                rewMakelList: [],

            },
            bbMasterData: {
                bbModelList: [],
                bbMakeList: [],
                cabinetList: []
            },
            atsMasterData: {
                atsModelList: [],
                atsMakeList: [],
            },
            fcdMasterData: {
                fcdModelList: [],
                fcdMakeList: [],
            },
            spdMasterData: {

                spdMakeList: [],
            },

            center: {
                lat: 3.8091,
                lng: 79.872
            },
            centerLat: null,
            centerlng: null,

            zoom: '14',
            resMasterData: {
                rectifierList: [],
            },
            mpConnectionMasterData: {
                powerConnectionType: [],
            },
            acMasterData: {
                acMakeList: []
            },
            genHutTypeMasterData: {
                typeSet: []
            },

            updateGeneralDetails: [],
            commonDropDownData: [],

            dropDownObjectSetRectifier: {
                "rectifierPlannedFromOperationalTeam": [],
                "rectifierPlannedFromPlanninglTeam": [],
                "rectifierWithCabinetPlannedFromOperationalTeam": [],
                "rectifierWithCabinetPlannedFromPlanninglTeam": [],
                "rectifierModulesPlannedFromOperationalTeam": [],
                "rectifierModulesPlannedFromPlanninglTeam": [],
                "rectifierControllerModel": [],
                "rectifierControllerSoftwareVersion": [],
                "rectifiernoOfModulesAtSite": [],
                "rectifierNoOfPoweredRecModules": [],
                "rmsintegratedStatus": [],
                "rmsinbandOutband": [],
                "rectifierNoOfBreakerSpacesAvailable": [],
                "rectifierNoOfBreakerSpacesAvailable100A": [],
                "rectifierRMSConnectionMode": [],
            },
            generatorDropDownObject: {
                "generatorControllerModel": [],
                "avrModel": [],
                "fuelTankCapacity": [],
                "availabilityOfGenCanapy": [],
                "genCanapyStatus": [],
                "soundProofStatus": [],
                "delayTimerStatus": [],
                "rmsintegratedStatus": [],
                "rmsInbandOutband": [],
                "rmsConnectionMethod": [],
                "genEngineModel": [],
            },
            dropdownObjSolar: {
                "resShelterDirectionFromTheTower": [],
                "resNonShadingSpaceDirectionFromTheTower": [],
                "resPvmake": [],
                "resInstalledLocation": [],
                "resSolarPanelInstalledOverTheOutdoorCabinet": [],
                "resInverterType": [],
                "resConverterInverterQuantity": [],
                "resRmsconnectionInband": [],
                "rmsRmsconnectionMode": [],
            },
            dropdownObjWind: {
                "rewPotential": [],
                "rewWindTurbineCount": [],
                "rewInstalledLocation": [],
                "rewRmsconnectionInband": [],
                "rewEnergyFeed": [],
                "rewRmsconnectionMode": [],
            },
            dropdownObjSoo: {
                "powerConnectionAuthority": [],
                "soohandletenant": [],
                "sharedPowerType": [],
                "sooCommercialPowerNoofPhases": [],
                "sooCommercialPowerPerPhaseCapacity": [],
                "sooGenPowerPerPhaseCapacity": [],
                "sooGenPowerNoofPhases": [],
                "sooDcPowerCapacity": [],
                "sooBillingMethod": [],
            },
            objSetDropdownCabinets: {
                "cabinetutilizedEquipmentSpace": [],
                "cabinetutilizedBatterySpace": [],
                "cnoOfCabinetsWithEquipmentInstalled": [],
                "cplannedFromPlanninglTeam": [],
                "cplannedFromOperationalTeam": [],
            },
            dropdownDetailsCabins: {
                "noofIndoorCabins": [],
                "indoorCabinType": [],
            },
            objectDropdownAc: {
                "airCoolingMethod": [],
                "acModeOfOperation": [],
            },
            dropdownObjGenHut: {
                "SpaceAvailableNewGen": [],
                "NumberofGeneratorHut": [],
                "availableGeneratorHut1Type": [],
                "availableGeneratorHut2Type": [],
            },
            objDropdowndcdu: {
                "dcduType": [],
                "dcduPowerFromLLVD": [],
                "dcduRbcapacityToPowerDCDU": [],
                "dcduNoOfBreakerSlots": [],
                "dcduFreeBreakerSlots": [],
            },
            spdDropdownObject: {
                "spdDeployedLocation": [],
                "spdProtectionViaHrcFuseIsolator": [],
            },
            dropdownObjIDBR: {
                "ibridbatteryrackModel": [],
                "iDRModelCount": [],
            },

            objBulktankDropdown: {
                "capacity": [],
            },
            dropdownObjEarthing: {
                "earERImprovementStatus": [],
            },
            dropdownATS: {
                "atsLowVoltageFunctionality": [],
            },
            dropdownObjBB: {
                "bbBatteryTemperatureSensorInstalledCorrectly": [],
            },
            allATSMakes: [],
            activityLogData: [],
        }
    }

    componentDidMount() {
        this.getSiteData();
        this.getPowerdBData();
        this.getDataMasterDataSiteInfo();
        this.getCommonDropDownData();
        this.fetchActivityLog(this.props.match.params.id);
    }

    getupdateGeneralDetailsById = async (id) => {
        const formData = new FormData();
        formData.append('id', id);
        var res = await getupdateGeneralDetailsById(formData);
        if (res.success) {
            this.setState({
                updateGeneralDetails: res?.data
            })
        }
    }

    fetchActivityLog = async (id) => {
        try {
            const response = await axios.get(siteViewNew+'summary/site-count?siteId=' + id);
            if (response.status === 200) {
                console.log('site count:', response.data);
                this.setState({
                    activityLogData: response.data
                })
            }
        } catch (error) {
            console.error('Error fetching site count:', error);
        }
    };

    getCommonDropDownData = async () => {
        var res = await getAllAtsMakes();
        if (res.success) {
            this.setState({
                allATSMakes: res.data ? res.data : []
            })
        }
        var res = await getAllDropdowns();
        if (res.success) {
            this.setState({
                dropDownObjectSetRectifier: {
                    "rectifierPlannedFromOperationalTeam": res.data["Rectifiers"]["Planned from Operational team"] ? res.data["Rectifiers"]["Planned from Operational team"] : [],
                    "rectifierPlannedFromPlanninglTeam": res.data["Rectifiers"]["Planned from Planning team"] ? res.data["Rectifiers"]["Planned from Planning team"] : [],
                    "rectifierWithCabinetPlannedFromOperationalTeam": res.data["Rectifier with Cabinet units"]["Planned from Operational team"] ? res.data["Rectifier with Cabinet units"]["Planned from Operational team"] : [],
                    "rectifierWithCabinetPlannedFromPlanninglTeam": res.data["Rectifier with Cabinet units"]["Planned from Planningl team"] ? res.data["Rectifier with Cabinet units"]["Planned from Planningl team"] : [],
                    "rectifierModulesPlannedFromOperationalTeam": res.data["Rectifier modules"]["Planned from Operational team"] ? res.data["Rectifier modules"]["Planned from Operational team"] : [],
                    "rectifierModulesPlannedFromPlanninglTeam": res.data["Rectifier modules"]["Planned from Planningl team"] ? res.data["Rectifier modules"]["Planned from Planningl team"] : [],
                    "rectifierControllerModel": res.data["Rectifier 1"]["Rectifier Controller Model"] ? res.data["Rectifier 1"]["Rectifier Controller Model"] : [],
                    "rectifierControllerSoftwareVersion": res.data["Rectifier 1"]["Rectifier Controller software Version"] ? res.data["Rectifier 1"]["Rectifier Controller software Version"] : [],
                    "rectifiernoOfModulesAtSite": res.data["Rectifier 1"]["No. of modules at site"] ? res.data["Rectifier 1"]["No. of modules at site"] : [],
                    "rectifierNoOfPoweredRecModules": res.data["Rectifier 1"]["No. of Powered Rec Modules"] ? res.data["Rectifier 1"]["No. of Powered Rec Modules"] : [],
                    "rmsintegratedStatus": res.data["Rectifier 1"]["RMS integrated status"] ? res.data["Rectifier 1"]["RMS integrated status"] : [],
                    "rmsinbandOutband": res.data["Rectifier 1"]["RMS inband/outband"] ? res.data["Rectifier 1"]["RMS inband/outband"] : [],
                    "rectifierNoOfPhases": res.data["Rectifier 1"]["No of phases"] ? res.data["Rectifier 1"]["No of phases"] : [],
                    "rectifierNoOfBreakerSpacesAvailable": res.data["Rectifier 1"]["No of breaker spaces available (size 80A or below)"] ? res.data["Rectifier 1"]["No of breaker spaces available (size 80A or below)"] : [],
                    "rectifierNoOfBreakerSpacesAvailable100A": res.data["Rectifier 1"]["No of breaker spaces available (size 100A or above)"] ? res.data["Rectifier 1"]["No of breaker spaces available (size 100A or above)"] : [],
                    "rectifierRMSConnectionMode": res.data["Rectifier 1"]["RMS connection method"] ? res.data["Rectifier 1"]["RMS connection method"] : [],
                },
                generatorDropDownObject: {
                    "generatorControllerModel": res.data["Generator 1"]["Generator Controller Model"] ? res.data["Generator 1"]["Generator Controller Model"] : [],
                    "avrModel": res.data["Generator 1"]["AVR model"] ? res.data["Generator 1"]["AVR model"] : [],
                    "fuelTankCapacity": res.data["Generator 1"]["Fuel Tank Capacity (l)"] ? res.data["Generator 1"]["Fuel Tank Capacity (l)"] : [],
                    "availabilityOfGenCanapy": res.data["Generator 1"]["Availability of gen Canapy"] ? res.data["Generator 1"]["Availability of gen Canapy"] : [],
                    "genCanapyStatus": res.data["Generator 1"]["Gen Canapy status"] ? res.data["Generator 1"]["Gen Canapy status"] : [],
                    "soundProofStatus": res.data["Generator 1"]["Sound proof Status"] ? res.data["Generator 1"]["Sound proof Status"] : [],
                    "delayTimerStatus": res.data["Generator 1"]["Delay timer Status"] ? res.data["Generator 1"]["Delay timer Status"] : [],
                    "rmsintegratedStatus": res.data["Generator 1"]["RMS integrated status"] ? res.data["Generator 1"]["RMS integrated status"] : [],
                    "rmsInbandOutband": res.data["Generator 1"]["RMS inband/outband"] ? res.data["Generator 1"]["RMS inband/outband"] : [],
                    "rmsConnectionMethod": res.data["Generator 1"]["RMS connection method"] ? res.data["Generator 1"]["RMS connection method"] : [],
                    "genEngineModel": res.data["Generator 1"]["Engine Model"] ? res.data["Generator 1"]["Engine Model"] : [],
                    "genCapacity": ["7.5", "8", "9.1", "10", "11.5", "12", "12.5", "13", "13.5", "15", "16", "17", "17.5", "18", "20", "22", "22.5", "22.7", "24", "25", "26", "27", "30", "35", "40", "42", "46", "55", "60", "66", "80", "100", "110", "140", "160", "167", "200", "250", "275", "300"],
                    "genMake": ["Airman", "AJ Power", "Ashok Layland", "Cummins", "Denyo", "Deutz", "Dulux", "FG Wilson", "Hired Gen", "Jubilee Power", "Kirloskar", "Kubota", "Mahendra", "Masons Power", "Olympian", "Polar Power", "Power Pro", "Power Source", "Powerlink", "Pramac", "Shindaiwa", "Soar Power", "TAIYO", "Tempast", "Volvo", "Yanmar", "Yoraka"],
                },
                dropdownObjSolar: {
                    "resShelterDirectionFromTheTower": res.data["Renewable Energy - Solar"]["Shelter Direction from the tower"],
                    "resNonShadingSpaceDirectionFromTheTower": res.data["Renewable Energy - Solar"]["Non shading space Direction from the tower"],
                    "resPvmake": res.data["Renewable Energy - Solar"]["Solar Panel Make"] ? res.data["Renewable Energy - Solar"]["Solar Panel Make"] : [],
                    "resInstalledLocation": res.data["Renewable Energy - Solar"]["Installed location"] ? res.data["Renewable Energy - Solar"]["Installed location"] : [],
                    "resSolarPanelInstalledOverTheOutdoorCabinet": res.data["Renewable Energy - Solar"]["Solar panel installed over the outdoor cabinet"] ? res.data["Renewable Energy - Solar"]["Solar panel installed over the outdoor cabinet"] : [],
                    "resInverterType": res.data["Renewable Energy - Solar"]["Inverter / Converter  type"] ? res.data["Renewable Energy - Solar"]["Inverter / Converter  type"] : [],
                    "resConverterInverterQuantity": res.data["Renewable Energy - Solar"]["Converter/ Inverter Quantity"] ? res.data["Renewable Energy - Solar"]["Converter/ Inverter Quantity"] : [],
                    "resRmsconnectionInband": res.data["Renewable Energy - Solar"]["RMS connection Inband/Outband"] ? res.data["Renewable Energy - Solar"]["RMS connection Inband/Outband"] : [],
                    "rmsRmsconnectionMode": res.data["Renewable Energy - Solar"]["RMS connection mode"] ? res.data["Renewable Energy - Solar"]["RMS connection mode"] : [],
                },
                dropdownObjWind: {
                    "rewPotential": res.data["Renewable Energy - Wind"]["Wind potential (Strong wind/ average wind/ low wind/NA)"] ? res.data["Renewable Energy - Wind"]["Wind potential (Strong wind/ average wind/ low wind/NA)"] : [],
                    "rewWindTurbineCount": res.data["Renewable Energy - Wind"]["Wind turbine count"] ? res.data["Renewable Energy - Wind"]["Wind turbine count"] : [],
                    "rewInstalledLocation": res.data["Renewable Energy - Wind"]["Installed location (tower mounted/ ground)"] ? res.data["Renewable Energy - Wind"]["Installed location (tower mounted/ ground)"] : [],
                    "rewRmsconnectionInband": res.data["Renewable Energy - Wind"]["RMS connection Inband/Outband"] ? res.data["Renewable Energy - Wind"]["RMS connection Inband/Outband"] : [],
                    "rewEnergyFeed": res.data["Renewable Energy - Wind"]["Energy feed onto the DC/AC bus"] ? res.data["Renewable Energy - Wind"]["Energy feed onto the DC/AC bus"] : [],
                    "rewRmsconnectionMode": res.data["Renewable Energy - Wind"]["RMS connection mode"] ? res.data["Renewable Energy - Wind"]["RMS connection mode"] : [],
                },
                dropdownObjSoo: {
                    "powerConnectionAuthority": ["CEB", "LECO"],
                    "soohandletenant": ["ABC", "Airtel", "ARAL", "Buddhist Radio", "CEB", "Derana", "EAP", "EAP Networks", "Frankils Developments", "Hiru", "Etisalat / Hutch", "ITN", "Lak FM", "LAK-FM", "LankaBell", "LankaCom", "LECO", "MBC", "Mobitel", "MTV", "Neth FM", "Neth-FM", "People's Media", "Power House", "Rangiri", "Rupawahini", "Sirasa", "SiyathaFM", "SLA", "SLAF", "SLBC", "SLN", "SLT", "SRI-FM", "Tritel", "Try Mas Media Network", "TV Lanka", "Vendol Media", "V-FM"],
                    "sharedPowerType": ["AC Commercial Power", "DC Power - Without Gen Backup", "DC Power - FTG", "Gen Backup Power", "AC Commercial Power + Gen", "DC Power - With Gen Backup", "Full Time Gen Power"],
                    "sooCommercialPowerNoofPhases": res.data["Shared Out-Operator 1"]["Commercial Power- No of Phases"],
                    "sooCommercialPowerPerPhaseCapacity": res.data["Shared Out-Operator 1"]["Commercial Power- Per Phase Capacity (A)"] ? res.data["Shared Out-Operator 1"]["Commercial Power- Per Phase Capacity (A)"] : [],
                    "sooGenPowerPerPhaseCapacity": res.data["Shared Out-Operator 1"]["Gen Power- Per Phase Capacity (A)"] ? res.data["Shared Out-Operator 1"]["Gen Power- Per Phase Capacity (A)"] : [],
                    "sooGenPowerNoofPhases": res.data["Shared Out-Operator 1"]["Gen Power- No of Phases"] ? res.data["Shared Out-Operator 1"]["Gen Power- No of Phases"] : [],
                    "sooDcPowerCapacity": res.data["Shared Out-Operator 1"]["DC power capacity (A)"] ? res.data["Shared Out-Operator 1"]["DC power capacity (A)"] : [],
                    "sooBillingMethod": res.data["Shared Out-Operator 1"]["Billing method"] ? res.data["Shared Out-Operator 1"]["Billing method"] : [],
                },
                objSetDropdownCabinets: {
                    "cabinetutilizedEquipmentSpace": res.data["Cabinets"]["Utilized Equipment space (U)"] ? res.data["Cabinets"]["Utilized Equipment space (U)"] : [],
                    "cabinetutilizedBatterySpace": res.data["Cabinets"]["Utilized Battery Space (U)"] ? res.data["Cabinets"]["Utilized Battery Space (U)"] : [],
                    "cnoOfCabinetsWithEquipmentInstalled": res.data["Cabinets"]["No of Cabinets with Equipment installed"] ? res.data["Cabinets"]["No of Cabinets with Equipment installed"] : [],
                    "cplannedFromPlanninglTeam": ["Yes", "No"],
                    "cplannedFromOperationalTeam": ["Yes", "No"],
                },
                dropdownDetailsCabins: {
                    "noofIndoorCabins": res.data["General Details"]["No of Indoor Cabins"] ? res.data["General Details"]["No of Indoor Cabins"] : [],
                    "indoorCabinType": res.data["General Details"]["Indoor Cabin Type"] ? res.data["General Details"]["Indoor Cabin Type"] : [],
                },
                objectDropdownAc: {
                    "airCoolingMethod": res.data["General Details"]["Cooling Method"] ? res.data["General Details"]["Cooling Method"] : [],
                    "acModeOfOperation": res.data["AC-1"]["Mode of Operation"] ? res.data["AC-1"]["Mode of Operation"] : [],
                },
                dropdownObjGenHut: {
                    "SpaceAvailableNewGen": res.data["General Details"]["Generator space availability for a new generator"] ? res.data["General Details"]["Generator space availability for a new generator"] : [],
                    "NumberofGeneratorHut": res.data["General Details"]["Number of Generator Hut"] ? res.data["General Details"]["Number of Generator Hut"] : [],
                    "availableGeneratorHut1Type": res.data["General Details"]["Available Generator Hut 1 Type"] ? res.data["General Details"]["Available Generator Hut 1 Type"] : [],
                    "availableGeneratorHut2Type": res.data["General Details"]["Available Generator Hut 2 Type"] ? res.data["General Details"]["Available Generator Hut 2 Type"] : [],
                },
                objDropdowndcdu: {
                    "dcduType": res.data["DCDU"]["Type"] ? res.data["DCDU"]["Type"] : [],
                    "dcduPowerFromLLVD": res.data["DCDU"]["Power from LLVD/BLVD"] ? res.data["DCDU"]["Power from LLVD/BLVD"] : [],
                    "dcduRbcapacityToPowerDCDU": res.data["DCDU"]["Rectifier Breaker capacity (A) to power DCDU"] ? res.data["DCDU"]["Rectifier Breaker capacity (A) to power DCDU"] : [],
                    "dcduNoOfBreakerSlots": res.data["DCDU"]["No of breaker/Fuse Slots"] ? res.data["DCDU"]["No of breaker/Fuse Slots"] : [],
                    "dcduFreeBreakerSlots": res.data["DCDU"]["Free breaker/Fuse Slots "] ? res.data["DCDU"]["Free breaker/Fuse Slots "] : [],
                },
                spdDropdownObject: {
                    "spdDeployedLocation": res.data["SPD 1"]["Deployed Location"] ? res.data["SPD 1"]["Deployed Location"] : [],
                    "spdProtectionViaHrcFuseIsolator": res.data["SPD 1"]["SPD Protection via HRC Fuse/Isolator"] ? res.data["SPD 1"]["SPD Protection via HRC Fuse/Isolator"] : [],
                },
                dropdownObjIDBR: {
                    "ibridbatteryrackModel": res.data["ID Battery Rack 1"]["ID battery rack  model"] ? res.data["ID Battery Rack 1"]["ID battery rack  model"] : [],
                    "iDRModelCount": res.data["ID Battery Rack 1"]["ID battery rack Model count"] ? res.data["ID Battery Rack 1"]["ID battery rack Model count"] : [],
                },
                bulckTankDropdown: {
                    "capacity": res.data["Generator 1"]["Bulk tank Capacity (l)"] ? res.data["Generator 1"]["Bulk tank Capacity (l)"] : [],
                },
                dropdownObjEarthing: {
                    "earERImprovementStatus": res.data["Earthing"]["ER Improvement status (WIP/Completed)"] ? res.data["Earthing"]["ER Improvement status (WIP/Completed)"] : [],
                },
                dropdownObjBB: {
                    "bbBatteryTemperatureSensorInstalledCorrectly": res.data["Battery Bank 1"]["Battery temperature sensor installed correctly"] ? res.data["Battery Bank 1"]["Battery temperature sensor installed correctly"] : [],
                },
                dropdownATS: {
                    "atsLowVoltageFunctionality": res.data["ATS"]["Low voltage functionality"] ? res.data["ATS"]["Low voltage functionality"] : [],
                },

            })
        } else {
            message.error(res.message);
            this.setState({
                commonDropDownData: {}
            })
        }
    }

    getDataPowerSummaryData = async (siteId) => {
        var data = new FormData();
        data.append('siteIds', siteId);
        var res = await getPowerSummaryData(data);
        console.log('getPowerSummaryData12')
        console.log(res)

        if (res.success) {

            this.state.site_new_details["powerSummaryDetaSet"] = res.data

            this.setState({
                site_details: this.state.site_new_details
            })


        } else {
            this.setState({
                site_details: this.state.site_new_details
            })
        }

    }
    getDataMasterDataSiteInfo = async () => {
        var res = await getMasterDataSiteInfo();
        var res2 = await getReMasterData();
        console.log("176")
        console.log(res)
        console.log("178")

        console.log(res2)

        if (res.success) {
            this.setState({
                rectifiersMasterData: {
                    rectifiersMakeList: res.data?.rectifierMake,
                    rectifiersModelList: res.data?.rectifierModel,
                    cabinetList: this.state.cabinet_data,
                    rectifierModuleModelList: res2?.data?.rectifierModuleModel,
                    renewableEnergySolaList: this.state.renewableEnergySolars_data,
                    renewableEnergyWind: this.state.renewableEnergyWind_data,
                },
                cabinetMasterData: {
                    cabinetMakeList: res.data?.cabinetMake,
                    cabinetModelList: res.data?.cabinetModel,
                    batteryBanksList: this.state.battery_banks_data,
                    cabinetTypeList: res.data?.cabinetType,
                },
                rewMasterData: {
                    rewMakelList: res.data?.renewableEnergyMake,
                    rectifierList: this.state.rectifiers_data
                },
                bbMasterData: {
                    bbMakeList: res.data?.batteryBankMake,
                    bbModelList: res.data?.batteryBankModel,
                    cabinetList: this.state.cabinet_data,
                    rectifierList: this.state.rectifiers_data,
                    bbTypeList: res.data?.batteryBankType,
                },
                atsMasterData: {
                    atsMakeList: res.data?.atsMake,
                    atsModelList: res.data?.atsModel,
                },
                fcdMasterData: {
                    fcdModelList: res.data?.fcbModel,
                    fcdMakeList: res.data?.fcbMake,
                },
                spdMasterData: {
                    spdMakeList: res.data?.spdMake,
                },
                dcduMasterData: {
                    rectifierList: this.state.rectifiers_data
                },
                resMasterData: {
                    rectifierList: this.state.rectifiers_data,
                    inverterConverterModelList: res2?.data?.inverterConverterModel,
                    solarPanelModelList: res2?.data?.solarPanelModel
                },
                mpConnectionMasterData: {
                    powerConnectionType: res?.data?.powerConnectionType
                },
                genHutTypeMasterData: {
                    typeSet: res.data?.genHutType
                },
                acMasterData: {
                    acMakeList: res.data?.acMake,
                    acTypeList: res.data?.acType
                },
                genMasterData: {
                    genMake: res.data?.genMake,
                },
            })
        }
    }

    getPowerdBData = async () => {
        this.updateNoOfValues();
        var data = JSON.stringify({
            "id": this.props.match.params.id
        });
        var res = await getPowerDetailsBySite(data);
        if (res.success) {
            this.setState({
                allData: res.data,
                access_technologies_data: res.data?.sitePowerDb,
                battery_banks_data: res.data?.batteryBanks,
                air_conditioners: res.data?.airConditioners,
                cabinet_data: res.data?.cabinets,
                generators_data: res.data?.generators,
                ats_data: res.data?.atses,
                dcdus_data: res.data?.dcdus,
                idBatteryRacks_data: res.data?.idBatteryRacks,
                sharedOutOperatorses_data: res.data?.sharedOutOperatorses,
                renewableEnergySolars_data: res.data?.renewableEnergySolars,
                renewableEnergyWind_data: res.data?.renewableEnergyWinds,
                spd_data: res.data?.spds,
                fcbs_data: res.data?.fcbs,
                rectifiers_data: res.data?.rectifiers,
            }, () => {
                console.log(this.state.rectifiers_data)
            })
            this.getDataMasterDataSiteInfo();
            this.getSiteData();
            if (this.state.access_technologies_data != null) {
                this.getupdateGeneralDetailsById(this.state.access_technologies_data.id)
            }
        }
    }

    getSiteData = async () => {
        var res = await getSiteDetails(this.props.match.params.id);
        if (res.success) {
            if (res.data.siteId) {
                this.getDataPowerSummaryData(res.data.siteId);
            }
            if (res.data.siteAddressList[0] != null) {
            } else {
            }
            if (res.data.siteAddressList[0] != null &&
                res.data.siteAddressList[0]?.latitude != '' &&
                res.data.siteAddressList[0]?.longitude != '' &&
                res.data.siteAddressList[0]?.latitude != 'Null' &&
                res.data.siteAddressList[0]?.longitude != 'Null' &&
                res.data.siteAddressList[0]?.latitude != null &&
                res.data.siteAddressList[0]?.longitude !== null &&
                isNaN(res.data.siteAddressList[0]?.longitude) == false &&
                isNaN(res.data.siteAddressList[0]?.latitude) == false) {
                this.setState({
                    site_new_details: res.data,
                    zoom: '14',
                    center: {
                        lat: parseFloat(res.data.siteAddressList[0]?.latitude !== null ? (res.data.siteAddressList[0]?.latitude) : ('')),
                        lng: parseFloat(res.data.siteAddressList[0]?.longitude !== null ? (res.data.siteAddressList[0]?.longitude) : ('')),
                    },
                    centerLat: parseFloat(res.data.siteAddressList[0]?.latitude !== null ? (res.data.siteAddressList[0]?.latitude) : ('')),
                    centerlng: parseFloat(res.data.siteAddressList[0]?.longitude !== null ? (res.data.siteAddressList[0]?.longitude) : ('')),
                }, () => {
                    console.log('this.state.center')
                    console.log(this.state.center)
                })
            } else {
                this.setState({
                    site_new_details: res.data,
                    zoom: '14',
                    centerLat: 6.8091,
                    centerlng: 80.872,
                    center: {
                        lat: 0.000,
                        lng: 0.00,
                    },
                }, () => {
                })
            }
        }
    }

    updateNoOfValues = async () => {
        var data = new FormData();
        data.append("siteId", this.props.match.params.id);
        await updateNoOfValues(data);
    }

    //set head of depot 
    setDepotOfficerHeadBySiteFunction = async (siteId, depotOfficerHead) => {
        var data = new FormData();
        data.append("sitePrimaryKey", siteId);
        data.append("userId", depotOfficerHead);
        var res = await setDepotOfficerHeadBySite(data);
        message.success(<span>Depot Officer Head Updated Successfully</span>, 2);
        this.getSiteData();
    }

    render() {
        const { powerSummaryDataSet } = this.state
        const { site_details } = this.state
        const { access_technologies_data } = this.state
        const { battery_banks_data } = this.state
        const { air_conditioners } = this.state
        const { cabinet_data } = this.state
        const { generators_data } = this.state
        const { ats_data } = this.state
        const { dcdus_data } = this.state
        const { idBatteryRacks_data } = this.state
        const { sharedOutOperatorses_data } = this.state
        const { renewableEnergySolars_data } = this.state
        const { renewableEnergyWind_data } = this.state
        const { spd_data } = this.state
        const { fcbs_data } = this.state
        const { rectifiers_data } = this.state
        const { allData } = this.state
        const { atsMasterData } = this.state
        const { bbMasterData } = this.state
        const { rewMasterData } = this.state
        const { fcdMasterData } = this.state
        const { spdMasterData } = this.state
        const { cabinetMasterData } = this.state
        const { rectifiersMasterData } = this.state
        const { dcduMasterData } = this.state
        const { resMasterData } = this.state
        const { mpConnectionMasterData } = this.state
        const { genHutTypeMasterData } = this.state
        const { acMasterData } = this.state
        const { genMasterData } = this.state
        const { updateGeneralDetails } = this.state
        const { dropDownObjectSetRectifier } = this.state
        const { generatorDropDownObject } = this.state
        const { dropdownObjSolar } = this.state
        const { dropdownObjWind } = this.state
        const { dropdownObjSoo } = this.state
        const { objSetDropdownCabinets } = this.state
        const { dropdownDetailsCabins } = this.state
        const { objectDropdownAc } = this.state
        const { dropdownObjGenHut } = this.state
        const { objDropdowndcdu } = this.state
        const { spdDropdownObject } = this.state
        const { dropdownObjIDBR } = this.state
        const { bulckTankDropdown } = this.state
        const { dropdownObjEarthing } = this.state
        const { dropdownObjBB } = this.state
        const { dropdownATS } = this.state
        const { allATSMakes } = this.state

        const powerData = [
            { title: 'Access Technologies', isActive: false, key: 'ACCESS_TECHNOLOGIES' },
            { title: 'Main Power', isActive: false, key: 'MAIN_POWER' },
            { title: 'Shared Gen Backup', isActive: false, key: 'SHARED_GEN_BACKUP' },
            { title: 'DC Power Share-IN', isActive: false, key: 'DC_POWER_SHARE_IN' },
            { title: 'Power Capacity(KW)', isActive: false, key: 'POWER_CAPACITY_KW' },
            { title: 'DC Load', isActive: false, key: 'DC_LOAD' },
            { title: 'Outdoor Cabinets', isActive: false, key: 'OUTDOOR_CABINETS' },
            { title: 'Rectifiers', isActive: false, key: 'RECTIFIERS' },
            { title: 'DCDU', isActive: false, key: 'DCDU' },
            { title: 'Battery Banks', isActive: false, key: 'BATTERY_BANKS' },
            { title: 'ID Battery Racks', isActive: false, key: 'ID_BATTERY_RACKS' },
            { title: 'Generator', isActive: false, key: 'GENERATOR' },
            { title: 'Generator Hut', isActive: false, key: 'GENERATOR_HUT' },
            { title: 'Bulk Tank', isActive: false, key: 'BULK_TANK' },
            { title: 'ATS', isActive: false, key: 'ATS' },
            { title: 'AC', isActive: false, key: 'AC' },
            { title: 'FCB', isActive: false, key: 'FCB' },
            { title: 'Indoor Cabin', isActive: false, key: 'INDOOR_CABIN' },
            { title: 'SPD', isActive: false, key: 'SPD' },
            { title: 'Solar', isActive: false, key: 'SOLAR' },
            { title: 'Wind', isActive: false, key: 'WIND' },
            { title: 'Shared-out Operators', isActive: false, key: 'SHARED_OUT_OPERATORS' },
            { title: 'Earthing', isActive: false, key: 'EARTHING' },
            { title: 'CEA Submissions', isActive: false, key: 'CEA_SUBMISSIONS' },
            { title: 'TRCSL Complaints', isActive: false, key: 'TRCSL_COMPLAINTS' }
        ];


        const powerSummaryData = [
            {
                title: 'Genaral',
                isActive: false,
                key: 'GENERAL',
            },
            {
                title: 'Images',
                isActive: false,
                key: 'IMAGES',
            },
            {
                title: 'SLD',
                isActive: false,
                key: 'SLD',
            }
        ]

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            <div className="row">
                                <div className="col">
                                    <span className="textStyles-small" style={{ fontSize: '14px', fontWeight: 'bold' }}>Site Summary</span>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a className='textStyles-small'>Site</a></li>
                                        <li className="breadcrumb-item"><a className='textStyles-small'>{site_details.siteName}</a></li>
                                        <li className="breadcrumb-item active"><span className='textStyles-small'>{site_details.siteId}</span></li>
                                    </ol>
                                </div>
                                <div className="col-auto align-self-center">
                                    {this.state.site_details.status !== '' ? (
                                        <span
                                            className="ant-tag ant-tag-has-color textStyles-small"
                                            style={{
                                                backgroundColor: 'green',
                                                fontSize: 12,
                                                borderRadius: '5px',
                                                padding: '10px',
                                                width: '100px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            {this.state.site_details.status ? this.state.site_details.status.toUpperCase() : 'Not Available'}
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* BASIC DETAILS SECTION */}
                <Fragment style={{ marginTop: '30px' }}>
                    <Row>
                        <Col lg={7}>
                            <div style={{
                                height: '200px',
                                width: '98%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '10px',
                                overflow: 'hidden' // Add this line
                            }}>
                                {this.state.centerLat !== null && this.state.centerlng !== null ? (
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: "AIzaSyC7QjauaBLV9W__koQgy65PIuxYbcSPpfg" }}
                                        defaultCenter={{
                                            lat: this.state.centerLat,
                                            lng: this.state.centerlng
                                        }}
                                        defaultZoom={8}
                                        options={{
                                            zoomControl: false,
                                            fullscreenControl: false,
                                        }}
                                    >
                                        <AnyReactComponent
                                            lat={this.state.center.lat}
                                            lng={this.state.center.lng}
                                            text={site_details.siteID }
                                            siteName={site_details.siteName}
                                            sitePrimaryKey={this.state.site_details.id}
                                            regionId={site_details?.depot?.region?.id}
                                        />
                                    </GoogleMapReact>
                                ) : ('')}
                            </div>
                        </Col>
                        <Col lg={17}>
                            <div style={{ backgroundColor: 'white', borderRadius: '5px', height: '200px', width: '100%' }}>
                                <Row>
                                    <Col lg={6}>
                                        <div class="dastone-profile-main-pic" style={{ margin: '30px' }}>
                                            <img src={'https://static.vecteezy.com/system/resources/previews/001/902/502/large_2x/telecommunication-radio-antenna-and-satelite-tower-with-blue-sky-free-photo.JPG'}
                                                alt="" height="110" class="rounded-circle"></img>
                                        </div>
                                    </Col>
                                    <Col lg={10}>
                                        <div class="col-lg-5 align-self-center mb-3 mb-lg-0" style={{ marginTop: '30px' }}>
                                            <div class="dastone-profile-main">
                                                <div class="dastone-profile_user-detail">
                                                    <span className='textStyles-small' style={{ fontSize: '20px', fontWeight: 'bold'}}>
                                                    {site_details.siteName}
                                                    </span>
                                                    <p className="mb-0 text-secondary font-16 align-middle me-2">
                                                        <span class="ant-tag ant-tag-has-color" style={{ backgroundColor: 'gray', fontSize: 14, borderRadius: 5 }}>
                                                            {site_details.siteId}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <ul class="list-unstyled personal-detail mb-0" style={{ marginTop: '20px' }}>
                                            <span className='textStyles-small' style={{ marginTop: '10px' }}>Region : {site_details?.depot?.region?.name}</span> <br />
                                            <span className='textStyles-small' style={{ marginTop: '10px' }}>Depot  : {site_details?.depot?.name}</span> <br />
                                            <span className='textStyles-small' style={{ marginTop: '10px' }}>Address : {site_details.siteAddressList != null && site_details?.siteAddressList[0]?.address}</span> <br />
                                        </ul>
                                    </Col>
                                    <Col lg={8}>
                                        <div style={{ marginTop: '30px' }}>
                                            <Row>
                                                {
                                                    this.state.site_details?.depot?.depotOfficers &&
                                                    this.state.site_details.depot.depotOfficers.some(officer => officer.headDepotOfficer === true) && (
                                                        <Row>
                                                            {this.state.site_details.depot.depotOfficers.filter(officer => officer.headDepotOfficer === true).slice(0, 1).map((officer, index) => (
                                                                <React.Fragment key={index}>
                                                                    <Col lg={8}>
                                                                        <div style={{
                                                                            backgroundColor: 'gray',
                                                                            borderRadius: '50%',
                                                                            height: '45px',
                                                                            width: '45px',
                                                                            border: '4px solid #F79326',
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center'
                                                                        }}>
                                                                            <UserOutlined style={{ fontSize: 20, color: 'white' }} size='medium' />
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={16}>
                                                                        <span className='textStyles-small' style={{ marginTop: '10px', fontSize: '15px' }}>
                                                                            {officer.name}
                                                                        </span>
                                                                        <br />
                                                                        <span className='textStyles-small' style={{ marginTop: '10px', color: 'gray' }}>
                                                                            Depot Officer
                                                                        </span>
                                                                        <br />
                                                                        <span className='textStyles-small' style={{ marginTop: '10px' }}>
                                                                            {officer.mobileNumber.slice(0, -7) + ' ' +
                                                                                officer.mobileNumber.slice(2, -4) + ' ' +
                                                                                officer.mobileNumber.slice(-4)}
                                                                        </span>
                                                                    </Col>
                                                                </React.Fragment>
                                                            ))}
                                                        </Row>
                                                    )
                                                }

                                                {
                                                    this.state.site_details?.depot?.depotOfficers &&
                                                    this.state.site_details.depot.depotOfficers.every(officer => officer.headDepotOfficer === false) && (
                                                        <Col span={24}>
                                                            <WarningOutlined /> <span className='textStyles-small' style={{ fontSize: 12, color: 'gray' }}>No Head Depot Officer</span>
                                                            <Select
                                                                placeholder="Please Choose Depot Officer"
                                                                style={{ width: '100%', height: 'auto', marginTop: 10 }}
                                                                className='borderedSelect'
                                                                bordered={false}
                                                                onChange={(value) => this.setDepotOfficerHeadBySiteFunction(this.state.site_details.id, value)}
                                                            >
                                                                {
                                                                    this.state.site_details.depot.depotOfficers.map((officer, index) => (
                                                                        <Option key={index} value={officer?.id}>
                                                                            <span className='textStyles-small' style={{ fontSize: 11 }}>
                                                                                {officer?.name}
                                                                            </span>
                                                                        </Option>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </Col>
                                                    )
                                                }
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Fragment>

                {/* NAVIGATION SECTION */}
                <Fragment>
                    <div style={{ marginTop: '20px', backgroundColor: 'white', borderRadius: '5px', padding: '10px', zIndex: 10 }}>
                        <Row>
                            <Button
                                onClick={() => this.setState({ selected_section: 'BASIC_DATA', sub_section: 'GENERAL' })}
                                style={{
                                    backgroundColor: this.state.selected_section === 'BASIC_DATA' ? '#F5E5E8' : 'white',
                                    borderColor: this.state.selected_section === 'BASIC_DATA' ? '#B9183A' : 'gray',
                                    fontSize: 15, 
                                    // width: '19%', 
                                    width: '32%',
                                    height: '60px', borderRadius: '5px', marginRight: '1%'
                                }}
                            >
                                <span className='textStyles-small' style={{ fontSize: 13, fontWeight: 500, color: this.state.selected_section === 'BASIC_DATA' ? '#B9183A' : 'black' }}>
                                    <UnorderedListOutlined /> Basic Data</span>
                            </Button>
                            <Button
                                onClick={() => this.setState({ selected_section: 'POWER_DATA', sub_section: 'ACCESS_TECHNOLOGIES' })}
                                style={{
                                    backgroundColor: this.state.selected_section === 'POWER_DATA' ? '#F5E5E8' : 'white',
                                    borderColor: this.state.selected_section === 'POWER_DATA' ? '#B9183A' : 'gray',
                                    fontSize: 15, 
                                    width: '32%', 
                                    height: '60px', borderRadius: '5px', marginRight: '1%'
                                }}
                            >
                                <span className='textStyles-small' style={{ fontSize: 13, fontWeight: 500, color: this.state.selected_section === 'POWER_DATA' ? '#B9183A' : 'black' }}>
                                    <UnorderedListOutlined /> Power Data</span>
                            </Button>
                            {/* <Button
                                style={{
                                    backgroundColor: this.state.selected_section === 'N360' ? '#F5E5E8' : 'white',
                                    borderColor: this.state.selected_section === 'N360' ? '#B9183A' : 'gray',
                                    fontSize: 15, width: '19%', height: '60px', borderRadius: '5px', marginRight: 5
                                }}
                            >
                                <span className='textStyles-small' style={{ fontSize: 13, fontWeight: 500, color: this.state.selected_section === 'N360' ? '#B9183A' : 'black' }}>
                                    <UnorderedListOutlined /> N360 Data</span>
                            </Button>
                            <Button
                                style={{
                                    backgroundColor: this.state.selected_section === 'ON_DEMAND' ? '#F5E5E8' : 'white',
                                    borderColor: this.state.selected_section === 'ON_DEMAND' ? '#B9183A' : 'gray',
                                    fontSize: 15, width: '19%', height: '60px', borderRadius: '5px', marginRight: 5
                                }}
                            >
                                <span className='textStyles-small' style={{ fontSize: 13, fontWeight: 500, color: this.state.selected_section === 'ON_DEMAND' ? '#B9183A' : 'black' }}>
                                    <UnorderedListOutlined /> On-Demand Data</span>
                            </Button> */}
                            <Button
                                onClick={() => this.setState({ selected_section: 'LOG', sub_section: 'LOG' })}
                                style={{
                                    backgroundColor: this.state.selected_section === 'LOG' ? '#F5E5E8' : 'white',
                                    borderColor: this.state.selected_section === 'LOG' ? '#B9183A' : 'gray',
                                    fontSize: 15, width: '32%', 
                                    height: '60px', borderRadius: '5px', marginRight: '1%'
                                }}
                            >
                                <span className='textStyles-small' style={{ fontSize: 13, fontWeight: 500, color: this.state.selected_section === 'LOG' ? '#B9183A' : 'black' }}>
                                    <UnorderedListOutlined /> Activity Summary</span>
                            </Button>
                        </Row>
                    </div>
                </Fragment>

                {
                    this.state.selected_section === 'BASIC_DATA' || this.state.selected_section === 'POWER_DATA' ? (
                        <div style={{ backgroundColor: 'white', padding: 2, marginTop: 10, borderRadius: 10, marginBottom: 20,  zIndex: 10 }}>
                            <Collapse defaultActiveKey={['1']} ghost={true}>
                                <Panel header={<span className='textStyles-small' style={{ fontSize: 13, fontWeight: 'bold' }} >{
                                    this.state.sub_section.replace(/_/g, ' ').toUpperCase()
                                }</span>}
                                    key="1">
                                    <Fragment>
                                        {
                                            this.state.selected_section === 'BASIC_DATA' ? (
                                                <Row span={24}>
                                                    <Col>
                                                        {
                                                            powerSummaryData.map((item, index) => {
                                                                return (
                                                                    <Button
                                                                        onClick={() => this.setState({ sub_section: item.key })}
                                                                        style={{
                                                                            backgroundColor: this.state.sub_section === item.key ? '#F5E5E8' : 'white',
                                                                            borderColor: this.state.sub_section === item.key ? '#B9183A' : 'gray',
                                                                            color: 'black',
                                                                            fontSize: 15,
                                                                            width: '200px',
                                                                            height: '40px',
                                                                            borderRadius: '5px',
                                                                            margin: '5px'
                                                                        }}
                                                                    >
                                                                        <span className='textStyles-small' style={{
                                                                            fontSize: 11,
                                                                            color: this.state.sub_section === item.key ? '#B9183A' : 'black'
                                                                        }}>{item.title}</span>
                                                                    </Button>
                                                                )
                                                            })
                                                        }
                                                    </Col>
                                                </Row>
                                            ) : null
                                        }
                                        {
                                            this.state.selected_section === 'POWER_DATA' ? (
                                                <Row>
                                                    <Col lg={24}>
                                                        {
                                                            powerData.map((item, index) => {
                                                                return (
                                                                    <Button
                                                                        onClick={() => this.setState({ sub_section: item.key })}
                                                                        style={{
                                                                            backgroundColor: this.state.sub_section === item.key ? '#F5E5E8' : 'white',
                                                                            borderColor: this.state.sub_section === item.key ? '#B9183A' : 'gray',
                                                                            color: 'black',
                                                                            fontSize: 15,
                                                                            width: '200px',
                                                                            height: '40px',
                                                                            borderRadius: '5px',
                                                                            margin: '5px'
                                                                        }}
                                                                    >
                                                                        <span className='textStyles-small' style={{
                                                                            fontSize: 11,
                                                                            color: this.state.sub_section === item.key ? '#B9183A' : 'black'
                                                                        }}>{item.title}</span>
                                                                    </Button>
                                                                )
                                                            })
                                                        }
                                                    </Col>
                                                </Row>
                                            ) : null
                                        }
                                    </Fragment>
                                </Panel>
                            </Collapse>
                        </div>
                    ) : null
                }

                {
                    this.state?.selected_section === 'LOG' && this.state?.sub_section === 'LOG' ? (
                        <ActivityLog
                            apiResponse={this.state.activityLogData}
                            siteID={this.props.match.params.id}
                        />
                    ) : null
                }

                {
                    this.state.sub_section === 'GENERATOR' ? (
                        this.state.generators_data !== null ? (
                            <GeneratorDetails
                                details={{ site_details }}
                                details1={{ access_technologies_data }}
                                details2={{ generators_data }}
                                genDropdown={{ generatorDropDownObject }}
                                parentMethod={this.getPowerdBData}
                                genMasterDataSet={genMasterData}
                                detailsupdateGeneralDetails={{ updateGeneralDetails }}
                            />
                        ) : (
                            <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h4 style={{ color: "#c5c5c5", fontSize: '14px' }} className='textStyles-small'>Data Not Found Generator</h4>
                            </div>
                        )
                    ) : null
                }

                {
                    this.state.sub_section === 'ACCESS_TECHNOLOGIES' ? (
                        this.state.access_technologies_data !== null ? (
                            <AccessTechnologies
                                details={{ access_technologies_data }}
                                details2={{ site_details }}
                                parentMethod={this.getPowerdBData}
                            />
                        ) : (
                            <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h4 style={{ color: "#c5c5c5", fontSize: '14px' }} className='textStyles-small'>Data Not Found Access Technologies</h4>
                            </div>
                        )
                    ) : null
                }

                {
                    this.state.sub_section === 'MAIN_POWER' ? (
                        this.state.access_technologies_data !== null ? (
                            <MainPowerConnection
                                details={{ access_technologies_data }}
                                details2={{ site_details }}
                                parentMethod={this.getPowerdBData}
                                mpConnectionMasterData={mpConnectionMasterData}
                                detailsupdateGeneralDetails={{ updateGeneralDetails }}
                            />
                        ) : (
                            <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h4 style={{ color: "#c5c5c5", fontSize: '14px' }} className='textStyles-small'>Data Not Found Main Power Connection</h4>
                            </div>
                        )
                    ) : null
                }

                {
                    this.state.sub_section === 'SHARED_GEN_BACKUP' ? (
                        this.state.access_technologies_data !== null ? (
                            <SharedBackupPower
                                details={{ access_technologies_data }}
                                details2={{ site_details }}
                                parentMethod={this.getPowerdBData}
                            />
                        ) : (
                            <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h4 style={{ color: "#c5c5c5", fontSize: '14px' }} className='textStyles-small'>Data Not Found Shared Backup Power</h4>
                            </div>
                        )
                    ) : null
                }

                {
                    this.state.sub_section === 'DC_POWER_SHARE_IN' ? (
                        this.state.access_technologies_data !== null ? (
                            <DCPowerSharing
                                details={{ access_technologies_data }}
                                details2={{ site_details }}
                                parentMethod={this.getPowerdBData}
                            />
                        ) : (
                            <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h4 style={{ color: "#c5c5c5", fontSize: '14px' }} className='textStyles-small'>Data Not Found DC Sharing</h4>
                            </div>
                        )
                    ) : null
                }

                {
                    this.state.sub_section === 'POWER_CAPACITY_KW' ? (
                        this.state.access_technologies_data !== null ? (
                            <PowerCapacityKw
                                details={{ access_technologies_data }}
                                details2={{ site_details }}
                                parentMethod={this.getPowerdBData}
                            />
                        ) : (
                            <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h4 style={{ color: "#c5c5c5", fontSize: '14px' }} className='textStyles-small'>Data Not Found Power Capacity</h4>
                            </div>
                        )
                    ) : null
                }

                {
                    this.state.sub_section === 'RECTIFIERS' ? (
                        this.state.rectifiers_data !== null ? (
                            <Rectifiers
                                details={{ access_technologies_data }}
                                details2={{ rectifiers_data }}
                                detailsSite={{ site_details }}
                                dropDownInfoRectifier={{ dropDownObjectSetRectifier }}
                                parentMethod={this.getPowerdBData}
                                rectifiersMasterData={{ rectifiersMasterData }}
                                detailsupdateGeneralDetails={{ updateGeneralDetails }}
                            />
                        ) : (
                            <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h4 style={{ color: "#c5c5c5" }}>Data Not Found Rectifiers</h4>
                            </div>
                        )
                    ) : null
                }

                {
                    this.state.sub_section === 'DCDU' ? (
                        this.state.dcdus_data !== null ? (
                            <DCDUS
                                details={{ access_technologies_data }}
                                dcdudrop={{ objDropdowndcdu }}
                                details2={{ dcdus_data }}
                                detailsSite={{ site_details }}
                                parentMethod={this.getPowerdBData}
                                dcduMasterData={{ dcduMasterData }}
                            />
                        ) : (
                            <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h4 style={{ color: "#c5c5c5" }}>Data Not Found DCDU</h4>
                            </div>
                        )
                    ) : null
                }

                {
                    this.state.sub_section === 'BATTERY_BANKS' ? (
                        this.state.battery_banks_data !== null ? (
                            <BatteryBanks
                                dropdown={{ dropdownObjBB }}
                                details={{ access_technologies_data }}
                                details2={{ battery_banks_data }}
                                detailsSite={{ site_details }}
                                parentMethod={this.getPowerdBData}
                                bbMasterData={{ bbMasterData }}
                                detailsupdateGeneralDetails={{ updateGeneralDetails }} />
                        ) : (
                            <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h4 style={{ color: "#c5c5c5", fontSize: '14px' }} className='textStyles-small'>Data Not Found Battery Banks</h4>
                            </div>
                        )
                    ) : null
                }

                {
                    this.state.sub_section === 'ID_BATTERY_RACKS' ? (
                        this.state.idBatteryRacks_data !== null ? (
                            <IDBR
                                details={{ access_technologies_data }}
                                dropdown={{ dropdownObjIDBR }}
                                details2={{ idBatteryRacks_data }}
                                detailsSite={{ site_details }}
                                parentMethod={this.getPowerdBData}
                            />
                        ) : (
                            <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h4 style={{ color: "#c5c5c5", fontSize: '14px' }} className='textStyles-small'>Data Not Found IDBR</h4>
                            </div>
                        )
                    ) : null
                }

                {
                    this.state.sub_section === 'GENERATOR_HUT' ? (
                        this.state.access_technologies_data !== null ? (
                            <GeneratorHut
                                dropdownGenhut={{ dropdownObjGenHut }}
                                details={{ access_technologies_data }}
                                details2={{ dcdus_data }}
                                details3={{ allData }}
                                genHutMastetData={genHutTypeMasterData}
                                detailsSite={{ site_details }}
                                parentMethod={this.getPowerdBData}
                            />
                        ) : (
                            <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h4 style={{ color: "#c5c5c5", fontSize: '14px' }} className='textStyles-small'>Data Not Found Generator Hut</h4>
                            </div>
                        )
                    ) : null
                }

                {
                    this.state.sub_section === 'ATS' ? (
                        this.state.ats_data !== null ? (
                            <ATS
                                details={{ access_technologies_data }}
                                dropdown={{ dropdownATS }}
                                allMakes={{ allATSMakes }}
                                details2={{ ats_data }}
                                detailsSite={{ site_details }}
                                parentMethod={this.getPowerdBData}
                                atsMasterData={{ atsMasterData }}
                            />
                        ) : (
                            <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h4 style={{ color: "#c5c5c5" }}>Data Not Found ATS</h4>
                            </div>
                        )
                    ) : null
                }

                {
                    this.state.sub_section === 'AC' ? (
                        this.state.air_conditioners !== null ? (
                            <AirConditioners
                                details={{ access_technologies_data }}
                                dropdownAC={{ objectDropdownAc }}
                                details2={{ air_conditioners }}
                                detailsSite={{ site_details }}
                                parentMethod={this.getPowerdBData}
                                detailsupdateGeneralDetails={{ updateGeneralDetails }}
                                acMasterData={{ acMasterData }} />) :
                            (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h4 style={{ color: "#c5c5c5" }}>Data Not Found AC</h4></div>)
                    ) : null
                }

                {
                    this.state.sub_section === 'FCB' ? (
                        this.state.fcbs_data !== null ? (
                            <FBC
                                details={{ access_technologies_data }}
                                details2={{ fcbs_data }}
                                detailsSite={{ site_details }}
                                parentMethod={this.getPowerdBData}
                                fcdMasterData={{ fcdMasterData }}
                                detailsupdateGeneralDetails={{ updateGeneralDetails }}
                            />
                        ) : (
                            <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h4 style={{ color: "#c5c5c5" }}>Data Not Found FCB</h4>
                            </div>
                        )
                    ) : null
                }

                {
                    this.state.sub_section === 'INDOOR_CABIN' ? (
                        this.state.access_technologies_data !== null ? (
                            <CabiDetails
                                details={{ access_technologies_data }}
                                cabindropdown={{ dropdownDetailsCabins }}
                                details2={{ dcdus_data }}
                                detailsSite={{ site_details }}
                                parentMethod={this.getPowerdBData}
                                details3={{ allData }}
                            />
                        ) : (
                            <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h4 style={{ color: "#c5c5c5" }}>Data Not Found Cabin Details</h4>
                            </div>
                        )
                    ) : null
                }

                {
                    this.state.sub_section === 'SPD' ? (
                        this.state.spd_data !== null ? (<SPD details={{ access_technologies_data }} dropdown={{ spdDropdownObject }} details2={{ spd_data }} detailsSite={{ site_details }} parentMethod={this.getPowerdBData} spdMasterData={{ spdMasterData }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <h4 style={{ color: "#c5c5c5" }}>Data Not Found SPD</h4></div>)
                    ) : null
                }

                {
                    this.state.sub_section === 'SOLAR' ? (
                        this.state.renewableEnergySolars_data !== null ? (
                            <RES
                                details={{ access_technologies_data }}
                                details2={{ renewableEnergySolars_data }}
                                detailsSite={{ site_details }}
                                solarDropdown={{ dropdownObjSolar }}
                                parentMethod={this.getPowerdBData}
                                details3={{ allData }}
                                detailsupdateGeneralDetails={{ updateGeneralDetails }}
                                resMasterData={{ resMasterData }}
                            />
                        ) : (
                            <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h4 style={{ color: "#c5c5c5" }}>Data Not Found RES</h4>
                            </div>
                        )
                    ) : null
                }
                {
                    this.state.sub_section === 'WIND' ? (
                        this.state.renewableEnergyWind_data !== null ? (
                            <REW
                                details={{ access_technologies_data }}
                                details2={{ renewableEnergyWind_data }}
                                detailsSite={{ site_details }}
                                windDropdown={{ dropdownObjWind }}
                                parentMethod={this.getPowerdBData}
                                details3={{ allData }}
                                rewMasterData={{ rewMasterData }}
                                detailsupdateGeneralDetails={{ updateGeneralDetails }}
                            />
                        ) : (
                            <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h4 style={{ color: "#c5c5c5" }}>Data Not Found REW</h4>
                            </div>
                        )
                    ) : null
                }

                {
                    this.state.sub_section === 'SHARED_OUT_OPERATORS' ? (
                        this.state.sharedOutOperatorses_data !== null ? (
                            <SharedOutOperators
                                dropdownSoo={{ dropdownObjSoo }}
                                details={{ access_technologies_data }}
                                details2={{ sharedOutOperatorses_data }}
                                detailsSite={{ site_details }}
                                parentMethod={this.getPowerdBData}
                                details3={{ allData }}
                                detailsupdateGeneralDetails={{ updateGeneralDetails }} />) :
                            (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h4 style={{ color: "#c5c5c5" }}>Data Not Found Shared-out Operators</h4></div>)
                    ) : null
                }

                {
                    this.state.sub_section === 'EARTHING' ? (
                        this.state.access_technologies_data !== null ? (
                            <Earthing 
                            dropdoenEAR={{ dropdownObjEarthing }} 
                            details={{ access_technologies_data }} 
                            details2={{ site_details }} 
                            parentMethod={this.getPowerdBData} />
                        ) : (
                            <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h4 style={{ color: "#c5c5c5", fontSize: '14px' }} className='textStyles-small'>Data Not Found Earthing</h4>
                            </div>
                        )
                    ) : null
                }

                {
                    this.state.sub_section === 'CEA_SUBMISSIONS' ? (
                        this.state.access_technologies_data !== null ? (
                            <CAE
                                details={{ access_technologies_data }}
                                details2={{ dcdus_data }}
                                detailsSite={{ site_details }}
                                parentMethod={this.getPowerdBData}
                            />
                        ) : (
                            <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h4 style={{ color: "#c5c5c5" }}>Data Not Found CAE</h4>
                            </div>
                        )
                    ) : null
                }

                {
                    this.state.sub_section === 'TRCSL_COMPLAINTS' ? (
                        this.state.access_technologies_data !== null ? (
                            <TRCSL
                                details={{ access_technologies_data }}
                                details2={{ dcdus_data }}
                                detailsSite={{ site_details }}
                                parentMethod={this.getPowerdBData}
                            />
                        ) : (
                            <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h4 style={{ color: "#c5c5c5" }}>Data Not Found TRCSL</h4>
                            </div>
                        )
                    ) : null
                }

                {
                    this.state.sub_section === 'OUTDOOR_CABINETS' ? (
                        this.state.cabinet_data !== null ? (
                            <Cabinet details={{ access_technologies_data }}
                                cabinetDropdown={{ objSetDropdownCabinets }}
                                details2={{ cabinet_data }}
                                detailsSite={{ site_details }}
                                parentMethod={this.getPowerdBData}
                                cabinetMasterData={{ cabinetMasterData }}
                                detailsupdateGeneralDetails={{ updateGeneralDetails }} />) :
                            (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h4 style={{ color: "#c5c5c5" }}>Data Not Found Cabinet</h4></div>)
                    ) : null
                }

                {
                    this.state.sub_section === 'DC_LOAD' ? (
                        this.state.access_technologies_data !== null ? (
                            <DCLoad details={{ access_technologies_data }}
                                details2={{ site_details }}
                                parentMethod={this.getPowerdBData} />) :
                            (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h4 style={{ color: "#c5c5c5" }}>Data Not Found DC Load</h4></div>)
                    ) : null
                }

                {
                    this.state.sub_section === 'BULK_TANK' ? (
                        this.state.bulckTank_data !== null ? (
                            <BulckTankDetails details={{ site_details }}
                                dropdownObj={{ bulckTankDropdown }} />) :
                            (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h4 style={{ color: "#c5c5c5" }}>Data Not Found Bulck Tank</h4></div>)
                    ) : null
                }

                {
                    this.state.sub_section === 'GENERAL' ? (
                        Object.keys(this.state.site_details).length !== 0 ? (
                            <GeneralDetails
                                details={{ site_details }}
                                details1={{ access_technologies_data }}
                                details3={{ renewableEnergySolars_data }}
                                detailsRectifiersData={{ rectifiers_data }}
                                detailsBatteryBanksData={{ battery_banks_data }}
                                detailsGeneratorsData={{ generators_data }}
                                detailsRenewableEnergyWindData={{ renewableEnergyWind_data }}
                                detailssharedOutOperatorses_data={{ sharedOutOperatorses_data }}
                                powerSummaryDataSet={powerSummaryDataSet}
                                detailsupdateGeneralDetails={{ updateGeneralDetails }}
                                parentMethod={this.getPowerdBData}
                                depotOfficers={this.state.site_details?.depot?.depotOfficers}
                                setDepotOfficerHeadBySiteFunction={this.setDepotOfficerHeadBySiteFunction}
                                siteID={this.props.match.params.id}
                            />
                        ) : null
                    ) : null
                }

                {
                    this.state.sub_section === 'IMAGES' ? (
                        Object.keys(this.state.site_details).length !== 0 ? (
                            // <ImageCapture details={{ site_details }} />
                            <ImageView siteID={this.props.match.params.id} />
                        ) : null
                    ) : null
                }

                {
                    this.state.sub_section === 'SLD' ? (
                        Object.keys(this.state.site_details).length !== 0 ? (
                            <Sld details={{ site_details }} />
                        ) : null
                    ) : null
                }

                {/* <div style={{marginTop: '20px'}}>
                    <div style={{backgroundColor: '#FAFAFA'}}>

                        <div className="tab-content" style={{backgroundColor: '#FAFAFA'}}>
                            <div className="tab-pane p-3 active site-tab-view" id="home" role="tabpanel">
                                {Object.keys(this.state.site_details).length != 0 && 
                                <GeneralDetails details={{ site_details }} details1={{ access_technologies_data }}
                                 details3={{ renewableEnergySolars_data }} detailsRectifiersData={{ rectifiers_data }} 
                                 detailsBatteryBanksData={{ battery_banks_data }} 
                                 detailsGeneratorsData={{ generators_data }} 
                                 detailsRenewableEnergyWindData={{ renewableEnergyWind_data }} 
                                 detailssharedOutOperatorses_data={{ sharedOutOperatorses_data }}
                                  powerSummaryDataSet={powerSummaryDataSet} detailsupdateGeneralDetails={{ updateGeneralDetails }} parentMethod={this.getPowerdBData} />}
                            </div>
                            <div className="tab-pane p-3  site-tab-view" id="sld" role="tabpanel">
                                {Object.keys(this.state.site_details).length != 0 && <Sld details={{ site_details }} />}
                            </div>
                            <div className="tab-pane p-3 site-tab-view" id="profile" role="tabpanel">
                                <AssetDetails />
                            </div>
                            <div className="tab-pane p-3 site-tab-view" id="imageCapture" role="tabpanel">
                                {Object.keys(this.state.site_details).length != 0 && <ImageCapture details={{ site_details }} />}
                            </div>
                            <div className="tab-pane p-3 site-tab-view" id="bulckTank" role="tabpanel">
                                {Object.keys(this.state.site_details).length != 0 && <BulckTankDetails details={{ site_details }} dropdownObj={{ bulckTankDropdown }} />}
                            </div>
                            <div className="tab-pane p-3 site-tab-view " id="generator" role="tabpanel">
                                {this.state.generators_data !== null ? (
                                GeneratorDetails details={{ site_details }} 
                                details1={{ access_technologies_data }} 
                                details2={{ generators_data }} 
                                genDropdown={{ generatorDropDownObject }} 
                                parentMethod={this.getPowerdBData} 
                                genMasterDataSet={genMasterData} 
                                detailsupdateGeneralDetails={{ updateGeneralDetails }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found Generator</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view " id="accessTechnologies" role="tabpanel">
                                {this.state.access_technologies_data !== null ? (<AccessTechnologies details={{ access_technologies_data }} details2={{ site_details }} parentMethod={this.getPowerdBData} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found  Access Technologies</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view " id="mainPowerConnection" role="tabpanel">
                                {this.state.access_technologies_data !== null ? (
                                    <MainPowerConnection details={{ access_technologies_data }}
                                        details2={{ site_details }}
                                        parentMethod={this.getPowerdBData}
                                        mpConnectionMasterData={mpConnectionMasterData}
                                        detailsupdateGeneralDetails={{ updateGeneralDetails }}
                                    />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <h4 style={{ color: "#c5c5c5" }}>Data Not Found  Main Power Connection</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view" id="sharedBackupPower" role="tabpanel">
                                {this.state.access_technologies_data !== null ? (<SharedBackupPower details={{ access_technologies_data }} details2={{ site_details }} parentMethod={this.getPowerdBData} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found  Shared Backup Power</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view" id="dcPowerSharing" role="tabpanel">
                                {this.state.access_technologies_data !== null ? (<DCPowerSharing details={{ access_technologies_data }} details2={{ site_details }} parentMethod={this.getPowerdBData} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found  DC Sharing</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view" id="power_capacity_kw" role="tabpanel">
                                {this.state.access_technologies_data !== null ? (<PowerCapacityKw details={{ access_technologies_data }} details2={{ site_details }} parentMethod={this.getPowerdBData} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found  Power Capacity</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view" id="dc_load" role="tabpanel">
                                {this.state.access_technologies_data !== null ? (<DCLoad details={{ access_technologies_data }} details2={{ site_details }} parentMethod={this.getPowerdBData} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found DC Load</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view " id="rectifiers" role="tabpanel">
                                {this.state.rectifiers_data !== null ? (<Rectifiers details={{ access_technologies_data }} details2={{ rectifiers_data }} detailsSite={{ site_details }} dropDownInfoRectifier={{ dropDownObjectSetRectifier }} parentMethod={this.getPowerdBData} rectifiersMasterData={{ rectifiersMasterData }} detailsupdateGeneralDetails={{ updateGeneralDetails }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found Rectifiers</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view  " id="battery_banks" role="tabpanel">
                                {this.state.battery_banks_data !== null ? (
                                <BatteryBanks dropdown={{ dropdownObjBB }} 
                                details={{ access_technologies_data }} 
                                details2={{ battery_banks_data }} 
                                detailsSite={{ site_details }}
                                 parentMethod={this.getPowerdBData} 
                                 bbMasterData={{ bbMasterData }} 
                                 detailsupdateGeneralDetails={{ updateGeneralDetails }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found Battery Banks</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view " id="ac" role="tabpanel">
                                {this.state.air_conditioners !== null ? (<AirConditioners details={{ access_technologies_data }} dropdownAC={{ objectDropdownAc }} details2={{ air_conditioners }} detailsSite={{ site_details }} parentMethod={this.getPowerdBData} detailsupdateGeneralDetails={{ updateGeneralDetails }} acMasterData={{ acMasterData }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found AC</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view " id="spd" role="tabpanel">
                                {this.state.spd_data !== null ? (<SPD details={{ access_technologies_data }} dropdown={{ spdDropdownObject }} details2={{ spd_data }} detailsSite={{ site_details }} parentMethod={this.getPowerdBData} spdMasterData={{ spdMasterData }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found SPD</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view " id="cabinet" role="tabpanel">
                                {this.state.cabinet_data !== null ? (<Cabinet details={{ access_technologies_data }} cabinetDropdown={{ objSetDropdownCabinets }} details2={{ cabinet_data }} detailsSite={{ site_details }} parentMethod={this.getPowerdBData} cabinetMasterData={{ cabinetMasterData }} detailsupdateGeneralDetails={{ updateGeneralDetails }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found Cabinet</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view " id="Generators2" role="tabpanel">
                                {this.state.access_technologies_data !== null ? (<GeneratorPd details={{ access_technologies_data }} details2={{ generators_data }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found Generators</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view " id="earthing" role="tabpanel">
                                {this.state.access_technologies_data !== null ? (<Earthing dropdoenEAR={{ dropdownObjEarthing }} details={{ access_technologies_data }} details2={{ site_details }} parentMethod={this.getPowerdBData} />) :
                                 (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found Earthing</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view " id="ats" role="tabpanel">
                                {this.state.ats_data !== null ? (<ATS details={{ access_technologies_data }} dropdown={{ dropdownATS }} allMakes={{ allATSMakes }} details2={{ ats_data }} detailsSite={{ site_details }} parentMethod={this.getPowerdBData} atsMasterData={{ atsMasterData }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found ATS</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view " id="dcdus" role="tabpanel">
                                {this.state.dcdus_data !== null ? (<DCDUS details={{ access_technologies_data }} dcdudrop={{ objDropdowndcdu }} details2={{ dcdus_data }} detailsSite={{ site_details }} parentMethod={this.getPowerdBData} dcduMasterData={{ dcduMasterData }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found DCDU</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view " id="fbc" role="tabpanel">
                                {this.state.fcbs_data !== null ? (<FBC details={{ access_technologies_data }} details2={{ fcbs_data }} detailsSite={{ site_details }} parentMethod={this.getPowerdBData} fcdMasterData={{ fcdMasterData }} detailsupdateGeneralDetails={{ updateGeneralDetails }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data No tFound FCB</h4></div>)}
                            </div>
                            <div className="tab-pane p-3  site-tab-view" id="cea" role="tabpanel">
                                {this.state.access_technologies_data !== null ? (<CAE details={{ access_technologies_data }} details2={{ dcdus_data }} detailsSite={{ site_details }} parentMethod={this.getPowerdBData} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found CAE</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view" id="trcsl" role="tabpanel">
                                {this.state.access_technologies_data !== null ? (<TRCSL details={{ access_technologies_data }} details2={{ dcdus_data }} detailsSite={{ site_details }} parentMethod={this.getPowerdBData} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found TRCSL</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view" id="res" role="tabpanel">
                                {this.state.renewableEnergySolars_data !== null ? (<RES details={{ access_technologies_data }} details2={{ renewableEnergySolars_data }} detailsSite={{ site_details }} solarDropdown={{ dropdownObjSolar }} parentMethod={this.getPowerdBData} details3={{ allData }} detailsupdateGeneralDetails={{ updateGeneralDetails }} resMasterData={{ resMasterData }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data NotFound RES</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view" id="rew" role="tabpanel">
                                {this.state.renewableEnergyWind_data !== null ? (<REW details={{ access_technologies_data }} details2={{ renewableEnergyWind_data }} detailsSite={{ site_details }} windDropdown={{ dropdownObjWind }} parentMethod={this.getPowerdBData} details3={{ allData }} rewMasterData={{ rewMasterData }} detailsupdateGeneralDetails={{ updateGeneralDetails }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found REW</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view" id="cabinDetails" role="tabpanel">
                                {this.state.access_technologies_data !== null ? (<CabiDetails details={{ access_technologies_data }} cabindropdown={{ dropdownDetailsCabins }} details2={{ dcdus_data }} detailsSite={{ site_details }} parentMethod={this.getPowerdBData} details3={{ allData }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found Cabin Details</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view" id="generatorHut" role="tabpanel">
                                {this.state.access_technologies_data !== null ? (<GeneratorHut dropdownGenhut={{ dropdownObjGenHut }} details={{ access_technologies_data }} details2={{ dcdus_data }} details3={{ allData }} genHutMastetData={genHutTypeMasterData} detailsSite={{ site_details }} parentMethod={this.getPowerdBData} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found Generator Hut</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view" id="SharedoutOperators" role="tabpanel">
                                {this.state.sharedOutOperatorses_data !== null ? (<SharedOutOperators dropdownSoo={{ dropdownObjSoo }} details={{ access_technologies_data }} details2={{ sharedOutOperatorses_data }} detailsSite={{ site_details }} parentMethod={this.getPowerdBData} details3={{ allData }} detailsupdateGeneralDetails={{ updateGeneralDetails }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found Shared-out Operators</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view " id="IdBatteryRacks56" role="tabpanel">
                                {this.state.idBatteryRacks_data !== null ? (
                                    <IDBR details={{ access_technologies_data }} dropdown={{ dropdownObjIDBR }} details2={{ idBatteryRacks_data }} detailsSite={{ site_details }} parentMethod={this.getPowerdBData} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found IDBR</h4></div>)}
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        );
    }
}

export default withRouter(SiteInfo);

