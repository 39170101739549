import React, { Component } from 'react'
import ReactApexChart from 'react-apexcharts'

 class MultipleRadialBars extends Component {
    constructor(props) {
        super(props);
        this.state={
            series: [],
            options: {
                chart: {
                    height: 200,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        dataLabels: {
                            name: {
                                fontSize: '22px',
                            },
                            value: {
                                fontSize: '16px',
                                percentage: false,
                            },
                            total: {
                                show: true,
                                label: 'Total',
                                formatter: function (w) {
                                    return 0
                                }
                            }
                        }
                    }
                },
                labels: [],
            },
            
        }
    }

    componentDidMount(){
        console.log(this.props.pendingReqTotal.reqPendingTotal)
        let totalCout=this.props.pendingReqTotal.reqPendingTotal
        this.setState({
            series:this.props.pendingReqSeries.reqPendingSeriesCount,
            options: {
                chart: {
                    height: 200,
                    type: 'radialBar',
                },
                colors : this.props.colorArray,
                plotOptions: {
                    radialBar: {
                        dataLabels: {
                            name: {
                                fontSize: '22px',
                            },
                            value: {
                                fontSize: '16px',
                                percentage: false,
                            },
                            total: {
                                show: true,
                                label: 'Total',
                                formatter: function (w) {
                                    return totalCout.toLocaleString()
                                }
                            }
                        }
                    }
                },
                labels:this.props.pendingReqLable.reqPendingSerieslabels,
            },
        })
    }
    
  render() {
    return (
        <div id="chart">
        <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" height={350} />
    </div>
    )
  }
}
export default MultipleRadialBars;