import ReactFlow, { MiniMap, Controls, Background, applyNodeChanges } from 'react-flow-renderer';
import { useCallback, useState, useEffect } from 'react';
import 'react-flow-renderer/dist/style.css';
import {getsldDiagramById} from '../../api/index';

import TextUpdaterNode from './TextUpdaterNode.js';

import './text-updater-node.css';

function Flow() {
    let fullEdgesSet = []
    let fullNodesSet = []
    let fullObject = [ ]
  
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    useEffect(() => {

        async function getDatasldDiagramById() {
            var res = await getsldDiagramById(3);
            console.log(res);
            if(res.success){
                fullObject=res.data
                setNodeLevelOne(fullObject)
                setEdgeslOne(fullObject)

            }

        }
        getDatasldDiagramById()
        

    

    }, []);
    

   

    const generateColor = () => {
        let randomColorString = "#";
        const arrayOfColorFunctions = "0123456789abcdef";
        for (let x = 0; x < 6; x++) {
          let index = Math.floor(Math.random() * 16);
          let value = arrayOfColorFunctions[index];
      
          randomColorString += value;
        }
        return randomColorString;
      };
    const setEdgeslOne = (data) => {
        const levelOne = data.filter(person => person.levelNo == 1)
        createEdges(levelOne, 1)

    }
    const setEdgeslTwo = (data) => {
        const levelTwo = data.filter(person => person.levelNo == 2)
        createEdges(levelTwo, 2)

    }
    const setEdgeslThree= (data) => {
        const levelThree = data.filter(person => person.levelNo == 3)
        createEdges(levelThree, 3)

    }
    const setEdgeslFour= (data) => {
        const levelFour = data.filter(person => person.levelNo == 4)
        createEdges(levelFour,4)

    }

    const setNodeLevelOne = (data) => {
        const levelOne = data.filter(person => person.levelNo == 1)
        createNodes(levelOne, 1)
        
    };
    const setNodeLevelTwo = (data) => {
        const levelTwo = data.filter(person => person.levelNo == 2)
        createNodes(levelTwo, 2)
      

    };
    const setNodeLevelThree = (data) => {
        const levelThree = data.filter(person => person.levelNo == 3)
        createNodes(levelThree, 3)
    };
    const setNodeLevelFour = (data) => {
        const levelFour = data.filter(person => person.levelNo == 4)
        createNodes(levelFour, 4)
    };
    const setNodeLevelFive = (data) => {
        const levelFive = data.filter(person => person.levelNo == 5)
        createNodes(levelFive, 5)
    };
    const createEdges = (data, lv) => {
        if (lv == 1) {

            var newseArray
            var newseArray2 = []
         
            var newNodesArray = data.map(value => {
           
               value.connectedItems.map(x => {
                 newseArray = {
                  "id": value.itemId+'-'+x.toString(),
                  source: value.itemId.toString(),
                  target: x.toString(),
                  style: { stroke: "#f6ab6c" }
                }
      
                newseArray2 = [...newseArray2, newseArray];
              })
      
              
            })
      
            fullEdgesSet=newseArray2
            setEdgeslTwo(fullObject)
           
        }
        if (lv == 2) {
            console.log("fullEdgesSet2")
            console.log(fullEdgesSet)
            var newseArray
            var newseArray2 = []
            var newNodesArray = data.map(value => {
           
              value.connectedItems.map(x => {
                newseArray = {
                 "id": value.itemId+'-'+x.toString(),
                 source: value.itemId.toString(),
                 target: x.toString()
               }
      
               newseArray2 = [...newseArray2, newseArray];
             })
      
             
           })

         
           
           fullEdgesSet = [...fullEdgesSet, ...newseArray2];
           setEdgeslThree(fullObject)

          
          
        }
        if (lv == 3) {
            console.log("fullEdgesSet2")
            console.log(fullEdgesSet)
            var newseArray
            var newseArray2 = []
            var newNodesArray = data.map(value => {
           
              value.connectedItems.map(x => {
                newseArray = {
                 "id": value.itemId+'-'+x.toString(),
                 source: value.itemId.toString(),
                 target: x.toString()
               }
      
               newseArray2 = [...newseArray2, newseArray];
             })
      
             
           })

         
           
           fullEdgesSet = [...fullEdgesSet, ...newseArray2];
           setEdgeslFour(fullObject)

          
          
        }
        if (lv == 4) {
            console.log("fullEdgesSet2")
            console.log(fullEdgesSet)
            var newseArray
            var newseArray2 = []
            var newNodesArray = data.map(value => {
           let setColor=generateColor();
              value.connectedItems.map(x => {
                newseArray = {
                 "id": value.itemId+'-'+x.toString(),
                 source: value.itemId.toString(),
                 target: x.toString(),
                 style: { stroke:setColor }
               }
      
               newseArray2 = [...newseArray2, newseArray];
             })
      
             
           })

         
           
           fullEdgesSet = [...fullEdgesSet, ...newseArray2];
           setEdges(fullEdgesSet)

          
          
        }

    }
    const createNodes = (data, lv) => {

        if (lv == 1) {

            var newseArray
            var setY =30
            var newNodesArray = data.map(value => {
                if (setY ==30) {
                    var setY12 = setY
                    setY = setY + 100
                } else {
                    var setY12 = setY
                    setY = setY + 100
                }
                return newseArray = {
                    "id": value.itemId.toString(),
                    "data": { label: value.name },
                    type: 'input',
                    sourcePosition: "right",
                    position: { x: 20, y: setY12 },
                    style: {
                        background: "#FFA500",
                        color: "#FFFFFF",
                        border: "1px solid #222138",
                    
                      }
                }
            })
            console.log('newNodesArray')
            fullNodesSet = newNodesArray
            setNodeLevelTwo(fullObject)
        }
        if (lv == 2) {

            var newseArray
            var setY = 60
            var newNodesArray = data.map(value => {
                if (setY ==60) {
                    var setY12 = setY
                    setY = setY + 100
                } else {
                    var setY12 = setY
                    setY = setY + 100
                }
                return newseArray = {
                    "id": value.itemId.toString(),
                    "data": { label: value.name },
                    targetPosition: "left",

                    sourcePosition: "right",
                    position: { x: 250, y: setY12 },
                    style: {
                        background: "#5539CC",
                        color: "#FFFFFF",
                        border: "1px solid #222138",
                    
                      }
                }
            })

            fullNodesSet = [...fullNodesSet, ...newNodesArray];
            setNodeLevelThree(fullObject)
        }
        if (lv == 3) {
            var newseArray
            var setY =60
            var newNodesArray = data.map(value => {
                if (setY ==60) {
                    var setY12 = setY
                    setY = setY + 100
                } else {
                    var setY12 = setY
                    setY = setY + 100
                }
                return newseArray = {
                    "id": value.itemId.toString(),
                    "data": { label: value.name },
                    targetPosition: "left",

                    sourcePosition: "right",
                    position: { x:420, y: setY12 },
                    style: {
                        background: "#5539CC",
                        color: "#FFFFFF",
                        border: "1px solid #222138",
                    
                      }
                }
            })

            fullNodesSet = [...fullNodesSet, ...newNodesArray];
            setNodeLevelFour(fullObject)




        }
        if (lv == 4) {
            var newseArray
            var setY = 80
            var newNodesArray = data.map(value => {
                if (setY == 80) {
                    var setY12 = setY
                    setY = setY + 100
                } else {
                    var setY12 = setY
                    setY = setY + 100
                }
                return newseArray = {
                    "id": value.itemId.toString(),
                    "data": { label: value.name },
                    targetPosition: "left",

                    sourcePosition: "right",
                    position: { x: 650, y: setY12 },
                    style: {
                        background: "#B6B6B4",
                        color: "#FFFFFF",
                        border: "1px solid #222138",
                    
                      }
                }
            })

            fullNodesSet = [...fullNodesSet, ...newNodesArray];
            setNodeLevelFive(fullObject)


        }
        if (lv == 5) {
            var newseArray
            var setY = 10
            var newNodesArray = data.map(value => {
                if (setY == 10) {
                    var setY12 = setY
                    setY = setY + 60
                } else {
                    var setY12 = setY
                    setY = setY + 60
                }
                return newseArray = {
                    "id": value.itemId.toString(),
                    "data": { label: value.name },
                    type: 'output',

                    targetPosition: "left",


                    position: { x: 950, y: setY12 },
                }
            })

            fullNodesSet = [...fullNodesSet, ...newNodesArray];
            setNodes(fullNodesSet)
        }
    }


    const nodeTypes = { textUpdater: TextUpdaterNode };

    const onNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [setNodes]
    );

    return (
        <div className="container-fluid">
            <div className="row">
                {/* aaa */}
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    nodeTypes={nodeTypes}
                    onNodesChange={onNodesChange}
                // onEdgesChange={onEdgesChange}
                // onConnect={onConnect}
                >
                    <MiniMap />
                    <Controls />
                </ReactFlow>
            </div>
        </div>
    );
}

export default Flow;
