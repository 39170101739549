import React, { Component } from "react";
import { Table, Input, Tag, Space, DatePicker } from "antd";
import ReactExport from "react-data-export";
import { getExportPaymentSheet } from "../../api/index";
import { getDonationCategories, getData } from "../../actions/index";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'

import ReactLoading from 'react-loading';
const moment = require('moment');




const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { RangePicker } = DatePicker;

class RefuelFullExport extends Component {
    constructor(props) {
        super(props);
        this.state = {
          title: "",
          filterTable: null,
          data_list: [],
          startDate: "",
          endDate: "",
          loading:false,
    
        };
      }
    
      componentDidMount() {
        if (JSON.parse(localStorage.getItem('serviceTypeSet')) == null) {
          const { history } = this.props;
   
          history.push("/");
         
        } else {
          this.getSetPageData(JSON.parse(localStorage.getItem('serviceTypeSet')) , 15)
        }
        this.props.getData();

        //console.log(this.props.details);
      }
      getSetPageData =(serviceTypeIs,Pageid) =>  {
        //console.log("this.props");
        //console.log(serviceTypeIs.serviceType);
        if(serviceTypeIs.serviceType.filter(checkuser=>checkuser?.id==Pageid)[0] == null ){
          const { history } = this.props;
       
          history.push("/not-allowed");
        }
        
      };
      getDataexpot = async (data) => {
          //console.log(data);
        var res = await getExportPaymentSheet(data);
        //console.log("getExportPaymentSheet");
        //console.log(res);
        if (res.success) {
          this.setState({
            loading: false,
            data_list: res.data,
          });
        }
      };
      onChangeDate = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);
        this.setState({
          startDate: dateString,
        });
      };
      onChangeDate2 = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);
        this.setState({
          endDate: dateString,
        });
      };
      searchClick = () => {
        //console.log("search");
        if(this.state.startDate!="" && this.state.endDate!=""){
          var data =  {
            "startDate": this.state.startDate,
            "endDate": this.state.endDate,
           
        };
          this.setState({
            loading: true,
          });
          this.getDataexpot(data);
        }
        
      };
      render() {
        const fontValue =  {sz: "12", bold: true,fill: {patternType: "solid", fgColor: {rgb: "FF0B0713"}}};
        const { filterTable } = this.state;
        const dataSet1 = this.state.data_list;
    
       
    
        const StatisticDataSet = [
    {
      columns: [
        // Sec 01
        {title: "Request Code",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:210}},//char width 
        {title: "Site Id", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width 
        {title: "Site Name", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Region", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//char width cManual_transport_amount
        {title: "Sub Region", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//char width cManual_transport_amount
        {title: "Depot", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//char width cManual_transport_amount
        {title: "Status", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//char width cManual_transport_amount
        {title: "Current Status", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//char width cManual_transport_amount
        {title: "Created Date",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}} },fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//pixels width 
        {title: "Created Time",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}} },fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:180}},//pixels width 
        {title: "Created By",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width   
       // Sec01.2
       {title: "Type",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width   
       {title: "Admin Approval",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width   
       {title: "Admin Approval Time",style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width   
        
        
          // Sec 02
        {title: "Fuel Filled", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:150}},//char width cManual_transport_amount
        {title: "Fuel Filled Time", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:150}},//char width cManual_transport_amount
        {title: "Fuel Filled Vc Verified Time", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Fuel Filled Do Verified Time", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:150}},//char width cManual_transport_amount
        {title: "Fuel Filled Pe Verified Time", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:150}},//char width cManual_transport_amount
        {title: "Fuel Filled Vc Rejected Time", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Fuel Filled Do Rejected Time", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:150}},//char width cManual_transport_amount
        {title: "Fuel Filled Pe Rejected Time", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:150}},//char width cManual_transport_amount
         {title: "System Manual Distance", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:150}},//char width cManual_transport_amount
         {title: "Vendor Sujested Manual Distance", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:150}},//char width cManual_transport_amount
        
    
        // Sec 03
        {title: "Approved Manual Transport", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Manual Transport Amount", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Service Code - Manual Transport", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "System Motarable Distance", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Vendor Sujested Motarable Distance", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        
        {title: "Approved Motarable Distance", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Motarable Transport Cost", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Service Code - Motarable  Transport", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Filling Quantity", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Filling Amount", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Service Code - Fuel Filling ", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Total Amount", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        
    
        
        // Sec 04
       // {title: "Service Code - Manual Transport", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
      //  {title: "Service Code - Motarable  Transport", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
       // {title: "Service Code - Fuel Filling", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Within SLA", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Outage Time", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Fuel Filled Vendor", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        
          // Sec 05
        {title: "Fuel Filled Team", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
       // {title: "Fuel utilized card ", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Abnormal Filling", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Abnormal Investigated", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
         
          // Sec 06  abnormalInvestigated
        {title: "Gen1 Name", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Gen1 Model", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Gen1 Tank Capacity", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Gen1 Tank Type", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Gen1 Efficinecy", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Gen1 HalfFilled", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Gen1 PreviousMeterReading", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Gen1 RemainingQuantity", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Gen1 RuningHours", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        {title: "Gen1 This Filling Meter Reading", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
        
        
    
         // Sec 07
         {title: "Gen2 Avilable", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
         {title: "Gen2 Name", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
         {title: "Gen2 Model", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
         {title: "Gen2 Tank Capacity", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
         {title: "Gen2 Tank Type", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
         {title: "Gen2 Efficinecy", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
         {title: "Gen2 HalfFilled", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
         {title: "Gen2 PreviousMeterReading", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
         {title: "Gen2 RemainingQuantity", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
         {title: "Gen2 RuningHours", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
         {title: "Gen2 This Filling Meter Reading", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:120}},//char width cManual_transport_amount
         //sec8
         {title: "procrement Code", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:220}},//char width cManual_transport_amount
        // {title: "System Motarable Distance", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:220}},//char width cManual_transport_amount
        // {title: "Vendor Sujested Motarable Distance", style: {border:{right:{ style:"thin", color: {rgb: "FF0B0713"}},bottom:{ style:"thin", color: {rgb: "FF0B0713"}},top:{ style:"thin", color: {rgb: "FF0B0713"}}},fill: {patternType: "solid", fgColor: {rgb: "E7E9E6"}},font:fontValue} ,width: {wpx:220}},//char width cManual_transport_amount
        
        
    ],
    data:dataSet1?.map((data)=>[
    
      // Sec 01
      {value:data.requestCode ,style: { font: { sz: "12" } }},
      {value:data.siteId?(data.siteId):("Null") ,style: { font: { sz: "12" } }},
      {value:data.site?(data.site):("Null") ,style: { font: { sz: "12" } }},
      {value:data.region?(data.region):("Null") ,style: { font: { sz: "12" } }},
      {value:data.subRegion?(data.subRegion):("Null") ,style: { font: { sz: "12" } }},
      {value:data.depot?(data.depot):("Null") ,style: { font: { sz: "12" } }},
      {value:data.status?(data.status):("Null") ,style: { font: { sz: "12" } }}, 
      {value:data.currentStatus?(data.currentStatus):("Null") ,style: { font: { sz: "12" } }}, 
      {value:data.createdTime? moment((data.createdTime)).format('YYYY-MM-DD'):("Null") ,style: { font: { sz: "12" } }},
      {value:data.createdTime? moment((data.createdTime)).format('HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
      {value:data.createdBy?(data.createdBy):("Null") ,style: { font: { sz: "12" } }},
      // Sec 1.2
      {value:data.requestType?(data.requestType):("Null") ,style: { font: { sz: "12" } }},
      {value:data.peApproval?(data.peApproval):("Null") ,style: { font: { sz: "12" } }},
      {value:data.peApprovalTime? moment((data.peApprovalTime)).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
  
      // Sec 02
         {value:data.fuelField?("Yes"):("No") ,style: { font: { sz: "12" } }},
         {value:data.fuelFilledTime?moment(data.fuelFilledTime).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
         {value:data.fuelFilledVcVerifiedTime?moment(data.fuelFilledVcVerifiedTime).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
         {value:data.fuelFilledDoVerifiedTime?moment(data.fuelFilledDoVerifiedTime).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
         {value:data.fuelFilledPeVerifiedTime?moment(data.fuelFilledPeVerifiedTime).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
         {value:data.fuelFilledVcRejectedTime?moment(data.fuelFilledVcRejectedTime).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},

         {value:data.fuelFilledDoRejectedTime?moment(data.fuelFilledDoRejectedTime).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
         {value:data.fuelFilledPeRejectedTime?moment(data.fuelFilledPeRejectedTime).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
         {value:data.systemManualTransport?(data.systemManualTransport):(0) ,style: { font: { sz: "12" } }},
         {value:data.vendorSujestedManualTransport?(data.vendorSujestedManualTransport):(0) ,style: { font: { sz: "12" } }},
    
      // Sec 03
     
      {value:data.manualTransport?(data.manualTransport):(0) ,style: { font: { sz: "12" } }},
      {value:data.manualTransportAmount?(data.manualTransportAmount):(0) ,style: { font: { sz: "12" } }},
      {value:data.manualTransportServiceCode?(data.manualTransportServiceCode):("Null") ,style: { font: { sz: "12" } }},   
      {value:data.systemVehicleTransport?(data.systemVehicleTransport):(0) ,style: { font: { sz: "12" } }},
      {value:data.vendorSujestedVehicleTransport?(data.vendorSujestedVehicleTransport):(0) ,style: { font: { sz: "12" } }},
    
      {value:data.vehicleTransport?(data.vehicleTransport):(0) ,style: { font: { sz: "12" } }},
      {value:data.vehicleTransportAmount?(data.vehicleTransportAmount):(0) ,style: { font: { sz: "12" } }},
      {value:data.vehicleTransportServiceCode?(data.vehicleTransportServiceCode):("Null") ,style: { font: { sz: "12" } }},   
    
      {value:data.fillingQuantity?(data.fillingQuantity):(0) ,style: { font: { sz: "12" } }},
      {value:data.fillingAmount?(data.fillingAmount):(0) ,style: { font: { sz: "12" } }},
      {value:data.fillingServiceCode?(data.fillingServiceCode):("Null") ,style: { font: { sz: "12" } }},   
      
      {value:data.totalAmount?(data.totalAmount):(0) ,style: { font: { sz: "12" } }},
    
      // Sec 04
      {value:data.slaBeach?("No"):("Yes"),style: { font: { sz: "12" } }}, 
      {value:data.outageTtime?moment(data.outageTtime).format('YYYY-MM-DD HH:mm:ss'):("Null") ,style: { font: { sz: "12" } }},
      {value:data.vendor?(data.vendor):("Null"),style: { font: { sz: "12" } }}, 
      
     // Sec 05
     {value:data.fuelFilledTeam?(data.fuelFilledTeam):("Null"),style: { font: { sz: "12" } }}, 
    // {value:data.fuelFilledTeam?("Null"):("Null"),style: { font: { sz: "12" } }}, 
     {value:data.abnormalFilling?("Yes"):("No"),style: { font: { sz: "12" } }}, 
    {value:data.abnormalInvestigated?("Yes"):("No") ,style: { font: { sz: "12" } }},
     
      // Sec 06
    {value:data.gen1Name?(data.gen1Name):("Null") ,style: { font: { sz: "12" } }},
    {value:data.gen1Model?(data.gen1Model):("Null") ,style: { font: { sz: "12" } }},
    {value:data.gen1TankCapacity?(data.gen1TankCapacity):(0) ,style: { font: { sz: "12" } }},
    {value:data.gen1TankType?(data.gen1TankType):("Null") ,style: { font: { sz: "12" } }},
    {value:data.gen1Efficinecy?(data.gen1Efficinecy):(0) ,style: { font: { sz: "12" } }},
    {value:data.gen1HalfFilled?("Yes"):("No") ,style: { font: { sz: "12" } }},
    {value:data.gen1PreviousMeterReading?(data.gen1PreviousMeterReading):(0) ,style: { font: { sz: "12" } }},
    {value:data.gen1RemainingQuantity?(data.gen1RemainingQuantity):(0) ,style: { font: { sz: "12" } }},
    {value:data.gen1RuningHours?(data.gen1RuningHours):(0) ,style: { font: { sz: "12" } }},
    {value:data.gen1thisFillingMeterReading?(data.gen1thisFillingMeterReading):(0) ,style: { font: { sz: "12" } }},
    
     // Sec 07
     {value:data.gen2Avilable?(data.gen2Avilable):("Null") ,style: { font: { sz: "12" } }},
     {value:data.gen2Name?(data.gen2Name):("Null") ,style: { font: { sz: "12" } }},
     {value:data.gen2Model?(data.gen2Model):("Null") ,style: { font: { sz: "12" } }},  
     {value:data.gen2TankCapacity?(data.gen2TankCapacity):(0) ,style: { font: { sz: "12" } }},
     {value:data.gen2TankType?(data.gen2TankType):("Null") ,style: { font: { sz: "12" } }},
     {value:data.gen2Efficinecy?(data.gen2Efficinecy):(0) ,style: { font: { sz: "12" } }},
     {value:data.gen2HalfFilled?("Yes"):("No") ,style: { font: { sz: "12" } }},
     {value:data.gen2PreviousMeterReading?(data.gen2PreviousMeterReading):(0) ,style: { font: { sz: "12" } }},
     {value:data.gen2RemainingQuantity?(data.gen2RemainingQuantity):(0) ,style: { font: { sz: "12" } }},
     {value:data.gen2RuningHours?(data.gen2RuningHours):(0) ,style: { font: { sz: "12" } }},
     {value:data.gen2thisFillingMeterReading?(data.gen2thisFillingMeterReading):(0) ,style: { font: { sz: "12" } }},
      
     // sec8
     {value:data.procrementCode?(data.procrementCode):("Null") ,style: { font: { sz: "12" } }},
    
     
   
    
       
    ]),
    }
        ];
    
        const columns = [
          {
            title: "Request Code",
            dataIndex: "requestCode",
            key: "requestCode",
            render: (item) => <span>{item}</span>,
          },
          {
            title: "Site Name",
            dataIndex: "site",
            key: "site",
            render: (item) => <span>{item}</span>,
          },
          {
            title: "Site Id",
            dataIndex: "siteId",
            key: "siteId",
            render: (item) => <span>{item}</span>,
          },
          {
            title: "Request Date",
            dataIndex: "requestDate",
            key: "requestDate",
            render: (item) => <span>{moment((item)).format('YYYY-MM-DD')}</span>,
          },
          {
            title: "Request Time",
            dataIndex: "requestDate",
            key: "requestDate",
            render: (item) => <span>{moment((item)).format('HH:mm:ss')}</span>,
          },
        ];
        const timestamp = Date.now();
        return (
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="row">
                    <div class="col">
                      <h4 class="page-title">Refuel Full Export</h4>
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Data Export </a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Refuel Full Export</a></li>

                      </ol>
                    </div>
                    <div class="col-auto align-self-center">
                      <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                      </a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div className="row">
            <div className="col-md-3 col-lg-3 col-xl-3">
              <DatePicker
                placeholder="Start Date"
                style={{ width: "100%" }}
                onChange={this.onChangeDate}
              />
            </div>
            <div className="col-md-3 col-lg-3 col-xl-3">
              <DatePicker
                placeholder="End Date"
                style={{ width: "100%" }}
                onChange={this.onChangeDate2}
              />
            </div>
            <div className="col-md-6 col-lg-6 col-xl-6">
              <button
                onClick={this.searchClick}
                class="btn btn-primary"
                //style={{ float: "right" }}
              >
                <i class="fas fa-search"> </i> Search
              </button>
            </div>
            <br/><br/>
            <div className="col-lg-12 col-xl-12">
              <div className="card">
                <div className="card-header">
                  <div className="row ">
                    <div className="col">
                      <h4 className="card-title">
                        Report -Refuel Full export{" "}
                        <ExcelFile
                          filename={"Refuel Full export -" + timestamp}
                          element={
                            <button
                              class="btn btn-primary"
                              style={{ float: "right" }}
                            >
                              <i class="fas fa-file-export"> </i>Refuel Full Export
                            </button>
                          }
                        >
                         
                          <ExcelSheet dataSet={StatisticDataSet} name="Refuel Full Export"/>
                        </ExcelFile>
                      </h4>
                      <div></div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
               
                  <Table
                    size="small"
                    columns={columns}
                    // rowKey="id"
                    loading={this.state.loading}
                    dataSource={this.state.data_list}
                  />
        
                </div>
              </div>
            </div>
          </div>
          </div>
        );
      }
}
function mapStateToProps(state) {
  //console.log("state", state);
  return {
    organization_count: state.organization.length,
    regions: state.regions,
    depots: state.depots,
    sites_count: state.sites.length,
    vendor_count: state.vendor.length,
    roles_count: state.roles.length,
  };
}
export default   withRouter(connect(mapStateToProps, { getDonationCategories, getData })(
  RefuelFullExport
)); 