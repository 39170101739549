import React, { Component } from 'react'; 
import SimpleReactValidator from 'simple-react-validator';
import {createNewVendor} from '../../api/index';
import {addVendor} from '../../actions/index';
import { connect } from "react-redux"; 
import { ToastContainer, toast } from 'material-react-toastify';
  import 'material-react-toastify/dist/ReactToastify.css';

 class ServiceTypes extends Component {

	constructor(props) {
		super(props) 
		this.validator = new SimpleReactValidator();
		this.state ={
			name : "",
			code: "",
			vatNumber: "",
			contactNumber: "",
			contactPerson: "",
			contactEmail: "",
			contactAddress : ""
		}
	}

	componentDidMount(){  
		 
	}

	createOrganization = async() =>{
		if (this.validator.allValid()) {
			 var data = {
				"name": this.state.name, 
			}

			var res = await createNewVendor(data);
		 
			if(res.success){
				toast.success("Successfully Created")
				 this.props.addVendor(res.data.data);
			} 
		  } else {
			this.validator.showMessages();
			// rerender to show messages for the first time
			// you can use the autoForceUpdate option to do this automatically`
			this.forceUpdate();
		  }
	}

	formItemChange = (event) =>{
        var obj = {};
		obj[event.target.name] = event.target.value; 
		this.setState(obj);  
	 }

	 
 
	render(){ 
		return (
			<div>  
  					<div className="row">
                        <div className="col-lg-4">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Create Service Type</h4>
                                    {/* <p className="text-muted mb-0">Small description.</p>  */}
                                </div> 
                                <div className="card-body">
                                    <form>
                                        <div className="mb-3">
                                            <label className="form-label" for="name">Name</label>
                                            <input autoComplete="off"  type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"   onChange={(e)=> this.formItemChange(e)}/>
											<p style={{color:"#f5325c"}}>{this.validator.message('name', this.state.name, 'required')}</p>
										 </div>
                                         
                                        
                                    </form>           
									<button   className="btn btn-primary" onClick={()=> this.createOrganization()}>Submit</button>                                 
                                </div> 
                            </div> 
                        </div> 
                        <div className="col-lg-8">
                            <div className="card">
							<div className="card-header">
                                    <h4 className="card-title">Service Types</h4>
                                    {/* <p className="text-muted mb-0">
									Small description
                                    </p> */}
                                </div> 
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-striped mb-0">
                                            <thead>
                                            <tr>
                                                <th> Name</th>
                                                {/* <th>Code</th>
												<th> vatNumber</th>
                                                <th>contactNumber</th> 
												<th>contactPerson</th> 
												<th>Email</th> 
												<th>Address</th> 
                                                <th className="text-end">Action</th> */}
                                            </tr>
                                            </thead>
                                            <tbody>
											{ this.props.vendors.map((vendor , index)=>{
												return <tr>
															{/* <td>{vendor.name}</td> */}
															{/* <td>{vendor.code}</td>
															<td>{vendor.vatNumber}</td>
															<td>{vendor.contactNumber}</td>
															<td>{vendor.contactPerson}</td>
															<td>{vendor.contactEmail}</td>
															<td>{vendor.contactAddress}</td> */}
															{/* <td className="text-end">                                                       
																<a href="#"><i className="las la-pen text-secondary font-16"></i></a>
																<a href="#"><i className="las la-trash-alt text-secondary font-16"></i></a>
															</td> */}
														</tr>
											  		})
                                            }
                                            
                                             </tbody>
                                        </table> 
                                    </div> 
                                </div>
                            </div> 
                        </div> 
                    </div> 
					<ToastContainer position="top-right" autoClose={5000}/>
			</div>
		);
	}
}


function mapStateToProps(state) { 
	//console.log(state)
	return {
		vendors: state.vendor ,
		
	};
  }
  
  function mapDispatchToProps(dispatch) {
	return {
		addVendor : data => dispatch(addVendor(data))
	};
  }
  
export default connect(
	mapStateToProps,mapDispatchToProps 
  )(ServiceTypes);  

