import React, { Component } from 'react';
import { Table, InputNumber, Input, Tag, Space, Select, Form, DatePicker, message, Switch, Drawer, Popconfirm, Collapse } from "antd";
import { getGeneratorMasterData, Creategenerator, getGeneratorDetails, getGeneratorBySiteId, Updategenerator, getBulckTankBySiteId, dataUpdateGeneralDetails } from "../../api/index";
import SimpleReactValidator from "simple-react-validator";
import moment from 'moment';

import AssetDelete from "./asset_delete";
import { checkServiceType } from "../../actions/service-type";


class GeneratorDetails extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();

        this.state = {
            open: false,
            openEdit: false,
            openGenDetails: false,
            selectedGenerator: "",
            title: "",
            assetsList: null,
            modelList: null,
            categoriesList: null,
            tankTypesList: null,
            showModal: false,
            generatorIsActive: null,
            genName: '',
            genDescription: '',
            genCapacity: '',
            genStatus: '',
            genMaxFillingLimit: '',
            selectedGenBulckTankId: null,
            GenBulckTanktype: [],
            selectedGeneratorAssetId: null,
            generatorAssetType: [],
            selectedGeneratormodelId: null,
            generatorModelId: [],
            selectedGeneratorMakeId: null,
            generatorMakeId: [],
            selectedGeneratorCategory: null,
            generatorCategoryType: [],
            WarrantyExpiredDate: null,
            SAPSerialNumber: '',
            engineSerialNumber: '',
            alternaterSerialNumber: '',
            controllerSerialNumber: '',
            anyOtherSerialNumber: '',
            materialCodeOfTheGenerator: '',
            siteGeneratorList: null,
            selectGenId: '',
            Alldataview: null,
            bulckTankList: [],
            actionView: false,
            genEdit: null,
            AssetManagementPermission: false,


            phase1Load: '',
            phase2Load: '',
            phase3Load: '',
            loadingWithBatteryCharging: '',
            loadingWithoutBatteryCharging: '',
            actualFuelConsumptionRate: '',
            availabilityOfGenCanapy: null,
            genCanapyStatus: '',
            soundProofStatus: '',
            delayTimerStatus: '',
            generatorWormUpDelayTime: '',
            rmsintegratedStatus: '',
            rms: '',
            rmsconnectionMethod: '',
            rmsrelatedSIMNumber: '',
            rmsInbandOutband: '',
            installedDate: null,
            lastServicedDate: null,
            generatorControllerModel: '',
            availableFuelQty: '',
            generatorCoolDownDelayTime: '',
            engineModel: '',
            genAverageRunningHourspermonth: '',
            engineModel: '',
            engineControllerModel: '',
            avrModel: '',
            fuelTankCapacity: '',
            hourMeterReading: '',

            switchStatus: true,
            objectSet: {
                "genTicketNumberOfPendingFaultyTicket": 0,
            },

            generatorDropDownObject: {
                "generatorControllerModel": "",
                "avrModel": "",
                "fuelTankCapacity": "",
                "availabilityOfGenCanapy": "",
                "genCanapyStatus": "",
                "soundProofStatus": "",
                "delayTimerStatus": "",
                "rmsintegratedStatus": "",
                "rmsInbandOutband": "",
                "rmsConnectionMethod": "",
            }
        };
        this.requestFromClose = this.requestFromClose.bind(this);
        this.grnView = this.grnView.bind(this);
        this.requestFromClose2 = this.requestFromClose2.bind(this);
        this.requestFromClose3 = this.requestFromClose3.bind(this);
        this.handleGenName = this.handleGenName.bind(this);
        this.handleGenDescription = this.handleGenDescription.bind(this);
        this.handleMaxFillingLimit = this.handleMaxFillingLimit.bind(this);
        this.handleGenCapacity = this.handleGenCapacity.bind(this);
        this.handleGenStatus = this.handleGenStatus.bind(this);
        this.handleGenSAPSerialNumber = this.handleGenSAPSerialNumber.bind(this);
        this.handleEngineSerialNumber = this.handleEngineSerialNumber.bind(this);
        this.handleAlternaterSerialNumber = this.handleAlternaterSerialNumber.bind(this);
        this.handleControllerSerialNumber = this.handleControllerSerialNumber.bind(this);
        this.handleAnyOtherSerialNumber = this.handleAnyOtherSerialNumber.bind(this);
        this.handleMaterialCodeOfTheGenerator = this.handleMaterialCodeOfTheGenerator.bind(this);


        this.handlephase1Load = this.handlephase1Load.bind(this);
        this.handlephase2Load = this.handlephase2Load.bind(this);
        this.handlephase3Load = this.handlephase3Load.bind(this);
        this.handleloadingWithBatteryCharging = this.handleloadingWithBatteryCharging.bind(this);
        this.handleloadingWithoutBatteryCharging = this.handleloadingWithoutBatteryCharging.bind(this);
        this.handleactualFuelConsumptionRate = this.handleactualFuelConsumptionRate.bind(this);
        this.handlegenCanapyStatus = this.handlegenCanapyStatus.bind(this);
        this.handlesoundProofStatus = this.handlesoundProofStatus.bind(this);
        this.handlDelayTimerStatus = this.handlDelayTimerStatus.bind(this);
        this.handlegeneratorWormUpDelayTime = this.handlegeneratorWormUpDelayTime.bind(this);
        this.handlermsintegratedStatus = this.handlermsintegratedStatus.bind(this);
        this.handlerMS = this.handlerMS.bind(this);
        this.handlermsconnectionMethod = this.handlermsconnectionMethod.bind(this);
        this.handlerMSRelatedSIMNumber = this.handlerMSRelatedSIMNumber.bind(this);
        this.handlerMsInbandOutband = this.handlerMsInbandOutband.bind(this);
        this.handlegeneratorControllerModel = this.handlegeneratorControllerModel.bind(this);
        this.handleavailableFuelQty = this.handleavailableFuelQty.bind(this);
        this.handlefuelTankCapacity = this.handlefuelTankCapacity.bind(this);
        this.handlehourMeterReading = this.handlehourMeterReading.bind(this);
        this.handlegeneratorCoolDownDelayTime = this.handlegeneratorCoolDownDelayTime.bind(this);
        this.handlegeneratorEngineModel = this.handlegeneratorEngineModel.bind(this);
        this.handlegeneratorAVRModel = this.handlegeneratorAVRModel.bind(this);
        this.handlAverageRunningHourspermonth = this.handlAverageRunningHourspermonth.bind(this);
        this.handleengineModel = this.handleengineModel.bind(this);
        this.handleengineControllerModel = this.handleengineControllerModel.bind(this);
        // this.handleavrModel = this.handleavrModel.bind(this);


    }

    handlephase1Load(e) {
        //console.log(e)
        this.setState({ phase1Load: e });
    }
    handlephase2Load(e) {
        //console.log(e)
        this.setState({ phase2Load: e });
    }
    handlephase3Load(e) {
        //console.log(e)
        this.setState({ phase3Load: e });
    }
    handleloadingWithBatteryCharging(e) {
        //console.log(e)
        this.setState({ loadingWithBatteryCharging: e });
    }
    handleloadingWithoutBatteryCharging(e) {
        //console.log(e)
        this.setState({ loadingWithoutBatteryCharging: e });
    }
    handleactualFuelConsumptionRate(e) {
        //console.log(e)
        this.setState({ actualFuelConsumptionRate: e });
    }

    onChangeAvailabilityOfGenCanapy = (e) => {
        //console.log(e.target.value)
        this.setState({ availabilityOfGenCanapy: e });
    }
    handlegenCanapyStatus(e) {
        //console.log(e.target.value)
        this.setState({ genCanapyStatus: e });
    }
    handlDelayTimerStatus(e) {
        //console.log(e.target.value)
        this.setState({ delayTimerStatus: e });
    }
    handlesoundProofStatus(e) {
        //console.log(e.target.value)
        this.setState({ soundProofStatus: e });
    }
    handlegeneratorWormUpDelayTime(e) {
        //console.log(e)
        this.setState({ generatorWormUpDelayTime: e });
    }
    handlermsintegratedStatus(e) {
        //console.log(e)
        this.setState({ rmsintegratedStatus: e });
    }
    handlerMS(e) {
        //console.log(e)
        this.setState({ rms: e.target.value });
    }
    handlermsconnectionMethod(e) {
        //console.log(e)
        this.setState({ rmsconnectionMethod: e });
    }
    handlerMSRelatedSIMNumber(e) {
        //console.log(e)
        this.setState({ rmsrelatedSIMNumber: e.target.value });
    }
    handlerMsInbandOutband(e) {
        //console.log(e)
        this.setState({ rmsInbandOutband: e });
    }
    onChangeLastServicedDate = (date, dateString) => {
        this.setState({ lastServicedDate: dateString });
    }
    onChangeInstalledDate = (date, dateString) => {
        this.setState({ installedDate: dateString });
    }
    handlegeneratorControllerModel(e) {
        //console.log(e)
        this.setState({ generatorControllerModel: e });
    }
    handleengineControllerModel(e) {
        this.setState({ engineControllerModel: e });
    }
    handlehourMeterReading(e) {
        //console.log(e)
        this.setState({ hourMeterReading: e });
    }
    handlefuelTankCapacity(e) {
        //console.log(e)
        this.setState({ fuelTankCapacity: e });
    }
    handleavailableFuelQty(e) {
        //console.log(e)
        this.setState({ availableFuelQty: e });
    }
    handlAverageRunningHourspermonth(e) {
        //console.log(e)
        this.setState({ genAverageRunningHourspermonth: e });
    }

    handlegeneratorAVRModel(e) {
        this.setState({ avrModel: e });
    }

    handlegeneratorEngineModel(e) {
        //console.log(e)
        this.setState({ engineModel: e });
    }
    handlegeneratorCoolDownDelayTime(e) {
        //console.log(e)
        this.setState({ generatorCoolDownDelayTime: e });
    }
    handleengineModel(e) {
        //console.log(e)
        this.setState({ engineModel: e.target.value });
    }

    grnView(id) {
        this.getGeneratorDataView(id);
        // this.setState({ showModal1: true, });
    }
    grnUpdata(id) {
        this.getDataBulckTankBySiteId()
        this.getGeneratorData(id);
        this.setState({ showModal1: true, openEdit: true });
    }
    viewMoreDetails(gen) {
        this.setState({
            showModal2: true,
            openGenDetails: true,
            selectedGenerator: gen
        });
    }
    requestFromClose() {
        this.setState({ showModal: true, open: true });
    }
    handleGenCapacity(e) {
        //console.log(e)
        this.setState({ genCapacity: e });
    }
    handleMaxFillingLimit(e) {
        //console.log(e)
        this.setState({ genMaxFillingLimit: e });
    }
    handleAlternaterSerialNumber(e) {
        //console.log(e.target.value)
        this.setState({ alternaterSerialNumber: e.target.value });
    }
    handleMaterialCodeOfTheGenerator(e) {
        //console.log(e.target.value)
        this.setState({ materialCodeOfTheGenerator: e.target.value });
    }
    handleAnyOtherSerialNumber(e) {
        //console.log(e.target.value)
        this.setState({ anyOtherSerialNumber: e.target.value });
    }
    handleControllerSerialNumber(e) {
        //console.log(e.target.value)
        this.setState({ controllerSerialNumber: e.target.value });
    }
    handleEngineSerialNumber(e) {
        //console.log(e.target.value)
        this.setState({ engineSerialNumber: e.target.value });
    }
    handleGenSAPSerialNumber(e) {
        //console.log(e.target.value)
        this.setState({ SAPSerialNumber: e.target.value });
    }
    handleGenStatus(e) {
        //console.log(e.target.value)
        this.setState({ genStatus: e.target.value });
    }
    handleGenDescription(e) {
        //console.log(e.target.value)
        this.setState({ genDescription: e.target.value });
    }
    handleGenName(e) {
        //console.log(e.target.value)
        this.setState({ genName: e.target.value });
    }
    requestFromClose3() {
        this.setState({ showModal1: false, });
    }
    requestFromClose2() {
        this.setState({ showModal: false, });
    }
    componentDidMount() {
        this.getDataGeneratorMasterData();
        this.getDataGeneratorBySiteId();

        if (JSON.parse(localStorage.getItem('serviceTypeSet')) == null) {
            const { history } = this.props;
            history.push("/");
        } else {
            this.getSetPageData(JSON.parse(localStorage.getItem('serviceTypeSet')), 38)
        }
        checkServiceType(38).then((data) => this.setState({
            AssetManagementPermission: data
        }, () => {
        }),
        )
        this.setState({
            objectSet: {
                "genTicketNumberOfPendingFaultyTicket": this.props.details1?.access_technologies_data?.genTicketNumberOfPendingFaultyTicket,
            }
        })
        this.getDataBulckTankBySiteId();
    }

    getSetPageData = (serviceTypeIs, Pageid) => {
        if (serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == Pageid)[0] != null) {
            console.log('fddf');
            this.setState({
                actionView: true,
            })
        }
    };
    getGeneratorDataView = async (id) => {
        var res = await getGeneratorDetails(id);
        if (res.success) {

            this.setState({
                Alldataview: res.data
            })


        }
    }
    getDataBulckTankBySiteId = async () => {
        var dataset = {
            'id': this.props.details.site_details.id
        }
        var res = await getBulckTankBySiteId(dataset);
        console.log("getBulckTankBySiteId");
        console.log(res);
        if (res.success) {
            this.setState({
                bulckTankList: res.data


            }, () => {
                console.log(this.state.bulckTankList)
            })
        }
    };
    getGeneratorData = async (id) => {
        var res = await getGeneratorDetails(id);
        if (res.success) {
            this.setState({
                genEdit: res?.data

            })
            console.log("getGeneratorData");
            console.log(res);
            this.setState({
                selectGenId: res.data?.id,
                genName: res.data?.name,
                // generatorAssetType: res.data?.asset,
                // selectedGeneratorAssetId: res.data?.asset.id,
                selectedGeneratormodelId: res.data?.model.id,
                generatorModelId: res.data?.model,
                selectedGeneratorCategory: res.data?.category.id,
                generatorCategoryType: res.data?.category,
                selectedGeneratorTypeId: res.data?.type.id,
                generatorType: res.data?.type,
                // selectedGenBulckTankId: res.data?.genBulckTank?.id,
                // GenBulckTanktype: res.data?.genBulckTank,
                genMaxFillingLimit: res.data?.maxFillingLimit,
                genCapacity: res.data?.capacity,
                genDescription: res.data?.description,
                materialCodeOfTheGenerator: res.data?.model?.materialCodeOfTheGenerator,
                SAPSerialNumber: res.data?.generatorSAPSerialNumber,
                engineSerialNumber: res.data?.engineSerialNumber,
                alternaterSerialNumber: res.data?.alternaterSerialNumber,
                controllerSerialNumber: res.data?.controllerSerialNumber,
                anyOtherSerialNumber: res.data?.anyOtherSerialNumber,

                phase1Load: res.data?.phase1Load,
                phase2Load: res.data?.phase2Load,
                phase3Load: res.data?.phase3Load,
                loadingWithBatteryCharging: res.data?.loadingWithBatteryCharging,
                loadingWithoutBatteryCharging: res.data?.loadingWithoutBatteryCharging,
                actualFuelConsumptionRate: res.data?.actualFuelConsumptionRate,
                availabilityOfGenCanapy: res.data?.availabilityOfGenCanapy,
                genCanapyStatus: res.data?.genCanapyStatus,
                soundProofStatus: res.data?.soundProofStatus,
                delayTimerStatus: res.data?.delayTimerStatus,
                generatorWormUpDelayTime: res.data?.generatorWormUpDelayTime,
                rmsintegratedStatus: res.data?.rmsintegratedStatus,
                rms: res.data?.rms,
                rmsconnectionMethod: res.data?.rmsconnectionMethod,
                rmsrelatedSIMNumber: res.data?.rmsrelatedSIMNumber,
                rmsInbandOutband: res.data?.rmsInbandOutband,

                generatorControllerModel: res.data?.generatorControllerModel,
                availableFuelQty: res.data?.availableFuelQty,
                fuelTankCapacity: res.data?.fuelTankCapacity,
                hourMeterReading: res.data?.hourMeterReading,
                generatorCoolDownDelayTime: res.data?.generatorCoolDownDelayTime,
                engineModel: res.data?.engineModel,
                genAverageRunningHourspermonth: res.data?.averageRunningHoursPerMonth,
                // engineModel: res.data?.engineModel,
                engineControllerModel: res.data?.engineControllerModel,
                avrModel: res.data?.avrModel



            })
            if (res.data?.genBulckTank != null) {
                this.setState({
                    selectedGenBulckTankId: res.data?.genBulckTank?.id,
                    GenBulckTanktype: res.data?.genBulckTank,
                })

            } else {
                this.setState({
                    selectedGenBulckTankId: null,
                    GenBulckTanktype: [],
                })
            }
            if (res.data?.make != null) {
                this.setState({
                    selectedGeneratorMakeId: res.data?.make?.id,
                    generatorMakeId: res.data?.make,
                })

            } else {
                this.setState({
                    selectedGeneratorMakeId: null,
                    generatorMakeId: [],
                })
            }
            if (res.data?.active == false) {
                this.setState({
                    generatorIsActive: 0
                })

            } else {
                this.setState({
                    generatorIsActive: 1
                })
            }
            if (res.data?.lastServicedDate != null) {
                //  //console.log("on Air fuck")
                this.setState({
                    lastServicedDate: res.data?.lastServicedDate
                })
            } else {
                this.setState({
                    lastServicedDate: '',
                })
            }

            if (res.data?.installedDate != null) {
                //  //console.log("on Air fuck")
                this.setState({
                    installedDate: res.data?.installedDate
                })
            } else {
                this.setState({
                    installedDate: '',
                })
            }
            if (res.data?.warrantyExpiredDate != null) {
                //  //console.log("on Air fuck")
                this.setState({
                    WarrantyExpiredDate: res.data?.warrantyExpiredDate
                })
            } else {
                this.setState({
                    WarrantyExpiredDate: '',
                })
            }
        }
    }
    getDataGeneratorBySiteId = async () => {
        var dataset = {
            'id': this.props.details.site_details.id
        }
        var res = await getGeneratorBySiteId(dataset);

        if (res.success) {
            this.setState({
                siteGeneratorList: res.data


            })
        }
    };

    getDataGeneratorMasterData = async () => {
        var res = await getGeneratorMasterData();
        console.log("getGeneratorMasterData");
        console.log(res.data);
        if (res.success) {
            this.setState({
                assetsList: res.data.assets,
                modelList: res.data.models,
                categoriesList: res.data.categories,
                tankTypesList: res.data.tankTypes,


            })
        }
    };
    onChangeWarrantyExpiredDate = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);
        this.setState({
            WarrantyExpiredDate: dateString,
        });
    };
    onChangeGenBulckTank = (value) => {
        //console.log("onChangeGenBulckTank");
        //console.log(value);
        this.setState({
            selectedGenBulckTankId: value,
            GenBulckTanktype: {
                "id": value
            },

        })
    };
    onChangeGeneratorType = (value) => {
        this.getDataBulckTankBySiteId();
        //console.log("onChangeGeneratorType");
        //console.log(value);
        //console.log('dddddddd1')

        if (value == 1) {
            //console.log('dddddddd2')
            this.setState({
                selectedGenBulckTankId: null,
                GenBulckTanktype: [],
            })
        }
        this.setState({
            selectedGeneratorTypeId: value,
            generatorType: {
                "id": value
            },

        })
    };
    onChangeGeneratorCategory = (value) => {
        //console.log("onChangeGeneratorCategory");
        //console.log(value);
        this.setState({
            selectedGeneratorCategory: value,
            generatorCategoryType: {
                "id": value
            },

        })
    };
    onChangeGeneratorMake = (value) => {
        //console.log("onChangeGeneratormodel");
        //console.log(value);
        this.setState({
            selectedGeneratorMakeId: value,
            generatorMakeId: {
                "id": value
            },

        })
    };
    onChangeGeneratormodel = (value) => {
        //console.log("onChangeGeneratormodel");
        //console.log(value);
        this.setState({
            selectedGeneratormodelId: value,
            generatorModelId: {
                "id": value
            },

        })
    };
    onChangeGeneratorAsset = (value) => {
        //console.log("onChangeGeneratorAsset");
        //console.log(value);
        this.setState({
            selectedGeneratorAssetId: value,
            generatorAssetType: {
                "id": value
            },

        })
    };
    onChangeGeneratorIsActive = (value) => {
        //console.log("onChangeGeneratorIsActive");
        //console.log(value);
        this.setState({
            generatorIsActive: parseInt(value),


        })
    };
    AllDataSubmitToUpdate = async () => {
        if (this.state.selectedGenBulckTankId != null) {

            if (this.validator.allValid()) {
                var dataSet;
                dataSet = {
                    "id": this.state.selectGenId,
                    "name": this.state.genName,
                    "description": this.state.genDescription,
                    "active": this.state.generatorIsActive,
                    "status": this.state.genStatus,
                    "maxFillingLimit": this.state.genMaxFillingLimit,
                    "capacity": this.state.genCapacity,
                    "warrantyExpiredDate": this.state.WarrantyExpiredDate,
                    "generatorSAPSerialNumber": this.state.SAPSerialNumber,
                    "engineSerialNumber": this.state.engineSerialNumber,
                    "alternaterSerialNumber": this.state.alternaterSerialNumber,
                    "controllerSerialNumber": this.state.controllerSerialNumber,
                    // "anyOtherSerialNumber": this.state.anyOtherSerialNumber,
                    // "materialCodeOfTheGenerator": this.state.materialCodeOfTheGenerator,
                    "site": { "id": this.props.details.site_details.id },
                    "model": this.state.generatorModelId,
                    "make": this.state.generatorMakeId,
                    "type": this.state.generatorType,
                    "category": this.state.generatorCategoryType,
                    "asset": {
                        "id": 2
                    },
                    "genBulckTank": this.state.GenBulckTanktype,

                    "phase1Load": this.state.phase1Load,
                    "phase2Load": this.state.phase2Load,
                    "phase3Load": this.state.phase3Load,
                    "loadingWithBatteryCharging": this.state.loadingWithBatteryCharging,
                    "loadingWithoutBatteryCharging": this.state.loadingWithoutBatteryCharging,
                    "actualFuelConsumptionRate": this.state.actualFuelConsumptionRate,
                    "availabilityOfGenCanapy": this.state.availabilityOfGenCanapy,
                    "genCanapyStatus": this.state.genCanapyStatus,
                    "soundProofStatus": this.state.soundProofStatus,
                    "delayTimerStatus": this.state.delayTimerStatus,
                    "generatorWormUpDelayTime": this.state.generatorWormUpDelayTime,
                    "rmsintegratedStatus": this.state.rmsintegratedStatus,
                    "rms": this.state.rms,
                    "rmsconnectionMethod": this.state.rmsconnectionMethod,
                    "rmsrelatedSIMNumber": this.state.rmsrelatedSIMNumber,
                    "rmsInbandOutband": this.state.rmsInbandOutband,
                    "installedDate": this.state.installedDate,
                    "lastServicedDate": this.state.lastServicedDate,
                    "generatorControllerModel": this.state.generatorControllerModel,
                    "availableFuelQty": this.state.availableFuelQty,
                    "fuelTankCapacity": this.state.fuelTankCapacity,
                    "hourMeterReading": this.state.hourMeterReading,
                    "generatorCoolDownDelayTime": this.state.generatorCoolDownDelayTime,
                    "engineModel": this.state.engineModel,
                    "averageRunningHoursPerMonth": this.state.genAverageRunningHourspermonth,
                    // "engineModel":this.state.engineModel,
                    "engineControllerModel": this.state.engineControllerModel,
                    "avrModel": this.state.avrModel

                }
                //console.log(dataSet)
                var res = await Updategenerator(dataSet);
                if (res.success) {
                    message.success('Successfully Generator Edited!')
                    this.formRef.current.resetFields();
                    this.getDataGeneratorBySiteId();
                    this.props.parentMethod();

                    this.setState({
                        showModal1: false,
                        openEdit: false
                    })
                    // const { history } = this.props;
                    // history.push("/site");
                } else {
                    message.warning(' Something went wrong!')
                }

            } else {
                this.validator.showMessages();
                // rerender to show messages for the first time
                // you can use the autoForceUpdate option to do this automatically`
                this.forceUpdate();
            }
        } else {
            if (this.validator.allValid()) {
                var dataSet;
                dataSet = {
                    "id": this.state.selectGenId,
                    "name": this.state.genName,
                    "description": this.state.genDescription,
                    "active": this.state.generatorIsActive,
                    "status": this.state.genStatus,
                    "maxFillingLimit": this.state.genMaxFillingLimit,
                    "capacity": this.state.genCapacity,
                    "warrantyExpiredDate": this.state.WarrantyExpiredDate,
                    "generatorSAPSerialNumber": this.state.SAPSerialNumber,
                    "engineSerialNumber": this.state.engineSerialNumber,
                    "alternaterSerialNumber": this.state.alternaterSerialNumber,
                    "controllerSerialNumber": this.state.controllerSerialNumber,
                    // "anyOtherSerialNumber": this.state.anyOtherSerialNumber,
                    //   "materialCodeOfTheGenerator": this.state.materialCodeOfTheGenerator,
                    "site": { "id": this.props.details.site_details.id },
                    "model": this.state.generatorModelId,
                    "make": this.state.generatorMakeId,
                    "type": this.state.generatorType,
                    "category": this.state.generatorCategoryType,
                    "asset": {
                        "id": 2
                    },
                    // "genBulckTank": this.state.GenBulckTanktype

                    "phase1Load": this.state.phase1Load,
                    "phase2Load": this.state.phase2Load,
                    "phase3Load": this.state.phase3Load,
                    "loadingWithBatteryCharging": this.state.loadingWithBatteryCharging,
                    "loadingWithoutBatteryCharging": this.state.loadingWithoutBatteryCharging,
                    "actualFuelConsumptionRate": this.state.actualFuelConsumptionRate,
                    "availabilityOfGenCanapy": this.state.availabilityOfGenCanapy,
                    "genCanapyStatus": this.state.genCanapyStatus,
                    "soundProofStatus": this.state.soundProofStatus,
                    "delayTimerStatus": this.state.delayTimerStatus,
                    "generatorWormUpDelayTime": this.state.generatorWormUpDelayTime,
                    "rmsintegratedStatus": this.state.rmsintegratedStatus,
                    "rms": this.state.rms,
                    "rmsconnectionMethod": this.state.rmsconnectionMethod,
                    "rmsrelatedSIMNumber": this.state.rmsrelatedSIMNumber,
                    "rmsInbandOutband": this.state.rmsInbandOutband,
                    "installedDate": this.state.installedDate,
                    "lastServicedDate": this.state.lastServicedDate,

                    "generatorControllerModel": this.state.generatorControllerModel,
                    "availableFuelQty": this.state.availableFuelQty,
                    "fuelTankCapacity": this.state.fuelTankCapacity,
                    "hourMeterReading": this.state.hourMeterReading,

                    "generatorCoolDownDelayTime": this.state.generatorCoolDownDelayTime,
                    "engineModel": this.state.engineModel,

                    "averageRunningHoursPerMonth": this.state.genAverageRunningHourspermonth,
                    // "engineModel":this.state.engineModel,
                    "engineControllerModel": this.state.engineControllerModel,
                    "avrModel": this.state.avrModel

                }
                //console.log(dataSet)
                var res = await Updategenerator(dataSet);
                if (res.success) {
                    message.success('Successfully Generator Edited!')
                    this.formRef.current.resetFields();
                    this.getDataGeneratorBySiteId();
                    this.props.parentMethod();

                    this.setState({
                        showModal1: false,
                        openEdit: false

                    })
                    // const { history } = this.props;
                    // history.push("/site");
                } else {
                    message.warning(' Something went wrong!')
                }

            } else {
                this.validator.showMessages();
                // rerender to show messages for the first time
                // you can use the autoForceUpdate option to do this automatically`
                this.forceUpdate();
            }

        }

    }
    AllDataSubmit = async () => {
        if (this.state.selectedGenBulckTankId != null) {
            if (this.validator.allValid()) {
                var dataSet;
                dataSet = {

                    "name": this.state.genName,
                    "description": this.state.genDescription,
                    "active": this.state.generatorIsActive,
                    "status": this.state.genStatus,
                    "maxFillingLimit": this.state.genMaxFillingLimit,
                    "capacity": this.state.genCapacity,
                    "warrantyExpiredDate": this.state.WarrantyExpiredDate,
                    "generatorSAPSerialNumber": this.state.SAPSerialNumber,
                    "engineSerialNumber": this.state.engineSerialNumber,
                    "alternaterSerialNumber": this.state.alternaterSerialNumber,
                    "controllerSerialNumber": this.state.controllerSerialNumber,
                    // "anyOtherSerialNumber": this.state.anyOtherSerialNumber,
                    //"materialCodeOfTheGenerator": this.state.materialCodeOfTheGenerator,
                    "site": { "id": this.props.details.site_details.id },
                    "model": this.state.generatorModelId,
                    "make": this.state.generatorMakeId,
                    "type": this.state.generatorType,
                    "category": this.state.generatorCategoryType,
                    "asset": {
                        "id": 2
                    },
                    "genBulckTank": this.state.GenBulckTanktype,

                    "phase1Load": this.state.phase1Load,
                    "phase2Load": this.state.phase2Load,
                    "phase3Load": this.state.phase3Load,
                    "loadingWithBatteryCharging": this.state.loadingWithBatteryCharging,
                    "loadingWithoutBatteryCharging": this.state.loadingWithoutBatteryCharging,
                    "actualFuelConsumptionRate": this.state.actualFuelConsumptionRate,
                    "availabilityOfGenCanapy": this.state.availabilityOfGenCanapy,
                    "genCanapyStatus": this.state.genCanapyStatus,
                    "soundProofStatus": this.state.soundProofStatus,
                    "delayTimerStatus": this.state.delayTimerStatus,
                    "generatorWormUpDelayTime": this.state.generatorWormUpDelayTime,
                    "rmsintegratedStatus": this.state.rmsintegratedStatus,
                    "rms": this.state.rms,
                    "rmsconnectionMethod": this.state.rmsconnectionMethod,
                    "rmsrelatedSIMNumber": this.state.rmsrelatedSIMNumber,
                    "rmsInbandOutband": this.state.rmsInbandOutband,
                    "installedDate": this.state.installedDate,
                    "lastServicedDate": this.state.lastServicedDate,

                    "generatorControllerModel": this.state.generatorControllerModel,
                    "availableFuelQty": this.state.availableFuelQty,
                    "fuelTankCapacity": this.state.fuelTankCapacity,
                    "hourMeterReading": this.state.hourMeterReading,

                    "generatorCoolDownDelayTime": this.state.generatorCoolDownDelayTime,
                    "engineModel": this.state.engineModel,

                    "averageRunningHoursPerMonth": this.state.genAverageRunningHourspermonth,
                    // "engineModel":this.state.engineModel,
                    "engineControllerModel": this.state.engineControllerModel,
                    "avrModel": this.state.avrModel

                }
                console.log(dataSet)
                var res = await Creategenerator(dataSet);
                if (res.success) {
                    message.success('Successfully Generator Added!')
                    this.formRef.current.resetFields();
                    this.getDataGeneratorBySiteId();
                    this.setState({
                        showModal: false,
                        open: false
                    })
                    this.props.parentMethod();
                    // const { history } = this.props;
                    // history.push("/site");
                } else {
                    message.warning(' Something went wrong!')
                }

            } else {
                this.validator.showMessages();
                //console.log( this.validator)
                // rerender to show messages for the first time
                // you can use the autoForceUpdate option to do this automatically`
                this.forceUpdate();
            }
        } else {
            if (this.validator.allValid()) {
                var dataSet;
                dataSet = {

                    "name": this.state.genName,
                    "description": this.state.genDescription,
                    "active": this.state.generatorIsActive,
                    "status": this.state.genStatus,
                    "maxFillingLimit": this.state.genMaxFillingLimit,
                    "capacity": this.state.genCapacity,
                    "warrantyExpiredDate": this.state.WarrantyExpiredDate,
                    "generatorSAPSerialNumber": this.state.SAPSerialNumber,
                    "engineSerialNumber": this.state.engineSerialNumber,
                    "alternaterSerialNumber": this.state.alternaterSerialNumber,
                    "controllerSerialNumber": this.state.controllerSerialNumber,
                    // "anyOtherSerialNumber": this.state.anyOtherSerialNumber,
                    // "materialCodeOfTheGenerator": this.state.materialCodeOfTheGenerator,
                    "site": { "id": this.props.details.site_details.id },
                    "model": this.state.generatorModelId,
                    "make": this.state.generatorMakeId,
                    "type": this.state.generatorType,
                    "category": this.state.generatorCategoryType,
                    "asset": {
                        "id": 2
                    },
                    //"genBulckTank": this.state.GenBulckTanktype

                    "phase1Load": this.state.phase1Load,
                    "phase2Load": this.state.phase2Load,
                    "phase3Load": this.state.phase3Load,
                    "loadingWithBatteryCharging": this.state.loadingWithBatteryCharging,
                    "loadingWithoutBatteryCharging": this.state.loadingWithoutBatteryCharging,
                    "actualFuelConsumptionRate": this.state.actualFuelConsumptionRate,
                    "availabilityOfGenCanapy": this.state.availabilityOfGenCanapy,
                    "genCanapyStatus": this.state.genCanapyStatus,
                    "soundProofStatus": this.state.soundProofStatus,
                    "delayTimerStatus": this.state.delayTimerStatus,
                    "generatorWormUpDelayTime": this.state.generatorWormUpDelayTime,
                    "rmsintegratedStatus": this.state.rmsintegratedStatus,
                    "rms": this.state.rms,
                    "rmsconnectionMethod": this.state.rmsconnectionMethod,
                    "rmsrelatedSIMNumber": this.state.rmsrelatedSIMNumber,
                    "rmsInbandOutband": this.state.rmsInbandOutband,
                    "installedDate": this.state.installedDate,
                    "lastServicedDate": this.state.lastServicedDate,

                    "generatorControllerModel": this.state.generatorControllerModel,
                    "availableFuelQty": this.state.availableFuelQty,
                    "fuelTankCapacity": this.state.fuelTankCapacity,
                    "hourMeterReading": this.state.hourMeterReading,

                    "generatorCoolDownDelayTime": this.state.generatorCoolDownDelayTime,
                    "engineModel": this.state.engineModel,

                    "averageRunningHoursPerMonth": this.state.genAverageRunningHourspermonth,
                    // "engineModel":this.state.engineModel,
                    "engineControllerModel": this.state.engineControllerModel,
                    "avrModel": this.state.avrModel

                }
                console.log(dataSet)
                var res = await Creategenerator(dataSet);
                if (res.success) {
                    message.success('Successfully Generator Added!');
                    this.formRef.current.resetFields();
                    this.getDataGeneratorBySiteId();
                    this.props.parentMethod();

                    this.setState({
                        showModal: false,
                        open: false
                    })
                    // const { history } = this.props;
                    // history.push("/site");
                } else {
                    message.warning(' Something went wrong!')
                }

            } else {
                this.validator.showMessages();
                //console.log( this.validator)
                // rerender to show messages for the first time
                // you can use the autoForceUpdate option to do this automatically`
                this.forceUpdate();
            }

        }

    }
    onDrawerCloseEdit = () => {
        console.log('jhj')
        this.formRef.current.resetFields();
        this.validator.hideMessages();
        this.setState({


            openEdit: false,
            selectGenId: '',
            genName: '',
            generatorAssetType: [],
            selectedGeneratorAssetId: null,
            selectedGeneratormodelId: null,
            generatorModelId: [],
            selectedGeneratorMakeId: null,
            generatorMakeId: [],
            selectedGeneratorCategory: null,
            generatorCategoryType: [],
            selectedGeneratorTypeId: null,
            generatorType: '',
            selectedGenBulckTankId: null,
            GenBulckTanktype: [],
            genMaxFillingLimit: '',
            genCapacity: '',
            genDescription: '',
            materialCodeOfTheGenerator: '',
            SAPSerialNumber: '',
            engineSerialNumber: '',
            alternaterSerialNumber: '',
            controllerSerialNumber: '',
            anyOtherSerialNumber: '',
            WarrantyExpiredDate: null,

            phase1Load: '',
            phase2Load: '',
            phase3Load: '',
            loadingWithBatteryCharging: '',
            loadingWithoutBatteryCharging: '',
            actualFuelConsumptionRate: '',
            availabilityOfGenCanapy: null,
            genCanapyStatus: '',
            soundProofStatus: '',
            delayTimerStatus: '',
            generatorWormUpDelayTime: '',
            rmsintegratedStatus: '',
            rms: '',
            rmsconnectionMethod: '',
            rmsrelatedSIMNumber: '',
            rmsInbandOutband: '',
            installedDate: '',
            lastServicedDate: "",

            generatorControllerModel: '',
            availableFuelQty: '',
            fuelTankCapacity: '',
            hourMeterReading: '',

            generatorCoolDownDelayTime: '',


            engineModel: '',
            engineControllerModel: '',
            avrModel: ''


        })
    }
    onDrawerClose = () => {
        console.log('sddsds')
        this.formRef.current.resetFields();
        this.validator.hideMessages();
        this.setState({
            open: false,

        })

    }
    onViewMoreDetailsDrawerClose = () => {
        this.setState({
            showModal2: false,
            openGenDetails: false,

        })

    }
    getDataDetailsForDelete = async (id) => {
        var res = await getGeneratorDetails(id);
        console.log(res);
        if (res.success) {
            var respons = AssetDelete.Action(res.data, this.props.details.site_details.id, Updategenerator)



            respons.then((value) => {
                if (value == 1) {
                    message.success('Successfully Delete!')
                    this.props.parentMethod();
                } else {
                    message.warning(' Something went wrong!')
                }


            });



        }

    }
    submitForm = async (item) => {
        this.getDataDetailsForDelete(item);

    }

    validatedTextLable = (field) => {
        return (field !== null && field !== '')
            ? <span class="badge badge-outline-secondary text-muted">{(field)}</span>
            : <span class="badge text-danger">Currently Not Set</span>;
    }

    validatedBoolLable = (field) => {
        return (field !== null && field !== '')
            ? field == true
                ? <span class="badge badge-outline-success badge-pill"> Yes </span>
                : <span class="badge badge-outline-pink badge-pill"> No </span>
            : <span class="badge text-danger">Currently Not Set</span>;
    }
    handlgenTicketNumberOfPendingFaultyTicket = (e) => {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                genTicketNumberOfPendingFaultyTicket: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })

    }
    dataUpdate() {
        console.log("ssss")
        this.setState({
            switchStatus: false
        })
    }
    close = async () => {
        this.props.parentMethod();
        this.setState({
            objectSet: {
                "genTicketNumberOfPendingFaultyTicket": this.props.details1?.access_technologies_data?.genTicketNumberOfPendingFaultyTicket,


            }
        });
        this.setState({
            switchStatus: true
        });
    }
    dataSetToUpdate = async () => {

        console.log(this.state.objectSet)
        console.log(this.props.detailsupdateGeneralDetails.updateGeneralDetails)
        let remoteJob = {
            ...this.props.detailsupdateGeneralDetails.updateGeneralDetails,
            ...this.state.objectSet
        };
        const formData = new FormData();
        formData.append('id', this.props.details1?.access_technologies_data?.id);
        formData.append('object', JSON.stringify(remoteJob));

        var res = await dataUpdateGeneralDetails(formData);

        if (res.success) {
            message.success('Successfully Update!')
            this.props.parentMethod();
            this.setState({
                switchStatus: true
            })
        } else {
            message.warning(' Something went wrong!')
        }


    }

    render() {
        const { Option } = Select;
        const { Panel } = Collapse;
        const generator = this.state.selectedGenerator;
        console.log("generator_klll")
        console.log(generator)

        const columns2 = [
            {
                title: "Name",
                details: [],
                dataIndex: "name",
                content: <Tag color="blue" style={{ margin: "0px" }}><span>{generator.name}</span></Tag>
            },
            {
                title: "Make",
                details: [],
                dataIndex: "make",
                content: generator !== ""
                    ? <span class="badge bg-secondary">{generator.make?.name}</span>
                    : <></>
            },
            {
                title: "Model",
                details: [
                    {
                        title: "Material Code Of The Generator",
                        details: [],
                        dataIndex: "materialCodeOfTheGenerator",
                        content: this.validatedTextLable(generator.model?.materialCodeOfTheGenerator),
                    },
                ],
                dataIndex: "model",
                content: generator !== "" ? <span class="badge badge-outline-info badge-pill"><i class="fas fa-hdd" style={{ paddingRight: "5px" }} />{(generator.model?.name)}</span> : <></>,
            },
            {
                title: "Capacity(kVA)",
                details: [],
                dataIndex: "capacity",
                content: this.validatedTextLable(generator.capacity),
            },
            {
                title: "Generator SAP Serial Number",
                details: [],
                dataIndex: "generatorSAPSerialNumber",
                content: this.validatedTextLable(generator.generatorSAPSerialNumber)
            },
            {
                title: "Engine Serial Number",
                details: [],
                dataIndex: "engineSerialNumber",
                content: this.validatedTextLable(generator.engineSerialNumber),
            },
            {
                title: "Alternater Serial Number",
                details: [],
                dataIndex: "alternaterSerialNumber",
                content: this.validatedTextLable(generator.alternaterSerialNumber),
            },
            {
                title: "Controller Serial Number",
                details: [],
                dataIndex: "controllerSerialNumber",
                content: this.validatedTextLable(generator.controllerSerialNumber),
            },
            {
                title: "Engine Model",
                details: [],
                dataIndex: "engineModel",
                content: this.validatedTextLable(generator.engineModel),

            },
            // {
            //     title: "Generator Controller Model",
            //     details: [],
            //     dataIndex: "generatorControllerModel",
            //     content: this.validatedTextLable(generator.generatorControllerModel),
            // },
            {
                title: "Engine Controller Model",
                details: [],
                dataIndex: "engineControllerModel",
                content: this.validatedTextLable(generator.engineControllerModel),
            },
            {
                title: "AVR Model ",
                details: [],
                dataIndex: "avrModel",
                content: generator !== ""
                    ? <span class="badge bg-secondary">{generator?.avrModel}</span>
                    : <></>
            },
            {
                title: "Fuel Tank Capacity(l)",
                details: [],
                dataIndex: "fuelTankCapacity",
                content: this.validatedTextLable(generator.fuelTankCapacity),
            },
            {
                title: "Bulk Tank Capacity (l)",
                details: [],
                dataIndex: "genBulckTank",
                content: this.validatedTextLable(generator?.genBulckTank != null ? (generator?.genBulckTank?.capacity) : ('')),
            },
            {
                title: "Average Running Hours Per Month",
                details: [],
                dataIndex: "averageRunningHoursPerMonth",
                content: this.validatedTextLable(generator.averageRunningHoursPerMonth),
            },
            {
                title: "Phase1 Load (A)",
                details: [],
                dataIndex: "phase1Load",
                content: this.validatedTextLable(generator.phase1Load),
            },
            {
                title: "Phase2 Load (A)",
                details: [],
                dataIndex: "phase2Load",
                content: this.validatedTextLable(generator.phase2Load),
            },
            {
                title: "Phase3 Load (A)",
                details: [],
                dataIndex: "phase3Load",
                content: this.validatedTextLable(generator.phase3Load),
            },
            {
                title: "Hour Meter Reading (Hrs)",
                details: [],
                dataIndex: "hourMeterReading",
                content: this.validatedTextLable(generator.hourMeterReading),
            },
            {
                title: "Loading With Battery Charging(%)",
                details: [],
                dataIndex: "loadingWithBatteryCharging",
                content: this.validatedTextLable(generator.loadingWithBatteryCharging),
            },
            {
                title: "Loading Without Battery Charging(%)",
                details: [],
                dataIndex: "loadingWithoutBatteryCharging",
                content: this.validatedTextLable(generator.loadingWithoutBatteryCharging),
            },
            {
                title: "Available Fuel Qty (l)",
                details: [],
                dataIndex: "availableFuelQty",
                content: this.validatedTextLable(generator.availableFuelQty),
            },

            {
                title: "Actual Fuel Consumption Rate (Litres/Hour)",
                details: [],
                dataIndex: "actualFuelConsumptionRate",
                content: this.validatedTextLable(generator.actualFuelConsumptionRate),
            },

            {
                title: "Availability Of Gen Canapy",
                details: [],
                dataIndex: "availabilityOfGenCanapy",
                content: this.validatedBoolLable(generator.availabilityOfGenCanapy),
            },
            {
                title: "Gen Canapy Status",
                details: [],
                dataIndex: "genCanapyStatus",
                content: this.validatedTextLable(generator.genCanapyStatus),
            },
            {
                title: "Sound Proof Status",
                details: [],
                dataIndex: "soundProofStatus",
                content: this.validatedTextLable(generator.soundProofStatus),
            },

            {
                title: "Delay Timer Status",
                details: [],
                dataIndex: "delayTimerStatus",
                content: this.validatedTextLable(generator.delayTimerStatus),
            },
            {
                title: "Generator start up delay time (min)",
                details: [],
                dataIndex: "generatorWormUpDelayTime",
                content: this.validatedTextLable(generator.generatorWormUpDelayTime),
            },
            {
                title: "Generator cool down delay time (min)",
                details: [],
                dataIndex: "generatorCoolDownDelayTime",
                content: this.validatedTextLable(generator.generatorCoolDownDelayTime),
            },
            {
                title: "RMS Integrated Status",
                details: [],
                dataIndex: "rmsintegratedStatus",
                content: this.validatedTextLable(generator.rmsintegratedStatus),
            },
            {
                title: "RMS Inband/Outband",
                details: [],
                dataIndex: "rmsInbandOutband",
                content: this.validatedTextLable(generator.rmsInbandOutband),

            },
            {
                title: "RMS Connection Method",
                details: [],
                dataIndex: "rmsconnectionMethod",
                content: this.validatedTextLable(generator.rmsconnectionMethod),
            },

            {
                title: "RMS Related SIM Number",
                details: [],
                dataIndex: "rmsrelatedSIMNumber",
                content: this.validatedTextLable(generator.rmsrelatedSIMNumber),

            },
            {
                title: "Last Serviced Date",
                details: [],
                dataIndex: "lastServicedDate",
                content: this.validatedTextLable(generator.lastServicedDate),
            },
            {
                title: "Installed Date",
                details: [],
                dataIndex: "installedDate",
                content: this.validatedTextLable(generator.installedDate),
            },
            // -------------------------------------------------------------------------------------------------------
            {
                title: "Active",
                details: [],
                dataIndex: "active",
                content: generator.active
                    ? <span class="badge bg-success">true</span>
                    : <span class="badge bg-danger">false</span>,
            },
            {
                title: "Type",
                details: [],
                dataIndex: "type",
                content: generator !== ""
                    ? <span class="badge bg-secondary">{generator.type?.name}</span>
                    : <></>
            },
            {
                title: "Category",
                details: [],
                dataIndex: "category",
                content: generator !== "" ? <span class="badge badge-soft-warning fw-semibold">{generator.category?.name}</span> : <></>,
            },
            {
                title: "Description",
                details: [],
                dataIndex: "description",
                content: this.validatedTextLable(generator.description),
            },
            // {
            //     title: "Any Other Serial Number",
            //     details: [],
            //     dataIndex: "anyOtherSerialNumber",
            //     content: this.validatedTextLable(generator.anyOtherSerialNumber),
            // },
            {
                title: "Fuel Half Filling",
                details: [],
                dataIndex: "fuelHalfFilling",
                content: this.validatedBoolLable(generator.fuelHalfFilling)
            },
            {
                title: "Last Filling Quantity",
                details: [],
                dataIndex: "lastFillingQuantity",
                content: this.validatedTextLable(generator.lastFillingQuantity),
            },
            {
                title: "Last Gen Efficiency",
                details: [],
                dataIndex: "lastGenEfficiency",
                content: this.validatedTextLable(generator.lastGenEfficiency),
            },
            {
                title: "Last Meter Reading",
                details: [],
                dataIndex: "lastMeterReading",
                content: this.validatedTextLable(generator.lastMeterReading),
            },
            {
                title: "Last Remaining Fuel Quantity",
                details: [],
                dataIndex: "lastRemainingFuelQuantity",
                content: this.validatedTextLable(generator.lastRemainingFuelQuantity),
            },
            {
                title: "Last Runing Hours",
                details: [],
                dataIndex: "lastRuningHours",
                content: this.validatedTextLable(generator.lastRuningHours),
            },
            {
                title: "Max Filling Limit",
                details: [],
                dataIndex: "maxFillingLimit",
                content: this.validatedTextLable(generator.maxFillingLimit),
            },
            {
                title: "Warranty Expired Date",
                details: [],
                dataIndex: "warrantyExpiredDate",
                content: this.validatedTextLable(generator.warrantyExpiredDate),
            },
        ];


        console.log("this.props.details1.access_technologies_data----------------------------------------")
        console.log(this.props.dropDownInfoGen)

        return (
            <div className="row">

                <div className='row'>
                    <div class="col-lg-12">
                        <div class="text-end">
                            <ul class="list-inline">
                                <li class="list-inline-item">
                                    {this.state.actionView
                                        ? <button type="button" class="btn btn-soft-primary btn-sm" onClick={this.requestFromClose}><i class="fas fa-plus me-2"></i>Add Generators</button>
                                        : ('')
                                    }
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>

                <div className='row'>

                    <div className='col-md-12'>
                        <div class="card">
                            <div class="card-header">
                                <div class="d-flex justify-content-between align-items-center">
                                    <h4 class="card-title">Information</h4>
                                    {this.state.switchStatus
                                        ? <button type="button" class="btn btn-soft-primary btn-icon-square-sm" onClick={() => this.dataUpdate()}> <i class="mdi mdi-pencil-outline"></i></button>
                                        : <div class="d-flex justify-content-end align-items-center">
                                            <button type="button" class="btn btn-soft-secondary " onClick={() => this.close()} style={{ marginRight: "10px" }}> Cancel </button>
                                            <button type="button" class="btn btn-soft-primary " onClick={() => this.dataSetToUpdate()}> Submit to Update </button>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div className='col-md-6'>
                                        <ul class="list-group">

                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>  No. of Generators
                                                </div>
                                                {this.props.details1?.access_technologies_data?.noOfGenerators != null ? 
                                                (<span class="badge badge-outline-success">{this.props.details2?.generators_data.length}</span>) : 
                                                (<span class="badge badge-outline-warning">Currently Not Set</span>)}
                                            </li>
                                        </ul>
                                    </div>

                                    <div className='col-md-6'>
                                        <ul class="list-group">

                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>  Ticket numbers of pending tickets
                                                </div>
                                                {/* {this.props?.details?.access_technologies_data?.cplannedFromOperationalTeam? (<span class="badge badge-outline-success">true</span>) : (<span class="badge badge-outline-warning">false</span>)} */}
                                                {/* {this.props?.details?.access_technologies_data?.cplannedFromOperationalTeam ? (<> <Switch disabled={this.state.switchStatus} defaultChecked onChange={() => this.switchTo()} checkedChildren="true" unCheckedChildren="false" /></>) : (<Switch disabled={this.state.switchStatus} onChange={() => this.switchTo()} checkedChildren="true" unCheckedChildren="false" />)} */}
                                                {this.state.switchStatus ? (<>{this.props.details1?.access_technologies_data?.genTicketNumberOfPendingFaultyTicket != null ? (<span class="badge badge-outline-success">{this.props.details1?.access_technologies_data?.genTicketNumberOfPendingFaultyTicket}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>  <InputNumber min={0} style={{ width: '20%' }} value={this.state.objectSet?.genTicketNumberOfPendingFaultyTicket} id="cnoOfCabinetsWithEquipmentInstalled" onChange={this.handlgenTicketNumberOfPendingFaultyTicket} /> </>)}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='row'>
                    {this.props.details2?.generators_data?.map((generator) => (
                        <div className='col-md-4'>
                            <div class="card">
                                <div class="card-header">
                                    <div class="d-flex justify-content-start align-items-center">
                                        <img src={'https://static.vecteezy.com/system/resources/previews/001/902/502/large_2x/telecommunication-radio-antenna-and-satelite-tower-with-blue-sky-free-photo.JPG'} alt="" class="thumb-md rounded" />
                                        <div class="badge badge-soft-primary" style={{ padding: "5px", marginLeft: "15px", border: "1px solid rgba(0, 0, 0, 0.05)" }}>
                                            <i class="la la-home font-14 text-dark" style={{ paddingLeft: "10px" }} />
                                            <i class="la la-angle-right text-dark font-14" style={{ padding: "5px" }} />
                                            <span class="fw-semibold text-pink">{this.props.details?.site_details?.siteName?.slice(0, 15)}</span>
                                            <i class="la la-angle-right text-dark font-14" style={{ padding: "5px" }} />
                                            <span class="fw-semibold text-primary" style={{ paddingRight: "10px" }}>{generator?.name?.slice(0, 15)}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="col">
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <h6 class="fw-normal m-0">Type</h6>
                                            <span class="badge bg-secondary">{generator?.type?.name}</span>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <h6 class="fw-normal m-0">Model</h6>
                                            <span class="badge badge-outline-info badge-pill"><i class="fas fa-hdd" style={{ paddingRight: "5px" }} />{generator?.model?.name}</span>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <h6 class="fw-normal m-0">Active Status</h6>
                                            {generator.active
                                                ? <span class="badge bg-success">Active</span>
                                                : <span class="badge bg-danger">Deactive</span>
                                            }
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <h6 class="fw-normal m-0">Category</h6>
                                            <span class="badge badge-soft-warning fw-semibold">{generator?.category?.name}</span>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <h6 class="fw-normal m-0">Filling</h6>
                                            <div class="d-flex align-items-center">
                                                <span class="badge badge-soft-pink fw-semibold" style={{ marginRight: "10px" }}><i class="far fa-fw ti-ruler font-14"></i> Max Limit {generator?.maxFillingLimit + "L"}</span>
                                                <span class="badge badge-soft-success fw-semibold"><i class="far fa-fw mdi mdi-fuel font-14"></i> {generator?.lastRemainingFuelQuantity + "L"} left</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p class="card-footer m-0">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <a href="#custom-modal" class="btn btn-soft-dark btn-sm waves-effect btn-round" onClick={() => this.viewMoreDetails(generator)}>
                                            <i class="mdi mdi mdi-unfold-more-horizontal"></i> More Details </a>
                                        <div class="text-end">
                                            {this.state.AssetManagementPermission
                                                ? (<button type="button" class="btn btn-soft-primary btn-icon-square-sm" onClick={() => this.grnUpdata(generator.id)} style={{ marginRight: "8px" }}><i class="mdi mdi-pencil-outline"></i></button>)
                                                : ("")}
                                            <Popconfirm
                                                title="Are You Sure?"
                                                description="Are you sure to delete this task?"
                                                onConfirm={() => this.submitForm(generator?.id)}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                {this.state.AssetManagementPermission
                                                    ? (<button type="button" class="btn btn-soft-danger btn-icon-square-sm"><i class="mdi mdi-trash-can-outline"></i></button>)
                                                    : ('')}
                                            </Popconfirm>
                                        </div>
                                    </div>
                                </p>
                            </div>
                        </div>
                    ))}
                </div>



                <Drawer maskClosable={false} title={"Generator Details"} width={"50%"} placement="right" onClose={() => this.onViewMoreDetailsDrawerClose()} visible={this.state.openGenDetails} closeIcon={<button type="button" class="btn btn-soft-danger btn-icon-square-sm" style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                    <Collapse
                        style={{ borderColor: "lavender", alignContent: "center" }}
                    >
                        {columns2.map((item) => (
                            <Panel
                                style={{ borderColor: "lavender" }}
                                header={
                                    <li class="d-flex justify-content-between align-items-center">
                                        <div>
                                            {item.details.length == 0 ? <i class="la la-hand-o-right text-primary" style={{ paddingLeft: "5px", paddingRight: "5px" }} /> : <></>}
                                            <span class="fw-normal m-2">{item.title}</span>
                                        </div>
                                        {item.content}
                                    </li>
                                }
                                key={item.title}
                                showArrow={item.details.length > 0}
                                collapsible={item.details.length > 0 ? "" : "icon"}
                            >
                                {item.details.map((detail) => (
                                    <li class="d-flex justify-content-between align-items-center me-2" style={{ paddingBottom: "10px", paddingLeft: "10px" }}>
                                        <div>
                                            <i class="la la-circle text-primary me-2"></i> <span>{detail.title}</span>
                                        </div>
                                        {detail.content}
                                    </li>
                                ))}
                            </Panel>
                        ))}
                    </Collapse>
                </Drawer>


                {/* popup start */}
                <Drawer maskClosable={false} title={" New Generator"} width={"60%"} placement="right" onClose={() => this.onDrawerClose()} visible={this.state.open} closeIcon={<button type="button" class="btn btn-soft-danger btn-icon-square-sm" style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                    <div className="row" >
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <Form ref={this.formRef}>
                                        <div className='row'>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Name<x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Generator_Name" tooltip="This is a required field">
                                                    <Input id="Generator_Name" defaultValue={"Generator-"} onChange={this.handleGenName} />
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Name', this.state.genName, 'required')}</p>
                                                </Form.Item>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Make <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Makw" tooltip="This is a required field">
                                                    <Select
                                                        showSearch
                                                        // onChange={this.onChangeTowerType}
                                                        onChange={this.onChangeGeneratorMake}
                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.props?.genMasterDataSet?.genMake?.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}

                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Model', this.state.selectedGeneratorMakeId, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Model <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Model" tooltip="This is a required field">
                                                    <Select
                                                        showSearch
                                                        // onChange={this.onChangeTowerType}
                                                        onChange={this.onChangeGeneratormodel}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.modelList?.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}

                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Model', this.state.selectedGeneratormodelId, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Capacity(kVA) <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Generator_Capacity" >
                                                    {/* <InputNumber style={{ width: '100%' }} id="Generator_Capacity" onChange={this.handleGenCapacity} /> */}
                                                    <div>
                                                        <Select
                                                            id='Generator_Capacity'
                                                            style={{ width: "100%" }}
                                                            showSearch
                                                            onChange={(e) => {
                                                                this.handleGenCapacity(e)
                                                            }}>
                                                            {this.props.genDropdown["generatorDropDownObject"]["genCapacity"]?.map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Capacity', this.state.genCapacity, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Generator SAP Serial Number <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="GeneratorSAPSerialNumber" >
                                                    <Input style={{ width: '100%' }} id="GeneratorSAPSerialNumber" onChange={this.handleGenSAPSerialNumber} />
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('SAP Serial Number', this.state.SAPSerialNumber, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Engine Serial Number <x style={{ color: "red" }}>*</x>
                                                </label>

                                                <Form.Item required name="Engine Serial Number" >
                                                    <Input style={{ width: '100%' }} id="Generator_Status" onChange={this.handleEngineSerialNumber} />
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Engine Serial Number', this.state.engineSerialNumber, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Alternater Serial Number <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Alternater Serial Number" >
                                                    <Input style={{ width: '100%' }} id="Generator_Status" onChange={this.handleAlternaterSerialNumber} />
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Alternater Serial Number', this.state.alternaterSerialNumber, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Controller Serial Number <x style={{ color: "red" }}>*</x>
                                                </label>

                                                <Form.Item required name="Controller Serial Number" >
                                                    <Input style={{ width: '100%' }} id="Generator_Status" onChange={this.handleControllerSerialNumber} />
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Controller Serial Number', this.state.controllerSerialNumber, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Engine Model <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Engine Model" >
                                                    <div>
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            showSearch
                                                            onChange={(e) => {
                                                                this.handlegeneratorEngineModel(e);
                                                            }}>
                                                            {this.props.genDropdown["generatorDropDownObject"]["genEngineModel"].map((x) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Engine Model', this.state.engineModel, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            {/* <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Generator Controller Model <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Generator Controller Model" >
                                                    <div>
                                                        <Select
                                                            showSearch
                                                            style={{ width: "100%" }}
                                                            onChange={(e) => {
                                                                this.handlegeneratorControllerModel(e);
                                                            }}>
                                                            {this.props.genDropdown["generatorDropDownObject"]["generatorControllerModel"].map((x) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Generator Controller Model', this.state.generatorControllerModel, 'required')}</p>
                                                </Form.Item>
                                            </div> */}
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Engine Controller Model <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Engine Controller Model" >
                                                    {/* <Input style={{ width: '100%' }} id="Generator Controller Model" onChange={this.handlegeneratorControllerModel} /> */}
                                                    <div>
                                                        <Select
                                                            showSearch
                                                            style={{ width: "100%" }}
                                                            onChange={(e) => {
                                                                this.handleengineControllerModel(e);
                                                            }}>
                                                            {this.props.genDropdown["generatorDropDownObject"]["generatorControllerModel"].map((x) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Engine Controller Model', this.state.engineControllerModel, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    AVR Model <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Generator_Status" >
                                                    <div>
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            onChange={(e) => {
                                                                this.handlegeneratorAVRModel(e);
                                                            }}>
                                                            {this.props.genDropdown["generatorDropDownObject"]["avrModel"].map((x) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('AVR Model', this.state.avrModel, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Fuel Tank Capacity (l) <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Fuel Tank Capacity" >
                                                    {/* <InputNumber style={{ width: '100%' }} id="Fuel Tank Capacity" onChange={this.handlefuelTankCapacity} /> */}
                                                    <div>
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            onChange={(e) => {
                                                                this.handlefuelTankCapacity(e);
                                                            }}>
                                                            {this.props.genDropdown["generatorDropDownObject"]["fuelTankCapacity"].map((x) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Fuel Tank Capacity', this.state.fuelTankCapacity, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Type <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Type" tooltip="This is a required field">
                                                    <Select
                                                        showSearch
                                                        // onChange={this.onChangeTowerType}
                                                        onChange={this.onChangeGeneratorType}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.tankTypesList?.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}

                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Type', this.state.selectedGeneratorTypeId, 'required')}</p>

                                                </Form.Item>
                                            </div>

                                            {this.state.selectedGeneratorTypeId == 2 ? (
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <label className="form-label" for="depot">
                                                            Gen Bulk Tank <x style={{ color: "red" }}>*</x>
                                                        </label>
                                                        <Form.Item >

                                                            <Select
                                                                showSearch
                                                                // onChange={this.onChangeTowerType}
                                                                onChange={this.onChangeGenBulckTank}

                                                                style={{ width: "100%" }}
                                                                placeholder="Search to Select"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                filterSort={(optionA, optionB) =>
                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                }
                                                            >
                                                                {this.state.bulckTankList?.map((item, index) => {
                                                                    return <Option value={item.id}>{"Capacity: " + item.capacity + " / Max Filling Limit: " + item.maxFillingLimit}</Option>;
                                                                })}

                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                </div>) : ("")
                                            }

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Average Running Hours per month
                                                </label>
                                                <Form.Item required name="Average Running Hours per month " >
                                                    <InputNumber style={{ width: '100%' }} id="Average Running Hours per month " onChange={this.handlAverageRunningHourspermonth} />
                                                    {/* <p style={{ color: "#f5325c" }}>{this.validator.message('Generator cool down delay time', this.state.generatorCoolDownDelayTime, 'required')}</p> */}
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Phase1 Load (A) <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Phase1 Load" tooltip="This is a required field">
                                                    <InputNumber style={{ width: '100%' }} id="Phase1 Load" onChange={this.handlephase1Load} />
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Phase1 Load', this.state.phase1Load, 'required')}</p>
                                                </Form.Item>

                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Phase2 Load (A) <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Phase2 Load" >
                                                    <InputNumber style={{ width: '100%' }} id="Phase2 Load" onChange={this.handlephase2Load} />
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Phase2 Load', this.state.phase2Load, 'required')}</p>
                                                </Form.Item>

                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Phase3 Load (A) <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Phase3 Load" tooltip="This is a required field">
                                                    <InputNumber style={{ width: '100%' }} id="Phase3 Load" onChange={this.handlephase3Load} />
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Phase3 Load', this.state.phase3Load, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Hour Meter Reading (Hrs) <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Fuel Tank Capacity" >
                                                    <InputNumber style={{ width: '100%' }} id="Fuel Tank Capacity" onChange={this.handlehourMeterReading} />
                                                    {/* <p style={{ color: "#f5325c" }}>{this.validator.message('Fuel Tank Capacity', this.state.hourMeterReading, 'required')}</p> */}
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Available Fuel Quantity (l) <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Available Fuel Quantity" >
                                                    <InputNumber style={{ width: '100%' }} id="Available Fuel Quantity" onChange={this.handleavailableFuelQty} />
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Available Fuel Quantity', this.state.availableFuelQty, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Actual Fuel Consumption Rate (Litres/Hour)
                                                </label>
                                                <Form.Item required name="Actual Fuel Consumption Rate" >
                                                    <InputNumber style={{ width: '100%' }} id="Actual Fuel Consumption Rate" onChange={this.handleactualFuelConsumptionRate} />
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Availability Of Gen Canapy <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item >
                                                    <div>
                                                        <Select
                                                            style={{ width: "100%" }}
                                                            onChange={(e) => {
                                                                this.onChangeAvailabilityOfGenCanapy(e);
                                                            }}>
                                                            {this.props.genDropdown["generatorDropDownObject"]["availabilityOfGenCanapy"]?.map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                </Form.Item>
                                                <p style={{ color: "#f5325c" }}>{this.validator.message('Availability Of Gen Canapy', this.state.availabilityOfGenCanapy, 'required')}</p>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Gen Canapy Status <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Gen Canapy Status" >
                                                    {/* <Input style={{ width: '100%' }} id="Gen Canapy Status" onChange={this.handlegenCanapyStatus} /> */}
                                                    <div>
                                                        <Select
                                                            style={{ width: "100%" }}
                                                            onChange={(e) => {
                                                                this.handlegenCanapyStatus(e);
                                                            }}>
                                                            {this.props.genDropdown["generatorDropDownObject"]["genCanapyStatus"].map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Gen Canapy Status', this.state.genCanapyStatus, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Sound Proof Status <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Sound Proof Status" >
                                                    {/* <Input style={{ width: '100%' }} id="Sound Proof Status" onChange={this.handlesoundProofStatus} /> */}
                                                    <div>
                                                        <Select
                                                            style={{ width: "100%" }}
                                                            onChange={(e) => {
                                                                this.handlesoundProofStatus(e);
                                                            }}>
                                                            {this.props.genDropdown["generatorDropDownObject"]["soundProofStatus"].map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Sound Proof Status', this.state.soundProofStatus, 'required')}</p>
                                                </Form.Item>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Delay Timer Status <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Delay Timer Status" >
                                                    {/* <Input style={{ width: '100%' }} id="Delay Timer Status" onChange={this.handlDelayTimerStatus} /> */}
                                                    <div>
                                                        <Select
                                                            style={{ width: "100%" }}
                                                            onChange={(e) => {
                                                                this.handlDelayTimerStatus(e);
                                                            }}>
                                                            {this.props.genDropdown["generatorDropDownObject"]["delayTimerStatus"].map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Delay Timer Status', this.state.delayTimerStatus, 'required')}</p>
                                                </Form.Item>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Generator start up delay time (min) <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Generator Worm-Up Delay Time" >
                                                    <InputNumber style={{ width: '100%' }} id="Generator Worm-Up Delay Time" onChange={this.handlegeneratorWormUpDelayTime} />
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Generator Worm-Up Delay Time Status', this.state.generatorWormUpDelayTime, 'required')}</p>
                                                </Form.Item>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Generator cool down delay time (min) <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Generator cool down delay time" >
                                                    <InputNumber style={{ width: '100%' }} id="Generator cool down delay time" onChange={this.handlegeneratorCoolDownDelayTime} />
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Generator cool down delay time', this.state.generatorCoolDownDelayTime, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    RMS Integrated Status
                                                </label>
                                                <Form.Item required name="Rm sintegrated Status" >
                                                    <div>
                                                        <Select
                                                            style={{ width: "100%" }}
                                                            onChange={(e) => {
                                                                this.handlermsintegratedStatus(e);
                                                            }}>
                                                            {this.props.genDropdown["generatorDropDownObject"]["rmsintegratedStatus"].map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                </Form.Item>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    RMS Inband/Outband
                                                </label>
                                                <Form.Item required name="RMS Inband Outband" >
                                                    {/* <Input style={{ width: '100%' }} id="RMS Inband Outband" onChange={this.handlerMsInbandOutband} /> */}
                                                    <div>
                                                        <Select
                                                            style={{ width: "100%" }}
                                                            onChange={(e) => {
                                                                this.handlerMsInbandOutband(e);
                                                            }}>
                                                            {this.props.genDropdown["generatorDropDownObject"]["rmsInbandOutband"].map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                </Form.Item>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    RMS Connection Method
                                                </label>
                                                <Form.Item required name="Rms Connection Method" >
                                                    <div>
                                                        <Select
                                                            style={{ width: "100%" }}
                                                            onChange={(e) => {
                                                                this.handlermsconnectionMethod(e);
                                                            }}>
                                                            {this.props.genDropdown["generatorDropDownObject"]["rmsConnectionMethod"].map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                </Form.Item>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    RMS related SIM Number
                                                </label>
                                                <Form.Item required name="Rm Srelated SIM Number" >
                                                    <Input style={{ width: '100%' }} id="Rm Srelated SIM Number" onChange={this.handlerMSRelatedSIMNumber} />
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Last Serviced Date
                                                </label>
                                                <Form.Item required name="Last Serviced Date" >
                                                    <DatePicker
                                                        style={{ width: "100%" }}
                                                        onChange={this.onChangeLastServicedDate}
                                                    />
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Installed Date
                                                </label>
                                                <Form.Item required name="Installed Date" >
                                                    <DatePicker
                                                        style={{ width: "100%" }}
                                                        onChange={this.onChangeInstalledDate}
                                                    />
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Is Active<x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Is_active" tooltip="This is a required field">
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeGeneratorIsActive}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        <Option value='1'>Yes</Option>
                                                        <Option value='0'>No</Option>

                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Is active', this.state.generatorIsActive, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Category <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Category" tooltip="This is a required field">
                                                    <Select
                                                        showSearch
                                                        // onChange={this.onChangeTowerType}
                                                        onChange={this.onChangeGeneratorCategory}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.categoriesList?.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}

                                                    </Select>

                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Category', this.state.selectedGeneratorCategory, 'required')}</p>
                                                </Form.Item>

                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Max Filling Limit <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Generator_MaxFillingLimit" tooltip="This is a required field">
                                                    <InputNumber style={{ width: '100%' }} id="Generator_MaxFillingLimit" onChange={this.handleMaxFillingLimit} />
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Max Filling Limit', this.state.genMaxFillingLimit, 'required')}</p>
                                                </Form.Item>

                                            </div>


                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Description
                                                </label>
                                                <Form.Item required name="Generator_Description" tooltip="This is a required field">
                                                    <Input id="Generator_Description" onChange={this.handleGenDescription} />
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Warranty Expired Date
                                                </label>
                                                <Form.Item required name=" Warranty Expired Date" >
                                                    <DatePicker
                                                        style={{ width: "100%" }}
                                                        onChange={this.onChangeWarrantyExpiredDate}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </Form>
                                    <div className='row' style={{ justifyContent: "end" }}>
                                        <div className='col-md-2' >   <button className="btn btn-primary" onClick={() => this.AllDataSubmit()} >
                                            Add New Asset
                                        </button></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Drawer>




                {/* Genarator Edit Section */}
                <Drawer maskClosable={false} title={this.state.genEdit !== null ? "Edit Generator" : " New Generator"} width={"70%"} placement="right" onClose={() => this.onDrawerCloseEdit()} visible={this.state.openEdit} closeIcon={<button type="button" class="btn btn-soft-danger btn-icon-square-sm" style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                    <div className="row" >
                        <div className="col-lg-12">
                            <div className="card">

                                <div className="card-body">
                                    <Form
                                        ref={this.formRef}
                                    >
                                        <div className='row'>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Name<x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item >
                                                    <Input id="Generator_Name" value={this.state.genName} onChange={this.handleGenName} />
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Name', this.state.genName, 'required')}</p>

                                                </Form.Item>

                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Make <x style={{ color: "red" }}>*</x>
                                                </label>
                                                {this.state.selectedGeneratorMakeId != null ? (<Form.Item required name="Makw" tooltip="This is a required field">
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeGeneratorMake}
                                                        defaultValue={this.state.selectedGeneratorMakeId}
                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.props?.genMasterDataSet?.genMake?.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}

                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Model', this.state.selectedGeneratorMakeId, 'required')}</p>
                                                </Form.Item>) : ('')}
                                                {this.state.selectedGeneratorMakeId == null ? (<Form.Item required name="Makw" tooltip="This is a required field">
                                                    <Select
                                                        showSearch
                                                        // onChange={this.onChangeTowerType}
                                                        onChange={this.onChangeGeneratorMake}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.props?.genMasterDataSet?.genMake?.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}

                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Model', this.state.selectedGeneratorMakeId, 'required')}</p>
                                                </Form.Item>) : ('')}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Model <x style={{ color: "red" }}>*</x>
                                                </label>
                                                {this.state.selectedGeneratormodelId != null ? (<Form.Item><Select
                                                    showSearch
                                                    // onChange={this.onChangeTowerType}
                                                    onChange={this.onChangeGeneratormodel}
                                                    defaultValue={this.state.selectedGeneratormodelId}
                                                    style={{ width: "100%" }}
                                                    placeholder="Search to Select"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                >
                                                    {this.state.modelList?.map((item, index) => {
                                                        return <Option value={item.id}>{item.name}</Option>;
                                                    })}

                                                </Select></Form.Item>) : ('')}
                                                <p style={{ color: "#f5325c" }}>{this.validator.message('Model', this.state.selectedGeneratormodelId, 'required')}</p>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Capacity(kVA)
                                                </label>
                                                <Form.Item>
                                                    <div>
                                                        <Select
                                                            value={this.state.genCapacity}
                                                            showSearch
                                                            id='Generator_Capacity'
                                                            style={{ width: "100%" }}
                                                            onChange={(e) => {
                                                                this.handleGenCapacity(e)
                                                            }}>
                                                            {this.props.genDropdown["generatorDropDownObject"]["genCapacity"]?.map((x, y) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Capacity', this.state.genCapacity, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Generator SAP Serial Number <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item>
                                                    <Input style={{ width: '100%' }} id="GeneratorSAPSerialNumber" value={this.state.SAPSerialNumber} onChange={this.handleGenSAPSerialNumber} />
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('SAP Serial Number', this.state.SAPSerialNumber, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Engine Serial Number <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item >
                                                    <Input style={{ width: '100%' }} id="Generator_Status" value={this.state.engineSerialNumber} onChange={this.handleEngineSerialNumber} />
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Engine Serial Number', this.state.engineSerialNumber, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Alternater Serial Number <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item  >
                                                    <Input style={{ width: '100%' }} id="Generator_Status" value={this.state.alternaterSerialNumber} onChange={this.handleAlternaterSerialNumber} />
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Alternater Serial Number', this.state.alternaterSerialNumber, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Controller Serial Number <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item>
                                                    <Input style={{ width: '100%' }} value={this.state.controllerSerialNumber} id="Generator_Status" onChange={this.handleControllerSerialNumber} />
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Controller Serial Number', this.state.controllerSerialNumber, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Engine Model <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item >
                                                    <div>
                                                        <Select
                                                            showSearch
                                                            value={this.state.engineModel}
                                                            style={{ width: '100%' }}
                                                            onChange={(e) => {
                                                                this.handlegeneratorEngineModel(e);
                                                            }}>
                                                            {this.props.genDropdown["generatorDropDownObject"]["genEngineModel"].map((x) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Engine Model', this.state.engineModel, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            {/* <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Generator Controller Model <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Generator Controller Model" >
                                                    <div>
                                                        <Select
                                                            showSearch
                                                            value={this.state.generatorControllerModel}
                                                            style={{ width: "100%" }}
                                                            onChange={(e) => {
                                                                this.handlegeneratorControllerModel(e);
                                                            }}>
                                                            {this.props.genDropdown["generatorDropDownObject"]["generatorControllerModel"].map((x) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Generator Controller Model', this.state.generatorControllerModel, 'required')}</p>
                                                </Form.Item>
                                            </div> */}

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Engine Controller Model <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Engine Controller Model" >
                                                    {/* <Input style={{ width: '100%' }} id="Generator Controller Model" onChange={this.handlegeneratorControllerModel} /> */}
                                                    <div>
                                                        <Select
                                                            value={this.state.engineControllerModel}
                                                            showSearch
                                                            style={{ width: "100%" }}
                                                            onChange={(e) => {
                                                                this.handleengineControllerModel(e);
                                                            }}>
                                                            {this.props.genDropdown["generatorDropDownObject"]["generatorControllerModel"].map((x) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Engine Controller Model', this.state.engineControllerModel, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    AVR Model <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="AVR Model" >
                                                    <div>
                                                        {/* <Input value={this.state.avrModel} style={{ width: '100%' }} id="AVR Model" onChange={this.handlegeneratorAVRModel} /> */}
                                                        <Select
                                                            showSearch
                                                            value={this.state.avrModel}
                                                            style={{ width: '100%' }}
                                                            onChange={(e) => {
                                                                this.handlegeneratorAVRModel(e);
                                                            }}>
                                                            {this.props.genDropdown["generatorDropDownObject"]["avrModel"].map((x) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('AVR Model', this.state.avrModel, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Fuel Tank Capacity (l) <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item  >
                                                    {/* <InputNumber style={{ width: '100%' }} value={this.state.fuelTankCapacity} id="Fuel Tank Capacity" onChange={this.handlefuelTankCapacity} /> */}
                                                    <div>
                                                        <Select
                                                            value={this.state.fuelTankCapacity}
                                                            style={{ width: '100%' }}
                                                            onChange={(e) => {
                                                                this.handlefuelTankCapacity(e);
                                                            }}>
                                                            {this.props.genDropdown["generatorDropDownObject"]["fuelTankCapacity"].map((x) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Fuel Tank Capacity', this.state.fuelTankCapacity, 'required')}</p>
                                                </Form.Item>
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Type <x style={{ color: "red" }}>*</x>
                                                </label>
                                                {this.state.selectedGeneratorTypeId != null ? (<Form.Item><Select
                                                    showSearch
                                                    // onChange={this.onChangeTowerType}
                                                    onChange={this.onChangeGeneratorType}
                                                    defaultValue={this.state.selectedGeneratorTypeId}
                                                    style={{ width: "100%" }}
                                                    placeholder="Search to Select"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                >
                                                    {this.state.tankTypesList?.map((item, index) => {
                                                        return <Option value={item.id}>{item.name}</Option>;
                                                    })}

                                                </Select></Form.Item>) : ("")}
                                                <p style={{ color: "#f5325c" }}>{this.validator.message('Type', this.state.selectedGeneratorTypeId, 'required')}</p>
                                                {this.state.selectedGeneratorTypeId == 2 ? (
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <label className="form-label" for="depot">
                                                                Gen Bulk Tank <x style={{ color: "red" }}>*</x>
                                                            </label>
                                                            {this.state.selectedGenBulckTankId != null ? (<Form.Item><Select
                                                                showSearch
                                                                // onChange={this.onChangeTowerType}
                                                                onChange={this.onChangeGenBulckTank}
                                                                defaultValue={this.state.selectedGenBulckTankId}
                                                                style={{ width: '300px' }}
                                                                placeholder="Search to Select"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                filterSort={(optionA, optionB) =>
                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                }
                                                            >
                                                                {this.state.bulckTankList?.map((item, index) => {
                                                                    return <Option value={item.id}>{"Capacity: " + item.capacity + " / Max Filling Limit: " + item.maxFillingLimit}</Option>;
                                                                })}

                                                            </Select></Form.Item>) : ('')}
                                                            {this.state.selectedGenBulckTankId == null ? (<Form.Item><Select
                                                                showSearch
                                                                // onChange={this.onChangeTowerType}
                                                                onChange={this.onChangeGenBulckTank}

                                                                style={{ width: "100%" }}
                                                                placeholder="Search to Select"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                filterSort={(optionA, optionB) =>
                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                }
                                                            >
                                                                {this.state.bulckTankList?.map((item, index) => {
                                                                    return <Option value={item.id}>{"Capacity: " + item.capacity + " / Max Filling Limit: " + item.maxFillingLimit}</Option>;
                                                                })}

                                                            </Select></Form.Item>) : ('')}

                                                            {/* <p style={{ color: "#f5325c" }}>{this.validator.message(' Gen Bulck Tank', this.state.selectedGenBulckTankId, 'required')}</p> */}


                                                        </div>
                                                    </div>) : ("")}

                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Average Running Hours per month
                                                </label>
                                                <Form.Item >
                                                    <InputNumber style={{ width: '100%' }} value={this.state.genAverageRunningHourspermonth} id="Average Running Hours per month " onChange={this.handlAverageRunningHourspermonth} />
                                                    {/* <p style={{ color: "#f5325c" }}>{this.validator.message('Generator cool down delay time', this.state.generatorCoolDownDelayTime, 'required')}</p> */}
                                                </Form.Item>
                                            </div>
                                        </div>

                                        <div className='row'>

                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        Phase1 Load (A) <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="Phase1 Load" tooltip="This is a required field">
                                                        <InputNumber value={this.state.phase1Load} style={{ width: '100%' }} id="Phase1 Load" onChange={this.handlephase1Load} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Phase1 Load', this.state.phase1Load, 'required')}</p>
                                                    </Form.Item>

                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        Phase2 Load (A) <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="Phase2 Load" >
                                                        <InputNumber value={this.state.phase2Load} style={{ width: '100%' }} id="Phase2 Load" onChange={this.handlephase2Load} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Phase2 Load', this.state.phase2Load, 'required')}</p>
                                                    </Form.Item>

                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        Phase3 Load (A) <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="Phase3 Load" tooltip="This is a required field">
                                                        <InputNumber value={this.state.phase3Load} style={{ width: '100%' }} id="Phase3 Load" onChange={this.handlephase3Load} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Phase3 Load', this.state.phase3Load, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        Hour Meter Reading (Hrs) <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item >
                                                        <InputNumber style={{ width: '100%' }} value={this.state.hourMeterReading} id="Fuel Tank Capacity" onChange={this.handlehourMeterReading} />
                                                        {/* <p style={{ color: "#f5325c" }}>{this.validator.message('Fuel Tank Capacity', this.state.hourMeterReading, 'required')}</p> */}
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        Available Fuel Quantity (l) <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="Available Fuel Quantity" >
                                                        <InputNumber value={this.state.availableFuelQty} style={{ width: '100%' }} id="Available Fuel Quantity" onChange={this.handleavailableFuelQty} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Available Fuel Quantity', this.state.availableFuelQty, 'required')}</p>
                                                    </Form.Item>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        Actual Fuel Consumption Rate (Litres/Hour)
                                                    </label>
                                                    <Form.Item  >
                                                        <InputNumber value={this.state.actualFuelConsumptionRate} style={{ width: '100%' }} id="Actual Fuel Consumption Rate" onChange={this.handleactualFuelConsumptionRate} />

                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        Availability Of Gen Canapy <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item >
                                                        <div>
                                                            <Select
                                                                value={this.state.availabilityOfGenCanapy}
                                                                style={{ width: "100%" }}
                                                                onChange={(e) => {
                                                                    this.onChangeAvailabilityOfGenCanapy(e);
                                                                }}>
                                                                {this.props.genDropdown["generatorDropDownObject"]["availabilityOfGenCanapy"]?.map((x, y) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Form.Item>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Availability Of Gen Canapy', this.state.availabilityOfGenCanapy, 'required')}</p>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        Gen Canapy Status <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item  >
                                                        {/* <Input value={this.state.genCanapyStatus} style={{ width: '100%' }} id="Gen Canapy Status" onChange={this.handlegenCanapyStatus} /> */}
                                                        <div>
                                                            <Select
                                                                value={this.state.genCanapyStatus?.toString()}
                                                                style={{ width: "100%" }}
                                                                onChange={(e) => {
                                                                    this.handlegenCanapyStatus(e);
                                                                }}>
                                                                {this.props.genDropdown["generatorDropDownObject"]["genCanapyStatus"]?.map((x, y) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Gen Canapy Status', this.state.genCanapyStatus, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        Sound Proof Status <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item  >
                                                        {/* <Input value={this.state.soundProofStatus} style={{ width: '100%' }} id="Sound Proof Status" onChange={this.handlesoundProofStatus} /> */}
                                                        <div>
                                                            <Select
                                                                value={this.state.soundProofStatus}
                                                                style={{ width: "100%" }}
                                                                onChange={(e) => {
                                                                    this.handlesoundProofStatus(e);
                                                                }}>
                                                                {this.props.genDropdown["generatorDropDownObject"]["soundProofStatus"]?.map((x, y) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Sound Proof Status', this.state.soundProofStatus, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        Delay Timer Status <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item  >
                                                        {/* <Input value={this.state.delayTimerStatus} id="Delay Timer Status" onChange={this.handlDelayTimerStatus} /> */}
                                                        <div>
                                                            <Select
                                                                value={this.state.delayTimerStatus}
                                                                style={{ width: "100%" }}
                                                                onChange={(e) => {
                                                                    this.handlDelayTimerStatus(e);
                                                                }}>
                                                                {this.props.genDropdown["generatorDropDownObject"]["delayTimerStatus"].map((x, y) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Delay Timer Status', this.state.delayTimerStatus, 'required')}</p>
                                                    </Form.Item>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        Generator start up delay time (min) <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item  >
                                                        <InputNumber value={this.state.generatorWormUpDelayTime} style={{ width: '100%' }} id="Generator Worm-Up Delay Time" onChange={this.handlegeneratorWormUpDelayTime} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Generator Worm-Up Delay Time Status', this.state.generatorWormUpDelayTime, 'required')}</p>
                                                    </Form.Item>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        Generator cool down delay time (min) <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="Generator cool down delay time" >
                                                        <InputNumber value={this.state.generatorCoolDownDelayTime} style={{ width: '100%' }} id="Generator cool down delay time" onChange={this.handlegeneratorCoolDownDelayTime} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Generator cool down delay time', this.state.generatorCoolDownDelayTime, 'required')}</p>
                                                    </Form.Item>
                                                </div>

                                            </div>

                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="form-label" >
                                                        RMS Integrated Status
                                                    </label>
                                                    <Form.Item >
                                                        <div>
                                                            <Select
                                                                value={this.state.rmsintegratedStatus}
                                                                style={{ width: "100%" }}
                                                                onChange={(e) => {
                                                                    this.handlermsintegratedStatus(e);
                                                                }}>
                                                                {this.props.genDropdown["generatorDropDownObject"]["rmsintegratedStatus"]?.map((x, y) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        RMS Inband/Outband
                                                    </label>
                                                    <Form.Item >
                                                        {/* <Input value={this.state.rmsInbandOutband} style={{ width: '100%' }} id="RMS Inband Outband" onChange={this.handlerMsInbandOutband} /> */}
                                                        <div>
                                                            <Select
                                                                value={this.state.rmsInbandOutband}
                                                                style={{ width: "100%" }}
                                                                onChange={(e) => {
                                                                    this.handlerMsInbandOutband(e);
                                                                }}>
                                                                {this.props.genDropdown["generatorDropDownObject"]["rmsInbandOutband"].map((x, y) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        RMS Connection Method
                                                    </label>
                                                    <Form.Item  >
                                                        <div>
                                                            <Select
                                                                value={this.state.rmsconnectionMethod}
                                                                style={{ width: "100%" }}
                                                                onChange={(e) => {
                                                                    this.handlermsconnectionMethod(e);
                                                                }}>
                                                                {this.props.genDropdown["generatorDropDownObject"]["rmsConnectionMethod"].map((x, y) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        RMS related SIM Number
                                                    </label>
                                                    <Form.Item >
                                                        <Input value={this.state.rmsrelatedSIMNumber} style={{ width: '100%' }} id="Rm Srelated SIM Number" onChange={this.handlerMSRelatedSIMNumber} />
                                                    </Form.Item>
                                                </div>

                                            </div>
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        Last Serviced Date
                                                    </label>
                                                    {this.state.lastServicedDate != null ? (<Form.Item required name="Last Serviced Date" >
                                                        <DatePicker
                                                            style={{ width: "100%" }}
                                                            defaultValue={this.state.lastServicedDate != '' ? (moment(this.state.lastServicedDate)) : ("")}
                                                            onChange={this.onChangeLastServicedDate}
                                                        />
                                                    </Form.Item>) : ('')}
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        Installed Date
                                                    </label>
                                                    {this.state.installedDate != null ? (<Form.Item required name="Installed Date" >
                                                        <DatePicker
                                                            style={{ width: "100%" }}
                                                            defaultValue={this.state.installedDate != '' ? (moment(this.state.installedDate)) : ("")}
                                                            onChange={this.onChangeInstalledDate}
                                                        />
                                                    </Form.Item>) : ('')}
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        Is Active<x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    {this.state.generatorIsActive != null ? (
                                                        <Form.Item >
                                                            <Select
                                                                showSearch
                                                                defaultValue={(this.state.generatorIsActive).toString()}
                                                                onChange={this.onChangeGeneratorIsActive}

                                                                style={{ width: "100%" }}
                                                                placeholder="Search to Select"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                filterSort={(optionA, optionB) =>
                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                }
                                                            >
                                                                <Option value='1'>Yes</Option>
                                                                <Option value='0'>No</Option>

                                                            </Select>
                                                        </Form.Item>) : ('')}
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Is active', this.state.generatorIsActive, 'required')}</p>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        Category <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    {this.state.selectedGeneratorCategory != null ? (<Form.Item><Select
                                                        showSearch
                                                        // onChange={this.onChangeTowerType}
                                                        onChange={this.onChangeGeneratorCategory}
                                                        defaultValue={this.state.selectedGeneratorCategory}
                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.categoriesList?.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}

                                                    </Select></Form.Item>) : ("")}
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Category', this.state.selectedGeneratorCategory, 'required')}</p>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        Max Filling Limit <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item>
                                                        <InputNumber style={{ width: '100%' }} value={this.state.genMaxFillingLimit} id="Generator_MaxFillingLimit" onChange={this.handleMaxFillingLimit} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Max Filling Limit', this.state.genMaxFillingLimit, 'required')}</p>
                                                    </Form.Item>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        Description
                                                    </label>
                                                    <Form.Item >
                                                        <Input id="Generator_Description" value={this.state.genDescription} onChange={this.handleGenDescription} />
                                                    </Form.Item>

                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        Warranty Expired Date
                                                    </label>
                                                    {this.state.WarrantyExpiredDate != null ? (<Form.Item required name=" Warranty Expired Date2" ><DatePicker
                                                        defaultValue={this.state.WarrantyExpiredDate != '' ? (moment(this.state.WarrantyExpiredDate)) : ("")}
                                                        style={{ width: "100%" }}

                                                        onChange={this.onChangeWarrantyExpiredDate}
                                                    /></Form.Item>) : ('')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row' style={{ justifyContent: "end" }}>
                                            <div className='col-md-2' style={{ width: '13.66667%' }}>   <button className="btn btn-primary" onClick={() => this.AllDataSubmitToUpdate()} >
                                                Update This Asset
                                            </button></div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Drawer>


            </div>
        );
    }
}

export default GeneratorDetails;