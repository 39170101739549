import React, { Component } from 'react'
import ReactApexChart from 'react-apexcharts'

class ColumnCharts extends Component {
    constructor(props) {
        super(props);
        this.state = {

            series: [{
                name: 'Net Profit',
                data: [44, 55, 57, 56, 61, 58]
              }, {
                name: 'Revenue',
                data: [76, 85, 101, 98, 87, 105]
              }, {
                name: 'Free Cash Flow',
                data: [35, 41, 36, 26, 45, 48]
              }],
              options: {
                chart: {
                  type: 'bar',
                  height: 350
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                  },
                },
                dataLabels: {
                  enabled: false
                },
                stroke: {
                  show: true,
                  width: 2,
                  colors: ['transparent']
                },
                xaxis: {
                  categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
                },
                yaxis: {
                  title: {
                    text: '$ (thousands)'
                  }
                },
                fill: {
                  opacity: 1
                },
                tooltip: {
                  y: {
                    formatter: function (val) {
                      return "$ " + val + " thousands"
                    }
                  }
                }
              },
            
            
        };
    }
    componentDidMount() {

      
    }

    render() {
        return (
            <div id="chart">
                  <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
            </div>
        )
    }
}
export default ColumnCharts;