import React, { Component } from "react";
import DepotGeneral from "./depot-general";
import AssetDetails from "../../components/site_components/asset_details";
import { withRouter } from "react-router-dom";
import { getDepotDetails, getSitesByDepotId } from "../../api/index";

// import GoogleMapReact from "google-map-react";

const AnyReactComponent = ({ text }) => (
  <div>
    <i
      style={{ color: "#ff0000", fontSize: 20 }}
      class="fas fa-map-marker-alt"
      title={text}
    ></i>
  </div>
);

class DepotSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      site_details: {},
      site_list: {},
      center: {
        lat: 7.244444,
        lng: 80.6375,
      },
      zoom: 8,
    };
  }

  componentDidMount() {
    this.getSiteData();
    this.getSitesByDepotId();

  }
  getSitesByDepotId = async () => {
    var res = await getSitesByDepotId(this.props.match.params.id);
    console.log(res);
    if (res.success) {
      this.setState(
        {
          site_list: res.data,
          // zoom: 9,
          center: {
            lat: parseFloat(res.data[0]?.siteAddressList[0]?.latitude),
            lng: parseFloat(res.data[0]?.siteAddressList[0]?.longitude),
          },
        },
        () => {
          //console.log(this.state.center);
        }
      );
    }
  };

  getSiteData = async () => {
    var res = await getDepotDetails(this.props.match.params.id);
    console.log(res);
    if (res.success) {
      this.setState(
        {
          site_details: res.data,
        },
        () => {
          //console.log(this.state.center);
        }
      );
    }
  };

  render() {
    const { site_details } = this.state;

    return (
      <div className="container-fluid">

        <div className="row">
          <div className="col-sm-12">
            <div className="page-title-box">
              <div className="row">
                <div className="col">
                  <h4 className="page-title">Depot Summary</h4>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="javascript:void(0);">Depot</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="javascript:void(0);">{site_details.name}</a>
                    </li>
                    <li className="breadcrumb-item active">
                      {site_details.id}
                    </li>
                  </ol>
                </div>
                <div className="col-auto align-self-center">
                  <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                    <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                    <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                  </a>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body p-0">

              </div>
              <div class="card-body">
                <div class="dastone-profile">
                  <div class="row">
                    <div class="col-lg-4 align-self-center mb-3 mb-lg-0">
                      <div class="dastone-profile-main">
                        <div class="dastone-profile-main-pic">
                          <img src={'https://www.shareicon.net/data/256x256/2016/08/18/815134_wireless_512x512.png'} alt="" height="110" class="rounded-circle"></img>
                          <span class="dastone-profile_main-pic-change">
                            <i class="fas fa-camera"></i>
                          </span>
                        </div>
                        <div class="dastone-profile_user-detail">
                          <h5 class="dastone-user-name">{site_details.name}</h5>
                          <p class="mb-0 dastone-user-name-post">{site_details.description}</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 ms-auto align-self-center">
                      <ul class="list-unstyled personal-detail mb-0">
                        <li class=""><i class="ti ti-mobile me-2 text-secondary font-16 align-middle"></i> <b>Organization </b> :{site_details.region?.organization?.name}</li>
                        <li class="mt-2"><i class="ti ti-email text-secondary font-16 align-middle me-2"></i> <b> Region </b> : {site_details.region?.parentRegion?.name}</li>
                        <li class="mt-2"><i class="ti ti-world text-secondary font-16 align-middle me-2"></i> <b>Sub Region </b> : {site_details.region?.name}      </li>
                      </ul>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
          <div class="pb-4">
                            <ul  class="nav-border nav nav-pills mb-0" id="pills-tab" role="tablist" style={{fontSize:13}}>
                                <li className="nav-item">
                                    <a style={{padding:8}} className="nav-link active" data-bs-toggle="tab" href="#home" role="tab" aria-selected="false">Site List</a>
                                </li>
                                {/* <li className="nav-item">
                                            <a className="nav-link" data-bs-toggle="tab" href="#profile" role="tab" aria-selected="false">Assets</a>
                                        </li>  */}
                                {/* <li className="nav-item">
                                    <a style={{padding:8}} className="nav-link" data-bs-toggle="tab" href="#profile" role="tab" aria-selected="false">Image</a>
                                </li>        */}
                                
                                {/* <li className="nav-item">
                                            <a className="nav-link" data-bs-toggle="tab" href="#settings" role="tab" aria-selected="true">Services</a>
                                        </li> */}
                                {/* <li className="nav-item">
                                            <a className="nav-link" data-bs-toggle="tab" href="#req" role="tab" aria-selected="true">Access Requests</a>
                                        </li> */}
                                {/* <li className="nav-item">
                                            <a className="nav-link " data-bs-toggle="tab" href="#Payments" role="tab" aria-selected="true">Payments</a>
                                        </li> */}
                                {/* <li className="nav-item">
                                            <a className="nav-link " data-bs-toggle="tab" href="#Submitions" role="tab" aria-selected="true">CEA Submitions</a>
                                        </li> */}

                            </ul>
                        </div>
            {/* <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-bs-toggle="tab"
                  href="#home"
                  role="tab"
                  aria-selected="false"
                >
                  General
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#profile"
                  role="tab"
                  aria-selected="false"
                >
                  Assets
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#settings"
                  role="tab"
                  aria-selected="true"
                >
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#req"
                  role="tab"
                  aria-selected="true"
                >
                  Access Requests
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link "
                  data-bs-toggle="tab"
                  href="#Payments"
                  role="tab"
                  aria-selected="true"
                >
                  Payments
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link "
                  data-bs-toggle="tab"
                  href="#Submitions"
                  role="tab"
                  aria-selected="true"
                >
                  CEA Submitions
                </a>
              </li>
            </ul> */}

            <div className="tab-content">
              <div className="tab-pane p-3 active" id="home" role="tabpanel">
                {Object.keys(this.state.site_list).length != 0 && <DepotGeneral details={this.state.site_list} />}
              </div>
              <div className="tab-pane p-3" id="profile" role="tabpanel">
                <AssetDetails />
              </div>
              <div className="tab-pane p-3 " id="settings" role="tabpanel">
                <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <h4 style={{ color: "#c5c5c5" }}>No Dat Found</h4></div>
              </div>
              <div className="tab-pane p-3 " id="Payments" role="tabpanel">
                <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <h4 style={{ color: "#c5c5c5" }}>No Dat Found</h4></div>
              </div>
              <div className="tab-pane p-3 " id="Submitions" role="tabpanel">
                <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <h4 style={{ color: "#c5c5c5" }}>No Dat Found</h4></div>
              </div>
              <div className="tab-pane p-3 " id="req" role="tabpanel">
                <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <h4 style={{ color: "#c5c5c5" }}>No Dat Found</h4></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(DepotSummary);
