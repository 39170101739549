import React, { Component } from "react";
import { connect } from "react-redux";
import { getDonationListData } from "../actions/index";
import { getDonationCategories, getData } from "../actions/index";
import { getPgData } from "../api/index";
import Pg from "../components/pg/pg";
import { Table, Input, Tag, Space } from "antd";
import SimpleReactValidator from "simple-react-validator";

import {
  RightOutlined,
  DownOutlined,
  BorderOuterOutlined,
  TeamOutlined,
  AppstoreOutlined,
  DeploymentUnitOutlined,
  ClusterOutlined,
} from "@ant-design/icons";

class Inbox extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      // filterTable: null,
      baseData: "",
      pgData: "",
      pgPendingCount: 0,
      filterTable: null,
      isPgSelected: false,
      selectedPgData: null,
      loading: false,
    };
  }
  organizationList() {
    //console.log("kk");
  }
  componentDidMount() {
    this.setState({
      loading: true,

    });
    this.getPgData();
  }

  getPgData = async () => {

    var res = await getPgData();
    //  console.log(res.data.requests);

    if (res.success) {
      this.setState({
        pgData: res.data.requests,
        loading: false
      });
    }
  };

  formItemChange = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
    //console.log(obj);
  };

  formItemChecked = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.checked;
    this.setState(obj);
    //console.log(obj);
  };
  search = (value) => {
    const { baseData } = this.state;
    //console.log("PASS", { value });

    const filterTable = this.state.pgData.filter((o) => {
      //console.log((o));
      return (
        Object.keys(o).some((k) => {
          //console.log(o[k]);
          return String(o[k]).toLowerCase().includes(value.toLowerCase()) || (o[k].requestCode && o[k].requestCode.toLowerCase() == value.toLowerCase());
        })
      );
    });

    this.setState({ filterTable });
  };
  filterRegions = (value, region) => {
    var rr = region.filter((d) => d.name.includes(value));
    //console.log(rr);
    return rr.length > 0;
  };
  render() {
    const { filterTable, pgData } = this.state;

    //console.log(filterTable)

    const columns = [
      {
        title: "#",
        dataIndex: "id",
        defaultSortOrder: "ascend",
        sorter: (a, b) => a.id - b.id,
        sortDirections: ["descend"],
        render: (text, record, index) => <span>{text}</span>,
      },
      {
        title: "Type",
        dataIndex: "id",
        defaultSortOrder: "ascend",
        sortDirections: ["descend"],
        render: (item) => <>
          <Tag style={{ fontSize: 10 }} color="#03d87f" key={item}>
            PG
          </Tag>
        </>,
      },
      {
        title: "Request Time",
        dataIndex: "serviceRequest",
        key: "serviceRequest",
        //sorter: (a, b) => a.name.localeCompare(b.name),
        render: (item) => <span>{item.requestDate} {item.requestTime}</span>,
      },
      // {
      //   title: "Request Time",
      //   dataIndex: "serviceRequest",
      //   key: "serviceRequest",
      //   //sorter: (a, b) => a.name.localeCompare(b.name),
      //   render: (item) => <span>{item.requestTime}</span>,
      // },

      {
        title: "Site",
        dataIndex: "serviceRequest",
        key: "serviceRequest",
        //sorter: (a, b) => a.name.localeCompare(b.name),
        render: (item) => (
          <>
            <Tag style={{ fontSize: 10 }} color="gray" key={item}>
              {item.site.siteName.toUpperCase()}
            </Tag>
          </>
        ),
      },
      {
        title: "Site Id",
        dataIndex: "serviceRequest",
        key: "serviceRequest",
        //sorter: (a, b) => a.name.localeCompare(b.name),
        render: (item) => (
          <>
            <Tag style={{ fontSize: 10 }} color="gray" key={item}>
              {item.site.siteId.toUpperCase()}
            </Tag>
          </>
        ),
      },
      {
        title: "Code",
        dataIndex: "serviceRequest",
        key: "serviceRequest",
        //sorter: (a, b) => a.name.localeCompare(b.name),
        render: (item) => <span>{item.requestCode}</span>,
      },
      // {
      //   title: "Status",
      //   dataIndex: "pgStatus",
      //   key: "pgStatus",
      //   //sorter: (a, b) => a.name.localeCompare(b.name),

      //   render: (item) => (
      //     <>
      //       <Tag style={{ fontSize: 10 }} color="success" key={item}>
      //         {item.name.toUpperCase()}
      //       </Tag>
      //     </>
      //   ),
      // },
    ];


    return (
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="page-title-box">
              <div class="row">
                <div class="col">
                  <h4 class="page-title">Inbox</h4>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0);">Workspace</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0);">Inbox</a></li>

                  </ol>
                </div>
                <div class="col-auto align-self-center">
                  <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                    <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                    <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                  </a>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            {/* <!-- Left sidebar -->*/}
            <div class="email-leftbar">
              {/* <button type="button" class="btn btn-primary btn-sm w-100 waves-effect waves-light" data-bs-toggle="modal" data-animation="bounce" data-bs-target=".compose-modal">
                                    <i class="fas fa-feather-alt me-2"></i>Compose
                                </button> */}

              <div class="card mt-3">
                <div class="card-body">
                  <div class="mail-list">
                    <a href="#" class="active pt-0"><i class="las la-inbox font-15 me-1"></i>Inbox <span class="ms-1">{filterTable == null ? pgData.length : filterTable.length}</span></a>
                    {/* <a href="#"><i class="las la-star font-15 me-1"></i>Starred</a>
                                            <a href="#"><i class="las la-tag font-15 me-1"></i>Important</a>
                                            <a href="#"><i class="las la-file-alt font-15 me-1"></i>Draft</a>
                                            <a href="#"><i class="las la-paper-plane font-15 me-1"></i>Sent Mail</a>
                                            <a href="#"><i class="las la-trash-alt font-15 me-1"></i>Trash</a> */}
                  </div>

                  <p class="text-dark my-4 fw-bold">Labels</p>
                  <div class="mail-list">
                    <a href="#"><span class="mdi mdi-arrow-right-drop-circle text-success float-end"></span>PG</a>
                    <a href="#"><span class="mdi mdi-arrow-right-drop-circle text-info float-end"></span>Refuel</a>
                    <a href="#"><span class="mdi mdi-arrow-right-drop-circle text-warning float-end"></span>PIS</a>
                    <a href="#"><span class="mdi mdi-arrow-right-drop-circle text-purple float-end"></span>Vehicle Management</a>
                    <a href="#"><span class="mdi mdi-arrow-right-drop-circle text-pink float-end"></span>Vehicle Pick</a>



                  </div>

                  {/* <p class="text-dark my-4 fw-bold">Chat</p>
                                        <div class="">
                                            <a href="#" class="media">
                                                <img class="d-flex me-3 rounded-circle" src="assets/images/users/user-1.jpg" alt="Generic placeholder image" height="36"/>
                                                <div class="media-body chat-user-box">
                                                    <p class="user-title m-0">Scott Median</p>
                                                    <p class="text-muted">Hello</p>
                                                </div>
                                            </a>
        
                                            <a href="#" class="media">
                                                <img class="d-flex me-3 rounded-circle" src="assets/images/users/user-2.jpg" alt="Generic placeholder image" height="36"/>
                                                <div class="media-body chat-user-box">
                                                    <p class="user-title m-0">Julian Rosa</p>
                                                    <p class="text-muted">What about our next..</p>
                                                </div>
                                            </a>
        
                                            <a href="#" class="media">
                                                <img class="d-flex me-3 rounded-circle" src="assets/images/users/user-3.jpg" alt="Generic placeholder image" height="36"/>
                                                <div class="media-body chat-user-box">
                                                    <p class="user-title m-0">David Medina</p>
                                                    <p class="text-muted">Yeah everything is fine</p>
                                                </div>
                                            </a>
        
                                            <a href="#" class="media">
                                                <img class="d-flex me-3 rounded-circle" src="assets/images/users/user-4.jpg" alt="Generic placeholder image" height="36"/>
                                                <div class="media-body chat-user-box">
                                                    <p class="user-title m-0">Jay Baker</p>
                                                    <p class="text-muted">Wow that's great</p>
                                                </div>
                                            </a>
                                        </div> */}
                </div> {/* <!-- end card-body -->*/}
              </div> {/* <!-- end card -->*/}
            </div>
            {/* <!-- End Left sidebar -->*/}


            {/* <!-- Right Sidebar -->*/}
            <div class="email-rightbar">
              <div class="float-end d-flex justify-content-between">
                <div class="btn-group ms-1">
                  <button type="button" class="btn btn-sm btn-outline-secondary waves-light waves-effect dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="fas fa-question-circle"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-end">
                    <a class="dropdown-item" href="#">Updates</a>
                    <a class="dropdown-item" href="#">Social</a>
                    <a class="dropdown-item" href="#">Team Manage</a>
                  </div>
                </div>
                <div class="btn-group ms-1">
                  <button type="button" class="btn btn-sm btn-outline-secondary waves-light waves-effect dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="fas fa-cog"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-end">
                    <a class="dropdown-item" href="#">Display density</a>
                    <a class="dropdown-item" href="#">Themes</a>
                    <a class="dropdown-item" href="#">Help</a>
                  </div>
                </div>
              </div> {/* <!-- end div -->*/}
              <div class="btn-toolbar" role="toolbar">
                <div class="btn-group">
                  <button type="button" class="btn btn-sm btn-outline-secondary waves-light waves-effect"><i class="fas fa-inbox"></i></button>
                  <button type="button" class="btn btn-sm btn-outline-secondary waves-light waves-effect"><i class="fas fa-exclamation-circle"></i></button>
                  <button type="button" class="btn btn-sm btn-outline-secondary waves-light waves-effect"><i class="fas fa-trash"></i></button>
                </div>
                <div class="btn-group ms-1">
                  <button type="button" class="btn btn-sm btn-outline-secondary waves-light waves-effect dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="fas fa-folder"></i><i class="mdi mdi-chevron-down ms-1"></i>
                  </button>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" href="#">Updates</a>
                    <a class="dropdown-item" href="#">Social</a>
                    <a class="dropdown-item" href="#">Team Manage</a>
                  </div>
                </div>
                <div class="btn-group ms-1">
                  <button type="button" class="btn btn-sm btn-outline-secondary waves-light waves-effect dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="fas fa-tag"></i><i class="mdi mdi-chevron-down ms-1"></i>
                  </button>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" href="#">Updates</a>
                    <a class="dropdown-item" href="#">Social</a>
                    <a class="dropdown-item" href="#">Team Manage</a>
                  </div>
                </div>

                <div class="btn-group ms-1">
                  <button type="button" class="btn btn-sm btn-outline-secondary waves-light waves-effect dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    More<i class="mdi mdi-chevron-down ms-1"></i>
                  </button>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" href="#">Mark as Unread</a>
                    <a class="dropdown-item" href="#">Mark as Important</a>
                    <a class="dropdown-item" href="#">Add to Tasks</a>
                    <a class="dropdown-item" href="#">Add Star</a>
                    <a class="dropdown-item" href="#">Mute</a>
                  </div>
                </div>
              </div>
              {/*  {/* <!-- end toolbar -->*/}


              <div class="card my-3">
                <Input
                  enterButton
                  style={{ marginBottom: 5 }}
                  placeholder="Search ..."
                  onChange={(e) => {
                    const currValue = e.target.value;
                    this.search(currValue);
                  }}
                />

                <Table
                  size="small"
                  fontSize={9}
                  columns={columns}
                  loading={this.state.loading}
                  dataSource={filterTable == null ? pgData : filterTable}
                />
              </div>


              {/* <!-- panel -->*/}

              <div class="row mb-3">
                {/* <div class="col-7 align-self-center">
                                        Showing 1 - 20 of 1,524
                                    </div> */}
                {/* <!-- end Col -->*/}

              </div>  {/* <!--end row-->*/}
            </div>  {/* <!-- end email-rightbar -->*/}
          </div> {/* <!-- end Col -->*/}
        </div>
      </div>
    );
  }
}

export default Inbox;
