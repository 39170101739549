import React, { Component } from 'react'
import {
    getByCode, updataRemoveTimeInPg
} from "../../api/index";
import { DatePicker, TimePicker, Input, Drawer, Form, InputNumber, message } from "antd";

const moment = require('moment');
const format = 'HH:mm';

class PgView extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selectReqCode: null,
            selectPgReqData: null,
            openRemovelTimeEdit: false,
            selectRemovedTime: null,
            selectRemovedData: null
        }
        this.handleRequestCode = this.handleRequestCode.bind(this);
    }
    handleRequestCode(e) {
        console.log(e.target.value)
        this.setState({ selectReqCode: e.target.value });
    }
    removedTimeEdite = () => {
        console.log("search123");
        this.setState({
            openRemovelTimeEdit: true
        })




    };
    onChangeARemovedData = (date, dateString) => {
        console.log(date);
        console.log(dateString);
        this.setState({
            selectRemovedData: dateString,
        });
    };
    onChangeARemovedTime = (date, dateString) => {
        console.log(date);
        console.log(dateString);

        this.setState({
            selectRemovedTime: dateString,
        });
    };
    onDrawerClose = () => {
        console.log('sddsds')
        this.formRef.current.resetFields();
        //  this.formRef.current.resetFields();
        this.setState({
            openRemovelTimeEdit: false,

        })

    }
    searchClickNew = () => {
        console.log("search");

        if (this.state.selectReqCode !== null && this.state.selectReqCode !== '') {
            console.log(this.state.selectReqCode);
            this.getDataByCode(this.state.selectReqCode)
        }


    };
    componentDidMount() {
        console.log('ert')
        // this.getDataByCode('DNS-PG-MT0124-20210922094310')

    }
    getDataByCode = async (data) => {

        this.setState({
            selectPgReqData: null,
            loading: true
        })
        //console.log("getAtsData1");

        var res = await getByCode(data);
        console.log("getByCode");
        console.log(res);
        if (res.success) {
            this.setState({
                selectPgReqData: res.data,
                loading: false
            })

        }else{
             this.setState({
            selectPgReqData: null,
            loading: false
        })
        }
    };
    removeTimeInPgUpdata = async () => {
        console.log(this.state.selectPgReqData?.serviceRequest?.id);
        var data = new FormData();
        data.append("serviceRequestId", this.state.selectPgReqData?.serviceRequest?.id);
        data.append("removedTime", this.state.selectRemovedData + ' ' + this.state.selectRemovedTime);

        var res = await updataRemoveTimeInPg(data);
        console.log("updataRemoveTimeInPg");
        console.log(res);
        if (res.success) {

            this.onDrawerClose();
            this.getDataByCode(this.state.selectReqCode)
            message.success('Update Successful !')


        }
    };
    removedDataTimeSubmit = async () => {
        if (this.state.selectRemovedData !== null && this.state.selectRemovedTime !== null) {
            this.removeTimeInPgUpdata();
        }

    }
    render() {
        return (
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="page-title-box">
                            <div class="row">
                                <div class="col">
                                    <h4 class="page-title">Update Removed Time in PG</h4>
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">PG </a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Update Remove Time</a></li>

                                    </ol>
                                </div>
                                <div class="col-auto align-self-center">
                                    <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row' style={{ paddingBottom: 8 }}>

                    <div class="col-md-4">
                        <Input placeholder="Request Code" onChange={this.handleRequestCode} />

                    </div>



                    <div className="col-md-3 col-lg-3 col-xl-3">
                        <button
                            onClick={this.searchClickNew}
                            class="btn btn-primary"
                        //style={{ float: "right" }}
                        >
                            <i class="fas fa-search"> </i>Search
                        </button>

                    </div>
                </div>
                {this.state.selectPgReqData != null ? (
                    <>
                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-body p-0">

                                    </div>
                                    <div class="card-body">
                                        <div class="dastone-profile">
                                            <div class="row">
                                                <div class="col-lg-6 align-self-center mb-3 mb-lg-0">
                                                    <div class="dastone-profile-main">
                                                        <div class="dastone-profile-main-pic">
                                                            <img src={'https://www.shareicon.net/data/256x256/2016/08/18/815134_wireless_512x512.png'} alt="" height="110" class="rounded-circle"></img>
                                                            {/* <span class="dastone-profile_main-pic-change">
                                                                
                                                            </span> */}
                                                        </div>
                                                        <div class="dastone-profile_user-detail">
                                                            <h5 class="dastone-user-name">{this.state.selectPgReqData?.serviceRequest?.site?.siteName}</h5>
                                                            <p class="mb-0 dastone-user-name-post" style={{fontSize:12}}>{this.state.selectPgReqData?.serviceRequest?.site?.siteId}</p>
                                                            <p class="mb-0 dastone-user-name-post" style={{fontSize:9}}>{this.state.selectPgReqData?.address}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 ms-auto align-self-center">
                                                    <ul class="list-unstyled personal-detail mb-0">
                                                        <li class=""><i class="ti ti-agenda me-2 text-secondary font-16 align-middle"></i> <b>Code </b> :{this.state.selectPgReqData?.serviceRequest?.requestCode}</li>
                                                        <li class="mt-2"><i class="ti ti-pulse text-secondary font-16 align-middle me-2"></i> <b> Service Status </b> : {this.state.selectPgReqData?.serviceRequest?.serviceStatus?.name}</li>
                                                        <li class="mt-2"><i class="ti ti-world text-secondary font-16 align-middle me-2"></i> <b>Service Type </b> : {this.state.selectPgReqData?.serviceRequest?.serviceType?.name}     </li>
                                                    </ul>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-lg-6 col-xl-5">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <h4 className="card-title">PG Information</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <ul class="list-group">
                                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                                        <div>
                                                      
                                                            <i class="la la-calendar-plus text-muted font-18 me-2"></i>Created On
                                                        </div>

                                                        <span class="terw badge badge-outline-secondary badge-pill">{moment((this.state.selectPgReqData?.serviceRequest?.createdOn)).format('YYYY-MM-DD HH:mm:ss')} </span>
                                                    </li>
                                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                                        <div>
                                                
                                                            <i class="la la-calendar-check text-muted font-16 me-2"></i>Request Date & Time
                                                        </div>
                                                        <span class="terw badge badge-outline-primary badge-pill">{this.state.selectPgReqData?.serviceRequest?.requestDate} {this.state.selectPgReqData?.serviceRequest?.requestTime}</span>
                                                    </li>
                                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                                        <div>
                                                     
                                                            <i class="la la-calendar-minus text-muted font-16 me-2"></i>Remove Request Time
                                                        </div>
                                                        <span class="terw badge badge-outline-success badge-pill">{this.state.selectPgReqData?.removeRequestTime !== null ? (moment((this.state.selectPgReqData?.removeRequestTime)).format('YYYY-MM-DD HH:mm:ss')) : ('N/A')}</span>
                                                    </li>

                                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                                        <div>
                                                       
                                                            <i class="la la-calendar-times text-muted font-16 me-2"></i>Removed Time
                                                            {this.state.selectPgReqData?.removedTime !== null ? (<a href='#r' onClick={() => this.removedTimeEdite()} >
                                                           
                                                                <i class="fa fa-edit" tooltip="hello world" style={{ paddingLeft: 6 }}></i>
                                                            </a>) : ('')}

                                                        </div>
                                                        <span class="terw badge badge-outline-warning badge-pill">{this.state.selectPgReqData?.removedTime !== null ? (moment((this.state.selectPgReqData?.removedTime)).format('YYYY-MM-DD HH:mm:ss')) : ('N/A')} </span>
                                                    </li>
                                                    {/*<li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <i class="la la-box text-muted font-16 me-2"></i>Site Category
                                                    </div>
                                                    <span class="badge badge-outline-warning">{site_details?.siteCategory?.name}</span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <i class="la la-align-center text-muted font-16 me-2"></i>Site Type
                                                    </div>
                                                    <span class="badge badge-outline-info badge-pill">{site_details?.siteType?.name}</span>
                                                </li>
												<li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <i class="la la-battery text-muted font-18 me-2"></i>Energy Category
                                                    </div>
                                                    
                                                    <span class="badge badge-outline-secondary badge-pill">{site_details?.energyCategory?.name}</span>
                                                </li>
												<li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <i class="la la-award text-muted font-16 me-2"></i>Entity
                                                    </div>
                                                    <span class="badge badge-outline-success badge-pill">{site_details?.entity?.name}</span>
                                                </li>
												<li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <i class="la la-rss text-muted font-16 me-2"></i>Indoor/Outdoor
                                                    </div>
                                                    <span class="badge badge-outline-info badge-pill">{site_details?.indoorOutdoor==''?('N/A'):(site_details?.indoorOutdoor)}</span>
                                                </li>
												<li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <i class="la la-money text-muted font-18 me-2"></i>Shelter Type
                                                    </div>
                                                    
                                                    <span class="badge badge-outline-warning">{site_details?.shelterType?.name}</span>
                                                </li>
												<li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <i class="la la-clock text-muted font-16 me-2"></i>Accessible Hours
                                                    </div>
                                                    <span class="badge badge-outline-success badge-pill">{site_details?.weekendsOrHolidaysAccessibleHours}</span>
                                                </li> */}
                                                </ul>
                                            </div>
                                        </div>





                                    </div>
                                </div>
                            </div>

                        </div>
                    </>
                ) : (
                    <>
                        {this.state.loading ? (<div class="col-lg-12" style={{ textAlign: 'center' }}>

                            <div class="spinner-border spinner-border-custom-2 text-primary" role="status"></div>



                        </div>) : (<div class="ant-empty ant-empty-normal"><div class="ant-empty-image"><svg class="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fill-rule="evenodd"><ellipse class="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse><g class="ant-empty-img-simple-g" fill-rule="nonzero"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" class="ant-empty-img-simple-path"></path></g></g></svg></div><div class="ant-empty-description">No Data</div></div>)}

                    </>)}
                <Drawer title='Update Remove Time' width={"30%"} placement="right" onClose={() => this.onDrawerClose()} visible={this.state.openRemovelTimeEdit}>

                    <div className='row'>
                        <Form ref={this.formRef} >
                            <div className="col-md-12">
                                <label className="form-label" for="depot" style={{ fontSize: 14 }}>
                                    Current Removed Time
                                </label>
                                <p style={{ fontSize: 15 }}>{this.state.selectPgReqData?.removedTime !== null ? (moment((this.state.selectPgReqData?.removedTime)).format('YYYY-MM-DD HH:mm:ss')) : ('N/A')} </p>


                            </div>
                            <div className="row">
                                <div className="col-md-8" style={{ marginBottom: 22 }}>
                                    <label className="form-label" for="depot">
                                        Removed Date <x style={{ color: "red" }}>*</x>
                                    </label>
                                    <Form.Item required name="removed_data" tooltip="This is a required field">
                                        <DatePicker
                                            onChange={this.onChangeARemovedData}
                                            style={{ width: "100%" }}

                                        />
                                    </Form.Item>


                                </div>
                                <div className="col-md-4" style={{ marginBottom: 22 }}>
                                    <label className="form-label" for="depot">
                                        Removed Time <x style={{ color: "red" }}>*</x>
                                    </label>
                                    <Form.Item required name="removed_time" tooltip="This is a required field">
                                        <TimePicker style={{ width: "100%" }} onChange={this.onChangeARemovedTime} format={format} />
                                    </Form.Item>

                                </div>

                            </div>
                        </Form>
                    </div>
                    <div className='row' style={{justifyContent:'end'}}>
                        <div className='col-md-2' >   <button className="btn btn-primary" onClick={() => this.removedDataTimeSubmit()} >Submit</button></div>


                    </div>

                </Drawer>

            </div>
        )
    }
}
export default PgView;
