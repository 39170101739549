import React, { Component } from 'react'
import ReactApexChart from 'react-apexcharts'

 class StackedBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
          
            series: [{
              name: 'Cancelled',
              data: [44, 55, 41, 37, 22, 43, 21]
            }, {
              name: 'Install Pending',
              data: [53, 32, 33, 52, 13, 43, 32]
            }, {
              name: 'Installed',
              data: [12, 17, 11, 9, 15, 11, 20]
            }, ],
            options: {
              chart: {
                type: 'bar',
                height: 350,
                bottom:0,
                stacked: true,
                toolbar: {
                    show:false
                }
              },
              
              // plotOptions: {
              //   bar: {
              //     horizontal: false,
              //     dataLabels: {
              //       total: {
              //         enabled: true,
              //         offsetX: 0,
              //         style: {
              //           fontSize: '13px',
              //           fontWeight: 900
              //         }
              //       }
              //     }
              //   },
              // },
              stroke: {
                width: 1,
                colors: ['#fff']
              },
             
              xaxis: {
                labels: {
                  rotate: -45
                },
                categories: [2008, 2009, 2010, 2011, 2012, 2013, 2014],
            
                
              },
              yaxis: {
                title: {
                  text: undefined
                },
              },
              tooltip: {
                intersect: false,
                y: {
                  formatter: function (val) {
                    return val;
                  }
                }
              },
              fill: {
                opacity: 1
              },
              legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
              }
            },
          
          
          };
    }
    componentDidMount() { 
      console.log(this.props)
      this.setState({
        series:this.props.y1,
        options: {
          chart: {
            type: 'bar',
            height: '300',
            parentHeightOffset:0,
            stacked: true,
            toolbar: {
                show:false
            }
          },
          dataLabels: {
            enabled: true,
            background: {
                enabled: false,
                foreColor: '#fff',
                padding: 2,
                borderRadius: 2,
                borderWidth: 1,
                borderColor: '#fff',
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
              dataLabels: {
                total: {
                  enabled: true,
                  offsetX: 0,
                  style: {
                    fontSize: '13px',
                    fontWeight: 900
                  }
                }
              }
            },
          },
          stroke: {
            width: 1,
            colors: ['#fff']
          },
          xaxis: {
            tickAmount: 10,
            grid: {
              padding: {
                left: 0,
                right: 0
              }
            },
            categories:this.props.x1,
            labels: {
              rotate:-0,
              tickAmount: 10,
              formatter: function (val) {
                return val;
              }
            },
          },
          yaxis: {
            title: {
              text:this.props.yLabale
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          }
        },
      })


    }
    
  render() {
    return (
        <div id="chart">
        <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={290} />
      </div>
    )
  }
}

export default StackedBar;
