import React, { useState, useEffect } from 'react';
import {
    CarOutlined,
    NodeIndexOutlined,
    EditOutlined,
    DeleteOutlined,
    EyeOutlined,
    CheckOutlined,
    DashboardOutlined,
    SearchOutlined,
    PhoneOutlined,
    PaperClipOutlined,
    UploadOutlined,
    DownloadOutlined,
    CloudSyncOutlined
} from '@ant-design/icons';
import { Checkbox, Avatar, Card, Skeleton, Switch, Table, Select, Input, Button, Row, Upload, Col, Dropdown, Tag, Modal, Steps, message, Form, DatePicker, TimePicker, Descriptions, Image, Empty, Spin} from 'antd';
import {
    getAllActiveVehicleOwner,
    getAllMainRegions,
    getVehicleListFullExport,
    getSessionFullExport,
    getVehicleSummaryReport,
    getMonthlySummaryReport,
    getAccessTeamExportDataREWAMP,
    getAccessFullExportDataREWAMP,
    getRegionExportDataREWAMP
} from "../../api/index";
import './style.css';
import xlsx from 'xlsx';
import jsonfile from 'jsonfile';
import fs from 'fs';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

const { Option } = Select;
const { RangePicker } = DatePicker;

const MasterdataExport = () => {
    const [expoertCategory, setExportCategory] = useState('1');
    const [startAndEndDate, setStartAndEndDate] = useState([]);
    const [exportFormat, setExportFormat] = useState('1');
    const [regionList, setRegionList] = useState([]);
    const [vendorName, setVendorName] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [searchData, setSearchData] = useState(null);
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState([]);
    const [mappedData, setMappedData] = useState([]);
    const [buttonVisible, setButtonVisible] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState([]);
    const [isStillLoading, setIsStillLoading] = useState(false);

    useEffect(() => {
        // fetchAllRegions();
        // fetchVehicleOwner();
    }, []);

    // getRegionExportDataREWAMP
    const fetchMasterData = async (type) => {
        setFetching(true);
        const response = await getRegionExportDataREWAMP(type);
        if (response.success) {
            setSearchData(response.data);
            setFetching(false);
            if (expoertCategory === 'vendor') {
                setMappedData(vendorExportMap(response.data));
            } else if (expoertCategory === 'region') {
                setMappedData(regionDataMap(response.data));
            } else if (expoertCategory === 'vendorTeam') {
                setMappedData(vendorTeamDataMap(response.data));
            } else if (expoertCategory === 'depot') {
                setMappedData(depotDataMap(response.data));
            } else {
                message.error("Failed to fetch data");
            }
            setButtonVisible(true);
        } else {
            message.error("Failed to fetch data");
            setFetching(false);
        }
    }

    const prepareDownloadData = () => {
        const mapDataSet = mappedData;
        if (expoertCategory === 'vendor') {
            exportData(mapDataSet, "Vendor Export-");
        } else if (expoertCategory === 'region') {
            exportData(mapDataSet, "Region Export-");
        } else if (expoertCategory === 'vendorTeam') {
            exportData(mapDataSet, "Vendor Team Export-");
        } else if (expoertCategory === 'depot') {
            exportData(mapDataSet, "Depot Export-");
        }
    }

    const reverseJsonKeys = (data) => {
        const reversedData = {};
        const keys = Object.keys(data);
        for (let i = keys.length - 1; i >= 0; i--) {
            reversedData[keys[i]] = data[keys[i]];
        }
        return reversedData;
    };

    const vendorExportMap = (apiData) => {
        return apiData?.map(item => ({
            "Vendor Name": item?.name ? item.name : "N/A",
            "Contact Number": item?.contactNumber ? item.contactNumber : "N/A",
            "Contact Person": item?.contactPerson ? item.contactPerson : "N/A",
            "Contact Address": item?.contactAddress ? item.contactAddress : "N/A",
            "VAT Number": item?.vatNumber ? item.vatNumber : "N/A",
            "Contact Email": item?.contactEmail ? item.contactEmail : "N/A",
        }));
    }

    const vendorExportCols = [
        {
            title: <span className='textStyles-small'>Vendor Name</span>,
            dataIndex: 'Vendor Name',
            key: 'Vendor Name',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
        {
            title: <span className='textStyles-small'>Contact Number</span>,
            dataIndex: 'Contact Number',
            key: 'Contact Number',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
        {
            title: <span className='textStyles-small'>Contact Person</span>,
            dataIndex: 'Contact Person',
            key: 'Contact Person',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
        {
            title: <span className='textStyles-small'>Contact Address</span>,
            dataIndex: 'Contact Address',
            key: 'Contact Address',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
        {
            title: <span className='textStyles-small'>VAT Number</span>,
            dataIndex: 'VAT Number',
            key: 'VAT Number',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
        {
            title: <span className='textStyles-small'>Contact Email</span>,
            dataIndex: 'Contact Email',
            key: 'Contact Email',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
    ];

    const regionDataMap = (apiData) => {
        let exportArr = [];
        apiData.forEach(item => {
            let regionalManagerArrLength = item?.regionalManagers?.length || 0;
            if (regionalManagerArrLength > 0) {
                let isFirstIteration = true;
                item.regionalManagers.forEach(manager => {
                    const managerData = {
                        "Manager Name": manager?.name || "N/A",
                        "Manager Email": manager?.email || "N/A",
                        "Manager Mobile": manager?.mobileNumber || "N/A",
                        "Manager NIC": manager?.nic || "N/A",
                        "Manager User Id": manager?.userId || "N/A",
                        "Manager Organization": manager?.organization?.name || "N/A",
                        "Manager Organization Description": manager?.organization?.description || "N/A",
                    };
                    if (isFirstIteration) {
                        managerData["Region Name"] = item?.name || "N/A";
                        managerData["Parent Region"] = item?.parentRegion || "N/A";
                        managerData["Is Sub Region"] = item?.isSubRegion !== undefined ? item.isSubRegion === 0 ? "No" : "Yes" : "N/A";
                        managerData["Is Live Region"] = item?.isLiveRegion !== undefined ? item.isLiveRegion === true ? "Yes" : "No" : "N/A";
                        isFirstIteration = false;
                    }
                    exportArr.push(managerData);
                });
            } else {
                exportArr.push({
                    "Region Name": item?.name || "N/A",
                    "Parent Region": item?.parentRegion || "N/A",
                    "Is Sub Region": item?.isSubRegion !== undefined ? item.isSubRegion === 0 ? "No" : "Yes" : "N/A",
                    "Is Live Region": item?.isLiveRegion !== undefined ? item.isLiveRegion === true ? "Yes" : "No" : "N/A",
                    "Manager Name": "N/A",
                    "Manager Email": "N/A",
                    "Manager Mobile": "N/A",
                    "Manager NIC": "N/A",
                    "Manager User Id": "N/A",
                    "Manager Organization": "N/A",
                    "Manager Organization Description": "N/A",
                });
            }
        });
        return exportArr;
    };

    const regionDataCols = [
        {
            title: <span className='textStyles-small'>Region Name</span>,
            dataIndex: 'Region Name',
            key: 'Region Name',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
        {
            title: <span className='textStyles-small'>Parent Region</span>,
            dataIndex: 'Parent Region',
            key: 'Parent Region',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
        {
            title: <span className='textStyles-small'>Is Sub Region</span>,
            dataIndex: 'Is Sub Region',
            key: 'Is Sub Region',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
        {
            title: <span className='textStyles-small'>Is Live Region</span>,
            dataIndex: 'Is Live Region',
            key: 'Is Live Region',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
        {
            title: <span className='textStyles-small'>Manager Name</span>,
            dataIndex: 'Manager Name',
            key: 'Manager Name',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
        {
            title: <span className='textStyles-small'>Manager Email</span>,
            dataIndex: 'Manager Email',
            key: 'Manager Email',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
        {
            title: <span className='textStyles-small'>Manager Mobile</span>,
            dataIndex: 'Manager Mobile',
            key: 'Manager Mobile',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
        {
            title: <span className='textStyles-small'>Manager NIC</span>,
            dataIndex: 'Manager NIC',
            key: 'Manager NIC',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
    ]

    const vendorTeamDataMap = (apiData) => {
        let exportArr = [];
        apiData.forEach(item => {
            let teamMembersArrLength = item?.teamMembers?.length || 0;
            if (teamMembersArrLength > 0) {
                let isFirstIteration = true;
                item.teamMembers.forEach(member => {
                    const memberData = {
                        "Member Organization Description": member?.organization?.description || "N/A",
                        "Member Organization": member?.organization?.name || "N/A",
                        "Member Email": member?.email || "N/A",
                        "Member Mobile": member?.mobileNumber || "N/A",
                        "Member NIC": member?.nic || "N/A",
                        "Member User Id": member?.userId || "N/A",
                        "Member Name": member?.name || "N/A",
                    };
                    if (isFirstIteration) {
                        memberData["Sub Region"] = item?.subRegion || "N/A";
                        memberData["Parent Region"] = item?.ParentRegion || "N/A";
                        memberData["Vendor"] = item?.vender || "N/A";
                        isFirstIteration = false;
                    }
                    exportArr.push(reverseJsonKeys(memberData));
                });
            } else {
                exportArr.push({
                    "Sub Region": item?.subRegion || "N/A",
                    "Parent Region": item?.ParentRegion || "N/A",
                    "Vendor": item?.vender || "N/A",
                    "Member Name": "N/A",
                    "Member Email": "N/A",
                    "Member Mobile": "N/A",
                    "Member NIC": "N/A",
                    "Member User Id": "N/A",
                    "Member Organization": "N/A",
                    "Member Organization Description": "N/A",
                });
            }
        });
        return exportArr;
    };

    const vendorTeamDataCols = [
        {
            title: <span className='textStyles-small'>Vendor</span>,
            dataIndex: 'Vendor',
            key: 'Vendor',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
        {
            title: <span className='textStyles-small'>Sub Region</span>,
            dataIndex: 'Sub Region',
            key: 'Sub Region',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
        {
            title: <span className='textStyles-small'>Parent Region</span>,
            dataIndex: 'Parent Region',
            key: 'Parent Region',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
        {
            title: <span className='textStyles-small'>Member Name</span>,
            dataIndex: 'Member Name',
            key: 'Member Name',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
        {
            title: <span className='textStyles-small'>Member Email</span>,
            dataIndex: 'Member Email',
            key: 'Member Email',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
        {
            title: <span className='textStyles-small'>Member Mobile</span>,
            dataIndex: 'Member Mobile',
            key: 'Member Mobile',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
        {
            title: <span className='textStyles-small'>Member NIC</span>,
            dataIndex: 'Member NIC',
            key: 'Member NIC',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
    ]

    const depotDataMap = (apiData) => {
        let exportArr = [];
        apiData.forEach(item => {
            let depotOfficersArrLength = item?.depotOfficers?.length || 0;
            if (depotOfficersArrLength > 0) {
                let isFirstIteration = true;
                item.depotOfficers.forEach(officer => {
                    const officerData = {
                        "Officer Organization Description": officer?.organization?.description || "N/A",
                        "Officer Organization": officer?.organization?.name || "N/A",
                        "Officer Email": officer?.email || "N/A",
                        "Officer Mobile": officer?.mobileNumber || "N/A",
                        "Officer NIC": officer?.nic || "N/A",
                        "Officer User Id": officer?.userId || "N/A",
                        "Officer Name": officer?.name || "N/A",
                    };
                    if (isFirstIteration) {
                        officerData["Sub Region"] = item?.subRegion || "N/A";
                        officerData["Description"] = item?.description || "N/A";
                        officerData["Parent Region"] = item?.parentRegion || "N/A";
                        officerData["Description"] = item?.description || "N/A";
                        officerData["Depot Contact"] = item?.depotContact || "N/A";
                        officerData["Depot Name"] = item?.name || "N/A";
                        isFirstIteration = false;
                    }    
                    exportArr.push(reverseJsonKeys(officerData));
                });
            } else {
                exportArr.push({
                    "Depot Name": item?.name || "N/A",
                    "Depot Contact": item?.depotContact || "N/A",
                    "Sub Region": item?.subRegion || "N/A",
                    "Description": item?.description || "N/A",
                    "Parent Region": item?.parentRegion || "N/A",
                    "Officer Name": "N/A",
                    "Officer NIC": "N/A",
                    "Officer User Id": "N/A",
                    "Officer Organization": "N/A",
                    "Officer Organization Description": "N/A",
                });
            }
        });
        return exportArr;
    };

    const depotDataCols = [
        {
            title: <span className='textStyles-small'>Depot Name</span>,
            dataIndex: 'Depot Name',
            key: 'Depot Name',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
        {
            title: <span className='textStyles-small'>Sub Region</span>,
            dataIndex: 'Sub Region',
            key: 'Sub Region',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
        {
            title: <span className='textStyles-small'>Depot Contact</span>,
            dataIndex: 'Depot Contact',
            key: 'Depot Contact',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
        {
            title: <span className='textStyles-small'>Parent Region</span>,
            dataIndex: 'Parent Region',
            key: 'Parent Region',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
        {
            title: <span className='textStyles-small'>Officer Name</span>,
            dataIndex: 'Officer Name',
            key: 'Officer Name',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
        {
            title: <span className='textStyles-small'>Officer NIC</span>,
            dataIndex: 'Officer NIC',
            key: 'Officer NIC',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
        {
            title: <span className='textStyles-small'>Officer User Id</span>,
            dataIndex: 'Officer User Id',
            key: 'Officer User Id',
            render: text => <span className='textStyles-small'>{text}</span>,
        },
    ]
    
    const exportData = (data, exportName) => {
        if (exportFormat === '1') {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = xlsx.utils.book_new();
            xlsx.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            xlsx.writeFile(workbook, exportName+ new Date().toISOString().slice(0, 10) + '.xlsx');

        } else {
            const csv = Papa.unparse(data);
            const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
            saveAs(blob, exportName + new Date().toISOString().slice(0, 10) + '.csv');
        }
    }

    return (
        <body>
            <div style={{ margin: '0', padding: '10px', height: '100%' }}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            <div className="row">
                                <div className="col">
                                    <h4 className="page-title" style={{ fontWeight: "bold" }}>
                                        Masterdata Export
                                    </h4>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Data Export</a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Masterdata Export</a>
                                        </li>
                                    </ol>
                                </div>
                                <div className="col-auto align-self-center">
                                    <a
                                        href="#"
                                        className="btn btn-sm btn-outline-primary"
                                        id="Dash_Date"
                                    >
                                        <span className="day-name" id="Day_Name">
                                            Today:
                                        </span>
                                        &nbsp;
                                        <span className="" id="Select_date">
                                            {new Date().toUTCString().slice(5, 16)}
                                        </span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            className="feather feather-calendar align-self-center icon-xs ms-1"
                                        >
                                            <rect
                                                x="3"
                                                y="4"
                                                width="18"
                                                height="18"
                                                rx="2"
                                                ry="2"
                                            ></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{
                    backgroundColor: "#FFFFFF",
                    padding: "20px 20px 20px 20px",
                    borderRadius: "10px",
                    marginTop: "5px",
                }}>
                    <div style={{ padding: 10, borderRadius: 10 }}>
                        <Row>
                            <Col span={24}>
                                <Select placeholder="Select Export Category"
                                    className='borderedSelect'
                                    style={{ width: '220px' }}
                                    onChange={(value) => {
                                        setExportCategory(value);
                                        setButtonVisible(false);
                                        setSearchData([]);
                                        setMappedData([]);
                                    }}
                                    bordered={false}
                                >
                                    <Option value="region" className="textStyle-small">Region Export</Option>
                                    <Option value="depot" className="textStyle-small">Depot Export</Option>
                                    <Option value="vendor" className="textStyle-small">Vendor Export</Option>
                                    <Option value="vendorTeam" className="textStyle-small">Vendor Team Export</Option>

                                </Select>

                                <Select placeholder="Select Export Format"
                                    className='borderedSelect'
                                    style={{ width: '220px', marginLeft: "10px" }}
                                    onChange={(value) => setExportFormat(value)}
                                    defaultValue={exportFormat}
                                    bordered={false}
                                >
                                    <Option value="1" className="textStyle-small">EXCEL</Option>
                                    <Option value="2" className="textStyle-small">CSV</Option>
                                </Select>

                                {
                                    isStillLoading !== true ? (
                                        <Button
                                        className='textStyles-small'
                                        type="primary"
                                        style={{ marginLeft: "10px", width: "150px", borderRadius: "5px" }}
                                        icon={<CloudSyncOutlined style={{ fontSize: "18px" }} />}
                                        onClick={() => fetchMasterData(expoertCategory)}
                                    >
                                        Request Data
                                    </Button>
                                    ) : null
                                }

                                {
                                    buttonVisible ? (
                                        <Button
                                            className='textStyles-small'
                                            type="primary"
                                            style={{ marginLeft: "10px", width: "150px", borderRadius: "5px", backgroundColor: "#52c41a", borderColor: "#52c41a" }}
                                            icon={<DownloadOutlined style={{ fontSize: "18px" }} />}
                                            onClick={prepareDownloadData}
                                        >
                                            Export Data
                                        </Button>
                                    ) : <></>
                                }

                            </Col>
                            <Col span={12}>
                                <div style={{ float: "right" }}>
                                </div>
                            </Col>
                        </Row>
                    </div> <br />

                    <Spin tip="Fetching Data..." size="large" spinning={isStillLoading}>
                    <Table
                            className="table-striped-rows"
                            dataSource={mappedData === null ? [] : mappedData}
                            columns={expoertCategory === 'vendor' ? vendorExportCols : expoertCategory === 'region' ? regionDataCols : expoertCategory === 'vendorTeam' ? vendorTeamDataCols : depotDataCols}
                            loading={mappedData === null ? fetching : false}
                            rowKey="key"
                            size="small"
                        />
                    </Spin>
                </div>
            </div>

        </body>

    )
}

export default MasterdataExport;