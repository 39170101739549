import React, { useState, useEffect } from 'react';
import {
    ClockCircleOutlined,
    ClockCircleFilled,
    PlusCircleFilled,
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import { Divider, Space, Avatar, Card, Skeleton, Switch, Table, Select, Badge, Input, Button, Row, Upload, Col, Dropdown, Tag, Modal, Steps, message, Form, DatePicker, TimePicker, Descriptions, Image, Radio, Checkbox, Collapse } from 'antd';
import { 
    getAllAccessMasterData, 
    insetMasterDataAccess, 
    updateMasterDataAccess, 
    deleteMasterDataAccess,
    
    insertMasterDataAccess,
    getAllRoleDataAccessFunction,
    getAllSubRegionsDepotCreate,
    updateRoleDataAccessFunction
} from "../../api/index";
import './style.css';
const { Step } = Steps;

const UserModule = () => {
    const [masterData, setMasterData] = useState([]);
    const [masterDataType, setMasterDataType] = useState("group");
    const [isLoading, setIsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [subRegions, setSubRegions] = useState([]);

    useEffect(() => {
        getMasterData('group');
        fetchAllSubRegionsDepotCreate();
    }, []);

    const fetchAllSubRegionsDepotCreate = async () => {
        const response = await getAllSubRegionsDepotCreate();
        if (response.success) {
            const responseData = response?.data;
            setSubRegions(responseData);
        }
    }

    const getMasterData = async (data) => {
        setIsLoading(true);
        const response = await getAllRoleDataAccessFunction(data);
        if (response?.success) {
            setIsLoading(false);
            setMasterData(response?.data?.results);
        } else {
            setIsLoading(false);
            message.error("Failed to fetch data");
        }
    }

    const insertMasterData = async (data) => {
        setIsLoading(true);
        const response = await insertMasterDataAccess(masterDataType, data);
        if (response?.success) {
            setIsLoading(false);
            message.success(masterDataType + " data inserted successfully");
            closeModal();
            getMasterData(masterDataType);
        } else {
            setIsLoading(false);
            message.error("Failed to insert data");
        }
    }

    const updateMasterData = async (id, data) => {
        setIsLoading(true);
        const response = await updateRoleDataAccessFunction(masterDataType, id, data);
        if (response?.success) {
            setIsLoading(false);
            message.success(masterDataType + " data updated successfully");
            closeModal();
            setSelectedData({});
            getMasterData(masterDataType);
        } else {
            setIsLoading(false);
            message.error("Failed to update data");
        }
    }

    const deleteMasterDataAccessMasterData = async (id) => {
        setIsLoading(true);
        console.log(id);
        const response = await deleteMasterDataAccess(masterDataType, id);
        if (response?.success) {
            setIsLoading(false);
            message.success(masterDataType + " data deleted successfully");
            closeModal();
            setSelectedData({});
            getMasterData(masterDataType);
        } else {
            setIsLoading(false);
            message.error("Failed to delete data");
        }
    }

    const closeModal = () => {
        setModalOpen(false);
        setIsUpdate(false);
        setSelectedData([]);
    }

    const dropdownData = [
        {
            id: 1,
            name: "Role Management",
            value: "role"
        },
        {
            id: 2,
            name: "Groups Management",
            value: "group"
        },
    ];

    const columns_Roles = [
        {
            title: <span className="textStyles-small">Role Name</span>,
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <span className="textStyles-small">{record.name}</span>
            )
        },
        {
            title: <span className="textStyles-small">Action</span>,
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button
                        shape='circle'
                        size='small'
                        icon={<EditOutlined />}
                        type="primary"
                        onClick={() => {
                            setIsUpdate(true);
                            setSelectedData(record);
                            setModalOpen(true);
                        }}
                    />
                </Space>
            )
        }
    ];

    const columns_Groups = [
        {
            title: <span className="textStyles-small">Group Name</span>,
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <span className="textStyles-small">{record.name}</span>
            )
        },
        {
            title: <span className="textStyles-small">Number of Users</span>,
            dataIndex: 'numberOfUsers',
            key: 'numberOfUsers',
            render: (text, record) => (
                <span className="textStyles-small">{record.numberOfUsers}</span>
            )
        },
        {
            title: <span className="textStyles-small">Group Type</span>,
            dataIndex: 'groupType',
            key: 'groupType',
            render: (text, record) => (
                <span className="textStyles-small">{record.groupType}</span>
            )
        },
        {
            title: <span className="textStyles-small">Region Name</span>,
            dataIndex: 'regionId',
            key: 'regionId',
            render: (text, record) => (
                <span className="textStyles-small">{
                    record?.regionId !== 0 ?
                    subRegions?.map(data => data.id === record.regionId ? data.name : '')
                    : 'Not Set Yet'
                    }</span>
            )
        },
        {
            title: <span className="textStyles-small">Action</span>,
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button
                        shape='circle'
                        size='small'
                        icon={<EditOutlined />}
                        type="primary"
                        onClick={() => {
                            setIsUpdate(true);
                            setSelectedData(record);
                            setModalOpen(true);
                        }}
                    />
                </Space>
            )
        }
    ];

    const onFinishSubmit = (values) => {
        if (isUpdate) {
            updateMasterData(selectedData?.id, values);
        } else {
            insertMasterData(values);
        }
    }

    return (
        <body>
            <div style={{ margin: '0', padding: '10px', height: '100%' }}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            <div className="row">
                                <div className="col">
                                    <h4 className="page-title" style={{ fontWeight: "bold" }}>
                                    Group and Role Management
                                    </h4>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Admin </a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Group and Role Management</a>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ margin: '0', padding: '10px', width: '100%', backgroundColor: 'white', borderRadius: '10px' }}>
                    <Select
                        className='borderedSelect'
                        bordered={false}
                        showSearch
                        style={{ width: 220 }}
                        placeholder="Select a Master Data"
                        onChange={(value) => {
                            setMasterDataType(value);
                            getMasterData(value);
                        }}
                        defaultValue={masterDataType}
                    >
                        {dropdownData.map((data, index) => {
                            return (
                                <Select.Option key={data.id} value={data.value}>{data.name}</Select.Option>
                            )
                        })}
                    </Select>
                    <Button
                        type="primary"
                        style={{ marginLeft: '10px', background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px' }}
                        onClick={() => setModalOpen(true)}
                    >
                        <span className='textStyles-small'>
                            <PlusCircleFilled style={{ fontSize: '16px', marginRight: '5px' }} />
                            Insert Data</span>
                    </Button>
                </div>

                <div style={{ marginTop: '10px', padding: '10px', width: '100%', backgroundColor: 'white', borderRadius: '10px' }}>
                    <Table
                        className='table-striped-rows'
                        columns={masterDataType === 'role' ? columns_Roles : columns_Groups}
                        dataSource={masterData}
                        loading={isLoading}
                        search={true} />
                </div>
            </div>

            <Modal
                title={
                    <span className='textStyles-small' style={{ fontSize: '16px' }}>
                        {
                            (dropdownData?.filter(data => data.value === masterDataType)[0]?.name || '') + (isUpdate ? ' Data Update' : ' Data Insert')
                        }
                    </span>
                }
                visible={modalOpen}
                onCancel={closeModal}
                footer={null}
                destroyOnClose={true}
            >
                <Form
                    name="basic"
                    onFinish={onFinishSubmit}
                    layout='vertical'
                >
                    <Form.Item
                        label={<span className='textStyles-small'>Name</span>}
                        name="name"
                        rules={[{ required: true, message: 'Please input your name!' }]}
                        initialValue={isUpdate ? selectedData?.name : ''}
                    >
                        <Input />
                    </Form.Item>

                    {
                        masterDataType === 'group' ? (
                            <>
                                <Form.Item
                                    label={<span className='textStyles-small'>Number of Users</span>}
                                    name="numberOfUsers"
                                    rules={[{ required: true, message: 'Please input your number of users!' }]}
                                    initialValue={isUpdate ? selectedData?.numberOfUsers : ''}
                                >
                                    <Input
                                        type='number'
                                    />
                                </Form.Item>

                                <Form.Item
                                    label={<span className='textStyles-small'>Region Id</span>}
                                    name="regionId"
                                    rules={[{ required: true, message: 'Please input your region id!' }]}
                                    initialValue={isUpdate ? selectedData?.regionId : ''}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a Region"
                                    >
                                        {
                                            subRegions.map((data, index) => {
                                                return (
                                                    <Select.Option key={data.id} value={data.id}>{data.name}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label={<span className='textStyles-small'>Group Type</span>}
                                    name="groupType"
                                    rules={[{ required: true, message: 'Please input your group type!' }]}
                                    initialValue={isUpdate ? selectedData?.groupType : ''}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a Group Type"
                                    >
                                        <Select.Option value="REQUESTER">Requester</Select.Option>
                                        <Select.Option value="HANDLER">Handler</Select.Option>
                                        <Select.Option value="UNSPECIFIED">Unspecified</Select.Option>
                                    </Select>
                                </Form.Item>
                            </>
                        ) : null
                    }
                    
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {<span className='textStyles-small'>{
                                isUpdate ? 'Update' : 'Insert'
                            }</span>}
                        </Button>

                        <Button type="primary" htmlType="reset" danger style={{ marginLeft: '10px' }}>
                            {<span className='textStyles-small'>Reset</span>}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </body>
    );
}

export default UserModule;