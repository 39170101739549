import React, { Component } from "react";
import {
  Table,
  Input,
  Popconfirm,
  Tag,
  Space,
  Button,
  Row,
  Col,
  Drawer,
  Select,
  Spin,
  Switch,
  message,
  Modal,
} from "antd";
import { updateAcTechnologies, getAllDropdowns } from "../../api/index";
const { Option } = Select;

class AccessTechnologies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      switchStatus: true,
      objectSet: {
        atGSM: true,
        atDCS: false,
        at3G: true,
        at4GFDD900: false,
        at4GFDD1800: true,
        at4GFDD2100: false,
        at4GTDD2300: true,
        at5G: false,
        atCAMBIUM: true,
        atWIBASS: false,
        atGPON: true,
        atCombinedTechnologies: false,
        atAccessTechnologyVendor: null,
      },
      dropDownSet: {
        atGSM: [],
        atDCS: [],
        at3G: [],
        at4GFDD900: [],
        at4GFDD1800: [],
        at4GFDD2100: [],
        at4GTDD2300: [],
        at5G: [],
        atCAMBIUM: [],
        atWIBASS: [],
        atGPON: [],
        atCombinedTechnologies: [],
      },
      dropdownOnchangeDataSet: {
        atGSM: null,
        atDCS: null,
        at3G: null,
        at4GFDD900: null,
        at4GFDD1800: null,
        at4GFDD2100: null,
        at4GTDD2300: null,
        at5G: null,
        atCAMBIUM: null,
        atWIBASS: null,
        atGPON: null,
        atCombinedTechnologies: null,
        atCombinedTechnologies: null,
      },
    };
  }
  // access technlogies data binding

  componentDidMount() {
    console.log("AcTec");
    console.log(this.props.details2.site_details.id);
    this.getAccessTechnologiesDropDownData();
    this.setState(
      {
        objectSet: {
          atGSM: this.props.details.access_technologies_data?.atGSM,
          atDCS: this.props.details.access_technologies_data?.atDCS,
          at3G: this.props.details.access_technologies_data?.at3G,
          at4GFDD900: this.props.details.access_technologies_data?.at4GFDD900,
          at4GFDD1800: this.props.details.access_technologies_data?.at4GFDD1800,
          at4GFDD2100: this.props.details.access_technologies_data?.at4GFDD2100,
          at4GTDD2300: this.props.details.access_technologies_data?.at4GTDD2300,
          at5G: this.props.details.access_technologies_data?.at5G,
          atCAMBIUM: this.props.details.access_technologies_data?.atCAMBIUM,
          atWIBASS: this.props.details.access_technologies_data?.atWIBASS,
          atGPON: this.props.details.access_technologies_data?.atGPON,
          atCombinedTechnologies: this.props.details.access_technologies_data?.atCombinedTechnologies,
          atAccessTechnologyVendor: this.props.details.access_technologies_data?.atAccessTechnologyVendor,
        },
        dropdownOnchangeDataSet: {
          atGSM: this.props.details.access_technologies_data?.atGSM,
          atDCS:
            this.props.details.access_technologies_data?.atDCS,
          at3G:
            this.props.details.access_technologies_data?.at3G,
          at4GFDD900:
            this.props.details.access_technologies_data?.at4GFDD900,
          at4GFDD1800:
            this.props.details.access_technologies_data?.at4GFDD1800,
          at4GFDD2100:
            this.props.details.access_technologies_data?.at4GFDD2100,
          at4GTDD2300:
            this.props.details.access_technologies_data?.at4GTDD2300,
          at5G:
            this.props.details.access_technologies_data?.at5G,
          atCAMBIUM:
            this.props.details.access_technologies_data?.atCAMBIUM,
          atWIBASS:
            this.props.details.access_technologies_data?.atWIBASS,
          atGPON:
            this.props.details.access_technologies_data?.atGPON,
          atCombinedTechnologies:
            this.props.details.access_technologies_data
              ?.atCombinedTechnologies,
          atCombinedTechnologies: this.props.details.access_technologies_data?.atCombinedTechnologies,
        },
      },
      () => {
        console.log("AccessTechnologies");
        console.log(Object.entries(this.state.objectSet));
      }
    );
  }

  getAccessTechnologiesDropDownData = async () => {
    var res = await getAllDropdowns();
    if (res.success) {
      console.log(JSON.stringify(res.data["Access Technologies"]["GSM"]));
      this.setState({
        dropDownSet: {
          atGSM: res.data["Access Technologies"]["GSM"],
          atDCS: res.data["Access Technologies"]["DCS"],
          at3G: res.data["Access Technologies"]["3G"],
          at4GFDD900: res.data["Access Technologies"]["5G"],
          at4GFDD1800: res.data["Access Technologies"]["5G"],
          at4GFDD2100: res.data["Access Technologies"]["5G"],
          at4GTDD2300: res.data["Access Technologies"]["5G"],
          at5G: res.data["Access Technologies"]["5G"],
          atCAMBIUM: res.data["Access Technologies"]["CAMBIUM"],
          atWIBASS: res.data["Access Technologies"]["WIBASS"],
          atGPON: res.data["Access Technologies"]["GPON"],
          atCombinedTechnologies: ["Yes", "No"],
        },
      });
    } else {
      message.warning("Something went wrong!");
    }
  };

  showUpdateConfirm = () => {
    const { confirm } = Modal;
    confirm({
      title:
        "Are You Want To Update These Data, Related To Access Technologies?",
      onOk: async () => {
        this.dataSetToUpdate();
      },
      onCancel() { },
    });
  };

  dataSetToUpdate = async () => {
    console.log(
      "AccessTechnologiesDropDownData--------------------------------------------------"
    );
    console.log(this.state.dropdownOnchangeDataSet);
    console.log(this.state.objectSet);
    var res = await updateAcTechnologies(
      this.props.details2.site_details.id,
      this.state.dropdownOnchangeDataSet
    );
    if (res.success) {
      message.success("Successfully Update!");
      this.props.parentMethod();
      this.setState({
        switchStatus: true,
      });
    } else {
      message.warning(" Something went wrong!");
    }
  };

  switchTo(id) {
    this.state.objectSet[id] = !this.state.objectSet[id];
  }
  dataUpdate() {
    console.log("ssss");
    this.setState({
      switchStatus: false,
    });
  }
  close = async () => {
    this.props.parentMethod();
    this.setState({
      objectSet: {
        atGSM: this.props.details.access_technologies_data?.atGSM,
        atDCS: this.props.details.access_technologies_data?.atDCS,
        at3G: this.props.details.access_technologies_data?.at3G,
        at4GFDD900: this.props.details.access_technologies_data?.at4GFDD900,
        at4GFDD1800: this.props.details.access_technologies_data?.at4GFDD1800,
        at4GFDD2100: this.props.details.access_technologies_data?.at4GFDD2100,
        at4GTDD2300: this.props.details.access_technologies_data?.at4GTDD2300,
        at5G: this.props.details.access_technologies_data?.at5G,
        atCAMBIUM: this.props.details.access_technologies_data?.atCAMBIUM,
        atWIBASS: this.props.details.access_technologies_data?.atWIBASS,
        atGPON: this.props.details.access_technologies_data?.atGPON,
        atCombinedTechnologies:
          this.props.details.access_technologies_data?.atCombinedTechnologies,
        atAccessTechnologyVendor: this.props.details.access_technologies_data?.atAccessTechnologyVendor,
      },
    });
    this.setState({
      switchStatus: true,
    });
  };

  render() {
    const { details } = this.props.details;
    return (
      <>
        {this.props.details.access_technologies_data != null ? (
          <div className="row">
            <div className="col-md-6">
              <div class="card">
                <div class="card-header">
                  <div class="d-flex justify-content-between align-items-center">
                    <h4 class="card-title">Information</h4>
                    {this.state.switchStatus ? (
                      <button
                        type="button"
                        class="btn btn-soft-primary btn-icon-square-sm"
                        onClick={() => this.dataUpdate()}
                      >
                        {" "}
                        <i class="mdi mdi-pencil-outline"></i>
                      </button>
                    ) : (
                      <div class="d-flex justify-content-end align-items-center">
                        <button
                          type="button"
                          class="btn btn-soft-secondary "
                          onClick={() => this.close()}
                          style={{ marginRight: "10px" }}
                        >
                          {" "}
                          Cancel{" "}
                        </button>
                        <button
                          type="button"
                          class="btn btn-soft-primary "
                          onClick={() => this.showUpdateConfirm()}
                        >
                          {" "}
                          Submit to Update{" "}
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div class="card-body">
                  <div class="row">
                    <ul class="list-group">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                          <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                          GSM
                        </div>
                        <div>
                          {this.state.switchStatus.toString() === "true" ? ( this.props.details.access_technologies_data?.atGSM?.toLowerCase() ==="yes" ? (
                              <Tag color="green">Yes</Tag>
                            ) : (
                              <Tag color="red">No</Tag>
                            )
                          ) : (
                            <Select
                              defaultValue={
                                this.props.details.access_technologies_data
                                  ?.atGSM
                              }
                              style={{ width: 120 }}
                              onChange={(e) => {
                                this.state.dropdownOnchangeDataSet.atGSM = e;
                              }}
                            >
                              {this.state.dropDownSet.atGSM.map((x, y) => {
                                return <Option value={x}>{x}</Option>;
                              })}
                            </Select>
                          )}
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                          <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                          DCS
                        </div>
                        <div>
                          {this.state.switchStatus.toString() == "true" ? (
                            this.props.details.access_technologies_data?.atDCS?.toLowerCase() === "yes" ? (
                              <Tag color="green">Yes</Tag>
                            ) : (
                              <Tag color="red">No</Tag>
                            )
                          ) : (
                            <Select
                              defaultValue={
                                this.props.details.access_technologies_data
                                  ?.atDCS
                              }
                              style={{ width: 120 }}
                              onChange={(e) => {
                                this.state.dropdownOnchangeDataSet.atDCS = e;
                              }}
                            >
                              {this.state.dropDownSet.atDCS.map((x, y) => {
                                return <Option value={x}>{x}</Option>;
                              })}
                            </Select>
                          )}
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                          <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                          3G
                        </div>
                        <div>
                          {this.state.switchStatus.toString() == "true" ? (this.props.details.access_technologies_data?.at3G?.toLowerCase() ===
                              "yes" ? (
                              <Tag color="green">Yes</Tag>
                            ) : (
                              <Tag color="red">No</Tag>
                            )
                          ) : (
                            <Select
                              defaultValue={
                                this.props.details.access_technologies_data
                                  ?.at3G
                              }
                              style={{ width: 120 }}
                              onChange={(e) => {
                                this.state.dropdownOnchangeDataSet.at3G = e;
                              }}
                            >
                              {this.state.dropDownSet.at3G.map((x, y) => {
                                return <Option value={x}>{x}</Option>;
                              })}
                            </Select>
                          )}
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                          <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                          4GFDD_900
                        </div>
                        <div>
                          {this.state.switchStatus.toString() == "true" ? (
this.props.details.access_technologies_data?.at4GFDD900?.toLowerCase() ===
                              "yes" ? (
                              <Tag color="green">Yes</Tag>
                            ) : (
                              <Tag color="red">No</Tag>
                            )
                          ) : (
                            <Select
                              defaultValue={
                                this.props.details.access_technologies_data
                                  ?.at4GFDD900
                              }
                              style={{ width: 120 }}
                              onChange={(e) => {
                                this.state.dropdownOnchangeDataSet.at4GFDD900 =
                                  e;
                              }}
                            >
                              {this.state.dropDownSet.at4GFDD900.map((x, y) => {
                                return <Option value={x}>{x}</Option>;
                              })}
                            </Select>
                          )}
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                          <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                          4GFDD_1800
                        </div>
                        <div>
                          {this.state.switchStatus.toString() == "true" ? (
this.props.details.access_technologies_data?.at4GFDD1800?.toLowerCase() ===
                              "yes" ? (
                              <Tag color="green">Yes</Tag>
                            ) : (
                              <Tag color="red">No</Tag>
                            )
                          ) : (
                            <Select
                              defaultValue={
                                this.props.details.access_technologies_data
                                  ?.at4GFDD1800
                              }
                              style={{ width: 120 }}
                              onChange={(e) => {
                                this.state.dropdownOnchangeDataSet.at4GFDD1800 =
                                  e;
                              }}
                            >
                              {this.state.dropDownSet.at4GFDD1800.map(
                                (x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                }
                              )}
                            </Select>
                          )}
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                          <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                          4GFDD2100
                        </div>
                        {/* {this.props.details.access_technologies_data?.at4GFDD2100 ? (<span class="badge badge-outline-success">{JSON.stringify(this.props.details.access_technologies_data.at4GFDD2100)}</span>) : (<span class="badge badge-outline-warning">{JSON.stringify(this.props.details.access_technologies_data?.at4GFDD2100)}</span>)} */}
                        <div>
                          {this.state.switchStatus.toString() == "true" ? (
                            this.props.details.access_technologies_data?.at4GFDD2100?.toLowerCase() ===
                              "yes" ? (
                              <Tag color="green">Yes</Tag>
                            ) : (
                              <Tag color="red">No</Tag>
                            )
                          ) : (
                            <Select
                              defaultValue={
                                this.props.details.access_technologies_data
                                  ?.at4GFDD2100
                              }
                              style={{ width: 120 }}
                              onChange={(e) => {
                                this.state.dropdownOnchangeDataSet.at4GFDD2100 =
                                  e;
                              }}
                            >
                              {this.state.dropDownSet.at4GFDD2100.map(
                                (x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                }
                              )}
                            </Select>
                          )}
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                          <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                          4GTDD2300
                        </div>
                        {/* {this.props.details.access_technologies_data?.at4GTDD2300 ? (<span class="badge badge-outline-success">{JSON.stringify(this.props.details.access_technologies_data.at4GTDD2300)}</span>) : (<span class="badge badge-outline-warning">{JSON.stringify(this.props.details.access_technologies_data?.at4GTDD2300)}</span>)} */}
                        <div>
                          {this.state.switchStatus.toString() == "true" ? (
this.props.details.access_technologies_data?.at4GTDD2300?.toLowerCase() ===
                              "yes" ? (
                              <Tag color="green">Yes</Tag>
                            ) : (
                              <Tag color="red">No</Tag>
                            )
                          ) : (
                            <Select
                              defaultValue={
                                this.props.details.access_technologies_data
                                  ?.at4GTDD2300
                              }
                              style={{ width: 120 }}
                              onChange={(e) => {
                                this.state.dropdownOnchangeDataSet.at4GTDD2300 =
                                  e;
                              }}
                            >
                              {this.state.dropDownSet.at4GTDD2300.map(
                                (x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                }
                              )}
                            </Select>
                          )}
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                          <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                          5G
                        </div>
                        {/* {this.props.details.access_technologies_data?.at5G ? (<span class="badge badge-outline-success">{JSON.stringify(this.props.details.access_technologies_data?.at5G)}</span>) : (<span class="badge badge-outline-warning">{JSON.stringify(this.props.details.access_technologies_data?.at5G)}</span>)} */}
                        <div>
                          {this.state.switchStatus.toString() == "true" ? (
                            this.props.details.access_technologies_data?.at5G?.toLowerCase() ===
                              "yes" ? (
                              <Tag color="green">Yes</Tag>
                            ) : (
                              <Tag color="red">No</Tag>
                            )
                          ) : (
                            <Select
                              defaultValue={
                                this.props.details.access_technologies_data
                                  ?.at5G
                              }
                              style={{ width: 120 }}
                              onChange={(e) => {
                                this.state.dropdownOnchangeDataSet.at5G = e;
                              }}
                            >
                              {this.state.dropDownSet.at5G.map((x, y) => {
                                return <Option value={x}>{x}</Option>;
                              })}
                            </Select>
                          )}
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                          <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                          WIBASS
                        </div>
                        {/* {this.props.details.access_technologies_data?.atWIBASS ? (<span class="badge badge-outline-success">{JSON.stringify(this.props.details.access_technologies_data.atWIBASS)}</span>) : (<span class="badge badge-outline-warning">{JSON.stringify(this.props.details.access_technologies_data?.atWIBASS)}</span>)} */}
                        <div>
                          {this.state.switchStatus.toString() == "true" ? (
this.props.details.access_technologies_data?.atWIBASS?.toLowerCase() ===
                              "yes" ? (
                              <Tag color="green">Yes</Tag>
                            ) : (
                              <Tag color="red">No</Tag>
                            )
                          ) : (
                            <Select
                              defaultValue={
                                this.props.details.access_technologies_data
                                  ?.atWIBASS
                              }
                              style={{ width: 120 }}
                              onChange={(e) => {
                                this.state.dropdownOnchangeDataSet.atWIBASS = e;
                              }}
                            >
                              {this.state.dropDownSet.atWIBASS.map((x, y) => {
                                return <Option value={x}>{x}</Option>;
                              })}
                            </Select>
                          )}
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                          <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                          CAMBIUM
                        </div>
                        {/* {this.props.details.access_technologies_data?.atCAMBIUM ? (<span class="badge badge-outline-success">{JSON.stringify(this.props.details.access_technologies_data.atCAMBIUM)}</span>) : (<span class="badge badge-outline-warning">{JSON.stringify(this.props.details.access_technologies_data?.atCAMBIUM)}</span>)} */}
                        <div>
                          {this.state.switchStatus.toString() == "true" ? (
this.props.details.access_technologies_data?.atCAMBIUM?.toLowerCase() ===
                              "yes" ? (
                              <Tag color="green">Yes</Tag>
                            ) : (
                              <Tag color="red">No</Tag>
                            )
                          ) : (
                            <Select
                              defaultValue={
                                this.props.details.access_technologies_data
                                  ?.atCAMBIUM
                              }
                              style={{ width: 120 }}
                              onChange={(e) => {
                                this.state.dropdownOnchangeDataSet.atCAMBIUM =
                                  e;
                              }}
                            >
                              {this.state.dropDownSet.atCAMBIUM.map((x, y) => {
                                return <Option value={x}>{x}</Option>;
                              })}
                            </Select>
                          )}
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                          <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                          GPON
                        </div>
                        {/* {this.props.details.access_technologies_data?.atGPON ? (<span class="badge badge-outline-success">{JSON.stringify(this.props.details.access_technologies_data.atGPON)}</span>) : (<span class="badge badge-outline-warning">{JSON.stringify(this.props.details.access_technologies_data?.atGPON)}</span>)} */}
                        <div>
                          {this.state.switchStatus.toString() == "true" ? (
this.props.details.access_technologies_data?.atGPON?.toLowerCase() ===
                              "yes" ? (
                              <Tag color="green">Yes</Tag>
                            ) : (
                              <Tag color="red">No</Tag>
                            )
                          ) : (
                            <Select
                              defaultValue={
                                this.props.details.access_technologies_data
                                  ?.atGPON
                              }
                              style={{ width: 120 }}
                              onChange={(e) => {
                                this.state.dropdownOnchangeDataSet.atGPON = e;
                              }}
                            >
                              {this.state.dropDownSet.atGPON.map((x, y) => {
                                return <Option value={x}>{x}</Option>;
                              })}
                            </Select>
                          )}
                        </div>
                      </li>
                      {/* <li class="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                          <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                          Combined Technologies
                        </div>
                        <div>
                          {this.state.switchStatus.toString() == "true" ? (
                            this.props.details.access_technologies_data?.atCombinedTechnologies?.toLowerCase() ===
                              "yes" ? (
                              <Tag color="green">Yes</Tag>
                            ) : (
                              <Tag color="red">No</Tag>
                            )
                          ) : (
                            <Select
                              defaultValue={
                                this.props.details.access_technologies_data
                                  ?.atCombinedTechnologies
                              }
                              style={{ width: 120 }}
                              onChange={(e) => {
                                this.state.dropdownOnchangeDataSet.atCombinedTechnologies =
                                  e;
                              }}
                            >
                              {this.state.dropDownSet.atCombinedTechnologies.map(
                                (x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                }
                              )}
                            </Select>
                          )}
                        </div>
                      </li>  */}
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                          <i class="la la-hand-o-right text-primary me-2"></i>{" "}
                          Access Technology Vendor
                        </div>
                        <div>
                          {this.state.switchStatus?.toString() == "true" ? (
                            this.props.details.access_technologies_data?.atAccessTechnologyVendor === null ||this.props.details.access_technologies_data?.atAccessTechnologyVendor == undefined || 
                            this.props.details.access_technologies_data?.atAccessTechnologyVendor == "" ? (
                              <Tag color="red">No Data</Tag>
                            ) : ( <Tag color="green">{this.props.details.access_technologies_data?.atAccessTechnologyVendor}</Tag>)
                          ) : (
                            // <Select
                            //   defaultValue={
                            //     this.props.details.access_technologies_data
                            //       ?.atCombinedTechnologies
                            //   }
                            //   style={{ width: 120 }}
                            //   onChange={(e) => {
                            //     this.state.dropdownOnchangeDataSet.atCombinedTechnologies =
                            //       e;
                            //   }}
                            // >
                            //   {this.state.dropDownSet.atCombinedTechnologies.map(
                            //     (x, y) => {
                            //       return <Option value={x}>{x}</Option>;
                            //     }
                            //   )}
                            // </Select>
                            <Input 
                            placeholder="Enter Access Technology Vendor"
                            onChange={(e) => {
                              this.state.dropdownOnchangeDataSet.atAccessTechnologyVendor = e.target.value;
                            }
                          }
                          defaultValue={this.props.details.access_technologies_data?.atAccessTechnologyVendor}
                          />
                          )}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              height: "120px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ color: "#c5c5c5" }}>
              Data NotFound Access Technologies
            </h4>
          </div>
        )}
      </>
    );
  }
}
export default AccessTechnologies;
