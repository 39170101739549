import React, { Component } from 'react'
import checkPermissions from '../../utils/permissionManager';
import { Table, Tag, Button, Drawer, Form, Input, Select, InputNumber, DatePicker, Popconfirm, message, Collapse, Row, Col, Divider, Card, Badge } from 'antd';
import { PlusCircleOutlined, DeleteOutlined, EditOutlined, MoreOutlined, HomeOutlined, UnorderedListOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import { createNewSharedOperator, getsharedOperatorDetails, UpdateSharedOperator, dataUpdateGeneralDetails } from "../../api/index";
import moment from 'moment';
import AssetDelete from "../site_components/asset_delete";
import { checkServiceType } from "../../actions/service-type";

class sharedOutOperators extends Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();

        this.state = {
            showModal: false,
            open: false,
            openEdit: false,
            title: "",
            tabaleLoading: false,
            actionView: false,
            selectedAssetId: null,
            AssetType: [],
            selectedMakeId: null,
            makeType: [],

            soohandletenant: '',
            sharedPowerType: '',
            sooDcPowerCapacity: '',
            sooBillingMethod: '',
            sooGenPowerNoofPhases: '',
            sooCommercialPowerNoofPhases: '',
            sooCommercialPowerPerPhaseCapacity: '',
            sooGenPowerPerPhaseCapacity: '',
            sooBillingAmount: '',
            sooBillingStartDate: null,
            selectedModelId: null,
            modelType: [],
            fbclastMaintainanceDate: null,
            fbcwarrantyExpireDate: null,
            fbcInstalledDate: null,
            openViewMoreDetails: false,
            selectedElement: "",
            AssetManagementPermission: false,
            switchStatus: true,
            objectSet: {
                "noOfPIVCopies": 0,
                "sooCEBLECODepot": null,
                "powerConnectionAuthority": null,
                "powerConnectedDate": null,
                "sooCapexAmount3PPowerConnection": 0,
            },

            dropdownObjSoo: {
                "powerConnectionAuthority": "",
                "soohandletenant": "",
                "sharedPowerType": "",
                "sooCommercialPowerNoofPhases": "",
                "sooCommercialPowerPerPhaseCapacity": "",
                "sooGenPowerPerPhaseCapacity": "",
                "sooGenPowerNoofPhases": "",
                "sooDcPowerCapacity": "",
                "sooBillingMethod": "",
            }

        };
        this.requestFromClose = this.requestFromClose.bind(this);

        this.handletenant = this.handletenant.bind(this);
        this.handleSharedPowerType = this.handleSharedPowerType.bind(this);

        this.handleDCPowerCapacity = this.handleDCPowerCapacity.bind(this);
        this.handleCommercialPowerNoofPhases = this.handleCommercialPowerNoofPhases.bind(this);
        this.handleBillingMethod = this.handleBillingMethod.bind(this);
        this.handleCommercialPowerPerPhaseCapacity = this.handleCommercialPowerPerPhaseCapacity.bind(this);
        this.handleGenPowerNoofPhases = this.handleGenPowerNoofPhases.bind(this);
        this.handleGenPowerPerPhaseCapacity = this.handleGenPowerPerPhaseCapacity.bind(this);
        this.handleBillingAmount = this.handleBillingAmount.bind(this);


    }
    handleBillingAmount(e) {
        console.log(e)
        this.setState({ sooBillingAmount: e });
    }
    handleGenPowerPerPhaseCapacity(e) {
        this.setState({ sooGenPowerPerPhaseCapacity: e });
    }
    handleGenPowerNoofPhases(e) {
        this.setState({ sooGenPowerNoofPhases: e });
    }
    handleCommercialPowerPerPhaseCapacity(e) {
        console.log(e)
        this.setState({ sooCommercialPowerPerPhaseCapacity: e });
    }
    handleCommercialPowerNoofPhases(e) {
        // console.log(e)
        this.setState({ sooCommercialPowerNoofPhases: e });
    }
    handleBillingMethod(e) {
        this.setState({ sooBillingMethod: e });
    }

    handleSharedPowerType(e) {
        this.setState({ sharedPowerType: e });
    }
    handletenant(e) {
        this.setState({ soohandletenant: e });
    }
    sharedOutOperatorsUpdata(id) {
        // console.log('dfdfd')
        console.log(id)
        this.getDataSharedOutOperatorsDetails(id);
        this.setState({ openEdit: true });
    }
    getDataSharedOutOperatorsDetails = async (id) => {
        var res = await getsharedOperatorDetails(id);
        console.log(res);
        if (res.success) {
            this.setState({
                sharedOperatorEdit: res.data,
                sharedOperatorId: res.data.id,
                soohandletenant: res.data?.tenant,
                sharedPowerType: res.data?.sharedPowerType,
                sooDcPowerCapacity: res.data?.dcPowerCapacity,
                sooBillingMethod: res.data?.billingMethod,
                sooCommercialPowerNoofPhases: res.data?.commercialPowerNoofPhases,
                sooCommercialPowerPerPhaseCapacity: res.data?.commercialPowerPerPhaseCapacity,
                sooGenPowerNoofPhases: res.data?.genPowerNoofPhases,
                sooGenPowerPerPhaseCapacity: res.data?.genPowerPerPhaseCapacity,
                sooBillingAmount: res.data?.billingAmount,



            })
            if (res.data?.billingStartDate != null) {

                this.setState({
                    sooBillingStartDate: res.data?.billingStartDate
                })
            } else {

                this.setState({
                    sooBillingStartDate: '',
                })
            }



        }
    }
    handleDCPowerCapacity(e) {
        console.log(e)
        this.setState({ sooDcPowerCapacity: e });
    }

    requestFromClose() {
        console.log('dsdsd')
        this.setState({ showModal: true, open: true });
    }

    componentDidMount() {
        if (JSON.parse(localStorage.getItem('serviceTypeSet')) == null) {
            const { history } = this.props;

            history.push("/");

        } else {

            this.getSetPageData(JSON.parse(localStorage.getItem('serviceTypeSet')), 38)

        }
        checkServiceType(38).then((data) => this.setState({
            AssetManagementPermission: data
        }, () => {
        }),

        )

        this.setState({
            objectSet: {
                // "noOfPIVCopies": this.props.details?.access_technologies_data?.noOfPIVCopies,
                // "sooCEBLECODepot": this.props?.details?.access_technologies_data?.sooCEBLECODepot,
                // "powerConnectionAuthority": this.props?.details?.access_technologies_data?.powerConnectionAuthority,
                // "powerConnectedDate": this.props.details?.access_technologies_data?.powerConnectedDate,
                "sooCapexAmount3PPowerConnection": this.props.details?.access_technologies_data?.sooCapexAmount3PPowerConnection,
            }
        })
        // console.log(this.props.details2)
    }
    getSetPageData = (serviceTypeIs, Pageid) => {

        if (serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == Pageid)[0] != null) {
            console.log('fddf');
            this.setState({
                actionView: true,
            })
        }

    };
    onDrawerClose = () => {
        console.log('sddsds')
        this.formRef.current.resetFields();
        this.setState({
            open: false,
            openEdit: false,
            selectedAssetId: null,
            AssetType: [],
            selectedMakeId: null,
            makeType: [],
            fbcCapacity: '',
            selectedModelId: null,
            modelType: [],
            fbcCapacity: '',
            fbclastMaintainanceDate: null,
            fbcwarrantyExpireDate: null,
            fbcInstalledDate: null,
            soohandletenant: '',
            sharedPowerType: '',
            sooDcPowerCapacity: '',
            sooBillingMethod: '',
            sooGenPowerNoofPhases: '',
            sooCommercialPowerNoofPhases: '',
            sooCommercialPowerPerPhaseCapacity: '',
            sooGenPowerPerPhaseCapacity: '',
            sooBillingAmount: '',
            sooBillingStartDate: null,

        })

    }
    onChangeAsset = (value) => {
        console.log("onChangeAsset");
        //console.log(value);
        this.setState({
            selectedAssetId: value,
            AssetType: {
                "id": value
            },

        })
    };
    onChangeMake = (value) => {
        console.log("onChangeMake");
        //console.log(value);
        this.setState({
            selectedMakeId: value,
            makeType: {
                "id": value
            },

        })
    };
    onChangeModel = (value) => {
        console.log("onChangeModel");
        //console.log(value);
        this.setState({
            selectedModelId: value,
            modelType: {
                "id": value
            },

        })
    };
    onChangeInstalledDate = (date, dateString) => {
        console.log(date);
        console.log(dateString);
        this.setState({
            fbcInstalledDate: dateString,
        });
    };
    onChangeBillingStartDate = (date, dateString) => {
        console.log(date);
        console.log(dateString);
        this.setState({
            sooBillingStartDate: dateString,
        });
    };
    onChangeLastMaintainanceDate = (date, dateString) => {
        console.log(date);
        console.log(dateString);
        this.setState({
            fbclastMaintainanceDate: dateString,
        });
    };

    AllDataSubmitUpdate = async () => {
        var dataSet;

        dataSet = {
            "id": this.state.sharedOperatorId,
            "site": { "id": this.props?.detailsSite?.site_details.id },
            "asset": {
                "id": 3
            },
            "tenant": this.state.soohandletenant,
            "sharedPowerType": this.state.sharedPowerType,
            "dcPowerCapacity": this.state.sooDcPowerCapacity,
            "billingMethod": this.state.sooBillingMethod,
            "commercialPowerNoofPhases": this.state.sooCommercialPowerNoofPhases,
            "commercialPowerPerPhaseCapacity": this.state.sooCommercialPowerPerPhaseCapacity,
            "genPowerNoofPhases": this.state.sooGenPowerNoofPhases,
            "genPowerPerPhaseCapacity": this.state.sooGenPowerPerPhaseCapacity,
            "billingStartDate": this.state.sooBillingStartDate,
            "billingAmount": this.state.sooBillingAmount
        }
        console.log(dataSet)
        var res = await UpdateSharedOperator(dataSet);
        console.log(res)
        if (res.success) {
            message.success('Successfully Shared-Out Operators Edited!');
            this.formRef.current.resetFields();

            this.setState({
                showModal: false,
                open: false,
                openEdit: false,

                selectedAssetId: null,
                AssetType: [],
                selectedMakeId: null,
                makeType: [],
                fbcCapacity: '',
                selectedModelId: null,
                modelType: [],
                fbcCapacity: '',
                fbclastMaintainanceDate: null,
                fbcwarrantyExpireDate: null,
                fbcInstalledDate: null,
                soohandletenant: '',
                sharedPowerType: '',
                sooDcPowerCapacity: '',
                sooBillingMethod: '',
                sooGenPowerNoofPhases: '',
                sooCommercialPowerNoofPhases: '',
                sooCommercialPowerPerPhaseCapacity: '',
                sooGenPowerPerPhaseCapacity: '',
                sooBillingAmount: '',
                sooBillingStartDate: null,
            })
            this.props.parentMethod();

        } else {
            message.warning(' Something went wrong!')
        }
    }
    AllDataSubmit = async () => {
        var dataSet;

        dataSet = {

            "site": { "id": this.props?.detailsSite?.site_details.id },
            "asset": {
                "id": 3
            },
            "tenant": this.state.soohandletenant,
            "sharedPowerType": this.state.sharedPowerType,
            "dcPowerCapacity": this.state.sooDcPowerCapacity,
            "billingMethod": this.state.sooBillingMethod,
            "commercialPowerNoofPhases": this.state.sooCommercialPowerNoofPhases,
            "commercialPowerPerPhaseCapacity": this.state.sooCommercialPowerPerPhaseCapacity,
            "genPowerNoofPhases": this.state.sooGenPowerNoofPhases,
            "genPowerPerPhaseCapacity": this.state.sooGenPowerPerPhaseCapacity,
            "billingStartDate": this.state.sooBillingStartDate,
            "billingAmount": this.state.sooBillingAmount
        }
        console.log(dataSet)
        var res = await createNewSharedOperator(dataSet);
        if (res.success) {
            message.success('Successfully Shared-Out Operators Added!');
            this.formRef.current.resetFields();

            this.setState({
                showModal: false,
                open: false,

                selectedAssetId: null,
                AssetType: [],
                selectedMakeId: null,
                makeType: [],
                fbcCapacity: '',
                selectedModelId: null,
                modelType: [],
                fbcCapacity: '',
                fbclastMaintainanceDate: null,
                fbcwarrantyExpireDate: null,
                fbcInstalledDate: null,
                soohandletenant: '',
                sharedPowerType: '',
                sooDcPowerCapacity: '',
                sooBillingMethod: '',
                sooGenPowerNoofPhases: '',
                sooCommercialPowerNoofPhases: '',
                sooCommercialPowerPerPhaseCapacity: '',
                sooGenPowerPerPhaseCapacity: '',
                sooBillingAmount: '',
                sooBillingStartDate: null,
            })
            this.props.parentMethod();
            // const { history } = this.props;
            // history.push("/site");
        } else {
            message.warning(' Something went wrong!')
        }

    }
    submitForm = async (id) => {
        var res = await getsharedOperatorDetails(id);
        console.log(res);
        if (res.success) {
            var respons = AssetDelete.Action(res.data, this.props?.detailsSite?.site_details.id, UpdateSharedOperator)
            respons.then((value) => {
                if (value == 1) {
                    message.success('Successfully Delete!')
                    this.props.parentMethod();
                } else {
                    message.warning(' Something went wrong!')
                }
            });
        }
    }

    viewMoreDetails(element) {
        this.setState({
            showModal2: true,
            openViewMoreDetails: true,
            selectedElement: element
        });
    }

    onViewMoreDetailsDrawerClose = () => {
        this.setState({
            showModal2: false,
            openViewMoreDetails: false,
        })
    }

    validatedTextLable = (field) => {
        return (field !== null && field !== '')
            ? <span class="badge badge-outline-secondary text-muted">{(field)}</span>
            : <span class="badge text-danger">Currently Not Set</span>;
    }


    onChangepowerConnectedDate = (date, dateString) => {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                powerConnectedDate: dateString
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    };
    handlsooCEBLECODepot = (e) => {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                sooCEBLECODepot: e.target.value
            }

        }, () => {
            console.log(this.state.objectSet)
        })

    }
    handlpowerConnectionAuthority = (e) => {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                powerConnectionAuthority: e.target.value
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }
    handlnoOfPIVCopies = (e) => {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                noOfPIVCopies: e
            }
        }, () => {
            console.log(this.state.objectSet)
        })
    }

    handlsooCapexAmount3PPowerConnection = (e) => {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                sooCapexAmount3PPowerConnection: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }


    dataUpdate() {
        console.log("ssss")
        this.setState({
            switchStatus: false
        })
    }
    close = async () => {
        this.props.parentMethod();
        this.setState({
            objectSet: {
                "noOfPIVCopies": this.props.details?.access_technologies_data?.noOfPIVCopies,
                "sooCEBLECODepot": this.props?.details?.access_technologies_data?.sooCEBLECODepot,
                "powerConnectionAuthority": this.props?.details?.access_technologies_data?.powerConnectionAuthority,
                "powerConnectedDate": this.props.details?.access_technologies_data?.powerConnectedDate,
                "sooCapexAmount3PPowerConnection": this.props.details?.access_technologies_data?.sooCapexAmount3PPowerConnection,
            }
        })
        this.setState({
            switchStatus: true
        });
    }
    dataSetToUpdate = async () => {

        console.log(this.state.objectSet)
        console.log(this.props.detailsupdateGeneralDetails.updateGeneralDetails)
        let remoteJob = {
            ...this.props.detailsupdateGeneralDetails.updateGeneralDetails,
            ...this.state.objectSet
        };
        const formData = new FormData();
        formData.append('id', this.props?.details?.access_technologies_data?.id);
        formData.append('object', JSON.stringify(remoteJob));

        var res = await dataUpdateGeneralDetails(formData);

        if (res.success) {
            message.success('Successfully Update!')
            this.props.parentMethod();
            this.setState({
                switchStatus: true
            })
        } else {
            message.warning(' Something went wrong!')
        }
    }

    render() {
        const { Option } = Select;
        const { Panel } = Collapse;
        const _shearedPower = this.state.selectedElement
        const columns2 = [
            {
                title: "Tenant",
                content: this.validatedTextLable(_shearedPower.tenant),
                details: [],
            },
            {
                title: "Shared power type",
                content: _shearedPower !== ""
                    ? <Tag color="gray" style={{ margin: "0px" }}><span>{_shearedPower.sharedPowerType}</span></Tag> : <></>,
                details: [],
            },
            {
                title: "Commercial - Power No of Phases",
                content: this.validatedTextLable(_shearedPower.commercialPowerNoofPhases),
                details: [],
            },
            {
                title: "Commercial Power - Per Phase Capacity (A)",
                content: this.validatedTextLable(_shearedPower.commercialPowerPerPhaseCapacity),
                details: [],
            },
            {
                title: "Gen Power - No of Phases",
                content: this.validatedTextLable(_shearedPower.genPowerNoofPhases),
                details: [],
            },
            {
                title: "Gen Power- Per Phase Capacity (A)",
                content: this.validatedTextLable(_shearedPower.genPowerPerPhaseCapacity),
                details: [],
            },
            {
                title: "DC Power Capacity (A)",
                content: this.validatedTextLable(_shearedPower.dcPowerCapacity),
                details: [],
            },
            {
                title: "Billing Method",
                content: this.validatedTextLable(_shearedPower.billingMethod),
                details: [],
            },
            {
                title: "Billing Start Date",
                content: this.validatedTextLable(_shearedPower.billingStartDate),
                details: [],
            },
            {
                title: "Billing Amount (LKR)",
                content: this.validatedTextLable(_shearedPower.billingAmount),
                details: [],
            },
        ];
        return (
            <>
                <div className='row'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div class="card" style={{ borderRadius: "10px", borderColor: "white" }}>
                                <div class="card-body">
                                    <div class="row">
                                        <div>
                                            <div className="d-flex justify-content-end align-items-center" style={{ marginBottom: "10px", marginRight: "10px" }}>
                                                {this.state.switchStatus
                                                    ?
                                                    <>
                                                        {checkPermissions('SITE_VIEW_GENERAL_USER') || checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                                                            <button type="button" class="btn btn-soft-primary btn-icon-square-sm"
                                                                style={{ borderRadius: 5, backgroundColor: '#F28502', borderColor: '#F28502' }}
                                                                onClick={() => this.dataUpdate()}> <i class="mdi mdi-pencil-outline" style={{ color: 'white' }}></i>
                                                            </button>) : (<></>)}

                                                        {checkPermissions('SITE_VIEW_GENERAL_USER') || checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                                                            <Button
                                                                type="button"
                                                                class="btn btn-soft-primary btn-sm"
                                                                style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', marginLeft: 10 }}
                                                                onClick={this.requestFromClose}
                                                                icon={<PlusCircleOutlined style={{ color: 'white' }} />}>
                                                                <span className="textStyles-small" style={{ color: 'white' }}>New Asset</span>
                                                            </Button>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </>
                                                    : <div class="d-flex justify-content-end align-items-center">
                                                        <button type="button" class="btn btn-soft-secondary " onClick={() => this.close()} style={{ marginRight: "10px" }}>
                                                            <span className='textStyles-small'>Cancel</span></button>
                                                        <button type="button" class="btn btn-soft-primary " onClick={() => this.dataSetToUpdate()}>
                                                            <span className='textStyles-small'>Submit to Update</span></button>
                                                    </div>
                                                }
                                            </div>
                                            <ul class="list-group">
                                                <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f8f9fa' }}>
                                                    <div>
                                                        <span className='textStyles-small'>
                                                            Total Capex Amount (LKR)
                                                        </span>
                                                    </div>
                                                    {this.state.switchStatus ? (<>{this.props.details?.access_technologies_data?.sooCapexAmount3PPowerConnection != null ?
                                                        (<span class="badge badge-outline-success textStyles-small">{this.props.details?.access_technologies_data?.sooCapexAmount3PPowerConnection}</span>) :
                                                        (<span class="badge badge-outline-warning textStyles-small">Currently Not Set</span>)}</>) : (<>
                                                            <InputNumber className='borderedSelect' min={0} style={{ width: '150px' }}
                                                                value={this.state.objectSet?.sooCapexAmount3PPowerConnection} id="airCoolingMethod" onChange={this.handlsooCapexAmount3PPowerConnection} /> </>)}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Row span={24}>
                        {this.props.details2.sharedOutOperatorses_data.map((element) => (
                            <Col span={8}>
                                <Card
                                    bordered={false}
                                    style={{
                                        width: '98%',
                                        borderRadius: '10px',
                                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                                        position: 'relative'
                                    }}
                                >
                                    <div style={{ display: 'flex', marginBottom: 16, backgroundColor: '#f8f9fa', padding: '10px', borderRadius: '10px' }}>
                                        <div>
                                            <HomeOutlined style={{ marginLeft: '3px', marginRight: '3px' }} /> &rarr;
                                            <span style={{ marginLeft: '3px', marginRight: '3px', fontWeight: 'bold' }} className="textStyles-small">{this.props?.detailsSite?.site_details.siteName}</span>
                                            &rarr;
                                            <span style={{ marginLeft: '3px', color: '#BA183A', fontWeight: 'bold' }} className="textStyles-small">{element.tenant}</span>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: 8 }}>
                                        <span className="textStyles-small">Tenant</span>
                                        <Tag color="default" style={{ float: 'right', borderRadius: 5 }}> <span className="textStyles-small">{element.tenant}</span></Tag>
                                    </div>
                                    <div style={{ marginBottom: 8 }}>
                                        <span className="textStyles-small">Shared power type</span>
                                        <Tag color="magenta" style={{ float: 'right', borderRadius: 5 }} tooltip="This is a required field">
                                            <span className="textStyles-small">
                                                {element.sharedPowerType.length > 20 ? element.sharedPowerType.substring(0, 15) + '...' : element.sharedPowerType}
                                            </span></Tag>
                                    </div>
                                    <div style={{ marginBottom: 8 }}>
                                        <span className="textStyles-small">DC Power Capacity (A)</span>
                                        <Tag color="green" style={{ float: 'right', borderRadius: 5 }}><span className="textStyles-small">{element.dcPowerCapacity} A</span></Tag>
                                    </div>
                                    <div style={{ marginBottom: 8 }}>
                                        <span className="textStyles-small">Gen Power- Per Phase Capacity (A)</span>
                                        <Tag color="orange" style={{ float: 'right', borderRadius: 5 }}><span className="textStyles-small">{element.genPowerPerPhaseCapacity} A</span></Tag>
                                    </div>
                                    <Divider style={{ marginTop: '3px' }} />
                                    <Button
                                        type="primary"
                                        style={{ marginRight: 8, borderRadius: '5px' }}
                                        size='small'
                                        onClick={() => this.viewMoreDetails(element)}>
                                        <span className="textStyles-small" style={{ fontSize: 10 }}>More Details</span>
                                    </Button>
                                    {checkPermissions('SITE_VIEW_GENERAL_USER') || checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                                        <Button
                                            icon={<EditOutlined style={{ color: 'white' }} />}
                                            style={{ marginRight: 8, float: 'right', borderRadius: 5, backgroundColor: '#F28502', borderColor: '#F28502' }}
                                            onClick={() => this.sharedOutOperatorsUpdata(element.id)}
                                            size='small'
                                        />) : ('')}
                                    <Popconfirm
                                        title={<span className='textStyles-small' style={{ fontSize: 14 }}>Are you sure to delete this Asset?</span>}
                                        description={<span className="textStyles-small">Are you sure to delete this Asset?</span>}
                                        onConfirm={() => this.submitForm(element.id)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        {checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                                            <Button
                                                icon={<DeleteOutlined style={{ color: 'white' }} />}
                                                danger
                                                size='small'
                                                style={{ marginRight: 8, float: 'right', borderRadius: 5, backgroundColor: '#BA183A', borderColor: '#BA183A' }}
                                            />
                                        )
                                            : ('')}
                                    </Popconfirm>
                                </Card>
                            </Col>
                        ))}
                    </Row>

                    <Drawer maskClosable={false}
                        title={<span className='textStyles-small' style={{ fontSize: '14px' }}>Shared Out-Operators Info</span>}
                        width={"50%"} placement="right" onClose={() => this.onViewMoreDetailsDrawerClose()}
                        visible={this.state.openViewMoreDetails} closeIcon={<button type="button"
                            class="btn btn-soft-danger btn-icon-square-sm" style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                        <Collapse
                            style={{ alignContent: "center", borderColor: "white" }}
                        >
                            {columns2.map((item, index) => (
                                <Panel
                                    style={{
                                        borderColor: index % 2 === 0 ? "#f8f9fa" : "white",
                                        backgroundColor: index % 2 === 0 ? "#f8f9fa" : "white"
                                    }}
                                    header={
                                        <li className="d-flex justify-content-between align-items-center"
                                            style={{ backgroundColor: index % 2 === 0 ? "#f8f9fa" : "white" }}>
                                            <div>
                                                <span className="textStyles-small">{item.title}</span>
                                            </div>
                                            <span className="textStyles-small">{item.content}</span>
                                        </li>
                                    }
                                    key={item.title}
                                    showArrow={item.details.length > 0}
                                    collapsible={item.details.length > 0 ? "" : "icon"}
                                >
                                    {item.details.map((detail) => (
                                        <li
                                            className="d-flex justify-content-between align-items-center me-2"
                                            style={{ paddingBottom: "10px", paddingLeft: "10px" }}
                                            key={detail.title}
                                        >
                                            <div>
                                                <span className='textStyles-small'>{detail.title}</span>
                                            </div>
                                            <span className="textStyles-small">{detail.content}</span>
                                        </li>
                                    ))}
                                </Panel>
                            ))}
                        </Collapse>

                    </Drawer>

                    <Drawer
                        maskClosable={false}
                        title={<span className='textStyles-small' style={{ fontSize: '14px' }}>New SharedOut Operator</span>}
                        width={"60%"} placement="right"
                        onClose={() => this.onDrawerClose()}
                        visible={this.state.open}
                        closeIcon={<button type="button"
                            class="btn btn-soft-danger btn-icon-square-sm"
                            style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                        <div className="row" >
                            <div className="col-lg-12">
                                <div className="card" style={{ borderColor: 'white' }}>
                                    <div className="card-body">
                                        <Form
                                            ref={this.formRef}
                                        >
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="textStyles-small" style={{ fontSize: '12px' }} for="dcdu">
                                                        Tenant <x style={{ color: "#ff0000" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="tenant" tooltip="This is a required field">
                                                        <div>
                                                            <Select
                                                                id='tenant'
                                                                showSearch
                                                                style={{ width: '100%' }}
                                                                className='borderedSelect'
                                                                bordered={false}
                                                                onChange={(e) => {
                                                                    this.handletenant(e);
                                                                }}>
                                                                {this.props.dropdownSoo["dropdownObjSoo"]["soohandletenant"]?.map((x) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                        <p className="textStyles-small" style={{ color: "#f5325c", fontSize: '11px', marginTop: 5 }}>
                                                            {this.state.soohandletenant == null || this.state.soohandletenant == "" ? "This is a required field" : ""}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="textStyles-small" style={{ fontSize: '12px' }} for="SharedPowerType">
                                                        Shared Power Type <x style={{ color: "#ff0000" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="SharedPowerType" tooltip="This is a required field">
                                                        <div>
                                                            <Select
                                                                id='SharedPowerType'
                                                                className='borderedSelect'
                                                                style={{ width: '100%' }}
                                                                bordered={false}
                                                                onChange={(e) => {
                                                                    this.handleSharedPowerType(e);
                                                                }}>
                                                                {this.props.dropdownSoo["dropdownObjSoo"]["sharedPowerType"]?.map((x) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                        <p className="textStyles-small" style={{ color: "#f5325c", fontSize: '11px', marginTop: 5 }}>
                                                            {this.state.sharedPowerType == null || this.state.sharedPowerType == "" ? "This is a required field" : ""}</p>
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="textStyles-small" style={{ fontSize: '12px' }} for="dcPowerCapacity">
                                                        Commercial Power No of Phases
                                                    </label>
                                                    <Form.Item required name="commercialPowerNoofPhases" >
                                                        <div>
                                                            <Select
                                                                id='commercialPowerNoofPhases'
                                                                className='borderedSelect'
                                                                bordered={false}
                                                                style={{ width: '100%' }}
                                                                onChange={(e) => {
                                                                    this.handleCommercialPowerNoofPhases(e);
                                                                }}>
                                                                {this.props.dropdownSoo["dropdownObjSoo"]["sooCommercialPowerNoofPhases"]?.map((x) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="textStyles-small" style={{ fontSize: '12px' }} for="dcPowerCapacity">
                                                        Commercial Power - Per Phase Capacity (A)
                                                    </label>
                                                    <Form.Item required name="commercialPowerPerPhaseCapacity" >
                                                        <div>
                                                            <Select
                                                                id='commercialPowerPerPhaseCapacity'
                                                                className='borderedSelect'
                                                                bordered={false}
                                                                style={{ width: '100%' }}
                                                                onChange={(e) => {
                                                                    this.handleCommercialPowerPerPhaseCapacity(e);
                                                                }}>
                                                                {this.props.dropdownSoo["dropdownObjSoo"]["sooCommercialPowerPerPhaseCapacity"]?.map((x) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="textStyles-small" style={{ fontSize: '12px' }} for="dcPowerCapacity">
                                                        Gen Power No of Phases
                                                    </label>
                                                    <Form.Item required name="genPowerNoofPhases" >
                                                        <div>
                                                            <Select
                                                                id='genPowerNoofPhases'
                                                                className='borderedSelect'
                                                                bordered={false}
                                                                style={{ width: '100%' }}
                                                                onChange={(e) => {
                                                                    this.handleGenPowerNoofPhases(e);
                                                                }}>
                                                                {this.props.dropdownSoo["dropdownObjSoo"]["sooGenPowerNoofPhases"]?.map((x) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="textStyles-small" style={{ fontSize: '12px' }} for="genPowerPerPhaseCapacity">
                                                        Gen Power- Per Phase Capacity (A)
                                                    </label>
                                                    <Form.Item required name="genPowerPerPhaseCapacity" >
                                                        <div>
                                                            <Select
                                                                id='genPowerPerPhaseCapacity'
                                                                style={{ width: '100%' }}
                                                                className='borderedSelect'
                                                                bordered={false}
                                                                onChange={(e) => {
                                                                    this.handleGenPowerPerPhaseCapacity(e);
                                                                }}>
                                                                {this.props.dropdownSoo["dropdownObjSoo"]["sooGenPowerPerPhaseCapacity"]?.map((x) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="textStyles-small" style={{ fontSize: '12px' }} for="dcPowerCapacity">
                                                        DC Power Capacity (A)
                                                    </label>
                                                    <Form.Item required name="dcPowerCapacity" >
                                                        <div>
                                                            <Select
                                                                id='dcPowerCapacity'
                                                                style={{ width: '100%' }}
                                                                className='borderedSelect'
                                                                bordered={false}
                                                                onChange={(e) => {
                                                                    this.handleDCPowerCapacity(e);
                                                                }}>
                                                                {this.props.dropdownSoo["dropdownObjSoo"]["sooDcPowerCapacity"]?.map((x) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="textStyles-small" style={{ fontSize: '12px' }} for="dcdu">
                                                        Billing Method
                                                    </label>
                                                    <Form.Item required name="billingMethod" tooltip="This is a required field">
                                                        <div>
                                                            <Select
                                                                className='borderedSelect'
                                                                bordered={false}
                                                                id='billingMethod'
                                                                style={{ width: '100%' }}
                                                                onChange={(e) => {
                                                                    this.handleBillingMethod(e);
                                                                }}>
                                                                {this.props.dropdownSoo["dropdownObjSoo"]["sooBillingMethod"]?.map((x) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="textStyles-small" style={{ fontSize: '12px' }} for="depot">
                                                        Billing Start Date
                                                    </label>
                                                    <Form.Item required name="billingStartDate" tooltip="This is a required field">
                                                        <DatePicker
                                                            className='borderedSelect'
                                                            style={{ width: "100%", height: '34px' }}
                                                            onChange={this.onChangeBillingStartDate}
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label
                                                        className="textStyles-small"
                                                        style={{ fontSize: '12px' }}
                                                        for="billingAmount">
                                                        Billing Amount (LKR)
                                                    </label>
                                                    <Form.Item required name="billingAmount" >
                                                        <InputNumber
                                                            className='borderedSelect'
                                                            style={{ width: '100%', height: '34p' }}
                                                            id="billingAmount"
                                                            onChange={this.handleBillingAmount} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Form>
                                        <div className='row' style={{ justifyContent: "end" }}>
                                            <div className='col-md-2' >
                                                {this.state.soohandletenant == null || this.state.soohandletenant == "" || this.state.sharedPowerType == null || this.state.sharedPowerType == "" ?
                                                    (<Button className="btn btn-primary" disabled style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', marginRight: '5px' }}>
                                                        <span className="textStyles-small" style={{ color: 'white' }}>Add This Asset</span>
                                                    </Button>) :
                                                    (<Button className="btn btn-primary" onClick={() => this.AllDataSubmit()}
                                                        style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', marginRight: '5px' }}>
                                                        <span className="textStyles-small" style={{ color: 'white' }}>Add This Asset</span>
                                                    </Button>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Drawer>

                    <Drawer maskClosable={false}
                        title={<span className='textStyles-small' style={{ fontSize: '14px' }}>Edit SharedOut Operators</span>}
                        width={"70%"} placement="right" onClose={() => this.onDrawerClose()}
                        visible={this.state.openEdit} closeIcon={<button type="button"
                            class="btn btn-soft-danger btn-icon-square-sm"
                            style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                        <div className="row" >
                            <div className="col-lg-12">
                                <div className="card" style={{ borderColor: 'white' }}>
                                    <div className="card-body">
                                        <Form
                                            ref={this.formRef}
                                        >
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="textStyles-small" style={{ fontSize: '12px' }} for="dcdu">
                                                        Tenant <x style={{ color: "#ff0000" }}>*</x>
                                                    </label>
                                                    <Form.Item >
                                                        <div>
                                                            <Select
                                                                bordered={false}
                                                                className='borderedSelect'
                                                                id='tenant'
                                                                showSearch
                                                                value={this.state.soohandletenant}
                                                                style={{ width: '100%' }}
                                                                onChange={(e) => {
                                                                    this.handletenant(e);
                                                                }}>
                                                                {this.props.dropdownSoo["dropdownObjSoo"]["soohandletenant"]?.map((x) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                        <p
                                                            className='textStyles-small'
                                                            style={{ color: "#f5325c", fontSize: '11px', marginTop: 5 }}>
                                                            {this.state.soohandletenant == null || this.state.soohandletenant == "" ? "This is a required field" : ""}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="textStyles-small" style={{ fontSize: '12px' }} for="SharedPowerType">
                                                        Shared Power Type <x style={{ color: "#ff0000" }}>*</x>
                                                    </label>
                                                    <Form.Item>
                                                        <div>
                                                            <Select
                                                                bordered={false}
                                                                className='borderedSelect'
                                                                id='SharedPowerType'
                                                                value={this.state.sharedPowerType}
                                                                style={{ width: '100%' }}
                                                                onChange={(e) => {
                                                                    this.handleSharedPowerType(e);
                                                                }}>
                                                                {this.props.dropdownSoo["dropdownObjSoo"]["sharedPowerType"]?.map((x) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                        <p
                                                            className='textStyles-small'
                                                            style={{ color: "#f5325c", fontSize: '11px', marginTop: 5 }}>
                                                            {this.state.sharedPowerType == null || this.state.sharedPowerType == "" ? "This is a required field" : ""}</p>
                                                    </Form.Item>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="textStyles-small" style={{ fontSize: '12px' }} for="dcPowerCapacity">
                                                        Commercial Power No of Phases
                                                    </label>
                                                    <Form.Item >
                                                        <div>
                                                            <Select
                                                                bordered={false}
                                                                className='borderedSelect'
                                                                id='commercialPowerNoofPhases'
                                                                style={{ width: '100%' }}
                                                                value={this.state.sooCommercialPowerNoofPhases}
                                                                onChange={(e) => {
                                                                    this.handleCommercialPowerNoofPhases(e);
                                                                }}>
                                                                {this.props.dropdownSoo["dropdownObjSoo"]["sooCommercialPowerNoofPhases"]?.map((x) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="textStyles-small" style={{ fontSize: '12px' }} for="dcPowerCapacity">
                                                        Commercial Power - Per Phase Capacity (A)
                                                    </label>
                                                    <Form.Item  >
                                                        <div>
                                                            <Select
                                                                bordered={false}
                                                                className='borderedSelect'
                                                                id='commercialPowerPerPhaseCapacity'
                                                                value={this.state.sooCommercialPowerPerPhaseCapacity}
                                                                style={{ width: '100%' }}
                                                                onChange={(e) => {
                                                                    this.handleCommercialPowerPerPhaseCapacity(e);
                                                                }}>
                                                                {this.props.dropdownSoo["dropdownObjSoo"]["sooCommercialPowerPerPhaseCapacity"]?.map((x) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Form.Item>
                                                </div>


                                                <div className="col-md-6">
                                                    <label className="textStyles-small" style={{ fontSize: '12px' }} for="dcPowerCapacity">
                                                        Gen Power No of Phases
                                                    </label>
                                                    <Form.Item >
                                                        <div>
                                                            <Select
                                                                bordered={false}
                                                                className='borderedSelect'
                                                                id='genPowerNoofPhases'
                                                                value={this.state.sooGenPowerNoofPhases}
                                                                style={{ width: '100%' }}
                                                                onChange={(e) => {
                                                                    this.handleGenPowerNoofPhases(e);
                                                                }}>
                                                                {this.props.dropdownSoo["dropdownObjSoo"]["sooGenPowerNoofPhases"]?.map((x) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="textStyles-small" style={{ fontSize: '12px' }} for="genPowerPerPhaseCapacity">
                                                        Gen Power- Per Phase Capacity (A)
                                                    </label>
                                                    <Form.Item  >
                                                        <div>
                                                            <Select
                                                                bordered={false}
                                                                className='borderedSelect'
                                                                value={this.state.sooGenPowerPerPhaseCapacity}
                                                                id='genPowerPerPhaseCapacity'
                                                                style={{ width: '100%' }}
                                                                onChange={(e) => {
                                                                    this.handleGenPowerPerPhaseCapacity(e);
                                                                }}>
                                                                {this.props.dropdownSoo["dropdownObjSoo"]["sooGenPowerPerPhaseCapacity"]?.map((x) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Form.Item>
                                                </div>


                                                <div className="col-md-6">
                                                    <label className="textStyles-small" style={{ fontSize: '12px' }} for="dcPowerCapacity">
                                                        DC Power Capacity (A)
                                                    </label>
                                                    <Form.Item >
                                                        <div>
                                                            <Select
                                                                bordered={false}
                                                                className='borderedSelect'
                                                                id='dcdu_Capacity'
                                                                value={this.state.sooDcPowerCapacity}
                                                                style={{ width: '100%' }}
                                                                onChange={(e) => {
                                                                    this.handleDCPowerCapacity(e);
                                                                }}>
                                                                {this.props.dropdownSoo["dropdownObjSoo"]["sooDcPowerCapacity"]?.map((x) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="textStyles-small" style={{ fontSize: '12px' }} for="dcdu">
                                                        Billing Method
                                                    </label>
                                                    <Form.Item>
                                                        <div>
                                                            <Select
                                                                bordered={false}
                                                                className='borderedSelect'
                                                                id='billingMethod'
                                                                value={this.state.sooBillingMethod}
                                                                style={{ width: '100%' }}
                                                                onChange={(e) => {
                                                                    this.handleBillingMethod(e);
                                                                }}>
                                                                {this.props.dropdownSoo["dropdownObjSoo"]["sooBillingMethod"]?.map((x) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Form.Item>
                                                </div>
                                                {this.state.sooBillingStartDate != null ? (
                                                    <div className="col-md-6">
                                                        <label className="textStyles-small" style={{ fontSize: '12px' }} for="depot">
                                                            Billing Start Date
                                                        </label>
                                                        <Form.Item >
                                                            <DatePicker
                                                                className='borderedSelect'
                                                                defaultValue={this.state.sooBillingStartDate != '' ? (moment(this.state.sooBillingStartDate)) : ("")}
                                                                style={{ width: "100%", height: '34px' }}
                                                                onChange={this.onChangeBillingStartDate}
                                                            />
                                                        </Form.Item>
                                                    </div>) : ('')
                                                }
                                                <div className="col-md-6">
                                                    <label className="textStyles-small" style={{ fontSize: '12px' }} for="billingAmount">
                                                        Billing Amount (LKR)
                                                    </label>
                                                    <Form.Item  >
                                                        <InputNumber
                                                            className='borderedSelect'
                                                            style={{ width: '100%', height: '34px' }} value={this.state.sooBillingAmount} id="billingAmount" onChange={this.handleBillingAmount} />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                        </Form>
                                        <div className='row' style={{ justifyContent: "end" }}>
                                            <div className='col-md-2' >
                                                {this.state.soohandletenant == null || this.state.soohandletenant == "" || this.state.sharedPowerType == null || this.state.sharedPowerType == "" ?
                                                    (<Button className="btn btn-primary" disabled style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', marginRight: '5px' }}>
                                                        <span className="textStyles-small" style={{ color: 'white' }}>Update This Asset </span>
                                                    </Button>) :
                                                    (<Button className="btn btn-primary" onClick={() => this.AllDataSubmitUpdate()} style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', marginRight: '5px' }}>
                                                        <span className="textStyles-small" style={{ color: 'white' }}>Update This Asset </span>
                                                    </Button>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Drawer>
                </div>


            </>
        )
    }
}
export default sharedOutOperators;
