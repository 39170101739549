import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { createNewVendor, getAllVendors, updateVendorByid, getAllDepots, removeVendorDataAccessFunction } from '../../api/index';
import { addVendor, removeVendor, updateVendor, getData, getDonationCategories } from '../../actions/index';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'material-react-toastify';
import { Collapse, Select, Button, Tag, message, Row, Col, Form, Modal, Input, Result, Table, Drawer, Menu, Dropdown, Descriptions, form, Switch, Checkbox } from 'antd';
import { ExclamationCircleOutlined, DeleteOutlined, EditOutlined, PlusCircleFilled, MoreOutlined, EyeOutlined, MinusCircleOutlined } from '@ant-design/icons';
import './../master-data/style.css';
import 'material-react-toastify/dist/ReactToastify.css';
import depotList from '../depot/depot-list';
const options = [];

class Vendor extends Component {
	constructor(props) {
		super(props);
		this.validator = new SimpleReactValidator();
		this.state = {
			name: '',
			code: '',
			vatNumber: '',
			contactNumber: '',
			contactPerson: '',
			contactEmail: '',
			contactAddress: '',
			depotListId: [],
			filterTable: null,
			vendors: [],
			depotList: [],
			editDrawerVisible: false,
			editVendorDetails: {
				id: null,
				name: '',
				code: '',
				vatNumber: '',
				contactNumber: '',
				contactPerson: '',
				contactEmail: '',
				contactAddress: '',
				depotListId: [],
				isAcService: false,
				isCrService: false,
				isGenService: false,
				isCleaningService: false,
				isRefuelService: false,
			},
			isEdit: false,
			isOpenModal: false,
			isView: false,
		};
	}

	componentDidMount() {
		this.props.getData();
		this.getAllVendors();
		this.getAllDepotes();
	}

	getAllVendors = async () => {
		var res = await getAllVendors();
		this.setState({
			vendors: res.data ? res.data : [],
		});

	};

	getAllDepotes = async () => {
		var res = await getAllDepots();
		this.setState({
			depotList: res.data,
		});
	};

	sucessMessage = (messageInfo) => {
		message.success('System Message : ' + messageInfo);
	};

	errorMessage = (messageInfo) => {
		message.error('System Message : ' + messageInfo);
	};

	warningMessage = (messageInfo) => {
		message.warning('System Message : ' + messageInfo);
	};


	showCreateConfirmationModal = (values) => {
		Modal.confirm({
			title: 'Confirm',
			icon: <ExclamationCircleOutlined />,
			content: 'Do you want to register a new vendor?',
			okText: 'Yes',
			cancelText: 'No',
			onOk: () => this.createVendor(values),
		});
	};

	closePopup = () => {
		Modal.destroyAll();
	};

	showUpdateConfirmationModal = (values) => {
		Modal.confirm({
			title: 'Confirm',
			icon: <ExclamationCircleOutlined />,
			content: 'Do you want to update this vendor?',
			okText: 'Yes',
			cancelText: 'No',
			onOk: () => this.updateVendor(values),
		});
	};

	createVendor = async (values) => {
		// Navigate to the same page, effectively refreshing it
		//window.location.href = window.location.href;

		console.log(values);

		this.closePopup();
		// if (this.validator.allValid()) {
		// 	const isValidMobileNumber = /^[0-9]{10}$/.test(this.state.contactNumber);
		// 	if (!isValidMobileNumber) {
		// 		this.errorMessage('Please enter a valid 10-digit mobile number');
		// 		return;
		// 	}

		// 	// var data = {
		// 	// 	"name": this.state.name,
		// 	// 	"code": this.state.code,
		// 	// 	"vatNumber": this.state.vatNumber,
		// 	// 	"contactNumber": this.state.contactNumber,
		// 	// 	"contactPerson": this.state.contactPerson,
		// 	// 	"contactEmail": this.state.contactEmail,
		// 	// 	"contactAddress": this.state.contactAddress,
		// 	// 	"depot": this.state.depotListId.map((id) => {
		// 	// 		return {
		// 	// 			"id": id,
		// 	// 		};
		// 	// 	}),
		// 	// }
		// 	var res = await createNewVendor(values);
		// 	if (res.success) {
		// 		this.sucessMessage('New Vendor Registered Successfully');
		// 		this.props.addVendor(res.data.data);
		// 	}
		// } else {
		// 	this.validator.showMessages();
		// 	this.forceUpdate();
		// 	this.errorMessage("Please fill all the required fields");
		// }
		var data = {
			"name": values.name,
			"code": values.code,
			"vatNumber": values.vatNumber,
			"contactNumber": values.contactNumber,
			"contactPerson": values.contactPerson,
			"contactEmail": values.contactEmail,
			"acService": values.isAcService ? values.isAcService : false,
			"crService": values.isCrService ? values.isCrService : false,
			"genService": values.isGenService ? values.isGenService : false,
			"cleaningService": values.isCleaningService ? values.isCleaningService : false,
			"refuelService": values.isRefuelService ? values.isRefuelService : false,
			"contactAddress": values.contactAddress,
			"depot": values.depotListId.map((id) => {
				return {
					"id": id,
				};
			}),
		}
		const response = await createNewVendor(data);
		if (response.success) {
			this.sucessMessage('New Vendor Registered Successfully');
			// this.props.addVendor(response.data.data);
			this.setState({
				isEdit: false,
				isOpenModal: false,
				isView: false,
			});
			this.props.getData();
			this.getAllVendors();
		} else {
			this.errorMessage('Failed to register new vendor');
		}
	}

	clearForm = () => {
		this.setState({
			name: '',
			code: '',
			vatNumber: '',
			contactNumber: '',
			contactPerson: '',
			contactEmail: '',
			contactAddress: '',
			depotListId: [],
			isAcService: false,
			isCrService: false,
			isGenService: false,
			isCleaningService: false,
			isRefuelService: false,
		});
	};

	formItemChange = (event) => {
		var obj = {};
		obj[event.target.name] = event.target.value;
		this.setState(obj);
	};

	search = (value) => {
		const filterTable = this.state.vendors.filter((o) =>
			Object.keys(o).some((k) =>
				String(o[k]).toLowerCase().includes(value.toLowerCase())
			)
		);
		this.setState({ filterTable });
	};

	showDeleteConfirm = (record) => {
		const { confirm } = Modal;
		confirm({
			title: 'Do you want to deactivate this vendor?',
			onOk: async () => {
				this.deleteDepotFromId(record.id);
			},
			onCancel() { },
		});
	};

	deleteDepotFromId = async (id) => {
		const formData = new FormData();
		formData.append('vendorId', id);
		var res = await removeVendorDataAccessFunction(formData);
		if (res.success) {
			toast.success('Vendor Deactivated successfully');
			this.props.getData();
			this.getAllVendors();
		} else {
			toast.error(res?.data);
		}
	}

	showEditDrawer = (record) => {
		this.setState({
			editDrawerVisible: true,
			name: record.name,
			code: record.code,
			vatNumber: record.vatNumber,
			contactNumber: record.contactNumber,
			contactPerson: record.contactPerson,
			contactEmail: record.contactEmail,
			contactAddress: record.contactAddress,

			isAcService: record.acService,
			isCrService: record.crService,
			isGenService: record.genService,
			isCleaningService: record.cleaningService,
			isRefuelService: record.refuelService,
			
			depotListId: record.depot.map((depot) => depot.id),
			editVendorDetails: {
				id: record.id,
				name: record.name,
				code: record.code,
				vatNumber: record.vatNumber,
				contactNumber: record.contactNumber,
				contactPerson: record.contactPerson,
				contactEmail: record.contactEmail,
				contactAddress: record.contactAddress,
				isAcService: record.acService,
				isCrService: record.crService,
				isGenService: record.genService,
				isCleaningService: record.cleaningService,
				isRefuelService: record.refuelService,
				// depot : record.depot.map((depot) => {
				// 	return {
				// 		"id": depot.id,
				// 	};
				// }),
			},
		});
	};

	closeEditDrawer = () => {
		this.setState({
			editDrawerVisible: false,
			name: '',
			code: '',
			vatNumber: '',
			contactNumber: '',
			contactPerson: '',
			contactEmail: '',
			contactAddress: '',
			depotListId: [],
			isAcService: false,
			isCrService: false,
			isGenService: false,
			isCleaningService: false,
			isRefuelService: false,
		});
	};

	handleCancelFunction = () => {
		this.setState({
			isOpenModal: false,
			isEdit: false,
			isView: false
		});

		this.clearForm();
		this.closeEditDrawer();
	}

	editFormItemChange = (event, type, val) => {
		if (type === 'depot') {
			this.setState((prevState) => ({
				editVendorDetails: {
					depot: val,
				},
			}));
		} else {
			const { name, value } = event.target;
			this.setState((prevState) => ({
				editVendorDetails: {
					...prevState.editVendorDetails,
					[name]: value,
				},
			}));
		}
		// const { name, value } = event.target;
		// this.setState((prevState) => ({
		// 	editVendorDetails: {
		// 		...prevState.editVendorDetails,
		// 		[name]: value,
		// 	},
		// }));

		var obj = {};
		obj[event.target.name] = event.target.value;
		this.setState(obj);
	};

	// editFormItemChange = (event, type, val) => {
	// 	if (type === 'depot') {
	// 		this.setState((prevState) => ({
	// 			editVendorDetails: {
	// 				...prevState.editVendorDetails,
	// 				depot: val.map(id => ({ id })),
	// 			},
	// 			depotListId: val,
	// 		}));
	// 	} else {
	// 		const { name, value } = event.target;
	// 		this.setState((prevState) => ({
	// 			editVendorDetails: {
	// 				...prevState.editVendorDetails,
	// 				[name]: value,
	// 			},
	// 		}));
	// 	}
	// };


	updateVendor = async (values) => {
		// this.closePopup();
		// if (this.validator.allValid()) {
		// 	const isValidMobileNumber = /^[0-9]{10}$/.test(this.state.contactNumber);
		// 	if (!isValidMobileNumber) {
		// 		this.errorMessage('Please enter a valid 10-digit mobile number');
		// 		return;
		// 	}
		// 	var res = await updateVendorByid(this.state.editVendorDetails, this.state.editVendorDetails.id);
		// 	if (res.success) {
		// 		toast.success('Vendor updated successfully');
		// 		this.closeEditDrawer();
		// 		window.location.reload();
		// 	}
		// } else {
		// 	this.validator.showMessages();
		// 	this.forceUpdate();
		// }
		var data = {
			"id": this.state.editVendorDetails.id,
			"name": values.name,
			"code": values.code,
			"vatNumber": values.vatNumber,
			"contactNumber": values.contactNumber,
			"contactPerson": values.contactPerson,
			"contactEmail": values.contactEmail,
			"contactAddress": values.contactAddress,
			"acService": values.isAcService ? values.isAcService : false,
			"crService": values.isCrService ? values.isCrService : false,
			"genService": values.isGenService ? values.isGenService : false,
			"cleaningService": values.isCleaningService ? values.isCleaningService : false,
			"refuelService": values.isRefuelService ? values.isRefuelService : false,
			"depot": values.depotListId.map((id) => {
				return {
					"id": id,
				};
			}),
		}
		const response = await updateVendorByid(data, this.state.editVendorDetails.id);
		if (response.success) {
			toast.success('Vendor updated successfully');
			this.setState({
				isEdit: false,
				isOpenModal: false,
				isView: false
			});
			this.closeEditDrawer();
			this.props.getData();
			this.getAllVendors();
		} else {
			toast.error('Failed to update vendor');
		}
	};

	render() {
		const columns = [
			{
				title: <span className='textStyles-small' style={{ fontSize: 11 }}>Vendor Name</span>,
				dataIndex: 'name',
				key: 'name',
				render: (text) => (
					<span className='textStyles-small'>{text === ' ' ? <i>No name</i> : text}</span>
				),
			},
			{
				title: <span className='textStyles-small' style={{ fontSize: 11 }}>Vendor Code</span>,
				dataIndex: 'code',
				key: 'code',
				render: (text) => <span className='textStyles-small'>{text}</span>,
			},
			{
				title: <span className='textStyles-small' style={{ fontSize: 11 }}>VAT Number</span>,
				dataIndex: "vatNumber",
				key: "vatNumber",
				render: (text) => <span className='textStyles-small'>{text}</span>,
			},
			{
				title: <span className='textStyles-small' style={{ fontSize: 11 }}>Contact Number</span>,
				dataIndex: "contactNumber",
				key: "contactNumber",
				render: (text) => <span className='textStyles-small'>{text}</span>,
			},
			{
				title: <span className='textStyles-small' style={{ fontSize: 11 }}>Contact Person</span>,
				dataIndex: "contactPerson",
				key: "contactPerson",
				render: (text) => <span className='textStyles-small'>{text}</span>,
			},
			{
				title: <span className='textStyles-small' style={{ fontSize: 11 }}>Contact Email</span>,
				dataIndex: "contactEmail",
				key: "contactEmail",
				render: (text) => <span className='textStyles-small'>{text}</span>,
			},
			{
				title: <span className='textStyles-small' style={{ fontSize: 11 }}>Contact Address</span>,
				dataIndex: "contactAddress",
				key: "contactAddress",
				render: (text) => <span className='textStyles-small'>{text}</span>,
			},
			// {
			// 	title: <span className='textStyles-small' style={{ fontSize: 11 }}>Depot</span>,
			// 	dataIndex: "depot",
			// 	key: "depot",
			// 	render: (text) =>
			// 		<span className='textStyles-small'>
			// 			{text.map((depot) => (
			// 				<span className='textStyles-small' key={depot.id}>{depot.name} , </span>
			// 			))}
			// 		</span>,
			// },
			{
				title: <span className='textStyles-small' style={{ fontSize: 11 }}>Action</span>,
				key: 'action',
				render: (text, record, index) => {
					const menu = (
						<Menu>
							<Menu.Item key="2"
								icon={<EyeOutlined />}
								onClick={() => {
									this.setState({
										isView: true,
										isOpenModal: true,
										name: record.name,
										code: record.code,
										vatNumber: record.vatNumber,
										contactNumber: record.contactNumber,
										contactPerson: record.contactPerson,
										contactEmail: record.contactEmail,
										contactAddress: record.contactAddress,
										depotListId: record.depot,
										isAcService: record.acService,
										isCrService: record.crService,
										isGenService: record.genService,
										isCleaningService: record.cleaningService,
										isRefuelService: record.refuelService,
									});
								}}>
								<span className='textStyles-small'>View Vendor</span>
							</Menu.Item>
							<Menu.Item key="0" icon={<EditOutlined />}
								onClick={() => {
									this.showEditDrawer(record);
									this.setState({
										isEdit: true,
										isOpenModal: true
									});
								}}>
								<span className='textStyles-small'>Edit Vendor</span>
							</Menu.Item>
							<Menu.Item key="1" onClick={() => this.showDeleteConfirm(record)} icon={<MinusCircleOutlined />}>
								<span className='textStyles-small'>Deactivate Vendor</span>
							</Menu.Item>
						</Menu>
					);
					return (
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<Dropdown overlay={menu} trigger={['click']}>
								<Button shape='circle' icon={<MoreOutlined />} size='small' type='primary' />
							</Dropdown>
						</div>
					);
				},
			}
		];

		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<div className="page-title-box">
							<div className="row">
								<div className="col">
									<h4 className="page-title">Vendor</h4>
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a href="javascript:void(0);">Admin</a>
										</li>
										<li className="breadcrumb-item">
											<a href="javascript:void(0);">Vendor</a>
										</li>
									</ol>
								</div>
								<div className="col-auto align-self-center">
									<a href="#" className="btn btn-sm btn-outline-primary" id="Dash_Date">
										<span className="day-name" id="Day_Name">Today:</span>&nbsp;
										<span className="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-calendar align-self-center icon-xs ms-1">
											<rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
											<line x1="16" y1="2" x2="16" y2="6"></line>
											<line x1="8" y1="2" x2="8" y2="6"></line>
											<line x1="3" y1="10" x2="21" y2="10"></line>
										</svg>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* <div className="row">
					<div>
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">
									<b>Vendor List</b>
									<Button
										type="primary"
										style={{ float: 'right', borderRadius: 10 }}
										onClick={() => window.location.reload()}
									> Reload Vendor List </Button>
								</h4>
							</div>
							<div>
							</div>
						</div>
					</div>
				</div> */}
				<div style={{ margin: '0', padding: '10px', width: '100%', backgroundColor: 'white', borderRadius: '10px', marginTop: '20px' }}>
					<Row>
						<Col span={12}>
							<Button type="primary" onClick={() => this.setState({ isOpenModal: true, isEdit: false })}
								style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px', margin: 10 }}><PlusCircleFilled />
								<span className="textStyles-small">New Vendor</span></Button>
						</Col>
						<Col span={12} style={{ textAlign: 'right' }}>
							<Input
								enterButton
								className='borderedSelect' style={{ height: 33, margin: 10, marginRight: 10, width: 350 }}
								placeholder="Search Vendors ..."
								onChange={(e) => {
									const currValue = e.target.value;
									this.search(currValue);
								}}
							/>
						</Col>
					</Row>
					<Table
						className="table-striped-rows"
						size="small"
						columns={columns}
						dataSource={
							this.state.filterTable == null
								? [...this.state.vendors].sort((a, b) => b.id - a.id)
								: [...this.state.filterTable].sort((a, b) => b.id - a.id)
						}
						rowKey="id"
					/>
				</div>


				<Modal
					title={this.state.isEdit ? <span className='textStyles-small'
						style={{ fontSize: 14 }}>Edit Vendor</span> : <span className='textStyles-small'
							style={{ fontSize: 14 }}>Add New Vendor</span>}
					visible={this.state.isOpenModal}
					onCancel={this.handleCancelFunction}
					width={800}
					footer={null}
					destroyOnClose={true}
				>
					<>
						{
							this.state.isView ? (
								<>
									<Descriptions bordered column={1} size='small'>
										<Descriptions.Item label={<span className='textStyles-small' style={{ fontSize: 11 }}>Vendor Name</span>}>
											<span className='textStyles-small'>{this.state.name}</span>
										</Descriptions.Item>
										<Descriptions.Item label={<span className='textStyles-small' style={{ fontSize: 11 }}>Vendor Code</span>}>
											<span className='textStyles-small'>{this.state.code}</span>
										</Descriptions.Item>
										<Descriptions.Item label={<span className="textStyles-small" style={{ fontSize: 11 }}>VAT Number</span>}>
											<span className='textStyles-small'>{this.state.vatNumber}</span>
										</Descriptions.Item>
										<Descriptions.Item label={<span className='textStyles-small' style={{ fontSize: 11 }}>Contact Number</span>}>
											<span className='textStyles-small'>{this.state.contactNumber}</span>
										</Descriptions.Item>
										<Descriptions.Item label={<span className='textStyles-small' style={{ fontSize: 11 }}>Contact Person</span>}>
											<span className='textStyles-small'>{this.state.contactPerson}</span>
										</Descriptions.Item>
										<Descriptions.Item label={<span className='textStyles-small' style={{ fontSize: 11 }}>Contact Email</span>}>
											<span className='textStyles-small'>{this.state.contactEmail}</span>
										</Descriptions.Item>
										<Descriptions.Item label={<span className='textStyles-small' style={{ fontSize: 11 }}>Contact Address</span>}>
											<span className='textStyles-small'>{this.state.contactAddress}</span>
										</Descriptions.Item>
										<Descriptions.Item label={<span className='textStyles-small' style={{ fontSize: 11 }}>Depot</span>}>
											{this.state.depotListId.map((depot) => (
												<Tag key={depot.id} color='blue' style={{ marginTop: 2 }}><span className='textStyles-small'>{depot.name}</span></Tag>
											))}
										</Descriptions.Item>
										<Descriptions.Item label={<span className='textStyles-small' style={{ fontSize: 11 }}>Services</span>}>
											{this.state.isAcService ? <Tag color='green' style={{ marginTop: 2 }}><span className='textStyles-small'>AC Service</span></Tag> : null}
											{this.state.isCrService ? <Tag color='green' style={{ marginTop: 2 }}><span className='textStyles-small'>CR Service</span></Tag> : null}
											{this.state.isGenService ? <Tag color='green' style={{ marginTop: 2 }}><span className='textStyles-small'>Gen Service</span></Tag> : null}
											{this.state.isCleaningService ? <Tag color='green' style={{ marginTop: 2 }}><span className='textStyles-small'>Cleaning Service</span></Tag> : null}
											{this.state.isRefuelService ? <Tag color='green' style={{ marginTop: 2 }}><span className='textStyles-small'>Refuel Service</span></Tag> : null}
										</Descriptions.Item>
									</Descriptions>
								</>
							) : (
								this.state.isEdit ? (
									<Form
										layout="vertical"
										onFinish={(values) => {
											this.showUpdateConfirmationModal(values);
										}}
									>
										<Row>
											<Col span={12}>
												<Form.Item
													label={<span className='textStyles-small' style={{ fontSize: 11 }}>Vendor Name</span>}
													name="name"
													rules={[{ required: true, message: 'Please enter vendor name' }]}
													initialValue={this.state.name}
												>
													<Input
														autoComplete="off"
														type="text"
														className='borderedSelect'
														style={{ height: 34, width: '98%' }}
														name="name"
														aria-describedby="emailHelp"
														value={this.state.name}
														onChange={this.editFormItebd268mChange}
													/>
												</Form.Item>
											</Col>

											<Col span={12}>
												<Form.Item
													label={<span className='textStyles-small' style={{ fontSize: 11 }}>Vendor Code</span>}
													name="code"
													rules={[{ required: true, message: 'Please enter vendor code' }]}
													initialValue={this.state.code}
												>
													<Input
														autoComplete="off"
														type="text"
														className='borderedSelect'
														style={{ height: 34, width: '98%' }}
														name="code"
														value={this.state.code}
														onChange={this.editFormItemChange}
													/>
												</Form.Item>
											</Col>

											<Col span={12}>
												<Form.Item
													label={<span className='textStyles-small' style={{ fontSize: 11 }}>VAT Number</span>}
													name="vatNumber"
													rules={[{ required: true, message: 'Please enter VAT number' }]}
													initialValue={this.state.vatNumber}
												>
													<Input
														autoComplete="off"
														type="text"
														className='borderedSelect'
														style={{ height: 34, width: '98%' }}
														name="vatNumber"
														value={this.state.vatNumber}
														onChange={this.editFormItemChange}
													/>
												</Form.Item>
											</Col>

											<Col span={12}>
												<Form.Item
													label={<span className='textStyles-small' style={{ fontSize: 11 }}>Contact Number</span>}
													name="contactNumber"
													rules={[{ required: true, message: 'Please enter contact number' }]}
													initialValue={this.state.contactNumber}
												>
													<Input
														autoComplete="off"
														type="tel"
														pattern="[0-9]{10}"
														className='borderedSelect'
														style={{ height: 34, width: '98%' }}
														name="contactNumber"
														value={this.state.contactNumber}
														onChange={this.editFormItemChange}
													/>
												</Form.Item>
											</Col>

											<Col span={12}>
												<Form.Item
													label={<span className='textStyles-small' style={{ fontSize: 11 }}>Contact Person</span>}
													name="contactPerson"
													rules={[{ required: true, message: 'Please enter contact person' }]}
													initialValue={this.state.contactPerson}
												>
													<Input
														autoComplete="off"
														type="text"
														className='borderedSelect'
														style={{ height: 34, width: '98%' }}
														name="contactPerson"
														value={this.state.contactPerson}
														onChange={this.editFormItemChange}
													/>
												</Form.Item>
											</Col>

											<Col span={12}>
												<Form.Item
													label={<span className='textStyles-small' style={{ fontSize: 11 }}>Contact Email</span>}
													name="contactEmail"
													rules={[{ required: true, message: 'Please enter contact email' }]}
													initialValue={this.state.contactEmail}
												>
													<Input
														autoComplete="off"
														type="email"
														className='borderedSelect'
														style={{ height: 34, width: '98%' }}
														name="contactEmail"
														value={this.state.contactEmail}
														onChange={this.editFormItemChange}
													/>
												</Form.Item>
											</Col>

											<Col span={12}>
												<Form.Item
													label={<span className='textStyles-small' style={{ fontSize: 11 }}>Contact Address</span>}
													name="contactAddress"
													rules={[{ required: true, message: 'Please enter contact address' }]}
													initialValue={this.state.contactAddress}
												>
													<Input
														autoComplete="off"
														type="text"
														className='borderedSelect'
														style={{ height: 34, width: '98%' }}
														name="contactAddress"
														value={this.state.contactAddress}
														onChange={this.editFormItemChange}
													/>
												</Form.Item>
											</Col>

											<Col span={12}>
												<Form.Item
													label={<span className='textStyles-small' style={{ fontSize: 11 }}>Depot</span>}
													name="depotListId"
													rules={[{ required: true, message: 'Please select depot' }]}
													initialValue={this.state.depotListId}
												>
													<Select
														className='borderedSelect'
														style={{ width: '98%' }}
														bordered={false}
														mode="multiple"
														placeholder="Please select"
														name="depotListId"
														// onChange={(value) => this.editFormItemChange(value, 'depot', value)}
														value={this.state.depotListId}
													>
														{this.state.depotList.map((depot) => (
															<Select.Option key={depot.id} value={depot.id}>
																{depot.name}
															</Select.Option>
														))}
													</Select>
												</Form.Item>
											</Col>

											<Col span={6}>
												<Form.Item name="isGenService" valuePropName="checked" initialValue={this.state.isGenService}>
													<Checkbox><span className='textStyles-small' style={{ fontSize: 11 }}>General Service</span></Checkbox>
												</Form.Item>
											</Col>
											<Col span={6}>
												<Form.Item name="isAcService" valuePropName="checked" initialValue={this.state.isAcService}>
													<Checkbox><span className='textStyles-small' style={{ fontSize: 11 }}>AC Service</span></Checkbox>
												</Form.Item>
											</Col>
											<Col span={6}>
												<Form.Item name="isCleaningService" valuePropName="checked" initialValue={this.state.isCleaningService}>
													<Checkbox><span className='textStyles-small' style={{ fontSize: 11 }}>Cleaning Service</span></Checkbox>
												</Form.Item>
											</Col>
											<Col span={6}>
												<Form.Item name="isRefuelService" valuePropName="checked" initialValue={this.state.isRefuelService}>
													<Checkbox><span className='textStyles-small' style={{ fontSize: 11 }}>Refuel Service</span></Checkbox>
												</Form.Item>
											</Col>
											<Col span={6}>
												<Form.Item name="isCrService" valuePropName="checked" initialValue={this.state.isCrService}>
													<Checkbox><span className='textStyles-small' style={{ fontSize: 11 }}>CR Service</span></Checkbox>
												</Form.Item>
											</Col>

											<Col span={24}>
												<Form.Item>
													<button type="submit" className="btn btn-primary"
														style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px' }}>
														<span className="textStyles-small">Update Vendor</span>
													</button>
												</Form.Item>
											</Col>
										</Row>
									</Form>
								) : (
									<div>
										<Form
											layout="vertical"
											onFinish={(values) => {
												this.showCreateConfirmationModal(values);
											}}
										>
											<Row>
												<Col span={12}>
													<Form.Item
														label={<span className='textStyles-small' style={{ fontSize: 11 }}>Vendor Name</span>}
														name="name"
														rules={[{ required: true, message: 'Please enter vendor name' }]}
													>
														<Input
															autoComplete="off"
															type="text"
															className='borderedSelect'
															style={{ height: 34, width: '98%' }}
															name="name"
															aria-describedby="emailHelp"
														/>
													</Form.Item>
												</Col>
												<Col span={12}>
													<Form.Item
														label={<span className='textStyles-small' style={{ fontSize: 11 }}>Vendor Code</span>}
														name="code"
														rules={[{ required: true, message: 'Please enter vendor code' }]}
													>
														<Input
															autoComplete="off"
															type="text"
															className='borderedSelect'
															style={{ height: 34, width: '98%' }}
															name="code"
														/>
													</Form.Item>
												</Col>
												<Col span={12}>
													<Form.Item
														label={<span className='textStyles-small' style={{ fontSize: 11 }}>VAT Number</span>}
														name="vatNumber"
														rules={[{ required: true, message: 'Please enter VAT number' }]}
													>
														<Input
															autoComplete="off"
															type="text"
															className='borderedSelect'
															style={{ height: 34, width: '98%' }}
															name="vatNumber"
														/>
													</Form.Item>
												</Col>
												<Col span={12}>
													<Form.Item
														label={<span className='textStyles-small' style={{ fontSize: 11 }}>Contact Number</span>}
														name="contactNumber"
														rules={[{ required: true, message: 'Please enter contact number' }]}
													>
														<Input
															autoComplete="off"
															type="tel"
															pattern="[0-9]{10}"
															className='borderedSelect'
															style={{ height: 34, width: '98%' }}
															name="contactNumber"
														/>
													</Form.Item>
												</Col>
												<Col span={12}>
													<Form.Item
														label={<span className='textStyles-small' style={{ fontSize: 11 }}>Contact Person</span>}
														name="contactPerson"
														rules={[{ required: true, message: 'Please enter contact person' }]}
													>
														<Input
															autoComplete="off"
															type="text"
															className='borderedSelect'
															style={{ height: 34, width: '98%' }}
															name="contactPerson"
														/>
													</Form.Item>
												</Col>
												<Col span={12}>
													<Form.Item
														label={<span className='textStyles-small' style={{ fontSize: 11 }}>Contact Email</span>}
														name="contactEmail"
														rules={[{ required: true, message: 'Please enter contact email' }]}
													>
														<Input
															autoComplete="off"
															type="email"
															className='borderedSelect'
															style={{ height: 34, width: '98%' }}
															name="contactEmail"
														/>
													</Form.Item>
												</Col>
												<Col span={12}>
													<Form.Item
														label={<span className='textStyles-small' style={{ fontSize: 11 }}>Contact Address</span>}
														name="contactAddress"
														rules={[{ required: true, message: 'Please enter contact address' }]}
													>
														<Input
															autoComplete="off"
															type="text"
															className='borderedSelect'
															style={{ height: 34, width: '98%' }}
															name="contactAddress"
														/>
													</Form.Item>
												</Col>
												<Col span={12}>
													<Form.Item
														label={<span className='textStyles-small' style={{ fontSize: 11 }}>Depot</span>}
														name="depotListId"
														rules={[{ required: true, message: 'Please select depot' }]}
													>
														<Select
														  className='borderedSelect'
														  style={{ width: '98%' }}
														  bordered={false}
														  mode="multiple"
														  placeholder="Please select"
														  name="depotListId"
														  showSearch
														  filterOption={(input, option) =>
															option.children.toLowerCase().includes(input.toLowerCase())
														  }
														>
														  {this.state.depotList.map((depot) => (
															<Select.Option key={depot.id} value={depot.id}>
															  {depot.name}
															</Select.Option>
														  ))}
														</Select>
													</Form.Item>
												</Col>
												<Col span={6}>
													<Form.Item name="isGenService" valuePropName="checked">
														<Checkbox><span className='textStyles-small' style={{ fontSize: 11 }}>General Service</span></Checkbox>
													</Form.Item>
												</Col>
												<Col span={6}>
													<Form.Item name="isAcService" valuePropName="checked">
														<Checkbox><span className='textStyles-small' style={{ fontSize: 11 }}>AC Service</span></Checkbox>
													</Form.Item>
												</Col>
												<Col span={6}>
													<Form.Item name="isCleaningService" valuePropName="checked">
														<Checkbox><span className='textStyles-small' style={{ fontSize: 11 }}>Cleaning Service</span></Checkbox>
													</Form.Item>
												</Col>
												<Col span={6}>
													<Form.Item name="isRefuelService" valuePropName="checked">
														<Checkbox><span className='textStyles-small' style={{ fontSize: 11 }}>Refuel Service</span></Checkbox>
													</Form.Item>
												</Col>
												<Col span={6}>
													<Form.Item name="isCrService" valuePropName="checked">
														<Checkbox><span className='textStyles-small' style={{ fontSize: 11 }}>CR Service</span></Checkbox>
													</Form.Item>
												</Col>
												<Col span={24}>
													<Form.Item>
														<button type="submit" className="btn btn-primary"
															style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px' }}>
															<span className="textStyles-small">Register Vendor</span>
														</button>
													</Form.Item>
												</Col>
											</Row>
										</Form>
									</div>
								)
							)
						}

					</>
				</Modal>


				<ToastContainer position="top-right" autoClose={5000} />
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		vendors: state.vendor,
	};
}

/*function mapDispatchToProps(dispatch) {
	return {
		addVendor: (data) => dispatch(addVendor(data)),
		//removeVendor: (id) => dispatch(removeVendor(id)),
		//updateVendor: (data) => dispatch(updateVendor(data)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Vendor);
*/

export default connect(mapStateToProps, { getDonationCategories, getData })(
	Vendor
);