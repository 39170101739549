import moment from "moment";
import { DATE, DATE_TIME, TIME } from "../constants/action-types";

export  function validateStringValue(value) {  
	return value ? value : "Null";
}

export  function validateBoolValue(value) {  
	return (value == true) ? "Yes" : "No" ;
}

export  function validateIntValue(value) {  
	return value != null ? value : "Null";
}

export  function validateDateTimeFormat(value, type) {  
	if(type == DATE_TIME) return value ? moment((value)).format('YYYY-MM-DD HH:mm:ss'):("Null");
	if(type == DATE) return value ? moment((value)).format('YYYY-MM-DD'):("Null");
	if(type == TIME) return value ? moment((value)).format('HH:mm:ss'):("Null");
} 

export  function validateModle(model) {  
	return model != null;
}