import React from "react";
import "./style.css"

function Loading({isLoading}){
    return(
        isLoading && <div className="alert-loading-container"></div>
    )

}

export { Loading }