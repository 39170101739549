import React, { Component } from 'react'
import ReactApexChart from 'react-apexcharts'

 class SimplePieChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
            series: [],
            options: {
              chart: {
                width: 380,
                type: 'pie',
              },
              title: {
                text: '',
                align: 'left'
              },
              legend: {
                show: true,
                position: 'bottom',
            },
              labels: [],
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
             
                }
              }]
            },
          
          
          };
    }
    componentDidMount() {
        console.log('tytqwe')
        console.log(this.props.vehicleSeries.vehicleSeriesCount)
        console.log(this.props.vehicleLable.vehicleSerieslabels)
        this.setState({
            series:this.props.vehicleSeries.vehicleSeriesCount,
            options: {
              chart: {
                width: 380,
                type: 'pie',
              },
              title: {
                text: this.props.simplePieChartlabla,
                align: 'left'
              },
              legend: {
                show: true,
                position: 'bottom',
            },
              labels: this.props.vehicleLable.vehicleSerieslabels,
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
             
                }
              }]
            },
        })
       

    }
    
  render() {
    return (
        <div id="chart">
        <ReactApexChart options={this.state.options} series={this.state.series} type="pie" height={350} />
    </div>
    )
  }
}
export default SimplePieChart;