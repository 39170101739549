import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { createNewSiteCategory, getAllSiteCategories,updateSiteCategoryTeam } from '../../api/index';
import { addVendor, getDonationCategories, getData } from '../../actions/index';
import { connect } from "react-redux";
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import { Collapse, Select, Button, Tag, message, Row, Col, Form, Modal, Input, Result, Table, Drawer, Menu, Dropdown, Space, Spin } from 'antd';
import { ExclamationCircleOutlined, DeleteOutlined, EditOutlined, PlusCircleFilled, MoreOutlined, EyeOutlined } from '@ant-design/icons';
import './../master-data/style.css';

class SiteCategory extends Component {

	constructor(props) {
		super(props)
		this.validator = new SimpleReactValidator();
		this.state = {
			name: "",
			site_categories: [],
			filterTable: null,
			editDrawerVisible: false,
			isEdit: false,
			isOpenModal: false,
		}
	}

	componentDidMount() {
		this.getAllSiteCategoryData();
	}

	getAllSiteCategoryData = async () => {
		var res = await getAllSiteCategories();
		if (res.success) {
			this.setState({
				site_categories: res.data
			})
		}
	}

	// add drawer Details Here
	showEditDrawer = (record) => {
		this.setState({
			editDrawerVisible: true,
			name: record.name,
			editVendorDetails: {
				id: record.id,
				name: record.name,
			},
		});
	};

	closeEditDrawer = () => {
		this.setState({ editDrawerVisible: false });
	};

	editFormItemChange = (event) => {
		const { name, value } = event.target;
		this.setState((prevState) => ({
			editVendorDetails: {
				...prevState.editVendorDetails,
				[name]: value,
			},
		}));
		var obj = {};
		obj[event.target.name] = event.target.value;
		this.setState(obj);
	};

	formItemChange = (event) => {
		var obj = {};
		obj[event.target.name] = event.target.value;
		this.setState(obj);
	}

	// Create new site here and update , delete operations perform here
	createNewSite = async () => {
		if (this.validator.allValid()) {
			var data = {
				"name": this.state.name,
			}
			var res = await createNewSiteCategory(data);
			if (res.success) {
				toast.success("Successfully New Site Category Created")
				this.getAllSiteCategoryData();
				this.setState({
					name: ""
				})
			}
		} else {
			this.validator.showMessages();
			// rerender to show messages for the first time
			// you can use the autoForceUpdate option to do this automatically`
			this.forceUpdate();
		}
		this.refreshPage();
	}

	updateSiteCategory = async () => {
		if (this.validator.allValid()) {
			var res = await updateSiteCategoryTeam(this.state.editVendorDetails, this.state.editVendorDetails.id);
			if (res.success) {
			  toast.success('Vendor updated successfully');
			  this.closeEditDrawer();
			  //Window.location.reload();
			  this.refreshPage();
			}
		} else {
			this.validator.showMessages();
			this.forceUpdate();
		}
	};

	showDeleteConfirm = (record) => {
		const { confirm } = Modal;
		confirm({
			title: 'Are you sure you want to delete this Depot?',
			onOk: async () => {
				/*var res = await deleteVendor(record.id);
				if (res.success) {
				  toast.success('Vendor deleted successfully');
				  this.props.removeVendor(record.id);
				}*/
			},
			onCancel() { },
		});
	};

	showCreateConfirmationModal = () => {
		Modal.confirm({
			title: 'Confirm',
			icon: <ExclamationCircleOutlined />,
			content: 'Are you sure you want to create new site category?',
			okText: 'Yes',
			cancelText: 'No',
			onOk: () => this.createNewSite(),
		});
	};

	showUpdateConfirmationModal = () => {
		Modal.confirm({
			title: 'Confirm',
			icon: <ExclamationCircleOutlined />,
			content: 'Are you sure you want to update this site category?',
			okText: 'Yes',
			cancelText: 'No',
			onOk: () => this.updateSiteCategory(),
		});
	};

	// Implement Search Functionality to the Table
	search = (value) => {
		const filterTable = this.state.site_categories.filter((o) =>
			Object.keys(o).some((k) =>
				String(o[k]).toLowerCase().includes(value.toLowerCase())
			)
		);
		this.setState({ filterTable });
	};

	// Window Reload Function
	refreshPage() {
		window.location.reload(false);
	}

	render() {
		const columns = [
			{
				title: <span className="textStyles-small">ID</span>,
				dataIndex: 'id',
				key: 'id',
				render: (text) => (
					<span className="textStyles-small">{text === ' ' ? <i>No name</i> : text}</span>
				),
			},
			{
				title: <span className="textStyles-small">Site Category Name</span>,
				dataIndex: 'name',
				key: 'name',
				render: (text) => <span className="textStyles-small">{text}</span>,
			},
			{
				title: <span className="textStyles-small">Action</span>,
				key: 'action',
				render: (text, record, index) => (
					<span className="text-end">
						<Button
					shape='circle'
					size='small'
					type='primary'
					icon={<EditOutlined style={{ fontSize: '14px' }} />}
					style={{ marginLeft: '10px' }}
					onClick={() => {
						this.showEditDrawer(record);
						this.setState({
							isEdit: true,
							isOpenModal: true
						});
					}}
				/>
					</span>
				),
			},
		];

		return (
			<div class="container-fluid">
				<div class="row">
					<div class="col-sm-12">
						<div class="page-title-box">
							<div class="row">
								<div class="col">
									<h4 class="page-title">Site Category</h4>
									<ol class="breadcrumb">
										<li class="breadcrumb-item"><a href="javascript:void(0);">Admin</a></li>
										<li class="breadcrumb-item"><a href="javascript:void(0);">Site Category</a></li>

									</ol>
								</div>
								<div class="col-auto align-self-center">
									<a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
										<span class="day-name" id="Day_Name">Today:</span>&nbsp;
										<span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
									</a>

								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div style={{ margin: '0', padding: '20px', width: '100%', backgroundColor: 'white', borderRadius: '10px', marginTop: '20px' }}>
						<Row>
							<Col span={12}>
								<Button type="primary" onClick={() => this.setState({ isOpenModal: true, isEdit: false })}
									style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px', margin: 10 }}><PlusCircleFilled />
									<span className="textStyles-small">New Category</span></Button>
							</Col>
							<Col span={12} style={{ textAlign: 'right' }}>
								<Input
									enterButton
									className='borderedSelect' style={{ height: 33, margin: 10, marginRight: 10, width: 350 }}
									placeholder="Search Site Category...."
									onChange={(e) => {
										const currValue = e.target.value;
										this.search(currValue);
									}}
								/>
							</Col>
						</Row>
						<Table
							className="table-striped-rows"
							size="small"
							style={{ marginTop: 10 }}
							columns={columns}
							dataSource={
								this.state.filterTable == null
									? this.state.site_categories
									: this.state.filterTable
							}
							rowKey="id"
						/>
					</div>

					<Modal
						title={this.state.isEdit ? <span className="textStyles-small" style={{fontSize: 13}}>Edit Site Category</span> : 
						<span className="textStyles-small" style={{fontSize: 13}}>Create Site Category</span>}
						visible={this.state.isOpenModal}
						onOk={() => this.setState({ isOpenModal: false, isEdit: false })}
						onCancel={() => this.setState({ isOpenModal: false, isEdit: false })}
						footer={null}
						destroyOnClose={true}
					>
						{
							this.state.isEdit ? (
								<div>
								<div className="mb-3">
								<label className="form-label" for="exampleInputPassword1"><span className='textStyles-small' style={{ fontSize: 12 }}>Edit Site Category Name </span></label>
									<input
										autoComplete="off" 
										type="text" 
										className='borderedSelect'
										style={{ height: 34, width: '100%' }}
										name="name" 
										value={this.state.name} 
										aria-describedby="emailHelp" 
										onChange={this.editFormItemChange}
									/>
									<p style={{ color: '#f5325c' }}>
										{this.validator.message(
											'name',
											this.state.name,
											'required'
										)}
									</p>
								</div>
						
							{/* <button className="btn btn-primary" onClick={() => this.showUpdateConfirmationModal()}>Update Site Category</button> */}
							<Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
										<button type="submit" className="btn btn-primary"
											style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px', marginTop: 10 }}
											onClick={() => this.showUpdateConfirmationModal()}
											>
											<span className="textStyles-small">Update Site Category</span>
										</button>
									</Row>
						</div>
							) : (
								<div>
								<form>
									<div className="mb-3">
									<label className="form-label" for="exampleInputPassword1"><span className='textStyles-small' style={{ fontSize: 12 }}>Site Category Name </span></label>
										<input autoComplete="off" type="text" 
																													className='borderedSelect'
																													style={{ height: 34, width: '100%' }}
										name="name" value={this.state.name} aria-describedby="emailHelp" onChange={(e) => this.formItemChange(e)} />
										<p style={{ color: "#f5325c" }}>{this.validator.message('name', this.state.name, 'required')}</p>
									</div>
								</form>
								<Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
										<button type="submit" className="btn btn-primary"
											style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px', marginTop: 10 }}
											onClick={() => this.showCreateConfirmationModal()}
											>
											<span className="textStyles-small">Create Site Category</span>
										</button>
									</Row>
								{/* <button className="btn btn-primary" onClick={() => this.showCreateConfirmationModal()}>Create Site Category</button> */}
							</div>
							)
						}

					</Modal>


				</div>
				<ToastContainer position="top-right" autoClose={5000} />
			</div>
		);
	}
}


function mapStateToProps(state) {
	return {
		vendors: state.vendor,

	};
}

function mapDispatchToProps(dispatch) {
	return {
		addVendor: data => dispatch(addVendor(data))
	};
}

export default connect(
	mapStateToProps, mapDispatchToProps
)(SiteCategory);

  
  
