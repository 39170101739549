import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

class SimpleDonutChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [],
            options: {
                chart: {
                    width: 380,
                    type: 'donut',
                },
                title: {
                    text: 'Refuel',
                    align: 'left'
                },
                legend: {
                    show: true,
                    position: 'bottom',
                },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                    }
                }]
            },
            isAllZero: false
        };
    }

    componentDidMount() {
        const { y1, x1, colorArray, chartLabale } = this.props;

        // Check if all series values are zero
        const isAllZero = y1.every(value => value === 0);

        this.setState({
            series: y1,
            isAllZero: isAllZero,
            options: {
                ...this.state.options,
                colors: isAllZero ? ['#d3d3d3'] : colorArray,
                chart: {
                    ...this.state.options.chart,
                    width: 380,
                    type: 'donut',
                },
                title: {
                    text: chartLabale,
                    fontFamily: 'Roboto, sans-serif',
                    align: 'left'
                },
                legend: {
                    show: true,
                    showForSingleSeries: false,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: 'top',
                    fontSize: '12px',
                    fontFamily: 'Roboto, sans-serif',
                    colors: 'black',
                    floating: false,
                    labels: {
                        colors: ['black'],
                        useSeriesColors: true
                    },
                },
                labels: x1,
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: !isAllZero,
                                name: {
                                    show: true,
                                    fontSize: '22px',
                                    fontFamily: 'Roboto, sans-serif',
                                    fontWeight: 600,
                                    color: undefined,
                                    offsetY: -10,
                                },
                                value: {
                                    show: true,
                                    fontSize: '16px',
                                    fontFamily: 'Roboto, sans-serif',
                                    fontWeight: 400,
                                    color: undefined,
                                    offsetY: 16,
                                    formatter: function (val) {
                                        return val
                                    }
                                },
                                total: {
                                    show: true,
                                    showAlways: true,
                                    label: 'Total',
                                    color: '#373d3f',
                                    formatter: function (w) {
                                        return w.globals.seriesTotals.reduce((a, b) => {
                                            return a + b
                                        }, 0)
                                    }
                                }
                            }
                        }
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                    }
                }]
            },
        });
    }

    render() {
        return (
            <div id="chart" style={{ position: 'relative' }}>
                <ReactApexChart
                    style={{ fontWeight: "bold", fontFamily: 'Roboto' }}
                    options={this.state.options}
                    series={this.state.series}
                    type="donut"
                    height={350}
                />
                {this.state.isAllZero && (
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        textAlign: 'center',
                        color: '#d3d3d3',
                        fontSize: '24px',
                        fontFamily: 'Roboto, sans-serif'
                    }}>
                        No Data Available For the Selected Month
                    </div>
                )}
            </div>
        );
    }
}

export default SimpleDonutChart;
