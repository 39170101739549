import React, { Component, Fragment } from 'react'

import { Table, Input, InputNumber, Tag, Space, Button, Row, Col, Drawer, Select, Spin, Switch, message } from "antd";
import { updateCabinDetails } from "../../api/index";
import checkPermissions from '../../utils/permissionManager';
const { Option } = Select;


class CabinDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            tabaleLoading: false,
            switchStatus: true,
            objectSet: {
                "noofIndoorCabins": null,
                "indoorCabinType": null,
                "indoorCabinLength": null,
                "indoorCabinWidth": null,
            },

            dropdownDetailsCabins: {
                "noofIndoorCabins": "",
                "indoorCabinType": "",
            }
        };
        this.handlNofIndoorCabin = this.handlNofIndoorCabin.bind(this);
        this.handlIndoorCabinType = this.handlIndoorCabinType.bind(this);
        this.handlIndoorCabinLenght = this.handlIndoorCabinLenght.bind(this);
        this.handlIndoorCabinWidth = this.handlIndoorCabinWidth.bind(this);

    }
    handlIndoorCabinWidth(e) {



        this.setState({
            objectSet: {
                ...this.state.objectSet,
                indoorCabinWidth: e
            }

        }, () => {

        })
    }
    handlIndoorCabinLenght(e) {



        this.setState({
            objectSet: {
                ...this.state.objectSet,
                indoorCabinLength: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }
    handlIndoorCabinType(e) {
        console.log(e.target.value)


        this.setState({
            objectSet: {
                ...this.state.objectSet,
                indoorCabinType: e.target.value
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }
    handlNofIndoorCabin(e) {
        console.log(e)


        this.setState({
            objectSet: {
                ...this.state.objectSet,
                noofIndoorCabins: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    }

    componentDidMount() {
        console.log('tytqwe')
        console.log(this.props.details)
        this.setState({
            objectSet: {
                "noofIndoorCabins": this.props.details3?.allData?.noofIndoorCabins,
                "indoorCabinType": this.props.details3?.allData?.indoorCabinType,
                "indoorCabinLength": this.props.details3?.allData?.indoorCabinLength,
                "indoorCabinWidth": this.props.details3?.allData?.indoorCabinWidth,

            }
        })
        // console.log(this.props.details2)
    }
    dataSetToUpdate = async () => {
        console.log(this.props.detailsSite)
        let formData = new FormData(); //formdata object
        let setobjectToUpate = { "noofIndoorCabins": this.state.objectSet.noofIndoorCabins, "indoorCabinWidth": this.state.objectSet.indoorCabinWidth, "indoorCabinLength": this.state.objectSet.indoorCabinLength, "indoorCabinType": this.state.objectSet.indoorCabinType }
        console.log(setobjectToUpate)
        formData.append("object", JSON.stringify(setobjectToUpate)); //append the values with key, value pair
        formData.append("id", this.props.detailsSite.site_details.id);
        var res = await updateCabinDetails(formData);
        if (res.success) {
            message.success('Successfully Update!')
            this.props.parentMethod();
            this.setState({
                switchStatus: true
            })
        } else {
            message.warning(' Something went wrong!')
        }





    }

    dataUpdate() {
        console.log("ssss")
        this.setState({
            switchStatus: false
        })
    }
    close = async () => {
        this.props.parentMethod();
        this.setState({
            objectSet:
            {
                "noofIndoorCabins": this.props.details3?.allData?.noofIndoorCabins,
                "indoorCabinType": this.props.details3?.allData?.indoorCabinType,
                "indoorCabinLength": this.props.details3?.allData?.indoorCabinLength,
                "indoorCabinWidth": this.props.details3?.allData?.indoorCabinWidth,

            }
        });
        this.setState({
            switchStatus: true
        });
    }
    render() {
        const { details } = this.props.details

        return (
            <Fragment>
                {this.props.details.access_technologies_data != null ? (
                    <div style={{ width: "100%", backgroundColor: "white", padding: "20px", borderRadius: "5px" }}>
                        <div className="d-flex justify-content-end align-items-center" style={{ marginBottom: "10px", marginRight: "10px" }}>
                            {this.state.switchStatus
                                ? checkPermissions('SITE_VIEW_GENERAL_USER') || checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                                    <button
                                        type="button"
                                        className="btn btn-soft-primary btn-icon-square-sm"
                                        onClick={() => this.dataUpdate()}
                                        style={{ borderRadius: 5, backgroundColor: '#F28502', borderColor: '#F28502' }}
                                    >
                                        <i className="mdi mdi-pencil-outline" style={{ color: 'white' }}></i>
                                    </button>
                                ) : null
                                : <div class="d-flex justify-content-end align-items-center">
                                    <button type="button" class="btn btn-soft-secondary  textStyles-small" onClick={() => this.close()} style={{ marginRight: "10px" }}> Cancel </button>
                                    <button type="button" class="btn btn-soft-primary  textStyles-small" onClick={() => this.dataSetToUpdate()}> Submit to Update </button>
                                </div>
                            }
                        </div>
                        <ul class="list-group">
                            <Row span={24}>
                                <Col span={12}>
                                    <div style={{ width: '98%' }}>
                                        <li class="list-group-item d-flex justify-content-between align-items-center">
                                            <div>
                                                <span className="textStyles-small">
                                                    No of Indoor Cabins
                                                </span>
                                            </div>
                                            <div>
                                                {this.state.switchStatus.toString() == "true" ? (
                                                    this.props.details3?.allData?.noofIndoorCabins ? (
                                                        <span class="badge badge-outline-success textStyles-small">{this.props.details3?.allData?.noofIndoorCabins}</span>
                                                    ) : (
                                                        <span class="badge badge-outline-danger textStyles-small">Currently Not Set</span>
                                                    )
                                                ) : (
                                                    <Select
                                                        defaultValue={this.props.details3?.allData?.noofIndoorCabins}
                                                        style={{ width: '150px' }}
                                                        bordered={false}
                                                        className='borderedSelect'
                                                        onChange={(e) => {
                                                            this.state.objectSet.noofIndoorCabins = e;
                                                        }}>
                                                        {this.props.cabindropdown["dropdownDetailsCabins"]["noofIndoorCabins"]?.map((x) => {
                                                            return <Option value={x}>{x}</Option>
                                                        })}
                                                    </Select>
                                                )}
                                            </div>
                                        </li>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div style={{ width: '98%' }}>
                                        <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f8f9fa' }}>
                                            <div>
                                                <span className="textStyles-small">
                                                    Indoor Cabin Type
                                                </span>
                                            </div>
                                            <div>
                                                {this.state.switchStatus.toString() == "true" ? (
                                                    this.props.details3?.allData?.indoorCabinType ? (
                                                        <span class="badge badge-outline-success textStyles-small">{this.props.details3?.allData?.indoorCabinType}</span>
                                                    ) : (
                                                        <span class="badge badge-outline-danger textStyles-small">Currently Not Set</span>
                                                    )
                                                ) : (
                                                    <Select
                                                        defaultValue={this.props.details3?.allData?.indoorCabinType}
                                                        style={{ width: '150px' }}
                                                        bordered={false}
                                                        className='borderedSelect'
                                                        onChange={(e) => {
                                                            this.state.objectSet.indoorCabinType = e;
                                                        }}>
                                                        {this.props.cabindropdown["dropdownDetailsCabins"]["indoorCabinType"]?.map((x) => {
                                                            return <Option value={x}>{x}</Option>
                                                        })}
                                                    </Select>
                                                )}
                                            </div>
                                        </li>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div style={{ width: '98%' }}>
                                        <li class="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f8f9fa' }}>
                                            <div>
                                                <span className="textStyles-small">
                                                    Indoor Cabin Length (m)
                                                </span>
                                            </div>
                                            {this.state.switchStatus ? (<> {this.props.details3?.allData?.indoorCabinLength != null ?
                                                (<span class="badge badge-outline-success textStyles-small">{this.props.details3?.allData?.indoorCabinLength}</span>) :
                                                (<span class="badge badge-outline-warning textStyles-small">Currently Not Set</span>)}</>) : (<>
                                                    <InputNumber style={{ width: '150px', height: 34 }}
                                                        className='borderedSelect' value={this.state.objectSet?.indoorCabinLength} id="mpcNoOfPhases" onChange={this.handlIndoorCabinLenght} /> </>)}
                                        </li>
                                    </div>
                                </Col>

                                <Col span={12}>
                                    <div style={{ width: '98%' }}>
                                        <li class="list-group-item d-flex justify-content-between align-items-center">
                                            <div>
                                                <span className="textStyles-small">
                                                    Indoor Cabin Width (m)
                                                </span>
                                            </div>
                                            {this.state.switchStatus ? (<> {this.props.details3?.allData?.indoorCabinWidth != null ? (
                                                <span class="badge badge-outline-success textStyles-small">{this.props.details3?.allData?.indoorCabinWidth}</span>) : (<span class="badge badge-outline-warning textStyles-small">Currently Not Set</span>)}</>) : (<>
                                                    <InputNumber style={{ width: '150px', height: 34 }}
                                                        className='borderedSelect' value={this.state.objectSet?.indoorCabinWidth} id="mpcNoOfPhases" onChange={this.handlIndoorCabinWidth} /> </>)}
                                        </li>
                                    </div>
                                </Col>
                            </Row>
                        </ul>
                    </div>
                ) : (
                    <div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <h4 style={{ color: "#c5c5c5", fontSize: 16 }} className='textStyles-small'>No Dat Found Access Technologies</h4>
                    </div>
                )}
            </Fragment>
        )
    }
}
export default CabinDetails;
