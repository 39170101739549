import React, { Component } from 'react'
import SemiCircleGaugeChat from '../../../components/charts/semi-circle-gauge';
import TwoLineChartChart from '../../../components/charts/two-line-chart';
import LineCharts from '../../../components/charts/line-charts';
import StackedBarChart from '../../../components/charts/stacked-bar'
import SimpleDonutChart from '../../../components/charts/simple-donut-chart'
import { FilterOutlined } from "@ant-design/icons";
import {
    getPISRegionViseStatusCount,
    getPISRegionViseTargetCount,
    getMonthlyCompletionTrendCount,
    getMonthlyWiseStatusCount,
    getMonthlyTargetVsCompletionTrendCount,
    VehicleDashboardCounts, VehicleDashboardCounts2, getPisGenaralDetailsById
} from "../../../api/index";
import moment from 'moment';
import { getDonationCategories, getData } from "../../../actions/index";
import { connect } from "react-redux";
import { PullRequestOutlined, CloseOutlined, BarsOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { Select, DatePicker, Space, Badge, Alert, Button, Row, Col } from "antd";
const { RangePicker } = DatePicker;

class DashboardGen extends Component {

    constructor(props) {
        super(props);
        const currentDate_landing = new Date();
        this.state = {
            genServiceTargetChart: false,
            genServiceUniqueYear: null,
            genServiceTargetChartSetY1: null,
            genServiceTargetChartSetX1: null,

            requesteTrendChart: false,
            requestTreandYear: null,
            requesteTrendChartSetY1: null,
            requesteTrendChartSetX1: null,

            requestStatusChart: false,
            requestStatusChartSetY1: null,
            requestStatusChartSetX1: null,

            momCompletionChart: false,
            momCompletionChartSetY1: null,
            momCompletionChartSetX1: null,

            slaChart: false,
            slaChartSetY1: null,
            slaChartSetX1: null,

            isRegionViseCountList: false,
            regionViseCountList: [],

            SubRegion_list: [],
            getAllData: [],
            depot_list: [],
            filter_date: currentDate_landing.getFullYear(),
            //filter_date_to: moment().format("YYYY-MM-DD"),
            // filter date to end of month
            filter_date_to: currentDate_landing.getMonth() + 1,
            filter_depot: [],
            filter_region: [],
            filter_sub_region: [],
            installed_count: 0,
            requested_count: 0,
            pending_count: 0,
            cancelled_count: 0,
        }
    }

    componentDidMount() {
        this.props.getData();

        var data = new FormData();
        data.append('year', this.state.filter_date);
        data.append('month', this.state.filter_date_to);
        data.append('fromDate', this.state.filter_date);
        data.append('toDate', this.state.filter_date_to);
        data.append('regionIds', this.state.filter_region);
        data.append('subregionIds', this.state.filter_sub_region);
        data.append('depotIds', this.state.filter_depot);
        this.getSiteAccessDashboardCounts(data);
        this.getPISRegionViseStatusCount(data);
        this.getPISRegionViseTargetCount(data);
        this.getMonthlyCompletionTrendCount(data);
        this.getMonthlyWiseStatusCount(data);
        this.getMonthlyTargetVsCompletionTrendCount(data);

        this.setState({
            /*depot_list: this.props.depots,
            SubRegion_list: this.props.regions.filter((item) => {
                return item.isSubRegion == 1;
            }),*/
            slaChartSetY1: [44, 55],
            slaChartSetX1: ['Without SLA', 'Within SLA']
        }, () => {
            this.setState({
                slaChart: true
            })
        })
    }

    getLastDayOfMonth(inputDate) {
        const [year, month] = inputDate.split('-');
        const nextMonth = new Date(year, month);
        nextMonth.setDate(0);
        const lastDay = nextMonth.getDate();
        return `${year}-${month}-${lastDay.toString().padStart(2, '0')}`;
    }

    /*
        {
        "region": "ND Super",
        "completedCount": 0,
        "targetCount": 0.0
    },
    {
        "region": "Central office",
        "completedCount": 0,
        "targetCount": 0.0
    }
    */
    getPISRegionViseStatusCount = async (data2) => {
        this.setState({
            requestStatusChart: false
        })
        var res = await getPISRegionViseStatusCount("GEN", data2);
        var data = res.data;
        // remove give data index from array (remove indexes are : 5, 6, 7
        if (data != null) {
            data.splice(5, 3);
            let fff = data.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
            let regionSet = data.map(a => a.region != null ? a.region.split(" ") : 'null');
            let canceledSet = fff.map(a => a.counts.cancel);
            let compleateSet = fff.map(a => a.counts.service_completed);
            let pendingSet = fff.map(a => a.counts.pending);
            let approvalCompletedSet = fff.map(a => a.counts.approval_completed);

            this.setState({
                requestStatusChartSetY1: [{
                    name: 'Cancelled',
                    type: 'column',
                    data: canceledSet
                }, {
                    name: 'Service Completed',
                    type: 'column',
                    data: compleateSet
                }, {
                    name: 'Approval Completed',
                    type: 'column',
                    data: approvalCompletedSet
                }, {
                    name: 'Pending',
                    type: 'column',
                    data: pendingSet
                }],
                requestStatusChartSetX1: regionSet
            }, () => {
                this.setState({
                    requestStatusChart: true
                })
            })
        }
    }

    getPISRegionViseTargetCount = async (data2) => {
        this.setState({
            isRegionViseCountList: false
        })
        var res = await getPISRegionViseTargetCount("GEN", data2);
        var data = res.data;
        if (data != null) {
            data.map(e => {
                e.trend = (e.completedCount / e.targetCount * 100).toString()
            })
            this.setState({
                regionViseCountList: data,
            }, () => {
                this.setState({
                    isRegionViseCountList: true
                })
            })
        }
    }

    getMonthlyCompletionTrendCount = async (data2) => {
        try {
            this.setState({
                requesteTrendChart: false
            });
            const res = await getMonthlyCompletionTrendCount("GEN", data2);
            const data = res.data;

            if (data != null) {
                // const today = new Date();
                // const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                // const formattedFirstDay = firstDayOfMonth.toISOString().split('T')[0]; // Get the first day of the month in "YYYY-MM-DD" format

                let set_year = this.state.filter_date;
                let set_month = this.state.filter_date_to;
                let set_data = set_year + '-' + set_month
                const inputDate = set_data;
                const lastDayOfMonth = this.getLastDayOfMonth(inputDate);

                const today = new Date(lastDayOfMonth);

                const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                const formattedFirstDay = firstDayOfMonth.toISOString().split('T')[0];

                const filteredData = data
                    .filter(entry => entry.date >= formattedFirstDay && entry.date <= today.toISOString().split('T')[0])
                    .sort((a, b) => Date.parse(a.date) - Date.parse(b.date));

                const counts = filteredData.map(entry => entry.count);
                const dates = filteredData.map(entry => entry.date);

                this.setState({
                    requesteTrendChartSetY1: [{
                        name: "Total",
                        data: counts
                    }],
                    requesteTrendChartSetX1: dates,
                }, () => {
                    this.setState({
                        requesteTrendChart: true
                    });
                });
            }
        } catch (error) {
            // Handle error
            console.error('Error fetching data:', error);
        }
    };

    stripYearFromDates(dates) {
        return dates.map(date => date.slice(8));
    }

    getMonthlyWiseStatusCount = async (data2) => {
        try {
            this.setState({
                momCompletionChart: false
            })
            const res = await getMonthlyWiseStatusCount("GEN", data2);
            const data = res.data;
            if (data != null) {
                const sortedData = data.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
                const months = sortedData.map(entry => entry.month.substring(0, 3)); // Abbreviate month names
                const completedCount = sortedData.map(entry => entry.completedCount);
                const pendingCount = sortedData.map(entry => entry.pendingCount);

                this.setState({
                    momCompletionChartSetY1: [
                        {
                            name: 'Completed',
                            type: 'column',
                            data: completedCount,
                        },
                        {
                            name: 'Pending',
                            type: 'column',
                            data: pendingCount,
                        },
                    ],
                    momCompletionChartSetX1: months,
                    momCompletionChart: true,
                });
                console.log("Months List Here:", this.state.momCompletionChartSetX1);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    getMonthlyTargetVsCompletionTrendCount = async (data2) => {
        this.setState({
            genServiceTargetChart: false
        });
        var res = await getMonthlyTargetVsCompletionTrendCount("GEN", data2);
        var data = res.data;
        if (data != null) {
            let fff = data.data;
            const currentDate = new Date();
            const today = currentDate.getDate();

            var _completedCount = [];
            var counts = 0;
            let set_year = this.state.filter_date;
            let set_month = this.state.filter_date_to - 1;

            for (var i = 0; i < fff.length; i++) {
                var entryDate = new Date(fff[i].date);
                var entryDay = entryDate.getDate();
                var entryMonth = entryDate.getMonth();
                var entryYear = entryDate.getFullYear();

                if ((entryYear === set_year && entryMonth === set_month && entryDay <= today) || (entryYear !== set_year || entryMonth !== set_month)) {
                    counts += fff[i].count;
                    _completedCount.push(counts);
                }
            }
            console.log(_completedCount);

            let _dateList = fff.map(a => a.date);
            var _avrage = data.target / fff.length;
            var _targetCount = [];

            for (let i = 1; i < _dateList.length + 1; i++) {
                _targetCount.push(Math.round(i * _avrage));
            }

            this.setState({
                genServiceTargetChartSetY1: [
                    {
                        name: "Gen Service Target",
                        data: _targetCount
                    },
                    {
                        name: "Gen Service Completion",
                        data: _completedCount
                    }
                ],
                genServiceTargetChartSetX1: _dateList,
            }, () => {
                this.setState({
                    genServiceTargetChart: true
                });
            });
        }
    };


    // all related functions to the dropdowns
    onChangeRegion = (value) => {
        this.setState({
            depot_list: [],
            filter_depot: [],
            filter_sub_region: [],

        });
        console.log(value);
        this.setState({
            filter_region: value,
            selectedDepot: "all_depots",
            selected_region_id: value,
            SubRegion_list: this.props.regions.filter(alldate => value.map((n) => n).includes(alldate.parentRegion?.id)),
            depot_list: this.props.depots.filter(alldate => value.map((n) => n).includes(alldate.region?.parentRegion?.id))
        }, () => {
            if (this.state.filter_sub_region == "") {
                var getall = this.props.regions.filter(alldate => value.includes(alldate.parentRegion?.id))
                var output = [];
                for (var i = 0; i < getall.length; ++i)
                    output.push(getall[i]['id']);
                var data1 = new FormData();

                console.log(this.state.filter_region);
                data1.append('year', this.state.filter_date);
                data1.append('month', this.state.filter_date_to);
                data1.append('regionIds', this.state.filter_region);
                data1.append('subregionIds', this.state.filter_sub_region);
                data1.append('depotIds', this.state.filter_depot);

                this.getSiteAccessDashboardCounts(data1);
            }
        });
        var data = {
            id: value,
            type: "region",
        };
    };

    onChangeDepot = (value) => {
        this.setState({
            selectedDepot: value,
            filter_depot: value,
        }, () => {
            var data1 = new FormData();

            console.log(this.state.filter_region);
            data1.append('fromDate', this.state.filter_date);
            data1.append('toDate', this.state.filter_date_to);
            data1.append('regionIds', this.state.filter_region);
            data1.append('subregionIds', this.state.filter_sub_region);
            data1.append('depotIds', this.state.filter_depot);

            this.getSiteAccessDashboardCounts(data1);
        });
    }

    dataonChange = (date, dateString) => {
        const [yearValue, monthValue] = dateString.split('-');
        console.log(monthValue)
        console.log(yearValue)
        this.setState({
            filter_date: yearValue,
            filter_date_to: monthValue,
        }, () => {
            var data1 = new FormData();
            data1.append('fromDate', this.state.filter_date);
            data1.append('toDate', this.state.filter_date_to);
            data1.append('year', this.state.filter_date);
            data1.append('month', this.state.filter_date_to);
            data1.append('regionIds', this.state.filter_region);
            data1.append('subregionIds', this.state.filter_sub_region);
            data1.append('depotIds', this.state.filter_depot);
            this.getSiteAccessDashboardCounts(data1);
            this.getPISRegionViseStatusCount(data1);
            this.getPISRegionViseTargetCount(data1);
            this.getMonthlyCompletionTrendCount(data1);
            this.getMonthlyWiseStatusCount(data1);
            this.getMonthlyTargetVsCompletionTrendCount(data1);



        })
    };

    onsubChange = (value) => {
        this.setState({
            depot_list: [],
            filter_depot: [],
        });
        this.setState({
            selectedDepot: "all_depots",
            selected_region_id: value,
            filter_sub_region: value,
            depot_list: this.props.depots.filter(alldate => value.map((n) => n).includes(alldate.region?.id))
        }, () => {
            var data1 = new FormData();
            console.log(this.state.filter_region);
            data1.append('fromDate', this.state.filter_date);
            data1.append('toDate', this.state.filter_date_to);
            data1.append('regionIds', this.state.filter_region);
            data1.append('subregionIds', this.state.filter_sub_region);
            data1.append('depotIds', this.state.filter_depot);
            this.getSiteAccessDashboardCounts(data1);
        });
        var data = {
            id: value,
            type: "region",
        };
    };

    getSiteAccessDashboardCounts = async (data) => {
        var data = data;
        var res = await getPisGenaralDetailsById("GEN", data);
        if (res.data != null) {
            this.setState({
                requested_count: res.data[0]["approval_completed"] || 0,
                installed_count: res.data[0]["service_completed"] || 0,
                pending_count: res.data[0]["pending"] || 0,
                cancelled_count: res.data[0]["cancel"] || 0,
            })
        }
    };

    render() {
        const currentDate = new Date(this.state.filter_date + "-" + this.state.filter_date_to);
        const formattedDate = currentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long' });

        {/* DROPDOWN OPTIONS GOES HERE */ }
        const { Option } = Select;
        function onBlur() {
            // onBlur function goes here
        }

        function onFocus() {
            // onFocus function goes here
        }

        function onSearch(val) {
            // on search function goes here
        }


        return (
            <div class="container-fluid" style={{
                fontFamily: 'Roboto',
            }}>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="page-title-box">
                            <div class="row">
                                <div class="col">
                                    <h4 class="page-title">PIS- Gen Service</h4>
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">PIS- Gen Service</a></li>
                                    </ol>
                                </div>
                                <div class="col-auto align-self-center">
                                    <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>

                    {/* DROPDOWN IMPLEMENTATION GOES HERE */}
                    <div class="col-md-12" style={{
                        background: '#fafafa', padding: '20px', borderRadius: '10px'
                    }}>
                        <div className="row">
                            <div className="col-md-2 col-lg-3">
                                <DatePicker
                                    picker="month"
                                    className='customDropdown textStyles-small'
                                    style={{ width: "100%", borderRadius: "5px" }}
                                    allowClear={false}
                                    defaultValue={moment(moment().format("YYYY-MM-DD").slice(0, -2) + '01')}
                                    size='medium'
                                    onChange={this.dataonChange}
                                />
                            </div>
                            {/* <div className="col-md-2 col-lg-3">
                                <Select
                                    mode="multiple"
                                    showSearch
                                    style={{ width: "100%", borderRadius: "5px" }}
                                    placeholder="Select a region"
                                    optionFilterProp="children"
                                    onChange={this.onChangeRegion}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.props.regions.filter(allret => "0".includes(allret.isSubRegion)).map((item, index) => {
                                        return <Option value={item.id}>{item.name}</Option>;
                                    })}
                                </Select>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <Select
                                    mode="multiple"
                                    showSearch
                                    style={{ width: "100%", borderRadius: "5px" }}
                                    placeholder="Select a Subregion"
                                    optionFilterProp="children"
                                    onChange={this.onsubChange}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {this.state.SubRegion_list.map((item, index) => {
                                        return <Option value={item.id}>{item.name}</Option>;
                                    })}
                                </Select>
                            </div>
                            <div className="col-md-2 col-lg-3">
                                <Select
                                    mode="multiple"
                                    showSearch
                                    style={{ width: "100%", borderRadius: "5px" }}
                                    placeholder="Select a depot"
                                    optionFilterProp="children"
                                    onChange={this.onChangeDepot}
                                    defaultActiveFirstOption={true}
                                    allowClear={true}
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {this.state.depot_list.map((item, index) => {
                                        return <Option value={item.id}>{item.name}</Option>;
                                    })}
                                </Select>
                            </div> */}
                        </div>
                        {/* <Button
                            type="primary"
                            style={{ marginTop: '10px' , marginTop: "10px", borderRadius: "5px", alignContent: "center" }}
                            onClick={() => {
                                var data = new FormData();
                                data.append('fromDate', this.state.filter_date);
                                data.append('toDate', this.state.filter_date_to);
                                data.append('regionIds', this.state.filter_region);
                                data.append('subregionIds', this.state.filter_sub_region);
                                data.append('depotIds', this.state.filter_depot);
                                this.getSiteAccessDashboardCounts(data);
                            }}
                        > <b><FilterOutlined/> Filter Data</b></Button> */}
                    </div>

                    <div style={{ background: 'white', borderRadius: '10px', padding: '20px' }}>
                        <Row>
                            <Col span={12}>
                                <h5 style={{
                                    fontWeight: "bold", fontFamily: 'Roboto'
                                }}>Dashboard Statistics </h5>
                            </Col>
                            <Col span={12}>
                                <div style={{ float: 'right', marginRight: '20px' }}>
                                    <Select
                                        mode="multiple"
                                        showSearch
                                        className="borderedSelect"
                                        bordered={false}
                                        style={{ width: "250px", borderRadius: "5px" }}
                                        placeholder="Select a region"
                                        optionFilterProp="children"
                                        onChange={this.onChangeRegion}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                        onSearch={onSearch}
                                        filterOption={(input, option) =>
                                            option.props.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.props.regions.filter(allret => "0".includes(allret.isSubRegion)).map((item, index) => {
                                            return <Option value={item.id}>{item.name}</Option>;
                                        })}
                                    </Select>

                                    {/* <Select
                                        mode="multiple"
                                        showSearch
                                        className="borderedSelect"
                                        bordered={false}
                                        style={{ width: "250px", borderRadius: "5px", marginLeft: '10px'}}
                                        placeholder="Select a Subregion"
                                        optionFilterProp="children"
                                        onChange={this.onsubChange}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                        onSearch={onSearch}
                                        filterOption={(input, option) =>
                                            option.props.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }>
                                        {this.state.SubRegion_list.map((item, index) => {
                                            return <Option value={item.id}>{item.name}</Option>;
                                        })}
                                    </Select> */}
                                </div>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '10px' }}>
                            <div class="col-md-2 col-lg-3" style={{ marginLeft: '5px', width: '24%' }}>
                                <div class="card report-card  bg-soft-primary" style={{ borderRadius: '20px' }}>
                                    <div class="card-body">
                                        <div class="row d-flex justify-content-center">
                                            <div class="col">
                                                <p class="mb-0 fw-semibold" style={{
                                                    fontSize: "14px"
                                                }}>
                                                    <b>Approval Completed</b></p>
                                                <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.requested_count}</h2>
                                            </div>
                                            <div class="col-auto align-self-center">
                                                <div style={{
                                                    background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                                                    alignItems: "center", justifyContent: "center", margin: "auto"
                                                }} float="left" className="mt-1">
                                                    <PullRequestOutlined style={{ color: "#1761fd", fontSize: '20px' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2 col-lg-3" style={{ marginLeft: '5px', width: '24%' }}>
                                <div class="card report-card bg-soft-success" style={{ borderRadius: '20px' }}>
                                    <div class="card-body" >
                                        <div class="row d-flex justify-content-center">
                                            <div class="col">
                                                <p class="mb-0 fw-semibold" style={{
                                                    fontSize: "14px"
                                                }}><b>Service Completed</b></p>
                                                <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.installed_count}</h2>
                                            </div>
                                            <div class="col-auto align-self-center">
                                                <div style={{
                                                    background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                                                    alignItems: "center", justifyContent: "center", margin: "auto"
                                                }} float="left" className="mt-1">
                                                    <CheckCircleOutlined style={{ color: "#99db7f", fontSize: '20px' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-3" style={{ marginLeft: '5px', width: '24%' }}>
                                <div class="card report-card bg-soft-pink" style={{ borderRadius: '20px' }}>
                                    <div class="card-body" >
                                        <div class="row d-flex justify-content-center">
                                            <div class="col">
                                                <p class="mb-0 fw-semibold" style={{
                                                    fontSize: "14px"
                                                }}><b>Pending</b></p>
                                                <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.pending_count}</h2>
                                            </div>
                                            <div class="col-auto align-self-center">
                                                <div style={{
                                                    background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                                                    alignItems: "center", justifyContent: "center", margin: "auto"
                                                }} float="left" className="mt-1">
                                                    <BarsOutlined style={{ color: "#fd3ca3", fontSize: '20px' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-3" style={{ marginLeft: '5px', width: '24%' }}>
                                <div class="card report-card bg-soft-warning" style={{ borderRadius: '20px' }}>
                                    <div class="card-body" >
                                        <div class="row d-flex justify-content-center">
                                            <div class="col">
                                                <p class="mb-0 fw-semibold" style={{
                                                    fontSize: "14px"
                                                }}><b>Canceled</b></p>
                                                <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.cancelled_count}</h2>
                                            </div>
                                            <div class="col-auto align-self-center">
                                                <div style={{
                                                    background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                                                    alignItems: "center", justifyContent: "center", margin: "auto"
                                                }} float="left" className="mt-1">
                                                    <CloseOutlined style={{ color: "#ffda7e", fontSize: '20px' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-12 col-lg-12">

                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className="card">
                            <div className="card-header" style={{ backgroundColor: '#fafafa' }}>
                                <div className='row'>
                                    <div className="col-md-3">
                                        <h4 className="card-title" style={{
                                            fontWeight: "bold", fontFamily: 'Roboto'
                                        }}>Gen Service Monthly ({formattedDate})</h4>
                                    </div>
                                    <div className="container" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <div className="col">
                                            <span><Badge color="#2D5FF4" text="Completed" style={{ marginLeft: "10px", fontFamily: 'Roboto', fontSize: '9px', fontWeight: 'bold' }} /></span>
                                            <span style={{ marginLeft: "10px", fontFamily: 'Roboto', fontSize: '9px', fontWeight: 'bold' }}><Badge color="#42B02A" text="Target" /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div class="col-md-6 col-lg-2" style={{ textAlign: "center", width: "20%" }} >
                                        {this.state.isRegionViseCountList
                                            ? <SemiCircleGaugeChat
                                                setcolor="#2D5FF4"
                                                setvalues={Number(this.state.regionViseCountList[0].trend).toFixed(2)}
                                                setTitel={this.state.regionViseCountList[0].region}
                                                realValue={this.state.regionViseCountList[0].completedCount}
                                                targetValue={this.state.regionViseCountList[0].targetCount}
                                            /> : ""}
                                    </div>
                                    <div class="col-md-6 col-lg-2" style={{ textAlign: "center", width: "20%" }} >
                                        {this.state.isRegionViseCountList
                                            ? <SemiCircleGaugeChat
                                                setcolor="#2D5FF4"
                                                setvalues={Number(this.state.regionViseCountList[1].trend).toFixed(2)}
                                                setTitel={this.state.regionViseCountList[1].region}
                                                realValue={this.state.regionViseCountList[1].completedCount}
                                                targetValue={this.state.regionViseCountList[1].targetCount}
                                            /> : ""}
                                    </div>
                                    <div class="col-md-6 col-lg-2" style={{ textAlign: "center", width: "20%" }} >
                                        {this.state.isRegionViseCountList
                                            ? <SemiCircleGaugeChat
                                                setcolor="#2D5FF4"
                                                setvalues={Number(this.state.regionViseCountList[2].trend).toFixed(2)}
                                                setTitel={this.state.regionViseCountList[2].region}
                                                realValue={this.state.regionViseCountList[2].completedCount}
                                                targetValue={this.state.regionViseCountList[2].targetCount}
                                            /> : ""}
                                    </div>
                                    <div class="col-md-6 col-lg-2" style={{ textAlign: "center", width: "20%" }} >
                                        {this.state.isRegionViseCountList
                                            ? <SemiCircleGaugeChat
                                                setcolor="#2D5FF4"
                                                setvalues={Number(this.state.regionViseCountList[3].trend).toFixed(2)}
                                                setTitel={this.state.regionViseCountList[3].region}
                                                realValue={this.state.regionViseCountList[3].completedCount}
                                                targetValue={this.state.regionViseCountList[3].targetCount}
                                            /> : ""}
                                    </div>
                                    <div class="col-md-6 col-lg-2" style={{ textAlign: "center", width: "20%" }} >
                                        {this.state.isRegionViseCountList
                                            ? <SemiCircleGaugeChat
                                                setcolor="#2D5FF4"
                                                setvalues={Number(this.state.regionViseCountList[4].trend).toFixed(2)}
                                                setTitel={this.state.regionViseCountList[4].region}
                                                realValue={this.state.regionViseCountList[4].completedCount}
                                                targetValue={this.state.regionViseCountList[4].targetCount}
                                            /> : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <div className="card">
                                <div className="card-header" style={{ backgroundColor: '#fafafa' }}>
                                    <h4 className="card-title" style={{
                                        fontWeight: "bold", fontFamily: 'Roboto'
                                    }}>Gen Service Target vs Completion</h4>
                                </div>
                                <div className="card-body">
                                    {this.state.genServiceTargetChart ? (
                                        <TwoLineChartChart
                                            y1={this.state.genServiceTargetChartSetY1}
                                            x1={this.stripYearFromDates(this.state.genServiceTargetChartSetX1)}
                                            yLabale={'Target Gen And Actual Gen'}
                                            xLabale={'Month'}
                                        />) : ('')
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12">
                            <div className="card">
                                <div className="card-header" style={{ backgroundColor: '#fafafa' }}>
                                    <h4 className="card-title" style={{
                                        fontWeight: "bold", fontFamily: 'Roboto'
                                    }}>Completion Trend</h4>
                                </div>
                                <div className="card-body">
                                    {this.state.requesteTrendChart ? (
                                        <React.Fragment>
                                            <LineCharts
                                                y1={this.state.requesteTrendChartSetY1}
                                                x1={this.stripYearFromDates(this.state.requesteTrendChartSetX1)}
                                                yLabale={"Total"}
                                            />
                                        </React.Fragment>
                                    ) : ('')}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6">
                            <div className="card">
                                <div className="card-header" style={{ backgroundColor: '#fafafa' }}>
                                    <h4 className="card-title" style={{
                                        fontWeight: "bold", fontFamily: 'Roboto'
                                    }}>Request Status</h4>
                                </div>
                                <div className="card-body">
                                    {this.state.requestStatusChart ? (
                                        <StackedBarChart
                                            y1={this.state.requestStatusChartSetY1}
                                            x1={this.state.requestStatusChartSetX1}
                                            yLabale={'Total'}
                                            barColors={['#FF3A29', '#42B02A', '#008FFB', '#FFB200']}
                                            horizontalStatus={false}
                                        />) : ('1')}
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-12 col-lg-6">
                            <div className="card">
                                <div className="card-header" style={{ backgroundColor: '#fafafa' }}>
                                    <h4 className="card-title" style={{
                                        fontWeight: "bold", fontFamily: 'Roboto'
                                    }}>MOM Completion</h4>
                                </div>
                                <div className="card-body">
                                    {this.state.momCompletionChart ? (
                                        <StackedBarChart
                                            y1={this.state.momCompletionChartSetY1}
                                            x1={this.state.momCompletionChartSetX1}
                                            yLabale={'Total'}
                                            barColors={['#42B02A', '#FFB200']}
                                            horizontalStatus={false}
                                        />
                                    ) : ('1')}
                                    {console.log("Months List Here:", this.state.momCompletionChartSetX1)}
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        organization_count: state.organization.length,
        regions: state.regions,
        depots: state.depots,
        sites_count: state.sites.length,
        vendor_count: state.vendor.length,
        roles_count: state.roles.length,
    };
}

export default connect(mapStateToProps, { getDonationCategories, getData })(
    DashboardGen
);

