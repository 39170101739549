import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { createNewRegion } from "../../app/api/index";
import { ToastContainer, toast } from "material-react-toastify";
import { getPgData } from "../api/index";

import { Table, Input, Tag, Space } from "antd";


class Pg extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      // filterTable: null,
      baseData: "",
      pgData: "",
      pgPendingCount: 0,
      filterTable: null,
      isPgSelected: false,
      selectedPgData: null,
    };
  }
  organizationList() {
    //console.log("kk");
  }
  componentDidMount() {
    this.getPgData();
  }

  getPgData = async () => {
    var res = await getPgData();
    // //console.log(res.data.requests);

    if (res.success) {
      this.setState({
        pgData: res.data.requests,
        // pgPendingCount:res.data.counts.pendingCount
      });
    }
  };

  formItemChange = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
    //console.log(obj);
  };

  formItemChecked = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.checked;
    this.setState(obj);
    //console.log(obj);
  };
  search = (value) => {
    const { baseData } = this.state;
    //console.log("PASS", { value });

    const filterTable = this.state.pgData.filter((o) => {
      //console.log((o));
      return (
        Object.keys(o).some((k) => {
          //console.log(o[k]);
         return String(o[k]).toLowerCase().includes(value.toLowerCase())  ||  (o[k].requestCode && o[k].requestCode.toLowerCase() == value.toLowerCase());
        }) 
      );
    });

    this.setState({ filterTable });
  };
  filterRegions = (value, region) => {
    var rr = region.filter((d) => d.name.includes(value));
    //console.log(rr);
    return rr.length > 0;
  };
  render() {
    const { filterTable, pgData } = this.state;

    const columns = [
      {
        title: "#",
        dataIndex: "id",
        defaultSortOrder: "ascend",
        sorter: (a, b) => a.id - b.id,
        sortDirections: ["descend"],
        render: (text, record, index) => <span>{text}</span>,
      },
      {
        title: "Request Date",
        dataIndex: "serviceRequest",
        key: "serviceRequest",
        //sorter: (a, b) => a.name.localeCompare(b.name),
        render: (item) => <span>{item.requestDate}</span>,
      },
      {
        title: "Request Time",
        dataIndex: "serviceRequest",
        key: "serviceRequest",
        //sorter: (a, b) => a.name.localeCompare(b.name),
        render: (item) => <span>{item.requestTime}</span>,
      },
      {
        title: "Code",
        dataIndex: "serviceRequest",
        key: "serviceRequest",
        //sorter: (a, b) => a.name.localeCompare(b.name),
        render: (item) => <span>{item.requestCode}</span>,
      },
      {
        title: "Site",
        dataIndex: "serviceRequest",
        key: "serviceRequest",
        //sorter: (a, b) => a.name.localeCompare(b.name),
        render: (item) => (
          <>
            <Tag style={{ fontSize: 10 }} color="blue" key={item}>
              {item.site.siteName.toUpperCase()}
            </Tag>
          </>
        ),
      },
      {
        title: "Status",
        dataIndex: "pgStatus",
        key: "pgStatus",
        //sorter: (a, b) => a.name.localeCompare(b.name),

        render: (item) => (
          <>
            <Tag style={{ fontSize: 10 }} color="success" key={item}>
              {item.name.toUpperCase()}
            </Tag>
          </>
        ),
      },
    ];

    // const columns = [
    //   {
    //     title: "#",
    //     dataIndex: "id",

    //     defaultSortOrder: "ascend",
    //     sorter: (a, b) => a.id - b.id,
    //     sortDirections: ["descend"],
    //     render: (text, record, index) => <span>{text}</span>,
    //   },
    //   {
    //     title: "Name",
    //     dataIndex: "name",
    //     key: "name",
    //     sorter: (a, b) => a.name.localeCompare(b.name),
    //     render: (text) => <span>{text}</span>,
    //   },
    //   {
    //     title: "Email",
    //     dataIndex: "email",
    //     key: "email",
    //     filters: [
    //       {
    //         text: "@dns.dialog.lk",
    //         value: "@dns.dialog.lk",
    //       },
    //       {
    //         text: "@eimsky.com",
    //         value: "@eimsky.com",
    //       },
    //     ],
    //     // specify the condition of filtering result
    //     // here is that finding the name started with `value`
    //     onFilter: (value, record) => record.email.includes(value),
    //     sorter: (a, b) => a.email.localeCompare(b.email),
    //   },
    //   {
    //     title: "Mobile",
    //     dataIndex: "mobileNumber",
    //     key: "mobileNumber",
    //   },
    //   {
    //     title: "Roles",
    //     key: "roles",
    //     dataIndex: "roles",
    //     filters: this.props.roles.map((item) => ({
    //       text: item.name.toUpperCase(),
    //       value: item.name,
    //     })),

    //     onFilter: (value, record) => this.filterRegions(value, record.roles),
    //     render: (tags) => (
    //       <>
    //         {tags.map((tag) => {
    //           let color = "geekblue";
    //           return (
    //             <Tag color={color} key={tag} style={{ fontSize: 10 }}>
    //               {tag.name.toUpperCase()}
    //             </Tag>
    //           );
    //         })}
    //       </>
    //     ),
    //   },
    //   {
    //     title: "Region",
    //     key: "region",
    //     dataIndex: "region",
    //     filters: this.props.regions.map((item) => ({
    //       text: item.name.toUpperCase(),
    //       value: item.name,
    //     })),

    //     onFilter: (value, record) => this.filterRegions(value, record.region),

    //     render: (tags) => (
    //       <>
    //         {tags.map((tag) => {
    //           let color = "green";

    //           return (
    //             <Tag color={color} key={tag} style={{ fontSize: 10 }}>
    //               {tag.name.toUpperCase()}
    //             </Tag>
    //           );
    //         })}
    //       </>
    //     ),
    //   },
    //   {
    //     title: "Organization",
    //     key: "organization",
    //     dataIndex: "organization",
    //     filters: this.props.organization.map((item) => ({
    //       text: item.name.toUpperCase(),
    //       value: item.name,
    //     })),

    //     onFilter: (value, record) =>
    //       record.organization.name.indexOf(value) === 0,

    //     render: (organization) => (
    //       <>
    //         <Tag style={{ fontSize: 10 }} color="volcano" key={organization}>
    //           {organization.name.toUpperCase()}
    //         </Tag>
    //       </>
    //     ),
    //   },

    //   // {
    //   //   title: 'Action',
    //   //   key: 'action',
    //   //   sorter: true,
    //   //   render: () => (
    //   //     <Space size="small">
    //   //       <a>Delete</a>
    //   //       <a className="ant-dropdown-link">

    //   //       </a>
    //   //     </Space>
    //   //   ),
    //   // },
    //   {
    //     title: "Action",
    //     key: "action",
    //     render: (text, record) => (
    //       <Space size="middle">
    //         <a>View</a>
    //       </Space>
    //     ),
    //   }
    // ];
    return (
      <div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">PG</h4>
              </div>
              <div className="card-body">
                <Input
                  enterButton
                  style={{ marginBottom: 5 }}
                  placeholder="Search ..."
                  onChange={(e) => {
                    const currValue = e.target.value;
                    this.search(currValue);
                  }}
                />

                <Table
                  size="small"
                  columns={columns}
                  dataSource={filterTable == null ? pgData : filterTable}
                />
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="top-right" autoClose={5000} />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    organization: state.organization,
    regions: state.regions,
    roles: state.roles,
  };
}
export default connect(mapStateToProps)(Pg);
