import React, { Component } from 'react'
import Rectifiers from '../../components/power-data-manage/rectifiers'
import RectifiersModel from '../../components/power-data-manage/rectifiers-model'
import CabinetModel from '../../components/power-data-manage/cabinet-model'
import BatteryBank from '../../components/power-data-manage/battery-bank'
import RectifierModuleModel from '../../components/power-data-manage/rectifier-module-model'
import AcMake from '../../components/power-data-manage/ac-make'
import FcbModel from '../../components/power-data-manage/fcb-model'
import GeneratorModel from '../../components/power-data-manage/generator-model'
import AtsModel from '../../components/power-data-manage/ats-model'
import SpdModel from '../../components/power-data-manage/spd-model'
import SolarPanelModel from '../../components/power-data-manage/solar-panel-model'
import InverterConverterModel from '../../components/power-data-manage/inverter-converter-model'
import GenMake from '../../components/power-data-manage/generatorMake'

import { getMasterDataSiteInfo } from "../../api/index";

class DataManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cabinetMasterData: null


        }

    }
    componentDidMount() {

        this.getAllMasterData()
    }
    getAllMasterData = async () => {


        var res = await getMasterDataSiteInfo();

        console.log('masterData')
        console.log(res)
        if (res.success) {
            this.setState({
                cabinetMasterData:res.data.cabinetMake
            })


        }





    }

    render() {
        const { cabinetMasterData } = this.state
        return (
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="page-title-box">
                            <div class="row">
                                <div class="col">
                                    <h4 class="page-title">Power Data</h4>
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Power Data </a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Manage </a></li>

                                    </ol>
                                </div>
                                <div class="col-auto align-self-center">
                                    <div class="btn btn-sm btn-outline-primary" id="Dash_Date">
                                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div class="col-md-12 col-lg-12">


                        <ul class="nav-border nav nav-pills" id="pills-tab" role="tablist" style={{ fontSize: 13 }}>
                            <li class="nav-item">
                                <a class="nav-link active" data-bs-toggle="tab" href="#Rectifier" role="tab" aria-selected="false">Rectifier</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#rectifierModule" role="tab" aria-selected="false">Rectifier Module</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link " data-bs-toggle="tab" href="#batteryBank" role="tab" aria-selected="true">	Battery Bank</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link " data-bs-toggle="tab" href="#cabinet" role="tab" aria-selected="false">Cabinet</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#acTab" role="tab" aria-selected="false">AC </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link " data-bs-toggle="tab" href="#fcbTab" role="tab" aria-selected="true">	FCB</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link " data-bs-toggle="tab" href="#generator" role="tab" aria-selected="true">Generator Modal</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#generator-make" role="tab" aria-selected="false">Generator Make</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link " data-bs-toggle="tab" href="#atsTab" role="tab" aria-selected="false">ATS</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#spdTab" role="tab" aria-selected="false">SPD </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link " data-bs-toggle="tab" href="#solarPane" role="tab" aria-selected="true">	Solar Pane</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link " data-bs-toggle="tab" href="#inverterConverter" role="tab" aria-selected="true">Inverter / Converter</a>
                            </li>
                        </ul>


                        <div class="tab-content">
                            <div class="tab-pane p-3 active" id="Rectifier" role="tabpanel">
                                <p class="mb-0 text-muted">
                                    <RectifiersModel />
                                </p>
                            </div>
                            <div class="tab-pane p-3" id="rectifierModule" role="tabpanel">
                                <p class="mb-0 text-muted">
                                    <RectifierModuleModel />
                                </p>
                            </div>
                            <div class="tab-pane p-3 " id="batteryBank" role="tabpanel">
                                <p class="text-muted mb-0">
                                    <BatteryBank />
                                </p>
                            </div>
                            <div class="tab-pane p-3 " id="cabinet" role="tabpanel">
                                <p class="text-muted mb-0">
                                <CabinetModel masterData={{cabinetMasterData}} />  
                                </p>
                            </div>
                            <div class="tab-pane p-3 " id="acTab" role="tabpanel">
                                <p class="text-muted mb-0">
                                    <AcMake />
                                </p>
                            </div>
                            <div class="tab-pane p-3 " id="fcbTab" role="tabpanel">
                                <p class="text-muted mb-0">
                                    <FcbModel />
                                </p>
                            </div>
                            <div class="tab-pane p-3 " id="generator" role="tabpanel">
                                <p class="text-muted mb-0">
                                    <GeneratorModel />
                                </p>
                            </div>

                            <div class="tab-pane p-3 " id="generator-make" role="tabpanel">
                                <p class="text-muted mb-0">
                                    <GenMake />
                                </p>
                            </div>

                            <div class="tab-pane p-3 " id="atsTab" role="tabpanel">
                                <p class="text-muted mb-0">
                                    <AtsModel />
                                </p>
                            </div>
                            <div class="tab-pane p-3 " id="spdTab" role="tabpanel">
                                <p class="text-muted mb-0">
                                    <SpdModel />
                                </p>
                            </div>
                            <div class="tab-pane p-3 " id="solarPane" role="tabpanel">
                                <p class="text-muted mb-0">
                                    <SolarPanelModel />
                                </p>
                            </div>
                            <div class="tab-pane p-3 " id="inverterConverter" role="tabpanel">
                                <p class="text-muted mb-0">
                                    <InverterConverterModel />
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default DataManage;
