import ReactFlow, { MiniMap, Controls, Background, applyNodeChanges, ConnectionLineType, ReactFlowProvider } from 'react-flow-renderer';
import { useCallback, useState, useEffect } from 'react';
import { useLocation } from "react-router-dom"
import 'react-flow-renderer/dist/style.css';
import { getsldDiagramById } from '../../api/index';
import { getSiteDetails } from '../../api/Repos/SiteRepo';
import { Table, Tag, Button, Drawer, Form, Input, Select, InputNumber, message, Popconfirm, DatePicker } from 'antd';



import TextUpdaterNode from './TextUpdaterNode.js';

// import './text-updater-node.css';

function Flow(props) {
    let fullEdgesSet = []
    let fullNodesSet = []
    let fullObject = [
        {
            "itemId": 1,
            "name": "ACDB",
            "levelNo": 3,
            "connectedItems": [
                6
            ]
        },
        {
            "itemId": 2,
            "name": "ATS",
            "levelNo": 2,
            "connectedItems": [
                1
            ]
        },
        {
            "itemId": 3,
            "name": "Commercial Power",
            "levelNo": 1,
            "connectedItems": [
                2
            ]
        },
        {
            "itemId": 4,
            "name": "Generator 1",
            "levelNo": 1,
            "connectedItems": [
                2
            ]
        },
        {
            "itemId": 5,
            "name": "Generator 1",
            "levelNo": 1,
            "connectedItems": [
                2
            ]
        },
        {
            "itemId": 6,
            "name": "rectifire",
            "levelNo": 4,
            "connectedItems": [
                7,
                8,
                9,
                10
            ]
        },
        {
            "itemId": 7,
            "name": "batteryBank",
            "levelNo": 5,
            "connectedItems": []
        },
        {
            "itemId": 8,
            "name": "batteryBank",
            "levelNo": 5,
            "connectedItems": []
        },
        {
            "itemId": 9,
            "name": "dcdu",
            "levelNo": 5,
            "connectedItems": []
        },
        {
            "itemId": 10,
            "name": "dcdu",
            "levelNo": 5,
            "connectedItems": []
        },
        {
            "itemId": 11,
            "name": "Solar",
            "levelNo": 1,
            "connectedItems": [
                6
            ]
        }
    ]

    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const [siteName, setSiteName] = useState('');
    const [siteId, setSiteId] = useState('');

    useEffect(() => {






        // setNodeLevelOne(fullObject)
        // setEdgeslOne(fullObject)
        getDatasldDiagramById()
        getSiteData()




    }, []);
    
    async function getDatasldDiagramById() {
        let siteId = props.details.site_details.id
        var res = await getsldDiagramById(siteId);

        console.log(res)

        if (res.success) {
            fullObject = res.data
            setNodeLevelOne(fullObject)
            setEdgeslOne(fullObject)
        }

    }

    async function getSiteData() {
        let siteId = props.details.site_details.id

        var res = await getSiteDetails(siteId);
        console.log(res)

        if (res.success) {
            setSiteName(res.data.siteName)
            setSiteId(res.data.siteId)

        }

    }
    const reSetSLD = () => {
        getDatasldDiagramById()
        getSiteData()

    }

    const generateColor = () => {
        let randomColorString = "#";
        const arrayOfColorFunctions = "0123456789abcdef";
        for (let x = 0; x < 6; x++) {
            let index = Math.floor(Math.random() * 16);
            let value = arrayOfColorFunctions[index];

            randomColorString += value;
        }
        return randomColorString;
    };
    const setEdgeslOne = (data) => {
        const levelOne = data.filter(person => person.levelNo == 1)
        createEdges(levelOne, 1)

    }
    const setEdgeslTwo = (data) => {
        const levelTwo = data.filter(person => person.levelNo == 2)
        createEdges(levelTwo, 2)

    }
    const setEdgeslThree = (data) => {
        const levelThree = data.filter(person => person.levelNo == 3)
        createEdges(levelThree, 3)

    }
    const setEdgeslFour = (data) => {
        const levelFour = data.filter(person => person.levelNo == 4)
        createEdges(levelFour, 4)

    }

    const setNodeLevelOne = (data) => {
        const levelOne = data.filter(person => person.levelNo == 1)
        createNodes(levelOne, 1)

    };
    const setNodeLevelTwo = (data) => {
        const levelTwo = data.filter(person => person.levelNo == 2)
        createNodes(levelTwo, 2)


    };
    const setNodeLevelThree = (data) => {
        const levelThree = data.filter(person => person.levelNo == 3)
        createNodes(levelThree, 3)
    };
    const setNodeLevelFour = (data) => {
        const levelFour = data.filter(person => person.levelNo == 4)
        createNodes(levelFour, 4)
    };
    const setNodeLevelFive = (data) => {
        const levelFive = data.filter(person => person.levelNo == 5)
        createNodes(levelFive, 5)
    };
    const createEdges = (data, lv) => {
        if (lv == 1) {

            var newseArray
            var newseArray2 = []

            var newNodesArray = data.map(value => {

                value.connectedItems.map(x => {
                    newseArray = {
                        "id": value.itemId + '-' + x.toString(),
                        source: value.itemId.toString(),
                        target: x.toString(),


                        style: { stroke: "#f6ab6c", strokeWidth: 2 }
                    }

                    newseArray2 = [...newseArray2, newseArray];
                })


            })

            fullEdgesSet = newseArray2
            setEdgeslTwo(fullObject)

        }
        if (lv == 2) {

            var newseArray
            var newseArray2 = []
            var newNodesArray = data.map(value => {

                value.connectedItems.map(x => {
                    newseArray = {
                        "id": value.itemId + '-' + x.toString(),
                        source: value.itemId.toString(),
                        target: x.toString(),
                        type: 'straight',
                        style: { strokeWidth: 2 }
                    }

                    newseArray2 = [...newseArray2, newseArray];
                })


            })



            fullEdgesSet = [...fullEdgesSet, ...newseArray2];
            setEdgeslThree(fullObject)



        }
        if (lv == 3) {

            var newseArray
            var newseArray2 = []
            var newNodesArray = data.map(value => {

                value.connectedItems.map(x => {
                    newseArray = {
                        "id": value.itemId + '-' + x.toString(),
                        source: value.itemId.toString(),
                        target: x.toString(),

                        style: { strokeWidth: 2 }
                    }

                    newseArray2 = [...newseArray2, newseArray];
                })


            })



            fullEdgesSet = [...fullEdgesSet, ...newseArray2];
            setEdgeslFour(fullObject)



        }
        if (lv == 4) {

            var newseArray
            var newseArray2 = []
            var newNodesArray = data.map(value => {
                let setColor = generateColor();
                value.connectedItems.map(x => {
                    newseArray = {
                        "id": value.itemId + '-' + x.toString(),
                        source: value.itemId.toString(),
                        target: x.toString(),

                        style: { stroke: setColor, strokeWidth: 3 }
                    }

                    newseArray2 = [...newseArray2, newseArray];
                })


            })



            fullEdgesSet = [...fullEdgesSet, ...newseArray2];
            setEdges(fullEdgesSet)



        }

    }
    const createNodes = (data, lv) => {

        if (lv == 1) {

            var newseArray
            var setY = 30
            var newNodesArray = data.map(value => {
                if (setY == 30) {
                    var setY12 = setY
                    setY = setY + 50
                } else {
                    var setY12 = setY
                    setY = setY + 50
                }
                return newseArray = {
                    "id": value.itemId.toString(),
                    "data": { label: value.name },
                    type: 'input',
                    sourcePosition: "right",
                    position: { x: 20, y: setY12 },
                    style: {
                        background: "#FFA500",
                        color: "#FFFFFF",
                        border: "1px solid #222138",
                        fontSize: 14,
                        padding: 6
                    }
                }
            })
            console.log('newNodesArray')
            fullNodesSet = newNodesArray
            setNodeLevelTwo(fullObject)
        }
        if (lv == 2) {

            var newseArray
            var setY = 30
            var newNodesArray = data.map(value => {
                if (setY == 30) {
                    var setY12 = setY
                    setY = setY + 100
                } else {
                    var setY12 = setY
                    setY = setY + 100
                }
                return newseArray = {
                    "id": value.itemId.toString(),
                    "data": { label: value.name },
                    targetPosition: "left",

                    sourcePosition: "right",
                    position: { x: 250, y: setY12 },
                    style: {
                        background: "#5539CC",
                        color: "#FFFFFF",
                        border: "1px solid #222138",
                        fontSize: 14,
                        padding: 6

                    }
                }
            })

            fullNodesSet = [...fullNodesSet, ...newNodesArray];
            setNodeLevelThree(fullObject)
        }
        if (lv == 3) {
            var newseArray
            var setY = 30
            var newNodesArray = data.map(value => {
                if (setY == 30) {
                    var setY12 = setY
                    setY = setY + 100
                } else {
                    var setY12 = setY
                    setY = setY + 100
                }
                return newseArray = {
                    "id": value.itemId.toString(),
                    "data": { label: value.name },
                    targetPosition: "left",

                    sourcePosition: "right",
                    position: { x: 420, y: setY12 },
                    style: {
                        background: "#5539CC",
                        color: "#FFFFFF",
                        border: "1px solid #222138",
                        fontSize: 14,
                        padding: 6

                    }
                }
            })

            fullNodesSet = [...fullNodesSet, ...newNodesArray];
            setNodeLevelFour(fullObject)




        }
        if (lv == 4) {
            var newseArray
            var setY = 50
            var newNodesArray = data.map(value => {
                if (setY == 50) {
                    var setY12 = setY
                    setY = setY + 100
                } else {
                    var setY12 = setY
                    setY = setY + 100
                }
                return newseArray = {
                    "id": value.itemId.toString(),
                    "data": { label: value.name },
                    targetPosition: "left",

                    sourcePosition: "right",
                    position: { x: 650, y: setY12 },
                    style: {
                        background: "#B6B6B4",
                        color: "#FFFFFF",
                        border: "1px solid #222138",
                        fontSize: 14,
                        padding: 6


                    }
                }
            })

            fullNodesSet = [...fullNodesSet, ...newNodesArray];
            setNodeLevelFive(fullObject)


        }
        if (lv == 5) {
            var newseArray
            var setY = 10
            var newNodesArray = data.map(value => {
                if (setY == 10) {
                    var setY12 = setY
                    setY = setY + 60
                } else {
                    var setY12 = setY
                    setY = setY + 60
                }
                return newseArray = {
                    "id": value.itemId.toString(),
                    "data": { label: value.name },
                    type: 'output',

                    targetPosition: "left",


                    position: { x: 900, y: setY12 },
                    style: {
                        background: value.color !== null ? ('#' + value.color) : ('#8FBA74'),
                        color: "#FFFFFF",
                        border: "1px solid #222138",
                        fontSize: 14,
                        padding: 6


                    }
                }
            })

            fullNodesSet = [...fullNodesSet, ...newNodesArray];

            console.log('fullNodesSet2222222222222');
            console.log(fullNodesSet);
            setNodes(fullNodesSet)
        }
    }


    const nodeTypes = { textUpdater: TextUpdaterNode };

    const onNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [setNodes]
    );

    return (
        <>
            <div className='row' >
                <div class="col-lg-12"><div class="text-end"><ul class="list-inline"><li class="list-inline-item"><button type="button" class="btn btn-soft-primary btn-sm" onClick={() => reSetSLD()} ><i class="fas fa-undo"></i> ReSet </button></li></ul></div></div>

            </div>
            <div className="layoutflow" style={{ height: "40em" }}>



                <ReactFlowProvider>

                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        nodeTypes={nodeTypes}
                        onNodesChange={onNodesChange}
                        connectionLineType={ConnectionLineType.SmoothStep}
                        // snapToGrid={true}
                        showZoom={true}


                    // onEdgesChange={onEdgesChange}
                    // onConnect={onConnect}
                    >

                        <Controls />

                    </ReactFlow>

                </ReactFlowProvider>
            </div>

        </>







    );
}

export default Flow;
