import React, { Component } from 'react';
import { Table, Input, InputNumber, Tag, Space, Select, Form, DatePicker, TimePicker, Button, Switch, message, Steps, Alert, notification } from "antd";
import ReactLoading from 'react-loading';

import { getAllSiteType, createSite, getSiteAccessBaseData, getAllDistrict, getAllEnergyCategory, getAllSiteCategory, getAllSiteOwner, getAllEntity, getAllTowerCategory, getAllTowerType, getAllCoolingMethods, getAllShelterType, getAllGenHutType } from "../../api/index";

import { connect } from "react-redux";
import moment from 'moment';
import SimpleReactValidator from "simple-react-validator";
import { checkSiteIDDuplicateCheck } from "../../api/index";
import { getDonationCategories, getData } from "../../actions/index";
import { withRouter } from 'react-router-dom'
import { getAllDropdowns } from "../../api/index";
class SiteCreate extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.validator2 = new SimpleReactValidator();


        this.state = {
            current: 0,
            current2: 0,
            items: [],
            allSite: [],
            siteName: null,
            siteId: null,
            allSiteType: [],
            allDistrict: [],
            allEnergyCategory: [],
            allSiteOwner: [],
            selectedDistrictId: '',
            siteType: [],
            selectedSiteTypeId: '',
            selectedDepotTypeId: '',
            selectedEnergyCategory: '',
            selectedSiteCategoryId: '',
            selectedSiteOwnerId: '',
            accessPermitionGroupId: '',
            shelterTypeId: '',
            selectedtowerCategoryId: '',
            selectedTowerTypeId: '',
            coolingMethodsTypeId: '',
            selectedGeneratorHut1Typ: '',
            selectedGeneratorHut2Typ: '',

            selectedSiteEntityId: '',
            depot_list: [],
            depotType: [],
            accessPermitionGroupType: [],
            district: [],
            siteOwner: [],
            towerCategory: [],
            towerType: [],
            energyCategory: [],
            siteEntity: [],
            siteCategory: [],
            coolingMethods: [],
            generatorHut1Typ: null,
            generatorHut2Typ: null,
            allSiteCategory: [],
            allSiteEntity: [],
            allTowerCategory: [],
            allTowerType: [],
            GenHutType: [],
            accessPermitionGroup: null,
            allCoolingMethods: [],
            allShelterType: [],
            isStandbyGeneratorAvailable: '',
            manualTransport: '',
            manualDistanceMeter: '',
            refuelManualDistance: '',
            pgPossibility: '',
            siteLatitude: '',
            siteLongitude: '',
            siteAddress: '',
            towerOwnerCategory: '',
            towerHeight: '',
            siteStatus: '',
            siteOnAirDate: '',
            tenants: '',
            powerSharedTenants: '',
            noOfPhases: '',
            towerLegsCount: '',
            indoorOutdoor: '',
            siteElevation: '',
            ambientTemperature: '',
            dimension: '',
            travelTimeFromDepot: '',
            isIdleGeneratorHutAvaialable: '',
            isSpaceAvailableNewGen: '',
            workingDaysAccessibleHours: '',
            yearoftheJFS: '',
            freeLandAvailable: '',
            weekendsOrHolidaysAccessibleHours: '',
            shelterSize: '',
            nonShadingSpaceAvailabilityOnshelter: '',
            nonShadingSpaceAvailabilityGround: '',
            dependentCellCount: '',
            distancefromRegionalOffice: '',
            accessPermissionProvidingParty: '',
            activePassive: '',
            siteTopologyTerrainMapping: '',
            timeRestrictedReason: '',
            pisVendor: '',
            landAreainPerch: '',
            vehicleType: '',

            isAccessPermissionRequired: '',
            isSiteKeyRequired: '',
            portablegenConnectingSetupAvailability: '',
            submitButtonDisable: false,
            indoorOutdoorid: '',
            indoorOutdoorType: null,

            keyLocation: '',
            sOwnerName: '',
            sOwnerAddress: '',

            siteIDUniquensess: false,

            dropdownDataSet: {
                "noOftenants": [],
            },

        };

        this.handleSiteName = this.handleSiteName.bind(this);
        this.handleSiteId = this.handleSiteId.bind(this);
        this.handleManualDistanceMeter = this.handleManualDistanceMeter.bind(this);
        this.handleYearoftheJFS = this.handleYearoftheJFS.bind(this);
        this.handleAccessPermissionProvidingParty = this.handleAccessPermissionProvidingParty.bind(this);
        this.handleactivePassive = this.handleactivePassive.bind(this);
        this.handlevehicleType = this.handlevehicleType.bind(this);
        this.handletimeRestrictedReason = this.handletimeRestrictedReason.bind(this);
        this.handlePISvendor = this.handlePISvendor.bind(this);
        this.handlelandAreainPerch = this.handlelandAreainPerch.bind(this);
        this.handlesiteTopologyTerrainMapping = this.handlesiteTopologyTerrainMapping.bind(this);
        this.handleRefuelManualDistanc = this.handleRefuelManualDistanc.bind(this);
        this.handleDistancefromRegionalOffice = this.handleDistancefromRegionalOffice.bind(this);
        this.handleSiteLatitude = this.handleSiteLatitude.bind(this);
        this.handleSiteLongitude = this.handleSiteLongitude.bind(this);
        this.handleSiteAddress = this.handleSiteAddress.bind(this);
        this.handleTowerOwnerCategory = this.handleTowerOwnerCategory.bind(this);
        this.handleTowerHeight = this.handleTowerHeight.bind(this);
        this.handleStatus = this.handleStatus.bind(this);
        this.handleTenants = this.handleTenants.bind(this);
        this.handlePowerSharedTenants = this.handlePowerSharedTenants.bind(this);
        this.handleNoOfPhases = this.handleNoOfPhases.bind(this);
        this.handleTowerLegsCount = this.handleTowerLegsCount.bind(this);
        this.handleIndoorOutdoor = this.handleIndoorOutdoor.bind(this);
        this.handleSiteElevation = this.handleSiteElevation.bind(this);
        this.handleAmbientTemperature = this.handleAmbientTemperature.bind(this);
        this.handleDimension = this.handleDimension.bind(this);
        this.handleFreeLandAvailable = this.handleFreeLandAvailable.bind(this);
        this.handleshelterSize = this.handleshelterSize.bind(this);
        this.handleNonShadingSpaceAvailabilityOnshelter = this.handleNonShadingSpaceAvailabilityOnshelter.bind(this);
        this.handleNonShadingSpaceAvailabilityGround = this.handleNonShadingSpaceAvailabilityGround.bind(this);
        this.handleDependentCellCount = this.handleDependentCellCount.bind(this);
        this.handleTravelTimeFromDepot = this.handleTravelTimeFromDepot.bind(this);
        this.handleWorkingDaysAccessibleHours = this.handleWorkingDaysAccessibleHours.bind(this);
        this.handleWeekendsOrHolidaysAccessibleHours = this.handleWeekendsOrHolidaysAccessibleHours.bind(this);

        this.handleSOwnerName = this.handleSOwnerName.bind(this);
        this.handleSOwnerAddress = this.handleSOwnerAddress.bind(this);
        this.handleKeyLocation = this.handleKeyLocation.bind(this);

    }

    componentDidMount() {

        if (JSON.parse(localStorage.getItem('serviceTypeSet')) == null) {
            window.location.reload(false);

        } else {
            this.getSetPageData(JSON.parse(localStorage.getItem('serviceTypeSet')), 13)
        }
        this.props.getData();
        this.getDataAllSiteType();
        this.getDataAllDistrict();
        this.getDataAllEnergyCategory();
        this.getDataAllSiteCategory();
        this.getDataAllSiteOwner();
        this.getDataAllEntity();
        this.getDataAllTowerCategory();
        this.getDataAllTowerType();
        this.getDataAllGenHutType();
        this.getDataAllCoolingMethods();
        this.getDataAllShelterType();
        this.getDataSiteAccessBaseData();
        this.getDropDownData();
    
        this.setState({
            depot_list: this.props.depots,
        })
    }

    getDropDownData = async () => {
        var res = await getAllDropdowns();
        console.log("---------------------------------------------------------------------------------------------------");
        console.log(res);
        if (res.success) {
            this.setState({
                dropdownDataSet: {
                    "noOftenants":  res.data["General Details"]["No. of tenants"],
                }
            })
            console.log(this.state.dropdownDataSet)
        } else {
            message.warning('Issue With Dropdown Data Fetching! : ' + res.data.message);
        }
    }

    getSetPageData = (serviceTypeIs, Pageid) => {
        if (serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == Pageid)[0] == null) {
            const { history } = this.props;

            history.push("/not-allowed");
        }

    };
    handleDependentCellCount(e) {
        //console.log(e)
        this.setState({ dependentCellCount: e });
    }
    handleNonShadingSpaceAvailabilityGround(e) {
        console.log(e.target.value)
        this.setState({ nonShadingSpaceAvailabilityGround: e.target.value });
    }
    handleNonShadingSpaceAvailabilityOnshelter(e) {
        //console.log(e.target.value)
        this.setState({ nonShadingSpaceAvailabilityOnshelter: e.target.value });
    }
    handleshelterSize(e) {
        //console.log(e.target.value)
        this.setState({ shelterSize: e.target.value });
    }
    handleFreeLandAvailable(e) {
        //console.log(e.target.value)
        this.setState({ freeLandAvailable: e.target.value });
    }
    handleWeekendsOrHolidaysAccessibleHours(e) {
        //console.log(e.target.value)
        this.setState({ weekendsOrHolidaysAccessibleHours: e.target.value });
    }
    handleWorkingDaysAccessibleHours(e) {
        //console.log(e.target.value)
        this.setState({ workingDaysAccessibleHours: e.target.value });
    }
    handleTravelTimeFromDepot(e) {
        //console.log(e.target.value)
        this.setState({ travelTimeFromDepot: e.target.value });
    }
    handleDimension(e) {
        //console.log(e.target.value)
        this.setState({ dimension: e.target.value });
    }

    handleAmbientTemperature(e) {
        //console.log(e.target.value)
        this.setState({ ambientTemperature: e.target.value });
    }
    handleSiteElevation(e) {
        console.log(e.target.value)
        this.setState({ siteElevation: e.target.value });
    }

    handleStatus(e) {
        console.log(e.target.value)
        this.setState({ siteStatus: e.target.value });
    }
    handleTowerHeight(e) {
        //console.log(e.target.value)
        this.setState({ towerHeight: e.target.value });
    }
    handleIndoorOutdoor(e) {
        //console.log(e.target.value)
        this.setState({ indoorOutdoor: e.target.value });
    }
    handleSiteName(e) {
        //console.log(e.target.value)
        this.setState({ siteName: e.target.value });
    }
    handleSiteAddress(e) {
        //console.log(e.target.value)
        this.setState({ siteAddress: e.target.value });
    }
    handleTowerOwnerCategory(e) {
        //console.log(e.target.value)
        this.setState({ towerOwnerCategory: e.target.value });
    }

    handleSiteId(e) {

        console.log("site id long : " + e.target.value)
        this.setState({ siteIDUniquensess: false });

        if (e.target.value.length <= 0 || e.target.value === null || e.target.value === "") {
            this.setState({ siteId: e.target.value });
            this.setState({ siteIDUniquensess: false });
        } else {
            this.setState({ siteId: e.target.value });
            this.onChangeCheckSiteID_Duplication(e.target.value);
        }

        // if(e.target.value.length <= 0){
        //     this.setState({ siteIDUniquensess: false });
        // } else {
        //     this.onChangeCheckSiteID_Duplication(e.target.value);
        // }

        // //this.onChangeCheckSiteID_Duplication(e.target.value);

    }

    handleKeyLocation(e) {
        this.setState({ keyLocation: e.target.value });
    }

    handleSOwnerName(e) {
        this.setState({ sOwnerName: e.target.value });
    }

    handleSOwnerAddress(e) {
        this.setState({ sOwnerAddress: e.target.value });
    }

    handlesiteTopologyTerrainMapping(e) {
        //console.log(e.target.value)
        this.setState({ siteTopologyTerrainMapping: e.target.value });
    }
    handlevehicleType(e) {
        //console.log(e.target.value)
        this.setState({ vehicleType: e.target.value });
    }
    handlelandAreainPerch(e) {
        //console.log(e.target.value)
        this.setState({ landAreainPerch: e });
    }
    handlePISvendor(e) {
        //console.log(e.target.value)
        this.setState({ pisVendor: e.target.value });
    }
    handletimeRestrictedReason(e) {
        //console.log(e.target.value)
        this.setState({ timeRestrictedReason: e.target.value });
    }
    handleactivePassive(e) {
        //console.log(e.target.value)
        this.setState({ activePassive: e.target.value });
    }
    handleAccessPermissionProvidingParty(e) {
        //console.log(e.target.value)
        this.setState({ accessPermissionProvidingParty: e.target.value });
    }

    handleYearoftheJFS(e) {
        //console.log(e)
        this.setState({ yearoftheJFS: e });
    }
    handleManualDistanceMeter(e) {
        //console.log(e)
        this.setState({ manualDistanceMeter: e });
    }
    handleTowerLegsCount(e) {
        //console.log(e)
        this.setState({ towerLegsCount: e });
    }
    handleSiteLongitude(e) {
        //console.log(e)
        this.setState({ siteLongitude: e });
    }
    handleTenants(e) {
        //console.log(e)
        this.setState({ tenants: e });
    }
    handleNoOfPhases(e) {
        //console.log(e)
        this.setState({ noOfPhases: e });
    }
    handlePowerSharedTenants(e) {
        //console.log(e)
        this.setState({ powerSharedTenants: e });
    }
    handleSiteLatitude(e) {
        //console.log(e)
        this.setState({ siteLatitude: e });
    }
    handleDistancefromRegionalOffice(e) {
        //console.log(e)
        this.setState({ distancefromRegionalOffice: e });
    }
    handleRefuelManualDistanc(e) {
        //console.log(e)
        this.setState({ refuelManualDistance: e });
    }
    getDataSiteAccessBaseData = async () => {
        console.log("getSiteAccessBaseData-------------------------------------------------------------------------------------------------------------------------------------")
        var res = await getSiteAccessBaseData();
        console.log("getSiteAccessBaseData");
        console.log(res.data);
        if (res.success) {
            this.setState({
                accessPermitionGroupType: res.data?.accessPermitionGroups,
            })
        }
        console.log(this.state.accessPermitionGroupType)
    };

    getDataAllShelterType = async () => {
        var res = await getAllShelterType();
        //console.log("getAllShelterType");
        //console.log(res.data);
        if (res.success) {
            this.setState({
                allShelterType: res.data,
            })
        }
    };
    getDataAllGenHutType = async () => {
        var res = await getAllGenHutType();
        console.log("getAllGenHutType");
        console.log(res.data);
        if (res.success) {
            this.setState({
                GenHutType: res.data,
            })
        }
    };
    getDataAllTowerType = async () => {
        var res = await getAllTowerType();
        //console.log("getAllTowerType");
        //console.log(res.data);
        if (res.success) {
            this.setState({
                allTowerType: res.data,
            })
        }
    };
    getDataAllCoolingMethods = async () => {
        var res = await getAllCoolingMethods();
        //console.log("getAllCoolingMethods");
        //console.log(res.data);
        if (res.success) {
            this.setState({
                allCoolingMethods: res.data,
            })
        }
    };
    getDataAllTowerCategory = async () => {
        var res = await getAllTowerCategory();
        //console.log("getAllTowerCategory");
        //console.log(res.data);
        if (res.success) {
            this.setState({
                allTowerCategory: res.data,
            })
        }
    };
    getDataAllEntity = async () => {
        var res = await getAllEntity();
        //console.log("getAllEntity");
        //console.log(res.data);
        if (res.success) {
            this.setState({
                allSiteEntity: res.data,
            })
        }
    };
    getDataAllSiteOwner = async () => {
        var res = await getAllSiteOwner();
        //console.log("getAllSiteOwner");
        //console.log(res.data);
        if (res.success) {
            this.setState({
                allSiteOwner: res.data,
            })
        }
    };

    getDataAllSiteCategory = async () => {
        var res = await getAllSiteCategory();
        //console.log("getAllSiteCategory");
        //console.log(res.data);
        if (res.success) {
            this.setState({
                allSiteCategory: res.data,
            })
        }
    };
    getDataAllEnergyCategory = async () => {
        var res = await getAllEnergyCategory();
        //console.log("getAllEnergyCategory");
        //console.log(res.data);
        if (res.success) {
            this.setState({
                allEnergyCategory: res.data,
            })
        }
    };

    getDataAllDistrict = async () => {
        var res = await getAllDistrict();
        //console.log("getAllDistrict");
        //console.log(res.data);
        if (res.success) {
            this.setState({
                allDistrict: res.data,
            })
        }
    };
    getDataAllSiteType = async () => {
        var res = await getAllSiteType();
        console.log("getAllSiteType");
        console.log(res.data);
        if (res.success) {
            this.setState({
                allSiteType: res.data,
            })
        }
    };
    onChangeSiteOnAirDate = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);
        this.setState({
            siteOnAirDate: dateString,
        });
    };
    onChangeEnergyCategory = (value) => {
        //console.log("onChangeEnergyCategory");
        //console.log(value);
        this.setState({
            selectedEnergyCategory: value,
            energyCategory: {
                "id": value
            },

        })
    };
    onChangeSiteCategory = (value) => {
        //console.log("onChangeSiteCategory");
        //console.log(value);
        this.setState({
            selectedSiteCategoryId: value,
            siteCategory: {
                "id": value
            },

        })
    };
    onChangeDistrict = (value) => {
        //console.log("onChangeDistrict");
        //console.log(value);
        this.setState({
            selectedDistrictId: value,
            district: {
                "id": value
            },

        })
    };
    onChangeDepot = (value) => {
        console.log("onChangeSiteType");
        console.log(value);
        this.setState({
            selectedDepotTypeId: value,
            depotType: {
                "id": value
            },

        })
    };
    onChangePGPossibility = (value) => {
        //console.log("onChangePGPossibility");
        //console.log(value);
        this.setState({
            pgPossibility: value,


        })
    };
    onChangeManualTransport = (value) => {
        //console.log("onChangeManualTransport");
        //console.log(value);
        this.setState({
            manualTransport: value,


        })
    };
    onChangeInDoorOutDoor = (value) => {
        //console.log("onChangeManualTransport");
        //console.log(value);
        this.setState({
            indoorOutdoorid: value,
            indoorOutdoorType: {
                "id": value
            },


        })
    };
    onportablegenConnectingSetupAvailability = (value) => {
        //console.log("onChangeisSiteKeyRequired");
        //console.log(value);
        this.setState({
            portablegenConnectingSetupAvailability: parseInt(value),


        })
    };
    onChangeisSiteKeyRequired = (value) => {
        //console.log("onChangeisSiteKeyRequired");
        //console.log(value);
        this.setState({
            isSiteKeyRequired: parseInt(value),


        })
    };
    onChangeisAccessPermissionRequired = (value) => {
        //console.log("onChangeisAccessPermissionRequired");
        //console.log(value);
        this.setState({
            isAccessPermissionRequired: parseInt(value),


        })
    };
    onChangeisSpaceAvailableNewGen = (value) => {
        //console.log("onChangeisSpaceAvailableNewGen");
        //console.log(value);
        this.setState({
            isSpaceAvailableNewGen: value,


        })
    };
    onChangeisidlegeneratorhutavaialability = (value) => {
        //console.log("onChangeisidlegeneratorhutavaialability");
        //console.log(value);
        this.setState({
            isIdleGeneratorHutAvaialable: value,


        })
    };
    onChangeisStandbyGeneratorAvailable = (value) => {
        //console.log("onChangeisStandbyGeneratorAvailable");
        //console.log(value);
        this.setState({
            isStandbyGeneratorAvailable: parseInt(value),


        })
    };
    onChangeaccessPermitionGroup = (value) => {
        //console.log("onChangeaccessPermitionGroup");
        //console.log(value);
        this.setState({
            accessPermitionGroupId: value,
            accessPermitionGroup: {
                "id": value
            },

        })
    };
    onChangeShelterType = (value) => {
        //console.log("onChangeShelterType");
        //console.log(value);
        this.setState({
            shelterTypeId: value,
            shelterType: {
                "id": value
            },

        })
    };
    onChangeCoolingMethods = (value) => {
        //console.log("onChangeCoolingMethods");
        //console.log(value);
        this.setState({
            coolingMethodsTypeId: value,
            coolingMethods: {
                "id": value
            },

        })
    };
    onChangeGeneratorHut1Typ = (value) => {
        //console.log("onChangeTowerType");
        //console.log(value);
        this.setState({
            selectedGeneratorHut1Typ: value,
            generatorHut1Typ: {
                "id": value
            },

        })
    };
    onChangeGeneratorHut2Typ = (value) => {
        //console.log("onChangeTowerType");
        //console.log(value);
        this.setState({
            selectedGeneratorHut2Typ: value,
            generatorHut2Typ: {
                "id": value
            },

        })
    };
    onChangeTowerType = (value) => {
        //console.log("onChangeTowerType");
        //console.log(value);
        this.setState({
            selectedTowerTypeId: value,
            towerType: {
                "id": value
            },

        })
    };
    onChangeTowerCategory = (value) => {
        //console.log("onChangeTowerCategory");
        //console.log(value);
        this.setState({
            selectedtowerCategoryId: value,
            towerCategory: {
                "id": value
            },

        })
    };
    onChangeSiteEntity = (value) => {
        //console.log("onChangeSiteEntity");
        //console.log(value);
        this.setState({
            selectedSiteEntityId: value,
            siteEntity: {
                "id": value
            },

        })
    };
    onChangeSiteOwner = (value) => {
        //console.log("onChangeSiteOwner");
        //console.log(value);
        this.setState({
            selectedSiteOwnerId: value,
            siteOwner: {
                "id": value
            },

        })
    };
    onChangeSiteType = (value) => {
        console.log("onChangeSiteType");
        console.log(value);
        this.setState({
            selectedSiteTypeId: value,
            siteType: {
                "id": value
            },
        })
    };
    onChangeClearFields = () => {
        this.setState({
             siteIDUniquensess: false,
             siteId: null,
             }
        )
    }

    AllDataSubmit = async () => {
        if (this.validator.allValid()) {
            var dataSet;
            this.setState({
                submitButtonDisable: true
            })

            dataSet = {
                "depot": this.state.depotType,
                "user": { "id": localStorage.getItem("userId") },
                "district": this.state.district,
                "energyCategory": this.state.energyCategory,
                "siteType": this.state.siteType,
                "siteCategory": this.state.siteCategory,
                "coolingMethods": {
                    "id": 1
                },
                "availableGeneratorHut1Type": this.state.generatorHut1Typ,
                "availableGeneratorHut2Type": this.state.generatorHut2Typ,
                "siteOwner": this.state.siteOwner,
                "shelterType": this.state.shelterType,
                "towerType": this.state.towerType,
                "towerCategory": this.state.towerCategory,
                "entity": this.state.siteEntity,
                "siteId": this.state.siteId,
                "siteName": this.state.siteName,
                "status": this.state.siteStatus,
                "towerLegsCount": this.state.towerLegsCount,
                "towerHeight": this.state.towerHeight,
                "siteElevation": this.state.siteElevation,
                "indoorOutdoor": this.state.indoorOutdoorType,
                "ambientTemperature": this.state.ambientTemperature,
                "tenants": this.state.tenants,
                "powerSharedTenants": this.state.powerSharedTenants,
                "dimension": this.state.dimension,
                "freeLandAvailable": this.state.freeLandAvailable,
                "shelterSize": this.state.shelterSize,
                "nonShadingSpaceAvailabilityOnshelter": this.state.nonShadingSpaceAvailabilityOnshelter,
                "nonShadingSpaceAvailabilityGround": this.state.nonShadingSpaceAvailabilityGround,
                "dependentCellCount": this.state.dependentCellCount,
                //"manualDistance": "test",
                //"manualDelivery": "test",
                "travelTimeFromDepot": this.state.travelTimeFromDepot,
                "workingDaysAccessibleHours": this.state.workingDaysAccessibleHours,
                "weekendsOrHolidaysAccessibleHours": this.state.weekendsOrHolidaysAccessibleHours,
                "yearOfTheJfs": this.state.yearoftheJFS,
                "isIdleGeneratorHutAvaialable": this.state.isIdleGeneratorHutAvaialable,
                "isSpaceAvailableNewGen": this.state.isSpaceAvailableNewGen,
                "siteOnAirDate": this.state.siteOnAirDate,
                //"pgMannualDistance": "test",
                "isStandbyGeneratorAvailable": this.state.isStandbyGeneratorAvailable,
                "noOfPhases": this.state.noOfPhases,
                "manualTransport": this.state.manualTransport,
                "pgPossibility": this.state.pgPossibility,
                "towerOwnerCategory": this.state.towerOwnerCategory,
                "siteAddressList": [{ "longitude": this.state.siteLongitude, "latitude": this.state.siteLatitude, "address": this.state.siteAddress }],
                "manualDistanceMeter": this.state.manualDistanceMeter,
                "manualDistance": this.state.manualDistanceMeter,
                "refuelManualDistance": this.state.refuelManualDistance,
                "regionToSiteDistance": this.state.distancefromRegionalOffice,
                "accessPermissionProvidingParty": this.state.accessPermissionProvidingParty,
                "activePassive": this.state.activePassive,
                "siteTopologyTerrainMapping": this.state.siteTopologyTerrainMapping,
                "timeRestrictedReason": this.state.timeRestrictedReason,
                "pisVendor": this.state.pisVendor,
                "landAreainPerch": this.state.landAreainPerch,

                "vehicleType": this.state.vehicleType,
                "accessPermissionRequired": this.state.isAccessPermissionRequired,
                "siteKeyRequired": this.state.isSiteKeyRequired,
                "portablegenConnectingSetupAvailability": this.state.portablegenConnectingSetupAvailability,
                "accessPermitionGroup": this.state.accessPermitionGroup,

                "keyLocation" : this.state.keyLocation,
                "siteOwnerNameForAccess" : this.state.sOwnerName,
                "siteOwnerAddressForAccess" : this.state.sOwnerAddress,

            }
            console.log(dataSet);
            var res = await createSite(dataSet);
            console.log(res)
            localStorage.removeItem("siteInfo");
            if (res.success) {
                const { history } = this.props;
                history.push("/site");
            }


        } else {
            console.log(this.validator.fields);

            if (this.validator.fields.Tower_Category == false) {
                this.setState({ current: 3 })

            }
            if (this.validator.fields.Tower_Type == false) {
                this.setState({ current: 3 })

            }
            if (this.validator.fields.Tower_Owner_Category == false) {
                this.setState({ current: 3 })

            }
            if (this.validator.fields.Site_Owner == false) {
                this.setState({ current: 2 })

            }
            if (this.validator.fields.Site_Entity == false) {
                this.setState({ current: 2 })

            }
            if (this.validator.fields.Site_Type == false) {
                this.setState({ current: 2 })

            }
            if (this.validator.fields.Standby_Generator_Available == false) {
                this.setState({ current: 1 })

            }
            if (this.validator.fields.PG_Possibility == false) {
                this.setState({ current: 1 })

            }
            if (this.validator.fields.No_Of_Phases == false) {
                this.setState({ current: 1 })

            }
            if (this.validator.fields.Shelter_Type == false) {
                this.setState({ current: 1 })

            }
            if (this.validator.fields.Access_Permission_Group == false) {
                this.setState({ current: 1 })

            }
            if (this.validator.fields.site_name == false) {
                this.setState({ current: 0 })

            }
            if (this.validator.fields.site_id == false) {
                this.setState({ current: 0 })

            }
            if (this.validator.fields.depot == false) {
                this.setState({ current: 0 })

            }
            if (this.validator.fields.district == false) {
                this.setState({ current: 0 })

            }
            if (this.validator.fields.energy_category == false) {
                this.setState({ current: 0 })

            }
            if (this.validator.fields.Site_Category == false) {
                this.setState({ current: 0 })

            }
            if (this.validator.fields.Available_Generator_Hut_1_Type == false) {
                this.setState({ current: 6 })

            }
            if (this.validator.fields.Available_Generator_Hut_2_Type == false) {
                this.setState({ current: 6 })

            }

            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
        //console.log("All danta sub");
    }

    // onChangeCheckSiteID_Duplication = async (value) => {
    //     console.log(value);
    //     var data = new FormData();
    //     data.append('site_id', value);
    //     const response = await checkSiteIDDuplicateCheck(data);
    //     console.log(response);

    //     if(response.data != null){
    //         const validation = response.data.msg;
    //         console.log(validation)
    //     }
    // }

    onChangeCheckSiteID_Duplication = async (value) => {

        if (value == null || value == "") {
            this.setState({ siteIDUniquensess: false });
            return;
        }

        var data = new FormData();
        data.append('site_id', value);
        try {
            const response = await checkSiteIDDuplicateCheck(data);
            console.log(response);
            if (response.success && response.data.includes('msg :true')) {
                this.setState({ siteIDUniquensess: true });
            } else {

                notification.error({
                    message: 'Site ID already exists',
                    description: 'Please enter a unique Site ID',
                    placement: 'topRight',
                    duration: 3,
                });

                this.setState({ siteIDUniquensess: false });
            }
        } catch (error) {
            console.error('Error during API call:', error);
        }
    }

    render() {
        const steps = [
            {
                title: 'First',
                content: 'First-content',
            },
            {
                title: 'Second',
                content: 'Second-content',
            },
            {
                title: 'Last',
                content: 'Last-content',
            },
            {
                title: 'Last',
                content: 'Last-content',
            },
            {
                title: 'Last',
                content: 'Last-content',
            },
            {
                title: 'Last',
                content: 'Last-content',
            },
            {
                title: 'Last',
                content: 'Last-content',
            },
        ];
        const next = () => {
            const current = this.state.current + 1;
            this.setState({ current });

        };
        const prev = () => {
            const current = this.state.current - 1;
            this.setState({ current });
        };
        const items = steps.map((item) => ({
            key: item.title,
            title: item.title,
        }));
        const description = 'This is a description.';
        const { Option } = Select;
        const Step = Steps.Step;
        return (
            <div class="container-fluid" >
                <div class="row">
                    <div class="col-sm-12">
                        <div class="page-title-box">
                            <div class="row">
                                <div class="col">
                                    <h4 class="page-title">New Site</h4>
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Admin</a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Site Manage</a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Site Create</a></li>

                                    </ol>
                                </div>
                                <div class="col-auto align-self-center">
                                    <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <Steps size="small" current={this.state.current}>
                                    <Step title="Primary Details -1" description="" />
                                    <Step title="Primary Details -2" description="" />
                                    <Step title="Secondary Details" description="" />
                                    <Step title="Tower Details" description="" />
                                    <Step title="Distance Details" description="" />
                                    <Step title="Site Address" description="" />
                                    <Step title="Other Details" description="" />
                                </Steps>

                            </div>
                            <div class="card-body">
                                <div className='row'>

                                    {this.state.current == 0 ? (
                                        <div className='row'>
                                            <div className='row'>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Site Name<x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="site_name" tooltip="This is a required field">
                                                        <Input id="site_name" onChange={this.handleSiteName} value={this.state.siteName} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p>
                                                    </Form.Item>

                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Site ID<x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    {/* <Form.Item required name="site_id" tooltip="This is a required field">
                                                        <Input id="site_id" onChange={
                                                            this.handleSiteId
                                                        } value={this.state.siteId} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('site_id', this.state.siteId, 'required')}</p>
                                                    </Form.Item> */}
                                                    <Form.Item
                                                        required
                                                        name="site_id"
                                                        tooltip="This is a required field"
                                                    >
                                                        <Input
                                                            id="site_id"
                                                            onChange={this.handleSiteId}
                                                            value={this.state.siteId}
                                                            addonAfter={
                                                                this.state.siteId && (
                                                                    <Button
                                                                        type="link"
                                                                        icon={<i className="far fa-times-circle" />}
                                                                        onClick={
                                                                        this.onChangeClearFields
                                                                        }
                                                                    />
                                                                )
                                                            }
                                                        />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('site_id', this.state.siteId, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Depot<x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeDepot}
                                                        defaultValue={this.state.selectedDepotTypeId}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.depot_list.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}

                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('depot', this.state.selectedDepotTypeId, 'required')}</p>

                                                </div>

                                            </div>
                                            <div className='row'>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        District<x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeDistrict}
                                                        defaultValue={this.state.selectedDistrictId}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.allDistrict.map((item, index) => {
                                                            return <Option value={item.id}>{item.nameEn}</Option>;
                                                        })}
                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('district', this.state.selectedDistrictId, 'required')}</p>

                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Energy Category<x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeEnergyCategory}
                                                        defaultValue={this.state.selectedEnergyCategory}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.allEnergyCategory.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}

                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('energy_category', this.state.selectedEnergyCategory, 'required')}</p>

                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Site Category<x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeSiteCategory}
                                                        defaultValue={this.state.selectedSiteCategoryId}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.allSiteCategory.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}
                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Site_Category', this.state.selectedSiteCategoryId, 'required')}</p>
                                                </div>
                                            </div>
                                        </div>

                                    ) : ('')}
                                    {this.state.current == 1 ? (
                                        <div className='row'>
                                            <div className='row'>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Standby Generator Available<x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeisStandbyGeneratorAvailable}
                                                        defaultValue={this.state.isStandbyGeneratorAvailable}
                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        <Option value='1'>Yes</Option>
                                                        <Option value='0'>No</Option>

                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Standby_Generator_Available', this.state.isStandbyGeneratorAvailable, 'required')}</p>

                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        PG Possibility<x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangePGPossibility}
                                                        defaultValue={this.state.pgPossibility}
                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        <Option value='Yes'>Yes</Option>
                                                        <Option value='No'>No</Option>

                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('PG_Possibility', this.state.pgPossibility, 'required')}</p>

                                                </div>

                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        No Of Phases<x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="siteOnAirDate" tooltip="This is a required field">
                                                        <InputNumber style={{ width: '100%' }} id="siteOnAirDate" onChange={this.handleNoOfPhases} value={this.state.noOfPhases} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('No_Of_Phases', this.state.noOfPhases, 'required')}</p>
                                                    </Form.Item>

                                                </div>

                                            </div>
                                            <div className='row'>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Shelter Type <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeShelterType}
                                                        defaultValue={this.state.shelterTypeId}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.allShelterType.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}
                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Shelter_Type', this.state.shelterTypeId, 'required')}</p>

                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Access Permission Group <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeaccessPermitionGroup}
                                                        defaultValue={this.state.accessPermitionGroupId}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.accessPermitionGroupType.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}
                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Access_Permission_Group ', this.state.accessPermitionGroupId, 'required')}</p>

                                                </div>
                                            </div>
                                        </div>
                                    ) : ('')}
                                    {this.state.current == 2 ? (
                                        <div className='row'>
                                            <div className='row'>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Site Owner<x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeSiteOwner}
                                                        defaultValue={this.state.selectedSiteOwnerId}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.allSiteOwner.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}

                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Site_Owner', this.state.selectedSiteOwnerId, 'required')}</p>

                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Site Entity<x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeSiteEntity}
                                                        defaultValue={this.state.selectedSiteEntityId}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.allSiteEntity.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}

                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Site_Entity', this.state.selectedSiteEntityId, 'required')}</p>

                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Site Status
                                                    </label>
                                                    <Form.Item name="status">
                                                        <Input id="status" onChange={this.handleStatus} value={this.state.siteStatus} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.siteStatus, 'required')}</p>

                                                    </Form.Item>

                                                </div>



                                            </div>
                                            <div className='row'>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Site Type<x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeSiteType}
                                                        defaultValue={this.state.selectedSiteTypeId}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.allSiteType.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}
                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Site_Type', this.state.selectedSiteTypeId, 'required')}</p>

                                                </div>

                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Site On Air Date
                                                    </label>
                                                    <DatePicker
                                                        defaultValue={this.state.siteOnAirDate != '' ? (moment(this.state.siteOnAirDate)) : ('')}
                                                        style={{ width: "100%" }}
                                                        onChange={this.onChangeSiteOnAirDate}
                                                    />
                                                    {/* <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.siteOnAirDate, 'required')}</p> */}



                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Site Elevation
                                                    </label>
                                                    <Form.Item name="site_elevation">
                                                        <Input id="site_elevation" onChange={this.handleSiteElevation} value={this.state.siteElevation} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.siteElevation, 'required')}</p>

                                                    </Form.Item>

                                                </div>

                                            </div>


                                        </div>) : ('')}
                                    {this.state.current == 3 ? (
                                        <div className='row'>
                                            <div className='row'>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Tower Category<x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeTowerCategory}
                                                        defaultValue={this.state.selectedtowerCategoryId}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.allTowerCategory.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}

                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Tower_Category', this.state.selectedtowerCategoryId, 'required')}</p>

                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Tower Type <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeTowerType}
                                                        defaultValue={this.state.selectedTowerTypeId}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.allTowerType.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}

                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Tower_Type', this.state.selectedTowerTypeId, 'required')}</p>


                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Tower Owner Category<x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="towerOwnerCategory" tooltip="This is a required field">
                                                        <Input id="towerOwnerCategory" onChange={this.handleTowerOwnerCategory} value={this.state.towerOwnerCategory} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Tower_Owner_Category', this.state.towerOwnerCategory, 'required')}</p>

                                                    </Form.Item>

                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Tower Height
                                                    </label>
                                                    <Form.Item required name="tower_height" tooltip="This is a required field">
                                                        <Input id="tower_height" onChange={this.handleTowerHeight} value={this.state.towerHeight} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.towerHeight, 'required')}</p>

                                                    </Form.Item>

                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Tower Legs Count
                                                    </label>
                                                    <Form.Item required name="tower_legs_count" tooltip="This is a required field">
                                                        <InputNumber style={{ width: '100%' }} id="tower_legs_count" onChange={this.handleTowerLegsCount} value={this.state.towerLegsCount} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.towerLegsCount, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                            </div>


                                        </div>) : ('')}
                                    {this.state.current == 4 ? (
                                        <div className='row'>
                                            <div className='row'>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        PG Manual Distance
                                                    </label>
                                                    <Form.Item required name="manualDistanceMeter" tooltip="This is a required field">
                                                        <InputNumber style={{ width: '100%' }} id="manualDistanceMeter" onChange={this.handleManualDistanceMeter} value={this.state.manualDistanceMeter} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.manualDistanceMeter, 'required')}</p>

                                                    </Form.Item>
                                                </div>

                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Refuel Manual Distance
                                                    </label>
                                                    <Form.Item required name="RefuelManualDistance" tooltip="This is a required field">
                                                        <InputNumber style={{ width: '100%' }} id="refuelManualDistance" onChange={this.handleRefuelManualDistanc} value={this.state.refuelManualDistance} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.refuelManualDistance, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Distance from regional office
                                                    </label>
                                                    <Form.Item required name="regionToSiteDistance" tooltip="This is a required field">
                                                        <InputNumber style={{ width: '100%' }} id="regionToSiteDistance" onChange={this.handleDistancefromRegionalOffice} value={this.state.distancefromRegionalOffice} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.distancefromRegionalOffice, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Manual Transport
                                                    </label>
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeManualTransport}
                                                        defaultValue={this.state.manualTransport}
                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        <Option value='Yes'>Yes</Option>
                                                        <Option value='No'>No</Option>

                                                    </Select>
                                                </div>
                                                <div className="col-md-4">
                                                </div>
                                                <div className="col-md-4">
                                                </div>
                                            </div>

                                        </div>

                                    ) : ('')}
                                    {this.state.current == 5 ?
                                        (<div className='row'>

                                            <div className='row'>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Longitude
                                                    </label>
                                                    <Form.Item required name="siteLongitude" tooltip="This is a required field">
                                                        <InputNumber style={{ width: '100%' }} id="siteLongitude" onChange={this.handleSiteLongitude} value={this.state.siteLongitude} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.siteLongitude, 'required')}</p>

                                                    </Form.Item>

                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Latitude
                                                    </label>
                                                    <Form.Item required name="siteLatitude" tooltip="This is a required field">
                                                        <InputNumber style={{ width: '100%' }} id="siteLatitude" onChange={this.handleSiteLatitude} value={this.state.siteLatitude} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.siteLatitude, 'required')}</p>

                                                    </Form.Item>

                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Address
                                                    </label>
                                                    <Form.Item required name="siteAddress" tooltip="This is a required field">
                                                        <Input id="siteAddress" onChange={this.handleSiteAddress} value={this.state.siteAddress} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.siteAddress, 'required')}</p>

                                                    </Form.Item>
                                                </div>

                                            </div>

                                        </div>) : ('')}
                                    {this.state.current == 6 ? (
                                        <div className='row'>
                                            <div className='row'>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Available Generator Hut 1 Type <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeGeneratorHut1Typ}
                                                        defaultValue={this.state.selectedGeneratorHut1Typ}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.GenHutType.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}

                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message(' Available_Generator_Hut_1_Type', this.state.selectedGeneratorHut1Typ, 'required')}</p>

                                                    <br></br>
                                                    <br></br>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Available Generator Hut 2 Type <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeGeneratorHut2Typ}
                                                        defaultValue={this.state.selectedGeneratorHut2Typ}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.GenHutType.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}

                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Available_Generator_Hut_2_Type', this.state.selectedGeneratorHut2Typ, 'required')}</p>

                                                </div>

                                            </div>

                                            <div className='row'>

                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        No. of tenants
                                                    </label>
                                                    <Form.Item required name="tenants" tooltip="This is a required field">
                                                        {/* <InputNumber style={{ width: '100%' }} id="tenants" onChange={this.handleTenants} value={this.state.tenants} /> */}
                                                        <Select
                                                            style={{ width: '100%' }} 
                                                            onChange={(e) => {
                                                                this.handleTenants(e);
                                                            }}>
                                                            {this.state.dropdownDataSet.noOftenants?.map((x) => {
                                                                return <Option value={x}>{x}</Option>
                                                            })}
                                                        </Select>
                                                        <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.tenants, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Power Shared Tenants
                                                    </label>
                                                    <Form.Item required name="powerSharedTenants" tooltip="This is a required field">
                                                        <InputNumber style={{ width: '100%' }} id="powerSharedTenants" onChange={this.handlePowerSharedTenants} value={this.state.powerSharedTenants} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.powerSharedTenants, 'required')}</p>

                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Indoor Outdoor
                                                    </label>
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeInDoorOutDoor}
                                                        //   defaultValue={this.state.manualTransport}
                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        <Option value='1'>Indoor</Option>
                                                        <Option value='2'>Outdoor</Option>
                                                        <Option value='3'>N/A</Option>
                                                        <Option value='4'>Lamp Pole</Option>

                                                    </Select>
                                                </div>

                                            </div>
                                            <div className='row'>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Ambient Temperature
                                                    </label>
                                                    <Form.Item required name="ambientTemperature" tooltip="This is a required field">
                                                        <Input id="ambientTemperature" onChange={this.handleAmbientTemperature} value={this.state.ambientTemperature} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.ambientTemperature, 'required')}</p>

                                                    </Form.Item>

                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Dimension
                                                    </label>
                                                    <Form.Item required name="dimension" tooltip="This is a required field">
                                                        <Input id="dimension" onChange={this.handleDimension} value={this.state.dimension} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.dimension, 'required')}</p>

                                                    </Form.Item>


                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        FreeLand Available
                                                    </label>
                                                    <Form.Item required name="freeLandAvailable" tooltip="This is a required field">
                                                        <Input id="freeLandAvailable" onChange={this.handleFreeLandAvailable} value={this.state.freeLandAvailable} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.freeLandAvailable, 'required')}</p>

                                                    </Form.Item>

                                                </div>
                                            </div>
                                            <div className='row'>



                                                <div className="col-md-4">

                                                    <label className="form-label" for="depot">
                                                        Non Shading Space Availability Onshelter
                                                    </label>
                                                    <Form.Item required name="nonShadingSpaceAvailabilityOnshelter" tooltip="This is a required field">
                                                        <Input id="nonShadingSpaceAvailabilityOnshelter" onChange={this.handleNonShadingSpaceAvailabilityOnshelter} value={this.state.nonShadingSpaceAvailabilityOnshelter} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.nonShadingSpaceAvailabilityOnshelter, 'required')}</p>

                                                    </Form.Item>




                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Non Shading Space Availability Ground
                                                    </label>
                                                    <Form.Item required name="nonShadingSpaceAvailabilityGround" tooltip="This is a required field" >
                                                        <Input id="freeLanonShadingSpaceAvailabilityGroundndAvailable" onChange={this.handleNonShadingSpaceAvailabilityGround} value={this.state.nonShadingSpaceAvailabilityGround} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.nonShadingSpaceAvailabilityGround, 'required')}</p>

                                                    </Form.Item>

                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Dependent CellCount
                                                    </label>
                                                    <Form.Item required name="dependentCellCount" tooltip="This is a required field">
                                                        <InputNumber style={{ width: '100%' }} id="dependentCellCount" onChange={this.handleDependentCellCount} value={this.state.dependentCellCount} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.dependentCellCount, 'required')}</p>

                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='row'>


                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Travel Time From Depot
                                                    </label>
                                                    <Form.Item required name="travelTimeFromDepot" tooltip="This is a required field">
                                                        <Input id="travelTimeFromDepot" onChange={this.handleTravelTimeFromDepot} value={this.state.travelTimeFromDepot} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.travelTimeFromDepot, 'required')}</p>
                                                    </Form.Item>



                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Idle Generator Hut Avaialability
                                                    </label>
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeisidlegeneratorhutavaialability}
                                                        defaultValue={this.state.isIdleGeneratorHutAvaialable}
                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        <Option value='1'>Yes</Option>
                                                        <Option value='0'>No</Option>

                                                    </Select>


                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Space Available New Gen
                                                    </label>
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeisSpaceAvailableNewGen}
                                                        defaultValue={this.state.isSpaceAvailableNewGen}
                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        <Option value='1'>Yes</Option>
                                                        <Option value='0'>No</Option>

                                                    </Select>


                                                </div>


                                            </div>
                                            <div className='row'>


                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Working Days Accessible Hours
                                                    </label>
                                                    <Form.Item required name="workingDaysAccessibleHours" tooltip="This is a required field">
                                                        <Input id="workingDaysAccessibleHours" onChange={this.handleWorkingDaysAccessibleHours} value={this.state.workingDaysAccessibleHours} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.workingDaysAccessibleHours, 'required')}</p>

                                                    </Form.Item>

                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Weekends Or Holidays Accessible Hours
                                                    </label>
                                                    <Form.Item required name="weekendsOrHolidaysAccessibleHours" tooltip="This is a required field">
                                                        <Input id="weekendsOrHolidaysAccessibleHours" onChange={this.handleWeekendsOrHolidaysAccessibleHours} value={this.state.weekendsOrHolidaysAccessibleHours} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.weekendsOrHolidaysAccessibleHours, 'required')}</p>

                                                    </Form.Item>

                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Year of the JFS
                                                    </label>
                                                    <Form.Item required name="yearoftheJFS" tooltip="This is a required field">
                                                        <InputNumber style={{ width: '100%' }} id="yearoftheJFS" onChange={this.handleYearoftheJFS} value={this.state.yearoftheJFS} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.yearoftheJFS, 'required')}</p>

                                                    </Form.Item>
                                                </div>

                                            </div>
                                            <div className='row'>


                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Access Permission Providing Party
                                                    </label>
                                                    <Form.Item required name="accessPermissionProvidingParty" tooltip="This is a required field">
                                                        <Input id="accessPermissionProvidingParty" onChange={this.handleAccessPermissionProvidingParty} value={this.state.accessPermissionProvidingParty} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.accessPermissionProvidingParty, 'required')}</p>

                                                    </Form.Item>

                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Active Passive
                                                    </label>
                                                    <Form.Item required name="activePassive" tooltip="This is a required field">
                                                        <Input id="activePassive" onChange={this.handleactivePassive} value={this.state.activePassive} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.activePassive, 'required')}</p>

                                                    </Form.Item>

                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Site Topology Terrain Mapping
                                                    </label>
                                                    <Form.Item required name="siteTopologyTerrainMapping" tooltip="This is a required field">
                                                        <Input style={{ width: '100%' }} id="siteTopologyTerrainMapping" onChange={this.handlesiteTopologyTerrainMapping} value={this.state.siteTopologyTerrainMapping} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.siteTopologyTerrainMapping, 'required')}</p>

                                                    </Form.Item>
                                                </div>

                                            </div>
                                            <div className='row'>


                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Vehicle Type
                                                    </label>
                                                    <Form.Item required name="vehicleType" tooltip="This is a required field">
                                                        <Input id="vehicleType" onChange={this.handlevehicleType} value={this.state.vehicleType} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.vehicleType, 'required')}</p>

                                                    </Form.Item>

                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Time Restricted Reason
                                                    </label>
                                                    <Form.Item required name="timeRestrictedReason" tooltip="This is a required field">
                                                        <Input id="timeRestrictedReason" onChange={this.handletimeRestrictedReason} value={this.state.timeRestrictedReason} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator2.message('depot', this.state.timeRestrictedReason, 'required')}</p>

                                                    </Form.Item>

                                                </div>

                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Access Permission Required
                                                    </label>
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeisAccessPermissionRequired}
                                                        defaultValue={this.state.isAccessPermissionRequired}
                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        <Option value='1'>Yes</Option>
                                                        <Option value='0'>No</Option>

                                                    </Select>
                                                </div>



                                            </div>
                                            <div className='row'>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Site Key Required
                                                    </label>

                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeisSiteKeyRequired}
                                                        defaultValue={this.state.isSiteKeyRequired}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        <Option value='1'>Yes</Option>
                                                        <Option value='0'>No</Option>

                                                    </Select>

                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Portable Gen Connecting Setup Availability
                                                    </label>

                                                    <Select
                                                        showSearch
                                                        onChange={this.onportablegenConnectingSetupAvailability}
                                                        defaultValue={this.state.portablegenConnectingSetupAvailability}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        <Option value='1'>Yes</Option>
                                                        <Option value='0'>No</Option>

                                                    </Select>

                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        PIS vendor
                                                    </label>
                                                    <Form.Item required name="pisVendor" tooltip="This is a required field">
                                                        <Input id="pisVendor" onChange={this.handlePISvendor} value={this.state.pisVendor} />
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Land Areain Perch
                                                    </label>
                                                    <Form.Item required name="landAreainPerch" tooltip="This is a required field">
                                                        <InputNumber style={{ width: '100%' }} id="landAreainPerch" onChange={this.handlelandAreainPerch} value={this.state.landAreainPerch} />
                                                    </Form.Item>
                                                </div>

                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Key Location
                                                    </label>
                                                    <Form.Item required name="keyLocation" tooltip="This is a required field">
                                                        <Input style={{ width: '100%' }} 
                                                        id="keyLocation" 
                                                        onChange={this.handleKeyLocation} 
                                                        value={this.state.keyLocation} />
                                                    </Form.Item>
                                                </div>
                                                
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Site Owner Name
                                                    </label>
                                                    <Form.Item required name="sOwnerName" 
                                                    tooltip="This is a required field">
                                                        <Input style={{ width: '100%' }} 
                                                        id="sOwnerName" 
                                                        onChange={this.handleSOwnerName} 
                                                        value={this.state.sOwnerName} />
                                                    </Form.Item>
                                                </div>
                                                
                                                <div className="col-md-4">
                                                    <label className="form-label" for="depot">
                                                        Site Owner Address
                                                    </label>
                                                    <Form.Item required name="sOwnerAddress" 
                                                    tooltip="This is a required field">
                                                        <Input style={{ width: '100%' }} 
                                                        id="sOwnerAddress" 
                                                        onChange={this.handleSOwnerAddress} 
                                                        value={this.state.sOwnerAddress} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    ) : ('')}
                                </div>
                                <br></br>
                                <div className='row'>
                                    <div className=" steps-action">
                                        {this.state.current > 0 && (
                                            <Button
                                                style={{
                                                    margin: '0 8px',
                                                }}
                                                onClick={() => prev()}
                                            >
                                                Previous
                                            </Button>
                                        )}
                                        {this.state.siteIDUniquensess && this.state.current < steps.length - 1 && (
                                            <Button type="primary" onClick={() => next()}>
                                                Next
                                            </Button>
                                        )}
                                        {this.state.current === steps.length - 1 && (
                                            <Button type="primary" onClick={() => this.AllDataSubmit()}>
                                                Submit
                                            </Button>
                                        )}

                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                    <div>


                    </div>
                </div>

            </div>

        );
    }
}
function mapStateToProps(state) {
    //console.log("state", state);
    return {
        organization_count: state.organization.length,
        regions: state.regions,
        depots: state.depots,
        sites_count: state.sites.length,
        vendor_count: state.vendor.length,
        roles_count: state.roles.length,
    };
}

export default withRouter(connect(mapStateToProps, { getDonationCategories, getData })(SiteCreate));