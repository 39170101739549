import React, { Component } from 'react';
import PropTypes from 'prop-types';

class IndoorOutdoor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allDataset: null,
            allKeySet: null,
            tableView: false

        }

    }

    componentWillMount() {

    }

    componentDidMount() {
        this.setState({
            allKeySet: Object.keys(this.props.dataSet),
            allDataset: Object.values(this.props.dataSet)
        }, () => {
            console.log("iopoppopo")
            console.log(this.state.allDataset)
            this.setState({
                tableView: true
            })
        })
    }

    // componentWillReceiveProps(nextProps) {

    // }

    // shouldComponentUpdate(nextProps, nextState) { 
    //     console.log(nextProps);
    //     console.log(nextState);
    // }

    // componentWillUpdate(nextProps, nextState) {

    // }

    // componentDidUpdate(prevProps, prevState) {

    // }

    componentWillUnmount() {

    }

    render() {
        let fullAmout=0;
        return (
            <div>{
                this.state.tableView ? (
                    <table class="table mb-0 textStyles-small" style={{ fontSize: 'smaller' }}>
                        <thead>
                            <tr>
                                <th>Region</th>
                                <th>Indoor</th>
                                <th>Outdoor</th>
                                <th>Lamp Pole</th>
                                <th>Not Applicable</th>
                                <th>Not Assigned</th>
                                <th class="text-end">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.allDataset.map((x) => {

                                return <tr>

                                    <td>{x[0].region}</td>
                                    {(() => {
                                        const arr = [];
                                        let totalValue = 0;
                                        if (x.filter(re => re.indoor_outdoor == "Indoor").length != 0) {
                                            totalValue = totalValue + x.filter(re => re.indoor_outdoor == "Indoor")[0].count
                                            arr.push(
                                                <td className='power-data-dashboard-table-tb' style={{textAlign:"end"}}>{x.filter(re => re.indoor_outdoor == "Indoor")[0].count.toLocaleString()}</td>
                                            );
                                        } else {
                                            arr.push(
                                                <td className='power-data-dashboard-table-tb' style={{textAlign:"end"}}>0</td>
                                            );

                                        }

                                        if (x.filter(re => re.indoor_outdoor == "Outdoor").length != 0) {
                                            totalValue = totalValue + x.filter(re => re.indoor_outdoor == "Outdoor")[0].count
                                            arr.push(
                                                <td className='power-data-dashboard-table-tb' style={{textAlign:"end"}}>{x.filter(re => re.indoor_outdoor == "Outdoor")[0].count.toLocaleString()}</td>
                                            );
                                        } else {
                                            arr.push(
                                                <td className='power-data-dashboard-table-tb' style={{textAlign:"end"}}>0</td>
                                            );
                                        }

                                        // Lamp Pole
                                        if (x.filter(re => re.indoor_outdoor == "Lamp Pole").length != 0) {
                                            totalValue = totalValue + x.filter(re => re.indoor_outdoor == "Lamp Pole")[0].count
                                            arr.push(
                                                <td className='power-data-dashboard-table-tb' style={{textAlign:"end"}}>{x.filter(re => re.indoor_outdoor == "Lamp Pole")[0].count.toLocaleString()}</td>
                                            );
                                        } else {
                                            arr.push(
                                                <td className='power-data-dashboard-table-tb' style={{textAlign:"end"}}>0</td>
                                            );
                                        }

                                        if (x.filter(re => re.indoor_outdoor == "Data Not Available").length != 0) {
                                            totalValue = totalValue + x.filter(re => re.indoor_outdoor == "Data Not Available")[0].count
                                            arr.push(
                                                <td className='power-data-dashboard-table-tb' style={{textAlign:"end"}}>{x.filter(re => re.indoor_outdoor == "Data Not Available")[0].count.toLocaleString()}</td>
                                            );
                                        } else {
                                            arr.push(
                                                <td className='power-data-dashboard-table-tb' style={{textAlign:"end"}}>0</td>
                                            );
                                        }


                                        if (x.filter(re => re.indoor_outdoor == null).length != 0) {
                                            totalValue = totalValue + x.filter(re => re.indoor_outdoor == null)[0].count
                                            arr.push(
                                                <td className='power-data-dashboard-table-tb' style={{textAlign:"end"}}>{x.filter(re => re.indoor_outdoor == null)[0].count.toLocaleString()}</td>
                                            );
                                        } else {
                                            arr.push(
                                                <td className='power-data-dashboard-table-tb' style={{textAlign:"end"}}>0</td>
                                            );

                                        }

                                        arr.push(
                                            <td className='power-data-dashboard-table-tb' style={{textAlign:"end"}}><b>{totalValue.toLocaleString()} </b></td>
                                        );
                                        return arr;
                                    })()}
                                </tr>
                            })}


                            <tr>

                                <td><b>Total</b></td>
                                {(() => {
                                    const arr = [];
                                    let totalValue = 0;
                                    {
                                        this.state.allDataset.map((x) => {
                                            if (x.filter(re => re.indoor_outdoor == "Indoor").length != 0) {
                                                totalValue = totalValue + x.filter(re => re.indoor_outdoor == "Indoor")[0].count
                                            }
                                        })
                                        fullAmout=fullAmout+totalValue;
                                    }
                                    return <td style={{textAlign:"end"}}><b>{totalValue.toLocaleString()}</b></td>
                                })()
                                }


                                {(() => {
                                    const arr = [];
                                    let totalValue = 0;
                                    {
                                        this.state.allDataset.map((x) => {
                                            if (x.filter(re => re.indoor_outdoor == "Outdoor").length != 0) {
                                                totalValue = totalValue + x.filter(re => re.indoor_outdoor == "Outdoor")[0].count
                                            }
                                        })
                                        fullAmout=fullAmout+totalValue;

                                    }
                                    return <td style={{textAlign:"end"}}><b>{totalValue.toLocaleString()}</b></td>

                                })()
                                }

                                {(() => {
                                    const arr = [];
                                    let totalValue = 0;
                                    {
                                        this.state.allDataset.map((x) => {
                                            if (x.filter(re => re.indoor_outdoor == "Lamp Pole").length != 0) {
                                                totalValue = totalValue + x.filter(re => re.indoor_outdoor == "Lamp Pole")[0].count
                                            }
                                        })
                                        fullAmout=fullAmout+totalValue;
                                    }
                                    return <td style={{textAlign:"end"}}><b>{totalValue.toLocaleString()}</b></td>
                                })()
                                }

                                {
                                    (() => {
                                        const arr = [];
                                        let totalValue = 0;
                                        {
                                            this.state.allDataset.map((x) => {
                                                if (x.filter(re => re.indoor_outdoor == "Data Not Available").length != 0) {
                                                    totalValue = totalValue + x.filter(re => re.indoor_outdoor == "Data Not Available")[0].count
                                                }
                                            })
                                            fullAmout=fullAmout+totalValue;
                                        }
                                        return <td style={{textAlign:"end"}}><b>{totalValue.toLocaleString()}</b></td>
                                    })()
                                }
                                
                                {(() => {
                                    const arr = [];
                                    let totalValue = 0;
                                    {
                                        this.state.allDataset.map((x) => {

                                            if (x.filter(re => re.indoor_outdoor ==null).length != 0) {
                                                totalValue = totalValue + x.filter(re => re.indoor_outdoor == null)[0].count

                                            }


                                        })
                                        fullAmout=fullAmout+totalValue;

                                    }
                                    return <td  style={{textAlign:"end"}}><b>{totalValue.toLocaleString()}</b></td>

                                })()
                                }
                                <td style={{textAlign:"end"}}>
                                  <b> {fullAmout.toLocaleString()}</b> 
                                </td>
                            </tr>
                        </tbody>
                    </table>
                ) : ('')}

            </div>
        );
    }
}

IndoorOutdoor.propTypes = {

};

export default IndoorOutdoor;