import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import { getSiteListByPage, filterSiteData } from "../../api/Repos/SiteRepo";
import { Link } from "react-router-dom";
// import Select from 'react-select'
import { Collapse } from "reactstrap";
import { DatePicker, Table, List, Skeleton, Select, Tag, Row, Col, Input, Button, message, Menu, Dropdown, Modal } from "antd";
import { getDonationCategories, getData } from "../../actions/index";
import {
    getAllDepotList,
    getSitesLiteForSiteManage,
    getAllSiteCategories,
    changeDepot
} from "../../api/index";
import '../master-data/style.css';
import { checkServiceType } from "../../actions/service-type";
import {RetweetOutlined, SearchOutlined, EnvironmentOutlined, PlusOutlined, FilterOutlined, CloseCircleOutlined, EditOutlined, DownOutlined, ExclamationCircleOutlined, DeleteFilled, DeleteOutlined, MinusCircleOutlined } from '@ant-design/icons';

const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
];
const { Option } = Select;
class MigrateSites extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            editVisibilty: false,
            site_list: [],
            search_data: [],
            site_name: "",
            site_id: "",
            depotList: [],
            depot_list_new: [],
            isOpen: false,
            loading: true,
            depotId: "",
            setLogin: false,
            AssetManagementPermission: false,
            filterButton: true,
            setSearchValue: "",
            filteredTable: null,
            setValues: null,
            depot_list: [],
            sitwCategory: [],
            filterRegion: null,
            filterDepot: null,
            filterCategory: null,
            fullAllSite: [],
            selectedSites: [],
            targetDepot: null,

            filterTableData: [],
        };
    }

    componentDidMount() {
        this.props.getData();
        this.getAllDepotList();
        console.log(this.props);
        this.setState({
            depot_list: this.props.depots,
        });
        checkServiceType(38).then((data) =>
            this.setState(
                {
                    AssetManagementPermission: data,
                },
                () => {
                    console.log(this.state.AssetManagementPermission);
                }
            )
        );
        if (JSON.parse(localStorage.getItem("serviceTypeSet")) == null) {
            this.setState({
                setLogin: true,
            });
        }
        this.getSitesLiteForSiteManage();
        this.getAllSiteCategories();
    }

    getAllSiteCategories = async () => {
        var res = await getAllSiteCategories();
        console.log("getAllSiteCategories");
        console.log(res);
        if (res.success) {
            this.setState({
                sitwCategory: res.data,
            });
        }
    };
    // error message modal define here
    showErrorMessage = (message) => {
        Modal.error({
            title: 'Error',
            content: <span className='textStyles-small'>{message}</span>,
            onOk() { },
        });
    };


    localStorageSaverFunction = (data) => {
        const numberOfDaysCacheAvailable = 1;
        const currentTime = new Date().getTime();
        const cacheExpiryTime = localStorage.getItem("cacheExpiryTime");
        if (!localStorage.getItem("siteInfo") || currentTime > cacheExpiryTime) {
            localStorage.setItem("siteInfo", JSON.stringify(data));
            localStorage.setItem("cachingTime", currentTime);
            const expiryTime = currentTime + numberOfDaysCacheAvailable * 24 * 60 * 60 * 1000;
            localStorage.setItem("cacheExpiryTime", expiryTime);
        }
    };

    checkLocalStorageCache = () => {
        const currentTime = new Date().getTime();
        const cacheExpiryTime = localStorage.getItem("cacheExpiryTime");
        const siteInfo = JSON.parse(localStorage.getItem("siteInfo"));
        if (siteInfo && Object.keys(siteInfo).length > 0 && currentTime <= cacheExpiryTime) {
            return true;
        }
        return false;
    };

    getSitesLiteForSiteManage = async () => {
        this.setState({
            loading: true,
            site_list: [],
        });


        if (this.checkLocalStorageCache()) {
            var res = {
                success: true,
                data: JSON.parse(localStorage.getItem("siteInfo"))
            };
        } else {
            var res = await getSitesLiteForSiteManage();
            console.log("getSitesLiteForSiteManage");
            console.log(res);
            if (res.success) {
                this.localStorageSaverFunction(res.data);
            }
        }

        if (res.success) {
            if (this.state.setValues == null) {
                this.setState({
                    site_list: res.data,
                    fullAllSite: res.data,
                    loading: false,
                });
            } else {
                this.setState(
                    {
                        site_list: res.data,
                        fullAllSite: res.data,
                        loading: false,
                    },
                    () => {
                        const filtered = this.state.site_list.filter(
                            (user) =>
                                user?.site_name
                                    ?.toLowerCase()
                                    .includes(this.state.setValues.toLowerCase()) ||
                                user?.site_id
                                    ?.toLowerCase()
                                    .includes(this.state.setValues.toLowerCase())
                        );
                        this.setState({
                            filteredTable: filtered,
                        });
                    }
                );
            }
        }
    };


    getSetPageData = (serviceTypeIs, Pageid) => {
        if (
            serviceTypeIs.serviceType.filter(
                (checkuser) => checkuser?.id == Pageid
            )[0] == null
        ) {
            this.setState({
                editVisibilty: false,
            });
        } else {
            this.setState({
                editVisibilty: true,
            });
        }
    };

    getAllDepotList = async () => {
        var res = await getAllDepotList();
        this.setState({
            loadingDepot: true,
            depot_list_new: [],
        });
        console.log(res);
        if (res.success) {
            this.setState(
                {
                    depot_list_new: res.data,
                    loadingDepot: false,
                },
                () => {
                    var _depotList = [];

                    this.state.depot_list_new.map((depot) => {
                        _depotList.push({ value: depot.id, label: depot.name });
                    });

                    this.setState({
                        depotList: _depotList,
                    });
                }
            );
        }
    };

    getAllSites = async () => {
        var res = await getSiteListByPage(this.state.pageNo);
        this.setState({
            loading: true,
            site_list: [],
            fullAllSite: [],
        });
        if (res.success) {
            this.setState({
                site_list: res.data,
                loading: false,
                fullAllSite: res.data,
            });
        }
    };

    previousBtnClick = () => {
        if (this.state.pageNo > 1) {
            this.setState({
                loading: true,
                site_list: [],
            });
            var aa = this.state.pageNo - 1;
            this.setState(
                {
                    pageNo: aa,
                },
                () => {
                    this.getAllSites();
                }
            );
        }
    };

    nextBtnClick = () => {
        this.setState({
            loading: true,
            site_list: [],
        });
        var aa = this.state.pageNo + 1;
        this.setState(
            {
                pageNo: aa,
            },
            () => {
                this.getAllSites();
            }
        );
    };

    searchFormItemChange = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        //console.log(obj)
        this.setState(obj, () => {
            if (
                this.state.site_id != "" ||
                this.state.site_name != "" ||
                this.state.depotId != ""
            ) {
                this.setState({
                    filterButton: true,
                });
            } else {
                this.setState({
                    filterButton: false,
                });
            }
        });
    };

    searchDepottemChange = (event) => {
        this.setState(
            {
                depotId: event.value,
            },
            () => {
                if (
                    this.state.site_id != "" ||
                    this.state.site_name != "" ||
                    this.state.depotId != ""
                ) {
                    this.setState({
                        filterButton: true,
                    });
                } else {
                    this.setState({
                        filterButton: false,
                    });
                }
            }
        );
    };

    viewFilterClick = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    };

    filterBtnClick = async () => {
        this.setState({
            loading: true,
        });
        let filtered = [];
        if(this.state.filterRegion !== null){
        if (this.state.filterCategory != null) {
            filtered = this.state.fullAllSite
                .filter((site) =>
                    this.state.filterDepot != null
                        ? site.depot_id == this.state.filterDepot
                        : site && this.state.filterRegion != null
                            ? site.patent_region_id == this.state.filterRegion
                            : site
                )
                .filter(
                    (category) => category.site_category_id == this.state.filterCategory
                );
        } else {
            filtered = this.state.fullAllSite.filter((site) =>
                this.state.filterDepot != null
                    ? site.depot_id == this.state.filterDepot
                    : site && this.state.filterRegion != null
                        ? site.patent_region_id == this.state.filterRegion
                        : site
            );
        }} else {
            message.error("Please select a region to filter sites");   
        }
        this.setState({
            filteredTable: filtered,
            loading: false,
        });
    };


    clearBtnClick = () => {
        window.location.reload();
    };
    search = (value) => {
        this.setState({
            setValues: value,
        });

        const filtered = this.state.site_list.filter(
            (user) =>
                user?.site_name?.toLowerCase().includes(value.toLowerCase()) ||
                user?.site_id?.toLowerCase().includes(value.toLowerCase())
        );

        this.setState({
            filteredTable: filtered,
        });
    };
    onChangeRegion = (value) => {
        console.log(value);

        var dd = value;
        //console.log(dd)
        var subRegion = [];
        var _depotList1 = [];
        var _allSite = [];
        var subRegionIdArray = [];

        this.props.regions
            .filter((allret) => allret?.parentRegion?.id == dd)
            .map((subR) => {
                subRegionIdArray.push(subR.id);
            });

        this.setState(
            {
                selectRegion: subRegionIdArray,
                filterDepot: null,
                filterRegion: value == "undefined" ? null : value,
            },
            () => {
                if (
                    this.state.filterRegion != null ||
                    this.state.filterDepot != null ||
                    this.state.filterCategory != null
                ) {
                    this.setState({
                        filterButton: true,
                    });
                } else {
                    this.setState({
                        filterButton: true,
                    });
                }
            }
        );

        //console.log(this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id)));
        subRegion = this.props.regions.filter(
            (allret) => allret?.parentRegion?.id == dd
        );
        //console.log(this.props.depots.filter(alldate => subRegion.map((n) => n.id).includes(alldate.region?.id)));
        this.props.depots
            .filter((alldate) =>
                subRegion.map((n) => n.id).includes(alldate.region?.id)
            )
            .map((depot) => {
                _depotList1.push({ id: depot?.id, name: depot?.name });
            });
        console.log("selectDepot");
        console.log(this.state.selectDepot);

        this.setState({
            depot_list: _depotList1,
        });
    };
    onChangeCategory = (value) => {
        this.setState(
            {
                // DepotName: dataa[value - 1]?.name,
                filterCategory: value == "undefined" ? null : value,
                selectType: 2,
                selectId: value,
                selectDepot: value,
            },
            () => {
                if (
                    this.state.filterRegion != null ||
                    this.state.filterDepot != null ||
                    this.state.filterCategory != null
                ) {
                    this.setState({
                        filterButton: true,
                    });
                } else {
                    this.setState({
                        filterButton: true,
                    });
                }
            }
        );
    };
    onChangeDepot = (value) => {
        //console.log("onChangeDepot");
        console.log(value);

        this.setState(
            {
                // DepotName: dataa[value - 1]?.name,
                filterDepot: value == "undefined" ? null : value,
                selectType: 2,
                selectId: value,
                selectDepot: value,
            },
            () => {
                if (
                    this.state.filterRegion != null ||
                    this.state.filterDepot != null ||
                    this.state.filterCategory != null
                ) {
                    this.setState({
                        filterButton: true,
                    });
                } else {
                    this.setState({
                        filterButton: true,
                    });
                }
            }
        );
    };

    render() {
        const columns123 = [
            {
                title: <span className='textStyles-small' style={{ fontSize: 12 }}>Site ID</span>,
                key: "id",
                render: (text, record, index) => (
                    <span className='textStyles-small' >
                        <Link className='textStyles-small' to={"/site-summary/" + text.id}>{text?.site_id}</Link>
                    </span>
                ),
            },
            {
                title: <span className='textStyles-small' style={{ fontSize: 12 }}>Site Name</span>,
                key: "siteName",
                render: (item) => (
                    <span className='textStyles-small' >
                        <Link className='textStyles-small'
                            style={{ fontSize: 12 }}
                            to={"/site-summary/" + item.id}>{item?.site_name}</Link>
                    </span>
                ),
            },
            {
                title: <span className='textStyles-small' style={{ fontSize: 12 }}>Region</span>,
                //   dataIndex: "accessPermissionCode",
                key: "accessPermissionCode",
                render: (item) => <span>{item.parent_region_name}</span>,
            },
            {
                title: <span className='textStyles-small' style={{ fontSize: 12 }}>Depot</span>,
                //   dataIndex: "accessPermissionCode",
                key: "accessPermissionCode",
                render: (item) => <span className='textStyles-small' >{item?.depot_name}</span>,
            },

            {
                title: <span className='textStyles-small' style={{ fontSize: 12 }}>Status</span>,
                dataIndex: "status",
                key: "operationTypeName",
                render: (item) => (
                    <>
                        <Tag color="#03d87f" key={item} style={{ borderRadius: 5 }}>
                            <span className='textStyles-small' style={{ fontSize: 10 }}>{item}</span>
                        </Tag>
                    </>
                ),
            },
            {
                title: <span className='textStyles-small' style={{ fontSize: 12 }}>Site Category</span>,
                //dataIndex: "siteCategory",
                align: "center",
                key: "siteCategory",
                render: (item) => (
                    <span class="badge badge-outline-primary badge-pill textStyles-small">
                        {item?.site_category_name}
                    </span>
                ),
                //render: (item) => <span>{item}</span>,
            },
            // {
            //   title: <span className='textStyles-small' style={{fontSize: 12}}>Tower Height</span>,
            //   dataIndex: "tower_height",
            //   align: "right",
            //   key: "towerHeight",
            //   render: (item) => <span className='textStyles-small' >{item}</span>,
            // },
            {
                title: <span className='textStyles-small' style={{ fontSize: 12 }}>Contact Person</span>,
                key: "DepotOfficer ",
                render: (item) => <span className='textStyles-small'>{item?.contact_person}</span>,
            },
            {
                title: <span className='textStyles-small' style={{ fontSize: 12 }}>Contact Number</span>,
                key: "contact_no",
                align: "right",
                width: "9%",

                render: (item) => (
                    <span className='textStyles-small'>
                        {item?.contact_no}
                    </span>
                ),
            },
        ];

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                this.setState({
                    selectedSites: selectedRows,
                });
            },
            getCheckboxProps: (record) => ({
                disabled: record.name === 'Disabled User', // Example: Disable checkbox for specific rows
                name: record.name,
            }),
        };


        const handleChangeDepot = async () => {
            if (this.state.targetDepot == null) {
                this.showErrorMessage("Please select a depot to migrate sites");
                return;
            }
            if (this.state.selectedSites.length == 0) {
                this.showErrorMessage("Please select sites to migrate");
                return;
            }
            const fromdata = new FormData();
            fromdata.append("depotId", this.state.targetDepot);
            fromdata.append("siteIds", this.state.selectedSites.map((site) => site.id));
            var res = await changeDepot(fromdata);
            console.log(res);
            if (res.success) {
                message.success("Sites migrated successfully");
                localStorage.removeItem("siteInfo");
                this.getSitesLiteForSiteManage();
                this.setState({
                    selectedSites: [],
                    filterRegion: null,
                    filterDepot: null,
                    filterCategory: null,
                    targetDepot: null,
                    filteredTable: [],
                });
                window.location.reload();
            } else {
                message.error("Failed to migrate sites : " + res.data.data);
            }
        };

        const siteMigrateTask = () => {
            Modal.confirm({
              title: 'Confirm',
              icon: <ExclamationCircleOutlined />,
              content: <span className='textStyles-small'>Do you want to migrate this selected sites? <br /> 
              {this.state.selectedSites.map((site) => site.site_name).join(", ")}</span>,
              okText: 'Yes',
              cancelText: 'No',
              onOk: async () => handleChangeDepot(),
            });
          };


        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="page-title-box">
                                <div className="row">
                                    <div class="col">
                                        <h4 class="page-title">Sites Migrate</h4>
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item">
                                                <a href="javascript:void(0);">Admin</a>
                                            </li>
                                            <li class="breadcrumb-item">
                                                <a href="javascript:void(0);">Sites Migrate</a>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: 10, backgroundColor: "white", padding: 15, borderRadius: 5 }}>
                        <div className="row">
                                <div className="row">
                                    <div>
                                        <div class="row">
                                            <div className="col-md-4">
                                                <label class="mb-3"><span class="textStyles-small">Region</span></label>
                                                <Select
                                                    showSearch
                                                    onChange={this.onChangeRegion}
                                                    name="regionId"
                                                    value={this.state.filterRegion}
                                                    placeholder="Select Regions"
                                                    bordered={false}
                                                    className="borderedSelect"
                                                    style={{ height: 34, width: '98%' }}
                                                    optionFilterProp="children"
                                                    allowClear
                                                    filterOption={(input, option) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children
                                                            .toLowerCase()
                                                            .localeCompare(optionB.children.toLowerCase())
                                                    }
                                                >
                                                    {this.props.regions
                                                        .filter((allret) => "0".includes(allret.isSubRegion))
                                                        .map((item, index) => {
                                                            return (
                                                                <Option value={item?.id}>{item?.name}</Option>
                                                            );
                                                        })}
                                                </Select>
                                            </div>
                                            <div class="col-md-4">
                                                <label class="mb-3"><span class="textStyles-small">Depot</span></label>
                                                <Select
                                                    showSearch
                                                    onChange={this.onChangeDepot}
                                                    value={this.state.filterDepot}
                                                    name="depotsId"
                                                    bordered={false}
                                                    className="borderedSelect"
                                                    style={{ height: 34, width: '98%' }}
                                                    placeholder="Select Depot"
                                                    optionFilterProp="children"
                                                    allowClear
                                                    filterOption={(input, option) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children
                                                            .toLowerCase()
                                                            .localeCompare(optionB.children.toLowerCase())
                                                    }
                                                >
                                                    {this.state.depot_list.map((item, index) => {
                                                        return <Option value={item?.id}>{item?.name}</Option>;
                                                    })}
                                                </Select>
                                            </div>
                                            <div class="col-md-4">
                                                <label class="mb-3"><span class="textStyles-small">Category</span></label>
                                                <Select
                                                    showSearch
                                                    onChange={this.onChangeCategory}
                                                    value={this.state.filterCategory}
                                                    name="depotsId"
                                                    bordered={false}
                                                    className="borderedSelect"
                                                    style={{ height: 34, width: '98%' }}
                                                    placeholder="Select Depot"
                                                    optionFilterProp="children"
                                                    allowClear
                                                    filterOption={(input, option) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children
                                                            .toLowerCase()
                                                            .localeCompare(optionB.children.toLowerCase())
                                                    }
                                                >
                                                    {this.state.sitwCategory.map((item, index) => {
                                                        return <Option value={item?.id}>{item?.name}</Option>;
                                                    })}
                                                </Select>
                                            </div>

                                            <div class="col-md-12" style={{ marginTop: 10, display: 'flex', justifyContent: 'flex-end', marginLeft: 5 }}>
                                                {this.state.filterButton ? (
                                                    <Button
                                                        icon={<SearchOutlined style={{ color: 'white' }} />}
                                                        style={{ width: 100, height: 34, borderRadius: 5, marginLeft: 5, backgroundColor: "#00AC4F", borderColor: "#00AC4F" }}
                                                        class="btn btn-primary"
                                                        onClick={() => this.filterBtnClick()}
                                                    >
                                                        <span className='textStyles-small' style={{ color: 'white' }}>Apply</span>
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        icon={<SearchOutlined style={{ color: 'white' }} />}
                                                        disabled
                                                        style={{ width: 100, height: 34, borderRadius: 5, marginLeft: 5, backgroundColor: "#00AC4F", borderColor: "#00AC4F" }}
                                                        class="btn btn-primary"
                                                        onClick={() => this.filterBtnClick()}
                                                    >
                                                        <span className='textStyles-small' style={{ color: 'white' }}>Apply</span>
                                                    </Button>
                                                )}

                                                <Button
                                                    icon={<CloseCircleOutlined style={{ color: 'white' }} />}
                                                    style={{ width: 100, height: 34, borderRadius: 5, marginLeft: 5, backgroundColor: "red", borderColor: "red" }}
                                                    class="btn btn-dark"
                                                    onClick={() => this.clearBtnClick()}
                                                >
                                                    <span className='textStyles-small' style={{ color: 'white' }}>Clear</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Row>
                                <Col span={12} style={{marginTop: 10}}>
                                    {
                                        this.state.selectedSites.length > 0 ? (
                                            <>
                                                <Select
                                                    showSearch
                                                    onChange={(value) => this.setState({ targetDepot: value })}
                                                    name="depotsId"
                                                    bordered={false}
                                                    className="borderedSelect"
                                                    style={{ height: 34, width: '45%' }}
                                                    placeholder="Select Depot"
                                                    optionFilterProp="children"
                                                    allowClear
                                                    filterOption={(input, option) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        optionA.children
                                                            .toLowerCase()
                                                            .localeCompare(optionB.children.toLowerCase())
                                                    }
                                                >
                                                    {this.state.depot_list.map((item, index) => {
                                                        return <Option value={item?.id}>{item?.name}</Option>;
                                                    })}
                                                </Select>
                                                
                                                {
                                                    this.state.targetDepot != null ? (
                                                        <Button
                                                        icon={<RetweetOutlined style={{ color: 'white' }} />}
                                                        style={{ width: 140, height: 34, borderRadius: 5, marginLeft: 5}}
                                                        class="btn btn-dark"
                                                        type="primary"
                                                        onClick={() => siteMigrateTask()}
                                                    >
                                                        <span className='textStyles-small' style={{ color: 'white' }}>Migrate Sites</span>
                                                    </Button>
                                                    ) : null
                                                }

                                            </>
                                        ) : null
                                    }
                                </Col>
                                {/* <Col span={12}>
                                    <Row align="middle" justify="end">
                                        <Input
                                            id="clearSearch"
                                            enterButton
                                            className="borderedSelect"
                                            style={{ height: 34, width: '45%' }}
                                            placeholder="Search by Site name or Site ID"
                                            value={this.state.setSearchValue}
                                            onChange={(e) => {
                                                const currValue = e.target.value;
                                                this.search(currValue);
                                                this.setState({
                                                    setSearchValue: currValue,
                                                });
                                            }}
                                        />
                                        <Button
                                            icon={<FilterOutlined style={{ color: 'white' }} />}
                                            style={{ width: 34, height: 34, borderRadius: 5, marginLeft: 5, backgroundColor: "#00AC4F", borderColor: "#00AC4F" }}
                                            class="btn btn-danger"
                                            onClick={() => this.viewFilterClick()}
                                        />
                                    </Row>
                                </Col> */}
                            </Row>

                            {/* <pre>
                            {JSON.stringify(this.state.site_list, null, 2)}    
                            </pre>                             */}
                            <Table
                                        size="small"
                                        className="table-striped-rows"
                                        style={{ marginTop: 10 }}
                                        columns={columns123}
                                        loading={this.state.loading}
                                        rowSelection={rowSelection}
                                        rowKey="id"
                                        // dataSource={
                                        //     this.state.filteredTable == null
                                        //         ? [...this.state.site_list].sort((a, b) => b.id - a.id)
                                        //         : [...this.state.filteredTable].sort((a, b) => b.id - a.id)
                                        // }
                                        dataSource={this.state.filteredTable === null ? [] : [...this.state.filteredTable].sort((a, b) => b.id - a.id)}
                                    />


                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        organization_count: state.organization.length,
        regions: state.regions,
        depots: state.depots,
        sites_count: state.sites.length,
        vendor_count: state.vendor.length,
        roles_count: state.roles.length,
    };
}

export default connect(mapStateToProps, { getDonationCategories, getData })(
    MigrateSites
);