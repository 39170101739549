import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { connect } from "react-redux";
import { createNewRegion, UpdateRegionByGivenID } from '../../app/api/index';
import { ToastContainer, toast } from 'material-react-toastify';
import { addRegion, getDonationCategories, getData } from '../../app/actions/index';
import { Collapse, Select, Button, Tag, message, Row, Col, Form, Modal, Input, Result, Table, Drawer, Menu, Dropdown } from 'antd';
import { ExclamationCircleOutlined, DeleteOutlined, EditOutlined, PlusCircleFilled, MoreOutlined } from '@ant-design/icons';
import './master-data/style.css';

class Region extends Component {

	constructor(props) {
		super(props)
		this.validator = new SimpleReactValidator();
		this.state = {
			name: "",
			description: "",
			isSubRegion: false,
			parentRegion: null,
			organization: null,
			regionState: [],
			filterTable: null,
			editDrawerVisible: false,
			isEdit: false,
			isOpenModal: false,
		}
	}

	componentDidMount() {
		this.props.getData();
		this.setState({
			regionState: this.props.regions
		}, () => {
			console.log(this.state.regionState)
		})
	}

	formItemChange = (event) => {
		var obj = {};
		obj[event.target.name] = event.target.value;
		this.setState(obj);
	}

	formItemChecked = (event) => {
		const { name, checked } = event.target;
		this.setState((prevState) => ({
			editVendorDetails: {
				...prevState.editVendorDetails,
				[name]: checked?'1':'0',
			},
		}));
		var obj = {};
		obj[event.target.name] = event.target.checked;
		this.setState(obj);
	}

	showDeleteConfirm = (record) => {
		const { confirm } = Modal;
		confirm({
			title: 'Do you want to delete this Region?',
			onOk: async () => {
				/*var res = await deleteVendor(record.id);
				if (res.success) {
				  toast.success('Vendor deleted successfully');
				  this.props.removeVendor(record.id);
				}*/
			},
			onCancel() { },
		});
	};

	updateRegion = async (values) => { 
		var data = {
			"id": this.state.editVendorDetails.id,
			"name": values.name,
			"description": values.description,
			"isSubRegion": values.parentRegion == null ? 0 : 1,
			"parentRegion": values.parentRegion,
			"organization": {
				"id": values.organization
			}
		}
		
		var res = await UpdateRegionByGivenID(data, this.state.editVendorDetails.id);
		if (res.success) {
			this.props.getData();
			toast.success('Region updated successfully');
			this.closeEditDrawer();
			this.setState({
				name: "",
				description: "",
				isOpenModal: false,
				isSubRegion: false,
				isOpenModal: false,
				isEdit: false,
			})
		} else {
			toast.error('Something went wrong : ' + res.errorMessage);
			this.closeEditDrawer();
			this.forceUpdate();
		}
	};

	createRegion = async (values) => {
		console.log(values);
		// if (this.validator.allValid()) {
		// 	var data = {
		// 		"name": this.state.name,
		// 		"description": this.state.description,
		// 		"isSubRegion": this.state.isSubRegion ? 1 : 0,
		// 		"parentRegion": this.state.parentRegion,
		// 		"organization": {
		// 			"id": this.state.organization
		// 		}
		// 	}
		// 	var res = await createNewRegion(data);
		// 	if (res.success) {
		// 		this.props.getData();
		// 		toast.success("Successfully Created New Region")
		// 		this.setState({
		// 			name: "",
		// 			description: "",
		// 			isOpenModal: false,
		// 			isSubRegion: false,
		// 			isEdit: false,
		// 			isOpenModal: false
		// 		}, () => {
		// 		})
		// 	}
		// } else {
		// 	this.validator.showMessages();
		// 	this.forceUpdate();
		// }
		// values {
		// 	"name": "erwer",
		// 	"description": "werwrw",
		// 	"organization": 2,
		// 	"isSubRegion": "",
		// 	"parentRegion": 1
		// }

		var data = {
			"name": values.name,
			"description": values.description,
			"isSubRegion": values.parentRegion == null ? 0 : 1,
			"parentRegion": values.parentRegion,
			"organization": {
				"id": values.organization
			}
		}

		var res = await createNewRegion(data);
		if (res.success) {
			this.props.getData();
			toast.success("Successfully Created New Region")
			this.setState({
				name: "",
				description: "",
				isOpenModal: false,
				isSubRegion: false,
				isEdit: false,
				isOpenModal: false
			}, () => {
			})
		} else {
			toast.error('Something went wrong');
			this.closeEditDrawer();
			this.forceUpdate();
		}
	}

	search = (value) => {
		const filterTable = this.state.regionState.filter((o) =>
			Object.keys(o).some((k) =>
				String(o[k]).toLowerCase().includes(value.toLowerCase())
			)
		);
		this.setState({ filterTable });
	};

	showCreateConfirmationModal = (values) => {
		Modal.confirm({
			title: 'Confirm',
			icon: <ExclamationCircleOutlined />,
			content: 'Do you want to register a new Region?',
			okText: 'Yes',
			cancelText: 'No',
			onOk: () => this.createRegion(values),
		});
	};

	showUpdateConfirmationModal = (values) => {
		Modal.confirm({
			title: 'Confirm',
			icon: <ExclamationCircleOutlined />,
			content: 'Do you want to update this Region?',
			okText: 'Yes',
			cancelText: 'No',
			onOk: () => this.updateRegion(values),
		});
	};


	showEditDrawer = (record) => {
		this.setState({
			editDrawerVisible: true,
			name: record.name,
			description: record.description,
			isSubRegion: record.parentRegion == null ? 0: 1,
			parentRegion: record.parentRegion == null ? null : record.parentRegion.id,
			organization: record.organization.id,
			editVendorDetails: {
				id: record.id,
				name: record.name,
				description: record.description,
				isSubRegion: record.parentRegion == null ? 0: 1,
				parentRegion: record.parentRegion == null ? null : record.parentRegion.id,
				organization: record.organization.id,
			},
		});
	};

	closeEditDrawer = () => {
		this.setState({
			editDrawerVisible: false,
			name: "",
			description: "",
			isSubRegion: false,
			parentRegion: null,
			organization: null,
			regionState: [],
		});
	};

	editFormItemChange = (event) => {
		const { name, value } = event.target;
		this.setState((prevState) => ({
			editVendorDetails: {
				...prevState.editVendorDetails,
				[name]: value,
			},
		}));

		var obj = {};
		obj[event.target.name] = event.target.value;
		this.setState(obj);
	};


	render() {
		const columns = [
			{
				title: <span className='textStyles-small' style={{ fontSize: 11 }}>Region Name</span>,
				dataIndex: 'name',
				key: 'name',
				render: (text) => (
					<span className='textStyles-small' >{text === ' ' ? <i>No name</i> : text}</span>
				),
			},
			{
				title: <span className='textStyles-small' style={{ fontSize: 11 }}>Description</span>,
				dataIndex: 'description',
				key: 'description',
				render: (text) => <span className='textStyles-small' >{text}</span>,
			},
			{
				title: <span className='textStyles-small' style={{ fontSize: 11 }}>Is Sub Region</span>,
				dataIndex: 'isSubRegion',
				key: 'isSubRegion',
				render: (text) => <span className='textStyles-small' >{text == 0 ? "No" : "Yes"}</span>,
			},
			{
				title: <span className='textStyles-small' style={{ fontSize: 11 }}>Parent Region</span>,
				dataIndex: 'parentRegion',
				key: 'parentRegion',
				render: (text) => <span className='textStyles-small' >{text == null ? "-" : text.name}</span>,
			},
			{
				title: <span className='textStyles-small' style={{ fontSize: 11 }}>Organization</span>,
				dataIndex: 'organization',
				key: 'organization',
				render: (text) => <span className='textStyles-small' >{text.name}</span>,
			},
			{
				title: <span className='textStyles-small' style={{ fontSize: 11 }}>Action</span>,
				key: 'action',
				render: (text, record) => (
				<span className="text-end">
				<Button
					shape='circle'
					size='small'
					type='primary'
					icon={<EditOutlined style={{ fontSize: '14px' }} />}
					style={{ marginLeft: '10px' }}
					onClick={() => {
						this.showEditDrawer(record);
						this.setState({
							isEdit: true,
							isOpenModal: true
						});
					}}
				/>
				{/* <Button
					shape='circle'
					size='small'
					type='primary'
					danger
					icon={<DeleteOutlined style={{ fontSize: '14px' }} />}
					style={{ marginLeft: '10px' }}
					// onClick={() => this.showDeleteConfirm(record)}
				/> */}
			</span>
				),
			},
		];
		return (
			<div class="container-fluid">
				<div class="row">
					<div class="col-sm-12">
						<div class="page-title-box">
							<div class="row">
								<div class="col">
									<b>Region</b>
									<ol class="breadcrumb">
										<li class="breadcrumb-item"><a href="javascript:void(0);">Admin</a></li>
										<li class="breadcrumb-item"><a href="javascript:void(0);">Region</a></li>
									</ol>
								</div>
								<div class="col-auto align-self-center">
									<a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
										<span class="day-name" id="Day_Name">Today:</span>&nbsp;
										<span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
									</a>

								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div style={{ margin: '0', padding: '10px', width: '100%', backgroundColor: 'white', borderRadius: '10px', marginTop: '20px' }}>
						<Row>
							<Col span={12}>
								<Button type="primary" onClick={() => this.setState({ isOpenModal: true, isEdit: false })}
									style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px', margin: 10 }}><PlusCircleFilled />
									<span className="textStyles-small">New Region</span></Button>
							</Col>
							<Col span={12} style={{ textAlign: 'right' }}>
								<Input
									enterButton
									className='borderedSelect' style={{ height: 33, margin: 10, marginRight: 10, width: 350 }}
									placeholder="Search Region ..."
									onChange={(e) => {
										const currValue = e.target.value;
										this.search(currValue);
									}}
								/>
							</Col>
						</Row>
						<Table
							size="small"
							className="table-striped-rows"
							columns={columns}
							style={{ marginTop: 10 }}
							dataSource={
								this.state.filterTable == null
									? [...this.props.regions].sort((a, b) => b.id - a.id)// Reverse the order of the array
									: [...this.state.filterTable].sort((a, b) => b.id - a.id) // Reverse the order of the filtered array
							}
							rowKey="id"
						/>
					</div>

					{/* CREATE AND UPDATE REGION */}
					<Modal
						title={<span className="textStyles-small" style={{ fontSize: 14 }}>{
							this.state.isEdit ? 'Update Region' : 'Create New Region'
						}</span>} 
						visible={this.state.isOpenModal}
						onOk={() => {
							this.setState({ isOpenModal: false });
							this.closeEditDrawer();
						}}
						onCancel={() => {
							this.setState({ isOpenModal: false });
							this.closeEditDrawer();
						}}
						footer={null}
					>
						{
							this.state.isEdit ? (
								<div>
									{/* <div className="mb-3">
										<label className="form-label" htmlFor="editName">
											<span className="textStyles-small" style={{ fontSize: 12 }}>Region Name</span>
										</label>
										<input
											autoComplete="off"
											type="text"
											className="borderedSelect"
											style={{ height: 34, width: '98%' }}
											id="editName"
											name="name"
											value={this.state.name}
											onChange={(e) => {
												this.setState({ name: e.target.value, editVendorDetails: { ...this.state.editVendorDetails, name: e.target.value } });
											}}
										/>
										<p style={{ color: '#f5325c' }}>
											{this.validator.message('editName', this.state.name, 'required')}
										</p>
									</div>
									<div className="mb-3">
										<label className="form-label" htmlFor="editDescription">
											<span className="textStyles-small" style={{ fontSize: 12 }}>Region Description</span>
										</label>
										<input
											autoComplete="off"
											type="text"
											className="borderedSelect"
											style={{ height: 34, width: '98%' }}
											id="editDescription"
											name="description"
											value={this.state.description}
											onChange={(e) => {
												this.setState({ description: e.target.value, editVendorDetails: { ...this.state.editVendorDetails, description: e.target.value } });
											}}
										/>
										<p style={{ color: '#f5325c' }}>
											{this.validator.message('editDescription', this.state.description, 'required')}
										</p>
									</div>
									
									<div className="mb-3">
										<label className="form-label" htmlFor="organization">
											<span className="textStyles-small" style={{ fontSize: 12 }}>Select Organization</span>
										</label>
										<select
											className="borderedSelect"
											style={{ height: 34, width: '98%' }}
											id="organization"
											name="organization"
											// onChange={this.editFormItemChange}
											onChange={(e) => {
												this.setState({ organization: e.target.value, editVendorDetails: { ...this.state.editVendorDetails, organization: { id: e.target.value } } });
											}}
											value={this.state.organization}
										>
											{this.props.organizations.map((organization) => (
												<option key={organization.id} value={organization.id}>
													{organization.name}
												</option>
											))}
										</select>
										<p style={{ color: '#f5325c' }}>
											{this.validator.message('organization', this.state.organization, 'required')}
										</p>
									</div>

									<div className="mb-3">
										<div className="checkbox checkbox-primary" style={{ marginLeft: 7 }}>
											<input
												id="checkbox2"
												type="checkbox"
												name="isSubRegion"
												checked={this.state.isSubRegion}
												onChange={(e) => {
													this.setState({
														isSubRegion: e.target.checked,
														parentRegion: null,
														editVendorDetails: {
															...this.state.editVendorDetails,
															isSubRegion: e.target.checked ? 1 : 0,
															parentRegion: null
														}
													});
												}}
											/>
											<label className="form-label" htmlFor="checkbox2">
												<span className="textStyles-small" style={{ fontSize: 12 }}>
													{this.state.isSubRegion ? "Make It As Region" : "Make It As Sub Region"}
												</span>
											</label>
										</div>
									</div>
									{this.state.isSubRegion && (
										<div className="mb-3">
											<label className="form-label" htmlFor="parentRegion">
												<span className="textStyles-small" style={{ fontSize: 12 }}>Parent Region</span>
											</label>
											<select
												className="borderedSelect"
												style={{ height: 34, width: '98%' }}
												id="parentRegion"
												name="parentRegion"
												value={this.state.parentRegion}
												onChange={(e) => {
													this.setState({ parentRegion: e.target.value, 
														editVendorDetails: { ...this.state.editVendorDetails, parentRegion: { id: e.target.value } } });
												}}
											>
												{this.props.regions.map((region) => (
													<option key={region.id} value={region.id}>
														{region.name}
													</option>
												))}
											</select>
										</div>
									)}

									{
										this.state.isSubRegion && (this.state.editVendorDetails !== null || this.state.editVendorDetails === "") ? (
											<Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
											<button type="submit" className="btn btn-primary"
												style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px', marginTop: 10 }}
												onClick={this.showUpdateConfirmationModal}>
												<span className="textStyles-small">Update Region</span>
											</button>
										</Row>
										) : null
									}

									{
										this.state.isSubRegion === false ? (
											<Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
												<button type="submit" className="btn btn-primary"
													style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px', marginTop: 10 }}
													onClick={this.showUpdateConfirmationModal}>
													<span className="textStyles-small">Update Region</span>
												</button>
											</Row>
										) : null
									} */}

									<Form
										layout="vertical"
										onFinish={(value) => {
											this.showUpdateConfirmationModal(value);
										}}
									>
										<Form.Item
											label={<span className='textStyles-small' style={{ fontSize: 12 }}>Region Name</span>}
											name="name"
											rules={[{ required: true, message: 'Please input your region name!' }]}
											initialValue={this.state.name}
										>
											<Input
												autoComplete="off"
												type="text"
												className="borderedSelect"
												style={{ height: 34, width: '98%' }}
												name="name"
											/>
										</Form.Item>

										<Form.Item
											label={<span className='textStyles-small' style={{ fontSize: 12 }}>Description</span>}
											name="description"
											rules={[{ required: true, message: 'Please input your region description!' }]}
											initialValue={this.state.description}
										>
											<Input
												autoComplete="off"
												type="text"
												className="borderedSelect"
												style={{ height: 34, width: '98%' }}
												name="description"
											/>
										</Form.Item>

										<Form.Item
											label={<span className='textStyles-small' style={{ fontSize: 12 }}>Organization</span>}
											name="organization"
											rules={[{ required: true, message: 'Please select organization!' }]}
											initialValue={this.state.organization}
										>
											<Select
												className='borderedSelect'
												style={{ height: 34, width: '98%' }}
												bordered={false}
												name="organization"
											>
												{this.props.organizations.map((organization, index) => {
													return <Select.Option value={organization.id}>{organization.name}</Select.Option>
												})}
											</Select>
										</Form.Item>

										<Row>
											<Form.Item name="isSubRegion">
												<input
													id="checkbox2"
													type="checkbox"
													name="isSubRegion"
													label="Sub Region"
													onChange={(e) => {
														this.setState({
															isSubRegion: e.target.checked,
															parentRegion: null
														});
													}}
												/>
											</Form.Item>
											<span className='textStyles-small' style={{ fontSize: 12, marginLeft: 10 , marginTop: 10}}>
												{this.state.isSubRegion ? "Make It As Region" : "Make It As Sub Region"}
											</span>
										</Row>

										{this.state.isSubRegion && (
											<Form.Item
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Parent Region</span>}
												name="parentRegion"
												rules={[{ required: true, message: 'Please select parent region!' }]}
												initialValue={this.state.parentRegion}
											>
												<Select
													className='borderedSelect'
													style={{ height: 34, width: '98%' }}
													bordered={false}
													name="parentRegion"
												>
													{this.props.regions.map((region, index) => {
														return <Select.Option value={region.id}>{region.name}</Select.Option>
													})}
												</Select>
											</Form.Item> ) 
										}
										<Form.Item>
											<Button type="primary" htmlType="submit" style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px', marginTop: 10 }}>
												<span className="textStyles-small">Update Region</span>
											</Button>
										</Form.Item>
										</Form>
								</div>
							) : (
								<div>
										<Form
											layout="vertical"
											onFinish={(values) => {
												this.showCreateConfirmationModal(values);
											}}
										>
											<Form.Item
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Region Name</span>}
												name="name"
												rules={[{ required: true, message: 'Please input your region name!' }]}
											>
												<Input
													autoComplete="off"
													type="text"
													className="borderedSelect"
													style={{ height: 34, width: '98%' }}
													name="name"
												/>
											</Form.Item>

											<Form.Item
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Description</span>}
												name="description"
												rules={[{ required: true, message: 'Please input your region description!' }]}
											>
												<Input
													autoComplete="off"
													type="text"
													className="borderedSelect"
													style={{ height: 34, width: '98%' }}
													name="description"
												/>
											</Form.Item>

											<Form.Item
												label={<span className='textStyles-small' style={{ fontSize: 12 }}>Organization</span>}
												name="organization"
												rules={[{ required: true, message: 'Please select organization!' }]}
											>
												<Select
													className='borderedSelect'
													style={{ height: 34, width: '98%' }}
													bordered={false}
													name="organization"
												>
													{this.props.organizations.map((organization, index) => {
														return <Select.Option value={organization.id}>{organization.name}</Select.Option>
													})}
												</Select>
											</Form.Item>

											<Row>
												<Form.Item name="isSubRegion">
													<input
														id="checkbox2"
														type="checkbox"
														name="isSubRegion"
														label="Sub Region"
														onChange={(e) => {
															this.setState({
																isSubRegion: e.target.checked,
																parentRegion: null
															});
														}}
													/>
												</Form.Item>
												<span className='textStyles-small' style={{ fontSize: 12, marginLeft: 10 , marginTop: 10}}>
													{this.state.isSubRegion ? "Make It As Region" : "Make It As Sub Region"}
												</span>
											</Row>

											{this.state.isSubRegion && (
												<Form.Item
													label={<span className='textStyles-small' style={{ fontSize: 12 }}>Parent Region</span>}
													name="parentRegion"
													rules={[{ required: true, message: 'Please select parent region!' }]}
												>
													<Select
														className='borderedSelect'
														style={{ height: 34, width: '98%' }}
														bordered={false}
														name="parentRegion"
													>
														{this.props.regions.map((region, index) => {
															return <Select.Option value={region.id}>{region.name}</Select.Option>
														})}
													</Select>
												</Form.Item>
											)}

											<Form.Item>
												<Button type="primary" htmlType="submit" style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px', marginTop: 10 }}>
													<span className="textStyles-small">Create New Region</span>
												</Button>
											</Form.Item>

										</Form>
								</div>
								

							)
						}
					</Modal>
				</div>
				<ToastContainer position="top-right" autoClose={5000} />
			</div>
		);
	}
}


function mapStateToProps(state) {
	return {
		regions: state.regions,
		organizations: state.organization
	};
}


/*function mapDispatchToProps(dispatch) {
	return {
		addRegion: data => dispatch(addRegion(data))
	};
}

export default connect(
	mapStateToProps, mapDispatchToProps
)(Region);
*/

export default connect(mapStateToProps, { getDonationCategories, getData })(
	Region
);

