import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import { Collapse } from 'reactstrap';
import { getAllVehicleDetails, createVehicleDetail, getVehicleData, getVehicleDetailById, editVehicleDetail } from "../../api/index";

import { DatePicker, Table, List, Skeleton, Avatar, Form, Tag, message, Drawer, Select, InputNumber, Input } from 'antd';

class Index extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props)
        this.validator = new SimpleReactValidator();
        this.state = {
            isOpen: false,
            open: false,
            selectPageNo: 0,
            selectRecodePerPage: 30,
            dataPageCount:0,
            searchVehicleReNo: '',
            vehicleDataCategory: [],
            vehicleDataOwner: [],
            vehicleDataPaymentCategory: [],
            vehicleDataType: [],
            vehicleDataSubType: [],
            vehicle_list: [],
            loading: false,
            vehicleModel: '',
            vehicleBrand: '',
            vehicleReNum: '',
            vehicleSeatingCapacity: '',
            vehiclePaymentCategory: null,
            selectedTypeId: null,
            vehicleType: [],
            selectedVehicleSubTypeId: null,
            vehicleSubType: [],
            selectedVehicleOwnerId: null,
            VehicleOwner: [],
            selectVehiclePaymentCategoryId: null,
            VehiclePayment: [],
            selectIdforUpdate: '',
            vehicleActive: null,

        }
        this.handleVehicleModel = this.handleVehicleModel.bind(this);
        this.handleVehicleBrand = this.handleVehicleBrand.bind(this);
        this.handleVehicleRegistationNumber = this.handleVehicleRegistationNumber.bind(this);
        this.handleSeatingCapacity = this.handleSeatingCapacity.bind(this);
        this.handleVehiclePaymentCategory = this.handleVehiclePaymentCategory.bind(this);
    }
    onChangeVehicleOwner = (value) => {
        console.log("onChangeVehicleOwner");
        //console.log(value);
        this.setState({
            selectedVehicleOwnerId: value,
            VehicleOwner: {
                "id": value
            },

        })
    };
    onChangeVehiclePaymentCategory = (value) => {
        console.log("onChangeVehicleSubType");
        //console.log(value);
        this.setState({
            selectVehiclePaymentCategoryId: value,
            VehiclePayment: {
                "id": value
            },

        })
    };
    onChangeVehicleSubType = (value) => {
        console.log("onChangeVehicleSubType");
        //console.log(value);
        this.setState({
            selectedVehicleSubTypeId: value,
            vehicleSubType: {
                "id": value
            },

        })
    };
    onChangeVehicleType = (value) => {
        console.log("onChangeVehicleType");
        //console.log(value);
        this.setState({
            selectedTypeId: value,
            vehicleType: {
                "id": value
            },

        })
    };
    handleVehiclePaymentCategory(e) {
        console.log(e.target.value)
        this.setState({ vehiclePaymentCategory: e.target.value });
    }
    handleSeatingCapacity(e) {
        console.log(e)
        this.setState({ vehicleSeatingCapacity: e });
    }
    handleVehicleRegistationNumber(e) {
        console.log(e.target.value)

        var myStr = e.target.value; // space at both end
        var newStr = myStr.replace(/\s/g, "");
        this.setState({ vehicleReNum: newStr });
    }
    handleVehicleModel(e) {
        console.log(e.target.value)
        this.setState({ vehicleModel: e.target.value });
    }
    handleVehicleBrand(e) {
        console.log(e.target.value)
        this.setState({ vehicleBrand: e.target.value });
    }
    componentDidMount() {
        this.getAllVehicle();
        this.getAllVehicleData();
    }
    onChangeCreateVehicle = () => {
        console.log('sadsgf')
        this.setState({
            open: true
        })
    }
    getDataVehicleDetailById = async (id) => {
        var data = new FormData();
        data.append('id', id);
        var res = await getVehicleDetailById(data);
        console.log('getVehicleDetailById');
        console.log(res);
        if (res.success) {
            this.setState({
                selectIdforUpdate: id,
                vehicleModel: res.data.model,
                vehicleBrand: res.data.brand,
                vehicleReNum: res.data.vehicleRegistationNumber,
                vehicleSeatingCapacity: res.data.seatingCapacity,
                selectedTypeId: res.data.vehicleType.id,
                vehicleType: res.data.vehicleType,
                selectedVehicleSubTypeId: res.data.vehicleSubType.id,
                vehicleSubType: res.data.vehicleSubType,
                selectedVehicleOwnerId: res.data.vehicleOwner.id,
                VehicleOwner: res.data.vehicleOwner,
                //vehicleActive:res.data.active,


            })

            if (res.data?.active == false) {

                this.setState({
                    vehicleActive: 0
                })

            } else {

                this.setState({
                    vehicleActive: 1
                })
            }

        }
    }
    updateVehicle = (id) => {
        console.log(id)
        this.getDataVehicleDetailById(id);
        this.setState({
            openEdit: true,
        })
    }
    onDrawerClose = () => {
        console.log('sddsds')
        this.formRef.current.resetFields();
        this.setState({
            open: false,
            openEdit: false,

            showModal: false,
            open: false,
            vehicleModel: '',
            vehicleBrand: '',
            vehicleReNum: '',
            vehicleSeatingCapacity: '',
            vehiclePaymentCategory: null,
            selectedTypeId: null,
            vehicleType: [],
            selectedVehicleSubTypeId: null,
            vehicleSubType: [],
            selectedVehicleOwnerId: null,
            VehicleOwner: [],
            selectVehiclePaymentCategoryId: null,
            VehiclePayment: [],
            vehicleActive: null,





        })

    }
    viewFilterClick = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }
    getAllVehicleData = async () => {
        var res = await getVehicleData();
        console.log('getAllVehicleData');
        console.log(res);
        if (res.success) {
            this.setState({
                vehicleDataCategory: res.data.category,
                vehicleDataOwner: res.data.owner,
                vehicleDataPaymentCategory: res.data.paymentCategory,
                vehicleDataType: res.data.type,
                vehicleDataSubType: res.data.subType,
            })
        }
    }

    getAllVehicle = async () => {
        this.setState({
            loading: true,
            vehicle_list: [],
        })
        var data = new FormData();
        data.append('pageNo', this.state.selectPageNo);
        data.append('recodesPerPage', this.state.selectRecodePerPage);
        data.append('vehicleRegistationNumber', this.state.searchVehicleReNo);



        var res = await getAllVehicleDetails(data);
        console.log('getAllVehicleDetailslll')
        console.log(res)
        console.log(res.data.data)

        if (res.success) {
            this.setState({
                vehicle_list: res.data.data,
                dataPageCount:res.data.pageCount,
                loading: false
            })
        }
    }
    previousBtnClick = () => {
        if (this.state.selectPageNo > 0) {
            this.setState({
                loading: true,
                vehicle_list: [],
            })
            var aa = this.state.selectPageNo - 1
            this.setState({
                selectPageNo: aa
            }, () => {
                this.getAllVehicle();
            })
        } else {
            message.success('First Page!')
        }
    }

    nextBtnClick = () => {
        console.log(this.state.vehicle_list.length)
        if (this.state.vehicle_list.length == this.state.selectRecodePerPage) {
            this.setState({
                loading: true,
                vehicle_list: [],
            })
            var aa = this.state.selectPageNo + 1
            this.setState({
                selectPageNo: aa
            }, () => {
                this.getAllVehicle();
            })
        } else {
            message.warning('Last Page!')
        }



    }
    searchFormItemChange = (event) => {
        console.log(event.target.value)
        this.setState({
            searchVehicleReNo: event.target.value
        });

    }
    onChangevehicleActive = (value) => {
        //console.log("onChangeislowVoltageFunctionality");
        console.log(value);

        this.setState({
            vehicleActive: parseInt(value),


        })


    };
    filterBtnClick = async () => {
        this.setState({
            loading: true,
            vehicle_list: [],
            selectPageNo:0
        }, () => {
            this.getAllVehicle();
        })


    }
    clearBtnClick = () => {
        window.location.reload()
    }
    AllDataSubmitUpdate = async () => {
        var dataSet;
        dataSet = {
            "id": this.state.selectIdforUpdate,
            "model": this.state.vehicleModel,
            "brand": this.state.vehicleBrand,
            "seatingCapacity": this.state.vehicleSeatingCapacity,
            "vehicleRegistationNumber": this.state.vehicleReNum,
            "vehicleType": this.state.vehicleType,
            "vehicleSubType": this.state.vehicleSubType,
            "vehicleOwner": this.state.VehicleOwner,
            "active": this.state.vehicleActive,
            // "vehiclePaymentCategory": this.state.VehiclePayment



        }
        console.log(dataSet)

        var res = await editVehicleDetail(dataSet);
        console.log("editVehicleDetail");
        console.log(res);
        if (res.success) {
            message.success('Update Successful!')
            console.log("awaaa")
            this.formRef.current.resetFields();

            this.setState({
                showModal: false,
                open: false,
                openEdit: false,
                vehicleModel: '',
                vehicleBrand: '',
                vehicleReNum: '',
                vehicleSeatingCapacity: '',
                vehiclePaymentCategory: null,
                selectedTypeId: null,
                vehicleType: [],
                selectedVehicleSubTypeId: null,
                vehicleSubType: [],
                selectedVehicleOwnerId: null,
                VehicleOwner: [],
                // searchVehicleReNo: '',
                vehicle_list: [],
                selectVehiclePaymentCategoryId: null,
                VehiclePayment: [],
                vehicleActive: null,
            }, () => {
                this.getAllVehicle();
        this.getAllVehicleData();
            })
        }
    }
    AllDataSubmit = async () => {
        var dataSet;
        dataSet = {

            "model": this.state.vehicleModel,
            "brand": this.state.vehicleBrand,
            "seatingCapacity": this.state.vehicleSeatingCapacity,
            "vehicleRegistationNumber": this.state.vehicleReNum,
            "vehicleType": this.state.vehicleType,
            "vehicleSubType": this.state.vehicleSubType,
            "vehicleOwner": this.state.VehicleOwner,
            



        }
        console.log(dataSet)
        var res = await createVehicleDetail(dataSet);
        console.log("getAllVehicleDetails");
        console.log(res);
        if (res.success) {
            message.success('Create Successful!')
            console.log("awaaa")
            this.formRef.current.resetFields();

            this.setState({
                showModal: false,
                open: false,
                vehicleModel: '',
                vehicleBrand: '',
                vehicleReNum: '',
                vehicleSeatingCapacity: '',
                vehiclePaymentCategory: null,
                selectedTypeId: null,
                vehicleType: [],
                selectedVehicleSubTypeId: null,
                vehicleSubType: [],
                selectedVehicleOwnerId: null,
                VehicleOwner: [],
                searchVehicleReNo: '',
                vehicle_list: [],
                selectVehiclePaymentCategoryId: null,
                VehiclePayment: [],
            }, () => {
                this.getAllVehicle();
        this.getAllVehicleData();
            })



            // const { history } = this.props;
            // history.push("/site");
        } else {
            message.warning(res.data)
        }
    }

    render() {
        const { Option } = Select;

        const columns123 = [
            {
                title: "Vehicle Registation Number",
                dataIndex: "vehicleRegistationNumber",
                key: "vehicleRegistationNumber",
                // render: (item) => <span>{item}</span>,
                render: (item) => <span class="badge badge-outline-primary badge-pill">{item}</span>,
            },
            {
                title: "Active",
                dataIndex: "active",
                key: "active",
                render: (item) => <span>{item ? (<>
                    <Tag color="#03d87f" key={item}>
                        <span >Yes</span>
                    </Tag>
                </>) : (<>
                    <Tag color="#8a6d3b" key={item}>
                        <span >No</span>
                    </Tag>
                </>)}</span>,

            },
            
            {
                title: "Vehicle Owner",
                dataIndex: "vehicleOwner",
                key: "vehicleOwner",
                render: (item) => <span>{item?.name}</span>,
            },
            {
                title: "Vehicle Type",
                dataIndex: "vehicleType",
                key: "vehicleType",

                render: (item) => <>
                    <Tag color="gray" key={item}>
                        <span >{item?.name}</span>
                    </Tag>
                </>,

            },
            {
                title: "Vehicle Sub Type",
                dataIndex: "vehicleSubType",
                key: "vehicleSubType",
                render: (item) => <span>{item?.name}</span>,
            },
            {
                title: "Fixed/Pool",
                dataIndex: "vehicleCategory",
                key: "vehicleCategory",
                render: (item) => <span>{item?.id==51 ? (<>
                    <Tag color="#37893a" key={item}>
                        <span >Fixed</span>
                    </Tag>
                </>) : (<>
                    <Tag color="#6d81f5" key={item}>
                        <span >Pool</span>
                    </Tag>
                </>)}</span>,
            },
            {
                title: "Model",
                dataIndex: "model",
                key: "model",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Brand",
                dataIndex: "brand",
                key: "brand",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Seating Capacity",
                dataIndex: "seatingCapacity",
                key: "seatingCapacity",
                render: (item) => <span>{item}</span>,
            },

            {
                title: 'Action',

                key: 'x',
                width: '10px',
                render: (item) => <a href='#' onClick={() => this.updateVehicle(item.id)}><i data-feather="home" class="fa fa-pen"></i></a>,
            },

        ];
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            <div className="row">
                                <div class="col">
                                    <h4 class="page-title">Vehicle List </h4>
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Vehicle</a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">List </a></li>

                                    </ol>
                                </div>
                                <div className='col-md-2' style={{ display: "inline", justifyContent: "end", textAlign: 'end' }}>



                                    <Link to="#" onClick={() => this.onChangeCreateVehicle()} type="button" style={{ marginLeft: 10 }} class="btn btn-info">Create Vehicle</Link>




                                </div>
                                <div className='col-md-1'>



                                    <button type="button" style={{ marginLeft: 10 }} class="btn btn-danger" onClick={() => this.viewFilterClick()}><i class="fas fa-filter"></i></button>




                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Collapse isOpen={this.state.isOpen}>
                    <div className="row">
                        <div class="card">

                            <div class="card-body bootstrap-select-1">
                                <div class="row">

                                    <div class="col-md-3">
                                        <label class="mb-3">Vehicle Registation No</label>
                                        <input autoComplete="off" style={{ height: 40 }} type="text" value={this.state.site_name} className="form-control" name="site_name" id="site_name" placeholder=" Registation No" onChange={(e) => this.searchFormItemChange(e)} />
                                    </div>

                                </div>
                                <div class="col-md-3" style={{ marginTop: 10 }}>
                                    <button type="button" class="btn btn-primary" onClick={() => this.filterBtnClick()}> Filter</button>
                                    <button type="button" style={{ marginLeft: 5 }} class="btn btn-dark" onClick={() => this.clearBtnClick()}>Clear Filter</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Collapse>
                <div className='row'>
                    <>
                        <Table
                            size='small'
                            fontSize={9}
                            columns={columns123}
                            bordered
                            pagination={false}
                            loading={this.state.loading}
                            dataSource={this.state.vehicle_list}
                        />
                    </>


                </div>
                <br></br>
                <div class="row">

                    <div class=" col-md-12">
                        <div class="dataTables_paginate paging_simple_numbers" id="row_callback_paginate" style={{ display: "flex", textAlign: "end", justifyContent: "end" }}>
                            <ul class="pagination">
                                <li class="paginate_button page-item previous" id="row_callback_previous">
                                    <a onClick={() => this.previousBtnClick()} aria-controls="row_callback" data-dt-idx="0" tabindex="0" class="page-link" style={{ fontSize: 15 }}>Previous</a>
                                </li>
                                <li class="paginate_button page-item ">
                                    <a aria-controls="row_callback" data-dt-idx="1" tabindex="0" class="page-link" style={{ fontSize: 15 }}>{this.state.dataPageCount} / {this.state.selectPageNo + 1}
                                    </a>
                                </li>

                                <li class="paginate_button page-item next" id="row_callback_next">
                                    <a onClick={() => this.nextBtnClick()} aria-controls="row_callback" data-dt-idx="7" tabindex="0" class="page-link" style={{ fontSize: 15 }}>Next
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <Drawer title={"New Vehicle  "} width={"50%"} placement="right" onClose={() => this.onDrawerClose()} visible={this.state.open}>
                    <div className="row" >
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <Form
                                        ref={this.formRef}
                                    >



                                        <div className='row'>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Vehicle Registation Number  <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item name="Vehicle_Registation_Number" tooltip="This is a required field" rules={[{ required: true, message: 'Vehicle registation number required ' }, {
                                                    validator: (_, value) =>
                                                        !value.includes(" ")
                                                            ? Promise.resolve()
                                                            : Promise.reject(new Error("No spaces allowed"))
                                                }]}  >
                                                    <Input placeholder="Ex: KK-2222 / 250-2222" onInput={e => e.target.value = e.target.value.toUpperCase()} id="Vehicle_Registation_Number" onChange={this.handleVehicleRegistationNumber} />
                                                    {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                </Form.Item>


                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Vehicle Type  <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item name="Vehicle_Type" tooltip="This is a required field">
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeVehicleType}

                                                        // onChange={this.onChangeTowerType}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.vehicleDataType?.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}



                                                    </Select>


                                                </Form.Item>

                                                {/* <p style={{ color: "#f5325c" }}>{this.validator.message('Tower Type', this.state.selectedTowerTypeId, 'required')}</p> */}


                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Vehicle Sub Type  <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Is_active" tooltip="This is a required field">
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeVehicleSubType}

                                                        // onChange={this.onChangeTowerType}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.vehicleDataSubType?.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}
                                                        {/* return <Option value={65}>Test</Option> */}



                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Asset', this.state.selectedGeneratorAssetId, 'required')}</p>

                                                </Form.Item>

                                                {/* <p style={{ color: "#f5325c" }}>{this.validator.message('Tower Type', this.state.selectedTowerTypeId, 'required')}</p> */}


                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Vehicle Owner <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item required name="Is_active" tooltip="This is a required field">
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeVehicleOwner}

                                                        // onChange={this.onChangeTowerType}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.vehicleDataOwner?.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}




                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Asset', this.state.selectedGeneratorAssetId, 'required')}</p>

                                                </Form.Item>

                                                {/* <p style={{ color: "#f5325c" }}>{this.validator.message('Tower Type', this.state.selectedTowerTypeId, 'required')}</p> */}


                                            </div>





                                        </div>
                                        <div className='row'>


                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Model
                                                </label>
                                                <Form.Item name="vehicle_model" tooltip="This is a required field">
                                                    <Input id="vehicle_model" onChange={this.handleVehicleModel} />
                                                    {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                </Form.Item>

                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="dcdu">
                                                    Brand
                                                </label>
                                                <Form.Item name="vehicle_brand" tooltip="This is a required field">
                                                    <Input id="vehicle_model" onChange={this.handleVehicleBrand} />
                                                    {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                </Form.Item>

                                            </div>

                                        </div>
                                        <div className='row'>


                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Seating Capacity
                                                </label>
                                                <Form.Item  >
                                                    <InputNumber id='Seating_Capacity' style={{ width: '100%' }} onChange={this.handleSeatingCapacity} />
                                                </Form.Item>

                                            </div>


                                       




                                        </div>







                                    </Form>




                                    <div className='row' style={{ justifyContent: "end" }}>
                                        <div className='col-md-2' >   <button className="btn btn-primary" onClick={() => this.AllDataSubmit()} >Submit</button></div>


                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </Drawer>

                <Drawer title={"Edit Vehicle  "} width={"50%"} placement="right" onClose={() => this.onDrawerClose()} visible={this.state.openEdit}>
                    <div className="row" >
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <Form
                                        ref={this.formRef}
                                    >



                                        <div className='row'>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Vehicle Registation Number  <x style={{ color: "red" }}>*</x>
                                                </label>
                                                <Form.Item tooltip="This is a required field" rules={[{ required: true, message: 'Vehicle registation number required ' }, {
                                                    validator: (_, value) =>
                                                        !value.includes(" ")
                                                            ? Promise.resolve()
                                                            : Promise.reject(new Error("No spaces allowed"))
                                                }]}  >
                                                    <Input placeholder="Ex: KK-2222 / 250-2222" value={this.state.vehicleReNum} onInput={e => e.target.value = e.target.value.toUpperCase()} id="Vehicle_Registation_Number" onChange={this.handleVehicleRegistationNumber} />
                                                    {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                </Form.Item>


                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Vehicle Type  <x style={{ color: "red" }}>*</x>
                                                </label>
                                                {this.state.selectedTypeId != null ? (<Form.Item tooltip="This is a required field">
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeVehicleType}
                                                        defaultValue={this.state.selectedTypeId}

                                                        // onChange={this.onChangeTowerType}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.vehicleDataType?.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}



                                                    </Select>


                                                </Form.Item>) : ('')}

                                                {/* <p style={{ color: "#f5325c" }}>{this.validator.message('Tower Type', this.state.selectedTowerTypeId, 'required')}</p> */}


                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Vehicle Sub Type  <x style={{ color: "red" }}>*</x>
                                                </label>
                                                {this.state.selectedVehicleSubTypeId != null ? (<Form.Item required tooltip="This is a required field">
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeVehicleSubType}
                                                        defaultValue={this.state.selectedVehicleSubTypeId}
                                                        // onChange={this.onChangeTowerType}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.vehicleDataSubType?.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}
                                                        {/* return <Option value={65}>Test</Option> */}



                                                    </Select>


                                                </Form.Item>) : ('')}

                                                {/* <p style={{ color: "#f5325c" }}>{this.validator.message('Tower Type', this.state.selectedTowerTypeId, 'required')}</p> */}


                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Vehicle Owner <x style={{ color: "red" }}>*</x>
                                                </label>
                                                {this.state.selectedVehicleOwnerId != null ? (<Form.Item required tooltip="This is a required field">
                                                    <Select
                                                        showSearch
                                                        onChange={this.onChangeVehicleOwner}
                                                        defaultValue={this.state.selectedVehicleOwnerId}

                                                        // onChange={this.onChangeTowerType}

                                                        style={{ width: "100%" }}
                                                        placeholder="Search to Select"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                        }
                                                    >
                                                        {this.state.vehicleDataOwner?.map((item, index) => {
                                                            return <Option value={item.id}>{item.name}</Option>;
                                                        })}




                                                    </Select>
                                                    <p style={{ color: "#f5325c" }}>{this.validator.message('Asset', this.state.selectedGeneratorAssetId, 'required')}</p>

                                                </Form.Item>) : ('')}

                                                {/* <p style={{ color: "#f5325c" }}>{this.validator.message('Tower Type', this.state.selectedTowerTypeId, 'required')}</p> */}


                                            </div>





                                        </div>
                                        <div className='row'>


                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Model
                                                </label>
                                                <Form.Item tooltip="This is a required field">
                                                    <Input id="vehicle_model" value={this.state.vehicleModel} onChange={this.handleVehicleModel} />
                                                    {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                </Form.Item>

                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-label" for="dcdu">
                                                    Brand
                                                </label>
                                                <Form.Item tooltip="This is a required field">
                                                    <Input id="vehicle_model" value={this.state.vehicleBrand} onChange={this.handleVehicleBrand} />
                                                    {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                </Form.Item>

                                            </div>

                                        </div>
                                        <div className='row'>


                                            <div className="col-md-6">
                                                <label className="form-label" for="depot">
                                                    Seating Capacity
                                                </label>
                                                <Form.Item>
                                                    <InputNumber value={this.state.vehicleSeatingCapacity} id='Seating_Capacity' style={{ width: '100%' }} onChange={this.handleSeatingCapacity} />
                                                </Form.Item>

                                            </div>


                                            
                                            <div className='col-md-6'>
                                                <label className="form-label" for="depot">
                                                    Vehicle Active
                                                </label>

                                                {this.state.vehicleActive != null ? (
                                                    <Form.Item >
                                                        <Select
                                                            showSearch
                                                            onChange={this.onChangevehicleActive}
                                                            defaultValue={(this.state.vehicleActive).toString()}
                                                            //  defaultValue={this.state.isSiteKeyRequired}

                                                            style={{ width: "100%" }}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >
                                                            <Option value='1'>Yes</Option>
                                                            <Option value='0'>No</Option>

                                                        </Select>
                                                    </Form.Item>) : ('')}
                                            </div>



                                        </div>
                                






                                    </Form>




                                    <div className='row' style={{ justifyContent: "end" }}>
                                        <div className='col-md-2' >   <button className="btn btn-primary" onClick={() => this.AllDataSubmitUpdate()} >Submit</button></div>


                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </Drawer>

            </div>
        )
    }
}
export default Index
