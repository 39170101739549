import React, { useState, useEffect } from 'react';
import EditHistory from './access-history';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import {
    SyncOutlined,
    ClockCircleOutlined,
    KeyOutlined,
    SnippetsOutlined,
    DownloadOutlined,
    MenuFoldOutlined,
    MoreOutlined,
    AimOutlined,
    CarryOutOutlined,
    EyeOutlined
} from '@ant-design/icons';
import { Menu, Divider, Space, Avatar, Card, Skeleton, Switch, Table, Select, Input, Button, Row, Upload, Col, Dropdown, Tag, Modal, Steps, message, Form, DatePicker, TimePicker, Descriptions, Image, Radio, Checkbox, spin, Spin } from 'antd';
import {
    getSiteAccessRequestByIDREWAMP,
    downloadSiteAccessRequest,
    getFilteredDepotUsersDataREWAMP,
} from "../../../api/index";
import RequestInfo from './components/requestInfo';
import FilterForm from './components/filterForm';
const { Option } = Select;
const baseImgURL = "https://storage.googleapis.com/dns_storage_v2/dnsrevampimages/";

const AcessRequestOverviewDO = () => {

    const [requestType, setRequestType] = useState("RUNNING");
    const [requestData, setRequestData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [requestInfo, setRequestInfo] = useState([]);
    const [openInformationModal, setOpenInformationModal] = useState(false);
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [stillDownloading, setStillDownloading] = useState(false);
    const [editHistoryView, setEditHistoryView] = useState(false);
    const [editHistory, setEditHistory] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const requestJson = {
                regionIds: [],
                depotIds: [],
                requestTypeEnums: [],
                subRequestTypeIds: [],
                requestCategoryIds: [],
                timeFrame: null,
                filterType: requestType,
                statuses: [],
                siteId: null,
                bulkId: null,
                creationType: 'ALL',
                isEditOptionEnabled: false,
            }
            const response = await fetchAllData(requestJson);
            if (!response) {
                message.error("An error occurred while fetching data.");
            }
        };
        fetchData();
    }, [requestType]);

    const formatStartDate = (dateString) => {
        if (!dateString) return null;
        let date = new Date(dateString);
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}T00:00:00.000Z`;
    }

    const formatEndDate = (dateString) => {
        if (!dateString) return null;
        let date = new Date(dateString);
        date.setDate(date.getDate() + 1);
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}T00:00:00.000Z`;
    }

    const formateJsonInput = (value) => {
        const requestJson = {
            regionIds: value?.region ? value?.region : null,
            depotIds: value?.depot ? value?.depot : null,
            requestTypeEnums: value?.type ? value?.type : null,
            subRequestTypeIds: value?.subType ? value?.subType : null,
            requestCategoryIds: value?.category ? value?.category : null,
            initiatorGroupId: null,
            handlerGroupId: null,
            timeFrame: value?.date ? value?.date : null,
            filterType: requestType === "RUNNING" ? "RUNNING" : "COMPLETED",
            statuses: value?.step ? value?.step : null,
            siteIds: value?.siteID ? value?.siteID : null,
            bulkIds: value?.bulkIDs ? value?.bulkIDs : null,
            creationType: 'ALL',
            startDate: value?.customDate ? formatStartDate(value?.customDate[0]) : null,
            endDate: value?.customDate ? formatEndDate(value?.customDate[1]) : null,
            isEditOptionEnabled: false,
        }
        fetchAllData(requestJson);
    }

    const fetchAllData = async (requestJson) => {
        setRequestData([]);
        setLoading(true);
        try {
            const firstResponse = await getFilteredDepotUsersDataREWAMP(20, 1, requestJson);
            if (!firstResponse.success) {
                message.error(firstResponse?.data?.errors[0]?.errorMessage);
                return false;
            }
            const pageCount = firstResponse?.data?.results?.totalPages;
            const dataArr = [...firstResponse?.data?.results?.data];
            for (let i = 2; i <= pageCount; i++) {
                const response = await getFilteredDepotUsersDataREWAMP(20, i, requestJson);
                if (!response.success) {
                    message.error(response?.data?.errors[0]?.errorMessage);
                    return false;
                }
                dataArr.push(...response?.data?.results?.data);
            }
            if (requestJson.filterType === "RUNNING") {
                setRequestData(dataArr);
            } else {
                setRequestData(dataArr);
            }
            setLoading(false);
            setOpenFilterModal(false);
            return true;
        } catch (error) {
            message.error("An error occurred while fetching data.");
            setLoading(false);
            setOpenFilterModal(false);
            return false;
        }
    };

    const fetchSiteAccessRequestByID = async (id) => {
        setLoading(true);
        var responseAPI = await getSiteAccessRequestByIDREWAMP(id);
        if (responseAPI.success) {
            setRequestInfo(responseAPI?.data?.results);
            setOpenInformationModal(true);
        } else {
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
        setLoading(false);
    }

    const columns = [
        {
            title: <span className="textStyle">Request ID</span>,
            dataIndex: 'requestId',
            key: 'requestId',
            render: (text, record) => (
                <span
                    className="textStyles-small" style={{ fontWeight: 500, cursor: 'pointer' }}
                    onClick={() => fetchSiteAccessRequestByID(record?.id)}
                >
                    {text}
                </span>
            ),
        },
        {
            title: <span className="textStyle">Type</span>,
            dataIndex: 'instanceType',
            key: 'instanceType',
            render: (text) => {
                let color = 'red';
                let displayText = 'N/A';

                if (text) {
                    if (text === 'BULK') {
                        color = 'orange';
                        displayText = 'BULK';
                    } else {
                        color = 'green';
                        displayText = 'SINGLE';
                    }
                }

                return (
                    <Tag
                        color={color}
                        style={{
                            borderColor: 'transparent',
                            padding: 5,
                            borderRadius: 8
                        }}
                    >
                        <span
                            className="textStyles-small"
                            style={{
                                fontWeight: 550,
                                fontSize: '12px'
                            }}
                        >
                            {displayText}
                        </span>
                    </Tag>
                );
            },
        },
        {
            title: <span className="textStyle">Site ID</span>,
            dataIndex: 'siteId',
            key: 'siteId',
            render: text => <span className="textStyles-small" style={{ fontWeight: 500 }}>{text}</span>,
        },
        {
            title: <span className="textStyle">Site Name</span>,
            dataIndex: 'siteName',
            key: 'siteName',
            render: text => <span className="textStyles-small" style={{ fontWeight: 500 }}>{text?.replace(/_/g, ' ')}</span>,
        },
        {
            title: <span className="textStyle">Permission Start Date & Time</span>,
            dataIndex: 'permissionStartDateTime',
            key: 'permissionStartDateTime',
            render: text => {
                return text ? (
                    (() => {
                        const dateObject = new Date(text);
                        const options = {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                        };

                        const formattedDate = dateObject.toLocaleString(undefined, options);

                        return <span className="textStyles-small" style={{ fontWeight: 500 }}>{formattedDate}</span>;
                    })()
                ) : (
                    <span className="textStyles-small" style={{ fontWeight: 500 }}>
                        N/A
                    </span>
                );
            }
        },
        {
            title: <span className="textStyle">Permission End Date</span>,
            dataIndex: 'permissionEndDate',
            key: 'permissionEndDate',
            render: text => {
                return text ? (
                    (() => {
                        const dateObject = new Date(text);
                        const options = {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                        };

                        const formattedDate = dateObject.toLocaleString(undefined, options);

                        return <span className="textStyles-small" style={{ fontWeight: 500 }}>{formattedDate}</span>;
                    })()
                ) : (
                    <span className="textStyles-small" style={{ fontWeight: 500 }}>
                        N/A
                    </span>
                );
            }
        },
        {
            title: <span className="textStyle">Request Status</span>,
            dataIndex: 'requestStatus',
            key: 'requestStatus',
            render: (text) => {
                let color = 'red';
                let displayText = 'UNKNOWN';
                if (!text) {
                    return <span className="textStyleChilds">N/A</span>;
                }
                if (text === 'ON_GOING') {
                    color = 'blue';
                    displayText = 'On Going';
                }
                else if (text === 'COMPLETED') {
                    color = 'green';
                    displayText = 'Completed';
                }
                else if (text === 'CANCELLED') {
                    color = 'pink';
                    displayText = 'Cancelled';
                }
                return (
                    <Tag
                        color={color}
                        style={{
                            borderColor: 'transparent',
                            padding: 5,
                            borderRadius: 8
                        }}
                    >
                        <span
                            style={{
                                fontWeight: 550,
                                fontSize: '12px'
                            }}
                            className="textStyles-small"
                        >
                            {displayText}
                        </span>
                    </Tag>
                );
            }
        },
        {
            title: <span className="textStyle">Request Approval Status</span>,
            dataIndex: 'requestApprovalStatus',
            key: 'requestApprovalStatus',
            render: (text) => {
                let color = 'blue';
                let displayText = 'Approved';
                if (!text) {
                    return <span className="textStyleChilds">N/A</span>;
                }
                if (text === 'PENDING_APPROVAL') {
                    color = 'orange';
                    displayText = 'Pending Approval';
                }
                else if (text === 'PENDING_APPROVAL_SITE_OWNER') {
                    color = 'purple';
                    displayText = 'Pending With Site Owner';
                }
                else if (text === 'REJECTED') {
                    color = 'red';
                    displayText = 'Rejected';
                }
                else if (text === 'CANCELLED') {
                    color = 'pink';
                    displayText = 'Cancelled';
                }

                return (
                    <Tag
                        color={color}
                        style={{
                            borderColor: 'transparent',
                            padding: 5,
                            borderRadius: 8
                        }}
                    >
                        <span
                            style={{
                                fontWeight: 550,
                                fontSize: '12px'
                            }}
                            className="textStyles-small"
                        >
                            {displayText}
                        </span>
                    </Tag>
                );
            }
        },
        {
            title: <span className="textStyle">Actions</span>,
            key: 'id',
            dataIndex: 'id',
            render: (text, record) => {
                const menu = (
                    <Menu>
                        <Menu.Item key="0" icon={<EyeOutlined />} onClick={() => fetchSiteAccessRequestByID(text)}>
                            <span className='textStyle-small'> View Request</span>
                        </Menu.Item>
                        {
                            record?.updatedAccessReport === null ? (
                                <Menu.Item key="1" icon={<SnippetsOutlined />} onClick={() => downloadAccessReport(record?.id)}>
                                    <span className='textStyle-small'> Download Access Letter</span>
                                </Menu.Item>
                            ) : (
                                <Menu.Item key="1" icon={<SnippetsOutlined />}>
                                    <a href={`${baseImgURL}${record?.updatedAccessReport}`} target="_blank" rel="noopener noreferrer" download>
                                        <span className='textStyle-small'> Download Replaced Access Letter</span>
                                    </a>
                                </Menu.Item>
                            )
                        }

                        <Menu.Item key="2" icon={<DownloadOutlined />} onClick={() => fetchDocLinksSiteAccessRequestByID(text)}>
                            <span className='textStyle-small'>Uploaded Documents</span>
                        </Menu.Item>

                        <Menu.Item key="3" icon={<ClockCircleOutlined />} onClick={() => {
                            setEditHistory(record.id);
                            setEditHistoryView(true)
                        }}>
                            <span className='textStyle-small'> View Edit History</span>
                        </Menu.Item>
                    </Menu>
                );

                return (
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Dropdown overlay={menu} trigger={['click']}>
                            <Button shape='circle' icon={<MoreOutlined />} size='small' />
                        </Dropdown>
                    </div>
                );
            },
        }
    ];

    const fetchDocLinksSiteAccessRequestByID = async (id) => {
        var responseAPI = await getSiteAccessRequestByIDREWAMP(id);
        if (responseAPI.success) {
            const urlOne = responseAPI?.data?.results?.initiatorFileOne ? baseImgURL + responseAPI?.data?.results?.initiatorFileOne : null;
            const urlTwo = responseAPI?.data?.results?.initiatorFileTwo ? baseImgURL + responseAPI?.data?.results?.initiatorFileTwo : null;
            const urlThree = responseAPI?.data?.results?.initiatorFileThree ? baseImgURL + responseAPI?.data?.results?.initiatorFileThree : null;
            const urlFour = responseAPI?.data?.results?.initiatorFileFour ? baseImgURL + responseAPI?.data?.results?.initiatorFileFour : null;
            const urlFive = responseAPI?.data?.results?.initiatorFileFive ? baseImgURL + responseAPI?.data?.results?.initiatorFileFive : null;
            const links = [
                urlOne,
                urlTwo,
                urlThree,
                urlFour,
                urlFive
            ];
            if (links.every(link => link === null)) {
                message.info(<span className='textStyle-small'>No documents uploaded for this request</span>, 3);
            } else {
                let downloadCount = 0;
                links.forEach((link, index) => {
                    if (link !== null) {
                        setStillDownloading(true);
                        setTimeout(() => {
                            downloadFiles(link);
                            downloadCount++;
                            if (downloadCount === links.filter(link => link !== null).length) {
                                setStillDownloading(false);
                            }
                        }, index * 2000);
                    }
                });
            }
        } else {
            message.error('Error In Fetching API Request : ' + responseAPI.message);
            setStillDownloading(false);
        }
    };


    const downloadAccessReport = async (accessReqID) => {
        const response = await downloadSiteAccessRequest(accessReqID);
        if (response) {
            downloadFiles(response);
        } else {
            message.error(<span className='textStyle-small'>Error Downloading Report</span>, 5);
        }
    }

    const downloadFiles = (url) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = '';
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    return (
        <div>
            <div className="row" style={{ paddingTop: 10, paddingLeft: 20 }}>
                <div className="col-sm-12">
                    <div className="page-title-box">
                        <div className="row">
                            <div className="col">
                                <h4 style={{ fontWeight: "bold", fontSize: 16 }} className="textStyles-small" >
                                    DO View
                                </h4>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a style={{ fontSize: 13 }} className="textStyles-small">Site Access </a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a style={{ fontSize: 13 }} className="textStyles-small">Do View</a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div style={{ padding: 20, backgroundColor: 'white', marginTop: 10, borderRadius: 10 }}>
                <Row span={24}>
                    <Col span={12}>
                        {
                            requestType === "RUNNING" ? (
                                <span className="textStyles-small" style={{ fontWeight: 500, fontSize: 20 }}>
                                    Pending Requests
                                </span>
                            ) : (
                                <span className="textStyles-small" style={{ fontWeight: 500, fontSize: 20 }}>
                                    Completed Requests
                                </span>
                            )
                        }
                    </Col>
                    <Col span={12}>
                        <Button
                            type="primary"
                            style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '10px', width: '100px', height: '36px', marginLeft: 5, float: 'right' }}
                            onClick={() => setOpenFilterModal(true)}
                        >
                            <span className="textStyles-small" style={{ fontWeight: 500, fontSize: 14 }}>Filter</span>
                        </Button>
                        <Select
                            placeholder="Select Request Type"
                            style={{ width: 200, float: 'right', height: 36, borderRadius: 10 }}
                            bordered={false}
                            className='borderedSelect'
                            defaultValue={requestType}
                            onChange={(value) => setRequestType(value)}
                        >
                            <Option value="RUNNING" className="textStyles-small" >PENDING</Option>
                            <Option value="COMPLETED" className="textStyles-small" >COMPLETED</Option>
                        </Select>
                    </Col>
                </Row>
                <Table
                    className="table-striped-rows textStyles-small"
                    columns={columns}
                    dataSource={requestData}
                    pagination={requestData.length > 10 ? { pageSize: 10 } : false}
                    loading={loading}
                    style={{ marginTop: 20 }}
                // onRow={(record, rowIndex) => ({
                //     onClick: (event) => {
                //         fetchSiteAccessRequestByID(record?.id);
                //     }
                // })}
                />
            </div>

            <Modal
                title={<span style={{ fontSize: 20, fontWeight: 600 }} className="textStyles-small">Request Info</span>}
                visible={openInformationModal}
                onCancel={() => setOpenInformationModal(false)}
                footer={null}
                width={900}
            >
                <RequestInfo reqFormData={requestInfo} />
            </Modal>

            <Modal
                title={<span style={{ fontSize: 20, fontWeight: 600 }} className="textStyles-small">Filter By</span>}
                visible={openFilterModal}
                onCancel={() => setOpenFilterModal(false)}
                width={400}
                footer={null}
                header={null}
                style={{
                    position: 'fixed',
                    top: 20,
                    right: 20,
                    overflow: 'auto',
                }}
            >
                <FilterForm jobType={requestType} returnData={formateJsonInput} />
            </Modal>

            <Modal
                visible={stillDownloading}
                width={500}
                footer={null}
                destroyOnClose={true}
                closable={false}
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <SyncOutlined spin style={{ fontSize: '80px', color: 'blue' }} />
                    <h3 className='textStyle-small' style={{ marginTop: '15px', fontSize: '14px' }}>
                        Your file is being prepared for download. Please wait...
                    </h3>
                </div>
            </Modal>

            <Modal
                title={<span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "15px" }}>Request Edit History</span>}
                visible={editHistoryView}
                onOk={() => {
                    setEditHistoryView(false);
                    setEditHistory(null);
                }}
                onCancel={() => {
                    setEditHistoryView(false);
                    setEditHistory(null);
                }}
                width={870}
                height={410}
                footer={null}
                destroyOnClose={true}
            >
                <div style={{
                    borderColor: 'white', backgroundColor: 'white', width: '100%', height: '410px',
                    marginTop: '10px', border: 'white', borderRadius: 8
                }}>
                    <EditHistory key={editHistory} siteAccessRequestID={editHistory} />
                </div>
            </Modal>
        </div>
    );
}

export default AcessRequestOverviewDO;