import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getRoutingObservationAll, routingObservationCreate, routingObservationDetails, UpdateroutingObservation } from '../../../../api/index';
import { Table, Tag, Button, Drawer, Form, Input, Select, InputNumber, message, Popconfirm, DatePicker } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { async } from 'q';
import AssetDelete from "../../../../components/site_components/asset_delete";

class index extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();

        this.state = {
            allData: null,
            tabaleLoading: false,
            AssetManagementPermission: true,
            name: '',
            observationId: null


        }

    }

    formItemChange = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
        //console.log(obj)
    }
    editObservation = async () => {
        if (this.validator.allValid()) {

            var data = {
                "id": this.state.observationId,
                "name": this.state.name,

            }

            var res = await UpdateroutingObservation(data);
            if (res.success) {
                message.success('Successfully Updated!')
                this.setState({
                    name: '',
                    observationId: null,
                    open: false,



                })
                this.getRoutingObservationAll();


            } else {
                message.warning(' Something went wrong!')
                return
            }




        }
    }
    createObservation = async () => {
        if (this.validator.allValid()) {
            var data = {
                "name": this.state.name,

            }

            var res = await routingObservationCreate(data);
            console.log(res)
            if (res.success) {

                message.success('Successfully Created!')
                this.setState({
                    name: '',
                })
                this.getRoutingObservationAll();

            } else {
                message.warning(' Something went wrong!')
                return
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }




    }
    observationGetById = async (id) => {


        var res = await routingObservationDetails(id);
        if (res.success) {
            this.setState({
                open: true,
                name: res.data.name,
                observationId: res.data.id
            })

        } else {
            message.warning(' Something went wrong!')
            return
        }

        console.log(res)


    }

    getRoutingObservationAll = async () => {
        this.setState({
            tabaleLoading: true
        })
        var res = await getRoutingObservationAll();
        if (res.success) {
            this.setState({
                allData: res.data
            }, () => {
                this.setState({
                    tabaleLoading: false
                })
            })



        } else {
            message.warning(' Something went wrong!')
            this.setState({
                tabaleLoading: false
            })
            return

        }
    }



    componentDidMount() {
        this.getRoutingObservationAll();
    }
    submitForm = async (item) => {
        this.getDataDetailsForDelete(item);

    }
    getDataDetailsForDelete = async (id) => {

        var res = await routingObservationDetails(id);
        console.log(res);
        if (res.success) {
            var respons = AssetDelete.Action(res.data, res.data.id, UpdateroutingObservation)

            respons.then((value) => {
                if (value == 1) {
                    message.success('Successfully Delete!')
                  this.getRoutingObservationAll();
                } else {
                    message.warning(' Something went wrong!')
                }


            });



        }
    }
    onDrawerClose = () => {
        console.log('sddsds')
        

        this.setState({
            open: false,
            name: '',
            observationId: null

        })

    }



    render() {
        const columns2 = [
            {
                title: "Id",

                dataIndex: "id",
                key: "id",
                fixed: 'left',

                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,


            },
            {
                title: "Name",
                dataIndex: "name",
                key: "name",

                fixed: 'left',
                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,

            },



            {

                dataIndex: "id",
                key: "id",
                width: '50px',

                fixed: 'right',

                render: (item) => <>
                    {this.state.AssetManagementPermission ? (<a style={{ color: 'blue' }} onClick={() => this.observationGetById(item)}><i className="las la-pen font-15"></i></a>) : ('')}
                    <Popconfirm
                     placement="topRight"
                        title="Are You Sure?"
                        description="Are you sure to delete this task?"
                        onConfirm={() => this.submitForm(item)}
                        okText="Yes"
                        cancelText="No"
                    >
                        {this.state.AssetManagementPermission ? (<a style={{ color: 'blue' }}><i className="las la-trash-alt font-15"></i></a>) : ('')}
                    </Popconfirm> </>,

            },









        ];
        return (
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="page-title-box">
                            <div class="row">
                                <div class="col">
                                    <h4 class="page-title">Observation</h4>
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Admin</a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Observation</a></li>

                                    </ol>
                                </div>
                                <div class="col-auto align-self-center">
                                    <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-5">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Create Observation</h4>
                                {/* <p className="text-muted mb-0">Basic example to demonstrate Bootstrap’s form styles.</p>  */}
                            </div>
                            <div className="card-body">
                                <form>
                                    <div className="mb-3">
                                        <label className="form-label" for="exampleInputEmail1">Name</label> <x style={{ color: "red" }}>*</x>
                                        <input autoComplete="off" type="text" className="form-control" id="exampleInputEmail1" onChange={(e) => this.formItemChange(e)} name="name" value={this.state.name} aria-describedby="emailHelp" placeholder="Name" />
                                        <p style={{ color: "#f5325c" }}>{this.validator.message('name', this.state.name, 'required')}</p>
                                    </div>

                                </form>
                                <button className="btn btn-primary" onClick={() => this.createObservation()}>Submit</button>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Observations</h4>
                                {/* <p className="text-muted mb-0">
                                        Use <code>.table-striped</code> to add zebra-striping to any table row
                                        within the <code>&lt;tbody&gt;</code>.
                                    </p> */}
                            </div>
                            <div className="card-body">
                                <Table
                                    size="small"
                                    columns={columns2}
                                    // loading={this.state.loading && this.state.rectifierloading && this.state.batteryBanksloading && this.state.airConditionersloading && this.state.spdsloading && this.state.cabinetsloading && this.state.generatorsloading && this.state.atsesloading && this.state.dcdusloading && this.state.fcbsloading && this.state.sharedOutOperatorsesloading}
                                    loading={this.state.tabaleLoading}
                                    dataSource={this.state.allData}

                                // dataSource={this.state.filteredTable == null ? this.state.allRectifiersModel?.reverse() : this.state.filteredTable?.reverse()}

                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Drawer title={'Edit Data'} width={"60%"} placement="right" onClose={() => this.onDrawerClose()} visible={this.state.open}>
                    <div className="row" >
                        <div className="col-lg-12">
                            <div className="card">

                                <div className="card-body">
                                    <form  >
                                        <div className="mb-3">
                                            <label className="form-label" for="exampleInputEmail1">Name</label> <x style={{ color: "red" }}>*</x>
                                            <input autoComplete="off" type="text" className="form-control" id="exampleInputEmail1" onChange={(e) => this.formItemChange(e)} name="name" value={this.state.name} aria-describedby="emailHelp" placeholder="Name" />
                                            <p style={{ color: "#f5325c" }}>{this.validator.message('name', this.state.name, 'required')}</p>
                                        </div>

                                    </form>
                                    <button className="btn btn-primary" onClick={() => this.editObservation()}>Submit</button>






                                </div>

                            </div>
                        </div>
                    </div>
                </Drawer>

            </div>
        );
    }
}

index.propTypes = {

};

export default index;