import React from 'react';
import { Select, DatePicker, Table,message } from 'antd';
import { connect } from "react-redux";
import { getDonationCategories, getData } from "../actions/index";


import FullMap from "../components/vector-map";
import { Card, VehicleCard, VehicaleCell, LocationContainer, LocationContainer2 } from "../components/cards";
import { DataContext, withContext } from "../context"
import { TickClock, StillClock } from "../components/clocks";
import { CardContainer } from "../components/essentials/containers";
import { Flex, Box } from "rebass"
import { FiToggleLeft, FiToggleRight, FiMapPin, FiNavigation, FiCalendar } from 'react-icons/fi';
import { pgStatusCount } from "../api/index";

import { Loading } from "../components/essentials/alerts"
import { LoginModal } from "../components/essentials/modals"
import _ from "lodash";
import './all-pg-live.css';
import { Collapse } from 'reactstrap';
import markers from "../../markers.config"

const { Option } = Select;

var colors = ["#f1f1f1", "#f1f1f1", "#f14d4d", "#60ab62", "#ff9c10", "#ff9c10", "#ff9c10", "#f1f1f1", "#f1f1f1", "#f14d4d", "#60ab62", "#ff9c10", "#ff9c10", "#ff9c10", "#f1f1f1", "#f1f1f1", "#f14d4d", "#60ab62", "#ff9c10", "#ff9c10", "#ff9c10", "#f1f1f1", "#f1f1f1", "#f14d4d", "#60ab62", "#ff9c10", "#ff9c10", "#ff9c10"]
const columns = [
  {
    title: 'Region',
    dataIndex: 'name',

  },
  {
    title: 'Pending For Installation',

    dataIndex: 'pendingForInstallation',
    align: 'center',

  },
  {
    title: 'Installed',
    dataIndex: 'installed',
    align: 'center',

  },
  {
    title: 'Remove Requested',
    dataIndex: 'removeRequested',
    align: 'center',

  },
  {
    title: 'Removed',
    dataIndex: 'removed',
    align: 'center',

  },
  {
    title: 'Cancel',
    dataIndex: 'cancel',
    align: 'center',

  },
];
class DashboardPgStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curTime: "",
      data: [],
      depot_list: [],
      SubRegion_list: [],

      isLight: true,
      assignedDockCount: "",
      dockCount: "",
      isOpen: false,
      filterDate: "",
      filterEndDate: "",
      filterToday: "",
      filterRegion: [],
      filterDepot: [],
      regionName: "",
      DepotName: "",
      headerName: "",
      dataLoading: false,
      stats: {
        total_req: 0,
        delayed_allo: 0
      },
    }
  }

  componentDidMount() {
    //console.log(this.state.data);
    this.setState({
      depot_list: this.props.depots,
      SubRegion_list: this.props.regions.filter((item) => {
        return item.isSubRegion == 1;
      }),
    });
    this.filterBtnClick();

    this.interval = setInterval(() => this.filterBtnClick(), 30000) //30000
  }
  componentWillUnmount() {
    clearInterval(this.interval)
  }
  counter({ data }) {
    let total_req = 0;
    let delayed_allo = 0;
    let countpg = 0;

    //console.log("dayee");
    //console.log(data);



    total_req = data.length;
    data.map((data) => {
      if (data.status == "Completed") {
        ++countpg;

      }

    });
    delayed_allo = countpg;
    return {
      total_req,
      delayed_allo
    }
  }
  createDataWithPins({ data }) {
    //console.log("ddddddd")
    //console.log(data)
    const arrayResult = Object.keys(data).map(room => {
      return { name: room, cancel: data[room].cancel, installed: data[room].installed, pendingForInstallation: data[room].pendingForInstallation, removed: data[room].removed, removeRequested: data[room].removeRequested }
    });
    // data.data.final_card_data,
    return arrayResult.map(e => ({
      ...e,
      ..._.find(markers, { name: e.name })
    }));
  }
  viewFilterClick = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }
  filterBtnClick = async () => {
    this.setState({
      dataLoading: true
    })
    var form = new FormData();
    var myCurrentDate = new Date();
    var date = myCurrentDate.getFullYear() + '-' + (myCurrentDate.getMonth() + 1) + '-' + myCurrentDate.getDate();


    if (this.state.filterDate == "") {

      form.append("RequestDateTo", date);
      form.append("RequestDateFrom", date);
      this.setState({
        filterDate: date,
        filterEndDate: date
      })

    } else {
      form.append("RequestDateTo", this.state.filterEndDate);
      form.append("RequestDateFrom", this.state.filterDate);
    }



    //console.log(form);

    var res = await pgStatusCount(form);
    //console.log(res);

    if (res.success) {

      //console.log("fist");
      //console.log(this.createDataWithPins(res));

      this.setState({

        curTime: new Date(this.state.filterDate).toDateString(),
        //  headerName:this.state.regionName || this.state.DepotName?(this.state.regionName+' / '+this.state.DepotName):("All Sites"),
        // stats: this.counter(res),
        data: this.createDataWithPins(res),
        //  pins: this.getPins(this.state.full_data_set),
        isLoading: false,
        dataLoading: false

      })
    }else{
      message.warning(res?.data?.message +'    '+ res?.data?.error )

    }
  }
  onChangeDate = (date, dateString) => {
    //console.log(date);
    //console.log(dateString);
    this.setState({
      filterDate: dateString,
    });
  };
  onChangeDate2 = (date, dateString) => {
    //console.log(date);
    //console.log(dateString);
    this.setState({
      filterEndDate: dateString,
    });
  };
  onChangeRegion = (value) => {
    //console.log("onChangeRegion");
    //console.log(value);
    const dataa = this.props.regions.filter((item) => {
      return item;
    });


    this.setState({
      regionName: dataa[value - 1]?.name,
      // filterDepot: "",
      DepotName: "",
      filterRegion: value,

      SubRegion_list: this.props.regions.filter(alldate => value.map((n) => n).includes(alldate.parentRegion?.id))

    });
  };
  onChangeSubRegion = (value) => {
    //console.log("onChangeSubRegion");
    //console.log(value);
    const dataa = this.props.regions.filter((item) => {
      return item;
    });


    this.setState({
      regionName: dataa[value - 1]?.name,
      // filterDepot:"",
      DepotName: "",
      filterSubRegion: value,

      depot_list: this.props.depots.filter(alldate => value.map((n) => n).includes(alldate.region?.id))

    });
  };
  onChangeDepot = (value) => {
    //console.log("onChangeDepot");
    //console.log();
    const dataa = this.props.depots.filter((item) => {
      return item;
    });
    //    //console.log(dataa[value-1].name);

    this.setState({

      DepotName: dataa[value - 1]?.name,
      filterDepot: value,
    });
  };
  clearBtnClick = () => {
    window.location.reload()
  }

  VehicaleCellContext = (_data) => {

    const { data, color, name } = _data;

    return (
      <div id="box">
        <div id="boxData" style={{ width: "12%", borderColor: colors[data[0].statusId], borderStyle: "solid", borderWidth: 1, background: colors[data[0].statusId] }}>
          <h5 style={{ fontSize: 12, marginTop: 17 }}> {data[0].siteName}</h5>

        </div>

        <div id="boxData" style={{ width: "86%", borderColor: colors[data[0].statusId], borderStyle: "solid", borderWidth: 1, borderBottomLeftRadius: 0, borderTopLeftRadius: 0, borderTopRightRadius: 10, borderBottomRightRadius: 10, }}>

          <Flex pt={0} pr={30} pb={0} pl={2} alignItems="center" className="card-vehicle-container-header">
            <Flex bold={true} style={{ height: "100%", textAlign: "left" }} width={1 / 3} alignItems="center"> {data[0].status == null ? <i>"N/A"</i> : data[0].status}</Flex>
            <Flex style={{ fontWeight: 10, height: "100%", display: "grid" }} width={1 / 3} alignItems="center"> {data[0].VehicleNumber == null ? <i>"N/A"</i> : data[0].VehicleNumber}</Flex>
            <Flex style={{ fontWeight: 10, height: "100%", display: "grid" }} width={1 / 2} alignItems="center"> {data[0].requestDate == null ? <i>"N/A"</i> : data[0].requestDate + ':' + data[0].requestTime}</Flex>
            {data[0].isSLABreach == false ? (<Flex style={{ height: "90%", display: "grid", backgroundColor: "#008000a6", borderRadius: 20, color: "#ffffff" }} width={1 / 8} alignItems="center">{data[0].isSLABreach == false ? <x>Yes</x> : <x>No</x>} </Flex>) : (<Flex style={{ height: "90%", display: "grid", backgroundColor: "#ff000094", borderRadius: 20, color: "#ffffff" }} width={1 / 8} alignItems="center">{data[0].isSLABreach == false ? <x>Yes</x> : <x>No</x>} </Flex>)}

            <Flex style={{ fontWeight: 10, height: "100%", display: "grid" }} width={1 / 2} alignItems="center">{data[0].installedTime == null ? <i>"N/A"</i> : data[0].installedTime}</Flex>
          </Flex>
        </div>

      </div>


    )
  }


  VehicaleDetails = (vehicles) => {


    return (<h5 style={{ color: "#b3b3b3", marginLeft: 20 }}>No Vehicles Found</h5>)

    return (

      <ul >
        {vehicles.map((data, index) => {
          return <li key={index} style={{ cursor: "pointer", listStyle: "none" }} className="hash_item"><i>{data.vehicleNumber}</i></li>
        })}
      </ul>
    )

  }

  colorCodes = () => {

    return (

      <ul >
        <li style={{ cursor: "pointer", listStyle: "none", background: "#008000a6", fontSize: 13 }} className="color_codes"><i>Within SLA Yes</i></li>
        <li style={{ cursor: "pointer", listStyle: "none", background: "#ff000094", fontSize: 13 }} className="color_codes"><i>Within SLA No</i></li>

      </ul>
    )

  }

  CellContext = (assignedDockCount, dockCount) => {

    return (
      <Card
        value={assignedDockCount}
        from={dockCount}
        header={<React.Fragment><FiNavigation style={{ margin: 5 }} />Allocated</React.Fragment>}
        className="app-dashboard app-dashboard-card-color-lable red"
        style={{ paddingLeft: 10 }} />


    )
  }

  render() {
    return (
      <DataContext>
        {withContext(Loading)}
        {/* {withContext(LoginModal)} */}
        <div className={`App ${this.state.isLight ? 'light' : 'dark'}`}>
          <span onClick={() => this.setState({ isLight: !this.state.isLight })} style={{ position: 'absolute', bottom: 0, right: 10 }}>{
            this.state.isLight
              ? <FiToggleLeft style={{ fontSize: 20 }} />
              : <FiToggleRight style={{ fontSize: 20 }} />
          }</span>
          <div className="app-dashboard-sidebar">

            <div>










            </div>





          </div>

          <div className="app-dashboard-body">
            <CardContainer style={{ borderRadius: '5px 5px 30px 30px' }}>
              <Flex className="app-dashboard-body-topbar">

                <Flex width={1} justifyContent="flex-start">{this.state.curTime ? (<div className="center-content"><FiCalendar style={{ margin: 5 }} />{this.state.curTime}</div>) : (<StillClock />)}</Flex>
                <Flex width={1} justifyContent="center"><div className="departures">All Region</div></Flex>
                <Flex width={1} justifyContent="flex-end"><TickClock /></Flex>
                <div class="col-md-1">
                  <button type="button" style={{ marginLeft: 32 }} class="btn btn-danger" onClick={() => this.viewFilterClick()}><i class="fas fa-filter"></i></button>
                </div>
              </Flex>
            </CardContainer>
            <div className="app-dashboard-body-display">
              <Collapse isOpen={this.state.isOpen}>
                <div className="row" style={{ paddingLeft: 20, paddingRight: 20 }}>
                  <div class="card">

                    <div class="card-body bootstrap-select-1">
                      <div class="row">
                        <div class="col-md-2">
                          <label class="mb-3">Start Date</label>
                          <div className="row">
                            <div className="col-md-12">
                              <DatePicker
                                placeholder="Start Date"
                                style={{ width: "100%" }}
                                onChange={this.onChangeDate}
                              />
                            </div>
                          </div>

                        </div>
                        <div class="col-md-2">
                          <label class="mb-3">End Date</label>
                          <div className="row">
                            <div className="col-md-12">
                              <DatePicker
                                placeholder="End Date"
                                style={{ width: "100%" }}
                                onChange={this.onChangeDate2}
                              />
                            </div>
                          </div>

                        </div>
                        









                      </div>
                      <div class="col-md-3" style={{ marginTop: 10 }}>
                        <button type="button" class="btn btn-primary" onClick={() => this.filterBtnClick()}> Filter</button>
                        <button type="button" style={{ marginLeft: 5 }} class="btn btn-dark" onClick={() => this.clearBtnClick()}>Clear Filter</button>
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>

              {/* <Main /> */}
              <Flex>
                {/* LEFT SIDE */}
                <Box width={1}>
                  <Table
                    columns={columns}
                    dataSource={this.state.data}
                    loading={this.state.dataLoading}
                    bordered

                  />
                </Box>
                {/* RIGHT SIDE */}
                {/* <Box width={1}>
                    <LocationContainer>
                    {withContext(({data  ,assignedDockCount , dockCount}) => data.map((_data, i) => i%2==1 &&  this.VehicaleCellContext(_data,  assignedDockCount , dockCount , i)))}
                    </LocationContainer>
                  </Box> */}
              </Flex>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: "#acacac" }}>

            </div>
            <div style={{ display: 'flex', height: 25, justifyContent: 'center', alignItems: 'center', color: "#acacac" }}>
              <h6> © 2020 Eimsky Business Solutions Pvt Ltd. All Rights Reserved.  </h6>
            </div>

          </div>
        </div>
      </DataContext>
    );
  }
}
function mapStateToProps(state) {
  //console.log("state", state);
  return {
    organization_count: state.organization.length,
    regions: state.regions,
    depots: state.depots,
    sites_count: state.sites.length,
    vendor_count: state.vendor.length,
    roles_count: state.roles.length,
    pins: state.pins
  };
}

export default connect(mapStateToProps, { getDonationCategories, getData })(DashboardPgStatus);