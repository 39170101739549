import React, { Component } from 'react'
import ReactApexChart from 'react-apexcharts'

class SimpleDonutChart2 extends Component {
    constructor(props) {
        super(props);
        this.state = {

            series: [],
            options: {
                chart: {
                    type: 'donut',
                },
                plotOptions: {
                    pie: {
                      donut: {
                        size: "85%"
                      },
                    }
                  },
                dataLabels: {
                  enabled: false
                },
                legend: {
                    show: false,
                    position: 'bottom',
                },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                    }
                }]
            },


        };
    }
    componentDidMount() {
        let totalCout=this.props.refuleSeries.appVersionCount
        this.setState({
            series:this.props.refuleSeries.appVersionCount,
            options: {
                chart: {
                    type: 'donut',
                },
                colors : this.props.colorArray,
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    fontSize: '22px',
                                    fontFamily: 'Roboto, sans-serif',
                                    fontWeight: 600,
                                    color: undefined,
                                    offsetY: -10,
                                },
                                value: {
                                    show: true,
                                    fontSize: '16px',
                                    fontFamily: 'Roboto, sans-serif',
                                    fontWeight: 400,
                                    color: undefined,
                                    offsetY: 16,
                                    formatter: function (val) {
                                        return val
                                    }
                                },
                                total: {
                                    show: true,
                                    showAlways: true,
                                    label: 'Total',
                                    color: '#373d3f',
                                    formatter: function (w) {
                                        return w.globals.seriesTotals.reduce((a, b) => {
                                            let total = a + b
                                            return total
                                        }, 0)
                                    }
                                }
                            }
                        }
                    }
                },
                // plotOptions: {
                //     pie: {
                //       donut: {
                //         size: "80%"
                //       },
                //     }
                //   },
                dataLabels: {
                  enabled: false
                },
                legend: {
                    show: true,
                    position: 'bottom',
                },
             
                labels:this.props.refuleLable.appVersionlabels,
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },

                    }
                }]
            },
        })
    }

    render() {
        return (
            <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="donut" height={350} />
            </div>
        )
    }
}
export default SimpleDonutChart2;