import ReactFlow, { MiniMap, Controls, Background, applyNodeChanges, ConnectionLineType, ReactFlowProvider } from 'react-flow-renderer';
import { useCallback, useState, useEffect, Fragment } from 'react';
import { useLocation } from "react-router-dom";
import 'react-flow-renderer/dist/style.css';
import { getsldDiagramById, getSiteDetails } from '../../api/index';
import { Button } from 'antd';
import TextUpdaterNode from './TextUpdaterNode';

function Flow(props) {
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const [siteName, setSiteName] = useState('');
    const [siteId, setSiteId] = useState('');

    const nodeColors = ['#FFA500', '#5539CC', '#B6B6B4', '#8FBA74'];

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        let siteId = props.details.site_details.id;
        setSiteId(siteId);
        const [diagramRes, siteRes] = await Promise.all([
            getsldDiagramById(siteId),
            getSiteDetails(siteId),
        ]);

        if (diagramRes.success) {
            generateNodesAndEdges(diagramRes.data);
        }

        if (siteRes.success) {
            setSiteName(siteRes.data.siteName);
        }
    };

    const reSetSLD = () => {
        loadData();
    };

    const generateColor = () => {
        return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    };

    const generateNodesAndEdges = (data) => {
        const levels = [1, 2, 3, 4, 5];
        const newNodes = [];
        const newEdges = [];
        const xOffsets = [20, 250, 420, 650, 900];

        levels.forEach((level, index) => {
            const nodesAtLevel = data.filter(item => item.levelNo === level);
            let yPosition = 30;

            nodesAtLevel.forEach(item => {
                const color = nodeColors[index] || generateColor();
                newNodes.push({
                    id: item.itemId.toString(),
                    data: { label: item.name },
                    type: level === 1 ? 'input' : level === 5 ? 'output' : undefined,
                    sourcePosition: 'right',
                    targetPosition: 'left',
                    position: { x: xOffsets[index], y: yPosition },
                    style: {
                        background: color,
                        color: "#FFFFFF",
                        border: "1px solid #222138",
                        fontSize: 14,
                        padding: 6,
                    },
                });

                item.connectedItems.forEach(targetId => {
                    newEdges.push({
                        id: `${item.itemId}-${targetId}`,
                        source: item.itemId.toString(),
                        target: targetId.toString(),
                        type: 'smoothstep',
                        style: { stroke: color, strokeWidth: 2 },
                    });
                });

                yPosition += 100;
            });
        });

        setNodes(newNodes);
        setEdges(newEdges);
    };

    const onNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [setNodes]
    );

    const nodeTypes = { textUpdater: TextUpdaterNode };

    return (
<Fragment>
    <div className='row'>
        <div className="col-lg-12 text-end">
            <Button type="button" class="btn btn-soft-primary btn-sm"
                style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', marginLeft: 10, zIndex: 10, position: 'relative' }}
                onClick={reSetSLD}>
                <span className="textStyles-small" style={{color: 'white'}}><i className="fas fa-undo" style={{marginRight: '10px', color: 'white'}}/>Reset</span>
            </Button>
        </div>
    </div>
    <div 
    className="layoutflow" 
    style={{ height: "40em", borderRadius: 15, backgroundColor: 'white', overflow: 'hidden', position: 'relative', marginTop: 10 }}>
        <ReactFlowProvider>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                onNodesChange={onNodesChange}
                connectionLineType={ConnectionLineType.SmoothStep}
                fitView={true}
                style={{ width: '100%', height: '100%', fontSize: 10 }}
                className='textStyles-small' 
            >
                <Controls slot='top-left' />
                <Background color="#aaa" gap={10} />
            </ReactFlow>
        </ReactFlowProvider>
    </div>
</Fragment>
    );
}

export default Flow;
