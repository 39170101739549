import React, { Component } from 'react'
import { Select, DatePicker, Table, Image, Avatar, Input, Tag, Badge, Descriptions, Space } from 'antd';

import { Link } from "react-router-dom";


import { getLastThreeRequestDetails, getUserSites, getPisHistoryData, getSitesWithFilter, getPisHistoryDetailsById, getAllPisStatus } from "../../../api/index";
import Modal from 'react-modal';

import { THE_FAMILY_ID } from '@azure/msal-common/dist/utils/Constants';
import Item from 'antd/lib/list/Item';
import ReactLoading from 'react-loading';
import { tupleNum } from 'antd/lib/_util/type';
import { Collapse } from 'reactstrap';
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from "react-redux";
import * as XLSX from "xlsx";


import moment from 'moment';
import dashboard from '../../dashboard';


class PisHistoryView extends Component {

    constructor(props) {
        super(props);
        this.state = {

            loading: false,
            showModal: false,
            isOpenFillter: true,
            allList: null,
            allSite_loading: true,
            allSite: [],
            depotList: [],
            statusList: [],
            regionsList: [],
            allDataList: [],
            startDate: "",
            endDate: "",
            selectSiteId: null,
            selectSiteName: null,
            selectComptName: null,
            comptId: null,
            selectImagesDetails: null,
            regionIds: '',
            depotIds: '',
            siteIds: '',
            statusId: '',
            enterReqCode: '',
            fullPageCount: 0,
            loopPageCount: 0,
            recodesPerPage:50,
            itemDetails: [],
            selectedServiceTypeId: 1,
            selectedDataUrl: "/genServiceRequest/getAllCheklistExport",
            selectedTypeName: "Gen Service - PIS",
            selectedDetailUrl: "",
            recodesPerPage2: 1,
            pageNo2: 0,
            fullArray: [],
            buttonLodading: false,
            newArray: [],
            finalOne: null,
            sectionArray: null,
           
        };

    }
    viewFilterClick = () => {
        this.setState({
            isOpenFillter: !this.state.isOpenFillter
        })
    }

    previousBtnClick2 = () => {
        if (this.state.pageNo2 > 0) {
            var aa = this.state.pageNo2 - 1
            this.setState({
                pageNo2: aa
            }, () => {
                this.getPisDataList();
            })
        }
    }
    nextBtnClick2 = () => {
        if (this.state.recodesPerPage2 > this.state.pageNo2 + 1) {
            var aa = this.state.pageNo2 + 1
            this.setState({
                pageNo2: aa
            }, () => {
                this.getPisDataList();
            })

        }

    }
    componentDidMount() {

        this.getAllDataPisStatus();

        var _depotList = [];
        var _regionsList = [];

        this.props.depots.map((depot) => {
            _depotList.push({ value: depot.id, label: depot.name })
        })
        this.props.regions.filter(allret => "0".includes(allret.isSubRegion)).map((regions) => {
            _regionsList.push({ value: regions.id, label: regions.name })
        })
        this.setState({
            depotList: _depotList,
            regionsList: _regionsList
        })
    }
    getAllDataPisStatus = async () => {
        var res = await getAllPisStatus();
        console.log('getAllPisStatus')
        console.log(res);
        if (res.success) {
            var _statudList = [];

            res.data.map((depot) => {
                _statudList.push({ value: depot.id, label: depot.name })
            })

            this.setState({
                statusList: _statudList,
            })

        }

    }
    setArray(arr) {
        console.log("allDataList")
        console.log(arr)
        console.log(this.state.allDataList)
        let forNullData = [
            {
                "test": "Ok",
            },
        ]
        var newData = arr.filter(function (element) {
            // return element.data !== null ;
            return (element.data !== null && element.data.length>0)
        });
        console.log(newData)
        var newseArray
        var getSection = newData[newData.length - 1]?.data.map(se => {

            return newseArray = {
                "secName": se.section.name,
                "SecLength": Object.values(se.data).length
            }
        })

        console.log('getSection')
        console.log(getSection)


        var ss = arr.map((x, y) => {
            if (x.data != null) {
                console.log("opopopopopopopopop")
                console.log(x.data)
                return   x.data.map((i) => {

                    const sortedKeys = Object.keys(i.data).sort();

                    const sortedObject = sortedKeys.reduce((acc, key) => {
                      acc[key] = i.data[key];
                      return acc;
                    }, {});

                    
                    return   {  ...sortedObject,
                        requestCode: x.requestCode,
                        jobStatus: x.jobStatus,
                        requestType: x.requestType,
                        siteID: x.siteID,
                        siteName: x.siteName, };


                })
            } else {
                return forNullData.map(i => ({

                    requestCode: x.requestCode,
                    jobStatus: x.jobStatus,
                    requestType: x.requestType,
                    siteID: x.siteID,
                    siteName: x.siteName,


                }))

                // {
                //     requestCode: x.requestCode,
                //     jobStatus: x.jobStatus,
                //     requestType: x.requestType,
                //     siteID: x.siteID,
                //     siteName: x.siteName,

                // }
            }
        })

        console.log("sssssssss,lolklklk");
        console.log(ss);
        var data56 = ss.filter(function (element) {
            return element !== undefined;
        });
        var newlp = data56.map((ff, gg) => {
            const mergeJSON = (...objs) => {
                // Return a new object with the properties of all objects
                return Object.assign({}, ...objs);
            };

            const result = mergeJSON(...ff);

            return result



        })
        this.setState({
            finalOne: newlp,
            sectionArray: getSection

        })

    



    }
    getPisDataList = async () => {
        this.setState({
            loading: true
        })
        var data = new FormData();
        data.append("pageNo", this.state.pageNo2);
        data.append("rowCountPerPage", this.state.recodesPerPage);
        data.append('fromDate', this.state.startDate);
        data.append('toDate', this.state.endDate);
        data.append('regionIds', this.state.regionIds);
        data.append('depotIds', this.state.depotIds);
        data.append('siteIds', this.state.siteIds);
        data.append('status', this.state.statusId);
        data.append('serviceRequestIds', this.state.enterReqCode);


        var pageCount = 0;


        var res = await getPisHistoryData(this.state.selectedDataUrl, data);
        console.log(res)
        if (res.success) {


            pageCount = res?.data?.pageCount;

            console.log(this.state.newArray)

            if (pageCount == 0) {

                this.setState({
                    loading: false,
                    buttonLodading: false,
                    allDataList: []
                });
            }

            if (pageCount == 1) {
                this.setState({
                    loading: false,
                    buttonLodading: false,
                    allDataList: res.data.data.reverse(),
                }, () => {
                    this.setArray(this.state.allDataList)

                });
            }

            if (pageCount > 1) {
                // for (let i = 0; i < pageCount; i++) {
                //     this.getPisDataList2(i, pageCount)
                //     if (i == pageCount - 1) {
                //         this.setState({
                //             loading: false,

                //         },()=>{
                          
                //         })
                //     }
                // }
                this.getPisDataList2(pageCount)
            }
      

        }



    }

    getPisDataList2 = async (pageCount) => {

        console.log("this.state.loopPageCount")
        console.log(pageCount)
        console.log(this.state.loopPageCount)
        var data = new FormData();
        data.append("pageNo", this.state.loopPageCount);
        data.append("rowCountPerPage", this.state.recodesPerPage);
        data.append('fromDate', this.state.startDate);
        data.append('toDate', this.state.endDate);
        data.append('regionIds', this.state.regionIds);
        data.append('depotIds', this.state.depotIds);
        data.append('siteIds', this.state.siteIds);
        data.append('status', this.state.statusId);
        data.append('serviceRequestIds', this.state.enterReqCode);

        var res = await getPisHistoryData(this.state.selectedDataUrl, data);
        if (res.success) {
            this.setState({
                fullArray: [...this.state.fullArray, ...res.data.data]
            }, () => {

                if (pageCount - 1 == this.state.loopPageCount) {
                    console.log('data+1')
                    
                    this.setState({
                        buttonLodading: false,
                        loading: false,
                        loopPageCount:0
                    },()=>{
                        console.log('sdfssss');
                        console.log(this.state.fullArray);
                        this.setArray(this.state.fullArray.reverse())
                    })


                }else{

                    this.setState({
                        loopPageCount: ++this.state.loopPageCount,
                    }, () => {
                        console.log('ff')
    
                        this.getPisDataList2(pageCount);
                    })
                }
                this.setState({

                    allDataList: this.state.fullArray,
                });


            })

        }

    }



    clearBtnClick = () => {
        window.location.reload()
    }
    searchRegionmChange = (event) => {
        var dd = event
        //console.log(dd)
        var subRegion = [];
        var _depotList1 = [];
        var subRegionIdArray = [];

        this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id)).map((subR) => {
            subRegionIdArray.push(subR.id)
        })

        this.setState({
            regionIds: subRegionIdArray
        }, () => { this.getDataSitesWithFilter() });

        //console.log(this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id)));
        subRegion = this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id));
        //console.log(this.props.depots.filter(alldate => subRegion.map((n) => n.id).includes(alldate.region?.id)));
        this.props.depots.filter(alldate => subRegion.map((n) => n.id).includes(alldate.region?.id)).map((depot) => {
            _depotList1.push({ value: depot.id, label: depot.name })
        })

        this.setState({
            depotList: _depotList1

        })

    }
    searchDepottemChange = (event) => {

        this.setState({
            depotIds: event
        }, () => this.getDataSitesWithFilter());
    }
    getDataSitesWithFilter = async () => {
        this.setState({
            allSite_loading: true,
        })

        var _regionList = [];
        var _depotList = [];
        var _allSite = [];
        if (this.state.regionIds !== '') {
            this.state.regionIds?.map((x, y) => {
                _regionList.push({ "id": x })
            }, () => {

            })
        }
        if (this.state.depotIds !== '') {
            this.state.depotIds?.map((a, b) => {
                _depotList.push({ "id": a })
            }, () => {

            })
        }


        var data = new FormData();
        // data.append('regions','[{"id":6},{"id":7}]');
        data.append('regions', JSON.stringify(_regionList));
        data.append('depots', JSON.stringify(_depotList));
        var res = await getSitesWithFilter(data);
        if (res.success) {
            res.data.data.map((sites) => {
                _allSite.push({ value: sites.id, label: sites.siteName })
            })
            this.setState({
                allSite_loading: false,
                allSite: _allSite,

            })

        }

    }
    onChangeDate = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);
        this.setState({
            startDate: dateString,
        });
    };
    onChangeDate2 = (date, dateString) => {
        //console.log(date);
        //console.log(dateString);
        this.setState({
            endDate: dateString,
        });
    };
    searchStatusChange = (event) => {

        this.setState({
            statusId: event
        });
    }

    searchSiteemChange = (event) => {

        this.setState({
            siteIds: event
        });
    }
    filterBtnClick = async () => {

        this.setState({ buttonLodading: true, loading: true, allDataList: [], fullArray: [] })
        if (this.state.startDate != "" && this.state.endDate != "") {
            this.getPisDataList();
        }

    }



    serviceTypesChange = (e, serviceTypes) => {
        this.setState({
            selectedServiceTypeId: e,
            selectedDataUrl: serviceTypes[e - 1].data_url,
            selectedTypeName: serviceTypes[e - 1].name
            
        })
        console.log(serviceTypes[e - 1].data_url);
        console.log(e);
    }

    searchFormReqCode = (event) => {
        this.setState({
            enterReqCode: event.target.value
        })


    }


    exportToCSV = () => {
        const XLSX = require('sheetjs-style');
        console.log('sheetjs-style')
        console.log(this.state.newArray)
        var colorArr= ['A4F199','99F1E9','F5F49C','D9C2FE','C0F0B2','F7B4B1','E9967A','F7B1CC','B9DCA9','A3E2CB'];
        const timestamp = Date.now();
        var testArray = this.state.finalOne


        console.log("testArray----------------------------------------------------------------------------------------------------------------");
        console.log(testArray);

        var wscols = [
            { wch: 30 }, // "characters"
            { wpx: 100 }, // "pixels"
            { wch: 20 }, // "pixels"
            ,
            // { hidden: true } // hide column
        ];
        const myHeader = ["requestCode", "jobStatus", "requestType", "siteID", "siteName"];
        const workbook = XLSX.utils.book_new();

        const worksheet3 = XLSX.utils.json_to_sheet(testArray, { origin: 'A2', header: myHeader });
        console.log(worksheet3)
        var range = XLSX.utils.decode_range(worksheet3['!ref']);

        var setRowCount = range.e.c


        worksheet3['!cols'] = wscols;     

        var xyt = this.state.sectionArray;
        xyt?.unshift({
            "secName":'General Details',
            "SecLength":5
        });

        let generalDeteilsCount=0 ;
        this.state.sectionArray?.map(se => {

            console.log("Section Array ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++");
            console.log(se);
            worksheet3[XLSX.utils.encode_cell({ r: 0, c: generalDeteilsCount })] = { t: 's', v: se.secName };

            generalDeteilsCount = generalDeteilsCount + se.SecLength;
        })

        
 
        let generalmergeCount =0;
        let merge123Array = [];
     
        this.state.sectionArray?.map(se => {
            console.log("Section Array-03 ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++");
            console.log(se);
            merge123Array = [...merge123Array, { e: { r: 0, c: generalmergeCount+se.SecLength-1 }, s: { r: 0, c: generalmergeCount} }]
            worksheet3[XLSX.utils.encode_cell({ r: 0, c: generalmergeCount })] = { t: 's', v: se.secName };

            worksheet3[XLSX.utils.encode_cell({ r: 0, c: generalmergeCount })].s = {
                // set the style for target cell
                fill: {
                    patternType: "solid",
                    fgColor: { rgb:colorArr[Math.floor(Math.random() * colorArr.length)].toString() }
                },
            };
            generalmergeCount = generalmergeCount + se.SecLength;
            
        })
        console.log(merge123Array)


        worksheet3['!merges'] = merge123Array;

      
        for (let index = 0; index < setRowCount + 1; index++) {

            worksheet3[XLSX.utils.encode_cell({ r: 1, c: index })].s = {
                // set the style for target cell
               
                font:{
                    bold:true	
                },
                border:{
                    right: {
                        style: "thin",
                        color: "000000"
                      },
                      left: {
                        style: "thin",
                        color: "000000"
                      },
                      top:{
                        style: "thin",
                        color: "000000"
                      },
                      bottom:{
                        style: "thin",
                        color: "000000"
                      }

                }
                

            };
        }

        console.log(worksheet3)

        //    XLSX.utils.book_append_sheet(workbook, tebleQQ, 'Visiting Data');
        XLSX.utils.book_append_sheet(workbook, worksheet3, 'Sheet1');

        XLSX.writeFile(workbook, this.state.selectedTypeName+"Cheklists Export "+timestamp + '.xlsx');
    }

    render() {
        const { Option } = Select;

        const dataSet1 = this.state.allDataList;
        const columns = [

            {
                title: "Request Code",
                render: (item) => <>

                    {item?.requestCode}
                </>,
            },

            {
                title: "Site Id",
                render: (item) => <>

                    {item?.siteID}
                </>,
            },
            {
                title: "Site Name",
                render: (item) => <>

                    {item?.siteName}
                </>,
            },
            {
                title: "Request Type",
                render: (item) => <>

                    {item?.requestType}
                </>,
            },
            {
                title: "Job Status",
                render: (item) => <>

                    {item?.jobStatus}
                </>,
            },
            // {
            //     title: "Created Date",
            //     render: (item) => <>
            //         < >
            //             {item?.createdDate.split("T")[0]}
            //         </>
            //     </>,
            // },
            // {
            //     title: "Status",
            //     render: (item) => <>
            //         <Tag color={item?.colorCode.replace("0xFF", "#")} style={{ padding: 3 }} >
            //             {item.status}
            //         </Tag>
            //     </>,
            // },
            // {
            //     title: "Service Date",
            //     render: (item) => <>
            //         < >
            //             {item?.serviceDate == null ? "PENDING" : item?.serviceDate}
            //         </>
            //     </>,
            // },



        ];
        var serviceTypes = [{ "id": 1, "name": "Gen Service - PIS", "data_url": "/genServiceRequest/getAllCheklistExport" }, { "id": 2, "name": "AC Service - PIS", "data_url": "/acServiceRequest/getAllCheklistExport" }, { "id": 3, "name": "Site Cleaning Service - PIS", "data_url": "/siteCleaningService/getAllCheklistExport" }, { "id": 4, "name": "Civil Routine - PIS", "data_url": "/civilRoutineService/getAllCheklistExport" }];

        return (

            <div class="container-fluid" >
                <div class="row">
                    <div class="col-sm-12">
                        <div class="page-title-box">
                            <div class="row">
                                <div class="col">
                                    <h4 class="page-title">PIS Cheklists Export</h4>

                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">PIS </a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Cheklists Export</a></li>

                                    </ol>
                                </div>
                                <div class="col-auto align-self-center">
                                    <div class="row">

                                        <div class="col-md-1">
                                            <button onClick={() => this.viewFilterClick()} type="button" style={{ marginLeft: 10 }} class="btn btn-danger" ><i class="fas fa-filter"></i></button>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">


                    <div class="col-sm-2">
                        <Select
                            // mode="multiple"
                            showSearch
                            name="regionId"
                            onChange={(e) => this.serviceTypesChange(e, serviceTypes)}
                            placeholder="Organization"
                            style={{ width: "100%" }}
                            value={this.state.selectedServiceTypeId}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                        >
                            {serviceTypes.map((item, index) => {
                                return <Option key={index} value={item.id}>{item.name}</Option>;
                            })}
                        </Select>
                        {/* {this.state.organizationError && <p style={{ color: "red", fontSize: '10px' }}>The organization field is required.</p>} */}
                    </div>
                    <div class="col-md-2">
                        <DatePicker
                            placeholder="Start Date *"
                            style={{ width: "100%" }}
                            onChange={this.onChangeDate}
                        />

                    </div>
                    <div className='col-md-2'>
                        <DatePicker
                            placeholder="End Date *"
                            style={{ width: "100%" }}
                            onChange={this.onChangeDate2}
                        />
                    </div>
                    <div class="col-md-2">


                        <Select
                            mode="multiple"
                            showSearch
                            name="regionId"
                            onChange={(e) => this.searchRegionmChange(e)}
                            placeholder="Region"
                            style={{ width: "100%" }}

                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                        // options={this.state.regionsList}

                        >
                            {this.state.regionsList?.map((item, index) => {
                                return <Option value={item.value}>{item.label}</Option>;
                            })}
                        </Select>
                    </div>
                    <div class="col-md-2">

                        <Select
                            mode="multiple"
                            showSearch
                            name="depotId"
                            onChange={(e) => this.searchDepottemChange(e)}
                            placeholder="Depot"
                            style={{ width: "100%" }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                        // options={this.state.depotList}

                        >
                            {this.state.depotList?.map((item, index) => {
                                return <Option value={item.value}>{item.label}</Option>;
                            })}
                        </Select>
                    </div>
                    <div class="col-md-2">

                        <Select
                            mode="multiple"
                            placeholder="Site"
                            style={{ width: "100%" }}
                            // options={this.state.allSite} 
                            loading={this.state.allSite_loading}
                            name="siteId"
                            onChange={(e) => this.searchSiteemChange(e)}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                        >
                            {this.state.allSite.map((item, index) => {
                                return <Option value={item.value}>{item.label}</Option>;
                            })}
                        </Select>
                    </div>



                </div>
                <br></br>
                <div className='row'>
                    <div class="col-md-2">

                        <Select
                            mode="multiple"
                            placeholder="Status"
                            style={{ width: "100%" }}
                            // options={this.state.allSite} 
                            // loading={this.state.allSite_loading}
                            name="statusId"
                            onChange={(e) => this.searchStatusChange(e)}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                        >
                            {this.state.statusList.map((item, index) => {
                                return <Option value={item.value}>{item.label}</Option>;
                            })}
                        </Select>
                    </div>
                    <div class="col-md-4">
                        {/* <Input placeholder="Request Code" onChange={(e) => this.searchFormReqCode(e)} /> */}


                    </div>

                </div>
                <div className="col-lg-12 col-xl-12" style={{ padding: 10, textAlign: 'end' }}>
                    <button type="button" class="btn btn-primary" onClick={() => this.filterBtnClick()}>   <i class="fas fa-search"> </i>  Search</button>

                </div>

                <div className='row'>
                    <div className="col-lg-12 col-xl-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row ">
                                    {/* <button onClick={this.exportToCSV}>Export</button> */}
                                    <div className="col">
                                        <h4 className="card-title">
                                            Report - {this.state.selectedTypeName} Cheklists Export{" "}
                                            <button
                                                class="btn btn-primary"
                                                style={{ float: "right" }}
                                                disabled={this.state.buttonLodading || dataSet1.length <= 0}
                                                onClick={this.exportToCSV}
                                            >
                                                <i class="fas fa-file-export" style={{ paddingRight: 5 }}> </i>{this.state.buttonLodading ? (<img width={20} src={'https://media.tenor.com/wpSo-8CrXqUAAAAj/loading-loading-forever.gif'}></img>) : ('')} {this.state.selectedTypeName} Cheklists Export
                                            </button>


                                        </h4>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">

                                <Table
                                    size="small"
                                    columns={columns}
                                    // rowKey="id"
                                    loading={this.state.loading}
                                    dataSource={this.state.allDataList}
                                />

                            </div>
                        </div>
                    </div>

                </div>
                <br></br>




                {/* modal end */}


            </div >
        )
    }

}
function mapStateToProps(state) {
    //console.log("state")
    //console.log(state)
    return {
        depots: state.depots,
        regions: state.regions
    };
}
export default withRouter(connect(mapStateToProps, {})(PisHistoryView));


