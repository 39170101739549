import React, { Component } from 'react'
import { Table, Tag, Button, Drawer, Form, Input, Select, InputNumber, message, Popconfirm, DatePicker } from 'antd';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { getAllModelData, powerDbModelCreate, powerDbModelGetById, powerDbModelUpdate, powerDbModelDelete } from "../../api/index";

class GeneratorModel extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            dataSet: [],
            actionView: true,
            AssetManagementPermission: true,
            open: false,
            modelName: '',
            materialCode: '',



        }
        this.requestFromClose = this.requestFromClose.bind(this);
        this.handleName = this.handleName.bind(this);
        this.handleMaterialCode = this.handleMaterialCode.bind(this);



    }
    handleBatterySpace(e) {
        console.log(e)
        this.setState({ batterySpace: e });
    }
    handleDescription(e) {
        console.log(e.target.value)
        this.setState({ rectifiersModelDescription: e.target.value });
    }

    handleMaterialCode(e) {
        console.log(e.target.value)
        this.setState({ materialCode: e.target.value });
    }
    handleName(e) {
        console.log(e.target.value)
        this.setState({ modelName: e.target.value });
    }
    handleCoolingMethodEquipmentCompartment(e) {
        console.log(e.target.value)
        this.setState({ coolingMethodEquipmentCompartment: e.target.value });
    }
    handleCoolingMethodBatteryCompartment(e) {
        console.log(e.target.value)
        this.setState({ coolingMethodBatteryCompartment: e.target.value });
    }
    handleCoolingCapacityEquipmentCompartment(e) {
        console.log(e.target.value)
        this.setState({ coolingCapacityEquipmentCompartment: e.target.value });
    }
    handleCoolingCapacityBatteryCompartment(e) {
        console.log(e.target.value)
        this.setState({ coolingCapacityBatteryCompartment: e.target.value });
    }
    handlEequipmentSpace(e) {
        console.log(e.target.value)
        this.setState({ eequipmentSpace: e.target.value });
    }


    requestFromClose() {
        console.log('dsdsd')
        this.setState({ showModal: true, open: true, editFlow: false });
    }

    onDrawerClose = () => {
        console.log('sddsds')
        this.formRef.current.resetFields();

        this.setState({
            open: false,
            modelName: '',
            materialCode: '',
        })

    }
    componentDidMount() {
        this.getAllModelData()
    }
    getAllModelData = async () => {
        this.setState({
            tabaleLoading: true
        })
        var Url = '/generatorModel/getAll'

        var res = await getAllModelData(Url);
        console.log(res.data)
        if (res.success) {
            if (this.state.setValues == null) {
                this.setState({
                    allDataModel: res.data,
                    tabaleLoading: false
                })
            } else {
                this.setState({
                    allDataModel: res.data,
                    tabaleLoading: false
                }, () => {
                    let value = this.state.setValues
                    const filtered = this.state.allDataModel.filter(user => (user?.name?.toLowerCase().includes(value.toLowerCase()) || user?.materialCodeOfTheGenerator?.toLowerCase().includes(value.toLowerCase())));



                
                    this.setState({
                        filteredTable: filtered
                    })

                })
            }
        
        }

    }
    AllDataSubmit = async () => {
        var Url = '/generatorModel/create'
        var dataSet = {

            "name": this.state.modelName,
            "materialCodeOfTheGenerator": this.state.materialCode,

        }
        var res = await powerDbModelCreate(Url, dataSet);
        console.log(res);
        if (res.success) {
            this.formRef.current.resetFields();
            this.setState({
                open: false,
                modelName: '',
                materialCode: '',
            }, () => {
                message.success('Successfully Created!')
                this.getAllModelData()

            })
        }

    }

    modelUpdata = (id) => {
        var Url = '/generatorModel/getById/' + id
        this.getModelById(Url)


    }
    getModelById = async (Url) => {

        var res = await powerDbModelGetById(Url);
        console.log(res)
        if (res.success) {
            this.setState({
                editFlow: true,
                open: true,
                modelid: res.data.id,
                modelName: res.data.name,
                materialCode: res.data.materialCodeOfTheGenerator,


            })
        }


    }
    editDataSubmit = async () => {
        var Url = '/generatorModel/update'
        var data = {
            "id": this.state.modelid,
            "name": this.state.modelName,
            "materialCodeOfTheGenerator": this.state.materialCode,
        }
        var res = await powerDbModelUpdate(Url, data);

        if (res.success) {
            this.setState({
                open: false,
                modelName: '',
                materialCode: '',
            }, () => {
                this.getAllModelData()
                message.success('Successfully Updated!')
            })
        }


    }

    submitForm = async (item) => {
        this.deleteModelData(item);


    }
    deleteModelData = async (item) => {
        var Url = '/generatorModel/deleteGeneratorModel?generatorModelId=' + item
        var res = await powerDbModelDelete(Url);
        if (res.success) {
            message.success('Successfully Delete!')
            this.getAllModelData();
        } else {
            message.warning(' Something went wrong!')
        }

        console.log(res)

    }
    search = (value) => {
        console.log(this.state.allDataModel)
        console.log(value)
        this.setState({
            setValues: value
        })

        const filtered = this.state.allDataModel.filter(user => (user?.name?.toLowerCase().includes(value.toLowerCase()) || user?.materialCodeOfTheGenerator?.toLowerCase().includes(value.toLowerCase())));
        this.setState({
            filteredTable: filtered
        })



    }

    render() {
        const columns2 = [
            {
                title: "Generator Model",

                dataIndex: "name",
                key: "name",
                fixed: 'left',

                render: (item) => <>
                    <Tag color="gray" key={item}>
                        <span>{item}</span>
                    </Tag>
                </>,


            },
            {
                title: "Material Code",
                dataIndex: "materialCodeOfTheGenerator",
                key: "materialCodeOfTheGenerator",

                fixed: 'left',
                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,

            },



            {

                dataIndex: "id",
                key: "id",
                width: '50px',

                fixed: 'right',

                render: (item) => <>
                    {this.state.AssetManagementPermission ? (<a style={{ color: 'blue' }} onClick={() => this.modelUpdata(item)}><i className="las la-pen font-15"></i></a>) : ('')}
                    <Popconfirm
                        title="Are You Sure?"
                        description="Are you sure to delete this task?"
                        onConfirm={() => this.submitForm(item)}
                        okText="Yes"
                        cancelText="No"
                    >
                        {this.state.AssetManagementPermission ? (<a style={{ color: 'blue' }}><i className="las la-trash-alt font-15"></i></a>) : ('')}
                    </Popconfirm> </>,

            },









        ];
        return (
            <div class="row">
                <div class="card">
                    <div class="card-header">

                        <div className="row align-items-center">
                            <div className="col">
                                <h4 className="card-title">Generator Model List</h4>
                            </div>
                            <div className="col-auto">
                                {this.state.actionView ? (<Button className="btn btn-primary" onClick={this.requestFromClose} block style={{ textAlign: "end" }} >
                                    <i class="la la-plus-circle text-muted font-16 me-2"></i> <b style={{ color: "#40a9ff" }}>Add Data</b>
                                </Button>) : ('')}
                            </div>
                        </div>
                    </div>
                    <div class="card-body">

                    <div className='row'>
                            <div className='col-md-10'>
                                <Input
                                    id='clearSearch'
                                    enterButton
                                    style={{ marginBottom: 5 }}
                                    placeholder="Search ..."
                                    value={this.state.clearSearchValue}
                                    onChange={(e) => {
                                        const currValue = e.target.value;
                                        this.search(currValue);
                                        this.setState({
                                            clearSearchValue: currValue
                                        })
                                    }}
                                />
                            </div>
                            <div className='col-md-2' style={{ textAlign: 'end' }}>
                                <button type="button" class="btn btn-primary " onClick={(e) => {
                                    this.setState({
                                        filteredTable: null
                                    }, () => {
                                        this.setState({
                                            clearSearchValue: ''
                                        })

                                    })

                                }} >Clear Search</button>
                            </div>

                        </div>
                        <Table
                            size="small"
                            columns={columns2}
                            // loading={this.state.loading && this.state.rectifierloading && this.state.batteryBanksloading && this.state.airConditionersloading && this.state.spdsloading && this.state.cabinetsloading && this.state.generatorsloading && this.state.atsesloading && this.state.dcdusloading && this.state.fcbsloading && this.state.sharedOutOperatorsesloading}
                            // loading={this.state.tabaleLoading}
                            loading={this.state.tabaleLoading}
                            // dataSource={this.state.allDataModel != null ? (this.state.allDataModel.reverse()) : ([])}
                            dataSource={this.state.filteredTable == null ? this.state.allDataModel?.reverse() : this.state.filteredTable?.reverse()}

                        />


                        <Drawer title={this.state.editFlow ? ('Edit Data') : ('Add Data')} width={"60%"} placement="right" onClose={() => this.onDrawerClose()} visible={this.state.open}>
                            <div className="row" >
                                <div className="col-lg-12">
                                    <div className="card">

                                        <div className="card-body">
                                            <Form
                                                ref={this.formRef}
                                            >

                                                <div className='row'>

                                                    {/*  */}

                                                    <div className="col-md-6">


                                                        <label className="form-label" for="dcdu">
                                                            Generator Model
                                                        </label>
                                                        <Form.Item required >
                                                            <Input id="Name" onChange={this.handleName} value={this.state.modelName} />
                                                            {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                        </Form.Item>



                                                    </div>
                                                    <div className="col-md-6">


                                                        <label className="form-label" for="materialCode">
                                                            Material Code
                                                        </label>
                                                        <Form.Item required >
                                                            <Input id="materialCode" onChange={this.handleMaterialCode} value={this.state.materialCode} />
                                                            {/* <p style={{ color: "#f5325c" }}>{this.validator.message('site_name', this.state.siteName, 'required')}</p> */}
                                                        </Form.Item>



                                                    </div>


                                                </div>











                                            </Form>




                                            <div className='row' style={{ justifyContent: "end" }}>
                                                <div className='col-md-2' >   <button className="btn btn-primary" onClick={this.state.editFlow ? (() => this.editDataSubmit()) : (() => this.AllDataSubmit())} >Submit</button></div>


                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Drawer>


                    </div>
                </div>
            </div>
        )
    }
}
export default GeneratorModel