import React, { useState, useEffect } from 'react';
import {
    CarOutlined,
    NodeIndexOutlined,
    EditOutlined,
    DeleteOutlined,
    EyeOutlined,
    CheckOutlined,
    DashboardOutlined,
    SearchOutlined,
    PhoneOutlined,
    PaperClipOutlined,
    UploadOutlined,
    DownloadOutlined,
    CloudSyncOutlined
} from '@ant-design/icons';
import { Checkbox, Avatar, Card, Skeleton, Switch, Table, Select, Input, Button, Row, Upload, Col, Dropdown, Tag, Modal, Steps, message, Form, DatePicker, TimePicker, Descriptions, Image, Empty } from 'antd';
import {
    getNewVehicleMaster,
    updateMasterRateCardRevamp,
    getAllMasterRateCardRevamp,
    registerMasterRateCard,
    registerMasterRateCardByID,
    updatePoolRateCard,
    getAllVendorPool,
    getAllPoolRateCardByID,
    getAllPoolRateCard,
    createPoolRateCard
} from "../../api/index";
import './style.css';
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;

const ConfigVehicle = () => {
    const [expoertCategory, setExportCategory] = useState('1');
    const [startAndEndDate, setStartAndEndDate] = useState([]);
    const [exportFormat, setExportFormat] = useState('1');
    const [regionList, setRegionList] = useState([]);
    const [vendorName, setVendorName] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [searchData, setSearchData] = useState(null);
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState([]);
    const [mappedData, setMappedData] = useState([]);
    const [buttonVisible, setButtonVisible] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState([]);

    const [vehicleMasters, setVehicleMasters] = useState([]);
    const [getAllmasterRateCard, setGetAllMasterRateCard] = useState([]);
    const [selectedMasterRateCard, setSelectedMasterRateCard] = useState(null);
    const [isMasterCardUpdate, setIsMasterCardUpdate] = useState(false);

    const [allPoolRateData, setAllPoolRateData] = useState([]);
    const [selectedPoolRateCard, setSelectedPoolRateCard] = useState(null);

    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        fetchGetNewVehicleMaster();
        fetchGetAllMasterRateCard();
        fetchGetAllVendorPool();
        fetchGetAllPoolRateCard();
    }, []);

    const openModalData = () => {
        setOpenModal(true);
    }

    const closeModalData = () => {
        setOpenModal(false);
        setSelectedMasterRateCard(null);
        setIsMasterCardUpdate(false);
    }


    const fetchGetAllPoolRateCardByID = async (id) => {
        const response = await getAllPoolRateCardByID(id);
        if (response.success) {
            setSelectedPoolRateCard(response.data.results);
            openModalData();
        } else {
            message.error("Failed to fetch pool rate card");
        }
    };

    const fetchGetAllVendorPool = async () => {
        const response = await getAllVendorPool();
        if (response.success) {
            setVendorName(response.data.results);
        } else {
            message.error("Failed to fetch vendor pool");
        }
    };

    const fetchGetAllPoolRateCard = async () => {
        const response = await getAllPoolRateCard();
        if (response.success) {
            const activeData = response.data.results.filter((item) => item.active);
            setAllPoolRateData(activeData);
        } else {
            message.error("Failed to fetch pool rate card");
        }
    };

    const fetchGetNewVehicleMaster = async () => {
        const response = await getNewVehicleMaster();
        if (response.success) {
            setVehicleMasters(response.data.results);
        } else {
            message.error("Failed to fetch vehicle masters");
        }
    };

    const fetchGetAllMasterRateCard = async () => {
        const response = await getAllMasterRateCardRevamp();
        if (response.success) {
            const activeData = response.data.results.filter((item) => item.active);
            setGetAllMasterRateCard(activeData);
        } else {
            message.error("Failed to fetch master rate card");
        }
    };

    const fetchRegisterMasterRateCard = async (values) => {
        const response = await registerMasterRateCard(values);
        if (response.success) {
            message.success("Master rate card created successfully");
            fetchGetAllMasterRateCard();
            closeModalData();
        } else {
            message.error("Failed to create master rate card");
        }
    };

    const fetchRegisterMasterRateCardByID = async (values) => {
        const response = await registerMasterRateCardByID(values);
        if (response.success) {
            setSelectedMasterRateCard(response.data.results);
            openModalData();
        } else {
            message.error("Failed to update master rate card");
        }
    };

    const fetchUpdateMasterRateCardRevamp = async (id, values) => {
        const response = await updateMasterRateCardRevamp(id, values);
        if (response.success) {
            message.success("Master rate card updated successfully");
            fetchGetAllMasterRateCard();
            closeModalData();
        } else {
            message.error("Failed to update master rate card");
        }
    };

    const fetchCreatePoolRateCard = async (values) => {
        const response = await createPoolRateCard(values);
        console.log(response);
        if (response?.success) {
            message.success("Pool rate card created successfully");
            fetchGetAllPoolRateCard();
            closeModalData();
        } else {
            message.error("Failed to create pool rate card");
        }
    };

    const fetchUpdatePoolRateCard = async (id, values) => {
        const response = await updatePoolRateCard(id, values);
        if (response.success) {
            fetchGetAllPoolRateCard();
            message.success("Pool rate card updated successfully");
            closeModalData();
        } else {
            message.error("Failed to update pool rate card");
        }
    };


    // Confirmation Models

    // const handlefetchUpdatePoolRateCard = async (id, values) => {
    //     const handle = (values) => {
    //         Modal.confirm({
    //             title: "Confirm Update",
    //             content: `Are you sure you want Update This Pool Rate Card?`,
    //             onOk: () => {
    //                 fetchUpdatePoolRateCard(id, values);
    //             },
    //             onCancel: () => { },
    //         });
    //     };

    //     const handleFetchCreatePoolRateCard = (values) => {
    //         Modal.confirm({
    //             title: "Confirm Register",
    //             content: `Are you sure you want Register This Pool Rate Card?`,
    //             onOk: () => {
    //                 fetchCreatePoolRateCard(values);
    //             },
    //             onCancel: () => { },
    //         });
    //     };

    //     const handleFetchRegisterMasterRateCardByID = (values) => {
    //         Modal.confirm({
    //             title: "Confirm Register",
    //             content: `Are you sure you want Register This Master Rate Card?`,
    //             onOk: () => {
    //                 fetchRegisterMasterRateCardByID(values);
    //             },
    //             onCancel: () => { },
    //         });
    //     };

    //     const handleFetchUpdateMasterRateCardRevamp = (id, values) => {
    //         Modal.confirm({
    //             title: "Confirm Update",
    //             content: `Are you sure you want to Update This Master Rate Card?`,
    //             onOk: () => {
    //                 fetchUpdateMasterRateCardRevamp(id, values);
    //             },
    //             onCancel: () => { },
    //         });
    //     };


        const prepareUpdateData = (id) => {
            fetchRegisterMasterRateCardByID(id);
            setIsMasterCardUpdate(true);
        }

        const preparePoolDataUpdate = (id) => {
            fetchGetAllPoolRateCardByID(id);
            setIsMasterCardUpdate(true);
        }

        const poolRateCardCreate = (values) => {
            // end date must be after than starttime
            if (values?.startDate[0]?.isAfter(values?.startDate[1])) {
                message.error("End date must be after than start date");
                return;
            } else {
                if (isMasterCardUpdate) {
                    const formData = new FormData();
                    formData.append('Request', JSON.stringify({
                        excessMileageRate: values.excessMileageRate ? values.excessMileageRate : 0,
                        vendorPoolId: values.vendorPoolId,
                        startDate: values.startDate[0].format('YYYY-MM-DD'),
                        endDate: values.startDate[1].format('YYYY-MM-DD')
                    }));
                    fetchUpdatePoolRateCard(selectedPoolRateCard.id, formData);
                    //handlefetchUpdatePoolRateCard(selectedPoolRateCard.id, formData);
                } else {
                    const formData = new FormData();
                    formData.append('Request', JSON.stringify({
                        excessMileageRate: values.excessMileageRate ? values.excessMileageRate : 0,
                        vendorPoolId: values.vendorPoolId,
                        startDate: values.startDate[0].format('YYYY-MM-DD'),
                        endDate: values.startDate[1].format('YYYY-MM-DD')
                    }));
                    fetchCreatePoolRateCard(formData);
                    //handleFetchCreatePoolRateCard(formData);
                }
            }
        }


        const masterRateCardCreate = (values) => {
            // end date must be after than starttime
            if (values?.startDate[0].isAfter(values?.startDate[1])) {
                message.error("End date must be after than start date");
                return;
            } else {
                if (isMasterCardUpdate) {
                    const formData = new FormData();
                    formData.append('Request', JSON.stringify({
                        baseRental: values.baseRental ? values.baseRental : 0,
                        notRate: values.notRate ? values.notRate : 0,
                        dotRate: values.dotRate ? values.dotRate : 0,
                        accomodationRate: values.accomodationRate ? values.accomodationRate : 0,
                        vehicleMasterId: values.vehicleMasterId,
                        startDate: values.startDate[0].format('YYYY-MM-DD'),
                        endDate: values.startDate[1].format('YYYY-MM-DD')
                    }));
                    fetchUpdateMasterRateCardRevamp(selectedMasterRateCard.id, formData);
                    // handleFetchUpdateMasterRateCardRevamp(selectedMasterRateCard.id, formData);
                } else {
                    const formData = new FormData();
                    formData.append('Request', JSON.stringify({
                        baseRental: values.baseRental ? values.baseRental : 0,
                        notRate: values.notRate ? values.notRate : 0,
                        dotRate: values.dotRate ? values.dotRate : 0,
                        accomodationRate: values.accomodationRate ? values.accomodationRate : 0,
                        vehicleMasterId: values.vehicleMasterId,
                        startDate: values.startDate[0].format('YYYY-MM-DD'),
                        endDate: values.startDate[1].format('YYYY-MM-DD')
                    }));
                    fetchRegisterMasterRateCard(formData);
                    // handleFetchRegisterMasterRateCardByID(formData);
                }
            }
        }

        const poolCartDataCol = [
            {
                title: 'Excess Mileage Rate',
                dataIndex: 'excessMileageRate',
                key: 'excessMileageRate',
            },
            {
                title: 'Vendor Pool',
                dataIndex: 'vendorPool',
                key: 'vendorPool',
                render: (text, record) => (
                    <span>
                        {record.vendorPool.name}
                    </span>
                )
            },
            {
                title: 'Start Date',
                dataIndex: 'startDate',
                key: 'startDate',
            },
            {
                title: 'End Date',
                dataIndex: 'endDate',
                key: 'endDate',
            },
            {
                title: 'Active',
                dataIndex: 'active',
                key: 'active',
                render: (text, record) => (
                    <span>
                        {record.active ? "Active" : "Inactive"}
                    </span>
                )
            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: 'id',
                render: (text, record) => (
                    <Button
                        type="circle"
                        style={{ backgroundColor: "#EBEFFF", width: "25px", height: "25px" }}
                        icon={<EditOutlined style={{ color: "#2C5EF4", fontWeight: "bold", fontSize: "10px" }} />}
                        onClick={() => preparePoolDataUpdate(record.id)}
                        size="small"
                    />
                ),
            },
        ]

        const masterDataCol = [
            {
                title: 'Base Rental',
                dataIndex: 'baseRental',
                key: 'baseRental',
            },
            {
                title: 'Not Rate',
                dataIndex: 'notRate',
                key: 'notRate',
            },
            {
                title: 'DOT Rate',
                dataIndex: 'dotRate',
                key: 'dotRate',
            },
            {
                title: 'Accomodation Rate',
                dataIndex: 'accomodationRate',
                key: 'accomodationRate',
            },
            {
                title: 'Vehicle Master',
                dataIndex: 'vehicleMaster',
                key: 'vehicleMaster',
                render: (text, record) => (
                    <span>
                        {record.vehicleMaster.code}
                    </span>
                )
            },
            {
                title: 'Start Date',
                dataIndex: 'startDate',
                key: 'startDate',
            },
            {
                title: 'End Date',
                dataIndex: 'endDate',
                key: 'endDate',
            },
            {
                title: 'Active',
                dataIndex: 'active',
                key: 'active',
                render: (text, record) => (
                    <span>
                        {record.active ? "Active" : "Inactive"}
                    </span>
                )
            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: 'id',
                render: (text, record) => (
                    <Button
                        type="circle"
                        style={{ backgroundColor: "#EBEFFF", width: "25px", height: "25px" }}
                        icon={<EditOutlined style={{ color: "#2C5EF4", fontWeight: "bold", fontSize: "10px" }} />}
                        onClick={() => prepareUpdateData(record.id)}
                        size="small"
                    />
                ),
            },
        ];

        return (
            <body>
                <div style={{ margin: '0', padding: '10px', height: '100%' }}>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="page-title-box">
                                <div className="row">
                                    <div className="col">
                                        <h4 className="page-title" style={{ fontWeight: "bold" }}>
                                            Master Data
                                        </h4>
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <a href="javascript:void(0);">Vehicle </a>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <a href="javascript:void(0);">Master Data</a>
                                            </li>
                                        </ol>
                                    </div>
                                    <div className="col-auto align-self-center">
                                        <a
                                            href="#"
                                            className="btn btn-sm btn-outline-primary"
                                            id="Dash_Date"
                                        >
                                            <span className="day-name" id="Day_Name">
                                                Today:
                                            </span>
                                            &nbsp;
                                            <span className="" id="Select_date">
                                                {new Date().toUTCString().slice(5, 16)}
                                            </span>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                className="feather feather-calendar align-self-center icon-xs ms-1"
                                            >
                                                <rect
                                                    x="3"
                                                    y="4"
                                                    width="18"
                                                    height="18"
                                                    rx="2"
                                                    ry="2"
                                                ></rect>
                                                <line x1="16" y1="2" x2="16" y2="6"></line>
                                                <line x1="8" y1="2" x2="8" y2="6"></line>
                                                <line x1="3" y1="10" x2="21" y2="10"></line>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{
                        backgroundColor: "#FFFFFF",
                        padding: "20px 20px 20px 20px",
                        borderRadius: "10px",
                        marginTop: "5px",
                    }}>


                        <div style={{ padding: 10, borderRadius: 10 }}>
                            <Row>
                                <Col span={12}>
                                    <Select placeholder="Export Category"
                                        className='borderedSelect'
                                        style={{ width: '220px' }}
                                        onChange={(value) => setExportCategory(value)}
                                        defaultValue={expoertCategory}
                                        bordered={false}
                                    >
                                        <Option value="1" className="textStyle-small">Master Rate Card</Option>
                                        <Option value="2" className="textStyle-small">Pool Rate Card</Option>
                                    </Select>
                                </Col>
                                <Col span={12}>
                                    <div style={{ float: "right" }}>
                                        {
                                            expoertCategory === '1' ?
                                                <Button type="primary"
                                                    onClick={openModalData} style={{ borderRadius: "5px" }}>
                                                    <span className='textStyle-small'>Create Master Rate Card</span>
                                                </Button>
                                                : <Button type="primary" onClick={openModalData} style={{ borderRadius: "5px" }}>
                                                    <span className='textStyle-small'>Create Pool Rate Card</span>
                                                </Button>
                                        }
                                    </div>
                                </Col>
                            </Row>

                            <Row style={{ marginTop: "10px" }}>
                                <Col span={24}>
                                    <div style={{
                                        backgroundColor: "#fafafa",
                                        padding: "10px 10px 10px 10px",
                                        borderRadius: "10px",
                                        marginTop: "5px",

                                    }}>
                                        <div>
                                            {
                                                expoertCategory === '1' ?
                                                    <>
                                                        <h5 style={{ fontWeight: "bold" }}>Master Rate Card</h5>
                                                        <Table
                                                            style={{ marginTop: "10px" }}
                                                            className="table-striped-rows"
                                                            dataSource={getAllmasterRateCard ? getAllmasterRateCard : []}
                                                            columns={masterDataCol}
                                                            rowKey="key"
                                                            size="small"
                                                        />
                                                    </> : <>
                                                        <h5 style={{ fontWeight: "bold" }}>Pool Rate Card</h5>
                                                        <Table
                                                            style={{ marginTop: "10px" }}
                                                            className="table-striped-rows"
                                                            dataSource={allPoolRateData ? allPoolRateData : []}
                                                            columns={poolCartDataCol}
                                                            rowKey="key"
                                                            size="small"
                                                        />
                                                    </>
                                            }
                                        </div>

                                        <Modal
                                            title={expoertCategory === '1' ? "Master Rate Card" : "Pool Rate Card"}
                                            visible={openModal}
                                            onOk={() => closeModalData()}
                                            onCancel={() => closeModalData()}
                                            destroyOnClose={true}
                                            width={800}
                                            footer={null}
                                        >
                                            {
                                                expoertCategory === '1' ?
                                                    <>
                                                        <Form
                                                            layout="vertical"
                                                            hideRequiredMark
                                                            onFinish={masterRateCardCreate}
                                                        >
                                                            <Row>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        name="baseRental"
                                                                        label={<span className='textStyle-small'>Base Rental</span>}
                                                                        rules={[{ required: true, message: 'Please enter base rental' }]}
                                                                        initialValue={selectedMasterRateCard ? selectedMasterRateCard.baseRental : ''}
                                                                    >
                                                                        <Input placeholder="Base Rental"
                                                                            className='borderedSelect'
                                                                            type='number'
                                                                            style={{ width: '80%', height: '30px' }}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        name="notRate"
                                                                        label={<span className='textStyle-small'>Not Rate</span>}
                                                                        rules={[{ required: true, message: 'Please enter not rate' }]}
                                                                        initialValue={selectedMasterRateCard ? selectedMasterRateCard.notRate : ''}
                                                                    >
                                                                        <Input placeholder="Not Rate"
                                                                            className='borderedSelect'
                                                                            type='number'
                                                                            style={{ width: '80%', height: '30px' }}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        name="dotRate"
                                                                        label={<span className='textStyle-small'>DOT Rate</span>}
                                                                        rules={[{ required: true, message: 'Please enter DOT rate' }]}
                                                                        initialValue={selectedMasterRateCard ? selectedMasterRateCard.dotRate : ''}
                                                                    >
                                                                        <Input placeholder="DOT Rate"
                                                                            className='borderedSelect'
                                                                            type='number'
                                                                            style={{ width: '80%', height: '30px' }}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        name="accomodationRate"
                                                                        label={<span className='textStyle-small'>Accomodation Rate</span>}
                                                                        rules={[{ required: true, message: 'Please enter accomodation rate' }]}
                                                                        initialValue={selectedMasterRateCard ? selectedMasterRateCard.accomodationRate : ''}
                                                                    >
                                                                        <Input placeholder="Accomodation Rate"
                                                                            className='borderedSelect'
                                                                            type='number'
                                                                            style={{ width: '80%', height: '30px' }}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        name="vehicleMasterId"
                                                                        label={<span className='textStyle-small'>Vehicle Master</span>}
                                                                        rules={[{ required: true, message: 'Please enter vehicle master' }]}
                                                                        initialValue={selectedMasterRateCard?.vehicleMaster.id}
                                                                    >
                                                                        <Select
                                                                            showSearch
                                                                            placeholder="Select Vehicle Master"
                                                                            optionFilterProp="children"
                                                                            filterOption={(input, option) =>
                                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                            className='borderedSelect'
                                                                            bordered={false}
                                                                            style={{ width: '80%', height: '30px' }}
                                                                        >
                                                                            {vehicleMasters.map((item, index) => (
                                                                                <Option key={index} value={item.id}>
                                                                                    {item.code}
                                                                                </Option>
                                                                            ))}
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        name="startDate"
                                                                        label={<span className='textStyle-small'>Start Date and End Date</span>}
                                                                        rules={[{ required: true, message: 'Please select start date' }]}
                                                                        initialValue={[moment(selectedMasterRateCard?.startDate), moment(selectedMasterRateCard?.endDate)]}
                                                                    >
                                                                        <RangePicker style={{ width: '80%', height: '30px' }}
                                                                            className='borderedSelect' />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Button type="primary" htmlType="submit" style={{ borderRadius: "5px" }} >
                                                                    {
                                                                        isMasterCardUpdate ? <span className='textStyle-small'>Update Master Rate Card</span> :
                                                                            <span className='textStyle-small'>Create Master Rate Card</span>
                                                                    }
                                                                </Button>
                                                            </Row>
                                                        </Form>
                                                    </> : <>
                                                        {/* {"excessMileageRate":500,"vendorPoolId":1,"startDate":"2024-03-06","endDate":"2025-03-06"} */}
                                                        <Form
                                                            layout="vertical"
                                                            hideRequiredMark
                                                            onFinish={poolRateCardCreate}
                                                        >
                                                            <Row>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        name="excessMileageRate"
                                                                        label={<span className='textStyle-small'>Excess Mileage Rate</span>}
                                                                        rules={[{ required: true, message: 'Please enter excess mileage rate' }]}
                                                                        initialValue={selectedPoolRateCard?.excessMileageRate}
                                                                    >
                                                                        <Input
                                                                            style={{ width: '80%', height: '30px' }}
                                                                            placeholder="Excess Mileage Rate"
                                                                            className='borderedSelect'
                                                                            type='number'
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        name="vendorPoolId"
                                                                        label={<span className='textStyle-small'>Vendor Pool</span>}
                                                                        rules={[{ required: true, message: 'Please enter vendor pool' }]}
                                                                        initialValue={selectedPoolRateCard?.vendorPool.id}
                                                                    >
                                                                        <Select
                                                                            showSearch
                                                                            placeholder={<span className='textStyle-small'>Select Vendor Pool</span>}
                                                                            optionFilterProp="children"
                                                                            filterOption={(input, option) =>
                                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                            className='borderedSelect'
                                                                            bordered={false}
                                                                            style={{ width: '80%', height: '30px' }}
                                                                        >
                                                                            {vendorName.map((item, index) => (
                                                                                <Option key={index} value={item.id}>
                                                                                    {item.name}
                                                                                </Option>
                                                                            ))}
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        name="startDate"
                                                                        label={<span className='textStyle-small'>Start Date and End Date</span>}
                                                                        rules={[{ required: true, message: 'Please select start date' }]}
                                                                        initialValue={[moment(selectedPoolRateCard?.startDate), moment(selectedPoolRateCard?.endDate)]}
                                                                    >
                                                                        <RangePicker style={{ width: '80%', height: '30px' }} className='borderedSelect' />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Button type="primary" htmlType="submit" style={{ borderRadius: "5px" }} >
                                                                    {
                                                                        isMasterCardUpdate ?
                                                                            <span className='textStyle-small'>
                                                                                Update Pool Rate Card
                                                                            </span> : <span>
                                                                                <span className='textStyle-small'>Create Pool Rate Card</span>
                                                                            </span>
                                                                    }
                                                                </Button>
                                                            </Row>

                                                        </Form>
                                                    </>
                                            }
                                        </Modal>
                                    </div>
                                </Col>
                            </Row>
                        </div> <br />
                    </div>
                </div>

            </body>

        )
    }


export default ConfigVehicle;