import {get , post} from '../api.client'
import { serviceCommon, serviceOne,serviceUser} from "../api.config";
 
 

export async function getSiteDetails(id) {  
	try { 
		var response;
		const res = await get(serviceCommon+'/site/getById/'+id); 
		 if (res.status == 200) { 
			  response = {
				  "success" : true, 
				  "data" : res.data
			  }
			 
			return response;
		 } else { 
			//throw new Error(texts.DEFAULT_ERROR_MESSAGE)
			 response = {
				"success" : false 
			}
			return response;
		}
	} catch (err) {
		return false;
		throw err;
	}
   }
 

   export async function getSiteListByPage(page_no) {  
	try { 
		var response;
		const res = await get(serviceCommon+'/site/getPage/'+page_no+'/20');  
		 if (res.status == 200) { 
			  response = {
				  "success" : true, 
				  "data" : res.data
			  }
			 
			return response;
		 } else { 
			//throw new Error(texts.DEFAULT_ERROR_MESSAGE)
			 response = {
				"success" : false 
			}
			return response;
		}
	} catch (err) {
		return false;
		throw err;
	}
   }


   export async function filterSiteData(data) {  
	try { 
		var response;
		const res = await post(serviceCommon+'/site/filterSites' , data);  
		 if (res.status == 200) { 
			  response = {
				  "success" : true, 
				  "data" : res.data
			  }
			 
			return response;
		 } else { 
			//throw new Error(texts.DEFAULT_ERROR_MESSAGE)
			 response = {
				"success" : false 
			}
			return response;
		}
	} catch (err) {
		return false;
		throw err;
	}
   }