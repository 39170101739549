import React, { Component } from 'react'
import { Table, Tag, Button, Drawer, Form, Input, Select, InputNumber, DatePicker, Popconfirm, message, Row, Col, Divider, Card } from 'antd';
import { PlusCircleOutlined, DeleteOutlined, EditOutlined, HomeOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import checkPermissions from '../../utils/permissionManager';
import { createNewBatteryRack, getBatteryRack, UpdateBatteryRack } from "../../api/index";
import { checkServiceType } from "../../actions/service-type";
import AssetDelete from "../site_components/asset_delete";

class iDBatteryRacks extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            title: "",
            tabaleLoading: false,
            actionView: false,
            showModal: false,
            open: false,
            openEdit: false,
            selectedAssetId: null,
            AssetType: [],
            dcduType: '',
            dcduCapacity: '',
            iDRModelCount: '',
            ibridbatteryrackModel: '',
            dcduFreeBreakerSlots: '',
            dcduPowerFromLLVD: '',
            dcduNoOfBreakerSlots: '',
            rbcapacityToPowerDCDU: '',
            AssetManagementPermission: false,
            openViewMoreDetails: false,
            selectedElement: "",

            dropdownObjIDBR: {
                "ibridbatteryrackModel": "",
                "iDRModelCount": "",
            },
        };
        this.requestFromClose = this.requestFromClose.bind(this);
        this.handleDcduType = this.handleDcduType.bind(this);
        this.handleDcduCapacity = this.handleDcduCapacity.bind(this);
        this.handleIdbatteryRackModelCount = this.handleIdbatteryRackModelCount.bind(this);
        this.handleidbatteryrackModel = this.handleidbatteryrackModel.bind(this);
        this.handleFreeBreakerSlots = this.handleFreeBreakerSlots.bind(this);
        this.handlePowerFromLLVD = this.handlePowerFromLLVD.bind(this);
        this.handleNoOfBreakerSlots = this.handleNoOfBreakerSlots.bind(this);
        this.handleRbcapacityToPowerDCDU = this.handleRbcapacityToPowerDCDU.bind(this);

    }
    handleidbatteryrackModel(e) {
        //console.log(e.target.value)
        this.setState({ ibridbatteryrackModel: e });
    }
    dcduUpdata(id) {
        // console.log('dfdfd')
        console.log(id)
        this.getDataDcduDetails(id);
        this.setState({ openEdit: true });
    }
    getDataDcduDetails = async (id) => {
        var res = await getBatteryRack(id);
        console.log(res);
        if (res.success) {
            this.setState({
                dcduEdit: res.data,
                dcduId: res.data?.id,
                iDRModelCount: res.data?.idbatteryRackModelCount,
                ibridbatteryrackModel: res.data?.idbatteryrackModel,
            })

        }
    }
    handleRbcapacityToPowerDCDU(e) {
        console.log(e.target.value)
        // console.log(e)
        this.setState({ rbcapacityToPowerDCDU: e.target.value });
    }
    handleNoOfBreakerSlots(e) {
        // console.log('ddddddd')
        // console.log(e)
        this.setState({ dcduNoOfBreakerSlots: e });
    }
    handlePowerFromLLVD(e) {
        console.log(e.target.value)
        this.setState({ dcduPowerFromLLVD: e.target.value });
    }
    handleFreeBreakerSlots(e) {
        //console.log(e.target.value)
        this.setState({ dcduFreeBreakerSlots: e });
    }
    handleDcduType(e) {
        //console.log(e.target.value)
        this.setState({ dcduType: e.target.value });
    }
    handleIdbatteryRackModelCount(e) {
        this.setState({ iDRModelCount: e });
    }
    handleDcduCapacity(e) {
        console.log(e)
        this.setState({ dcduCapacity: e });
    }
    componentDidMount() {
        console.log('tytqwe')
        console.log(this.props.detailsSite)
        if (JSON.parse(localStorage.getItem('serviceTypeSet')) == null) {
            const { history } = this.props;

            history.push("/");

        } else {

            this.getSetPageData(JSON.parse(localStorage.getItem('serviceTypeSet')), 38)

        }
        checkServiceType(38).then((data) => this.setState({
            AssetManagementPermission: data
        }, () => {
        }),

        )
        // console.log(this.props.details2)

    }
    getSetPageData = (serviceTypeIs, Pageid) => {

        if (serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == Pageid)[0] != null) {
            console.log('fddf');
            this.setState({
                actionView: true,
            })
        }

    };
    requestFromClose() {
        console.log('dsdsd')
        this.setState({ showModal: true, open: true });
    }
    onDrawerClose = () => {
        console.log('sddsds')
        this.formRef.current.resetFields();
        this.validator.hideMessages();
        this.setState({
            open: false,
            openEdit: false,
            selectedAssetId: null,
            AssetType: [],
            dcduType: '',
            dcduCapacity: '',
            dcduFreeBreakerSlots: '',
            dcduPowerFromLLVD: '',
            dcduNoOfBreakerSlots: '',
            rbcapacityToPowerDCDU: '',
            iDRModelCount: '',
            ibridbatteryrackModel: '',

        })

    }
    onChangeAsset = (value) => {
        console.log("onChangeAsset");
        //console.log(value);
        this.setState({
            selectedAssetId: value,
            AssetType: {
                "id": value
            },

        })
    };
    AllDataSubmitUpdate = async () => {

        if (this.validator.allValid()) {
            var dataSet;
            console.log('ssss')
            dataSet = {
                "id": this.state.dcduId,
                "site": { "id": this.props?.detailsSite?.site_details.id },
                "asset": {
                    "id": 2
                },
                "idbatteryRackModelCount": this.state.iDRModelCount,
                "idbatteryrackModel": this.state.ibridbatteryrackModel,

            }
            console.log(dataSet)
            var res = await UpdateBatteryRack(dataSet);
            console.log(res)
            if (res.success) {
                message.success('Successfully Battery Rack Edited!')
                this.formRef.current.resetFields();

                this.setState({
                    showModal: false,
                    open: false,
                    openEdit: false,

                    selectedAssetId: null,
                    AssetType: [],
                    dcduType: '',
                    dcduCapacity: '',
                    dcduFreeBreakerSlots: '',
                    dcduPowerFromLLVD: '',
                    dcduNoOfBreakerSlots: '',
                    rbcapacityToPowerDCDU: '',
                    iDRModelCount: '',
                    ibridbatteryrackModel: '',



                })
                this.props.parentMethod();

            } else {
                message.warning(' Something went wrong!')
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }

    }
    AllDataSubmit = async () => {
        if (this.validator.allValid()) {
            var dataSet;
            console.log(this.state.AssetType)
            dataSet = {

                "site": { "id": this.props?.detailsSite?.site_details.id },

                "idbatteryRackModelCount": this.state.iDRModelCount,
                "idbatteryrackModel": this.state.ibridbatteryrackModel,


            }
            console.log(dataSet)
            var res = await createNewBatteryRack(dataSet);
            if (res.success) {
                message.success('Successfully Battery Rack Added!')
                this.formRef.current.resetFields();

                this.setState({
                    showModal: false,
                    open: false,
                    selectedAssetId: null,
                    AssetType: [],
                    dcduType: '',
                    dcduCapacity: '',
                    dcduFreeBreakerSlots: '',
                    dcduPowerFromLLVD: '',
                    dcduNoOfBreakerSlots: '',
                    rbcapacityToPowerDCDU: '',
                    iDRModelCount: '',
                    ibridbatteryrackModel: '',
                })
                this.props.parentMethod();
                // const { history } = this.props;
                // history.push("/site");
            } else {
                message.warning(' Something went wrong!')
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }

    viewMoreDetails(element) {
        this.setState({
            showModal2: true,
            openViewMoreDetails: true,
            selectedElement: element
        });
    }

    onViewMoreDetailsDrawerClose = () => {
        this.setState({
            showModal2: false,
            openViewMoreDetails: false,
        })
    }

    validatedTextLable = (field) => {
        return (field !== null && field !== '')
            ? <span class="badge badge-outline-secondary text-muted">{(field)}</span>
            : <span class="badge text-danger">Currently Not Set</span>;
    }
    submitForm = async (item) => {
        this.getDataIdBatteryRaxkDetailsForDelete(item);

    }
    getDataIdBatteryRaxkDetailsForDelete = async (id) => {

        var res = await getBatteryRack(id);
        console.log(res);
        if (res.success) {
            var respons = AssetDelete.Action(res.data, this.props?.detailsSite?.site_details.id, UpdateBatteryRack)

            respons.then((value) => {
                if (value == 1) {
                    message.success('Successfully Delete!')
                    this.props.parentMethod();
                } else {
                    message.warning(' Something went wrong!')
                }
            });
        }
    }
    render() {
        const { Option } = Select;

        const _idBatteryRacks = this.state.selectedElement
        const columns2 = [
            {
                title: "ID Battery Rack Model 1",
                dataIndex: "idbatteryrackModel",
                key: "idbatteryrackModel",
                width: '100px',
                content: <span class="badge badge-outline-info badge-pill"><i class="fas fa-hdd" style={{ paddingRight: "5px" }} />{_idBatteryRacks.idbatteryrackModel}</span>,
                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
            },
            {
                title: "ID Battery Rack Model 1 Count",
                dataIndex: "idbatteryRackModelCount",
                key: "idbatteryRackModelCount",
                fixed: 'left',
                width: '80px',
                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
                content: <Tag color="gray"><span>{_idBatteryRacks.idbatteryRackModelCount}</span></Tag>,
            }
        ];
        return (
            <>
                <div className='row'>
                    <div class="col-lg-12">
                        {
                            checkPermissions('SITE_VIEW_GENERAL_USER') || checkPermissions('SITE_VIEW_ADMIN_USER') ? (

                                <div class="text-end">
                                    <Button
                                        type="button"
                                        class="btn btn-soft-primary btn-sm"
                                        style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px' }}
                                        onClick={this.requestFromClose}
                                        icon={<PlusCircleOutlined style={{ color: 'white' }} />}>
                                        <span className="textStyles-small" style={{ color: 'white' }}>New Asset</span>
                                    </Button>
                                </div>) : ('')}
                    </div>

                    <Row span={12}>
                        {this.props.details2.idBatteryRacks_data.map((element, index) => (
                            <Col span={8} key={index}>
                                <Card
                                    bordered={false}
                                    style={{
                                        width: '98%',
                                        borderRadius: '10px',
                                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                                        position: 'relative',
                                        marginTop: 10
                                    }}
                                >
                                    <div style={{ display: 'flex', marginBottom: 16, backgroundColor: '#f8f9fa', padding: '10px', borderRadius: '10px' }}>
                                        <div>
                                            <HomeOutlined style={{ marginLeft: '3px', marginRight: '3px' }} /> &rarr;
                                            <span style={{ marginLeft: '3px', marginRight: '3px', fontWeight: 'bold' }} className="textStyles-small">
                                                {this.props?.detailsSite?.site_details?.siteName?.substring(0, 11)}
                                            </span>
                                            &rarr;
                                            <span style={{ marginLeft: '3px', color: '#BA183A', fontWeight: 'bold' }} className="textStyles-small">{element?.idbatteryrackModel?.substring(0, 18)}</span>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: 8 }}>
                                        <span className="textStyles-small" style={{ fontSize: 12 }}>No of Battery Racks in Same Model</span>
                                        <Tag color="default" style={{ float: 'right', borderRadius: 5 }}> <span className="textStyles-small">
                                            {element.idbatteryRackModelCount}
                                        </span></Tag>
                                    </div>
                                    <div style={{ marginBottom: 8 }}>
                                        <span className="textStyles-small">Model</span>
                                        <Tag color="blue" style={{ float: 'right', borderRadius: 5 }} tooltip="This is a required field">
                                            <span className="textStyles-small" style={{ fontSize: 11 }}>
                                                {element.idbatteryrackModel}
                                            </span></Tag>
                                    </div>
                                    <Divider style={{ marginTop: '3px' }} />
                                    <Button
                                        type="primary"
                                        style={{ marginRight: 8, borderRadius: '5px' }}
                                        size='small'
                                        onClick={() => this.viewMoreDetails(element)}>
                                        <span className="textStyles-small" style={{ fontSize: 10 }}> <i class="mdi mdi mdi-unfold-more-horizontal"></i> More Details</span>
                                    </Button>
                                    {checkPermissions('SITE_VIEW_GENERAL_USER') || checkPermissions('SITE_VIEW_ADMIN_USER') ? (
                                        <Button
                                            icon={<EditOutlined style={{ color: 'white' }} />}
                                            style={{ marginRight: 8, float: 'right', borderRadius: 5, backgroundColor: '#F28502', borderColor: '#F28502' }}
                                            onClick={() => this.dcduUpdata(element.id)}
                                            size='small'
                                        />) : ('')}
                                    <Popconfirm
                                        title={<span className='textStyles-small' style={{ fontSize: 14 }}>Are you sure to delete this Asset?</span>}
                                        description={<span className="textStyles-small">Are you sure to delete this Asset?</span>}
                                        onConfirm={() => this.submitForm(element.id)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        {checkPermissions('SITE_VIEW_ADMIN_USER')
                                            ? (
                                                <Button
                                                    icon={<DeleteOutlined style={{ color: 'white' }} />}
                                                    danger
                                                    size='small'
                                                    style={{ marginRight: 8, float: 'right', borderRadius: 5, backgroundColor: '#BA183A', borderColor: '#BA183A' }}
                                                />
                                            )
                                            : ('')}
                                    </Popconfirm>

                                </Card>
                            </Col>

                        ))}
                    </Row>

                    <Drawer
                        maskClosable={false}
                        title={<span className="textStyles-small"
                            style={{ fontSize: 14 }}>ID Battery Rack Details</span>}
                        width={"50%"}
                        placement="right" onClose={() => this.onViewMoreDetailsDrawerClose()}
                        visible={this.state.openViewMoreDetails} closeIcon={<button type="button"
                            class="btn btn-soft-danger btn-icon-square-sm" style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                        <div class="row">
                            <div class="col-lg-12">
                                <ul class="list-group">
                                    {columns2.map((item) => (
                                        <li class="d-flex list-group-item justify-content-between align-items-center">
                                            <div>
                                                <span className='textStyles-small' style={{ fontSize: 12 }}>
                                                    {item.title}
                                                </span>
                                            </div>
                                            <span className='textStyles-small' style={{ fontSize: 12 }}>
                                                {item.content}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </Drawer>

                    <Drawer maskClosable={false} title={<span className="textStyles-small" style={{ fontSize: 14 }}>New Id Battery Racks</span>}
                        width={"50%"} placement="right" onClose={() => this.onDrawerClose()}
                        visible={this.state.open} closeIcon={<button type="button" class="btn btn-soft-danger btn-icon-square-sm" style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                        <div className="row" >
                            <div className="col-lg-12">
                                <div className="card" style={{ borderRadius: "10px", borderColor: "white" }}>
                                    <div className="card-body">
                                        <Form
                                            ref={this.formRef}
                                        >
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="dcdu">
                                                        <span className="textStyles-small">
                                                            ID Battery Rack Model 1 <x style={{ color: "red" }}>*</x>
                                                        </span>
                                                    </label>
                                                    <Form.Item required name="idbatteryrackModel" >
                                                        <div>
                                                            <Select
                                                                showSearch
                                                                bordered={false}
                                                                className='borderedSelect'
                                                                style={{ width: "100%" }}
                                                                id='idbatteryrackModel'
                                                                onChange={(e) => {
                                                                    this.handleidbatteryrackModel(e);
                                                                }}>
                                                                {this.props.dropdown["dropdownObjIDBR"]["ibridbatteryrackModel"]?.map((x, y) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Form.Item>
                                                    <p style={{ color: "#f5325c", marginTop: 5 }} className='textStyles-small'>
                                                        {this.validator.message(' Id batteryrack Model', this.state.ibridbatteryrackModel, 'required')}</p>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        <span className="textStyles-small">
                                                            ID Battery Rack Model 1 Count <x style={{ color: "red" }}>*</x>
                                                        </span>
                                                    </label>
                                                    <Form.Item required name="idbatteryRackModelCount" >
                                                        <div>
                                                            <Select
                                                                bordered={false}
                                                                className='borderedSelect'
                                                                style={{ width: "100%" }}
                                                                id='idbatteryRackModelCount'
                                                                onChange={(e) => {
                                                                    this.handleIdbatteryRackModelCount(e);
                                                                }}>
                                                                {this.props.dropdown["dropdownObjIDBR"]["iDRModelCount"]?.map((x, y) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Form.Item>
                                                    <p style={{ color: "#f5325c", marginTop: 5 }} className='textStyles-small'>
                                                        {this.validator.message('Id battery Rack Model Count', this.state.iDRModelCount, 'required')}</p>
                                                </div>
                                            </div>
                                        </Form>
                                        <div className='row' style={{ justifyContent: "end" }}>
                                            <div className='col-md-2' >
                                                <Button className="btn btn-primary"
                                                    style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', float: 'right' }}
                                                    onClick={() => this.AllDataSubmit()} >
                                                    <span className="textStyles-small" style={{ color: 'white' }}>Add This Asset</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Drawer>

                    <Drawer maskClosable={false} title={<span className="textStyles-small"
                        style={{ fontSize: 14 }}>Edit Id Battery Racks</span>} width={"70%"}
                        placement="right" onClose={() => this.onDrawerClose()}
                        visible={this.state.openEdit}
                        closeIcon={<button type="button"
                            class="btn btn-soft-danger btn-icon-square-sm"
                            style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                        <div className="row" >
                            <div className="col-lg-12">
                                <div className="card" style={{ borderColor: "white" }}>
                                    <div className="card-body">
                                        <Form
                                            ref={this.formRef}
                                        >
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="dcdu">
                                                        <span className="textStyles-small">
                                                            ID Battery Rack Model 1<x style={{ color: "red" }}>*</x>
                                                        </span>
                                                    </label>
                                                    <Form.Item >
                                                        <div>
                                                            <Select
                                                                showSearch
                                                                style={{ width: "100%" }}
                                                                bordered={false}
                                                                className='borderedSelect'
                                                                id='idbatteryrackModel'
                                                                value={this.state.ibridbatteryrackModel}
                                                                onChange={(e) => {
                                                                    this.handleidbatteryrackModel(e);
                                                                }}>
                                                                {this.props.dropdown["dropdownObjIDBR"]["ibridbatteryrackModel"]?.map((x, y) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Form.Item>
                                                    <p style={{ color: "#f5325c", marginTop: 5 }} className='textStyles-small'>
                                                        {this.validator.message(' Id batteryrack Model', this.state.ibridbatteryrackModel, 'required')}</p>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        <span className="textStyles-small">
                                                            ID Battery Rack Model 1 Count  <x style={{ color: "red" }}>*</x>
                                                        </span>
                                                    </label>
                                                    <Form.Item >
                                                        <div>
                                                            <Select
                                                                style={{ width: "100%" }}
                                                                bordered={false}
                                                                className='borderedSelect'
                                                                value={this.state.iDRModelCount}
                                                                id='idbatteryRackModelCount'
                                                                onChange={(e) => {
                                                                    this.handleIdbatteryRackModelCount(e);
                                                                }}>
                                                                {this.props.dropdown["dropdownObjIDBR"]["iDRModelCount"]?.map((x, y) => {
                                                                    return <Option value={x}>{x}</Option>
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </Form.Item>
                                                    <p style={{ color: "#f5325c", marginTop: 5 }} className='textStyles-small'>
                                                        {this.validator.message('Id battery Rack Model Count', this.state.iDRModelCount, 'required')}</p>
                                                </div>
                                            </div>
                                        </Form>
                                        <div className='row' style={{ justifyContent: "end" }}>
                                            <div className='col-md-2' >
                                                <Button className="btn btn-primary" onClick={() => this.AllDataSubmitUpdate()}
                                                    style={{ borderColor: '#BA183A', backgroundColor: '#BA183A', borderRadius: '5px', float: 'right' }}>
                                                    <span className="textStyles-small" style={{ color: 'white' }}>Update This Asset</span>
                                                </Button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Drawer>
                </div>
            </>
        )
    }
}
export default iDBatteryRacks;