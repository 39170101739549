export default [
    // {
    //     name: "W1-JSP Distributors Hotel (Pvt) Ltd",
    //     offsetX: 0.18,
    //     offsetY: 0.84,
    //     color: "# "
    // }
    {
        offsetX: 0.16,
        offsetY: 0.82,
        color: "#f475b0",
        name: "Egalla"
    },
    {
        offsetX: 0.30,
        offsetY: 0.72,
        color: "#84377c",
        name: "Mawanella_North"
    },
    {
        offsetX: 0.13,
        offsetY: 0.67,
        color: "#c4326b",
        name: "Kegalle_East"
    },
    {
        offsetX: 0.16,
        offsetY: 0.69,
        color: "#f99c41",
        name: "Boralesgamuwa"
    },
    {
        offsetX: 0.65,
        offsetY: 0.30,
        color: "#734d31",
        name: "Trinco"
    },
    {
        offsetX: 0.57,
        offsetY: 0.70,
        color: "#c9c640",
        name: "Badulla"
    },
    {
        offsetX: 0.86,
        offsetY: 0.54,
        color: "#96c292",
        name: "Batticaloa"
    },
    {
        offsetX: 0.20,
        offsetY: 0.03,
        color: "#00b56e",
        name: "Jaffna"
    },
    {
        offsetX: 0.32,
        offsetY: 0.96,
        color: "#8dd6dd",
        name: "Galle"
    },
    {
        offsetX: 0.47,
        offsetY: 0.96,
        color: "#00c7d7",
        name: "Tangalle1"
    },
    {
        offsetX: 0.18,
        offsetY: 0.62,
        color: "#018ccb",
        name: "Ganemulla"
    },
    {
        offsetX: 0.15,
        offsetY: 0.65,
        color: "#929396",
        name: "Colombo-15"
    },
    {
        offsetX: 0.46,
        offsetY: 0.65,
        color: "#b28b74",
        name: "NuwaraEliya"
    },
    {
        offsetX: 0.41,
        offsetY: 0.59,
        color: "#673ab7",
        name: "Kandy"
    },
    {
        offsetX: 0.34,
        offsetY: 0.54,
        color: "#607d8b",
        name: "Kurunagela"
    },
    {
        offsetX: 0.11,
        offsetY: 0.54,
        color: "#f6c6a7",
        name: "Chilaw"
    },
    {
        offsetX: 0.11,
        offsetY: 0.61,
        color: "#4caf50",
        name: "Negombo"
    },
]