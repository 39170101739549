import React, { Component } from "react";
import {
  Select,
  DatePicker,
  Table,
  Image,
  Avatar,
  List,
  Tag,
  Badge,
  Descriptions,
  Space,
} from "antd";

import { Link } from "react-router-dom";

import {
  getLastThreeRequestDetails,
  getUserSites,
  getPisHistoryData,
  getSitesWithFilter,
  getPisHistoryDetailsById,
  getRoutineView,
} from "../../../api/index";
import Modal from "react-modal";

import { THE_FAMILY_ID } from "@azure/msal-common/dist/utils/Constants";
import Item from "antd/lib/list/Item";
import ReactLoading from "react-loading";
import { tupleNum } from "antd/lib/_util/type";
import { Collapse } from "reactstrap";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import moment from "moment";

class RoutineHistoryView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showModal: false,
      isOpenFillter: true,
      allList: null,
      allSite_loading: true,
      allSite: [],
      depotList: [],
      regionsList: [],
      allDataList: [],
      startDate: "",
      endDate: "",
      selectSiteId: null,
      selectSiteName: null,
      selectComptName: null,
      comptId: null,
      selectImagesDetails: null,
      regionIds: "",
      depotIds: "",
      siteIds: "",
      fullPageCount: 0,
      loopPageCount: 0,
      setRecodesPerPage: 25,
      itemDetails: [],
      selectedServiceTypeId: 1,
      selectedDataUrl: "/genServiceRequest/getAllPisGenServiceRequests",
      selectedDetailUrl: "",
      pageNo: 0,
      pageNo2: 0,
      recodesPerPage: 1,
      recodesPerPage2: 1,
    };
  }
  viewFilterClick = () => {
    this.setState({
      isOpenFillter: !this.state.isOpenFillter,
    });
  };
  componentDidMount() {
    var _depotList = [];
    var _regionsList = [];

    this.props.depots.map((depot) => {
      _depotList.push({ value: depot.id, label: depot.name });
    });
    this.props.regions
      .filter((allret) => "0".includes(allret.isSubRegion))
      .map((regions) => {
        _regionsList.push({ value: regions.id, label: regions.name });
      });
    this.setState({
      depotList: _depotList,
      regionsList: _regionsList,
    });
  }
  getDataRoutineData = async () => {
    this.setState({
      loading: true,
    });
    var data = new FormData();
    data.append("pageNo", this.state.pageNo2);
    data.append("recodesPerPage", this.state.setRecodesPerPage);
    data.append("dateFrom", this.state.startDate);
    data.append("dateTo", this.state.endDate);
    data.append("regionIds", this.state.regionIds);
    data.append("depotIds", this.state.depotIds);
    data.append("siteIds", this.state.siteIds);

    var pageCount = 0;

    var res = await getRoutineView(data);
    console.log(res);
    if (res.success) {
      this.setState({
        loading: false,
        tabaleLoading: false,
        allDataList: res.data.data,
        recodesPerPage2: res.data.pageCount,
      });
    }
  };

  clearBtnClick = () => {
    window.location.reload();
  };
  searchRegionmChange = (event) => {
    var dd = event;
    //console.log(dd)
    var subRegion = [];
    var _depotList1 = [];
    var subRegionIdArray = [];

    this.props.regions
      .filter((allret) => dd.map((n) => n).includes(allret.parentRegion?.id))
      .map((subR) => {
        subRegionIdArray.push(subR.id);
      });

    this.setState(
      {
        regionIds: subRegionIdArray,
      },
      () => {
        this.getDataSitesWithFilter();
      }
    );

    //console.log(this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id)));
    subRegion = this.props.regions.filter((allret) =>
      dd.map((n) => n).includes(allret.parentRegion?.id)
    );
    //console.log(this.props.depots.filter(alldate => subRegion.map((n) => n.id).includes(alldate.region?.id)));
    this.props.depots
      .filter((alldate) =>
        subRegion.map((n) => n.id).includes(alldate.region?.id)
      )
      .map((depot) => {
        _depotList1.push({ value: depot.id, label: depot.name });
      });

    this.setState({
      depotList: _depotList1,
    });
  };
  searchDepottemChange = (event) => {
    this.setState(
      {
        depotIds: event,
      },
      () => this.getDataSitesWithFilter()
    );
  };
  getDataSitesWithFilter = async () => {
    this.setState({
      allSite_loading: true,
    });

    var _regionList = [];
    var _depotList = [];
    var _allSite = [];
    if (this.state.regionIds !== "") {
      this.state.regionIds?.map(
        (x, y) => {
          _regionList.push({ id: x });
        },
        () => {}
      );
    }
    if (this.state.depotIds !== "") {
      this.state.depotIds?.map(
        (a, b) => {
          _depotList.push({ id: a });
        },
        () => {}
      );
    }

    var data = new FormData();
    // data.append('regions','[{"id":6},{"id":7}]');
    data.append("regions", JSON.stringify(_regionList));
    data.append("depots", JSON.stringify(_depotList));
    var res = await getSitesWithFilter(data);
    if (res.success) {
      res.data.data.map((sites) => {
        _allSite.push({ value: sites.id, label: sites.siteName });
      });
      this.setState({
        allSite_loading: false,
        allSite: _allSite,
      });
    }
  };
  onChangeDate = (date, dateString) => {
    //console.log(date);
    //console.log(dateString);
    this.setState({
      startDate: dateString,
    });
  };
  onChangeDate2 = (date, dateString) => {
    //console.log(date);
    //console.log(dateString);
    this.setState({
      endDate: dateString,
    });
  };
  searchSiteemChange = (event) => {
    this.setState({
      siteIds: event,
    });
  };
  filterBtnClick = async () => {
    if (this.state.startDate != "" && this.state.endDate != "") {
      this.getDataRoutineData();
    }
  };

  serviceTypesChange = (e, serviceTypes) => {
    this.setState({
      selectedServiceTypeId: e,
      selectedDataUrl: serviceTypes[e - 1].data_url,
    });
    console.log(serviceTypes[e - 1].data_url);
    console.log(e);
  };

  previousBtnClick2 = () => {
    if (this.state.pageNo2 > 0) {
      var aa = this.state.pageNo2 - 1;
      this.setState(
        {
          pageNo2: aa,
        },
        () => {
          this.getDataRoutineData();
        }
      );
    }
  };
  nextBtnClick2 = () => {
    if (this.state.recodesPerPage2 > this.state.pageNo2 + 1) {
      var aa = this.state.pageNo2 + 1;
      this.setState(
        {
          pageNo2: aa,
        },
        () => {
          this.getDataRoutineData();
        }
      );
    }
  };

  render() {
    const { Option } = Select;

    const columns123 = [
      {
        title: "Id",
        // dataIndex: "siteId",
        //	defaultSortOrder: "descend",

        render: (item) => (
          <>
            <span style={{ padding: 1 }} key={item.id}>
              {item.id}
            </span>
          </>
        ),
      },
      {
        title: "VisitCode",
        // dataIndex: "siteId",
        //	defaultSortOrder: "descend",

        render: (item) => (
          <>
            <span style={{ padding: 1 }} key={item.routingVisitCode}>
              {item.routingVisitCode}
            </span>
          </>
        ),
      },
      {
        title: "Site Id",
        render: (item) => <>{item.siteId}</>,
      },
      {
        title: "Site Name",
        render: (item) => <>{item.siteName}</>,
      },
      {
        title: "Created Date",
        render: (item) => (
          <>
            <>{item.createdOn.split("T")[0]}</>
          </>
        ),
      },
      {
        title: "Routing Type",
        render: (item) => <>{item.type == null ? "N/A" : item.type}</>,
      },
      {
        title: "Updated Date",
        render: (item) => (
          <>
            <>{item.updatedOn.split("T")[0]}</>
          </>
        ),
      },

      {
        title: "Status",
        render: (item) => (
          <>
            <Tag color={item.routingVisitStatus.color} style={{ padding: 3 }}>
              {item.routingVisitStatus.name}
            </Tag>
          </>
        ),
      },
      {
        title: "User Name",
        render: (item) => (
          <>
            <>{item.userName}</>
          </>
        ),
      },
      {
        title: "Action",
        key: "action",
        render: (item) => (
          <>
            {
              <a
                href={
                  "/routine-detail-view/" +
                  item.id +
                  "/" +
                  this.state.selectedServiceTypeId
                }
              >
                {" "}
                View
              </a>
            }
          </>
        ),
      },
    ];
    var serviceTypes = [
      {
        id: 1,
        name: "Gen Service - PIS",
        data_url: "/genServiceRequest/getAllPisGenServiceRequests",
      },
      {
        id: 2,
        name: "AC Service - PIS",
        data_url: "/acServiceRequest/getAllPisAcServiceRequests",
      },
      {
        id: 3,
        name: "Site Cleaning Service - PIS",
        data_url: "/siteCleaningService/getAllPisScServiceRequests",
      },
      {
        id: 4,
        name: "Civil Routine - PIS",
        data_url: "/civilRoutineService/getAllPisCrServiceRequests",
      },
    ];

    return (
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="page-title-box">
              <div class="row">
                <div class="col">
                  <h4 class="page-title">Site Routine Data View</h4>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="javascript:void(0);">Site Routine </a>
                    </li>
                    <li class="breadcrumb-item">
                      <a href="javascript:void(0);">Data View</a>
                    </li>
                  </ol>
                </div>
                <div class="col-auto align-self-center">
                  <div class="row">
                    <div class="col-md-1">
                      <button
                        onClick={() => this.viewFilterClick()}
                        type="button"
                        style={{ marginLeft: 10 }}
                        class="btn btn-danger"
                      >
                        <i class="fas fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <Collapse isOpen={this.state.isOpenFillter}>
            <div className="row">
              <div class="card">
                <div class="card-body bootstrap-select-1">
                  <div class="row">
                    <div class="col-md-3">
                      <DatePicker
                        placeholder="Start Date *"
                        style={{ width: "100%" }}
                        onChange={this.onChangeDate}
                      />
                    </div>
                    <div className="col-md-3">
                      <DatePicker
                        placeholder="End Date *"
                        style={{ width: "100%" }}
                        onChange={this.onChangeDate2}
                      />
                    </div>
                    <div class="col-md-2">
                      <Select
                        mode="multiple"
                        showSearch
                        name="regionId"
                        onChange={(e) => this.searchRegionmChange(e)}
                        placeholder="Region"
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        // options={this.state.regionsList}
                      >
                        {this.state.regionsList?.map((item, index) => {
                          return (
                            <Option value={item.value}>{item.label}</Option>
                          );
                        })}
                      </Select>
                    </div>
                    <div class="col-md-2">
                      <Select
                        mode="multiple"
                        showSearch
                        name="depotId"
                        onChange={(e) => this.searchDepottemChange(e)}
                        placeholder="Depot"
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        // options={this.state.depotList}
                      >
                        {this.state.depotList?.map((item, index) => {
                          return (
                            <Option value={item.value}>{item.label}</Option>
                          );
                        })}
                      </Select>
                    </div>
                    <div class="col-md-2">
                      <Select
                        mode="multiple"
                        placeholder="Site"
                        style={{ width: "100%" }}
                        // options={this.state.allSite}
                        loading={this.state.allSite_loading}
                        name="siteId"
                        onChange={(e) => this.searchSiteemChange(e)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                      >
                        {this.state.allSite.map((item, index) => {
                          return (
                            <Option value={item.value}>{item.label}</Option>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                  <div class="col-md-3" style={{ marginTop: 10 }}>
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={() => this.filterBtnClick()}
                    >
                      {" "}
                      Filter
                    </button>
                    <button
                      type="button"
                      style={{ marginLeft: 5 }}
                      class="btn btn-dark"
                      onClick={() => this.clearBtnClick()}
                    >
                      Clear Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
          <Table
            size="small"
            fontSize={9}
            columns={columns123}
            bordered
            pagination={false}
            loading={this.state.loading}
            dataSource={this.state.allDataList}
          />
        </div>
        <br></br>
        <div class="row">
          <div class=" col-md-12">
            <div
              class="dataTables_paginate paging_simple_numbers"
              id="row_callback_paginate"
              style={{
                display: "flex",
                textAlign: "end",
                justifyContent: "end",
              }}
            >
              <ul class="pagination">
                <li
                  class="paginate_button page-item previous"
                  id="row_callback_previous"
                >
                  <a
                    onClick={() => this.previousBtnClick2()}
                    aria-controls="row_callback"
                    data-dt-idx="0"
                    tabindex="0"
                    class="page-link"
                  >
                    Previous
                  </a>
                </li>
                <li class="paginate_button page-item ">
                  <a
                    aria-controls="row_callback"
                    data-dt-idx="1"
                    tabindex="0"
                    class="page-link"
                  >
                    {this.state.pageNo2 + 1}/{this.state.recodesPerPage2}
                  </a>
                </li>

                <li
                  class="paginate_button page-item next"
                  id="row_callback_next"
                >
                  <a
                    onClick={() => this.nextBtnClick2()}
                    aria-controls="row_callback"
                    data-dt-idx="7"
                    tabindex="0"
                    class="page-link"
                  >
                    Next
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  //console.log("state")
  //console.log(state)
  return {
    depots: state.depots,
    regions: state.regions,
  };
}
export default withRouter(connect(mapStateToProps, {})(RoutineHistoryView));
