import React, { Component } from 'react'
import { Table, Tag, Button, Drawer, Form, Input, Select, InputNumber, message, Popconfirm } from 'antd';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';


class Rectifiers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSet: [],
            actionView:true

        }
    }

    render() {
        const columns2 = [
            {
                title: "Make",

                dataIndex: "make",
                key: "make",
                fixed: 'left',

                render: (item) => <>
                    <Tag color="gray" key={item}>
                        <span>{item?.name}</span>
                    </Tag>
                </>,


            },
            {
                title: "Model",
                dataIndex: "model",
                key: "model",

                fixed: 'left',
                render: (item) => <span>{item !== null ? (item?.name) : ('N/A')}</span>,

            },
            {
                title: "Capacity",
                dataIndex: "capacity",
                key: "capacity",



                render: (item) => <span>{item !== null ? (item) : ('N/A')}</span>,
            },
          
            {

                dataIndex: "id",
                key: "id",
                width: '50px',

                fixed: 'right',

                render: (item) => <>
                {this.state.AssetManagementPermission?(<a style={{ color: 'blue' }} onClick={() => this.atsUpdata(item)}><i className="las la-pen font-15"></i></a>):('')}
                    <Popconfirm
                        title="Are You Sure?"
                        description="Are you sure to delete this task?"
                        onConfirm={() => this.submitForm(item)}
                        okText="Yes"
                        cancelText="No"
                    >
                          {this.state.AssetManagementPermission?( <a style={{ color: 'blue' }}><i className="las la-trash-alt font-15"></i></a>):('')}
                    </Popconfirm> </>,

            },









        ];
        return (
            <div class="row">
                <div class="card">
                    <div class="card-header">
                        
                        <div className="row align-items-center">
                                    <div className="col">
                                        <h4 className="card-title">Rectifier List</h4>
                                    </div>
                                    <div className="col-auto">
                                        {this.state.actionView ? (<Button className="btn btn-primary"  block style={{ textAlign: "end" }} >
                                            <i class="la la-plus-circle text-muted font-16 me-2"></i> <b style={{ color: "#40a9ff" }}>Add Data</b>
                                        </Button>) : ('')}
                                    </div>
                                </div>
                    </div>
                    <div class="card-body">

                        <Table
                            size="small"
                            columns={columns2}
                            // loading={this.state.loading && this.state.rectifierloading && this.state.batteryBanksloading && this.state.airConditionersloading && this.state.spdsloading && this.state.cabinetsloading && this.state.generatorsloading && this.state.atsesloading && this.state.dcdusloading && this.state.fcbsloading && this.state.sharedOutOperatorsesloading}
                           // loading={this.state.tabaleLoading}
                           dataSource={this.state.dataSet}

                        />


                    </div>
                </div>
            </div>
        )
    }
}
export default Rectifiers;
