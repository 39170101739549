import React, { Component } from 'react'
import ImageGallery from 'react-image-gallery';


class View extends Component {
    
    render() {
        const images = [
            {
              original: 'https://picsum.photos/id/1018/1000/600/',
              thumbnail: 'https://picsum.photos/id/1018/250/150/',
            },
            {
              original: 'https://picsum.photos/id/1015/1000/600/',
              thumbnail: 'https://picsum.photos/id/1015/250/150/',
            },
            {
              original: 'https://picsum.photos/id/1019/1000/600/',
              thumbnail: 'https://picsum.photos/id/1019/250/150/',
            },
          ];
          
        return (
            <div>
                <div class="row">
                    <div class="col">
                        <h4 class="page-title">Image List </h4>
                    </div>
                    <div class="col-auto align-self-center">
                        <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                            <span class="ay-name" id="Day_Name">Today:</span>&nbsp;
                            <span class="" id="Select_date">Jan 19</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                        </a>

                    </div>
                </div>
                <br></br>
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-6 align-self-center">
                                    <ImageGallery items={images} />
                                    </div>
                                    <div class="col-lg-6 align-self">
                                        <div class="single-pro-detail">


                                            <h3 class="pro-title">Component 1</h3>
                                            <p class="text-muted mb-0">Morden and good look model 2020</p>
                                          

                                            <ul class="list-unstyled pro-features border-0">
                                                <li>It is a long established fact that a reader will be distracted.</li>
                                                <li>Contrary to popular belief, Lorem Ipsum is not text. </li>
                                            </ul>
                                            <h6 class="text-muted font-13 d-inline-block align-middle me-2">Color :</h6>
                                            <ul class="list-unstyled pro-features border-0">
                                                <li>It is a long established fact that a reader will be distracted.</li>
                                                <li>Contrary to popular belief, Lorem Ipsum is not text. </li>
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <h4 class="page-title">History</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body">
                               
                            <ImageGallery items={images} />
                                <div class="row my-4">
                                    <div class="col">
                                        <a href="#" class="title-text d-block">Unique Desk Camera</a>
                                    </div>
                                   
                                </div>
               
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body">
                               
                            <ImageGallery items={images} />
                                <div class="row my-4">
                                    <div class="col">
                                        <a href="#" class="title-text d-block">Unique Desk Camera</a>
                                    </div>
                                   
                                </div>
               
                            </div>
                        </div>
                    </div>
                    
                    
                    
                </div>
            </div>
        )
    }
}
export default View;