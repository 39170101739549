import React, { Component } from 'react'
import { DatePicker, Input, InputNumber, Tag, Space, Button, Row, Col, Drawer, Select, Spin, Switch, message } from "antd";
import { updateTrcslDetails } from "../../api/index";
import moment from 'moment';



class Trcsl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            tabaleLoading: false,
            switchStatus: true,
            objectSet:
            {
                "tRCSL5YearReportSubmittedDate": null

            }

        };
    }

    componentDidMount() {
        console.log('tytqwe')
        console.log(this.props.details)
        this.setState({
            objectSet:
            {
                "tRCSL5YearReportSubmittedDate": this.props.details.access_technologies_data?.trcsl5YearReportSubmittedDate 


            }
        });
    }
    onChangeInstalledDate = (date, dateString) => {
        // console.log(date);
        // console.log(dateString);
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                tRCSL5YearReportSubmittedDate: dateString
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    };
    dataUpdate() {
        console.log("ssss")
        this.setState({
            switchStatus: false
        })
    }
    close = async () => {
        this.props.parentMethod();
        this.setState({
            objectSet:
            {
                "tRCSL5YearReportSubmittedDate": this.props.details.access_technologies_data?.trcsl5YearReportSubmittedDate 


            }
        });
        this.setState({
            switchStatus: true
        });
    }
    dataSetToUpdate = async () => {
        console.log(this.props.detailsSite)
        let formData = new FormData(); //formdata object
        let setobjectToUpate = {"trcsl5YearReportSubmittedDate":this.state.objectSet.tRCSL5YearReportSubmittedDate}
        formData.append("object", JSON.stringify(setobjectToUpate)); //append the values with key, value pair
        formData.append("id", this.props.details?.access_technologies_data?.id);
        var res = await updateTrcslDetails(formData);
        if(res.success){
            message.success('Successfully Update!')
            this.props.parentMethod();
            this.setState({
              switchStatus: true
          })
         }else{
          message.warning(' Something went wrong!')
         }





    }
    render() {
        const { details } = this.props.details
       
        return (
            <>
                {this.props.details.access_technologies_data != null ? (
                    <div className='row'>
                        <div className='col-md-5'>
                            <div class="card">
                                <div class="card-header">
                                <div class="card-header">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <h4 class="card-title">Information</h4>
                                        {this.state.switchStatus
                                            ? <button type="button" class="btn btn-soft-primary btn-icon-square-sm" onClick={() => this.dataUpdate()}> <i class="mdi mdi-pencil-outline"></i></button>
                                            : <div class="d-flex justify-content-end align-items-center">
                                                <button type="button" class="btn btn-soft-secondary " onClick={() => this.close()} style={{ marginRight: "10px" }}> Cancel </button>
                                                <button type="button" class="btn btn-soft-primary " onClick={() => this.dataSetToUpdate()}> Submit to Update </button>
                                            </div>
                                        }
                                    </div>

                                </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">

                                        <ul class="list-group">
                                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                                <div>
                                                    <i class="la la-hand-o-right text-primary me-2"></i>5Year Report Submitted Date
                                                </div>

                                                {/* {this.props.details.access_technologies_data?.trcsl5YearReportSubmittedDate ? (<span class="badge badge-outline-success">{this.props.details.access_technologies_data.trcsl5YearReportSubmittedDate}</span>) : (<span class="badge badge-outline-warning">{JSON.stringify(this.props.details.access_technologies_data?.trcsl5YearReportSubmittedDate)}</span>)} */}
                                                {this.state.switchStatus ? (<> {this.props.details.access_technologies_data?.trcsl5YearReportSubmittedDate  != null ? (<span class="badge badge-outline-success">{this.props.details.access_technologies_data?.trcsl5YearReportSubmittedDate }</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>  <DatePicker style={{ width: "100%" }}  defaultValue={this.state.objectSet?.tRCSL5YearReportSubmittedDate != null ? (moment(this.state.objectSet?.tRCSL5YearReportSubmittedDate)) : ("")} onChange={this.onChangeInstalledDate}  /> </>)}


                                            </li>
                                            
                                           
                                            

                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-7'>

                            
                                {/* {this.props.details2.generators_data.length > 0 ? (<div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">Generators</h4>

                                </div>
                                <div class="card-body">
                                <Table
                                size="small"
                                columns={columns2}
                                // loading={this.state.loading && this.state.rectifierloading && this.state.batteryBanksloading && this.state.airConditionersloading && this.state.spdsloading && this.state.cabinetsloading && this.state.generatorsloading && this.state.atsesloading && this.state.dcdusloading && this.state.fcbsloading && this.state.sharedOutOperatorsesloading}
                                loading={this.state.tabaleLoading}
                                dataSource={this.props.details2.generators_data}
                                scroll={{
                                    x: 1300,
                                }}
                            />
                            </div>
                            </div>) : ('')} */}
                                
                        

                        </div>
                    </div>
                ) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h4 style={{ color: "#c5c5c5" }}>No Dat Found Access Technologies</h4></div>)}

            </>
        )
    }
}
export default Trcsl;
