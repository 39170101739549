import React, { Component } from 'react'
import { Table, Tag, Button, Drawer, Form, Input, Select, InputNumber, DatePicker, message, Popconfirm, Collapse, Switch } from 'antd';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import { createBatteryBank, getBatteryBankDetails, UpdateBatteryBank,dataUpdateGeneralDetails } from "../../api/index";
import moment from 'moment';
import AssetDelete from "../site_components/asset_delete";
import { checkServiceType } from "../../actions/service-type";


class BatteryBanks extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();

        this.state = {
            title: "",
            open: false,
            openEdit: false,
            tabaleLoading: false,
            actionView: false,
            bbBatterySerialNumber: '',
            bbMaterialCodeOfTheBatteryBank: '',
            bbCapacity: '',
            bbBatteryTemperatureSensorInstalledCorrectly: null,
            bbFloatVoltage: '',
            bbBoostVoltage: '',
            bbperiodic_boost_period: '',
            bbPeriodicBoostDuraion: '',
            bbBatteryCurrentLimit: '',
            bbrelevantRectifier: '',
            bbType: null,
            bbNumberof48VBatteryBlocks: '',
            bbper48VBlockCapacityrelevantRectifier: '',
            bbbatteryTemperature: '',
            bbfloatVoltageSet: '',
            bbIdBatteryRackModel: '',
            bbidBatteryRackModel1Count: '',
            bbhandleBBbatteryRackModelCount: '',
            bbInstalledDate: null,
            bbtemperatureCompansation: '',
            selectedAssetId: null,
            AssetType: [],
            selectedMakeId: null,
            makeType: [],
            selectedModelId: null,
            modelType: [],
            AssetManagementPermission: false,
            openViewMoreDetails: false,
            selectedElement: "",
            cabinets: null,
            rectifier: null,
            bbName: '',
            selectedCabinetsList: [],
            selectedRectifierList: [],
            selectedBBTypeId: null,

            switchStatus: true,

            objectSet: {
                "bbTicketNumberOfPendingBatteryFaultyTickets": 0,
                "bbDesignedBackupTime": 0,
                "bbAllocatedBackupTime": 0,
                "bbActualBackupTime": 0,
                "bbPlannedBatteryCapacityProjects": 0,
                "plannedBatteryCapacity":0
            },

            dropdownObjBB : {
                "bbBatteryTemperatureSensorInstalledCorrectly" : "",
            }


        };
        this.requestFromClose = this.requestFromClose.bind(this);
        this.handleBatterySerialNumber = this.handleBatterySerialNumber.bind(this);
        this.handleMaterialCodeOfTheBatteryBank = this.handleMaterialCodeOfTheBatteryBank.bind(this);
        this.handlebbNumberof48VBatteryBlocks = this.handlebbNumberof48VBatteryBlocks.bind(this);
        this.handlebbIdBatteryRackModel = this.handlebbIdBatteryRackModel.bind(this);
        this.handleBBCapacity = this.handleBBCapacity.bind(this);
        this.handleBBFloatVoltage = this.handleBBFloatVoltage.bind(this);
        this.handleBBBoostVoltage = this.handleBBBoostVoltage.bind(this);
        this.handleBBPeriodicBoostDuraion = this.handleBBPeriodicBoostDuraion.bind(this);
        this.handleBBperiodic_boost_period = this.handleBBperiodic_boost_period.bind(this);
        this.handleBBBatteryCurrentLimit = this.handleBBBatteryCurrentLimit.bind(this);
        this.handleBBrelevantRectifier = this.handleBBrelevantRectifier.bind(this);
        this.handleBBbatteryTemperature = this.handleBBbatteryTemperature.bind(this);
        this.handleBBidBatteryRackModel1Count = this.handleBBidBatteryRackModel1Count.bind(this);
        this.handleBBbatteryRackModelCount = this.handleBBbatteryRackModelCount.bind(this);
        this.handleBBper48VBlockCapacityrelevantRectifier = this.handleBBper48VBlockCapacityrelevantRectifier.bind(this);
        this.handleBBtemperatureCompansation = this.handleBBtemperatureCompansation.bind(this);
        this.handleBBfloatVoltageSet = this.handleBBfloatVoltageSet.bind(this);
        this.handleName = this.handleName.bind(this);


    }
    batteryBankUpdata(id) {
        //console.log('dfdfd')
        //console.log(id)
        this.getDataBatteryBankDetails(id);
        this.setState({ openEdit: true });
    }
    getDataBatteryBankDetails = async (id) => {
        var res = await getBatteryBankDetails(id);
        //console.log(res);
        if (res.success) {
            this.setState({
                batteryBankEdit: res.data,
                batteryBankId: res.data.id,
                selectedMakeId: res.data?.make?.id,
                makeType: res.data?.make,
                selectedModelId: res.data?.model?.id,
                modelType: res.data?.model,
                bbBatteryCurrentLimit: res.data?.batteryCurrentLimit,
                bbhandleBBbatteryRackModelCount: res.data?.batteryRackModelCount,
                bbBatterySerialNumber: res.data?.batterySerialNumber,
                bbbatteryTemperature: res.data?.batteryTemperature,
                bbType: res.data?.bbType,
                bbBoostVoltage: res.data?.boostVoltage,
                bbFloatVoltage: res.data?.floatVoltage,
                bbfloatVoltageSet: res.data.floatVoltageSet,
                bbIdBatteryRackModel: res.data.idBatteryRackModel,
                bbidBatteryRackModel1Count: res.data.idBatteryRackModel1Count,
                bbMaterialCodeOfTheBatteryBank: res.data.model?.materialCodeOfTheBatteryBank,
                bbNumberof48VBatteryBlocks: res.data.numberof48VBatteryBlocks,
                bbper48VBlockCapacityrelevantRectifier: res.data.model?.per48VBlockCapacity,
                bbPeriodicBoostDuraion: res.data.periodicBoostDuraion,
                bbperiodic_boost_period: res.data.periodic_boost_period,
                bbrelevantRectifier: res.data.relevantRectifier,
                bbtemperatureCompansation: res.data.temperatureCompansation,
                bbCapacity: res.data.totalCapacity,
                bbName: res.data.name,
                cabinets: res.data.cabinets,
                rectifier: res.data.rectifier,
                selectedBBTypeId: res.data?.bbType?.id,

            })
            if (res.data.cabinets != null) {
                res.data.cabinets.filter(name => name.delete == false).map((e) => this.state.selectedCabinetsList.push(e.id))
            }
            if (res.data.rectifier != null) {
                res.data.rectifier.filter(name => name.delete == false).map((e) => this.state.selectedRectifierList.push(e.id))
            }
            if (res.data?.installedDate != null) {

                this.setState({
                    bbInstalledDate: res.data?.installedDate
                })
            } else {

                this.setState({
                    bbInstalledDate: '',
                })
            }
            if (res.data?.batteryTemperatureSensorInstalledCorrectly == false) {

                this.setState({
                    bbBatteryTemperatureSensorInstalledCorrectly: 0
                })

            } else {

                this.setState({
                    bbBatteryTemperatureSensorInstalledCorrectly: 1
                })
            }
        }
    }
    handleBBfloatVoltageSet(e) {
        //console.log(e)
        this.setState({ bbfloatVoltageSet: e });
    }
    handleBBtemperatureCompansation(e) {
        //console.log(e)
        this.setState({ bbtemperatureCompansation: e });
    }
    handleBBper48VBlockCapacityrelevantRectifier(e) {
        //console.log(e)
        this.setState({ bbper48VBlockCapacityrelevantRectifier: e });
    }
    handleBBbatteryRackModelCount(e) {
        //console.log(e)
        this.setState({ bbhandleBBbatteryRackModelCount: e });
    }
    handleBBidBatteryRackModel1Count(e) {
        //console.log(e)
        this.setState({ bbidBatteryRackModel1Count: e });
    }
    handleBBbatteryTemperature(e) {
        //console.log(e)
        this.setState({ bbbatteryTemperature: e });
    }
    handleBBrelevantRectifier(e) {
        //console.log(e)
        this.setState({ bbrelevantRectifier: e });
    }
    handleBBBatteryCurrentLimit(e) {
        //console.log(e)
        this.setState({ bbBatteryCurrentLimit: e });
    }
    handleBBperiodic_boost_period(e) {
        //console.log(e)
        this.setState({ bbperiodic_boost_period: e });
    }
    handleBBPeriodicBoostDuraion(e) {
        //console.log(e)
        this.setState({ bbPeriodicBoostDuraion: e });
    }
    handleBBBoostVoltage(e) {
        // //console.log(e)
        this.setState({ bbBoostVoltage: e });
    }
    handleBBFloatVoltage(e) {
        //console.log(e)
        this.setState({ bbFloatVoltage: e });
    }
    onChangeBatteryTemperatureSensorInstalledCorrectly = (value) => {
        //console.log("onChangeislowVoltageFunctionality");
        //console.log(value);

        this.setState({
            bbBatteryTemperatureSensorInstalledCorrectly: parseInt(value),


        })


    };
    onChangeInstalledDate = (date, dateString) => {
        // console.log(date);
        // console.log(dateString);
        this.setState({
            bbInstalledDate: dateString,
        });
    };
    handleBBCapacity(e) {
        // console.log(e)
        this.setState({ bbCapacity: e });
    }
    handlebbIdBatteryRackModel(e) {
        // console.log(e.target.value)
        this.setState({ bbIdBatteryRackModel: e.target.value });
    }
    handlebbNumberof48VBatteryBlocks(e) {
        // console.log(e.target.value)
        this.setState({ bbNumberof48VBatteryBlocks: e });
    }
    handleMaterialCodeOfTheBatteryBank(e) {
        //console.log(e.target.value)
        this.setState({ bbMaterialCodeOfTheBatteryBank: e.target.value });
    }
    handleBatterySerialNumber(e) {
        //console.log(e.target.value)
        this.setState({ bbBatterySerialNumber: e.target.value });
    }
    handleName(e) {
        //console.log(e.target.value)
        this.setState({ bbName: e.target.value });
    }
    requestFromClose() {
        //  console.log('dsdsd')
        this.setState({ showModal: true, open: true });
    }
    componentDidMount() {

        if (JSON.parse(localStorage.getItem('serviceTypeSet')) == null) {
            const { history } = this.props;

            history.push("/");

        } else {

            this.getSetPageData(JSON.parse(localStorage.getItem('serviceTypeSet')), 38)

        }
        checkServiceType(38).then((data) => this.setState({
            AssetManagementPermission: data
        }, () => {
        }),

        )
        this.setState({
            objectSet: {
                "bbTicketNumberOfPendingBatteryFaultyTickets": this.props.details?.access_technologies_data?.bbTicketNumberOfPendingBatteryFaultyTickets,
                "bbDesignedBackupTime": this.props.details?.access_technologies_data?.bbDesignedBackupTime,
                "bbAllocatedBackupTime": this.props.details?.access_technologies_data?.bbAllocatedBackupTime,
                "bbActualBackupTime": this.props.details?.access_technologies_data?.bbActualBackupTime,
                "bbPlannedBatteryCapacityProjects": this.props.details?.access_technologies_data?.bbPlannedBatteryCapacityProjects,
                "plannedBatteryCapacity":this.props.details?.access_technologies_data?.plannedBatteryCapacity,

            }
        })
    }
    getSetPageData = (serviceTypeIs, Pageid) => {

        if (serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == Pageid)[0] != null) {
            //console.log('fddf');
            this.setState({
                actionView: true,
            })
        }

    };
    onChangeAsset = (value) => {
        // console.log("onChangeAsset");
        //console.log(value);
        this.setState({
            selectedAssetId: value,
            AssetType: {
                "id": value
            },

        })
    };
    onDrawerClose = () => {
        console.log('sddsds')
        this.formRef.current.resetFields();
        this.validator.hideMessages();
        this.setState({
            open: false,
            openEdit: false,
            selectedAssetId: null,
            AssetType: [],
            selectedMakeId: null,
            makeType: [],
            selectedModelId: null,
            modelType: [],
            bbBatterySerialNumber: '',
            bbMaterialCodeOfTheBatteryBank: '',
            bbCapacity: '',
            bbBatteryTemperatureSensorInstalledCorrectly: null,
            bbFloatVoltage: '',
            bbBoostVoltage: '',
            bbperiodic_boost_period: '',
            bbPeriodicBoostDuraion: '',
            bbBatteryCurrentLimit: '',
            bbrelevantRectifier: '',
            bbType: null,
            bbNumberof48VBatteryBlocks: '',
            bbper48VBlockCapacityrelevantRectifier: '',
            bbbatteryTemperature: '',
            bbfloatVoltageSet: '',
            bbIdBatteryRackModel: '',
            bbidBatteryRackModel1Count: '',
            bbhandleBBbatteryRackModelCount: '',
            bbInstalledDate: null,
            bbtemperatureCompansation: '',
            cabinets: null,
            rectifier: null,
            bbName: '',
            selectedCabinetsList: [],
            selectedRectifierList: [],
            selectedBBTypeId: null

        })

    }
    onChangeMake = (value) => {
        //console.log("onChangeMake");
        ////console.log(value);
        this.setState({
            selectedMakeId: value,
            makeType: {
                "id": value
            },

        })
    };
    onChangeModel = (value) => {
        //console.log("onChangeModel");
        ////console.log(value);
        this.setState({
            selectedModelId: value,
            modelType: {
                "id": value
            },

        })
    };
    onChangeCabinet = (value) => {
        var _tempList = [];
        value.map((e) => {
            _tempList.push({ id: e })
        })
        this.setState({
            cabinets: _tempList
        })
    };
    onChangeRectifierForCreate = (value) => {
        var _tempList = [];
        _tempList.push({ id: value })
        this.setState({
            rectifier: _tempList
        })
    };
    onChangeRectifier = (value) => {
        var _tempList = [];
        value.map((e) => {
            _tempList.push({ id: e })
        })
        this.setState({
            rectifier: _tempList
        })
    };
    onChangeBBType = (value) => {
        this.setState({
            selectedBBTypeId: value,
            bbType: {
                "id": value
            },

        })
    };
    AllDataSubmitUpdate = async () => {
        if (this.validator.allValid()) {
            var dataSet;
            dataSet = {
                "id": this.state.batteryBankId,

                "site": { "id": this.props?.detailsSite?.site_details.id },
                "asset": {
                    "id": 2
                },
                "make": this.state.makeType,
                "model": this.state.modelType,
                "batterySerialNumber": this.state.bbBatterySerialNumber,
                "materialCodeOfTheBatteryBank": this.state.bbMaterialCodeOfTheBatteryBank,
                "batteryTemperatureSensorInstalledCorrectly": this.state.bbBatteryTemperatureSensorInstalledCorrectly,
                "floatVoltage": this.state.bbFloatVoltage,
                "boostVoltage": this.state.bbBoostVoltage,
                "periodicBoostDuraion": this.state.bbPeriodicBoostDuraion,
                "periodic_boost_period": this.state.bbperiodic_boost_period,
                "batteryCurrentLimit": this.state.bbBatteryCurrentLimit,
                "relevantRectifier": this.state.bbrelevantRectifier,
                "batteryTemperature": this.state.bbbatteryTemperature,
                "bbType": this.state.bbType,
                "numberof48VBatteryBlocks": this.state.bbNumberof48VBatteryBlocks,
                "per48VBlockCapacity": this.state.bbper48VBlockCapacityrelevantRectifier,
                "floatVoltageSet": this.state.bbfloatVoltageSet,
                "temperatureCompansation": this.state.bbtemperatureCompansation,
                "idBatteryRackModel": this.state.bbIdBatteryRackModel,
                "idBatteryRackModel1Count": this.state.bbidBatteryRackModel1Count,
                "installedDate": this.state.bbInstalledDate,
                "batteryRackModelCount": this.state.bbhandleBBbatteryRackModelCount,
                "cabinets": this.state.cabinets,
                "rectifier": this.state.rectifier,
                "name": this.state.bbName,
                "totalCapacity": this.state.bbCapacity
            }
            //console.log(dataSet)
            var res = await UpdateBatteryBank(dataSet);
            if (res.success) {
                message.success('Successfully Battery Bank Edited!')
                this.formRef.current.resetFields();

                this.setState({
                    showModal: false,
                    open: false,
                    openEdit: false,
                    selectedAssetId: null,
                    AssetType: [],
                    selectedMakeId: null,
                    makeType: [],
                    selectedModelId: null,
                    modelType: [],
                    bbBatterySerialNumber: '',
                    bbMaterialCodeOfTheBatteryBank: '',
                    bbCapacity: '',
                    bbBatteryTemperatureSensorInstalledCorrectly: null,
                    bbFloatVoltage: '',
                    bbBoostVoltage: '',
                    bbperiodic_boost_period: '',
                    bbPeriodicBoostDuraion: '',
                    bbBatteryCurrentLimit: '',
                    bbrelevantRectifier: '',
                    bbType: null,
                    bbNumberof48VBatteryBlocks: '',
                    bbper48VBlockCapacityrelevantRectifier: '',
                    bbbatteryTemperature: '',
                    bbfloatVoltageSet: '',
                    bbIdBatteryRackModel: '',
                    bbidBatteryRackModel1Count: '',
                    bbhandleBBbatteryRackModelCount: '',
                    bbInstalledDate: null,
                    bbtemperatureCompansation: '',
                    cabinets: null,
                    rectifier: null,
                    bbName: '',
                    selectedCabinetsList: [],
                    selectedRectifierList: [],
                    selectedBBTypeId: null

                })
                this.props.parentMethod();
                // const { history } = this.props;
                // history.push("/site");
            } else {
                message.warning(' Something went wrong!')
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }
    AllDataSubmit = async () => {
        if (this.validator.allValid()) {
            var dataSet;
            dataSet = {

                "site": { "id": this.props?.detailsSite?.site_details.id },
                "asset": {
                    "id": 2
                },
                "make": this.state.makeType,
                "model": this.state.modelType,
                "batterySerialNumber": this.state.bbBatterySerialNumber,
                "batteryTemperatureSensorInstalledCorrectly": this.state.bbBatteryTemperatureSensorInstalledCorrectly,
                "floatVoltage": this.state.bbFloatVoltage,
                "boostVoltage": this.state.bbBoostVoltage,
                "periodicBoostDuraion": this.state.bbPeriodicBoostDuraion,
                "periodic_boost_period": this.state.bbperiodic_boost_period,
                "batteryCurrentLimit": this.state.bbBatteryCurrentLimit,
                "relevantRectifier": this.state.bbrelevantRectifier,
                "batteryTemperature": this.state.bbbatteryTemperature,
                "bbType": this.state.bbType,
                "numberof48VBatteryBlocks": this.state.bbNumberof48VBatteryBlocks,
                "floatVoltageSet": this.state.bbfloatVoltageSet,
                "temperatureCompansation": this.state.bbtemperatureCompansation,
                "idBatteryRackModel": this.state.bbIdBatteryRackModel,
                "idBatteryRackModel1Count": this.state.bbidBatteryRackModel1Count,
                "installedDate": this.state.bbInstalledDate,
                "batteryRackModelCount": this.state.bbhandleBBbatteryRackModelCount,
                "cabinets": this.state.cabinets,
                "rectifier": this.state.rectifier,
                "name": this.state.bbName != "" ? this.state.bbName : "Battery Model-"
            }
            //console.log(dataSet)
            var res = await createBatteryBank(dataSet);
            if (res.success) {
                message.success('Successfully Battery Bank Added!')
                this.formRef.current.resetFields();

                this.setState({
                    showModal: false,
                    open: false,
                    selectedAssetId: null,
                    AssetType: [],
                    selectedMakeId: null,
                    makeType: [],
                    selectedModelId: null,
                    modelType: [],
                    bbBatterySerialNumber: '',
                    bbMaterialCodeOfTheBatteryBank: '',
                    bbCapacity: '',
                    bbBatteryTemperatureSensorInstalledCorrectly: null,
                    bbFloatVoltage: '',
                    bbBoostVoltage: '',
                    bbperiodic_boost_period: '',
                    bbPeriodicBoostDuraion: '',
                    bbBatteryCurrentLimit: '',
                    bbrelevantRectifier: '',
                    bbType: null,
                    bbNumberof48VBatteryBlocks: '',
                    bbper48VBlockCapacityrelevantRectifier: '',
                    bbbatteryTemperature: '',
                    bbfloatVoltageSet: '',
                    bbIdBatteryRackModel: '',
                    bbidBatteryRackModel1Count: '',
                    bbhandleBBbatteryRackModelCount: '',
                    bbInstalledDate: null,
                    bbtemperatureCompansation: '',
                    cabinets: null,
                    rectifier: null,
                    bbName: '',
                    selectedCabinetsList: [],
                    selectedRectifierList: [],
                    selectedBBTypeId: null

                })
                this.props.parentMethod();
            } else {
                message.warning(' Something went wrong!')
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }
    getDataDetailsForDelete = async (id) => {
        var res = await getBatteryBankDetails(id);
        //console.log(res);
        if (res.success) {
            var respons = AssetDelete.Action(res.data, this.props?.detailsSite?.site_details.id, UpdateBatteryBank)



            respons.then((value) => {
                if (value == 1) {
                    message.success('Successfully Delete!')
                    this.props.parentMethod();
                } else {
                    message.warning(' Something went wrong!')
                }


            });



        }

    }
    submitForm = async (item) => {
        this.getDataDetailsForDelete(item);

    }

    viewMoreDetails(element) {
        this.setState({
            showModal2: true,
            openViewMoreDetails: true,
            selectedElement: element
        });
    }

    onViewMoreDetailsDrawerClose = () => {
        this.setState({
            showModal2: false,
            openViewMoreDetails: false,
        })
    }

    validatedTextLable = (field) => {
        return (field !== null && field !== '')
            ? <span class="badge badge-outline-secondary text-muted">{(field)}</span>
            : <span class="badge text-danger">Currently Not Set</span>;
    }

    validatedBoolLable = (field) => {
        return (field !== null && field !== '')
            ? field == 1
                ? <span class="badge badge-outline-success badge-pill"> Yes </span>
                : <span class="badge badge-outline-pink badge-pill"> No </span>
            : <span class="badge text-danger">Currently Not Set</span>;
    }

    handlbbActualBackupTime  = (e) => {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                bbActualBackupTime: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })

    }

    handlbbAllocatedBackupTime = (e) => {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                bbAllocatedBackupTime: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })

    }

    handlbbPlannedBatteryCapacityProjects = (e) => {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                bbPlannedBatteryCapacityProjects: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })

    }
    handlplannedBatteryCapacity = (e) => {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                plannedBatteryCapacity: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })
    
    }
    handlbbDesignedBackupTime = (e) => {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                bbDesignedBackupTime: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })

    }
    handlbbTicketNumberOfPendingBatteryFaultyTickets = (e) => {
        this.setState({
            objectSet: {
                ...this.state.objectSet,
                bbTicketNumberOfPendingBatteryFaultyTickets: e
            }

        }, () => {
            console.log(this.state.objectSet)
        })

    }
    dataUpdate() {
        console.log("ssss")
        this.setState({
            switchStatus: false
        })
    }
    close = async () => {
        this.props.parentMethod();
        this.setState({
            objectSet: {
                "bbTicketNumberOfPendingBatteryFaultyTickets": this.props.details?.access_technologies_data?.bbTicketNumberOfPendingBatteryFaultyTickets,
                "bbDesignedBackupTime": this.props.details?.access_technologies_data?.bbDesignedBackupTime,
                "bbAllocatedBackupTime": this.props.details?.access_technologies_data?.bbAllocatedBackupTime,
                "bbActualBackupTime": this.props.details?.access_technologies_data?.bbActualBackupTime,
                "bbPlannedBatteryCapacityProjects": this.props.details?.access_technologies_data?.bbPlannedBatteryCapacityProjects,
                "plannedBatteryCapacity":this.props.details?.access_technologies_data?.plannedBatteryCapacity,


            }
        });
        this.setState({
            switchStatus: true
        });
    }
    dataSetToUpdate = async () => {

        console.log(this.state.objectSet)
        console.log(this.props.detailsupdateGeneralDetails.updateGeneralDetails)
        let remoteJob = {
            ...this.props.detailsupdateGeneralDetails.updateGeneralDetails,
            ...this.state.objectSet
        };

        console.log(remoteJob)
        const formData = new FormData();
        formData.append('id', this.props.details?.access_technologies_data?.id);
        formData.append('object', JSON.stringify(remoteJob));

        var res = await dataUpdateGeneralDetails(formData);
        console.log(res)

           if(res.success){
              message.success('Successfully Update!')
              this.props.parentMethod();
              this.setState({
                switchStatus: true
            })
           }else{
            message.warning(' Something went wrong!')
           }


    }

    render() {
        const { Option } = Select;
        const { Panel } = Collapse;
        const _bBank = this.state.selectedElement
        console.log(_bBank)
        console.log("_bBank")
        // const columns2 = [
        //     {
        //         title: "Name",
        //         details: [],
        //         content: _bBank !== ""
        //             ? <Tag color="blue" style={{ margin: "0px" }}><span>{_bBank.name}</span></Tag> : <></>,
        //     },
        //     {
        //         title: "Battery Type",
        //         details: [],
        //         content: _bBank !== ""
        //             ? <span class="badge badge-soft-warning fw-semibold">{_bBank.bbType?.name}</span>
        //             : <></>,
        //     },
        //     {
        //         title: "Make/Brand",
        //         details: [],
        //         content: _bBank !== ""
        //             ? <Tag color="gray" style={{ margin: "0px" }}><span>{_bBank.make?.name}</span></Tag> : <></>,
        //     },
        //     {
        //         title: "Battery Model Name",
        //         content: _bBank !== ""
        //             ? <span class="badge badge-outline-info badge-pill"><i class="fas fa-hdd" style={{ paddingRight: "5px" }} />{_bBank.model?.name}</span>
        //             : <></>,
        //         details: [
        //             {
        //                 title: "Material Code",
        //                 content: this.validatedTextLable(_bBank.model?.materialCodeOfTheBatteryBank),
        //             },
        //             {
        //                 title: "Per 48V Block Capacity (Ah)",
        //                 content: this.validatedTextLable(_bBank.model?.per48VBlockCapacity),
        //             },
        //         ],
        //     },
        //     {
        //         title: "Cabinets",
        //         content: _bBank !== ""
        //             ? _bBank.cabinets.filter(name => name.delete == false).length != 0
        //                 ? <div class="d-flex justify-content-end align-items-center">
        //                     {_bBank.cabinets.filter(name => name.delete == false).map((e) => <span class="badge badge-soft-success fw-semibold" style={{ marginLeft: "5px" }}>{e.name}</span>)}
        //                 </div>
        //                 : <span class="badge badge-outline-secondary text-muted">No</span>
        //             : <></>,
        //         details: [],
        //     },
        //     {
        //         title: "Rectifiers",
        //         content: _bBank !== ""
        //             ? _bBank.rectifier.filter(name => name.delete == false).length != 0
        //                 ? <div class="d-flex justify-content-end align-items-center">
        //                     {_bBank.rectifier.filter(name => name.delete == false).map((e) => <span class="badge badge-soft-pink fw-semibold" style={{ marginLeft: "5px" }}>{e.name}</span>)}
        //                 </div>
        //                 : <span class="badge badge-outline-secondary text-muted">No</span>
        //             : <></>,
        //         details: [],
        //     },
        //     {
        //         title: "Installed Date",
        //         details: [],
        //         content: this.validatedTextLable(_bBank.installedDate),
        //     },
        //     {
        //         title: "Serial Number",
        //         content: this.validatedTextLable(_bBank.batterySerialNumber),
        //         details: [],
        //     },
        //     {
        //         title: "Total Capacity (Ah)",
        //         details: [],
        //         content: this.validatedTextLable(_bBank.totalCapacity),
        //         render: (item) => <span>{item}</span>,
        //     },
        //     {
        //         title: "Battery temperature sensor installed correctly",
        //         details: [],
        //         content: this.validatedBoolLable(_bBank.batteryTemperatureSensorInstalledCorrectly)
        //     },
        //     {
        //         title: "Float Voltage (V)",
        //         details: [],
        //         content: this.validatedTextLable(_bBank.floatVoltage),
        //     },
        //     {
        //         title: "Float Voltage set temperature (Cel)",
        //         details: [],
        //         content: this.validatedTextLable(_bBank.floatVoltageSet)
        //     },
        //     {
        //         title: "Boost Voltage (V)",
        //         details: [],
        //         content: this.validatedTextLable(_bBank.boostVoltage)
        //     },
        //     {
        //         title: "Periodic boost ch. Duraion (Days)",
        //         details: [],
        //         content: this.validatedTextLable(_bBank.periodicBoostDuraion)
        //     },
        //     {
        //         title: "Periodic boost ch. period/Cycle (Hrs)",
        //         details: [],
        //         content: this.validatedTextLable(_bBank.periodic_boost_period)
        //     },
        //     {
        //         title: "Number of 48V Battery Blocks",
        //         details: [],
        //         content: this.validatedTextLable(_bBank.numberof48VBatteryBlocks)
        //     },
        //     {
        //         title: "Battery current limit (C rate)",
        //         details: [],
        //         content: this.validatedTextLable(_bBank.batteryCurrentLimit)
        //     },


        //     {
        //         title: "Battery Temperature (Celceius)",
        //         details: [],
        //         content: this.validatedTextLable(_bBank.batteryTemperature)
        //     },
        //     {
        //         title: "Temperature compansation for 48V Battery Bank (mV/Celceius)",
        //         details: [],
        //         content: this.validatedTextLable(_bBank.temperatureCompansation)
        //     },
        // ];

        const columns2 = [
            {
                title: "Battery Model Name",
                content: _bBank !== "" ? 
                    <span class="badge badge-outline-info badge-pill">
                        <i class="fas fa-hdd" style={{ paddingRight: "5px" }} />{_bBank.model?.name}
                    </span> : <></>,
                details: [
                    {
                        title: "Material Code",
                        content: this.validatedTextLable(_bBank.model?.materialCodeOfTheBatteryBank),
                    },
                    {
                        title: "Per 48V Block Capacity (Ah)",
                        content: this.validatedTextLable(_bBank.model?.per48VBlockCapacity),
                    },
                ],
            },
            {
                title: "Battery Type",
                details: [],
                content: _bBank !== "" ?
                    <span class="badge badge-soft-warning fw-semibold">{_bBank.bbType?.name}</span> :
                    <></>,
            },
            {
                title: "Number of 48V Battery Blocks",
                details: [],
                content: this.validatedTextLable(_bBank.numberof48VBatteryBlocks),
            },
            {
                title: "Make/Brand",
                details: [],
                content: _bBank !== "" ?
                    <Tag color="gray" style={{ margin: "0px" }}>
                        <span>{_bBank.make?.name}</span>
                    </Tag> : <></>,
            },
            {
                title: "Battery Serial Number",
                details: [],
                content: this.validatedTextLable(_bBank.batterySerialNumber),
            },
            {
                title: "Model",
                details: [],
                content: this.validatedTextLable(_bBank.model?.name),
            },
            {
                title: "Material Code of the Battery Bank",
                details: [],
                content: this.validatedTextLable(_bBank.model?.materialCodeOfTheBatteryBank),
            },
            {
                title: "Per 48V Block Capacity (Ah)",
                details: [],
                content: this.validatedTextLable(_bBank.model?.per48VBlockCapacity),
            },
            {
                title: "Total Capacity (Ah)",
                details: [],
                content: this.validatedTextLable(_bBank.totalCapacity),
            },
            {
                title: "Battery temperature sensor installed correctly",
                details: [],
                content: this.validatedTextLable(_bBank.batteryTemperatureSensorInstalledCorrectly),
            },
            {
                title: "Float Voltage (V)",
                details: [],
                content: this.validatedTextLable(_bBank.floatVoltage),
            },
            {
                title: "Float Voltage set temperature (Cel)",
                details: [],
                content: this.validatedTextLable(_bBank.floatVoltageSetTemperature),
            },
            {
                title: "Boost Voltage (V)",
                details: [],
                content: this.validatedTextLable(_bBank.boostVoltage),
            },
            {
                title: "Periodic boost ch. Duraion (Days)",
                details: [],
                content: this.validatedTextLable(_bBank.periodicBoostChDuration),
            },
            {
                title: "Periodic boost ch. period/Cycle (Hrs)",
                details: [],
                content: this.validatedTextLable(_bBank.periodicBoostChPeriod),
            },
            {
                title: "Battery current limit (C rate)",
                details: [],
                content: this.validatedTextLable(_bBank.batteryCurrentLimit),
            },
            {
                title: "Temperature compansation for 48V Battery Bank (mV/Celceius)",
                details: [],
                content: this.validatedTextLable(_bBank.temperatureCompansationFor48VBatteryBank),
            },
            {
                title: "Relevant Rectifier",
                details: [],
                content: _bBank !== "" ?
                    _bBank.rectifier.filter(name => name.delete == false).length !== 0 ?
                        <div class="d-flex justify-content-end align-items-center">
                            {_bBank.rectifier.filter(name => name.delete == false).map((e) => <span class="badge badge-soft-pink fw-semibold" style={{ marginLeft: "5px" }}>{e.name}</span>)}
                        </div>
                        : <span class="badge badge-outline-secondary text-muted">No</span>
                    : <></>,
            },
            {
                title: "Battery Temperature (Celceius)",
                details: [],
                content: this.validatedTextLable(_bBank.batteryTemperature),
            },
            {
                title: "Installed date",
                details: [],
                content: this.validatedTextLable(_bBank.installedDate),
            },
            // ... (continue with the remaining fields)
        ];

        return (
            <>

                <div className='row'>
                    <div class="col-lg-12">
                        <div class="text-end">
                            <ul class="list-inline">
                                <li class="list-inline-item">
                                    {this.state.actionView
                                        ? <button type="button" class="btn btn-soft-primary btn-sm" onClick={this.requestFromClose}><i class="fas fa-plus me-2"></i>Add Battery Banks</button>
                                        : ('')
                                    }
                                </li>
                            </ul>
                        </div>
                    </div>
                  /
                    <div className='row'>

                        <div className='col-md-12'>
                            <div class="card">
                                <div class="card-header">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <h4 class="card-title">Information</h4>
                                        {this.state.switchStatus
                                            ? <button type="button" class="btn btn-soft-primary btn-icon-square-sm" onClick={() => this.dataUpdate()}> <i class="mdi mdi-pencil-outline"></i></button>
                                            : <div class="d-flex justify-content-end align-items-center">
                                                <button type="button" class="btn btn-soft-secondary " onClick={() => this.close()} style={{ marginRight: "10px" }}> Cancel </button>
                                                <button type="button" class="btn btn-soft-primary " onClick={() => this.dataSetToUpdate()}> Submit to Update </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div className='col-md-6'>
                                            <ul class="list-group">

                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <i class="la la-hand-o-right text-primary me-2"></i> No of Battery Models at Site

                                                    </div>
                                                    {this.props.details?.access_technologies_data?.noOfBatteryBanks != null ? 
                                                    (<span class="badge badge-outline-success">{this.props.details2.battery_banks_data.length}</span>) : 
                                                    (<span class="badge badge-outline-warning">Currently Not Set</span>)}
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <i class="la la-hand-o-right text-primary me-2"></i>Ticket number of Pending Battery faulty tickets
                                                    </div>


                                                    {this.state.switchStatus ? (<>{this.props.details?.access_technologies_data?.bbTicketNumberOfPendingBatteryFaultyTickets != null ? (<span class="badge badge-outline-success">{this.props.details?.access_technologies_data?.bbTicketNumberOfPendingBatteryFaultyTickets}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>  <InputNumber min={0} style={{ width: '20%' }} value={this.state.objectSet?.bbTicketNumberOfPendingBatteryFaultyTickets} id="cnoOfCabinetsWithEquipmentInstalled" onChange={this.handlbbTicketNumberOfPendingBatteryFaultyTickets} /> </>)}


                                                </li>
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <i class="la la-hand-o-right text-primary me-2"></i> Designed Back up time (Hrs)
                                                    </div>

                                                    {/* //  {this.state.switchStatus ? (<>{this.props?.details?.access_technologies_data?.cticketNumbersOfPendingFaultyTickets != null ? (<span class="badge badge-outline-success">{this.props?.details?.access_technologies_data?.cticketNumbersOfPendingFaultyTickets}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>  <InputNumber style={{ width: '20%' }} value={this.state.objectSet?.cticketNumbersOfPendingFaultyTickets} id="cnoOfCabinetsWithEquipmentInstalled" onChange={this.handlcticketNumbersOfPendingFaultyTickets} />  </>)} */}

                                                    {this.state.switchStatus ? (<>{this.props.details?.access_technologies_data?.bbDesignedBackupTime != null ? (<span class="badge badge-outline-success">{this.props.details?.access_technologies_data?.bbDesignedBackupTime}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>  <InputNumber min={0} style={{ width: '20%' }} value={this.state.objectSet?.bbDesignedBackupTime} id="bbDesignedBackupTime" onChange={this.handlbbDesignedBackupTime} /> </>)}



                                                </li>
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <i class="la la-hand-o-right text-primary me-2"></i> Planned Battery Capacity (Ah)-Operation

                                                    </div>

                                                    {/* //  {this.state.switchStatus ? (<>{this.props?.details?.access_technologies_data?.cticketNumbersOfPendingFaultyTickets != null ? (<span class="badge badge-outline-success">{this.props?.details?.access_technologies_data?.cticketNumbersOfPendingFaultyTickets}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>  <InputNumber style={{ width: '20%' }} value={this.state.objectSet?.cticketNumbersOfPendingFaultyTickets} id="cnoOfCabinetsWithEquipmentInstalled" onChange={this.handlcticketNumbersOfPendingFaultyTickets} />  </>)} */}

                                                    {this.state.switchStatus ? (<>{this.props.details?.access_technologies_data?.plannedBatteryCapacity != null ? (<span class="badge badge-outline-success">{this.props.details?.access_technologies_data?.plannedBatteryCapacity}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>  <InputNumber min={0} style={{ width: '20%' }} value={this.state.objectSet?.plannedBatteryCapacity} id="plannedBatteryCapacity" onChange={this.handlplannedBatteryCapacity} /> </>)}



                                                </li>
                                               

                                            </ul>
                                        </div>

                                        <div className='col-md-6'>
                                            <ul class="list-group">


                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <i class="la la-hand-o-right text-primary me-2"></i>Planned Battery Capacity (Ah) - Projects
                                                    </div>

                                                    {/* {this.props?.details?.access_technologies_data?.cplannedFromPlanninglTeam ? (<span class="badge badge-outline-success">true</span>) : (<span class="badge badge-outline-warning">false</span>)} */}

                                                    {/* {this.props?.details?.access_technologies_data?.rwcuPlannedFromOperationalTeam ? (<> <Switch disabled={this.state.switchStatus} defaultChecked onChange={() => this.switchTorwcuPlannedFromOperationalTeam()} checkedChildren="true" unCheckedChildren="false" /></>) : (<Switch disabled={this.state.switchStatus} onChange={() => this.switchTorwcuPlannedFromOperationalTeam()} checkedChildren="true" unCheckedChildren="false" />)} */}
                                                    {this.state.switchStatus ? (<>{this.props.details?.access_technologies_data?.bbPlannedBatteryCapacityProjects != null ? (<span class="badge badge-outline-success">{this.props.details?.access_technologies_data?.bbPlannedBatteryCapacityProjects}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>  <InputNumber min={0} style={{ width: '20%' }} value={this.state.objectSet?.bbPlannedBatteryCapacityProjects} id="bbPlannedBatteryCapacityProjects" onChange={this.handlbbPlannedBatteryCapacityProjects} /> </>)}


                                                </li>
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <i class="la la-hand-o-right text-primary me-2"></i>Allocated Back up time (Hrs):
                                                    </div>

                                                    {/* {this.props?.details?.access_technologies_data?.cplannedFromPlanninglTeam ? (<span class="badge badge-outline-success">true</span>) : (<span class="badge badge-outline-warning">false</span>)} */}

                                                    {/* {this.props?.details?.access_technologies_data?.bbAllocatedBackupTime ? (<> <Switch disabled={this.state.switchStatus} defaultChecked onChange={() => this.switchrwcuPlannedFromPlanninglTeam()} checkedChildren="true" unCheckedChildren="false" /></>) : (<Switch disabled={this.state.switchStatus} onChange={() => this.switchrwcuPlannedFromPlanninglTeam()} checkedChildren="true" unCheckedChildren="false" />)} */}
                                                    {this.state.switchStatus ? (<>{this.props.details?.access_technologies_data?.bbAllocatedBackupTime != null ? (<span class="badge badge-outline-success">{this.props.details?.access_technologies_data?.bbAllocatedBackupTime}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>  <InputNumber min={0} style={{ width: '20%' }} value={this.state.objectSet?.bbAllocatedBackupTime} id="bbAllocatedBackupTime" onChange={this.handlbbAllocatedBackupTime} /> </>)}


                                                </li>
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <i class="la la-hand-o-right text-primary me-2"></i>Actual Back up time (Hrs):
                                                    </div>

                                                    {/* {this.props?.details?.access_technologies_data?.cplannedFromPlanninglTeam ? (<span class="badge badge-outline-success">true</span>) : (<span class="badge badge-outline-warning">false</span>)} */}

                                                    {/* {this.props?.details?.access_technologies_data?.rmPlannedFromOperationalTeam ? (<> <Switch disabled={this.state.switchStatus} defaultChecked onChange={() => this.switchrmPlannedFromOperationalTeam()} checkedChildren="true" unCheckedChildren="false" /></>) : (<Switch disabled={this.state.switchStatus} onChange={() => this.switchrmPlannedFromOperationalTeam()} checkedChildren="true" unCheckedChildren="false" />)} */}
                                                    {this.state.switchStatus ? (<>{this.props.details?.access_technologies_data?.bbActualBackupTime != null ? (<span class="badge badge-outline-success">{this.props.details?.access_technologies_data?.bbActualBackupTime}</span>) : (<span class="badge badge-outline-warning">Currently Not Set</span>)}</>) : (<>  <InputNumber min={0} style={{ width: '20%' }} value={this.state.objectSet?.bbActualBackupTime} id="bbActualBackupTime" onChange={this.handlbbActualBackupTime} /> </>)}


                                                </li>
                                              

                                            </ul>
                                        </div>



                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    {this.props.details2.battery_banks_data.map((element) => (
                        <div className='col-md-4'>
                            <div class="card">
                                <div class="card-header">
                                    <div class="d-flex justify-content-start align-items-center">
                                        <img src={'https://static.vecteezy.com/system/resources/previews/001/902/502/large_2x/telecommunication-radio-antenna-and-satelite-tower-with-blue-sky-free-photo.JPG'} alt="" class="thumb-md rounded" />
                                        <div class="badge badge-soft-primary" style={{ padding: "5px", marginLeft: "15px", border: "1px solid rgba(0, 0, 0, 0.05)" }}>
                                            <i class="la la-home font-14 text-dark" style={{ paddingLeft: "10px" }} />
                                            <i class="la la-angle-right text-dark font-14" style={{ padding: "5px" }} />
                                            <span class="fw-semibold text-pink">{
                                                element.rectifier != null && element.rectifier.length > 0
                                                    ? element.rectifier[0].name + (element.rectifier.filter(name => name.delete == false).length > 1 ? (" ...") : (""))
                                                    : ""
                                            }</span>
                                            <i class="la la-angle-right text-dark font-14" style={{ padding: "5px" }} />
                                            <span class="fw-semibold text-primary" style={{ paddingRight: "10px" }}>{element?.name?.substring(0, 18)}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="col">
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <h6 class="fw-normal m-0">Make/Brand</h6>
                                            <span class="badge bg-secondary">{element.make?.name}</span>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <h6 class="fw-normal m-0">Battery Model Name</h6>
                                            <span class="badge badge-outline-info badge-pill"><i class="fas fa-hdd" style={{ paddingRight: "5px" }} />{element.model?.name}</span>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <h6 class="fw-normal m-0">Battery Type</h6>
                                            <div class="d-flex align-items-center">
                                                <span class="badge badge-soft-warning fw-semibold">{element.bbType?.name}</span>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <h6 class="fw-normal m-0">Total Capacity</h6>
                                            <div class="d-flex align-items-center">
                                                <span class="badge badge-soft-success fw-semibold">{element.totalCapacity}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p class="card-footer m-0">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <a href="#custom-modal" class="btn btn-soft-dark btn-sm waves-effect btn-round" onClick={() => this.viewMoreDetails(element)}>
                                            <i class="mdi mdi mdi-unfold-more-horizontal"></i> More Details </a>
                                        <div class="text-end">
                                            {this.state.AssetManagementPermission
                                                ? (<button type="button" class="btn btn-soft-primary btn-icon-square-sm" onClick={() => this.batteryBankUpdata(element.id)} style={{ marginRight: "8px" }}><i class="mdi mdi-pencil-outline"></i></button>)
                                                : ("")}
                                            <Popconfirm
                                                title="Are You Sure?"
                                                description="Are you sure to delete this task?"
                                                onConfirm={() => this.submitForm(element.id)}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                {this.state.AssetManagementPermission
                                                    ? (<button type="button" class="btn btn-soft-danger btn-icon-square-sm"><i class="mdi mdi-trash-can-outline"></i></button>)
                                                    : ('')}
                                            </Popconfirm>
                                        </div>
                                    </div>
                                </p>
                            </div>
                        </div>
                    ))}

                    <Drawer maskClosable={false} title={"Battery Bank Details"} width={"50%"} placement="right" onClose={() => this.onViewMoreDetailsDrawerClose()} visible={this.state.openViewMoreDetails} closeIcon={<button type="button" class="btn btn-soft-danger btn-icon-square-sm" style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                        <Collapse style={{ borderColor: "lavender", alignContent: "center" }}>
                            {columns2.map((item) => (
                                <Panel
                                    style={{ borderColor: "lavender" }}
                                    header={
                                        <li class="d-flex justify-content-between align-items-center">
                                            <div>
                                                {item.details.length == 0 ? <i class="la la-hand-o-right text-primary" style={{ paddingLeft: "5px", paddingRight: "5px" }} /> : <></>}
                                                <span class="fw-normal m-2">{item.title}</span>
                                            </div>
                                            {item.content}
                                        </li>
                                    }
                                    key={item.title}
                                    showArrow={item.details.length > 0}
                                    collapsible={item.details.length > 0 ? "" : "icon"}
                                >
                                    {item.details.map((detail) => (
                                        <li class="d-flex justify-content-between align-items-center me-2" style={{ paddingBottom: "10px", paddingLeft: "10px" }}>
                                            <div>
                                                <i class="la la-circle text-primary me-2"></i> <span>{detail.title}</span>
                                            </div>
                                            {detail.content}
                                        </li>
                                    ))}
                                </Panel>
                            ))}
                        </Collapse>
                    </Drawer>

                    <Drawer maskClosable={false} title={" New Battery Bank"} width={"70%"} placement="right" onClose={() => this.onDrawerClose()} visible={this.state.open} closeIcon={<button type="button" class="btn btn-soft-danger btn-icon-square-sm" style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                        <div className="row" >
                            <div className="col-lg-12">
                                <div className="card">

                                    <div className="card-body">
                                        <Form
                                            ref={this.formRef}
                                        >
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="dcdu">
                                                        Battery Bank Name <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="name" tooltip="This is a required field">
                                                        <Input id="name" defaultValue={"Battery Model-"} onChange={this.handleName} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Battery Bank Name', this.state.bbName, 'required')}</p>
                                                    </Form.Item>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" for="dcdu">
                                                        Battery Type <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="bbType" tooltip="This is a required field">
                                                        <Select
                                                            showSearch
                                                            onChange={this.onChangeBBType}
                                                            style={{ width: "100%" }}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >
                                                            {this.props.bbMasterData?.bbMasterData?.bbTypeList?.map((item, index) => {
                                                                return <Option value={item?.id}>{item?.name}</Option>;
                                                            })}

                                                        </Select>
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Battery Bank Type', this.state.bbType, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="dcdu">
                                                        Number of 48V Battery Blocks <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="bbnumberof48VBatteryBlocks" tooltip="This is a required field">
                                                        <InputNumber id="bbnumberof48VBatteryBlocks" style={{ width: '100%' }} onChange={this.handlebbNumberof48VBatteryBlocks} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Number of 48V Battery Blocks', this.state.bbNumberof48VBatteryBlocks, 'required')}</p>
                                                    </Form.Item>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                    Make/Brand <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="Is_active" tooltip="This is a required field">
                                                        <Select
                                                            showSearch
                                                            onChange={this.onChangeMake}
                                                            style={{ width: "100%" }}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >
                                                            {this.props.bbMasterData?.bbMasterData?.bbMakeList?.map((item, index) => {
                                                                return <Option value={item?.id}>{item?.name}</Option>;
                                                            })}

                                                        </Select>
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Make', this.state.selectedMakeId, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="dcdu">
                                                        Battery Serial Number <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="batterySerialNumber" tooltip="This is a required field">
                                                        <Input id="batterySerialNumber" onChange={this.handleBatterySerialNumber} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Battery Serial Number', this.state.bbBatterySerialNumber, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                    Battery Model Name <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="Model" tooltip="This is a required field">
                                                        <Select
                                                            showSearch
                                                            onChange={this.onChangeModel}
                                                            style={{ width: "100%" }}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >
                                                            {this.props.bbMasterData?.bbMasterData?.bbModelList?.map((item, index) => {
                                                                return <Option value={item?.id}>{item?.name}</Option>;
                                                            })}

                                                        </Select>
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Model', this.state.selectedModelId, 'required')}</p>

                                                    </Form.Item>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" for="ats">
                                                        Battery Temperature Sensor Installed Correctly <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="bb_floatVoltage" >
                                                        <Select
                                                            onChange={this.onChangeBatteryTemperatureSensorInstalledCorrectly}
                                                            style={{ width: "100%" }}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >
                                                            <Option value='1'>Yes</Option>
                                                            <Option value='0'>No</Option>

                                                        </Select>
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Battery Temperature Sensor Installed Correctly', this.state.bbBatteryTemperatureSensorInstalledCorrectly, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="CC">
                                                        Float Voltage(V) <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="bb_floatVoltage" >
                                                        <InputNumber style={{ width: '100%' }} id="bb_floatVoltage" onChange={this.handleBBFloatVoltage} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Float Voltage', this.state.bbFloatVoltage, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="CC">
                                                        Float Voltage set temperature (Cel) <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="bb_floatVoltageSet" >
                                                        <InputNumber style={{ width: '100%' }} id="bb_floatVoltageSet" onChange={this.handleBBfloatVoltageSet} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('float Voltage Set', this.state.bbfloatVoltageSet, 'required')}</p>
                                                    </Form.Item>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" for="CC">
                                                        Boost Voltage(V) <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="bb_boostVoltage" >
                                                        <InputNumber style={{ width: '100%' }} id="bb_boostVoltage" onChange={this.handleBBBoostVoltage} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Boost Voltage', this.state.bbBoostVoltage, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="CC">
                                                        Periodic Boost ch. Duraion (Days) <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="bb_PeriodicBoostDuraion" >
                                                        <InputNumber style={{ width: '100%' }} id="bb_PeriodicBoostDuraion" onChange={this.handleBBPeriodicBoostDuraion} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Periodic Boost Duraion', this.state.bbPeriodicBoostDuraion, 'required')}</p>
                                                    </Form.Item>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" for="CC">
                                                        Periodic Boost ch. Period/Cycle (Hrs) <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="bb_periodic_boost_period" >
                                                        <InputNumber style={{ width: '100%' }} id="bb_periodic_boost_period" onChange={this.handleBBperiodic_boost_period} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Periodic Boost Period', this.state.bbperiodic_boost_period, 'required')}</p>
                                                    </Form.Item>

                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="CC">
                                                    Battery Current Limit (C rate) <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="bb_batteryCurrentLimit" >
                                                        <InputNumber style={{ width: '100%' }} id="bb_batteryCurrentLimit" onChange={this.handleBBBatteryCurrentLimit} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Battery Current Limit', this.state.bbBatteryCurrentLimit, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="CC">
                                                        Temperature Compansation For 48V Battery Bank (mV/Celceius) <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="bb_temperatureCompansation" >
                                                        <InputNumber style={{ width: '100%' }} id="bb_temperatureCompansation" onChange={this.handleBBtemperatureCompansation} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Temperature Compansation', this.state.bbtemperatureCompansation, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        Rectifier <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="Rectifiers" tooltip="This is a required field">
                                                        <Select
                                                            showSearch
                                                            onChange={this.onChangeRectifierForCreate}
                                                            style={{ width: "100%" }}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >
                                                            {this.props.bbMasterData?.bbMasterData?.rectifierList?.map((item, index) => {
                                                                return <Option value={item?.id}>{item?.name}</Option>;
                                                            })}
                                                        </Select>
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Rectifiers', this.state.rectifier, 'required')}</p>
                                                    </Form.Item>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" for="CC">
                                                    Battery Temperature (Celceius) <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="bb_batteryTemperature" >
                                                        <InputNumber style={{ width: '100%' }} id="bb_batteryTemperature" onChange={this.handleBBbatteryTemperature} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Battery Temperature', this.state.bbbatteryTemperature, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        Installed Date
                                                    </label>
                                                    <Form.Item required name="installedDate" tooltip="This is a required field">
                                                        <DatePicker

                                                            style={{ width: "100%" }}
                                                            onChange={this.onChangeInstalledDate}
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        Cabinets 
                                                    </label>
                                                    <Form.Item required name="Cabinets" tooltip="This is a required field">
                                                        <Select
                                                            mode="multiple"
                                                            showSearch
                                                            onChange={this.onChangeCabinet}
                                                            style={{ width: "100%" }}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >
                                                            {this.props.bbMasterData?.bbMasterData?.cabinetList?.map((item, index) => {
                                                                return <Option value={item?.id}>{item.name}</Option>;
                                                            })}
                                                        </Select>
                                                    
                                                    </Form.Item>
                                                </div>

                                            </div>
                                        </Form>
                                        <div className='row' style={{ justifyContent: "end" }}>
                                            <div className='col-md-2' >   <button className="btn btn-primary" onClick={() => this.AllDataSubmit()} >Submit</button></div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </Drawer>

                    <Drawer maskClosable={false} title={" Edit Battery Bank"} width={"70%"} placement="right" onClose={() => this.onDrawerClose()} visible={this.state.openEdit} closeIcon={<button type="button" class="btn btn-soft-danger btn-icon-square-sm" style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                        <div className="row" >
                            <div className="col-lg-12">
                                <div className="card">

                                    <div className="card-body">
                                        <Form
                                            ref={this.formRef}
                                        >
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="dcdu">
                                                        Battery Bank Name <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item tooltip="This is a required field">
                                                        <Input id="name" value={this.state.bbName} onChange={this.handleName} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Battery Bank Name', this.state.bbName, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="dcdu">
                                                        Battery Type <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item tooltip="This is a required field">
                                                        <Select
                                                            showSearch
                                                            value={this.state.selectedBBTypeId}
                                                            onChange={this.onChangeBBType}
                                                            style={{ width: "100%" }}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >
                                                            {this.props.bbMasterData?.bbMasterData?.bbTypeList?.map((item, index) => {
                                                                return <Option value={item?.id}>{item?.name}</Option>;
                                                            })}

                                                        </Select>
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Battery Bank Type', this.state.bbType, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="dcdu">
                                                        Number of 48V Battery Blocks <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item tooltip="This is a required field">
                                                        <InputNumber id="bbnumberof48VBatteryBlocks" style={{ width: '100%' }} value={this.state.bbNumberof48VBatteryBlocks} onChange={this.handlebbNumberof48VBatteryBlocks} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Number of 48V Battery Blocks', this.state.bbNumberof48VBatteryBlocks, 'required')}</p>
                                                    </Form.Item>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                    Make/Brand <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    {this.state.selectedMakeId != null ? (<Form.Item required name="Is_active" tooltip="This is a required field">
                                                        <Select
                                                            showSearch
                                                            onChange={this.onChangeMake}
                                                            defaultValue={this.state.selectedMakeId}
                                                            style={{ width: "100%" }}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >
                                                            {this.props.bbMasterData?.bbMasterData?.bbMakeList?.map((item, index) => {
                                                                return <Option value={item?.id}>{item?.name}</Option>;
                                                            })}
                                                        </Select>
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Make', this.state.selectedMakeId, 'required')}</p>

                                                    </Form.Item>) : ('')}
                                                </div>
                                                
                                                <div className="col-md-6">
                                                    <label className="form-label" for="dcdu">
                                                        Battery Serial Number <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item tooltip="This is a required field">
                                                        <Input id="batterySerialNumber" value={this.state.bbBatterySerialNumber} onChange={this.handleBatterySerialNumber} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Battery Serial Number', this.state.bbBatterySerialNumber, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                    Battery Model Name <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    {this.state.selectedModelId != null ? (<Form.Item required name="Model" tooltip="This is a required field">
                                                        <Select
                                                            showSearch
                                                            onChange={this.onChangeModel}
                                                            defaultValue={this.state.selectedModelId}
                                                            style={{ width: "100%" }}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >
                                                            {this.props.bbMasterData?.bbMasterData?.bbModelList?.map((item, index) => {
                                                                return <Option value={item?.id}>{item?.name}</Option>;
                                                            })}
                                                        </Select>
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Model', this.state.selectedModelId, 'required')}</p>

                                                    </Form.Item>) : ('')}
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" for="ats">
                                                        Battery Temperature Sensor Installed Correctly <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    {this.state.bbBatteryTemperatureSensorInstalledCorrectly != null ? (
                                                        <Form.Item >
                                                            <Select
                                                                onChange={this.onChangeBatteryTemperatureSensorInstalledCorrectly}
                                                                defaultValue={(this.state.bbBatteryTemperatureSensorInstalledCorrectly).toString()}
                                                                //  defaultValue={this.state.isSiteKeyRequired}

                                                                style={{ width: "100%" }}
                                                                placeholder="Search to Select"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                filterSort={(optionA, optionB) =>
                                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                }
                                                            >
                                                                <Option value='1'>Yes</Option>
                                                                <Option value='0'>No</Option>

                                                            </Select>
                                                            <p style={{ color: "#f5325c" }}>{this.validator.message('Battery Temperature Sensor Installed Correctly', this.state.bbBatteryTemperatureSensorInstalledCorrectly, 'required')}</p>
                                                        </Form.Item>) : ('')}

                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="CC">
                                                        Float Voltage(V) <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item  >
                                                        <InputNumber style={{ width: '100%' }} value={Number(this.state.bbFloatVoltage)} id="bb_floatVoltage" onChange={this.handleBBFloatVoltage} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Float Voltage', this.state.bbFloatVoltage, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="CC">
                                                        Float Voltage set temperature (Cel) <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item >
                                                        <InputNumber style={{ width: '100%' }} value={this.state.bbfloatVoltageSet} id="bb_floatVoltageSet" onChange={this.handleBBfloatVoltageSet} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('float Voltage Set', this.state.bbfloatVoltageSet, 'required')}</p>
                                                    </Form.Item>
                                                </div>


                                                <div className="col-md-6">
                                                    <label className="form-label" for="CC">
                                                        Boost Voltage(V) <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item  >
                                                        <InputNumber style={{ width: '100%' }} value={this.state.bbBoostVoltage} id="bb_boostVoltage" onChange={this.handleBBBoostVoltage} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Boost Voltage', this.state.bbBoostVoltage, 'required')}</p>
                                                    </Form.Item>

                                                </div>
                                            <div className="col-md-6">
                                                    <label className="form-label" for="CC">
                                                        Periodic Boost ch. Duraion (Days) <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item  >
                                                        <InputNumber style={{ width: '100%' }} value={this.state.bbPeriodicBoostDuraion} id="bb_PeriodicBoostDuraion" onChange={this.handleBBPeriodicBoostDuraion} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Periodic Boost Duraion', this.state.bbPeriodicBoostDuraion, 'required')}</p>
                                                    </Form.Item>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" for="CC">
                                                        Periodic Boost ch. Period/Cycle (Hrs) <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item >
                                                        <InputNumber style={{ width: '100%' }} value={this.state.bbperiodic_boost_period} id="bb_periodic_boost_period" onChange={this.handleBBperiodic_boost_period} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Periodic Boost Period', this.state.bbperiodic_boost_period, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="CC">
                                                        Battery Current Limit (C rate) <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item >
                                                        <InputNumber style={{ width: '100%' }} value={this.state.bbBatteryCurrentLimit} id="bb_batteryCurrentLimit" onChange={this.handleBBBatteryCurrentLimit} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Battery Current Limit', this.state.bbBatteryCurrentLimit, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="CC">
                                                    Temperature compansation for 48V Battery Bank (mV/Celceius) <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item  >
                                                        <InputNumber style={{ width: '100%' }} value={this.state.bbtemperatureCompansation} id="bb_temperatureCompansation" onChange={this.handleBBtemperatureCompansation} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Temperature Compansation', this.state.bbtemperatureCompansation, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        Rectifiers <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="Rectifiers" tooltip="This is a required field">
                                                        <Select
                                                            mode="multiple"
                                                            showSearch
                                                            defaultValue={this.state.selectedRectifierList}
                                                            onChange={this.onChangeRectifier}
                                                            style={{ width: "100%" }}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >
                                                            {this.props.bbMasterData?.bbMasterData?.rectifierList?.map((item, index) => {
                                                                return <Option value={item?.id}>{item?.name}</Option>;
                                                            })}
                                                        </Select>
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Rectifiers', this.state.rectifier, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="CC">
                                                    Battery Temperature (Celceius)<x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item  >
                                                        <InputNumber style={{ width: '100%' }} value={this.state.bbbatteryTemperature} id="bb_batteryTemperature" onChange={this.handleBBbatteryTemperature} />
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Battery Temperature', this.state.bbbatteryTemperature, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        Installed Date
                                                    </label>
                                                    {this.state.bbInstalledDate != null ? (<Form.Item   ><DatePicker
                                                        defaultValue={this.state.bbInstalledDate != '' ? (moment(this.state.bbInstalledDate)) : ('')}
                                                        style={{ width: "100%" }}
                                                        onChange={this.onChangeInstalledDate}
                                                    /></Form.Item>) : ('')}
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        Cabinets 
                                                    </label>
                                                    {this.state.cabinets!=null?(  <Form.Item required name="Cabinets" tooltip="This is a required field">
                                                        <Select
                                                            mode="multiple"
                                                            showSearch
                                                            defaultValue={this.state.selectedCabinetsList}
                                                            onChange={this.onChangeCabinet}
                                                            style={{ width: "100%" }}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >
                                                            {this.props.bbMasterData?.bbMasterData?.cabinetList?.map((item, index) => {
                                                                return <Option value={item?.id}>{item.name}</Option>;
                                                            })}
                                                        </Select>
                                                        {/* <p style={{ color: "#f5325c" }}>{this.validator.message('Cabinets', this.state.cabinets, 'required')}</p> */}
                                                    </Form.Item>):('')}
                                                    {this.state.cabinets==null?(  <Form.Item required name="Cabinets" tooltip="This is a required field">
                                                        <Select
                                                            mode="multiple"
                                                            showSearch
                                                     
                                                            onChange={this.onChangeCabinet}
                                                            style={{ width: "100%" }}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >
                                                            {this.props.bbMasterData?.bbMasterData?.cabinetList?.map((item, index) => {
                                                                return <Option value={item?.id}>{item.name}</Option>;
                                                            })}
                                                        </Select>
                                                        {/* <p style={{ color: "#f5325c" }}>{this.validator.message('Cabinets', this.state.cabinets, 'required')}</p> */}
                                                    </Form.Item>):('')}
                                                </div>
                                            </div>
                                        </Form>
                                        <div className='row' style={{ justifyContent: "end" }}>
                                            <div className='col-md-2' >   <button className="btn btn-primary" onClick={() => this.AllDataSubmitUpdate()} >Submit</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Drawer>
                </div>
            </>
        )
    }
}
export default BatteryBanks;
