import React from "react";
import axios from "axios";
import _ from "lodash";
import moment from "moment"
import {
  BASE_URL,
  GET_INCOMPLETE_SCHEDULES,
  DEV_GET_INCOMPLETE_SCHEDULES
} from "../api/api.config"
import markers from "../../markers.config"
import { getPgByDepots } from "../api/index";


const Context = React.createContext();
const withContext = Component => (
  <Context.Consumer>{data => <Component  {...data}/>}</Context.Consumer>
);

class DataContext extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      full_data_set:{
        "Success": true,
        "data": {
            "final_card_data": [
                {
                    "name": "Boralesgamuwa",
                    "data": [
                        {
                            "delay_status": 1,
                            "vehicle_no": "LN-4763",
                            "StartTime": "2021-12-22 08:50:57",
                            "EndTime": "2021-12-22 10:06:44",
                            "DepartureTime": "2021-12-22 12:09:44",
                            "ArrivalTime": "waiting",
                            "status": "3",
                            "departure_scheduled_time": "09.30.00",
                            "late_duration": "02:39:44"
                        },
                        {
                            "delay_status": 1,
                            "vehicle_no": "LO-2538",
                            "StartTime": "2021-12-22 08:51:05",
                            "EndTime": "2021-12-22 10:07:05",
                            "DepartureTime": "2021-12-22 12:09:49",
                            "ArrivalTime": "waiting",
                            "status": "3",
                            "departure_scheduled_time": "09.30.00",
                            "late_duration": "02:39:49"
                        },
                        {
                            "delay_status": 1,
                            "vehicle_no": "LO-2677",
                            "StartTime": "2021-12-22 08:51:09",
                            "EndTime": "2021-12-22 10:07:09",
                            "DepartureTime": "2021-12-22 12:09:52",
                            "ArrivalTime": "waiting",
                            "status": "3",
                            "departure_scheduled_time": "09.30.00",
                            "late_duration": "02:39:52"
                        },
                        {
                            "delay_status": 1,
                            "vehicle_no": "LO-4246",
                            "StartTime": "2021-12-22 08:51:12",
                            "EndTime": "2021-12-22 10:07:44",
                            "DepartureTime": "2021-12-22 12:10:15",
                            "ArrivalTime": "waiting",
                            "status": "3",
                            "departure_scheduled_time": "09.30.00",
                            "late_duration": "02:40:15"
                        },
                        {
                            "delay_status": 1,
                            "vehicle_no": "LO-4247",
                            "StartTime": "2021-12-22 08:51:15",
                            "EndTime": "2021-12-22 10:07:42",
                            "DepartureTime": "2021-12-22 12:10:25",
                            "ArrivalTime": "waiting",
                            "status": "3",
                            "departure_scheduled_time": "09.30.00",
                            "late_duration": "02:40:25"
                        }
                    ]
                },
                {
                    "name": "Chilaw",
                    "data": [
                        {
                            "delay_status": 0,
                            "vehicle_no": "LK-2688",
                            "StartTime": "2021-12-23 07:20:28",
                            "EndTime": "2021-12-23 08:56:24",
                            "DepartureTime": "2021-12-23 09:09:01",
                            "ArrivalTime": "waiting",
                            "status": "3",
                            "departure_scheduled_time": "09.30.00",
                            "late_duration": "00:00:00"
                        },
                        {
                            "delay_status": 1,
                            "vehicle_no": "LK-3957",
                            "StartTime": "2021-12-23 07:20:30",
                            "EndTime": "2021-12-23 09:04:32",
                            "DepartureTime": "2021-12-23 09:45:35",
                            "ArrivalTime": "waiting",
                            "status": "3",
                            "departure_scheduled_time": "09.30.00",
                            "late_duration": "00:15:35"
                        },
                        {
                            "delay_status": 0,
                            "vehicle_no": "LK-3069",
                            "StartTime": "2021-12-23 07:20:32",
                            "EndTime": "2021-12-23 08:56:29",
                            "DepartureTime": "2021-12-23 09:09:49",
                            "ArrivalTime": "waiting",
                            "status": "3",
                            "departure_scheduled_time": "09.30.00",
                            "late_duration": "00:00:00"
                        },
                        {
                            "delay_status": 1,
                            "vehicle_no": "LO-4472",
                            "StartTime": "2021-12-23 07:29:35",
                            "EndTime": "2021-12-23 09:35:04",
                            "DepartureTime": "2021-12-23 09:35:19",
                            "ArrivalTime": "waiting",
                            "status": "3",
                            "departure_scheduled_time": "09.30.00",
                            "late_duration": "00:05:19"
                        },
                        {
                            "delay_status": 1,
                            "vehicle_no": "LO-4427",
                            "StartTime": "2021-12-23 07:29:37",
                            "EndTime": "2021-12-23 09:35:08",
                            "DepartureTime": "2021-12-23 10:58:26",
                            "ArrivalTime": "waiting",
                            "status": "3",
                            "departure_scheduled_time": "09.30.00",
                            "late_duration": "01:28:26"
                        }
                    ]
                },
                
                {
                    "name": "Tangalle",
                    "data": [
                        {
                            "delay_status": 1,
                            "vehicle_no": "LO-4020",
                            "StartTime": "2021-12-23 09:10:52",
                            "EndTime": "2021-12-23 10:47:37",
                            "DepartureTime": "2021-12-23 10:47:39",
                            "ArrivalTime": "waiting",
                            "status": "3",
                            "departure_scheduled_time": "09.30.00",
                            "late_duration": "01:17:39"
                        },
                        {
                            "delay_status": 1,
                            "vehicle_no": "LJ-9088",
                            "StartTime": "2021-12-23 09:10:57",
                            "EndTime": "2021-12-23 10:47:31",
                            "DepartureTime": "2021-12-23 10:47:41",
                            "ArrivalTime": "waiting",
                            "status": "3",
                            "departure_scheduled_time": "09.30.00",
                            "late_duration": "01:17:41"
                        },
                        {
                            "delay_status": 1,
                            "vehicle_no": "LJ-9105",
                            "StartTime": "2021-12-23 09:11:00",
                            "EndTime": "2021-12-23 10:47:27",
                            "DepartureTime": "2021-12-23 10:47:45",
                            "ArrivalTime": "waiting",
                            "status": "3",
                            "departure_scheduled_time": "09.30.00",
                            "late_duration": "01:17:45"
                        },
                        {
                            "delay_status": 1,
                            "vehicle_no": "LO-4752",
                            "StartTime": "2021-12-23 09:11:02",
                            "EndTime": "2021-12-23 10:47:25",
                            "DepartureTime": "2021-12-23 10:47:47",
                            "ArrivalTime": "waiting",
                            "status": "3",
                            "departure_scheduled_time": "09.30.00",
                            "late_duration": "01:17:47"
                        },
                        {
                            "delay_status": 1,
                            "vehicle_no": "LO-5069",
                            "StartTime": "2021-12-23 09:11:06",
                            "EndTime": "2021-12-23 10:47:21",
                            "DepartureTime": "2021-12-23 10:47:51",
                            "ArrivalTime": "waiting",
                            "status": "3",
                            "departure_scheduled_time": "09.30.00",
                            "late_duration": "01:17:51"
                        }
                    ]
                }
            ]
        }
    },
      isLoading: false,
      stats: {
        total_vehicle_c: 0,
        delayed_vehicle_c: 0,
        total_location_c: 0,
        delayed_location_c: 0,
        delayed_location_c_test: 0
      },
      pins: [],
      fPin: {}, 
      data: [],
      time: moment(),
      settings: {
        default_departure_time: ''
      },
      password: "1234"
    }
    this.methods = {
      fetch: this.fetch.bind(this),
      setFPin: this.setFPin.bind(this),
    };
  }

  getPins({data}){
    return data.final_card_data.map(({name}) => _.find(markers, { name }))
  }

  createDataWithPins({data}){
    // data.data.final_card_data,
    return data.final_card_data.map(e => ({
      ...e,
      ..._.find(markers, { name: e.name })
    }));
  }

  counter({data}){
    let total_vehicle_c = 0;
    let delayed_vehicle_c = 0;

    let total_location_c = data.final_card_data.length;
    let delayed_location_c = 0;

    data.final_card_data.forEach(({data})=> {

      let found_a_delayed_vehicle = false;

      total_vehicle_c = total_vehicle_c + data.length;

      data.forEach(({delay_status}) => {
        if(delay_status === 1){
          ++delayed_vehicle_c;
          found_a_delayed_vehicle = true;
        }
      })

      if(found_a_delayed_vehicle){
        ++delayed_location_c;
      }
        
    })

    return { 
      total_vehicle_c,
      delayed_vehicle_c,
      total_location_c,
      delayed_location_c
    }
  }

  // THIS IS A GLOBAL METHOD, ENYONE CAN CALL THIS
  setFPin(fPin){
    this.setState({ fPin })
  }

  // THIS IS A GLOBAL METHOD, ENYONE CAN CALL THIS
  fetch= async (value) => {
    //console.log(value);
    var res = await getPgByDepots();
    //console.log(res.data);
     this.setState({
              stats: this.counter(this.state.full_data_set),
              data: this.createDataWithPins(this.state.full_data_set),
              pins: this.getPins(this.state.full_data_set),
              isLoading: false
            })
   
  };

  componentDidMount(){
    //console.log("sdsds");
    //console.log(this.props);
    //this.fetch();
    //this.interval = setInterval(() => this.fetch(), 30000) //30000
  }

  componentWillUnmount(){
    clearInterval(this.interval)
  }

  onError(err){
    console.error(err)
  }

  render() {
    return (
      <Context.Provider value={{ ...this.methods, ...this.state }}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export { DataContext, withContext};
